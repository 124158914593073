import { classes, AutoMap } from '@automapper/classes';

export class MembershipWithPointStampResponse {
  @AutoMap()
  membershipCode?: string;

  @AutoMap()
  membershipDesc?: string;

  @AutoMap()
  cardImageData?: string;

  @AutoMap()
  logoImageData?: string;

  @AutoMap()
  membershipId?: number;

  @AutoMap()
  membershipNo?: string;

  @AutoMap()
  rewardType?: string;

  @AutoMap()
  pointName?: string;

  @AutoMap()
  joinDate?: string;

  @AutoMap()
  expiryDate?: string;

  @AutoMap()
  closingPoint: number;

  @AutoMap()
  stampEarned: number;

  @AutoMap()
  colCount: number;

  @AutoMap()
  totalStamp: number;

  @AutoMap()
  levelDesc?: string;

  @AutoMap()
  membershipLevel?: number;

  @AutoMap()
  externalMemberLinkMode: number;

  @AutoMap()
  memberType: number;

  @AutoMap()
  printedName?: string;
}
