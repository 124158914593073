import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bottom-odaring-back',
  templateUrl: './bottom-odaring-back.component.html',
  styleUrls: ['./bottom-odaring-back.component.scss']
})
export class BottomOdaringBackComponent implements OnInit {
  @Output() onClickNavigation = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  async clickNavigation() {
    this.onClickNavigation.emit();
  }

}
