import { Pipe, PipeTransform } from '@angular/core';
import { MenuTags } from 'src/app/core/enums/MenuTags';
import { ItemMenuTags } from 'src/app/core/models/ItemMenuTags';

@Pipe({
  name: 'menuTags'
})
export class MenuTagsPipe implements PipeTransform {
  nutriGradeCodes: string[] = [MenuTags.NG01A, MenuTags.NG01B, MenuTags.NG01C, MenuTags.NG01D, MenuTags.NG01AS, MenuTags.NG01BS, MenuTags.NG01CS, MenuTags.NG01DS];

  transform(itemMenuTags: ItemMenuTags[], filter?: string): any {
    let resultList = itemMenuTags;

    if (itemMenuTags) {
      if (filter === "nutri-grade") {
        resultList = itemMenuTags.filter(menuTag => {
          return menuTag.code.includes(MenuTags.NG01A) ||
            menuTag.code.includes(MenuTags.NG01B) ||
            menuTag.code.includes(MenuTags.NG01C) ||
            menuTag.code.includes(MenuTags.NG01D)
        });
      } else if (filter === 'icon') {
        resultList = itemMenuTags.filter(menuTag => !this.nutriGradeCodes.includes(menuTag.code));
      }
    }
    return resultList;
  }

}
