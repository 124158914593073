import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import * as _ from 'lodash';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  defaultUrl: string = "https://www.odaring.com";
  metadataJson: any = null;
  image: string = 'https://zeoniqdev.blob.core.windows.net/evolf/sample/channel/Odaring%20Channel.png';

  //seo
  seoDataSet: any;
  seoData: any[];
  defaultImage: string;

  constructor(
    private http: HttpClient,
    private metaService: Meta,
    private titleService: Title,
  ) { }

  getMetaJson(): Observable<any> {
    let metaJson: any;
    if (environment.hostName == "localhost" || environment.hostName == "odaringdev" || environment.hostName == "odaringlocalhost") {
      metaJson = 'assets/metadata_dev.json'
    } else {
      metaJson = 'assets/metadata_prod.json'
    }
    return this.http.get<any>(metaJson);
  }

  replaceWhiteSpaceWithDash(value: string) {
    let stringWithSpaces = _.cloneDeep(value);
    let stringWithoutBrackets = stringWithSpaces.replace(/[)]/g, '');
    let stringWithoutSpaces = stringWithoutBrackets.replace(/[^\p{L}0-9]/gu , "-");
    let formattedString = stringWithoutSpaces.replace(/-{2,}/g, '-');

    return formattedString;
  }

  async manageMetaTag(currentUrl: string, currentTag?: string) {
    this.getMetaJson().subscribe(data => {
      this.seoDataSet = data;
      this.processUpdateChannel(currentUrl, this.seoDataSet, currentTag);
    });
  }

  async processUpdateChannel(currentUrl: string, data: any, currentTag?: string) {
    let updateStore = currentUrl.includes('/store') ||
      currentUrl.includes('/queue') ||
      currentUrl.includes('/news') ||
      currentUrl.includes('/membership') ||
      currentUrl.includes('/promotion-details-page');

    let seoChannelData = data.ChannelDetail;
    let channelData: any;

    if (currentTag) {
      channelData = seoChannelData.find(data => data.ChannelTag.toUpperCase() === currentTag.toUpperCase());

      if (channelData) {
        this.updateTitle(channelData.ChannelTag.toUpperCase());

        this.defaultImage = channelData?.ImageUrl ? channelData.ImageUrl : this.image

        if (updateStore) {
          this.processUpdateRelatedPage(currentUrl, channelData, data);
        } else {
          this.updateChannelMetaTag(channelData.ChannelTag, channelData.ChannelTag, this.defaultImage)
        }
      } else {
        let tag = 'MY'
        channelData = seoChannelData.find(data => data.ChannelTag.toUpperCase() === tag.toUpperCase());
        this.processUpdateRelatedPage(currentUrl, channelData, data);
      }
    }
    else {
      for (let index = 0; index < seoChannelData.length; index++) {
        let metaChannelTag = seoChannelData[index].ChannelTag.toUpperCase();
        let channelData = seoChannelData[index];
        let uppercaseCurrentUrl = currentUrl.toUpperCase();
        if (uppercaseCurrentUrl.includes(metaChannelTag)) {
          this.updateTitle(channelData.ChannelTag.toUpperCase());
          if (updateStore) {
            this.processUpdateRelatedPage(currentUrl, channelData, data);
          } else {
            this.updateChannelMetaTag(channelData.ChannelTag, channelData.ChannelTag, channelData.ImageUrl)
          }
        }
      }
    }
  }

  async processUpdateRelatedPage(currentUrl: string, channalDetail: any, data: any) {
      //store meta || queue meta
    if (currentUrl.includes('/store') || currentUrl.includes('/queue')) {
      this.seoData = data.StoreDetail;
      this.seoData.forEach(store => {
        let formattedString = this.replaceWhiteSpaceWithDash(store.LocDesc);
        let storeName = currentUrl.includes(formattedString);
        let storeTag = currentUrl.includes(store.StoreId);

        if (storeName && storeTag) {

          let tempImage = channalDetail?.ImageUrl ? channalDetail.ImageUrl : this.image;
          this.defaultImage = store?.ImageUrl ? store.ImageUrl : tempImage;

          if (currentUrl.includes('/store')) {
            this.updateStoreMetaTag(store.LocDesc, store.CategoryDesc, this.defaultImage);
          } else if (currentUrl.includes('/queue')) {
            this.updateQueueMetaTag(store.LocDesc, channalDetail.ChannelTag, this.defaultImage);
          }
        }
      });
    }
    else if (currentUrl.includes('/news')) {
      this.seoData = data.NewsDetail;
      this.seoData.forEach(news => {
       if(news.Title) {
        let formattedString = this.replaceWhiteSpaceWithDash(news.Title);
        let newsTitle = currentUrl.includes(formattedString);
        let newsId = currentUrl.includes(news.InfoId);

        if(newsTitle && newsId){
          let tempImage = channalDetail?.ImageUrl ? channalDetail.ImageUrl : this.image;
          this.defaultImage = news?.ImageUrl ? news.ImageUrl : tempImage;

          let tempSubtitle: string = 'Let’s get the latest scoop from' + channalDetail.ChannelTag;
          let subtitle: string = news?.SubTitle ? news.SubTitle : tempSubtitle;

          this.updateNewsMetaTag(news.Title, subtitle, this.defaultImage);
        }
       }
      })
    }
    else if(currentUrl.includes('/promotion-details-page')){
      this.seoData = data.VoucherDetail;
      this.seoData.forEach(voucher => {
        if(voucher.VchTypTitle) {
          let formattedString = this.replaceWhiteSpaceWithDash(voucher.VchTypTitle);
          let voucherTitle = currentUrl.includes(formattedString);

          if(voucherTitle){
            let tempImage = channalDetail?.ImageUrl ? channalDetail.ImageUrl : this.image;
            this.defaultImage = voucher?.ImageUrl ? voucher.ImageUrl : tempImage;

            let tempSubtitle: string = 'Let’s get the latest scoop from' + channalDetail.ChannelTag;
            let vchTypDesc: string = voucher?.VchTypDesc ? voucher.VchTypDesc : tempSubtitle;


            this.updateVoucherMetaTag(voucher.VchTypTitle, vchTypDesc, this.defaultImage)
          }
        }
      })
    }
    else if (currentUrl.includes('/membership')) {
      this.seoData = data.MembershipDetail;
      this.seoData.forEach(membership => {
        let formattedString = this.replaceWhiteSpaceWithDash(membership.MembershipDesc);
        let MembershipDesc = currentUrl.includes(formattedString);
        let membershipCode = currentUrl.includes(membership.MembershipCode);

        if(MembershipDesc && membershipCode) {
          let tempImage = channalDetail?.ImageUrl ? channalDetail.ImageUrl : this.image;
          this.defaultImage  = membership?.CardImageData ? membership?.CardImageData : membership?.LogoImageData ? membership?.LogoImageData : tempImage;

          this.updateMembershipMetaTag(membership.MembershipDesc, channalDetail.ChannelTag, this.defaultImage)
        }
      })
    }
  }

  async updateTitle(metaTitle: string) {
    this.titleService.setTitle(metaTitle + ' | Your food services')
  }

  //meta description: store
  async updateStoreMetaTag(metaTitle: string, metaDesc: string, metaImage: string) {
    let title = 'Hey, check out' + ' ' + metaTitle + ' ' + 'and start order now.';
    let description = metaDesc;

    this.updateMetaTag(title, description, metaImage);
  }

  //meta description: channel
  async updateChannelMetaTag(metaTitle: string, channelMeta: string, metaImage: string) {
    let title = metaTitle + ' | Powered by Odaring';
    let description = 'Satisfy your cravings with' + ' ' + channelMeta + ' ' + ', where great food is just a tap away.';
    let image = metaImage;

    this.updateMetaTag(title, description, image);
  }

  //meta description: news
  async updateNewsMetaTag(metaTitle: string, metaDesc: string, metaImage: string) {
    let title = metaTitle;
    let description = metaDesc;

    this.updateMetaTag(title, description, metaImage);
  }

  //meta description: membership
  async updateMembershipMetaTag(metaTitle: string, channelMeta: string, metaImage: string) {
    let title = metaTitle + ' | ' + 'Membership';
    let description = 'The food services for your favourite restaurants -' + channelMeta;

    this.updateMetaTag(title, description, metaImage);
  }

  //meta description: voucher
  async updateVoucherMetaTag(metaTitle: string, metaDesc: string, metaImage: string) {
    let title = metaTitle;
    let description = metaDesc;

    this.updateMetaTag(title, description, metaImage);
  }

  //meta description: queue
  async updateQueueMetaTag(metaTitle: string, channelMeta: string, metaImage: string) {
    let title = metaTitle + ' | Queue';
    let description = 'The food services for your favourite restaurants -' + channelMeta;

    this.updateMetaTag(title, description, metaImage);
  }

  //meta description: table reservation
  async updateReservationMetaTag(metaTitle: string, channelMeta: string, metaImage: string) {
    let title = metaTitle + '| Table Reservation';
    let description = 'The food services for your favourite restaurants -' + channelMeta;

    this.updateMetaTag(title, description, metaImage);
  }

  async updateMetaTag(title: string, description: string, image: string) {
    this.metaService.updateTag({ name: 'title', content: title });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ name: 'twitter:text:description', content: description });
    this.metaService.updateTag({ name: 'image', content: image });

    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: image });
    this.metaService.updateTag({ property: 'og:url', content: this.defaultUrl });

    this.metaService.updateTag({ property: 'twitter:title', content: title });
    this.metaService.updateTag({ property: 'twitter:description', content: description });
    this.metaService.updateTag({ property: 'twitter:text:description', content: description });
    this.metaService.updateTag({ property: 'twitter:image', content: image });
    this.metaService.updateTag({ property: 'twitter:url', content: this.defaultUrl });
  }
}
