import { DetailActivity } from './../../../home/order/order.model';
import { Component, Input, OnInit } from '@angular/core';
import { VoidFlag } from 'src/app/core/enums/VoidFlag';

@Component({
  selector: 'app-cart-submitted-orders',
  templateUrl: './cart-submitted-orders.component.html',
  styleUrls: ['./cart-submitted-orders.component.scss']
})
export class CartSubmittedOrdersComponent implements OnInit {

  @Input() detailActivity : DetailActivity;

  voidFlag = VoidFlag;

  constructor() { }

  ngOnInit(): void {
  }

}
