<div class="empty-order-alert p-d-flex p-jc-center p-ai-center">
  <div class="p-d-inline-block">
    <img class="mascot" src="assets/mascot/Mascot_chef-waiting-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
    <div class="desc heading-8 p-text-center" *ngIf="isActive">{{'alert.empty.order.des.3' | translate}}</div>
    <div class="desc heading-8 p-text-center" *ngIf="!isActive">{{'alert.empty.order.des.4' | translate}}</div>
    <div class="d-flex p-text-center p-jc-center">
      <a routerLink="/home" class="btn-start-order" >{{'button.start.order' | translate}}</a>
    </div>
  </div>
</div>
