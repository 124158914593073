import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { QueueCart } from './queue-cart.model';

export interface QueueCartState extends EntityState<QueueCart> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'queue-cart' })
export class QueueCartStore extends EntityStore<QueueCartState, QueueCart> {

  constructor() {
    super();
  }

}
