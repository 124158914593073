import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appStoreImageHeight]'
})
export class StoreImageHeightDirective implements AfterViewInit {
  @Input('isItemDetail') isItemDetail : boolean;

  @Input('imageSrc') set imageLink(link : string){
    this.imageSrc = link;
    this.preCheckHeight();
  }

  get imageLink(){
    return this.imageSrc;
  }

  timeoutKey : any;
  imageSrc : string;

  constructor(private targetedEl: ElementRef) { }

  ngAfterViewInit(): void {
    this.preCheckHeight();
  }

  @HostListener('window:resize', ['$event'])
  onResize(){
    if(this.isItemDetail && (!this.imageSrc || this.imageSrc == "assets/image-unavailable.svg")){
      this.targetedEl.nativeElement.style.height = "170px"
    }
    else if(window.innerWidth < 991){
      this.checkHeight(this.targetedEl);
    }
    else{
      this.targetedEl.nativeElement.style.height = "300px"
    }
  }

  preCheckHeight(){
    if(this.isItemDetail && (!this.imageSrc || this.imageSrc == "assets/image-unavailable.svg")){
      this.targetedEl.nativeElement.style.height = "170px"
    }
    else if(window.innerWidth < 991){
      if(this.targetedEl){
        this.checkHeight(this.targetedEl);
      }
      else{
        this.startInitialTimer();
      }
    }
    else{
      this.targetedEl.nativeElement.style.height = "300px"
    }
  }

  // set initial timer as sometimes UI havent finish looping its content
  startInitialTimer(){
    this.timeoutKey = setTimeout(() => {
      this.checkHeight(this.targetedEl);
    }, 50);
  }

  checkHeight(el: ElementRef){
    if(!el || (el && !el.nativeElement.clientWidth)){
      clearTimeout(this.timeoutKey);
      this.startInitialTimer();
      return;
    }

    let calculatedHeight = (el.nativeElement.clientWidth * 80) / 100;
    el.nativeElement.style.height = calculatedHeight + "px";
  }
}
