import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PreviousRoute } from 'src/app/core/models/PreviousRoute';
import { QrCartStore } from 'src/app/core/qr-cart/qr-cart.store';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-store-not-found-error',
  templateUrl: './store-not-found-error.component.html',
  styleUrls: ['./store-not-found-error.component.scss']
})
export class StoreNotFoundErrorComponent implements OnInit {
  route : PreviousRoute;

  constructor(
    private router : Router,
    private previousRouteService : PreviousRouteService,
    private qrCartStore : QrCartStore,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    if(this.sessionStorageService.getItem("qrTokenResponse")){
      this.sessionStorageService.removeItem("qrTokenResponse");
      this.qrCartStore.update({qrTokenResponse : null});
    }

    this.route = this.previousRouteService.getPreviousRoute();
  }

  goBackToPreviousPage(){
    if(this.route.activityId){
      let data = { 'activityId': this.route.activityId };

      try {
        this.sessionStorageService.setItem("orderDetail", JSON.stringify(data));
      } catch(e) {
        console.log("Failed to set session storage", e)
      }
    }

    this.previousRouteService.removePreviousRoute();

    if(this.route.routeName){
      this.router.navigateByUrl(this.route.routeName);
    }
    else{
      this.router.navigate(['home']);
    }
  }

}
