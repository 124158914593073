import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { Channel } from '../home/channel/channel.model';
import { ChannelService } from '../home/channel/channel.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { CommonAddressResponse } from 'src/app/core/models/CommonAddressResponse';

import { MembershipService } from 'src/app/membership/membership/membership.service';
import * as _ from 'lodash';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MembershipRewardType } from 'src/app/membership/membership/RewardType';
import { MembershipDetailResponse } from 'src/app/membership/membership/membership-detail-response';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from 'src/app/store/store/store.service';
import { ChannelData } from 'src/app/core/models/Channel';
import { Subscription } from 'rxjs';
import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { RewardService } from 'src/app/account/services/reward.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ErrorCode } from '../core/enums/ErrorCode';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';

import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { CartModel } from 'src/app/core/models/CartModel';
import { MiniProgramService } from '../core/mini-program/mini-program.service';
import { AppLinkTokenResponse } from '../core/models/AppLinkTokenResponse';
import { ExternalMemberLinkMode } from 'src/app/membership/membership/external-member-link-mode.enum'
import { ExternalMemberDataaQuery } from './membership/externalMembership-data.query';
import { StoreQuery } from '../store/store/store.query';
import { MembershipWithPointStampResponse } from './membership/membership-with-point-stamp-response';
import { SessionStorageService } from '../shared/storage/session-storage.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit, OnDestroy {

  user: User;
  channel: Channel;
  channelData: ChannelData;
  voucherDetail$: Subscription;
  isLoggedIn: boolean;
  isMobileView: boolean;
  channelId: number;
  latitude: number = 0;
  longitude: number = 0;
  orderTypeFlag = OrderTypeFlag;
  currency: CurrencyResponse;

  membershipCode: string;
  balanceAmount: number;
  balanceStamp: number;
  selectedAddress: CommonAddressResponse;
  memberDetail: MembershipDetailResponse;
  memberVoucherList: VoucherTypeExtraResponse[] = [];
  checkRewardType = MembershipRewardType;
  voucherDetail: VoucherTypeExtraResponse;

  displayRewardHistory: boolean = false;
  displayQuitMembershipTerm: boolean = false;
  displayDeleteAccDialog: boolean = false;
  displayJoin: boolean = false;
  displayQuit: boolean = false;
  displayPointContent: boolean = false;
  displayStampContent: boolean = false;
  cardImageError: boolean = false;
  displayMemberVoucherDialog: boolean = false;
  displayGoToStore: boolean = false;
  displayRedeemError: boolean = false;
  showNearbyStore: boolean = false;

  pointRequires: number;
  errorCode: any = ErrorCode;

  skip: number = 0;
  take: number;

  dialogPosition: string;
  dismissable: boolean;

  storeResponseList: StoreResponse[];
  cartModel: CartModel;

  appLinkTokenResponse: AppLinkTokenResponse;
  appLinkSub$: Subscription;

  externalMemberLogin: ExternalMemberLinkMode = ExternalMemberLinkMode.LOGIN;
  storeSub$: Subscription;
  merchantMemberships: MembershipWithPointStampResponse[] = [];
  isLoading: boolean = false;
  externalMemberLinkMode: boolean = false;

  showClaimedVoucher: boolean = false;
  claimedVoucherList: VoucherTypeExtraResponse[] = [];
  displayClaimVoucherList: boolean = false;
  displayEmptyList: boolean = false;
  onshowInfo: boolean = false;
  displayViewVoucherBtn: boolean = false;

  constructor(
    private userService: UserService,
    private channelService: ChannelService,
    private membershipService: MembershipService,
    private userQuery: UserQuery,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private router: Router,
    private storeService: StoreService,
    private rewardService: RewardService,
    private toastService: ToastService,
    private staticQrService: StaticQrService,
    private miniProgramService: MiniProgramService,
    private externalMemberDataaQuery: ExternalMemberDataaQuery,
    private storeQuery: StoreQuery,
    private sessionStorageService: SessionStorageService
  ) {
    this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileView = true;
        this.dialogPosition = "bottom";
        this.dismissable = true;
      }
      else {
        this.dialogPosition = "center";
        this.dismissable = false;
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.user = this.userService.getCustomer();
    this.isLoggedIn = this.user ? true : false;

    this.channel = await this.channelService.getChannelDataCheck();
    this.channelData = this.channelService.getChannelData();

    this.channelId = this.channel?.channelId;

    this.currency = this.channelData.currency ? this.channelData.currency : null;

    this.getAppLinkTokenResponse();
    this.getStoreData();

    this.userQuery.selectedAddress$.subscribe(address => {
      this.latitude = address ? address.latitude : 0;
      this.longitude = address ? address.longitude : 0;
    });

    const routeParams = this.route.snapshot.paramMap;
    const membershipCodeFromRoute = String(routeParams.get('membershipCode'));
    this.membershipCode = membershipCodeFromRoute;

    let membershipNo = undefined;
    let externalMemberData = this.getExternalMemberData();
    if (this.appLinkTokenResponse) {
      membershipNo = this.appLinkTokenResponse.membershipNo;
    } else if (externalMemberData) {
      membershipNo = externalMemberData.membershipDetailResponse.membershipNo;
    } else {
      membershipNo = this.merchantMemberships.length > 0 ? this.merchantMemberships[0].membershipNo : undefined;
    }
    this.memberDetail = <MembershipDetailResponse>await this.membershipService.getMembershipDetails(this.membershipCode, this.channelId,
      this.latitude, this.longitude, membershipNo);
    this.memberVoucherList = this.memberDetail && this.memberDetail?.voucherList ? this.memberDetail.voucherList : [];
    this.getBalance();

    let isFromPage = this.sessionStorageService.getItem('isFromPage');
    if (isFromPage == 'reward') {
      this.displayGoToStore = true;
    }

    if (isFromPage == 'store') {
      this.displayViewVoucherBtn = true;
    }

    //check reward type and display related content
    if (this.memberDetail.rewardType === this.checkRewardType.pointStamp || this.memberDetail.rewardType === this.checkRewardType.point) {
      this.displayPointContent = true;
    }
    else if (this.memberDetail.rewardType === this.checkRewardType.stamp) {
      this.displayStampContent = true;
    }


    this.isLoading = false;

    if (this.memberDetail.externalMemberLinkMode === this.externalMemberLogin) {
      this.externalMemberLinkMode = false;
    } else {
      this.externalMemberLinkMode = true
    }
  }

  onShowVoucher() {
    this.voucherDetail$ = this.membershipService.detailDialog.subscribe((value: any) => {
      if (value.vchTypId) {
        this.voucherDetail = this.memberDetail.voucherList.filter(reward => reward.vchTypId === value.vchTypId)[0];
      }
      this.displayMemberVoucherDialog = value.isShow;
      this.onshowInfo = true;
    });
  }

  ngOnDestroy(): void {
    this.voucherDetail$?.unsubscribe();
    this.appLinkSub$?.unsubscribe();
    this.storeSub$?.unsubscribe();
  }

  getAppLinkTokenResponse() {
    this.appLinkSub$ = this.miniProgramService.getAppTokenRespObservable().subscribe(appLinkTokenResponse => {
      this.appLinkTokenResponse = appLinkTokenResponse ? appLinkTokenResponse : null;
    })
  }

  getBalance() {
    if (this.memberDetail) {
      if (this.memberDetail.upgradeLevel === null || this.memberDetail.ytdPurchase === null) {
        this.balanceAmount = 0;
      }
      else if (this.memberDetail.upgradeLevel !== null || this.memberDetail.ytdPurchase !== null) {
        this.balanceAmount = this.memberDetail.upgradeYTD - this.memberDetail.ytdPurchase;
      }

      this.balanceStamp = this.memberDetail.nextRewardStampId - this.memberDetail.stampEarned;
    }
  }

  async onSwitchTab(dt: string) {
    switch (dt) {
      case 'point': {
        this.displayPointContent = true;
        this.displayStampContent = false;
        break;
      }
      case 'stamp': {
        this.displayStampContent = true;
        this.displayPointContent = false;
        break
      }
    }
  }

  clickClose() {
    this.displayRewardHistory = false;
  }

  onClickQuitDialog() {
    if (this.appLinkTokenResponse) {
      return;
    }

    this.displayQuitMembershipTerm = true,
      this.displayJoin = true;
    this.displayQuit = false;
  }

  async continueQuit() {
    if (this.memberDetail.externalMemberLinkMode === this.externalMemberLogin) {
      if (this.memberDetail.membershipId === null || !this.memberDetail.membershipId) {
        this.membershipService.removeExternalMembershipData();
        this.onBackPreviousPage();
      }
      else {
        let quiteMemberResult = await this.membershipService.quitMembership(this.membershipCode);
        if (quiteMemberResult instanceof (HttpErrorResponse)) {
          let errorObj = quiteMemberResult.error;
          this.toastService.showApiFailedToast(errorObj);
        } else {
          this.membershipService.removeExternalMembershipData();
          this.onBackPreviousPage();
        }
      }
    }
    else {
      this.displayJoin = false;
      this.displayQuit = true;
    }
  }

  onContinue() {
    this.displayQuitMembershipTerm = false;
    this.displayDeleteAccDialog = true;
  }

  async onBackPreviousPage() {
    let isFromPage = this.sessionStorageService.getItem('isFromPage');
    let storeInfo = this.storeService.getMembershipStoreInfo();
    this.sessionStorageService.removeItem('isFromPage');
    this.storeService.removeMembershipStoreInfo();

    if (isFromPage == 'store') {
      this.storeService.setCurrentOrderType(storeInfo.currentOrderType);
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(storeInfo.locDesc);
      this.router.navigate(['store', storeInfo.storeId, locDescWithoutSpaces]);
    } else if (isFromPage == 'reward') {
      this.router.navigateByUrl('/reward/my-membership');
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  onImgError() {
    this.cardImageError = true
  }

  displayQrPopup() {
    if (this.appLinkTokenResponse) {
      return;
    }

    this.userService.showQrPopup();
  }

  async onClickHistory() {
    this.displayRewardHistory = true;
    if (this.displayRewardHistory == true) {
      document.body.classList.add('p-overflow-hidden');
    } else {
      document.body.classList.remove('p-overflow-hidden');
    }
  }

  async onClaim() {
    if (this.voucherDetail && this.memberDetail) {
      if (this.memberDetail.closingPoint >= this.voucherDetail.pointAmount) {
        let redeemId = this.voucherDetail ? this.voucherDetail.redeemId : 0;
        let vchTypCode = this.voucherDetail ? this.voucherDetail.vchTypCode : '';
        let pointAmount = this.voucherDetail ? this.voucherDetail.pointAmount : 0;

        let response = await this.rewardService.redeemmembershipvoucher(redeemId, this.channelId, this.membershipCode, vchTypCode, pointAmount);

        if (response instanceof (HttpErrorResponse)) {
          if (response.error.errorCode === this.errorCode.MembershipExpired_400) {
            this.displayMemberVoucherDialog = false;
            let errorObj = response.error;
            this.toastService.showApiFailedToast(errorObj);
          }
        } else {
          this.displayMemberVoucherDialog = false;
          this.memberDetail = <MembershipDetailResponse>await this.membershipService.getMembershipDetails(this.membershipCode, this.channelId, this.latitude, this.longitude);
          let toastData = {} as ToastData;
          toastData.icon = "oda-check-alt";
          toastData.iconColor = "#8CD600";
          toastData.message = "alert.voucher.redeem.successful";
          this.toastService.show(toastData);
        }

      }
      else if (this.memberDetail.closingPoint < this.voucherDetail.pointAmount) {
        this.displayMemberVoucherDialog = false;
        this.displayRedeemError = true;
        this.pointRequires = this.voucherDetail.pointAmount - this.memberDetail.closingPoint;
      }
    }
    if (!this.isLoggedIn) {
      this.membershipService.loginDialog.next(true);
    }
  }

  onClickOkay() {
    this.displayRedeemError = false;
    this.displayMemberVoucherDialog = true;
  }

  onShowNearbyStore() {
    let storeResponseList = this.memberDetail && this.memberDetail?.storeResponseList ? this.memberDetail.storeResponseList : [];
    if (storeResponseList && storeResponseList.length === 1) {
      let globalOrderType = this.staticQrService.getChannelOrderType();
      let storeCart: OrderH = null;
      let orderType: string;

      if ((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.cartModel && this.cartModel?.orderHs && this.cartModel.orderHs?.length > 0) {
        storeCart = this.cartModel.orderHs.find(orderH => orderH.storeId == storeResponseList[0].storeId);
      }

      if (storeCart) {
        orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
      }
      else {
        orderType = storeResponseList[0].currentOrderType;
      }
      this.storeService.preStoreNavigation(orderType, storeResponseList[0]);
    } else if (storeResponseList) {
      this.showNearbyStore = true;
      this.storeResponseList = storeResponseList;
    }
  }

  async onShowClaimedVoucher() {
    let membershipNo: string;
    let externalMemberData = this.getExternalMemberData();
    if (this.appLinkTokenResponse) {
      membershipNo = this.appLinkTokenResponse.membershipNo;
    } else if (externalMemberData) {
      membershipNo = externalMemberData.membershipDetailResponse.membershipNo;
    } else {
      membershipNo = this.memberDetail && this.memberDetail?.membershipNo ? this.memberDetail.membershipNo : null;
    }

    this.membershipService.getmemberclaimedvoucher(this.channelId, this.membershipCode, membershipNo)
      .then(respDt => {
        if (!(respDt instanceof (HttpErrorResponse))) {
          this.claimedVoucherList = respDt && respDt ? respDt : [];
          this.showClaimedVoucher = true;
          if (this.claimedVoucherList && this.claimedVoucherList.length > 0) {
            this.displayClaimVoucherList = true;
            this.displayEmptyList = false;
          }
          else {
            this.displayClaimVoucherList = false;
            this.displayEmptyList = true;
          }
        } else {
          let toastData = {} as ToastData;
          toastData.icon = "oda-alert";
          toastData.iconColor = "#EB3600";
          toastData.message = "technical.error.title.1";
          this.toastService.show(toastData);
        }
      });
  }

  onShowClaimedVoucherDetail() {
    this.voucherDetail$ = this.membershipService.detailDialog.subscribe((value: any) => {
      if (value.vchTypId) {
        this.voucherDetail = this.claimedVoucherList.filter(reward => reward.vchTypId === value.vchTypId)[0];
      }
      this.displayMemberVoucherDialog = value.isShow;
      this.onshowInfo = false;
    });

  }

  getExternalMemberData() {
    let externalMemberData = _.cloneDeep(this.externalMemberDataaQuery.getValue());
    if (externalMemberData && externalMemberData?.membershipDetailResponse) {
      return externalMemberData;
    }
    else {
      return null;
    }
  }

  getMembershipStoreInfo() {
    let storeInfo = JSON.parse(this.sessionStorageService.getItem("storeInfo"));
    return storeInfo ? storeInfo : null;
  }

  getStoreData() {
    let storeInfo = this.getMembershipStoreInfo();
    if (storeInfo) {
      this.storeSub$ = this.storeQuery.selectEntity(storeInfo.storeId).subscribe(val => {
        if (val) {
          this.merchantMemberships = _.cloneDeep(val.merchantMemberships);
        }
      })
    }
  }
}
