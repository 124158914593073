export enum ActivityStatus {
    Open = 'Open',
    Redeemed = 'Redeemed',
    Refunded = 'Refunded',
    Forfeited = 'Forfeited',
    PendingPayment = 'PendingPayment',
    Closed = 'Closed',
    OrderCancelled = 'OrderCancelled',
    PendingAllocate = 'PendingAllocate',
    Allocated = 'Allocated',
    Reallocate = 'Reallocate',
    Preparing = 'Preparing',
    PendingPickup = 'PendingPickup',
    PickedUp = 'PickedUp',
    DeliveryFailed = 'DeliveryFailed',
    CustomerCancelled = 'CustomerCancelled',
    PaymentCancelled = 'PaymentCancelled',
    PendingSubmit = 'PendingSubmit'
}
