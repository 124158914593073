import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ConfigService } from 'src/app/config.service';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Output() onClose = new EventEmitter();

  showDeleteAccTncDialog: boolean = false;
  showDeleteAccDialog: boolean = false;
  isMobile: boolean = false;
  isDefaultDomain: boolean = false;
  userChnlSub: Subscription = new Subscription();

  constructor(
    private configService: ConfigService,
    private router: Router,
    private userchnlQry: UserChnlQuery,
    ) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 991 ? true: false;

    this.userChnlSub = this.userchnlQry.isSubDomain$.subscribe((data) => {
      this.isDefaultDomain = !data;
    });
  }

  onNavigate(dt: string) {
    const originCountryUrl = this.isDefaultDomain ? (window.location.origin + this.configService.get('appBaseHref')) : window.location.origin;

    switch (dt) {
      case 'faq': {
        window.open(originCountryUrl + '/faq', '_blank');
        break;
      }
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tnc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
      case 'setting': {
        this.router.navigateByUrl("/settings");
        break;
      }
      default: {
        this.router.navigateByUrl("/");
        break;
      }
    }
  }

  onClickDeleteAcc() {
    this.showDeleteAccTncDialog = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let isMobile = window.innerWidth <= 991 ? true: false;

    if(isMobile !== this.isMobile) {
      this.router.navigateByUrl("/profile");
    }
  }

  ngOnDestroy() {
    this.userChnlSub?.unsubscribe();
  }
}
