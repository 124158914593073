<div class="news-card-container" (click)="onClickCard(newsData.infoUrl)">
  <div class="card-wrapper">
    <img *ngIf="imageUrl" [src]="imageUrl" [alt]="title" alt="news image" loading="lazy"/>
    <div class="details">
      <h1 *ngIf="title">{{ title }}</h1>
      <h2 *ngIf="subtitle">{{ subtitle }}</h2>
    </div>
  </div>
</div>


