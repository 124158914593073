import { ExternalMemberDataStore } from './externalMembership-data.store';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ExternalMemberData } from 'src/app/core/models/local/ExternalMembershipData';

@Injectable({ providedIn: 'root' })
export class ExternalMemberDataaQuery extends Query<ExternalMemberData> {

  constructor(protected store: ExternalMemberDataStore) {
    super(store);
  }
}


