import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { StoreMode } from 'src/app/core/enums';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { Channel } from 'src/app/home/channel/channel.model';
import { ChannelSec } from 'src/app/home/channelsec/channelsec.model';
import { SectionDataType } from 'src/app/core/enums/DataType';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})

export class NewsComponent implements OnInit, OnDestroy {
  channel$: Subscription = new Subscription();

  channelSecData: ChannelSec;
  news: any;
  sectionDataType = SectionDataType;

  constructor(private channelService: ChannelService
  ) { }

  async ngOnInit() {
    await this.channelService.getChannelDataCheck();

    this.channel$ = this.channelService.get(StoreMode.Internal).subscribe((channelData: Channel) => {
      let channelHomeSecs: any = channelData.data.channelHomeSections;
      this.channelSecData = channelHomeSecs.find(val => val.data.dataType == this.sectionDataType.InfoPanel);
      this.news = this.channelSecData.data.data.find((data: any) => data.dataSet && data.dataSet.length > 0)?.dataSet;
    });
  }

  ngOnDestroy(): void {
    this.channel$.unsubscribe();
  }

}
