<div class="delete-acc-container">
    <img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot"/>
    <h1>{{ 'delete.acc.sad.u.go' | translate }}</h1>
    <p class="desc" [innerHtml]="'delete.acc.delete.desc' | translate: { deleteText: '<span style=\'font-weight: bold; text-transform: lowercase;\'>' + deleteText + '</span>' } | safeHtml"></p>
    <div class="input-field">
        <input type="text" [placeholder]="deleteText" [(ngModel)]="deleteInput" id="deleteText" (click)="isError = false" (keyup.enter)="onDeleteAcc()" autocomplete="off" />
        <i class="oda-times btn-clear" *ngIf="deleteInput" (click)="deleteInput = ''"></i>
    </div>
    <p class="error" *ngIf="isError && deleteInput"><i class="oda-alert"></i>{{ 'profile.error.delete.desc' | translate }}</p>
    <div class="button-container">
        <button type="button" class="btn-delete" [disabled]="!deleteInput" (click)="onDeleteAcc()">{{ 'button.delete.account' | translate }}</button>
        <button type="button" class="btn-close" (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
    </div>
</div>
