
<div class="email-expired-container">
  <div class="content" *ngIf="!displayResendFailed">
    <img src="assets/mascot/Mascot_sleep-Grey@2x.webp" alt="odaring mascot"/>
    <h1 class="heading-11" style="text-align: center">
      {{'email.verification.expired.title' | translate}}
    </h1>
    <h1 class="heading-13" style="text-align: center">
      {{'email.verification.expired.content' | translate}}
    </h1>

    <div style="text-align: center">
      <button type="button" class="btn-resend-email" (click)="resendVerificationLink()">
        {{'email.verification.resend.btn' | translate}}
      </button>
    </div>
  </div>

  <div class="content" *ngIf="displayResendFailed">
    <img src="assets/mascot/Mascot_sleep-Grey@2x.webp" alt="odaring mascot"/>
    <h1 class="heading-11" style="text-align: center">
      {{'email.verification.resend.failed.title' | translate}}
    </h1>
    <h1 class="heading-13" style="text-align: center">
      {{'email.verification.resend.failed.content' | translate}}
    </h1>

    <div style="text-align: center">
      <button type="button" class="btn-resend-email" (click)="resendVerificationLink()">
        {{'button.retry' | translate}}
      </button>
    </div>
  </div>
</div>

<app-bottom-odaring-back (onClickNavigation)="navigateBackToHome()"></app-bottom-odaring-back>
