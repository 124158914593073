import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from 'src/app/core/models/MenuItem';

@Pipe({
  name: 'calPriceDiff'
})
export class CalPriceDiffPipe implements PipeTransform {
  priceDifference : number;

  transform(upsellItem: MenuItem, menuItem : MenuItem): number {
    this.priceDifference = 0;

    if(upsellItem.dispPrice && menuItem.dispPrice){
      this.priceDifference = upsellItem.dispPrice - menuItem.dispPrice;
    }
    else if(upsellItem.dispPrice){
      this.priceDifference = upsellItem.dispPrice - (menuItem.origPrice ? menuItem.origPrice : 0);
    }
    else if(menuItem.dispPrice){
      this.priceDifference = (upsellItem.origPrice ? upsellItem.origPrice : 0) - menuItem.dispPrice;
    }
    else{
      this.priceDifference = (upsellItem.origPrice ? upsellItem.origPrice : 0) - (menuItem.origPrice ? menuItem.origPrice : 0);
    }

    return this.priceDifference < 0 ? 0 : this.priceDifference;
  }

}
