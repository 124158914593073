import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { NIL } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryGuardGuard implements CanActivate {
  id: number;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (isPlatformServer(this.platformId)) {
      return false;
    }

    let activityGuid = route.queryParams.activityGUID;

    if(this.sessionStorageService.getItem("orderDetail")) {
      this.sessionStorageService.removeItem("orderDetail");
    }

    if (( this.sessionStorageService.getItem("orderSummary") && JSON.parse( this.sessionStorageService.getItem("orderSummary")).payTranId)
    ||  this.sessionStorageService.getItem("qrOrderSummary") ||  this.sessionStorageService.getItem("orderPayAtCounter")
    || ( this.sessionStorageService.getItem("queueOrderSummary") && JSON.parse( this.sessionStorageService.getItem("queueOrderSummary")).rsvTokenId)
    || activityGuid || localStorage.getItem("staticQrOrderSummary")
    || ( this.sessionStorageService.getItem("orderPaid") && JSON.parse( this.sessionStorageService.getItem("orderPaid")).activityId)) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
