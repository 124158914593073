import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-nconditions',
  templateUrl: './term-nconditions.component.html',
  styleUrls: ['./term-nconditions.component.scss']
})
export class TermNconditionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
