import { StorageService } from 'src/app/shared/services/storage.service';
import { OrderService } from 'src/app/home/order/order.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { StoreMode } from 'src/app/core/enums';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { CustomHeader, CustomRequest } from 'src/app/core/models/CustomRequest';
import { QrCartQuery } from 'src/app/core/qr-cart/qr-cart.query';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { UserService } from 'src/app/core/user/user.service';
import { StoreService } from 'src/app/store/store/store.service';
import { environment } from 'src/environments/environment';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { TimeService } from 'src/app/core/services/time.service';
import { CommonService } from './common.service';
import { SessionStorageService } from '../storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class WeblinkService {

  accessToken: string = "";
  refreshToken: string = "";

  curCartLinkId : number;
  curTokenLinkId : number;

  constructor(
    private userService: UserService,
    private customService: CustomService,
    private qrCartService: QrCartService,
    private qrCartQuery : QrCartQuery,
    private router: Router,
    private storeService : StoreService,
    private orderService : OrderService,
    private staticQrService : StaticQrService,
    private storageService : StorageService,
    private timeService : TimeService,
    private commonService : CommonService,
    private sessionStorageService: SessionStorageService
  ) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      if(dt){
        this.accessToken = dt && dt['accessToken'] ? dt['accessToken'] : '';
        this.refreshToken = dt && dt['refreshToken'] ? dt['refreshToken'] : '';
      }
    });

    this.qrCartQuery.select(val => val.qrTokenResponse).subscribe(qrToken => {
      if(qrToken){
        this.curTokenLinkId = qrToken.linkId;
      }
      else{
        this.curTokenLinkId = undefined;
      }
    })

    this.qrCartQuery.selectFirst().subscribe(qrCart => {
      if(qrCart){
        this.curCartLinkId = +qrCart.id;
      }
      else{
        this.curCartLinkId = undefined;
      }
    })
  }

  async validateWebLinkToken(qrToken: string, isScan?: boolean) {
    let respDt = null;
    respDt = await this.reqValidateWebLinkToken(this.accessToken, qrToken);

    // will check if qrPayment exists in session storage, remove if it does
    if(this.sessionStorageService.getItem("qrPayment")){
      this.sessionStorageService.removeItem("qrPayment");
    }

    if (respDt instanceof (HttpErrorResponse)) {
      this.qrCartService.quitQrDineInCheck();
      this.orderService.removeCurrentPageStored();
      this.storageService.removeQrInfo();

      let qrScanData = this.storageService.getIsQrScan();

      if (respDt.status >= 500){
        this.router.navigate(['technical-error']);
      }
      else {
        // if it is scan then route to invalid qr page as usual
        // else route to given url
        if(qrScanData.isScan){
          if(respDt.status >= 400){
            this.router.navigate(['/invalid-qr'], { skipLocationChange: true });

            let rr: any = [];
            let errorObj = JSON.parse(respDt.error.errorData[0].errorMessage);
            rr.push(errorObj)

            this.commonService.showExpiredQrErrorData.next({
              locDesc: rr[0].LocDesc,
              storeImageUrl: rr[0].StoreImageUrl
            })
          }
          else{
            this.router.navigateByUrl(qrScanData.url);
          }
        }
      }
    } else {
      this.timeService.storeIdleTime(new Date(), 6);
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      // remove static data cache in scenario where user scan dynamic qr after static qr flow
      this.staticQrService.remove();

      //if cart exists check by cart linkId else check if qrTokenResponse is empty or
      //have different linkId
      if(this.curCartLinkId){
        if(this.curCartLinkId != respDt.linkId){
          this.qrCartService.removeCart();
          this.orderService.removeCurrentPageStored();
        }
      }
      else if((!this.curTokenLinkId) || (this.curTokenLinkId != respDt.linkId)){
        this.qrCartService.removeCart();
        this.orderService.removeCurrentPageStored();
      }

      if(isScan){
        this.storeService.setShowQrSelection(true);
      }

      this.sessionStorageService.setItem('qrTokenResponse', JSON.stringify(respDt));
      this.qrCartService.updateQrTokenResponse(respDt);
      this.storeService.removeIsDisplayedFlag();
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(respDt.locDesc);
      this.router.navigate(['store', respDt.storeId, locDescWithoutSpaces], { queryParams : { qrToken: respDt.tokenId } });
    }

    return respDt;
  }

  async reValidateWebLinkToken(qrToken: string){
    let respDt = null;
    respDt = await this.reqValidateWebLinkToken(this.accessToken, qrToken);

    if (respDt instanceof (HttpErrorResponse)) {
      if (respDt.status >= 500){
        this.router.navigate(['technical-error']);
      }
      else if(respDt.status >= 400){
        let rr: any = [];
        let errorObj = JSON.parse(respDt.error.errorData[0].errorMessage);
        rr.push(errorObj)

        this.commonService.showExpiredQrErrorData.next({
          locDesc: rr[0].LocDesc,
          storeImageUrl: rr[0].StoreImageUrl
        })

        this.router.navigate(['/invalid-qr'], { skipLocationChange: true });
      }
    }

    return respDt;
  }

  private async reqValidateWebLinkToken(accessToken: string, qrToken: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.webLink.validateweblinktoken,
      hostPath: environment.hostPath,
      queryParams: {
        tokenId: qrToken
      },
      headers: {
        accessToken: accessToken ? accessToken : undefined,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }
}
