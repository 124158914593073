import { Pipe, PipeTransform } from '@angular/core';
import { Modifier } from 'src/app/core/models/Modifier';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { SubModifier } from 'src/app/core/models/SubModifier';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';

@Pipe({
  name: 'modQuantityCondition'
})
export class ModQuantityConditionPipe implements PipeTransform {

  transform(quantity: number, curMod: any, curModGrp: any, onlyOne: boolean): boolean {
    if (quantity > 0) {
      let isModifier = this.isModifier(curModGrp);
      let isDisplay : boolean;

      if(!onlyOne){
        if(isModifier){
          isDisplay = this.isDisplaySingle(curModGrp, curMod);
          return isDisplay ? false : true;
        }
        else{
          isDisplay = this.isSubModDisplaySingle(curModGrp, curMod);
          return isDisplay ? false : true;
        }
      }
      else{
        if(isModifier){
          isDisplay = this.isDisplaySingle(curModGrp, curMod);
          return isDisplay ? true : false;
        }
        else{
          isDisplay = this.isSubModDisplaySingle(curModGrp, curMod);
          return isDisplay ? true : false;
        }
      }

    } else {
      return false;
    }
  }

  isModifier(curModGrp : any){
    if('modifiers' in curModGrp){
      return true;
    }
    else{
      return false;
    }
  }

  isDisplaySingle(modifierGrp : ModifierGrp, modifier : Modifier){
    let showSingleQty = false;
    if((modifierGrp.maxSelect == 1 && modifierGrp.minSelect == 1 && modifier.minSelect == modifier.maxSelect && modifier.minSelect > 0 && modifier.maxSelect > 0)
    || modifierGrp.maxSelect == 1 || modifier.maxSelect == 1){
      showSingleQty = true;
    }
    else{
      showSingleQty = false;
    }

    return showSingleQty;
  }

  isSubModDisplaySingle(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let showSingleQty = false;
    if((subModifierGrp.maxSelect == 1 && subModifierGrp.minSelect == 1 && subModifier.minSelect == subModifier.maxSelect && subModifier.minSelect > 0 && subModifier.maxSelect > 0)
    || subModifierGrp.maxSelect == 1 || subModifier.maxSelect == 1){
      showSingleQty = true;
    }
    else{
      showSingleQty = false;
    }

    return showSingleQty;
  }

}
