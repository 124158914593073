import { StaticQrState } from './static-qr.model';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

/**
 * Create initial state
 */
export function createInitialState(): StaticQrState {
  return {
    channelId: undefined,
    orderType: '',
    tableNo: '',
    guestCount: 0,
    storeId : undefined,
    exp: ''
  };
}

/**
 * StaticQr store
 *
 * @export
 * @class StaticQrStore
 * @extends {Store<StaticQrState>}
 */
@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'staticQr'
 })
export class StaticQrStore extends Store<StaticQrState> {

  constructor() {
    super(createInitialState());
  }
}
