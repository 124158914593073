import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-quit-message',
  templateUrl: './qr-quit-message.component.html',
  styleUrls: ['./qr-quit-message.component.scss']
})
export class QrQuitMessageComponent implements OnInit {

  @Input() channelId: any;
  @Output() clickYes = new EventEmitter();
  @Output() clickNo = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  remainQrDineInMode(){
    this.clickNo.emit();
  }

  quitQrDineInMode(){
    this.clickYes.emit();
  }

}
