import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { Cart } from './cart.model';
import { CartState, CartStore } from './cart.store';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { UserQuery } from '../user/user.query';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartQuery extends QueryEntity<CartState, Cart> {

  constructor(
    protected cart: CartStore,
    protected channelQuery : ChannelQuery,
    protected channelService : ChannelService,
    private userQuery : UserQuery
  ) {
    super(cart);
  }

  getCurrentCart(){
    return this.channelQuery.selectFirst().pipe(
      switchMap(value => {
        return value? this.userQuery.isLoggedIn$ : EMPTY;
      }),
      switchMap(userInfo => {
        let channel = this.channelService.getChannelData();
        let currentId : string = "";

        if(!userInfo){
          currentId = "null-" + channel.channelTag;
          return this.selectEntity(currentId);
        }
        else{
          return this.selectEntity(userInfo.customerId);
        }
      })
    )
  }

  getLocalCart(){
    return this.channelQuery.selectFirst().pipe(
      switchMap(channel => {
        if(channel){
          let currentId = "null-" + channel.channelTag;
          return this.selectEntity(currentId);
        }
        else{
          return EMPTY;
        }
      })
    )
  }

}
