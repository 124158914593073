import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';
import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { StoreMode } from 'src/app/core/enums';
import { UserService } from 'src/app/core/user/user.service';
import { MembershipWithPointStampResponse } from 'src/app/membership/membership/membership-with-point-stamp-response';
import { MembershipRewardType } from 'src/app/membership/membership/RewardType';
import { StoreService } from 'src/app/store/store/store.service';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { ExternalMemberLinkMode } from 'src/app/membership/membership/external-member-link-mode.enum';
import { MembershipDetailResponse } from 'src/app/membership/membership/membership-detail-response';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-membership-card',
  templateUrl: './membership-card.component.html',
  styleUrls: ['./membership-card.component.scss']
})
export class MembershipCardComponent implements OnInit {
  @Input() membership: MembershipWithPointStampResponse[];
  @Input() appLinkTokenResponse : AppLinkTokenResponse;
  @Input() storeResponse : StoreResponse;
  @Input() channelId: number;
  @Input() isStorePage: boolean = false;
  @Input() isRewardPage: boolean = false;
  @Input() membershipReward?: MembershipWithPointStampResponse;

  user$: Subscription;
  isLoggedIn: boolean = false;
  isMobileView: boolean = false;
  cardImageError: boolean = false;
  checkRewardType = MembershipRewardType;
  externalMemberNone: ExternalMemberLinkMode = ExternalMemberLinkMode.NONE;
  externalMemberRegister: ExternalMemberLinkMode = ExternalMemberLinkMode.REGISTER;
  externalMemberLogin: ExternalMemberLinkMode = ExternalMemberLinkMode.LOGIN;

  show: boolean = false;
  displayExternalMembershipLoginDialog: boolean = false;

  externalMemberDataSub$ : Subscription;
  externalMemberResponse: MembershipDetailResponse

  constructor(
    private breakpointObserver: BreakpointObserver,
    private membershipService: MembershipService,
    private userService: UserService,
    private router: Router,
    private storeService: StoreService,
    private sessionStorageService: SessionStorageService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileView = true;
      }
      else {
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit() {
    this.user$ = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.isLoggedIn = userData && userData.id != 999 ? true : false;
    });
  }

  onImgError() {
    this.cardImageError = true
  }

  async onClickMember(memberData: MembershipWithPointStampResponse) {
    this.sessionStorageService.setItem('isFromPage', 'store');

    await this.storeInfoProcess();

    if (!this.isLoggedIn && !this.appLinkTokenResponse && memberData.externalMemberLinkMode !== this.externalMemberLogin) {
      this.membershipService.loginDialog.next(true);
      return;
    }

    if(!this.appLinkTokenResponse && memberData.externalMemberLinkMode === this.externalMemberLogin) {
      this.membershipService.externalMembershipLogin.next(true)
    }

    if (memberData.membershipId || memberData.membershipNo) {
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(memberData.membershipDesc);
      this.router.navigate(['membership', memberData.membershipCode, locDescWithoutSpaces]);
    }
    else if (!memberData.membershipId && !memberData.membershipNo && memberData.externalMemberLinkMode !== this.externalMemberLogin) {
      this.membershipService.joinTermDialog.next(true);
    }
  }

  async onClickStore() {
    this.sessionStorageService.setItem('isFromPage', 'reward');

    let membershipDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.membershipReward.membershipDesc)
    this.router.navigate(['/membership', this.membershipReward.membershipCode, membershipDescWithoutSpaces]);
  }

  async onClickNav() {
    this.sessionStorageService.setItem('isFromPage', 'store');

    await this.storeInfoProcess();

    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.externalMemberResponse.membershipDesc);
    this.router.navigate(['membership', this.externalMemberResponse.membershipCode, locDescWithoutSpaces]);
  }

  async storeInfoProcess(){
    let storeInfo = {} as Partial<StoreResponse>
    storeInfo.storeId = this.storeResponse.storeId;
    storeInfo.locDesc = this.storeResponse.locDesc;
    storeInfo.currentOrderType = this.storeResponse.currentOrderType;
    this.storeService.saveMembershipStoreInfo(storeInfo);
  }

}
