import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { Subject } from 'rxjs';

import { StoreMode } from 'src/app/core/enums';
import { User } from 'src/app/core/user/user.model';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from "src/app/core/enums/HttpHeaderType";
import { CustomRequest } from "src/app/core/models/CustomRequest";
import { StoreStore } from 'src/app/store/store/store.store';
import { UserService } from 'src/app/core/user/user.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { VoucherExtra } from 'src/app/core/models/VoucherExtra';
import { OrderVoucher } from 'src/app/core/models/OrderVoucher';
import { OrderData } from 'src/app/core/models/OrderData';

@Injectable({
  providedIn: 'root'
})
export class RewardService {
  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;
  cardTypes: any = VoucherCardType;
  dialogScrollPosition: number = 0;
  seeMoreData: {seeMore: boolean, vchCatgId: number};

  detailDialog = new Subject<{isShow: boolean, vchTypId?: number, voucherNo?: string, isBack?: boolean, vchCatgId?: number, cardType?: VoucherCardType}>();
  storeDialog = new Subject<{isShow: boolean, storeList?: StoreResponse[]}>();
  shareDialog = new Subject<{isShow: boolean, vchTypId?: number}>();
  qrDialog = new Subject<{isShow: boolean, voucherNo?: string}>();
  selectDialog = new Subject<{isShow: boolean, storeId?: number, orderData?: OrderData}>();
  promoDialog = new Subject<{isShow: boolean, storeId?: number}>();
  loginDialog = new Subject();
  searchVoucherDialog = new Subject<{isShow: boolean, storeId?: number}>();
  selectVoucher = new Subject<{selected: boolean, voucher: VoucherExtra, type: VoucherCardType, storeId?: number}>();
  applyVoucher = new Subject<{selectedVoucher: OrderVoucher[], storeId: number}>();
  removeReward = new Subject<{voucherNo: string, storeId?: number}>();
  removePromo = new Subject<{promotionCode: string, storeId?: number}>();
  fullPageRewardDetail = new Subject<{vchTypId?: number, voucherNo?: string, vchCatgId?: number, cardType?: VoucherCardType}>();

  constructor(private userService: UserService,
    private customService: CustomService,
    private storeStore: StoreStore) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.accessToken = dt && dt['accessToken']? dt['accessToken']: '';
      this.refreshToken = dt && dt['refreshToken']? dt['accessToken']: '';
      this.curUser = dt? dt : null;
    });
  }

  checkRewardType(rewardData: VoucherTypeExtraResponse): VoucherCardType{
    let cardType: VoucherCardType;

    if ( rewardData.totalIssuedPercentage === 100 ) {
      cardType = this.cardTypes.full;
    } else if ( rewardData.isUserClaimed ) {
      cardType = this.cardTypes.claimed;
    } else if ( rewardData.storeResponseList.length === 0 ) {
      cardType = this.cardTypes.claim;
    } else if ( rewardData.storeResponseList.length > 0 ) {
      cardType = this.cardTypes.store;
    } else {
      cardType = this.cardTypes.none;
    }

    return cardType;
  }

  updateVoucherClaimStatus(storeId: number, vchTypId: number, status: VoucherCardType) {
    this.storeStore.update(storeId, entity => {
      return {
        ...entity,
        storeVoucherType: {
          ...Object.keys(entity.storeVoucherType).map(index => entity.storeVoucherType[index]).map(voucher => {
            if(voucher.vchTypId === vchTypId) {
              if (status === this.cardTypes.claimed) {
                return {
                  ...voucher,
                  isUserClaimed: true
                }
              }

              if (status === this.cardTypes.full) {
                return {
                  ...voucher,
                  totalIssuedPercentage: 100
                }
              }
            }
            return {
              ...voucher
            }
          })
        }
      }
    });
  }

  async getVoucherTypeByVchTypCode(key: string, latitude: number, longitude: number, channelId: number, orderTypeFlag?: string) {
    let respDt = null;
    respDt = await this.reqGetVoucherTypeByVchTypCode(this.accessToken, key, latitude, longitude, channelId, orderTypeFlag);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getVoucherTypeByVchTypId(vchTypId: number, latitude: number, longitude: number, channelId: number, orderTypeFlag?: string) {
    let respDt = null;
    respDt = await this.reqGetVoucherTypeByVchTypId(this.accessToken, vchTypId, latitude, longitude, channelId, orderTypeFlag);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getStoreVoucherType(storeId: number, channelId?: number, claimCode?: string) {
    let respDt = null;
    respDt = await this.reqGetStoreVoucherType(this.accessToken, storeId, channelId, claimCode);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getNearbyVoucherType(latitude: number, longitude: number, channelId: number, skip: number, take: number, searchText?: string, attrOptionId?: number, orderTypeFlag?: string) {
    let respDt = null;
    respDt = await this.reqGetNearbyVoucherType(this.accessToken, latitude, longitude, channelId, skip, take, searchText, attrOptionId, orderTypeFlag);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getVoucherTypeCategories() {
    let respDt = null;
    respDt = await this.reqGetVoucherTypeCategories(this.accessToken);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async claimVoucher(vchTypId: number, storeId?: number, channelId?: number, sendNotification?: boolean, manualAssignSerialList? : string[],) {
    let respDt = null;
    respDt = await this.reqClaimVoucher(this.accessToken, vchTypId, storeId, channelId, sendNotification, manualAssignSerialList);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getCustomerClaimedVoucher(latitude: number, longitude: number, channelId: number, skip: number, take: number, searchText?: string, orderTypeFlag?: string, vchCatgId?: number) {
    let respDt = null;
    respDt = await this.reqGetCustomerClaimedVoucher(this.accessToken, latitude, longitude, channelId, skip, take, searchText, orderTypeFlag, vchCatgId);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getApplicableVoucher(storeId: number, channelId: number, membershipCode? : string, membershipNo? : string) {
    let respDt = null;
    respDt = await this.reqGetApplicableVoucher(this.accessToken, storeId, channelId, membershipCode, membershipNo);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getCustomerVoucherHistory(skip: number, take: number, searchedText?: string, voucherStatus?: string) {
    let respDt = null;
    respDt = await this.reqGetCustomerVoucherHistory(this.accessToken, skip, take, searchedText, voucherStatus);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async redeemmembershipvoucher(redeemId: number, channelId?: number, membershipCode?: string, vchTypCode? : string, pointAmount? : number){
    let respDt = null;
    respDt = await this.reqRedeemmembershipvoucher(this.accessToken, redeemId, channelId, membershipCode, vchTypCode, pointAmount );
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  private async reqGetVoucherTypeByVchTypCode(accessToken: string, key: string, latitude: number, longitude: number, channelId: number, orderTypeFlag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetVoucherTypeByVchTypCode,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        key: key,
        latitude: latitude,
        longitude: longitude,
        channelId: channelId,
        OrderTypeFlag: orderTypeFlag? orderTypeFlag: undefined,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetVoucherTypeByVchTypId(accessToken: string, vchTypId: number, latitude: number, longitude: number, channelId: number, orderTypeFlag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetVoucherTypeByVchTypId,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        vchTypId: vchTypId,
        latitude: latitude,
        longitude: longitude,
        channelId: channelId,
        OrderTypeFlag: orderTypeFlag? orderTypeFlag: undefined,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetStoreVoucherType(accessToken: string, storeId: number, channelId?: number, claimCode?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetStoreVoucherType,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        storeID: storeId,
        channelID: channelId? channelId: undefined,
        claimCode: claimCode? claimCode: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetNearbyVoucherType(accessToken: string, latitude: number, longitude: number, channelId: number, skip: number, take: number, searchText?: string, attrOptionId?: number, orderTypeFlag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetNearbyVoucherType,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        Latitude: latitude,
        Longitude: longitude,
        SearchText: searchText? searchText: undefined,
        ChannelId: channelId,
        Skip: skip,
        Take: take,
        AttrOptionId: attrOptionId? attrOptionId: undefined,
        OrderTypeFlag: orderTypeFlag? orderTypeFlag: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetVoucherTypeCategories(accessToken: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetVoucherTypeCategories,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqClaimVoucher(accessToken: string, vchTypId: number, storeId?: number, channelId?: number, sendNotification?: boolean, manualAssignSerialList?: string[]) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.voucher.ClaimVoucher,
      hostPath: environment.hostPath,
      body:{
        vchTypId : vchTypId,
        storeId: storeId? storeId: undefined,
        channelId: channelId? channelId: undefined,
        sendNotification: sendNotification !== null? sendNotification: undefined,
        manualAssignSerialList : manualAssignSerialList
      },
      headers: {
        accessToken: accessToken,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetCustomerClaimedVoucher(accessToken: string, latitude: number, longitude: number, channelId: number, skip: number, take: number, searchText?: string, orderTypeFlag?: string, vchCatgId?: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetCustomerClaimedVoucher,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        Latitude: latitude,
        Longitude: longitude,
        SearchText: searchText? searchText: undefined,
        ChannelId: channelId,
        Skip: skip,
        Take: take,
        orderTypeFlag: orderTypeFlag? orderTypeFlag: undefined,
        vchCatgId: vchCatgId? vchCatgId: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetApplicableVoucher(accessToken: string, storeId: number, channelId: number, membershipCode : string, membershipNo : string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetApplicableVoucher,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        StoreId: storeId,
        ChannelId: channelId,
        MembershipCode: membershipCode ? membershipCode : undefined,
        MembershipNo: membershipNo ? membershipNo : undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetCustomerVoucherHistory(accessToken: string, skip: number, take: number, searchText?: string, voucherStatus?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.GetCustomerVoucherHistory,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        SearchText: searchText? searchText: undefined,
        Skip: skip,
        Take: take,
        VoucherStatus: voucherStatus? voucherStatus: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqRedeemmembershipvoucher(accessToken: string, redeemId: number, channelId?: number, membershipCode?: string, vchTypCode? : string, pointAmount? : number) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.voucher.Redeemmembershipvoucher,
      hostPath: environment.hostPath,
      body:{
        redeemId: redeemId,
        channelId: channelId? channelId: undefined,
        membershipCode: membershipCode,
        vchTypCode: vchTypCode,
        pointAmount: pointAmount
      },
      headers: {
        accessToken: accessToken,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isOriBody?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, false, isOriBody).then((dd: any) => dd);
  }
}
