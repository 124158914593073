import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PopupMessage } from '../models/PopupMessage';

@Injectable({
  providedIn: 'root'
})
export class PopupMessageService {
  popupMsg = new Subject<PopupMessage>();
  showPopupMsg = new Subject<boolean>();
  onHide = new Subject<string>();

  constructor() { }

  show(content: PopupMessage) {
    this.showPopupMsg.next(true);
    this.popupMsg.next(content);
  }

  hide() {
    this.showPopupMsg.next(false);
  }
}
