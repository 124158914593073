import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslatorService } from 'src/app/core/services/translator.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class LanguageComponent implements OnInit {

  @Input() isStoreLang : boolean = false;
  @Output() changeLanguage = new EventEmitter();

  public _currentLang: string;
  displayLanguage: boolean = false;
  isMobileLayout: boolean = false;
  width: number = window.innerWidth;
  mobileLayoutWidth: number = 991;

  constructor(
    private translatorService: TranslatorService,
  ) {
    this._currentLang = localStorage.getItem('lang');
  }

  ngOnInit(): void {
    this.isMobileLayout = this.width <= this.mobileLayoutWidth;
  }

  changeLang(language: string) {
    this.translatorService.useLanguage(language);
    this._currentLang = language;
    this.changeLanguage.emit();
  }

  showLangDialog() {
    this.displayLanguage = true;
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobileLayout = this.width < this.mobileLayoutWidth;
  }


}
