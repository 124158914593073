export enum ChannelStatusFlag {
    Active = 'Active',
    Suspended = 'Suspended',
    Closed = 'Closed'
}

export enum CurrentStatusFlag {
    Open = 'Open',
    Closed = 'Closed',
    OutofCoverage = 'OutofCoverage',
    Suspended = 'Suspended',
    TempClosed = 'TempClosed'
}

export enum ChannelCustomerStatusFlag {
    Active = 'Active',
    PendingApproval = 'PendingApproval',
    Rejected = 'Rejected',
    Quit = 'Quit',
    BlockedExpired = 'Blocked_Expired',
    Hidden = 'Hidden'
}

export enum VoucherStatusFlag {
    Active = 'Active',
    Blocked = 'Blocked',
    Used = 'Used',
    Cancelled = 'Cancelled',
    Expired = 'Expired'
}
