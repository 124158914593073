<div class="mobile-nav-footer">
  <nav class="nav">
    <!--home-->
    <h1 [routerLink]="['/home']" routerLinkActive="active" class="nav__link ">
      <i class="oda-d-home duotone nav__icon"></i>
      <span class="nav__text">{{'navigation.Menu.des.18' | translate}}</span>
    </h1>

    <!--reward-->
    <h1 [routerLink]="['/reward']" routerLinkActive="active" class="nav__link ">
      <i class="oda-d-gift duotone nav__icon"></i>
      <span class="nav__text">{{'navigation.Menu.des.7' | translate}}</span>
    </h1>

    <!--my order-->
    <h1 [routerLink]="['/my-order']" routerLinkActive="active" class="nav__link" [ngClass]="{ 'noti': orderCount }">
      <span class="count" *ngIf="orderCount < 9" [hidden]="orderCount == 0">{{ orderCount }}</span>
        <span class="count" *ngIf="orderCount >= 9">9+</span>
        <i class="oda-d-dish duotone nav__icon"></i>
        <span class="nav__text">{{'navigation.Menu.des.5' | translate}}</span>
    </h1>

    <!--profile-->
    <h1 [routerLink]="['/profiles']" routerLinkActive="active" class="nav__link">
      <i class="oda-d-user-setting duotone nav__icon"></i>
      <span class="nav__text">{{'navigation.Menu.des.4' | translate}}</span>
    </h1>
  </nav>
</div>
