import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-login-message',
  templateUrl: './login-message.component.html',
  styleUrls: ['./login-message.component.scss']
})
export class LoginMessageComponent implements OnInit {

  @Input() sessionExpired : boolean;
  @Input() channelId : number;

  @Output() navigateToLogin = new EventEmitter();
  @Output() continueAsGuest = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickLogin(){
    this.navigateToLogin.emit();
  }
}
