import { CountryISO } from 'ngx-intl-tel-input';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'telInputCountryCode'
})
export class TelInputCountryCodePipe implements PipeTransform {

  transform(countryCode: string) {
    let index = Object.values(CountryISO).indexOf(countryCode.toLowerCase() as unknown as CountryISO);
    let countryValues = "my";
    if(index != -1){
      countryValues = Object.values(CountryISO)[index];
    }

    return countryValues as CountryISO;
  }

}
