import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

import { OtpService } from 'src/app/account/services/otp.service';
import { ProfileService } from 'src/app/account/services/profile.service';
import { PageName, StoreMode } from 'src/app/core/enums';
import { ChannelData } from 'src/app/core/models';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reset-mobile-otp',
  templateUrl: './reset-mobile-otp.component.html',
  styleUrls: ['./reset-mobile-otp.component.scss']
})
export class ResetMobileOtpComponent implements OnInit, AfterViewInit {
  @ViewChild('otpArray', {static: false}) otpArrayEl: ElementRef;
  @ViewChild('form') formEl: NgForm;

  user$: Subscription;
  otp$: Subscription;
  otpInfo$: Subscription;

  channelData: ChannelData;
  curUser: any;
  verifiedEmail: string;
  verifiedPhoneNo: string;
  resetMobileForm: UntypedFormGroup;
  otpArr: UntypedFormArray;
  otpLength: number = 6;
  curOtpVal: string;
  timeInSec: BehaviorSubject<number> = new BehaviorSubject<number>(environment.optResendInSec);
  numberOfTimeResend: number = environment.optMaxFailBypass;
  otp: string = "";
  phoneNo: string = "";
  prefix: string = "";
  pathName: string = "";
  pageName: PageName;

  invalidOtpErr: boolean = false;
  showTimer: boolean = true;
  showResend: boolean = false;
  showResetSuccess: boolean = true;
  isMobile: boolean = false;
  isLoggedIn: boolean = false;
  isSubDomain: boolean = false;

  constructor(private fb: UntypedFormBuilder,
    private otpService: OtpService,
    private profileService: ProfileService,
    private userService: UserService,
    private router: Router,
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private channelService: ChannelService,
    private userchnlQry: UserChnlQuery) { }

  ngOnInit(): void {
    this.user$ = this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUser = dt ? dt : null;
      this.isLoggedIn = dt && dt.id != 999 ? true : false;
      this.verifiedEmail = dt && dt.email && dt.isEmailVerified? dt.email: '';
      this.verifiedPhoneNo = dt && dt.mobileNo && dt.isMobileVerified? dt.mobileNo: '';
    });

    this.route.queryParams.subscribe((param) => {
      this.phoneNo = param['phoneNo']? param['phoneNo']: this.phoneNo;
      this.prefix = param['prefix']? param['prefix']: this.prefix;
      this.otp = param['otp']? param['otp']: this.otp;
    });
    
    this.otpInfo$ = this.profileService.getOtpInfo().subscribe((dt: any) => {
      this.phoneNo = dt && dt.mobileNo? dt.mobileNo: this.phoneNo;
      this.prefix = dt && dt.prefix? dt.prefix: this.prefix;
      this.pageName = dt && dt.pageName? dt.pageName: '';
    });

    if(!this.isLoggedIn) {
      this.router.navigate(['/login']);
    }

    this.channelData = this.channelService.getChannelData();
    this.isMobile = window.innerWidth <= 991 ? true: false;
    this.pathName = this.router.url;
    this.isSubDomain = this.userchnlQry.getValue().isSubDomain;

    this.otpArr = this.fb.array([
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
      this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    ], [Validators.required, Validators.maxLength(this.otpLength)]);
  
    this.resetMobileForm = this.fb.group({
      otp: this.otpArr,
    });

    this.otp$ = this.otpArr.valueChanges.subscribe((dt) => {
      this.curOtpVal = dt.join('');
      let otpLength = this.curOtpVal.length;

      if(this.otp === this.curOtpVal && otpLength === 6) {
        let submitBtnEl =  document.getElementById("btn-submit");
        setTimeout(() => { if(submitBtnEl){ submitBtnEl.click(); } }, 1000);
      }
    });
  }

  ngAfterViewInit(): void {
    this.focusFirstInput(); 
    
    if(this.phoneNo && this.otp) {
      this.pasteOtp(this.otp);
    }
  }

  get fg() { return this.resetMobileForm; }

  get f() { return this.fg.controls; }

  focusFirstInput() {
    if(document.getElementById('otpInput-0')) {
      document.getElementById('otpInput-0').focus();
    }
  }

  clearOtpInput() {
    this.fg.controls['otp'].reset();
    document.getElementById('otpInput-0').focus();
  }

  onOtpInput(event: any, index: number) {
    const nextInputEl = document.getElementById('otpInput-'+ (index+1)) as HTMLInputElement;

    if(event.target.value && event.target.value.length === 1 && nextInputEl) {
      nextInputEl.focus();
    }

    if(event.target.value && event.target.value.length > 1) {
      const inputVal = event.target.value.replace(' ', '').split('');
      const lastInputInd = inputVal.length >= this.otpArr.controls.length? this.otpArr.controls.length - 1: inputVal.length;
      event.preventDefault();

      this.otpArr.controls.forEach((input, index) => {
        let data = inputVal[index]? inputVal[index]: '';
        input.setValue(data);
      });

      //focus on the last input
      document.getElementById("otpInput-" + (lastInputInd)).focus();
    }
  }

  onOtpKeydown(event: any, index: number) {
    let inputEl = document.getElementById('otpInput-'+ index) as HTMLInputElement;
    const prevInputEl = document.getElementById('otpInput-'+ (index-1)) as HTMLInputElement;
    const nextInputEl = document.getElementById('otpInput-'+ (index+1)) as HTMLInputElement;

    // arrow keycode 37-40
    if(inputEl && event.key !== 'Backspace' && event.key !== 'Enter' && !(event.keyCode >= 37 && event.keyCode <= 40)) {
      inputEl.value = "";
    } 
    
    if(prevInputEl && !inputEl.value && event.key === 'Backspace'){
      prevInputEl.focus();
    }
    
    if(prevInputEl && event.key === 'ArrowLeft'){
      prevInputEl.focus();
      this.setCursorOnEnd(prevInputEl);
    }

    if(nextInputEl && event.key === 'ArrowRight'){
      nextInputEl.focus();
    }
  }

  onOtpFocus(event: any, index: number){
    const inputEl = document.getElementById('otpInput-'+ index) as HTMLInputElement;
    this.setCursorOnEnd(inputEl);
  }

  private setCursorOnEnd(inputEl: HTMLInputElement) {
    if(inputEl){
      let inputLength = inputEl.value.length * 2;
      setTimeout(() => { inputEl.setSelectionRange(inputLength, inputLength); }, 0);
    }
  }

  private pasteOtp(otpInString: string) {
    const inputVal = otpInString.replace(' ', '').split('');
    const lastInputInd = inputVal.length >= this.otpArr.controls.length? this.otpArr.controls.length - 1: inputVal.length;

    this.otpArr.controls.forEach((input, index) => {
      let data = inputVal[index]? inputVal[index]: '';
      input.setValue(data);
    });

    //focus on the last input
    document.getElementById("otpInput-" + (lastInputInd)).focus();
  }

  onTimerCallback() {
    this.showTimer = false;
    this.numberOfTimeResend--;
    this.showResend = true;
  }

  async onSendOtp(sendEmail?: boolean) {
    // show timer
    this.timeInSec.next(environment.optResendInSec);
    this.showTimer = true;

    // resend otp
    this.showResend = false;
    let emailRedirectLink = null;
    let url = window.location.origin;

    if(this.phoneNo && sendEmail) {
      let channelTag: string = this.isSubDomain? '': this.channelData?.channelTag? '/' + this.channelData.channelTag: '/' + environment.odaringChannel;
      emailRedirectLink = url + channelTag + '/account/profile/reset-phone-no';
    }

    if(this.phoneNo) {
      let otpPrefix = this.otpService.createOtpPrefix();
      await this.profileService.onSendOTP(this.phoneNo, otpPrefix, emailRedirectLink, sendEmail);
    }

    this.clearOtpInput();
  }

  async onSubmit() {
    this.invalidOtpErr = false;

    if (this.fg.invalid) {
      this.invalidOtpErr = true;
      this.clearOtpInput();
      return;
    }

    let otpCode = this.prefix + this.fg.value.otp.join('');
    let otpResult = null;
    
    otpResult = await this.profileService.onVerifyUpdateMobileNo(this.phoneNo, otpCode);

    if(!(otpResult instanceof HttpErrorResponse) && this.isMobile) {
      this.popupMessageService.show({
        icon: "oda-check-alt",
        iconColor: "green",
        desc: "alert.editProfile.des.1",
        btn: "button.ok",
        showMobileOnly: true
      });
    }

    if(!(otpResult instanceof HttpErrorResponse)) {
      this.router.navigateByUrl("/account/profile");
    } else {
      this.invalidOtpErr = true;
      this.clearOtpInput();
    }
  }

  ngOnDestroy() {
    this.user$.unsubscribe();
    this.otp$?.unsubscribe();
    this.otpInfo$?.unsubscribe();
  }
}
