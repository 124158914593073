import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { QrMergeMessageComponent } from './qr-merge-message/qr-merge-message.component';
import { AccountModule } from '../account/account.module';
import { CartStoreBindPipe } from './pipe/cart-store-bind.pipe';
import { MembershipModule } from 'src/app/membership/membership.module';

@NgModule({
  declarations: [CartComponent, QrMergeMessageComponent, CartStoreBindPipe],
  imports: [
    CommonModule,
    CartRoutingModule,
    TranslateModule,
    SharedModule,
    AccountModule,
    MembershipModule
  ]
})
export class CartModule { }
