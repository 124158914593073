import { Pipe, PipeTransform } from '@angular/core';
import { AttrCode } from 'src/app/core/enums/AttrCode';
import { StoreAttrResponse } from 'src/app/core/models/StoreAttrResponse';
import { StoreResponse } from 'src/app/core/models/StoreResponse';

@Pipe({
  name: 'storeImage'
})
export class StoreImagePipe implements PipeTransform {

  transform(storeResponse: StoreResponse, passAttrCode: string, isMultiple : boolean = false) {
    let storeAttr: StoreAttrResponse[] = [];
    let imageUrl: string[] = [];

    if (storeResponse.storeAttrs.length > 0) {
      storeAttr = storeResponse.storeAttrs.filter(attr => {
        let stringWithoutNumber = attr.attrCode.split(/(\d+)/)[0];
        if(stringWithoutNumber == passAttrCode){
          return true;
        }
        return false;
      });

      if (storeAttr.length > 1) {
        for(let image of storeAttr){
          imageUrl.push(image.attrData);
        }
      } else if(storeAttr.length == 1){
        imageUrl.push(storeAttr[0].attrData);
      } else{
        if(passAttrCode == AttrCode.BANNERIMG){
          let outletImg = storeResponse.storeAttrs.find(val => val.attrCode == AttrCode.OUTLETIMG);
          let currentImage = outletImg ? outletImg.attrData : 'assets/image-unavailable-banner.svg';
          imageUrl.push(currentImage);
        } else if(passAttrCode == AttrCode.BIGBANNERIMG) {
          imageUrl = [];
        } else{
          imageUrl.push('assets/image-unavailable.svg');
        }

      }
    } else {
      imageUrl.push(passAttrCode === AttrCode.OUTLETIMG ? 'assets/image-unavailable.svg' : 'assets/image-unavailable-banner.svg');
    }

    if(isMultiple){
      return imageUrl;
    }
    else{
      return imageUrl[0];
    }

  }

}
