<div class="reset-password-container" [ngClass]="{ 'resetPw': pageName === pageNames.ResetPasswordPage }">
  <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
    <div class="input-field" *ngIf="pageName === pageNames.ResetPasswordPage">
      <h1 class="heading-11">{{ 'setup.password.input.current.password.title' | translate }}<span class="mandatory">*</span></h1>
      <span class="p-float-label">
      <input pPassword type="password" [type]="showCurrPw ? 'password' : 'text'" formControlName="currPassword" class="form-control" placeholder="{{ 'input.placeholder.1' | translate }}" [feedback]="false"/>
      <button type="button" (click)="showCurrPw = !showCurrPw" class="resetPassForm-btn-hide" *ngIf="f.currPassword.value.length > 0">
        <i class="{{showCurrPw ? 'oda-eye-shut' : 'oda-eye-open'}}" alt="hideShowIcon"></i>
      </button>
      <div *ngIf="submitted" class="error">
        <div *ngIf="errorCode === errorCodes.PasswordMisMatch_400">
          <i class="oda-alert"></i>
          {{ 'login.form.password.invalid' | translate }}
        </div>
      </div>
      </span>
    </div>


    <div class="input-field">
      <h1 class="heading-11">{{ 'input.label.3' | translate }}<span class="mandatory">*</span></h1>
      <span class="p-float-label">
        <input pPassword type="password" [type]="showNewPw ? 'password' : 'text'" formControlName="password" class="form-control" placeholder="{{ 'input.placeholder.1' | translate }}" 
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [feedback]="false" #newpasswordvalue />
        <button type="button" (click)="showNewPw = !showNewPw" class="resetPassForm-btn-hide" *ngIf="f.password.value.length > 0">
          <i class="{{showNewPw ? 'oda-eye-shut' : 'oda-eye-open'}}" alt="hideShowIcon"></i>
        </button>
        <div *ngIf="newpasswordvalue.value && newpasswordvalue.value.length > 0" class="heading-12 password-lengthValidation">
          <i class="oda-check-alt" [ngClass]="{ 'active': newpasswordvalue.value.length >= 8 }"></i>
          <div [innerHtml]="'register.form.password.length.validation' | translate"> </div>
        </div>
      </span>
    </div>

    <div class="input-field">
      <h1 class="heading-11">{{ 'input.label.4' | translate }}<span class="mandatory">*</span></h1>
      <span class="p-float-label">
        <input pPassword [type]="showConfirmPw ? 'password' : 'text'" formControlName="confirmPassword" class="form-control" placeholder="{{ 'input.placeholder.1' | translate }}"
           [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" [feedback]="false"  />
        <button type="button" (click)="showConfirmPw = !showConfirmPw" class="resetPassForm-btn-hide" *ngIf="f.confirmPassword.value.length > 0">
          <i class="{{showConfirmPw ? 'oda-eye-shut' : 'oda-eye-open'}}" alt="hideShowIcon"></i>
        </button>
        <div *ngIf="submitted && f.confirmPassword.errors" class="error">
          <div *ngIf="f.confirmPassword.errors.mustMatch">
            <i class="oda-alert"></i>
            {{ 'forgot.password.not.match.error' | translate }}
          </div>
        </div>
      </span>
    </div>

    <div class="btn-container">
      <button class="resetPassForm-btn-cta" [disabled]="f.password.errors || f.confirmPassword.errors?.required || f.currPassword?.errors?.required">{{ pageName === pageNames.ForgotPasswordPage? ('button.reset' | translate) : ('dinein.qr.change.label' | translate) }}</button>
    </div>
  </form>
</div>
