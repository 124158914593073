export enum ActivityType {
    Order_submitted = 'Order_submitted',
    Order_confirmed = 'Order_confirmed',
    Order_received = 'Order_received',
    Rider_assigned = 'Rider_assigned',
    Preparing_order = 'Preparing_order',
    Order_ready = 'Order_ready',
    Delivering_order = 'Delivering_order',
    Order_completed = 'Order_completed',
    Payment_failed = 'Payment_failed',
    Order_cancelled = 'Order_cancelled',
    Cancel_order_requested = 'Cancel_order_requested',
    Cancel_failed = 'Cancel_failed',
    Store_cancel_requested = 'Store_cancel_requested',
    Store_cancelled = 'Store_cancelled',
    Store_cancel_failed = 'Store_cancel_failed',
    Assign_failed = 'Assign_failed',
    Pickup_failed = 'Pickup_failed',
    Delivery_failed = 'Delivery_failed',
    Refund_requested = 'Refund_requested',
    Order_refunded = 'Order_refunded',
    Refund_failed = 'Refund_failed',
    PendingManualRefund = "Pending Manual Refund",
    Queuing = 'Queuing'
}
