import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ChannelService } from '../home/channel/channel.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { Subscription } from 'rxjs';
import { ProfileService } from './services/profile.service';
import { HttpErrorResponse } from '@angular/common/http';
import { PopupMessageService } from '../core/services/popup-message.service';
import { UserService } from '../core/user/user.service';
import { PageName } from '../core/enums';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = undefined;
  isMobile: boolean = false;
  mobileNo: string;
  user$: Subscription;
  setupPw$: Subscription;
  resetPw$: Subscription;
  pageNames: any = PageName;

  profileNav_routeLinkList: any = [
    {
      desc: "navigation.Menu.des.1",
      icon: "oda-d-edit duotone",
      pageName: "",
      nav: ["/account/profile"],
    },
    {
      desc: "navigation.Menu.des.2",
      icon: "oda-pin",
      pageName: "MngAddr",
      nav: ["/account/address"]
    },
    {
      desc: "navigation.Menu.des.3",
      icon: "oda-wallet",
      pageName: "CardDetail",
      nav: ["/account/payment-method"]
    },
    {
      desc: "setup.password.title",
      icon: "oda-pass-lock",
      pageName: this.pageNames.SetupPasswordPage,
      hidden: true,
      nav: ["/account/setup-password"],
    },
    {
      desc: "change.password.header",
      icon: "oda-pass-lock",
      pageName: this.pageNames.ResetPasswordPage,
      hidden: true,
      nav: ["/account/change-password"]
    }
  ]

  constructor(
    private channelService: ChannelService,
    private userQuery: UserQuery,
    private profileService: ProfileService,
    private popupMessageService: PopupMessageService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getScreenSize();

    await this.channelService.getChannelDataCheck();

    this.user$ = this.userQuery.isLoggedIn$.subscribe( (userData: any) => {
      this.isLoggedIn = userData && userData.id != 999 ? true : false;
      this.mobileNo = userData && userData.mobileNo? userData.mobileNo: userData && userData.mobileNumber? userData.mobileNumber: "";
    });

    if(this.isLoggedIn) {
      this.checkHavePassword();
    }

    this.setupPw$ = this.userService.setupPw.subscribe(async (newPassword: string) => {
      let resp: any = await this.profileService.onUpdatePassword("", newPassword);

      if(resp instanceof(HttpErrorResponse)) {
        this.popupMessageService.show({
          icon: "oda-alert-alt",
          iconColor: "red",
          desc: "setup.password.unsuccessful.desc",
          btn: "button.ok",
          redirectLink: "/account/profile"
        });
      } else {
        this.checkHavePassword();
        this.popupMessageService.show({
          icon: "oda-check-alt",
          iconColor: 'green',
          desc: "setup.password.successful.desc",
          btn: "button.ok",
          redirectLink: this.isMobile? "/profiles": "/account/profile"
        });
      }
    });
  }

  async checkHavePassword() {
    let setupPwInd: number = this.profileNav_routeLinkList.findIndex((list: any) => list.pageName === this.pageNames.SetupPasswordPage);
    let resetPwInd: number = this.profileNav_routeLinkList.findIndex((list: any) => list.pageName === this.pageNames.ResetPasswordPage);
    let resp: any = await this.userService.getCustomerProfileStatus(this.mobileNo, "");

    if(!(resp instanceof HttpErrorResponse)) {
      if(setupPwInd >= 0) {
        this.profileNav_routeLinkList[setupPwInd].hidden = resp.passwordFlag;
      }

      if(resetPwInd >= 0) {
        this.profileNav_routeLinkList[resetPwInd].hidden = !resp.passwordFlag;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  ngOnDestroy(): void {
    this.setupPw$?.unsubscribe();
    this.resetPw$?.unsubscribe();
  }
}
