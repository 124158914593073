<div class="quit-member-container">
  <img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot"/>
  <h1>{{ 'sad.to.see.you.quit.desc' | translate }}</h1>
  <p class="desc" [innerHtml]="'quit.type.below.desc.1' | translate: { quitText: '<span style=\'font-weight: bold; text-transform: lowercase;\'>' + quitText + '</span>' } | safeHtml"></p>
  <div class="input-field">
      <input type="text" [placeholder]="quitText" [(ngModel)]="quitInput" id="quitText" (keyup.enter)="onQuitMember()" autocomplete="off" />
      <i class="oda-times btn-clear" *ngIf="quitInput" (click)="quitInput = ''"></i>
  </div>
  <div class="button-container">
      <button type="button" class="btn-quit" [disabled]="quitInput !== quitText" (click)="onQuitMember()">{{ 'button.quit.membership.desc' | translate }}</button>
      <button type="button" class="btn-close" (click)="onCancel()">{{ 'button.cancel' | translate }}</button>
  </div>
</div>
