import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';
import { StoreQuery } from 'src/app/store/store/store.query';
import * as _ from 'lodash';
import * as clone from 'rfdc';
import { OrderH } from 'src/app/core/models/OrderH';

@Component({
  selector: 'app-store-item-content',
  templateUrl: './store-item-content.component.html',
  styleUrls: ['./store-item-content.component.scss']
})
export class StoreItemContentComponent implements OnInit, OnDestroy {

  @Input() storeId : number;
  @Input() currencyCode : string;
  @Input() isSubModifier: boolean = false;
  @Input() isMobileView : boolean;
  @Input() tempSelectedQty : number;
  @Input() curStoreCart: OrderH = null;

  // setter for modifierGrps input
  @Input() set modifierGrps(modifierGroups : ModifierGrp[]){
    this.curItemGrps = modifierGroups;
  }

  // getter for modifierGrps input
  get modifierGrps() : ModifierGrp[]{
    return this.curItemGrps;
  }

  @Input() set submodifierGrps(subModifierGroups : SubModifierGrp[]){
    this.curSubItemGrps = subModifierGroups;
  }

  get submodifierGrps() : SubModifierGrp[]{
    return this.curSubItemGrps;
  }

  @Output() notifyBaseDisplayMaximum = new EventEmitter();

  curItemGrps : any;
  curSubItemGrps : SubModifierGrp[];

  emptyTempArray = []

  constructor(
    private storeQuery : StoreQuery
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(){
  }

  displayMaximumMessage(){
    this.notifyBaseDisplayMaximum.emit();
  }

  trackByFn(index, item) {
    return index;
  }

}
