
<div class="back-btn hidden-lg" [hidden]="hideCloseBtn">
  <i class="oda-chevron-left" (click)="closeQrDialog()"></i>
</div>

<div class="back-btn-desktop hidden-sm">
  <i class="oda-chevron-left btn-back" (click)="closeQrDialog()"></i>
  <h1 (click)="closeQrDialog()">{{ "button.back" | translate }}</h1>
</div>

<div class="user-qr-container">
  <div class="content" [hidden]="displayQR || displaybarCode">
    <h1 class="heading-17 p-mb-2" *ngIf="customerNo && encryptedCustID">{{'qr.scan.des.1' | translate}}</h1>

    <div (click)="showBarCodeDialog()" *ngIf="customerNo">
      <ejs-barcodegenerator #barcode id="barcode" width="{{ isMobile? '272px':'584px' }}" height="{{ isMobile? '80px':'155px'}}"
        mode="SVG" type="Code128" value="{{customerNo}}"></ejs-barcodegenerator>
    </div>

    <h1 class="heading-17 hidden-lg" [ngStyle]="{'padding-top': isMobile ? '58px':'86px'}">{{'qr.scan.des.2' | translate}}</h1>

    <div (click)="showQRDialog()">
      <ejs-qrcodegenerator #qrcode id="qrcode" width="{{ isMobile? '160px':'350px' }}" height="{{ isMobile? '160px':'350px' }}"
        mode="SVG" value="{{encryptedCustID}}"></ejs-qrcodegenerator>
    </div>
  </div>
</div>

<p-dialog header=" " [(visible)]="displayQR" *ngIf="displayQR" [blockScroll]="true" class="enlarge-QR-image">
  <div class="back-btn">
    <i class="oda-chevron-left" (click)="this.displayQR = false; this.enlarging.emit(false);"></i>
  </div>
  <ejs-qrcodegenerator #qrcode id="qrcode" width="200px" height="150px"
    mode="SVG" value="{{encryptedCustID}}"></ejs-qrcodegenerator>
</p-dialog>

<p-dialog header=" " [(visible)]="displaybarCode" *ngIf="displaybarCode" [blockScroll]="true" class="enlarge-QR-image">
  <div class="back-btn">
    <i class="oda-chevron-left" (click)="this.displaybarCode = false; this.enlarging.emit(false);"></i>
  </div>
  <div class="QR-image-rotate">
    <ejs-barcodegenerator #barcode id="barcode" width="300px" height="150px"
      mode="SVG" type="Code128" value="{{customerNo}}"></ejs-barcodegenerator>
  </div>
</p-dialog>
