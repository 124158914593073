import { Pipe, PipeTransform } from '@angular/core';
import { MenuTags } from 'src/app/core/enums/MenuTags';

@Pipe({
  name: 'menutagIcon'
})
export class MenutagIconPipe implements PipeTransform {

  transform(value: string): string {
    switch(value){
      case MenuTags.ChefChoice:
        return "oda-chef";
      case MenuTags.HotSelling:
        return 'oda-fire-alt'
      default:
        return null;
    }
  }

}
