<div class="store_order_time_top_container">

  <i class="oda-chevron-left hidden-lg" (click)="closeDialog()"></i>

  <div class="mobile-top-title hidden-lg">
    <span>{{"order.type" | translate}}</span>
  </div>

  <div class="order-type-container">
    <div class="order-type-flex">
      <!-- delivery tab button -->
      <div class="tab_cursor" (click)="changeOrderType(orderTypeFlag.Delivery)" *ngIf="storeResponse.deliveryFlag">
        <mark [ngClass]="{'current_order_type' : todayTime.orderType == orderTypeFlag.Delivery && storeResponse.deliveryFlag,
        'not_current_order_type' : todayTime.orderType != orderTypeFlag.Delivery}">
          <label class="tab_cursor" [ngClass]="{'selected-tab': todayTime.orderType == orderTypeFlag.Delivery}">
            {{"order.type.select.delivery" | translate}}
          </label>
        </mark>
      </div>

      <!-- pickup tab button -->
      <div (click)="changeOrderType(orderTypeFlag.Pickup)" class="tab_cursor" *ngIf="storeResponse.pickupFlag">
        <mark [ngClass]="{'current_order_type' : todayTime.orderType == orderTypeFlag.Pickup,
        'not_current_order_type' : todayTime.orderType != orderTypeFlag.Pickup}">
          <label class="tab_cursor" [ngClass]="{'selected-tab': todayTime.orderType == orderTypeFlag.Pickup}">
            {{"order.type.select.pick.up" | translate}}
          </label>
        </mark>
      </div>

      <!-- dine in tab button -->
      <div (click)="changeOrderType(orderTypeFlag.DineIn)" class="tab_cursor" *ngIf="storeResponse.dineFlag">
        <mark [ngClass]="{'current_order_type' : todayTime.orderType == orderTypeFlag.DineIn,
        'not_current_order_type' : todayTime.orderType != orderTypeFlag.DineIn}">
          <label class="tab_cursor" [ngClass]="{'selected-tab': todayTime.orderType == orderTypeFlag.DineIn}">
            {{"order.type.select.dine.in" | translate}}
          </label>
        </mark>
      </div>
    </div>
  </div>

  <i class="oda-times close_icon hidden-sm" (click)="closeDialog()"></i>
</div>

<hr class="top-title-separator hidden-sm"/>

<div class="store_order_time_content_container">
  <div class="content_top_container" *ngIf="todayTime != null && todayTime.timeInterval.length != 0">
    <div class="p-d-flex content_title_container">
      <i class="oda-calendar" style="color: var(--iconTextButton-primary); font-size: 15px;"></i>
      <div class="title_font_style"><label>{{"order.date.time" | translate}}</label></div>
    </div>

    <div class="day-content-container" #listContainer>
      <div class="p-d-flex day_outer_container" id="list" *ngIf="currentOrderDateSlot">
        <div class="p-d-flex p-flex-column day_container" *ngFor="let day of currentOrderDateSlot" (click)="changeDate(day)" [ngClass]="{'current_day_tab' : day.date == todayTime.date}">
          <div class="weekday" [ngClass]="{'current_day_font' : day.date == todayTime.date}" *ngIf="day.isToday != true">{{day.date | date:'EEE'}}</div>
          <div class="weekday" [ngClass]="{'current_day_font' : day.date == todayTime.date}" *ngIf="day.isToday == true">{{"order.info.delivery.status.des.2" | translate}}</div>
          <div class="day_of_month" [ngClass]="{'current_day_font' : day.date == todayTime.date}">{{day.date | date:'d'}}</div>
        </div>
      </div>

      <button class="scroller scroll-left hidden-sm" *ngIf="isOverflow && scrollLeft" (click)="scroll(listContainer, -3)">
        <i class="oda-chevron-left"></i>
      </button>

      <button class="scroller scroll-right hidden-sm" *ngIf="isOverflow && scrollRight" (click)="scroll(listContainer, 3)">
        <i class="oda-chevron-right"></i>
      </button>
    </div>
  </div>

  <div class="p-d-flex p-flex-wrap time_overflow_container" *ngIf="todayTime != null && todayTime.timeInterval.length > 0">
    <div class="time_container" [ngClass]="{'current_time' : todayTime?.chosenTime?.label == time.label}" *ngFor="let time of todayTime.timeInterval" (click)="changeTime(time)">
      <span *ngIf="time.label != 'ASAP'">{{time.label}}</span>
      <span *ngIf="time.label == 'ASAP'">{{"merchant.delivery.time.asap" | translate}}</span>
    </div>

    <div class="temp-time-container" *ngIf="todayTime?.timeInterval?.length % 2 > 0"></div>
  </div>

  <div class="schedule-unavailable-container" *ngIf="todayTime != null && todayTime.timeInterval.length == 0">
    <!-- delivery order type mascot -->
    <span class="mascot-container" *ngIf="todayTime.orderType == orderTypeFlag.Delivery && (channelId | isShowMascot)">
      <img src="assets/mascot/Mascot_delivery-default-Grey@2x.webp" alt="odaring mascot">
    </span>

    <!-- pickup order type mascot -->
    <span class="mascot-container" *ngIf="todayTime.orderType == orderTypeFlag.Pickup && (channelId | isShowMascot)">
      <img src="assets/mascot/Mascot_pick-up-default-Grey@2x.webp" alt="odaring mascot"/>
    </span>


    <!-- dine in mascot when store is not closed-->
    <ng-container *ngIf="todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus != statusFlag.Closed">
      <!-- if table number existed then show QR Code else normal checking-->
      <ng-container *ngIf="tableNo; else nonQrCode">
        <div class="qr-code-container" (click)="onClickQrCode()">
          <ejs-qrcodegenerator [width]="isMobileView ? '180px' : '195px'" [height]="isMobileView ? '180px' : '195px'"
                #barcode id="barcode" mode="SVG"
                value="{{currentStaticUrl}}"></ejs-qrcodegenerator>
        </div>
      </ng-container>

      <ng-template #nonQrCode>
        <span class="dine-in-mascot-container" *ngIf="channelId | isShowMascot">
          <img src="assets/mascot/Mascot_dine-in-window@2x.webp" *ngIf="!isTableReq || (isTableReq && isTableReq?.setValue == '0')" alt="odaring mascot" />
          <img src="assets/mascot/Mascot_scan-qr-dine-in@2x.webp" *ngIf="isTableReq && isTableReq?.setValue == '1'" alt="odaring mascot">
        </span>
      </ng-template>

    </ng-container>

    <!-- dine in mascot when store is closed -->
    <span class="dine-in-mascot-container" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus == statusFlag.Closed" [hidden]="channelId | isShowMascot">
      <img src="assets/mascot/Mascot_dine-in-window-Grey@2x.webp" alt="odaring mascot">
    </span>

    <!-- delivery order type message for when delivery schedule is empty -->
    <p class="unavailable-description" *ngIf="todayTime.orderType == orderTypeFlag.Delivery">{{"merchant.store.delivery.unavaible" | translate}}</p>

    <!-- pickup order type message for when delivery schedule is empty -->
    <p class="unavailable-description" *ngIf="todayTime.orderType == orderTypeFlag.Pickup">{{"merchant.store.pickup.unavailable" | translate}}</p>

    <!-- table number without table label given -->
    <h3 class="table-no-desc" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && !tableLabel && (tableNo || (orderH && orderH.orderData.tableNo))">
      {{"merchant.qr.dinein.table.no" | translate: { tableNo : orderH && orderH?.orderData?.tableNo ? orderH.orderData.tableNo : tableNo } }}
    </h3>

    <!-- table number with table label given -->
    <h3 class="table-no-desc" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && tableLabel && (tableNo || (orderH && orderH.orderData.tableNo))">
      {{ tableLabel + ' ' + (orderH && orderH?.orderData?.tableNo ? orderH.orderData.tableNo : tableNo)}}
    </h3>

    <!-- dine in description when store is open-->
    <p class="unavailable-description" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus != statusFlag.Closed &&
    ((isTableReq && isTableReq?.setValue == '1' && tableNo) || !isTableReq || (isTableReq && isTableReq?.setValue == '0'))">
      {{"ordertype.dinein.desc.1" | translate}}
    </p>

    <!-- dine in description when store is open but no table number -->
    <p class="unavailable-description" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus != statusFlag.Closed &&
    isTableReq && isTableReq?.setValue == '1' && !tableNo">
      {{"qr.no.table.number.desc" | translate}}
    </p>

    <!-- dine in unavailable description when store is closed -->
    <p class="unavailable-description" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus == statusFlag.Closed">
      {{"store.dine.in.close" | translate}}
    </p>

    <!-- change button for scanning qr, e.g. 0 false 1 true -->
    <button class="scan-qr-button" *ngIf="todayTime.orderType == orderTypeFlag.DineIn && (tableNo || (orderH && orderH.orderData.tableNo))"
    (click)="onClickScanQr()">
      <i class="oda-scan"></i>
      <span class="scan-qr-button-desc">{{"dinein.qr.change.label" | translate}}</span>
    </button>
  </div>

</div>

<!-- line between schedule slot and footer buttons -->
<!-- if not dine in order type OR dine in order type but store is not closed -->
<hr style="margin-top:-8px; margin-bottom: 0;"
  *ngIf="(todayTime.orderType != orderTypeFlag.DineIn) ||
  (todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus != statusFlag.Closed)"/>

<!-- footer that holds button -->
<!-- if not dine in order type OR dine in order type but store is not closed -->
<div style="padding: 12px; background-color: #FFFFFF;" *ngIf="
  (todayTime.orderType != orderTypeFlag.DineIn) ||
  (todayTime.orderType == orderTypeFlag.DineIn && storeResponse?.currentStatus != statusFlag.Closed)">

  <!-- is not Dine in order type -->
  <!-- OR -->
  <!-- Dine in order type AND (not table req OR (table req AND (table no. OR orderH.tableNo))) -->
  <button
    [style.background-color]="(todayTime.timeInterval.length != 0 || todayTime.orderType == orderTypeFlag.DineIn) ? 'var(--iconTextButton-tertiary)' : '#FFFFFF'"
    [style.color]="(todayTime.timeInterval.length != 0 || todayTime.orderType == orderTypeFlag.DineIn) ? '#FFFFFF' : '#B1B1B1'"
    class="continue_btn"
    (click)="checkoutOfChooseTime()"
    *ngIf="(todayTime.orderType != orderTypeFlag.DineIn) ||
    (todayTime.orderType == orderTypeFlag.DineIn && ((!isTableReq) || ((isTableReq.setValue == '0') ||
    (isTableReq.setValue == '1' && (tableNo || (orderH && orderH.orderData.tableNo))))) )">
    {{"button.continue" | translate}}
  </button>

  <!-- store scan qr button -->
  <button class="scan-qr-footer-button"
  *ngIf="todayTime.orderType == orderTypeFlag.DineIn && !tableNo && isTableReq && isTableReq.setValue == '1' && !isCartPage" (click)="onClickScanQr()">
    {{"scan.qr.label" | translate}}
  </button>

  <!-- cart scan qr button -->
  <button class="scan-qr-footer-button"
  *ngIf="todayTime.orderType == orderTypeFlag.DineIn && !tableNo && orderH && !orderH.orderData.tableNo && isTableReq && isTableReq.setValue == '1' && isCartPage"
  (click)="onClickScanQr()">
    {{"scan.qr.label" | translate}}
  </button>
</div>

<!-- enlarged qr code popup -->
<div class="qr-code-dialog">
  <p-dialog header=" " [(visible)]="displayQrCodeEnlarge" *ngIf="displayQrCodeEnlarge">
    <div class="back-btn">
      <i class="oda-chevron-left" (click)="this.displayQrCodeEnlarge = false"></i>
    </div>
    <ejs-qrcodegenerator #qrcode id="qrcode" width="200px" height="150px" mode="SVG" value="{{currentStaticUrl}}"></ejs-qrcodegenerator>
  </p-dialog>
</div>


