import { trigger, AnimationTriggerMetadata, style, state, transition, animate } from '@angular/animations';

export const toastAnimation : AnimationTriggerMetadata = trigger('toast', [
  state('show', style({
    visibility: 'visible',
    transform: 'translate(0, 0)'
  })),
  state('bottom-center', style({
    transform: 'translateY(100vh)',
    visibility: 'hidden'
  })),
  transition('bottom-center => show', animate('300ms ease-in-out')),
  transition('show => bottom-center', animate('300ms ease-in-out')),
]);

