<button class="back-btn" (click)="closeScannerDialog()" [hidden]="enlargingDialog">
  <i class="oda-chevron-left"></i>
</button>

<div class="qr-scanner-container">

  <zxing-scanner [(device)]="currentDevice" (scanSuccess)="onScanResultClick($event)"
                 [formats]="formatsEnabled" (permissionResponse)="onHasPermission($event)"
                 (camerasFound)="onCamerasFound($event)"></zxing-scanner>

  <div class="content" [ngClass]="{'no-permission ': hasPermission === false }">
    <button class="uploadImage-btn" [hidden]="true">
      <i class="duotone oda-d-pictures"></i>
    </button>

    <h1 class="scan-msg" *ngIf="selectedChannel?.orderType === orderTypeFlag.DineIn && tableNoReq && showHeaderDesc">{{'qr.scan.get.location.des' | translate}}</h1>

    <div class="scan-n-qrCode-btn" [ngClass]="{'scan-btn-not-loggedIn ': isLoggedIn === false }" [ngStyle]="{'z-index': enlargingDialog ? 0 : 1}">
      <button (click)="displayScannerQR = false" [ngClass]="{'grey-out': displayScannerQR === true }" >
        <i class="oda-scan"></i>
        <h1>{{'button.qr.scan.qr.code.des' | translate}}</h1>
      </button>

      <button *ngIf="isLoggedIn === true" (click)="showQRDialog()" [ngClass]="{'grey-out': displayScannerQR === false }">
        <i class="oda-qrcode"></i>
        <h1>{{'button.qr.scan.show.des' | translate}}</h1>
      </button>
    </div>
  </div>

  <div class="denied-permission-msg" *ngIf="hasPermission === false">
    <img src="assets/mascot/Mascot_sad-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot">
    <h2>{{'alert.camera.permission.denied.des.3' | translate}}</h2>
  </div>
</div>

<div class="scanner-qr-dialog">
  <p-dialog header="" [(visible)]="displayScannerQR" *ngIf="displayScannerQR">
    <app-user-qr-code  [hideCloseBtn]="true" (enlarging)="enlargingDialog = $event"></app-user-qr-code>
  </p-dialog>
</div>



