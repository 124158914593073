import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { UserService } from 'src/app/core/user/user.service';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { ProfileService } from '../services/profile.service';

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss']
})
export class SetupPasswordComponent implements OnInit, OnDestroy {
  setupPw$: Subscription;
  isQueue : boolean = false;

  constructor(private profileService: ProfileService,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private userService: UserService,
    private queueService : QueueService
    ) { }

  ngOnInit(): void {
    // check if queueData exists in session storage
    let queueData = this.queueService.getQueueData();
    this.isQueue = queueData ? true : false;

    this.setupPw$ = this.userService.setupPw.subscribe(async (newPassword: string) => {
      await this.profileService.onUpdatePassword("", newPassword);

      let route = this.previousRouteService.getPreviousRoute().routeName;
      this.previousRouteService.removePreviousRoute();
      this.router.navigateByUrl(route, {replaceUrl: true, state: {reinit: true}});
    });
  }

  ngOnDestroy(): void {
    this.setupPw$.unsubscribe();
  }

}
