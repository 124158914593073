<div class="nearby-store-container">
    <div class="store-content">
      <img [src]="storeList[0] | storeImage : attrCode.OUTLETIMG" alt="store image" loading="lazy">
      <div class="store-desc">
        <p class="item-storename">{{ storeList[0].merchantDesc }}</p>
        <p class="item-des">{{ getStoreAttrDesc(storeList[0].storeCatgAttrs) }}</p>
        <p class="other-store" *ngIf="storeList.length > 1">
          {{ 'item.card.number.stores.nearby' | translate: { count: storeList.length } }}
        </p>
      </div>
    </div>

    <hr>

    <div class="other-store-content-container">
      <div class="other-store-content" *ngFor="let otherStore of storeList">
        <div class="other-store-list" (click)="onNavigate(otherStore)" [ngClass]="{ 'disabled-store': otherStore.currentStatus !== storeStatusFlag.Open }">
          <div class="p-d-flex p-flex-wrap">
            <div class="item-storename">{{ otherStore.locDesc }}</div>
          </div>
          <div class="p-d-flex p-flex-wrap">
            <div class="p-mr-4">
              <div class="item-des-heading-1"><i class="oda-pin item-distance-icon"></i>
                {{"merchant.delivery.distance" | translate: { distance: otherStore.distance | number : '.1-1' } }}
              </div>
            </div>
            <div class="p-mr-4">
              <div class="item-des-heading-1">
                <i class="{{otherStore.currentOrderType | orderTypeIcon}} item-order-type-icon"></i>
                <ng-template [ngIf]="otherStore.deliveryTime">
                  {{ otherStore.deliveryTime > 1 ? 'store.delivery.minutes' : 'store.delivery.minute' | translate: { minute: otherStore.deliveryTime } }} -
                </ng-template>
                {{ otherStore.deliveryFee | currency: currency.currCode : 'symbol-narrow' }}
              </div>
            </div>
            <div *ngIf="otherStore.ratingAverage !== 0">
              <div class="item-des-heading-1"><i class="oda-star item-rate-icon"></i>
                {{ otherStore.ratingAverage | number : '1.1' }}
              </div>
            </div>
          </div>
        </div>
        <div class="order-later-container" *ngIf="otherStore.currentStatus !== storeStatusFlag.Open">
          <div class="p-d-flex p-flex-wrap">
            <div class="order-later"><i class="oda-clock"></i>
              <label *ngIf="otherStore.currentStatus === storeStatusFlag.Closed && otherStore.currentOrderType != orderTypeFlag.DineIn
              && otherStore.storeOHSchedules && ((otherStore.storeOHSchedules?.delivery && otherStore.storeOHSchedules?.delivery?.length > 0)
              || (otherStore.storeOHSchedules?.pickup && otherStore.storeOHSchedules?.pickup?.length > 0))">
                {{"pre.order.from" | translate}}
                <span>{{otherStore.currentStatus | nextAvailableTime : otherStore}}</span>
              </label>
              <label *ngIf="(otherStore.currentStatus === storeStatusFlag.Closed && otherStore.currentOrderType != orderTypeFlag.DineIn
              && ((otherStore.storeOHSchedules && (!otherStore.storeOHSchedules?.delivery || otherStore.storeOHSchedules?.delivery?.length == 0)
              && (!otherStore.storeOHSchedules?.pickup || otherStore.storeOHSchedules?.pickup?.length == 0)) || (!otherStore.storeOHSchedules)))
              || (otherStore.currentStatus === storeStatusFlag.Closed && otherStore.currentOrderType == orderTypeFlag.DineIn)">
                {{"store.qr.close" | translate}}
              </label>
              <label *ngIf="otherStore.currentStatus === storeStatusFlag.OutofCoverage">{{'store.current.status.out.of.service' | translate}}</label>
              <label *ngIf="otherStore.currentStatus === storeStatusFlag.TempClosed">{{'store.current.status.temporarily.closed' | translate}}</label>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
</div>
