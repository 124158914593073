import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ForgotPasswordService } from '../services/forgot-password.service';

@Component({
  selector: 'app-reset-password-result',
  templateUrl: './reset-password-result.component.html',
  styleUrls: ['./reset-password-result.component.scss'],
  providers: [ForgotPasswordService]
})
export class ResetPasswordResultComponent implements OnInit {
  countdownSec: number = environment.pwdResetRedirectInSec;
  sub: any;
  resetPasswordSuccess: boolean = false;

  constructor(
    private forgotPasswordService: ForgotPasswordService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.data.subscribe(data => {
      this.resetPasswordSuccess = data.resetSuccess;

    });

    this.startCountdown(environment.pwdResetRedirectInSec);
  }

  async onProceed() {
    await this.forgotPasswordService.navigateToLoginPage();
  }

  startCountdown(seconds) {
    let counter = seconds;

    const interval = setInterval(() => {
      counter--;

      this.countdownSec = counter;

      if (counter === 0) {
        clearInterval(interval);
        this.onProceed();
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
