import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { FormStage } from '../enums/FormStage';
import { Subject } from 'rxjs';
import { Gender } from '../models/Gender';
import { ProfileService } from './../services/profile.service';
import { PageName } from 'src/app/core/enums';
import { OtpService } from '../services/otp.service';
import { UserService } from 'src/app/core/user/user.service';
import { Subscription } from 'rxjs';
import { StoreMode } from 'src/app/core/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/core/services/utils.service';
import { RoutingService } from '../services/routing.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.scss"],
  host: {
    "(window:resize)": "onWindowResize($event)"
  },
  encapsulation: ViewEncapsulation.None
})

export class EditProfileComponent implements OnInit, OnDestroy {
  @ViewChild('nameInput', { static: false }) nameInputEl: any;

  currentRoute = "";

  dob: Date;

  curUser: any;
  sub: Subscription = new Subscription();
  resetMobileSuccessDialog$: Subscription;
  setupPw$: Subscription;

  encryptedCustID: any;

  showResendEmail: boolean = false;
  updateSuccessful: boolean = false;
  isMobileLayout: boolean = false;

  width: number = window.innerWidth;
  mobileLayoutWidth: number = 991;
  showGenderDialog: boolean = false;
  showDeleteAccTncDialog: boolean = false;
  showDeleteAccDialog: boolean = false;
  showResetMobileSuccessDialog: boolean = false;

  formStage: FormStage = FormStage.UpdateUserProfile;
  formStage$: Subject<FormStage> = new Subject<FormStage>();

  genderValue: any;

  dsGender: Gender[] = [
    {
      name: 'Male',
      code: 'M'
    },
    {
      name: 'Female',
      code: 'F'
    }
  ];
  selectedGender?: Gender;

  fgroup: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    mobileNo: ['', [Validators.required]],
    otpCode: ['', []],
    email: ['', [Validators.required, Validators.email, Validators.pattern(this.utilsService.emailPattern)]],
    dob: ['', []],
    password: ['', []],
    newPassword: ['', []],
    genderCode: ['', []],
  }, { updateOn: 'submit' });

  updMobileNoStage: FormStage = this.initMobileStage();
  updMobileNoStage$: Subject<FormStage> = new Subject<FormStage>();
  submitBtnDesc = 'SAVE';

  userProfile = {
    isMobileVerified: false,
    isEmailVerified: false
  };

  updateProfileErr: HttpErrorResponse;
  errCode: any = ErrorCode;

  minDate: Date;
  maxDate: Date;

  telInpSetting = DefaultSettingService.telInpSetting;
  countryCode : string = "MY";
  maxYearRange: number;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private otpService: OtpService,
    private userService: UserService,
    private utilsService: UtilsService,
    private routingService: RoutingService
  ) {

    const currentDate = new Date();
    this.maxYearRange = currentDate.getFullYear();
    this.dob = currentDate;
  }

  changeChildRoute(name: string) {
    this.currentRoute = name;
  }

  async ngOnInit() {
    this.formStage$.subscribe(dt => {
      this.formStage = dt;
      this.onValueChange();
    });
    this.updMobileNoStage$.subscribe(dt => {
      this.updMobileNoStage = dt;
      this.onValueChange();
    });

    this.sub = this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUser = dt ? dt : null;
      this.encryptedCustID = this.curUser ? this.curUser.encryptedCustID : null;
    });

    this.onValueChange();
    this.resetUserProfile();

    this.isMobileLayout = this.width <= this.mobileLayoutWidth;

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDate();

    this.minDate = new Date(1900, 0, 1);
    this.maxDate = new Date(currentYear, currentMonth, currentDate);

    let mobileNo = this.curUser ? this.curUser.mobileNo : null;
    let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + mobileNo);
    this.countryCode = parsedPhoneData.country;
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobileLayout = this.width <= this.mobileLayoutWidth;
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }


  async onSubmit() {
    this.updateProfileErr = null;
    this.f.name.setValue(this.nameInputEl.nativeElement.value);

    if (this.fg.invalid) {

      // focus on first invalid field
      const invalidInput = [];
      Object.keys(this.f).filter((field: any) => {
        if (this.fg.get(field).invalid) {
          invalidInput.push(field);
        }
      });

      (<any>this.fg.get(invalidInput[0])).nativeElement.focus();
      return;
    }

    await this.doUpdateProfile();
  }

  onUpdateAction(updateAction: string) {
    if (updateAction === 'verifyMobileNo') {
      this.changeFormStage(FormStage.VerifyMobile);
      this.doVerifyMobileNo();
    } else if (updateAction === 'updateMobileNo') {
      this.changeFormStage(FormStage.UpdateMobile);
      this.doUpdateMobile();
    } else if (updateAction === 'resetPassword') {
      this.changeFormStage(FormStage.UpdatePassword);
      this.doResetPassword();
    } else if (updateAction === 'verifyEmail') {
      this.changeFormStage(FormStage.VerifyEmail);
      this.doSendEmail();
    } else if (updateAction === 'verifiedEmail') {
      this.changeFormStage(FormStage.VerifiedEmail);
    }
  }

  onCancel() {
    if (this.formStage === FormStage.UpdatePassword
      || this.formStage === FormStage.VerifyMobile
      || this.formStage === FormStage.UpdateMobile) {
      this.changeFormStage(FormStage.UpdateUserProfile);
    }
    this.resetMobileStage();
    this.resetUserProfile();
  }

  onReload() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.resetMobileSuccessDialog$?.unsubscribe();
  }

  async onReCaptcha() {
    if (this.fg.invalid) {
      return;
    }
    await this.onSendOTP();
    this.changeMobileStage(FormStage.SendOTP);
  }

  async onSendOTP() {
    await this.otpService.onSendOTP(this.getFormControl("mobileNo").value, PageName.VerifyMobilePage);
  }

  onClickGender() {
    this.showGenderDialog = true;
  }

  onSwitchGender(genderCode: string) {
    if (this.f.genderCode.value !== genderCode) {
      this.f.genderCode.setValue(genderCode);
      this.showGenderDialog = false;
    }
  }

  onClickDeleteAcc() {
    this.showDeleteAccTncDialog = true;
  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  private onValueChange() {
    this.changeSubmitBtnDesc();
    this.createFormValidation();
  }

  private createFormValidation() {
    if (this.formStage === FormStage.UpdateUserProfile) {
      this.getFormControl('name').enable();
      this.getFormControl('mobileNo').disable();
      this.getFormControl('otpCode').disable();
      this.getFormControl('email').enable();
      this.getFormControl('dob').enable();
      this.getFormControl('password').disable();
      this.getFormControl('newPassword').disable();
      this.getFormControl('genderCode').enable();

      this.getFormControl('name').setValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
      this.getFormControl('mobileNo').setValidators([Validators.required]);
      this.getFormControl('otpCode').setValidators([]);
      this.getFormControl('email').setValidators([Validators.pattern(this.utilsService.emailPattern)]);
      this.getFormControl('dob').setValidators([]);
      this.getFormControl('password').setValidators([]);
      this.getFormControl('newPassword').setValidators([]);
      this.getFormControl('genderCode').setValidators([]);
    } else if (this.formStage === FormStage.UpdatePassword) {
      this.getFormControl('name').disable();
      this.getFormControl('mobileNo').disable();
      this.getFormControl('otpCode').disable();
      this.getFormControl('email').disable();
      this.getFormControl('dob').disable();
      this.getFormControl('password').enable();
      this.getFormControl('newPassword').enable();
      this.getFormControl('genderCode').disable();

      this.getFormControl('name').setValidators([]);
      this.getFormControl('mobileNo').setValidators([]);
      this.getFormControl('otpCode').setValidators([]);
      this.getFormControl('email').setValidators([]);
      this.getFormControl('dob').setValidators([]);
      this.getFormControl('password').setValidators([Validators.required]);
      this.getFormControl('newPassword').setValidators([Validators.required]);
      this.getFormControl('genderCode').setValidators([]);
    } else if (this.formStage === FormStage.VerifyMobile) {
      if (this.updMobileNoStage === FormStage.SendOTP) {
        this.getFormControl('name').disable();
        this.getFormControl('mobileNo').disable();
        this.getFormControl('otpCode').enable();
        this.getFormControl('email').disable();
        this.getFormControl('dob').disable();
        this.getFormControl('password').disable();
        this.getFormControl('newPassword').disable();
        this.getFormControl('genderCode').disable();

        this.getFormControl('name').setValidators([]);
        this.getFormControl('mobileNo').setValidators([Validators.required]);
        this.getFormControl('otpCode').setValidators([Validators.required]);
        this.getFormControl('email').setValidators([]);
        this.getFormControl('dob').setValidators([]);
        this.getFormControl('password').setValidators([]);
        this.getFormControl('newPassword').setValidators([]);
        this.getFormControl('genderCode').setValidators([]);

      } else {
        this.getFormControl('otpCode').reset();
        this.getFormControl('name').disable();
        this.getFormControl('mobileNo').disable();
        this.getFormControl('otpCode').disable();
        this.getFormControl('email').disable();
        this.getFormControl('dob').disable();
        this.getFormControl('password').disable();
        this.getFormControl('newPassword').disable();
        this.getFormControl('genderCode').disable();

        this.getFormControl('name').setValidators([]);
        this.getFormControl('mobileNo').setValidators([Validators.required]);
        this.getFormControl('otpCode').setValidators([]);
        this.getFormControl('email').setValidators([]);
        this.getFormControl('dob').setValidators([]);
        this.getFormControl('password').setValidators([]);
        this.getFormControl('newPassword').setValidators([]);
        this.getFormControl('genderCode').setValidators([]);
      }
    }
  }

  private changeSubmitBtnDesc() {
    if (this.formStage === FormStage.UpdateUserProfile) {
      this.submitBtnDesc = 'SAVE';
    } else if (this.formStage === FormStage.VerifyMobile
      || this.formStage === FormStage.UpdatePassword
      || this.formStage === FormStage.UpdateMobile
    ) {
      this.submitBtnDesc = 'Confirm';
    }
  }

  private changeFormStage(formStage: FormStage) {
    if (formStage === FormStage.VerifyMobile
      || formStage === FormStage.UpdateMobile) {
      this.changeMobileStage(FormStage.VerifyReCaptcha);
    }
    this.formStage$.next(formStage);
  }

  private resetUserProfile() {
    const userProfile = this.route.snapshot.data['userProfile'];
    this.userProfile = userProfile;
    this.fg.patchValue(userProfile);
  }

  private initMobileStage() {
    return FormStage.None;
  }

  private resetMobileStage() {
    const fs = this.initMobileStage();
    this.changeMobileStage(fs);
    return fs;
  }

  private changeMobileStage(formStage: FormStage) {
    this.updMobileNoStage$.next(formStage);
  }

  private async doUpdateProfile() {
    let result = await this.profileService.onUpdateProfile(this.fg.getRawValue(), false);
    if (result instanceof (HttpErrorResponse)) {
      this.updateProfileErr = result;
      (<any>this.fg.get('email')).nativeElement.focus();
    } else {
      this.updateSuccessful = true;
      this.userService.getProfile();
    }
  }

  closeSucessMsgDialog() {
    this.updateSuccessful = false;
  }

  doUpdateMobile() {
    this.profileService.navigateToResetMobile();
  }

  async doVerifyMobileNo() {
    await this.onSendOTP();
    this.routingService.navigate(PageName.OTPPage, { pageName: PageName.VerifyMobilePage });
  }

  doResetPassword() {
    this.profileService.navigateToResetPass();
  }

  async doSendEmail() {
    await this.profileService.onVerifyEmail();
    this.showResendEmail = true;
  }

  showQRBarCodeDialog() {
    this.userService.showQrPopup();
  }
}

