import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NetworkService } from 'src/app/core/network-connection/network.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lost-network-connection',
  templateUrl: './lost-network-connection.component.html',
  styleUrls: ['./lost-network-connection.component.scss']
})
export class LostNetworkConnectionComponent implements OnInit, OnDestroy {

  showNetworkPageFound: boolean = false;

  constructor(
    private router: Router,
    private loc: Location,
    private network: NetworkService,
    @Inject(PLATFORM_ID) private platformId: object) {
  }

  ngOnInit() {
    this.network.isActive = true;
    if (isPlatformBrowser(this.platformId)) {
      this.showNetworkPageFound = true;
    }
  }

  reload() {
    if (window.navigator.onLine) {
      this.router.navigateByUrl(this.loc.path(true));
    }
  }

  ngOnDestroy(): void {
    this.network.isActive = false;
  }
}
