import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChannelQuery } from 'src/app/home/channel/channel.query';

@Component({
  selector: 'app-submitting-order',
  templateUrl: './submitting-order.component.html',
  styleUrls: ['./submitting-order.component.scss']
})
export class SubmittingOrderComponent implements OnInit {
  channelId: number;

  constructor(
    private router: Router,
    private channelQuery: ChannelQuery,
  ) { }

  async ngOnInit(): Promise<void> {
    this.channelQuery.currentChannelData$.subscribe((data) => {
      if (data) {
        this.channelId = data.channelId;
      }
    });

    setTimeout(() => {
      this.router.navigate(['order-summary']);
    }, 7000);
  }

}
