
<div class="popular-order-container" *ngIf="!isUpsell">
  <h1 class="heading-11">{{'popular.order.slider.header.1' | translate}}</h1>

  <swiper [slidesPerView]="'auto'"
          [spaceBetween]="15"
          [loop]="false"
          class="mySwiper">

    <ng-template swiperSlide *ngFor="let item of crossSellItem">
      <div class="p-d-flex" style="width: 100%; height: 100%;">
        <!--restaurant/ item image here-->
        <img [src]="item.thumbnail" onerror="this.src='assets/errorMsg_image/imageError.webp'" *ngIf="item.thumbnail" alt="odaring image" loading="lazy"/>
        <img src="assets/image-unavailable.svg" *ngIf="!item.thumbnail" alt="image unavailable"/>
        <div class="p-d-flex p-flex-column p-ml-2" style="width: 100%;">
          <div class="item-title-container">
            <span class="menutag-container" *ngFor="let tags of item.menuTags | stringSplit : ','">
              <i class="{{tags | menutagIcon}}" *ngIf="tags | menutagIcon"></i>
            </span>
            <h1 class="item-title heading-11" [innerHtml]="item.title"></h1>
          </div>

          <div class="item-bottom-container p-d-flex p-mt-auto">
            <!--restaurant/item fees-->
            <h2 class="price-display heading-12" *ngIf="item.dispPrice > 0">{{item.dispPrice | currency : currencyCode : 'symbol-narrow'}}</h2>
            <div class="p-ml-auto">
              <i class="oda-plus btn-add" (click)="onClickItem(item, entryMode.CrossSellMenu)"></i>
            </div>
          </div>

        </div>
      </div>

    </ng-template>
  </swiper>
</div>

<div class="upsell-order-container" *ngIf="isUpsell">
  <swiper [slidesPerView]="'auto'"
          [spaceBetween]="isMobile ? 12 : 8"
          [loop]="false"
          class="mySwiper">

    <ng-template swiperSlide *ngFor="let item of crossSellItem">
      <div class="outer-container" (click)="onClickItem(item, entryMode.UpsellMenu)">
        <!-- item image here -->
        <div class="image-container">
          <img [src]="item.thumbnail" onerror="this.src='assets/errorMsg_image/imageError.webp'" *ngIf="item.thumbnail" alt="odaring image" loading="lazy"/>
          <img src="assets/image-unavailable.svg" *ngIf="!item.thumbnail" alt="image unavailable"/>
        </div>

        <div class="description-container">
          <h1 class="item-title heading-11" [innerHtml]="item.title"></h1>
          <!-- if price difference more than 0 -->
          <div class="item-bottom-container p-d-flex p-mt-auto" *ngIf="(item | calPriceDiff : currentItem) != 0">
            <span class="price-label">{{"merchant.detail.just.add" | translate}}</span>
            <span class="price-display">{{item | calPriceDiff : currentItem | currency : currencyCode : 'symbol-narrow'}}</span>
          </div>
          <!-- if price difference equal 0, will look for price range -->
          <div class="item-bottom-container p-d-flex p-mt-auto" *ngIf="(item | calPriceDiff : currentItem) == 0 && item.priceRange && item.priceRange?.min != 0">
            <span class="price-range-label">{{"menu.item.from" | translate}}</span>
            <span class="price-display">{{item.priceRange.min | currency : currencyCode : 'symbol-narrow'}}</span>
          </div>
        </div>
      </div>

    </ng-template>
  </swiper>
</div>


