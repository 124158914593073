import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '../shared/components/page-not-found/page-not-found.component';

import { AccountComponent } from './account.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetMobileComponent } from './reset-mobile/reset-mobile.component';
import { ProfileComponent } from '../home/template/profile/profile.component';
import { ManageAddressComponent } from './manage-address/manage-address.component';
import { OtpFormComponent } from './otp/otp-form/otp-form.component';
import { CardDetailComponent } from './card-Detail/card-detail.component';
import { AddressComponent } from './address/address.component';
import { NewAddressComponent } from './address/new-address/new-address.component';
import { AddressGuard } from '../account/guards/address.guard';
import { EditAddressComponent } from './address/edit-address/edit-address.component';
import { NetworkGuard } from 'src/app/core/network-connection/network-guard.service';
import { ResetMobileOtpComponent } from './reset-mobile/reset-mobile-otp/reset-mobile-otp/reset-mobile-otp.component';
import { ProfileSetupPasswordComponent } from './setup-password/profile-setup-password/profile-setup-password.component';

const routes: Routes = [
  {
    path: '', component: AccountComponent, canActivate: [NetworkGuard], children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      {
        path: 'profile', component: ProfileComponent, children: [
          {
            path: '', component: EditProfileComponent,
            resolve: {
              userProfile: UserProfileResolver
            }
          },
          { path: 'reset-phone', component: ResetMobileComponent },
          { path: 'reset-phone-no', component: ResetMobileOtpComponent },
          { path: 'reset-password', component: ResetPasswordComponent },
          { path: 'otp', component: OtpFormComponent },
        ]
      },
      {
        path: 'address', component: AddressComponent, children: [
          {
            path: '', component: ManageAddressComponent
          },
          {
            path: 'new-address', component: NewAddressComponent,
            canActivate: [AddressGuard]
          },
          {
            path: 'edit-address', component: EditAddressComponent,
            canActivate: [AddressGuard]
          },
        ]
      },
      { path: 'payment-method', component: CardDetailComponent },
      { path: 'setup-password', component: ProfileSetupPasswordComponent },
      { path: 'change-password', component: ResetPasswordComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent }


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountsRoutingModule { }
