import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ChannelSec } from './channelsec.model';

export interface ChannelSecState extends EntityState<ChannelSec> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'channelsec' })
export class ChannelSecStore extends EntityStore<ChannelSecState, ChannelSec> {

  constructor() {
    super();
  }

}

