import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AnalyticsEvent } from 'src/app/core/enums/AnalyticsEvent';
import { SocialShareIcon } from 'src/app/core/enums/SocialShareIcon';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss'],
})
export class SocialShareComponent implements OnInit {
  @Input() url?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() imageUrl?: string;
  @Input() isReferralPage: boolean = false;
  @Input() hideName?: boolean = false;
  @Input() include?: SocialShareIcon[] = [];

  name = 'ngx sharebuttons';
  socialShareIcons = SocialShareIcon;
  socialShareIcon: any[] = []

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    if(this.include.length === 0) {
      this.socialShareIcon = Object.keys(this.socialShareIcons).map(key => this.socialShareIcons[key]);
    } else {
      this.socialShareIcon = this.include;
    }
  }

  iconImage(icon: SocialShareIcon) {
    switch(icon) {
      case this.socialShareIcons.facebook:
        return "assets/social-media-icon/facebook-2019-05-21@2x.webp";
      case this.socialShareIcons.messenger:
        return "assets/social-media-icon/new-messenger-2020@2x.webp";
      case this.socialShareIcons.whatsapp:
        return "assets/social-media-icon/whatsapp.webp";
      case this.socialShareIcons.email:
        return "assets/social-media-icon/email.webp";
      default:
        return "";
    }
  }

  shareReferral(event: any) {
    this.analyticsService.logEvents(AnalyticsEvent.share, {
      content_type: 'referral code',
      item_id: '',
      method: 'web'
    });
  }
}
