import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { OrderData } from 'src/app/core/models/OrderData';
import { OrderPaymentDetailModel } from 'src/app/core/models/OrderPaymentDetailModel';
import { OrderTS } from 'src/app/core/models/OrderTS';

@Component({
  selector: 'app-order-amount',
  templateUrl: './order-amount.component.html',
  styleUrls: ['./order-amount.component.scss']
})
export class OrderAmountComponent implements OnInit{

  currentOrderData : OrderData;

  @Input() paymentMethod : string = null;
  @Input() isOrderSummary : boolean = false;
  @Input() set orderData(value : OrderData){
    this.currentOrderData = value;
    this.excludedTax = this.currentOrderData.orderTSes.filter(val => val.inclFlag == false);
    this.includedTax = this.currentOrderData.orderTSes.filter(val => val.inclFlag == true);
  }
  @Input() orderPaymentDetailModel: OrderPaymentDetailModel[] = [];

  get orderData() : OrderData{
    return this.currentOrderData;
  }

  excludedTax : OrderTS[];
  includedTax : OrderTS[];

  constructor() { }

  ngOnInit(): void {
    this.excludedTax = this.currentOrderData.orderTSes.filter(val => val.inclFlag == false);
    this.includedTax = this.currentOrderData.orderTSes.filter(val => val.inclFlag == true);
  }

}




