import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserQuery } from '../user/user.query';
import { PreviousRouteService } from '../services/previous-route.service';
import { Location } from "@angular/common";
import { PreviousRoute } from '../models/PreviousRoute';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardGuard implements CanActivate {

  previousUrl : string = null;
  currentUrl : string = null;

  isLoggedIn : boolean;

  constructor(
    private router : Router,
    private previousRouteService : PreviousRouteService,
    private userQuery : UserQuery,
    private location: Location,
    private sessionStorageService: SessionStorageService
  ){
    this.userQuery.isLoggedIn$.subscribe(val => {
      this.isLoggedIn = val && val.id != 999 ? true : false;
    })
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if(this.isLoggedIn){
      this.location.back();
      return false;
    }
    else{
      if(!this.sessionStorageService.getItem('previousRoute')){
        let previousRoute = {} as PreviousRoute;
        previousRoute.routeName = this.router.url;

        if (previousRoute.routeName == '/deleted-account-success') {
          this.previousRouteService.savedPreviousRoute({ routeName: '/home' });
        } else if (previousRoute.routeName !== '/reset-success') {
          this.previousRouteService.savedPreviousRoute(previousRoute);
        } else {
          this.previousRouteService.removePreviousRoute();
        }
      }
      return true;
    }

  }
}
