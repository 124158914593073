<app-download-app></app-download-app>

<div class="forgot-password_container">
  <!--mobile : header-->
  <div class="hidden-lg mobile-header">
    <div class="sub-header">
      <app-back-button class="back-btn"></app-back-button>
      <h1 class="heading-14">{{ 'forgotPassword.header' | translate }}</h1>
    </div>
  </div>

  <div class="content">
    <img src="assets/mascot/Mascot_login_reset-password@2x.webp" alt="odaring mascot"/>
    <h1 class="heading-5 hidden-sm forgot-title">{{ 'forgotPassword.header' | translate }}</h1>

    <div class="content-form">
      <app-reset-pass-form [pageName]="pageName" (onSubmitted)="onFormSubmit($event)" style="width: 100%"></app-reset-pass-form>
    </div>
  </div>
</div>
