<div class="menu-variation-container">
  <h3 class="variation-title heading-11">{{"variation.title" | translate}}</h3>

  <swiper [slidesPerView]="'auto'"
          [spaceBetween]="12"
          [loop]="false"
          [initialSlide]="initialSlide"
          class="mySwiper"
          #swiper *ngIf="variationItem.length > 0">

    <ng-template swiperSlide *ngFor="let item of variationItem">
      <div class="item-image-container p-d-flex" [ngClass]="{'item-with-image': item.thumbnail, 'item-without-image': !item.thumbnail,
      'chosen-border': item.isChosenVariation}" (click)="onClickVariation(item)">
        <!-- item image here -->
        <img [src]="item.thumbnail" onerror="this.src='assets/errorMsg_image/imageError.webp'" *ngIf="item.thumbnail" alt="odaring image" loading="lazy"/>
        <!-- item name container -->
        <div class="item-name-container" *ngIf="item.thumbnail">
          <span class="item-name" [innerHtml]="item.title"></span>
        </div>
        <!-- unavailable overlay and message when got image -->
        <span class="unavaible-thumbnail-container" *ngIf="item.status == itemStatus.Unavailable">
          {{"merchant.card.status.unavailable" | translate}}
        </span>

        <!-- no image UI state -->
        <div class="item-name-container" *ngIf="!item.thumbnail">
          <span class="item-name" [ngClass]="{'unavailable-item-name': item.status == itemStatus.Unavailable}" [innerHtml]="item.title"></span>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
