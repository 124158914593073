<div class="edit-profile-container">
  <!--update profile successfully-->
  <div class="editProfile-sucessUpdateAlert hidden-sm" *ngIf="updateSuccessful">
    <i class="color oda-check-alt"></i>{{ 'alert.editProfile.des.7' | translate }}
  </div>
  <!--update mobile number sucessfully-->
  <div class="editProfile-sucessUpdateAlert" *ngIf="showResetMobileSuccessDialog">
    <i class="color oda-check-alt"></i>{{ 'alert.editProfile.des.1' | translate }}
  </div>
<!--mobile header-->
  <div class="hidden-lg profile-header">
    <i class="oda-chevron-left" [routerLink]="['/profiles']"></i>
    <h1 class="heading-7">{{'edit.profile.mobile.header' | translate}}</h1>
  </div>

  <div class="profile-content" *ngIf="curUser">
    <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
      <!--QR-->
      <div class="qr-container" *ngIf="isMobileLayout">
        <div class="content" style="text-align: center;" (click)="showQRBarCodeDialog()">
          <ejs-qrcodegenerator *ngIf="encryptedCustID" height="110px"
                               #barcode id="barcode" mode="SVG"
                               value="{{encryptedCustID}}"></ejs-qrcodegenerator>
          <div class="outer-border"></div>
        </div>
      </div>
      <!--full name-->
      <div class="content">
        <h2>{{ 'input.label.19' | translate }}<span>*</span></h2>
        <div class="input-container">
          <input id="name" type="text" pInputText formControlName="name" placeholder="{{ 'input.label.19' | translate }}" #nameInput appInputTrim >
          <i class="oda-times btn-clear" *ngIf="nameInput.value" (click)="nameInput.value = ''"></i>
        </div>
        <div *ngIf="f.name.errors?.required || f.name.errors?.pattern" class="invalid-feeback">
          <i class="oda-alert"></i>{{ 'editprofile.name.empty' | translate }}
        </div>
      </div>
      <!--phone number-->
      <div class="content">
        <h2>{{ 'input.label.1' | translate }}<span>*</span></h2>
        <div class="input-container">
          <ngx-intl-tel-input [cssClass]="''"
              [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
              [enablePlaceholder]="telInpSetting.enablePlaceholder"
              [searchCountryFlag]="telInpSetting.searchCountryFlag"
              [searchCountryField]="telInpSetting.searchCountryField"
              [selectFirstCountry]="telInpSetting.selectFirstCountry"
              [phoneValidation]="telInpSetting.phoneValidation"
              [separateDialCode]="false"
              [preferredCountries]="telInpSetting.preferredCountries"
              [selectedCountryISO]="countryCode | telInputCountryCode" name="phone" formControlName="mobileNo" style="position:relative" >
          </ngx-intl-tel-input>
          <button type="button" class="reset-btn" (click)="doUpdateMobile()" *ngIf="formStage === 'UpdateUserProfile'">{{ 'button.reset' | translate}}</button>
        </div>
        <!--phone number: verifying phone no. msg-->
        <h1 class="verify-msg-heading-1" *ngIf="curUser && !curUser?.isMobileVerified">
          {{ 'alert.editProfile.des.2' | translate }}
          <span (click)="doVerifyMobileNo()">{{ 'alert.editProfile.des.3' | translate }}</span>
          {{ 'alert.editProfile.des.4' | translate }}
        </h1>
        <!--phone no. verified msg-->
        <h1 class="verify-msg-heading-2" style="margin-top: 0px;" *ngIf="curUser.isMobileVerified"><i class="oda-check-circle"></i>{{ 'alert.editProfile.des.8' | translate }}</h1>
      </div>

      <!--email-->
      <div class="content">
        <h2>{{ 'input.label.8' | translate }}</h2>
        <div class="input-container">
          <input type="text" pInputText formControlName="email" placeholder="{{ 'input.placeholder.8' | translate }}" #emailInput appInputTrim />
          <i class="oda-times btn-clear" *ngIf="emailInput.value" (click)="emailInput.value = ''"></i>
        </div>
        <!-- Email registered err -->
        <div *ngIf="updateProfileErr && updateProfileErr.error.errorCode === errCode.UserRegisterConflict_409; else emailError" class="invalid-feeback"><i class="oda-alert"></i>{{ 'UserRegisterConflict_409' | translate }}</div>

        <ng-template #emailError>
          <!-- Email format err -->
          <div *ngIf="f.email.errors && f.email.errors.pattern" class="invalid-feeback">
            <i class="oda-alert"></i>
            {{ 'refund.return.email.error.email.invalid' | translate }}
          </div>
          <!-- Email empty msg -->
          <div class="editProfile-emailMsg" *ngIf="!f.email.errors && !curUser.email">
            <i class="oda-shield-check"></i>
            <h1>{{ 'alert.editProfile.des.5' | translate }}</h1>
          </div>
          <!--Email verify msg-->
          <div *ngIf="!f.email.errors && curUser.email && (curUser.email === f.email.value) && !curUser.isEmailVerified">
            <h1 class="verify-msg-heading-1" *ngIf="!showResendEmail">
              {{ 'alert.editProfile.des.2' | translate }}
              <span (click)="doSendEmail()">{{ 'alert.editProfile.des.3' | translate }}</span>
              {{ 'alert.manageAddress.des.9' | translate }}
            </h1>
            <h2 class="verify-msg-heading-1" *ngIf="showResendEmail">
              {{'alert.editProfile.des.9' | translate}} <span (click)="doSendEmail()">{{'button.resend' | translate}}</span>
            </h2>
          </div>
          <!-- Email verified msg -->
          <div class="verify-msg-heading-2" *ngIf="!f.email.errors && (curUser.email === f.email.value) && curUser.isEmailVerified">
            <i class="oda-check-circle"></i>{{ 'editprofile.email.input.verified' | translate }}
          </div>
        </ng-template>
      </div>
      <!--dob and gender-->
      <div class="content-2">
        <!--dob-->
        <div class="sub-content p-mr-3">
          <h2>{{ 'input.label.9' | translate }}</h2>
            <p-calendar
              formControlName="dob"
              dateFormat="dd/mm/yy"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="'1900:' + maxYearRange"
              [maxDate]="maxDate"
              [touchUI]="isMobileLayout"
              [readonlyInput]="true"
              styleClass="editProfileCalender">
            </p-calendar>
        </div>
        <!--gender-->
        <div class="sub-content">
          <h2>{{ 'input.label.10' | translate }}</h2>
          <div class="mobile-gender hidden-lg" (click)="onClickGender();">
            <input [readonly]="true" [value]="f.genderCode.value === 'M'? ('editprofile.gender.male' | translate) : f.genderCode.value === 'F'? ('editprofile.gender.female' | translate): ''" placeholder="{{ 'input.placeholder.6' | translate }}"/>
            <i class="oda-chevron-right"></i>
          </div>
         <div class="hidden-sm genderStyle">
          <p-dropdown [options]="dsGender" formControlName="genderCode" optionLabel="name" optionValue="code"
                      placeholder="{{ 'input.placeholder.6' | translate }}"> </p-dropdown>
         </div>
        </div>
      </div>

      <div class="save-btn">
        <button class="editProfile-btn-cta" type="submit">{{ submitBtnDesc }}</button>
      </div>

      <button type="button" class="btn-delete-account hidden-sm" (click)="onClickDeleteAcc()">{{ 'button.delete.account' | translate }}</button>

    </form>
  </div>

  <!--update profile successfully-->
  <div *ngIf="isMobileLayout">
    <p-dialog position="bottom"
              [modal]="true"
              [dismissableMask]="true"
              [(visible)]="updateSuccessful" class="hidden-lg sucessUpdateAlert-dialog">
      <div class="content">
        <i class="color oda-check-alt"></i>
        <h1>{{ 'alert.editProfile.des.7' | translate }}</h1>
        <button (click)="closeSucessMsgDialog()">{{'button.ok' | translate}}</button>
      </div>
    </p-dialog>
    <!-- Reset Mobile No Success Dialog -->
    <p-dialog [(visible)]="showResetMobileSuccessDialog" class="reset-mobile-success-dialog hidden-lg" [modal]=true [dismissableMask]=true [draggable]=false [showHeader]="false" *ngIf="showResetMobileSuccessDialog">
      <i class="oda-check-alt"></i>
      <p class="desc">{{ 'alert.editProfile.des.1' | translate }}</p>
      <button type="button" (click)="showResetMobileSuccessDialog = false">{{ "button.ok" | translate }}</button>
    </p-dialog>
  </div>

   <!-- Gender Dialog-->
   <p-dialog [(visible)]="showGenderDialog" [modal]=true [dismissableMask]=true [draggable]=false class="hidden-lg gender-dialog" *ngIf="showGenderDialog">
    <ng-template pTemplate="header">
      <h1>{{ 'editprofile.gender.select.des' | translate }}</h1>
    </ng-template>
    <div class="gender-container">
      <div (click)="onSwitchGender('M');" [ngClass]="{'active': f.genderCode.value === 'M'}">
        <img [src]="f.genderCode.value !== 'M'? 'assets/mascot/Mascot_male-Grey@2x.webp': 'assets/mascot/Mascot_male@2x.webp'" alt="odaring mascot"/>
        <p>{{ 'editprofile.gender.male' | translate }}</p>
      </div>
      <div (click)="onSwitchGender('F');" [ngClass]="{'active': f.genderCode.value === 'F'}">
        <img [src]="f.genderCode.value !== 'F'? 'assets/mascot/Mascot_female-Grey@2x.webp': 'assets/mascot/Mascot_female@2x.webp'" alt="odaring mascot"/>
        <p>{{ 'editprofile.gender.female' | translate }}</p>
      </div>
    </div>
  </p-dialog>

  <!-- Delete Acc Tnc Dialog-->
  <p-dialog [(visible)]="showDeleteAccTncDialog" class="delete-acc-tnc-dialog" [modal]=true [dismissableMask]=true [draggable]=false [showHeader]="false" *ngIf="showDeleteAccTncDialog">
    <app-delete-acc-tnc (onHide)="showDeleteAccTncDialog = false" (onContinue)="showDeleteAccTncDialog = false; showDeleteAccDialog = true;"></app-delete-acc-tnc>
  </p-dialog>

  <!-- Delete Acc Dialog-->
  <p-dialog [(visible)]="showDeleteAccDialog" class="delete-acc-dialog" [modal]=true [dismissableMask]=true [draggable]=false *ngIf="showDeleteAccDialog">
    <app-delete-acc (onHide)="showDeleteAccDialog = false"></app-delete-acc>
  </p-dialog>
</div>
