import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfigService } from 'src/app/config.service';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() showFooter: boolean;
  @Input() showMobileBottomNav: boolean;
  @Input() isFinishedLoading: boolean;

  buildVersion: string = environment.version;
  userChnlSub: Subscription = new Subscription();
  isDefaultDomain: boolean = false;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private userchnlQry: UserChnlQuery,
  ) { }

  ngOnInit(): void {
    this.userChnlSub = this.userchnlQry.isSubDomain$.subscribe((data) => {
      this.isDefaultDomain = !data;
    });
  }

  onNavigate(dt: string) {
    const originCountryUrl = this.isDefaultDomain ? (window.location.origin + this.configService.get('appBaseHref')) : window.location.origin;

    switch (dt) {
      case 'email': {
        let mailText = "mailto:customerservice@odaring.com?subject=Order Issue";
        window.location.href = mailText;
        break;
      }
      case 'aboutUs': {
        this.router.navigateByUrl("/");
        break;
      }
      case 'beComeAPartner': {
        window.open("https://www.evolftech.com/odaring/merchant-partner/", "_blank");
        break;
      }
      case 'faq': {
        window.open(originCountryUrl + '/faq', '_blank');
        break;
      }
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tNc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
      case 'instagram': {
        window.open("https://www.instagram.com/odaringmy/", "_blank");
        break;
      }
      case 'facebook': {
        window.open("https://www.facebook.com/Odaring-101841945544106", '_blank');
        break;
      }
      case 'twitter': {
        window.open("https://twitter.com/Odaringmy", '_blank');
        break;
      }
      case 'apple-store': {
        window.open("https://apps.apple.com/my/app/odaring/id1586588150", '_blank');
        break;
      }
      case 'google-play': {
        window.open("https://play.google.com/store/apps/details?id=com.evolftech.odaring", '_blank');
        break;
      }
      case 'huawei-gallery': {
        window.open("https://appgallery.huawei.com/app/C104529893", '_blank');
        break;
      }
      case 'evolf': {
        window.open("https://www.evolftech.com/", "_blank")
      }
    }
  }

  ngOnDestroy() {
    this.userChnlSub?.unsubscribe();
  }
}
