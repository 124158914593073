
<div class="location-outer-container" *ngIf="currentOrderType !== orderTypeFlag.DineIn || !shareTable">
  <div class="location-container">
    <div class="heading-11 location-panel"
      *ngIf="(selectedAddress$ | async) as address; else elseAddress">
      <i class="oda-pin"></i>
      <label style="text-align: center;" *ngIf="address.addressDesc && isUserLogin; else elseAddressName">
        <div *ngIf="isMobileView && selectedChannel.channelTag !== defaultChannel">
          {{address.addressDesc}}
        </div>
        <div *ngIf="!isMobileView || selectedChannel.channelTag === defaultChannel">
          {{address.addressDesc}}
        </div>
      </label>
      <ng-template #elseAddressName>
        <label>
          <div *ngIf="isMobileView && selectedChannel.channelTag !== defaultChannel">
            {{address.fullAddress}}
          </div>
          <div *ngIf="!isMobileView || selectedChannel.channelTag === defaultChannel">
            {{address.fullAddress}}
          </div>
        </label>
      </ng-template>
    </div>

    <ng-template #elseAddress>
      <div class="heading-8 location-panel">
        <i class="oda-pin"></i>
        <label>
          <div *ngIf="!isMobileView || selectedChannel.channelTag === defaultChannel">
            {{'delivery.page.hint.text.field' | translate}}
          </div>
          <div *ngIf="isMobileView && selectedChannel.channelTag !== defaultChannel">
            {{'delivery.page.hint.text.field' | translate}}
          </div>
        </label>
      </div>
    </ng-template>
  </div>
</div>

<div *ngIf="currentOrderType === orderTypeFlag.DineIn && shareTable">
  <div class="heading-11 text-truncate" style="cursor: pointer;"
    [style.color]="isMobileView ? 'var(--iconTextButton-primary)' : 'var(--text-primary)'">
    <label style="margin: 0; cursor: pointer;" *ngIf="tblNoLbl">{{staticTableNo ? tblNoLbl + ' ' + staticTableNo : ('location.scan.desc' | translate)}}</label>
    <label style="margin: 0; cursor: pointer;" *ngIf="!tblNoLbl">
      {{staticTableNo ?  ("merchant.qr.dinein.table.no" | translate : { tableNo: staticTableNo }) : ('location.scan.desc' | translate)}}
    </label>
  </div>
</div>
