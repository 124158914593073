<div class="news-detail-container" *ngIf="infoPanel">
    <div class="header-image" id="header">
        <div class="header-image-wrapper">
            <img [src]="imageUrl" alt="news image" loading="lazy"/>
            <div class="overlay hidden-lg"></div>
        </div>
    </div>

    <div class="header-btn hidden-lg" id="headerBtn" *ngIf="showHeader">
        <i class="btn-back oda-chevron-left"id  id="backBtn" (click)="onClickBack()"></i>
        <h2 id="title" [innerHtml]="title"></h2>
        <i class="btn-share oda-upload" id="uploadBtn" (click)="showShare = true"></i>
    </div>

    <div class="content">
        <h1 [innerHtml]="title"></h1>
        <h2 [innerHtml]="subtitle"></h2>
        <p [innerHtml]="content"></p>

        <div class="share-container hidden-sm">
            <p>{{ "share.to" | translate }}</p>
            <div class="share-btn">
                <app-social-share [title]="title" [description]="subtitle" [url]="infoPanel.infoUrl" [imageUrl]="imageUrl" [include]="[socialShareIcons.facebook, socialShareIcons.whatsapp]" [hideName]="true" ></app-social-share>
                <button mat-icon-button class="btn-copy-link" (click)="copyUrl()">
                    <div class="image-card">
                        <img src="assets/social-media-icon/link.svg" alt="socialMedia image"/>
                    </div>
                    <div class="copied" *ngIf="copiedText">
                        <i class="oda-check-alt"></i>
                        <p>{{ 'rewards.copied' | translate }}</p>
                    </div>
                </button>
            </div>
        </div>
    </div>

    <!-- Share Dialog -->
    <p-dialog [(visible)]="showShare" [modal]=true [draggable]=false class="dialog-share" *ngIf="showShare">
        <h1 class="title">{{ 'button.share' | translate }}</h1>
        <div class="share-btn">
            <app-social-share [title]="title" [description]="subtitle" [url]="infoPanel.infoUrl" [include]="[socialShareIcons.facebook, socialShareIcons.whatsapp]" [hideName]="true" ></app-social-share>
            <button mat-icon-button class="btn-copy-link" (click)="copyUrl()">
                <div class="image-card">
                    <img src="assets/social-media-icon/link.svg" alt="socialMedia image"/>
                </div>
                <div class="copied" *ngIf="copiedText">
                    <i class="oda-check-alt"></i>
                    <p>{{ 'rewards.copied' | translate }}</p>
                </div>
            </button>
        </div>
    </p-dialog>
</div>

