import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  constructor(private translate: TranslateService) {

  }

  transform(seconds: number, displayValue: string): string {
    let minutes: number = Math.floor((seconds % 3600) / 60);
    let days: number = 0;
    let displayTimeFormat = '';

    if (displayValue === 'hourMins') {
      displayTimeFormat = ("00" + minutes).slice(-2) + ":" + ("00" + Math.floor(seconds - minutes * 60)).slice(-2);

    } else if (displayValue === 'days') {
      days = Math.floor(seconds / (3600 * 24));
      displayTimeFormat = days > 0 ? (days + this.translate.instant('day')) : "";
      if (days === 0) {
        displayTimeFormat = minutes > 0 ? (minutes + this.translate.instant('minute')) : "";
      }
    }

    return displayTimeFormat;
  }
}
