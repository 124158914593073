import { Pipe, PipeTransform } from '@angular/core';
import { CartService } from 'src/app/core/cart/cart.service';
import { MenuItem } from 'src/app/core/models/MenuItem';

@Pipe({
  name: 'itemTotalPrice'
})
export class ItemTotalPricePipe implements PipeTransform {

  constructor(
    private cartService : CartService
  ){ }

  async transform(dispPrice: number, menuItem : MenuItem) {
    dispPrice = dispPrice ? dispPrice : 0;

    let total : number = menuItem.dispPrice;
    let itemQty = menuItem.itemQty ? menuItem.itemQty : 1;

    total = itemQty * await this.cartService.calculateTotalPriceForAddToCart(menuItem);

    return total;
  }

}
