<div class="reward-container">
  <div class="reward-wrapper" *ngIf="isLoggedIn; else noLogin">
      <div class="header-container" [ngClass]="{ 'sticky': isHeaderSticky }" #menuContainer>
          <div class="header-wrapper" id="menuContainer">
              <div class="header">
                  <!-- Voucher History -->
                  <div class="hidden-lg title">{{ 'navigation.Menu.des.7' | translate }}
                      <p *ngIf="rewardSectionType === rewardSectionTypes.MyVoucher" routerLink="/voucher-history">{{
                          'my.order.history' | translate }}</p>
                  </div>
                  <div class="reward-type">
                      <div class="not-selectable"
                          [ngClass]="{'active': rewardSectionType === rewardSectionTypes.Explore }">
                          <span (click)="onSwitchVoucherType(rewardSectionTypes.Explore)">{{ 'rewards.explore' |
                              translate }}<mark>{{ 'rewards.explore' | translate }}</mark></span>
                      </div>
                      <div class="not-selectable"
                          [ngClass]="{'active': rewardSectionType === rewardSectionTypes.MyVoucher }">
                          <span (click)="onSwitchVoucherType(rewardSectionTypes.MyVoucher)">{{ 'rewards.my.voucher' |
                              translate }}<mark>{{ 'rewards.my.voucher' | translate }}</mark>
                          </span>
                      </div>
                      <div class="not-selectable"
                          [ngClass]="{'active': rewardSectionType === rewardSectionTypes.Membership }">
                          <span (click)="onSwitchVoucherType(rewardSectionTypes.Membership)">{{ 'MEMBERSHIP' |
                              translate }}<mark>{{ 'MEMBERSHIP' | translate }}</mark>
                          </span>
                      </div>
                  </div>

                  <!-- Explore & My Voucher menu -->
                  <div class="menu-container"
                      *ngIf="categories.length > 0 && rewardSectionType !== rewardSectionTypes.Membership">
                      <div class="menu" [ngClass]="{ 'history': rewardSectionType === rewardSectionTypes.MyVoucher }">
                          <button type="button" class="btn-menu-mobile hidden-lg"
                              (click)="onShowMobileMenu()"><i class="oda-sort"></i></button>
                          <button type="button" class="btn-slide-left hidden-sm" #menuSlideLeftIcon
                              (click)="onSlidePrev();"><i class="oda-chevron-left"></i></button>

                          <swiper [slidesPerView]="'auto'" [grabCursor]="true" #swiper class="swiper-menu"
                              (slideChange)="onSlideChange();">
                              <ng-template swiperSlide *ngIf="categories">
                                  <input type="radio" name="category" value="0" id="menu-0" class="radio"
                                      (change)="onMenuChange(0);" [checked]="categoryId === 0">
                                  <label class="category not-selectable" for="menu-0">{{ "cart.all.button" | translate
                                      }}</label>
                              </ng-template>
                              <ng-template swiperSlide class="category-wrapper" *ngFor="let category of categories">
                                  <input type="radio" name="category" value="{{ category.id }}"
                                      id="menu-{{ category.id }}" class="radio" (change)="onMenuChange(category.id);"
                                      [checked]="categoryId === category.id">
                                  <label class="category not-selectable" for="menu-{{ category.id }}">{{ category.desc
                                      | translate }}</label>
                              </ng-template>
                          </swiper>
                      </div>
                      <div dropdown class="menu-dropdown hidden-sm" (isOpenChange)="onShowMenu($event)">
                          <button type="button" id="reward-menu-dropdown" #menuIcon dropdownToggle
                              aria-controls="reward-menu-dropdown">
                              <i class="oda-chevron-down"></i>
                          </button>
                          <ul class="dropdown-menu" *dropdownMenu role="menu" aria-labelledby="reward-menu-dropdown">
                              <li>
                                  <input type="radio" name="category-dropdown" value="0" id="menu-dropdown-0"
                                      class="radio" (change)="onMenuChange(0);" [checked]="categoryId === 0">
                                  <label class="dropdown-item" for="menu-dropdown-0">{{ 'cart.all.button' | translate
                                      }}</label>
                              </li>
                              <li *ngFor="let category of categories" id="el-menu-dropdown-{{category.id }}">
                                  <input type="radio" name="category-dropdown" value="{{ category.id }}"
                                      id="menu-dropdown-{{ category.id }}" class="radio"
                                      (change)="onMenuChange(category.id);" [checked]="categoryId === category.id">
                                  <label class="dropdown-item" for="menu-dropdown-{{ category.id }}">{{ category.desc
                                      | translate }}</label>
                              </li>
                          </ul>
                      </div>
                      <button class="hidden-sm btn-history" *ngIf="rewardSectionType === rewardSectionTypes.MyVoucher"
                          routerLink="/voucher-history">{{ 'my.order.history' | translate }}</button>
                      <form (ngSubmit)="onSearch()" [formGroup]="searchFgroup" class="search-bar">
                          <i class="oda-ticket"></i>
                          <input type="text" pInputText placeholder="{{ 'voucher.text.hint.des.1' | translate }}"
                              formControlName="searchText" #searchBar />
                          <button type="button" class="btn-close" *ngIf="searchF.searchText?.value?.length > 0"
                              (click)="onSearch(true);"><i class="oda-times"></i></button>
                      </form>
                  </div>

                  <!-- Membership menu -->
                  <div class="menu-container" *ngIf="rewardSectionType === rewardSectionTypes.Membership"
                      [ngClass]="{'member-search-bar' : rewardSectionType === rewardSectionTypes.Membership}">
                      <form [formGroup]="searchFgroup" class="search-bar" [ngStyle]="{ 'margin-top': rewardSectionType === rewardSectionTypes.Membership ? '0px': '17px'}" (ngSubmit)="onSearch();">
                          <i class="oda-search"></i>
                          <input type="text" pInputText placeholder="{{ 'membership.search' | translate }}"
                              formControlName="searchText" #searchBar />
                          <button type="button" class="btn-close" *ngIf="searchF.searchText?.value?.length > 0"
                              (click)="onSearch(true)"><i class="oda-times"></i></button>
                      </form>
                  </div>
              </div>
          </div>
      </div>

      <!-- Explore & My Voucher content -->
      <div class="content-container"
          *ngIf="(categories.length > 0 && ((rewards && rewards.length > 0) || (myRewardList && myRewardList.length > 0) && myRewardCatgList)) && rewardSectionType !== rewardSectionTypes.Membership">
          <!-- Explore -->
          <div class="card-container" *ngIf="rewardSectionType === rewardSectionTypes.Explore">
              <div class="card-wrapper" *ngFor="let reward of rewards">
                  <app-reward-card [exploreReward]="reward" [showStoreName]="true" [customCardType]="customCardType"
                      [isLoggedIn]="isLoggedIn" [channelId]="channelId" ></app-reward-card>
              </div>
          </div>
          <!-- My Voucher -->
          <div class="card-group-container" *ngIf="rewardSectionType === rewardSectionTypes.MyVoucher">
              <div class="store-name sticky" [hidden]="!isHeaderSticky" #storeHeader><span></span></div>
              <div class="card-group" *ngFor="let myRewardList of myRewardCatgList; let i = index;">
                  <div class="store-name-container" id="storeContainer-{{ i + 1 }}"></div>
                  <div class="store-name" id="storeName-{{ i + 1 }}">{{ myRewardList.merchantDesc | titlecase }}</div>
                  <div class="card-container">
                      <div class="card-wrapper" *ngFor="let myReward of myRewardList.vouchers">
                          <app-reward-card [myReward]="myReward" [showStoreName]="false"
                              [customCardType]="customCardType" [channelId]="channelId"></app-reward-card>
                      </div>
                  </div>
              </div>
          </div>
          <ng-template [ngIf]="isOnListLoad">
              <div class="pagination-loader">
                  <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
              </div>
          </ng-template>
      </div>

      <!-- Membership content -->
      <div class="content-container"
          *ngIf="rewardSectionType === rewardSectionTypes.Membership && memberDetail.length > 0;">
          <div class="card-container">
              <div class="card-wrapper" *ngFor="let itm of memberDetail">
                  <app-membership-card [isRewardPage]="true" [membershipReward]="itm"></app-membership-card>
              </div>
          </div>
          <ng-template [ngIf]="isOnListLoad">
              <div class="pagination-loader">
                  <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
              </div>
          </ng-template>
      </div>

      <ng-template
          [ngIf]="(categories.length <= 0 || ((rewardSectionType === rewardSectionTypes.Explore && rewards && rewards.length <= 0) || (rewardSectionType === rewardSectionTypes.MyVoucher && myRewardList && myRewardList.length <= 0) && categories.length > 0)) && !isOnListLoad && !isOnLoad && !isOnFirstLoad && rewardSectionType !== rewardSectionTypes.Membership">
          <div class="no-reward">
              <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
              <h1 class="title">{{ 'rewards.result.empty.des.1' | translate }}</h1>
              <p class="desc" *ngIf="rewardSectionType === rewardSectionTypes.Explore && !searchedText">{{
                  'rewards.result.empty.des.2' | translate }}</p>
              <p class="desc" *ngIf="rewardSectionType === rewardSectionTypes.MyVoucher && !searchedText">{{
                  'rewards.result.empty.des.3' | translate }}</p>
              <p class="desc" *ngIf="searchedText">{{ 'rewards.result.empty.des.4' | translate: { searchText:
                  searchedText } }}</p>
              <button type="button" (click)="onSwitchVoucherType(rewardSectionTypes.Explore, true);"
                  *ngIf="rewardSectionType === rewardSectionTypes.Explore && !searchedText">{{
                  "button.rewards.all.vouchers" | translate }}</button>
              <button type="button" (click)="onSwitchVoucherType(rewardSectionTypes.MyVoucher);"
                  *ngIf="rewardSectionType === rewardSectionTypes.MyVoucher && !searchedText">{{
                  "button.rewards.explore.voucher" | translate }}</button>
              <button type="button" (click)="onSearch(rewardSectionTypes.Explore);" *ngIf="searchedText">{{
                  "merchant.search.again.food.button" | translate }}</button>
          </div>
      </ng-template>

      <!--empty membership and empty search result for membership-->
      <ng-template
          [ngIf]="rewardSectionType === rewardSectionTypes.Membership && memberDetail && memberDetail.length <= 0 && !isOnListLoad">
          <div class="empty-membership">
              <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot">
              <h1 *ngIf="!searchedText">{{'membership.empty.desc.1' | translate }}</h1>
              <h1 *ngIf="searchedText">{{'membership.result.empty.des.1' | translate: { searchText: searchedText } }}
              </h1>
              <button type="button" (click)="onSearch(true);"
                  *ngIf="searchedText">{{'merchant.search.again.food.button' | translate}}</button>
          </div>
      </ng-template>

      <ng-template [ngIf]="isOnListLoad">
          <div class="list-loader">
              <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation" />
          </div>
      </ng-template>

      <!-- Menu Dialog -->
      <p-dialog [(visible)]="showMobileMenu" [modal]=true [dismissableMask]=true [draggable]=false
          [blockScroll]="true" class="dialog-menu hidden-lg">
          <ng-template pTemplate="header">
              <h1><i class="oda-sort"></i>{{ 'rewards.voucher.category' | translate }}</h1>
          </ng-template>
          <ul class="menu-list">
              <li>
                  <input type="radio" name="category-mobile" value="0" id="menu-mobile-0" class="radio"
                      (change)="onMenuChange(0);" [checked]="categoryId === 0">
                  <label for="menu-0">{{ "cart.all.button" | translate }}</label>
              </li>
              <li *ngFor="let category of categories">
                  <input type="radio" name="category-mobile" value="{{ category.id }}"
                      id="menu-mobile-{{ category.id }}" class="radio" (change)="onMenuChange(category.id);"
                      [checked]="categoryId === category.id">
                  <label for="menu-mobile-{{ category.id }}">{{ category.desc | translate }}</label>
              </li>
          </ul>
      </p-dialog>

      <!-- Detail Dialog-->
      <p-dialog [(visible)]="showDetail" [modal]=true [draggable]=false class="dialog-detail" [showHeader]="false"
          *ngIf="showDetail && exploreRewardDetail || myRewardDetail">
          <app-reward-detail
            [exploreReward]="exploreRewardDetail"
            [myReward]="myRewardDetail"
            [customCardType]="customCardTypeDetail"
            [currentCart]="cartModel"
            [channelId]="channelId">
          </app-reward-detail>
      </p-dialog>

      <!-- Share Dialog -->
      <p-dialog [(visible)]="showShare" [modal]=true [draggable]=false class="dialog-share"
          (onShow)="blockScroll(true);" (visibleChange)="showDetail = true;" *ngIf="showShare">
          <h1 class="title">{{ 'button.share' | translate }}</h1>
          <div class="share-btn">
              <app-social-share [url]="shareUrl" [title]="shareStoreName"
                  [description]="shareStoreName? ('share.store.description' | translate: { storeName: shareStoreName }): ''">
              </app-social-share>
          </div>
          <h2 class="sub-title">{{ 'copy.link' | translate}}</h2>
          <div class="url-container">
              <div class="heading-9 url-sub-content">
                  <h1 class="heading-9 text-truncate" name="url-content" id="url-content">{{ shareUrl }}</h1>
              </div>
              <div class="copy-btn">
                  <ngx-copy-to-clipboard target="#url-content" [success]="onUrlCopied"><i class="oda-copy"></i>
                  </ngx-copy-to-clipboard>
              </div>
              <div class="copied-text" *ngIf="isCopied">{{ "rewards.copied" | translate }}</div>
          </div>
      </p-dialog>

      <!-- Store list Dialog -->
      <p-dialog [(visible)]="showStore" [modal]=true [draggable]=false [dismissableMask]="true"
          class="dialog-store" *ngIf="showStore && storeList">
          <app-nearby-store
            [storeList]="storeList"
            [currency]="currency"
            [showRewardDialog]="rewardSectionType === rewardSectionTypes.Explore"
            [currentCart]="cartModel">
          </app-nearby-store>
      </p-dialog>

      <!-- QR Dialog -->
      <p-dialog [(visible)]="showQr" [modal]=true [draggable]=false [dismissableMask]="true"
          class="dialog-qr" (onShow)="blockScroll(true);" *ngIf="showQr && myRewardQr">
          <h1 class="title">{{ myRewardQr.vchCatgCode === catgCodes.ODARING? catgCodes.ODARING :
              myRewardQr.merchantDesc }}</h1>
          <app-reward-card [myReward]="myRewardQr" [showStoreName]="false" [customCardType]="cardTypes.store" [channelId]="channelId"
              *ngIf="myRewardQr.vchCatgCode !== catgCodes.ODARING && myRewardQr.storeResponseList.length > 0">
          </app-reward-card>
          <p class="text-or"
              *ngIf="myRewardQr.vchCatgCode !== catgCodes.ODARING && myRewardQr.storeResponseList.length > 0">{{
              'opt.form.des.1' | translate }}</p>
          <div class="qr-container">
              <h2>{{ 'qr.scan.des.1' | translate }}</h2>
              <ejs-qrcodegenerator style="display: block;" #qrcode id="qrcode"
                  width="{{ isMobile? '130px': '160px' }}" height="{{ isMobile? '130px': '160px' }}" mode="SVG"
                  value="{{ myRewardQr.voucherNo? myRewardQr.voucherNo: '' }}"
                  (click)="onShowZoomQr();"></ejs-qrcodegenerator>
              <p>{{ 'qr.scan.des.3' | translate }}</p>
          </div>
      </p-dialog>

      <!-- QR Zoom Dialog -->
      <p-dialog [(visible)]="showQrZoom" [draggable]=false [showHeader]="false" class="dialog-zoom-qr"
          (onShow)="blockScroll(true);" *ngIf="showQrZoom && myRewardQr">
          <div class="header-mobile hidden-lg">
              <i class="oda-chevron-left btn-back" (click)="onBackZoomQr()"></i>
              <h1>{{ "rewards.qr.code" | translate }}</h1>
          </div>
          <div class="header hidden-sm">
              <i class="oda-chevron-left btn-back" (click)="onBackZoomQr()"></i>
              <h1 (click)="onBackZoomQr()">{{ "button.back" | translate }}</h1>
          </div>
          <div class="content-container">
              <div class="barcode-container" *ngIf="myRewardQr && myRewardQr?.voucherNo">
                  <h2>{{ 'qr.scan.des.1' | translate }}</h2>
                  <ejs-barcodegenerator style="display: block" #barcode id="barcode"
                      width="{{ isMobile? '272px': '584px' }}" height="{{ isMobile? '72px': '155px'}}" mode="SVG"
                      type="Code128"
                      value="{{ myRewardQr.voucherNo? myRewardQr.voucherNo: '' }}">
                  </ejs-barcodegenerator>
                  <p>{{ myRewardQr.voucherNo? myRewardQr.voucherNo: "" }}</p>
              </div>
              <div class="qrcode-container">
                  <h2>{{ 'qr.scan.des.2' | translate }}</h2>
                  <ejs-qrcodegenerator style="display: block;" #qrcode id="qrcode"
                      width="{{ isMobile? '160px': '350px' }}" height="{{ isMobile? '160px': '350px' }}" mode="SVG"
                      value="{{ myRewardQr.voucherNo? myRewardQr.voucherNo: '' }}">
                  </ejs-qrcodegenerator>
              </div>
          </div>
      </p-dialog>
  </div>
  <ng-template #noLogin>
      <div class="no-login-container">
          <div class="title">{{ 'navigation.Menu.des.7' | translate }}</div>
          <div class="no-login">
              <img class="mascot" src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
              <p>{{ 'rewards.login.to.view' | translate }}</p>
              <a routerLink="/login" class="btn-login">{{ 'button.login' | translate }}</a>
          </div>
      </div>
  </ng-template>
</div>
