import { SetCode } from 'src/app/core/enums/SetCode';
import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { QueueService } from './../../queue/queue/queue.service';
import { ToastService } from './../../shared/services/toast.service';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { StaticQrStore } from './static-qr.store';
import { StaticQrQuery } from './static-qr.query';
import { StaticQrState } from './static-qr.model';
import { UtilsService } from '../services/utils.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { OrderTypeFlag } from '../enums/OrderTypeFlag';
import { TimeService } from '../services/time.service';
import { StoreService } from 'src/app/store/store/store.service';
import { ToastData } from '../models/ToastData';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { Channel } from 'src/app/home/channel/channel.model';
import { RouteName } from '../enums/RouteName';
import { QrCartService } from '../qr-cart/qr-cart.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

/**
 * Static Qr service
 *
 * @export
 * @class StaticQrService
 */
@Injectable({ providedIn: 'root' })
export class StaticQrService {

  qrScannerTrigger$ : Subject<string> = new Subject()
  curChannel : Channel = null;

  constructor(
    private staticQrStore: StaticQrStore,
    private staticQrQuery: StaticQrQuery,
    private utilsService : UtilsService,
    private route: ActivatedRoute,
    private timeService : TimeService,
    private storeService: StoreService,
    private toastService : ToastService,
    private router: Router,
    private channelQuery : ChannelQuery,
    private queueService : QueueService,
    private qrCartService : QrCartService,
    private storageService : StorageService,
    private sessionStorageService: SessionStorageService
  ) {
    this.channelQuery.selectFirst().subscribe(channel => {
      if (channel) {
        this.curChannel = channel;
      } else {
        this.curChannel = null;
      }
    })
  }

  get() {
    return this.staticQrQuery.getValue();
  }

  getStaticQrData(): Observable<StaticQrState> {
    return this.staticQrQuery.select();
  }

  update(staticQrData: StaticQrState) {
    this.staticQrStore.setLoading(true);
    this.staticQrStore.update({
      channelId: staticQrData.channelId,
      orderType: staticQrData.orderType,
      tableNo: staticQrData.tableNo,
      guestCount: staticQrData.guestCount,
      storeId: staticQrData.storeId,
      exp: staticQrData.exp
    })
    this.staticQrStore.setLoading(false);
  }

  updateOrderType(passOrderType: string) {
    this.staticQrStore.setLoading(true);
    this.staticQrStore.update({
      orderType: passOrderType
    });
    this.staticQrStore.setLoading(false);
  }

  remove() {
    this.staticQrStore.setLoading(true);
    this.staticQrStore.reset();
    this.staticQrStore.setLoading(false);
  }

  async staticQrProcess(route : ActivatedRouteSnapshot) {
    const query = this.utilsService.toLowerQueryChar(route.queryParams);
    let queryOrderType = query.ordertype;
    let staticQrData = {} as StaticQrState;

    let staticQrCacheData = this.get();
    let staticOrderTypeData = this.getOrderTypeOnlyData();
    // if cache static data have table number and query have table number as well OR
    // cache static data dont have table number and query dont have table number as well
    // then we will not continue to do saving logic
    if(staticQrCacheData.tableNo && staticQrCacheData.orderType && query.tableno && query.ordertype
      && query.tableno == staticQrCacheData.tableNo && query.ordertype == staticQrCacheData.orderType
      && (!staticQrCacheData.storeId || staticQrCacheData.storeId && staticQrCacheData?.storeId == +route.params["storeId"])){
      return;
    }
    else if(staticOrderTypeData && staticOrderTypeData.storeId == +route.params["storeId"]){
      return;
    }

    if(queryOrderType && query.tableno){
      this.storageService.storeQrInfo(route.params["storeId"], window.location.href);
      this.timeService.storeIdleTime(new Date(), 6);
      staticQrData = await this.staticQrDataInit(queryOrderType, query.tableno, route.params["storeId"] ? +route.params["storeId"] : undefined);

      this.update(staticQrData);
      this.storeService.removeIsDisplayedFlag();

      // remove static order type only cache data
      this.removeOrderTypeOnlyData();

      // remove dynamic qr dine in data cause current mode is static
      this.qrCartService.quitQrDineInCheck();
    }
    else if(queryOrderType){
      queryOrderType = this.formatOrderType(queryOrderType);

      // remove qr info stored as this is another qr scan url but it does not save
      this.storageService.removeQrInfo();

      // save to session storage to represent as already taken once, so that it will not take from url again
      // unless they change tab
      this.storeOrderTypeOnlyData(queryOrderType, +route.params["storeId"], this.curChannel && this.curChannel?.channelId ? this.curChannel.channelId : undefined);

      // remove static qr dine in information as url only has order type
      this.remove();
      // remove dynamic qr dine in data just in case
      this.qrCartService.quitQrDineInCheck();
    }
  }

  async staticQrDataInit(orderType : string, tableNo : string, storeId? : number){
    let setting : ChannelPlatformSetResponse;
    let isShareTable : boolean = false;

    // if channel data exists then try to find the settings SHARETABLE
    if(this.curChannel && this.curChannel?.data && this.curChannel?.data?.platformSets){
      setting = this.curChannel.data.platformSets.find(settings => settings.setCode == SetCode.SHARETABLE);
      isShareTable = setting && setting?.setValue == "1" ? true : false;
    }

    // get existing static data stored to be used to check guestCount
    let currentStaticData = this.get();
    let staticQrData = {} as StaticQrState;
    if(!orderType && tableNo){
      staticQrData.orderType = OrderTypeFlag.DineIn;
    }
    else{
      staticQrData.orderType = orderType ? this.formatOrderType(orderType) : null;
    }
    staticQrData.tableNo = tableNo ? tableNo : null;
    // channel id will also be assign for change channel remove checking
    staticQrData.channelId = this.curChannel && this.curChannel?.channelId ? this.curChannel.channelId : undefined;

    // if share table setting exists and set code is 1
    if(storeId){
      staticQrData.storeId = !isShareTable ? storeId : undefined;
    }
    else {
      if(currentStaticData && currentStaticData.storeId){
        staticQrData.storeId = !isShareTable ? currentStaticData.storeId : undefined;
      }
      else{
        staticQrData.storeId = undefined;
      }
    }

    // if it is not share table and previous stored item have storeId then check if storeId OR table number is same or not
    // else if nothing is stored OR table number is different then reset guest count
    if(currentStaticData && currentStaticData.storeId && !isShareTable){
      staticQrData.guestCount = (currentStaticData.storeId != storeId) || (currentStaticData.tableNo != tableNo) ? 1 : currentStaticData.guestCount;
    }
    else{
      staticQrData.guestCount = (!currentStaticData) || (currentStaticData && currentStaticData.tableNo != tableNo) ? 1 : currentStaticData.guestCount;
    }
    let newUtcVal = this.timeService.getAdjustedLocalTimeInUtc();
    staticQrData.exp = this.timeService.formatDateString(newUtcVal);
    return staticQrData;
  }

  async staticDataUpdateInit(orderType : string, tableNo : string, guestCount : number){
    let staticQrData = {} as StaticQrState;
    staticQrData.orderType = orderType;
    staticQrData.tableNo = tableNo;
    staticQrData.guestCount = guestCount;
    staticQrData.channelId = this.curChannel && this.curChannel?.channelId ? this.curChannel.channelId : undefined;
    let newUtcVal = this.timeService.getAdjustedLocalTimeInUtc();
    staticQrData.exp = this.timeService.formatDateString(newUtcVal);
    return staticQrData;
  }

  async scanStaticQR(url : string){
    // remove queue data just in case when we scan for static qr
    this.queueService.removeQueueData();

    let scannedUrl = new URL(url);

    // get current route url
    let currentRouteUrl = new URL(window.location.href);

    if(scannedUrl.origin == currentRouteUrl.origin){
      // split scanned url path name by '/'
      let splittedPathName = scannedUrl.pathname.split('/');

      // split current route url path name by '/'
      let currentPathName = currentRouteUrl.pathname.split('/');

      if (splittedPathName[1] !== currentPathName[1]) {
        window.location.href = url
      }

      // create url search params
      let urlParam = new URLSearchParams(scannedUrl.search);
      // make url param key become lowercase
      urlParam = this.utilsService.lowerCaseSearchParam(urlParam);

      // if newly scanned url dont have order type and tableNo then straight away navigate them to that link
      if(!urlParam.get("ordertype") && !urlParam.get('tableno')){
        window.location.href = url;
      }

      let queryOrderType = this.formatOrderType(urlParam.get("ordertype"));
      let queryTableNo = urlParam.get('tableno');

      // check if current url contain store path
      let currentUrlStoreIndex = currentPathName.findIndex(val => val == "store");
      // check if scanned url contain store path
      let isStore = splittedPathName.findIndex(val => val == "store");

      if(isStore == -1){
        await this.staticQrStart(scannedUrl, queryOrderType, queryTableNo);
      }
      else{
        if (currentUrlStoreIndex == -1 ||
          (currentUrlStoreIndex != -1 && splittedPathName[isStore + 1] == currentPathName[currentUrlStoreIndex + 1])) {
          await this.staticQrStart(scannedUrl, queryOrderType, queryTableNo, +splittedPathName[isStore + 1]);
        }
        else if (currentUrlStoreIndex != -1 && splittedPathName[isStore + 1] != currentPathName[currentUrlStoreIndex + 1]) {
          window.location.href = url;
        }
      }
    }
    else{
      let isValid = await this.utilsService.checkUrlValidity(url);
      if(isValid){
        window.location.href = url;
      }
      else{
        // show invalid qr message
      }
    }
  }

  showUpdatedToast(){
    let toastData = {} as ToastData;
    toastData.message = "location.update.desc";
    toastData.icon = "oda-check-alt";
    toastData.iconColor = "#8CD600";
    this.toastService.show(toastData);
  }

  formatOrderType(orderType): string {
    if(!orderType){
      return null;
    }

    let formatedOrderType = '';
    if (orderType.toLowerCase() === OrderTypeFlag.DineIn.toLowerCase()) {
      formatedOrderType = OrderTypeFlag.DineIn;
    } else if (orderType.toLowerCase() === OrderTypeFlag.Pickup.toLowerCase()) {
      formatedOrderType = OrderTypeFlag.Pickup;
    } else if (orderType.toLowerCase() === OrderTypeFlag.Delivery.toLowerCase()) {
      formatedOrderType = OrderTypeFlag.Delivery;
    }

    return formatedOrderType;
  }

  removeStaticQrData(){
    let staticData = this.get();
    let localTime = this.timeService.getAdjustedLocalTimeInUtc();
    let timeIsAfter = this.timeService.compareIsTimeAfter(localTime.format(), staticData.exp, 12, 'hours');
    if(timeIsAfter){
      this.remove();
    }
  }

  async staticQrStart(scannedUrl: URL, queryOrderType: string, queryTableNo: string, storeId? : number) {
    let staticQrData = await this.staticQrDataInit(queryOrderType, queryTableNo, storeId);
    if(staticQrData.orderType && staticQrData?.orderType?.toLowerCase() == OrderTypeFlag.DineIn.toLowerCase()){
      this.update(staticQrData);

      if (scannedUrl.href.includes(this.router.url)) {
        window.history.replaceState(null, '', scannedUrl.href);
      }

      this.showUpdatedToast();
      this.qrScannerTrigger$.next(staticQrData.orderType);
    } else {
      window.location.href = scannedUrl.href;
    }
  }

  async staticDataClearCheck(newChannelId : number){
    let staticQrData = this.get();
    let staticOrderTypeOnlyData = this.getOrderTypeOnlyData();

    if(staticQrData.channelId != newChannelId){
      this.remove();
    }

    if(staticOrderTypeOnlyData && staticOrderTypeOnlyData?.channelId != newChannelId){
      this.removeOrderTypeOnlyData();
    }
  }

  async getStoreIdFromUrl(){
    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];

    return currentRoute == RouteName.Store ? splittedRoutes[2] : undefined;
  }

  saveChannelOrderType(orderType : string){
    this.sessionStorageService.setItem("channelOrderType", orderType);
  }

  getChannelOrderType(){
    let orderType = this.sessionStorageService.getItem("channelOrderType");
    return orderType ? orderType : null;
  }

  removeChannelOrderType(){
    this.sessionStorageService.removeItem("channelOrderType");
  }

  removeQueryFromUrl(){
    let currentUrl = window.location.href;
    let urlObj = new URL(currentUrl);
    let urlWithoutQuery = urlObj.origin + urlObj.pathname;
    history.replaceState('', '', urlWithoutQuery);
  }

  getOrderTypeFromurl(){
    let query = this.utilsService.toLowerQueryChar(this.route.snapshot.queryParams);
    let queryOrderType = query.ordertype;
    return queryOrderType ? queryOrderType : OrderTypeFlag.All;
  }

  //#region static order type only
  storeOrderTypeOnlyData(orderType : string, storeId : number, channelId : number){
    let staticOrderTypeData = { storeId : storeId, channelId : channelId, orderType: orderType };
    this.sessionStorageService.setItem("staticOrderTypeData", JSON.stringify(staticOrderTypeData));
  }

  getOrderTypeOnlyData(){
    return JSON.parse(this.sessionStorageService.getItem("staticOrderTypeData"));
  }

  removeOrderTypeOnlyData(){
    this.sessionStorageService.removeItem("staticOrderTypeData");
  }
  //#endregion
}
