import { Component, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Modifier } from 'src/app/core/models/Modifier';
import { StoreQuery } from 'src/app/store/store/store.query';
import * as _ from 'lodash';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { StoreStore } from 'src/app/store/store/store.store';
import { MenuItem } from 'src/app/core/models/MenuItem';
import { Subscription } from 'rxjs';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';
import { StoreService } from '../store/store.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MenuService } from 'src/app/core/menu/menu.service';
import * as deepClone from 'rfdc';
import { SubModifier } from 'src/app/core/models/SubModifier';

@Component({
  selector: 'app-store-item-submodifier',
  templateUrl: './store-item-submodifier.component.html',
  styleUrls: ['./store-item-submodifier.component.scss']
})
export class StoreItemSubmodifierComponent implements OnInit, OnDestroy {

  //for scrolling and getting ids for scrolling
  @ViewChild('menu_nav_bar') mileStoneNavbar;
  @ViewChild('parent') parentContainer : ElementRef

  @Input() storeId : number;
  @Input() currencyCode : string;
  @Input() isSubModifier : boolean;

  @Output() displayMaximumMessage  = new EventEmitter();

  curModifier : Modifier;

  //for controlling scroll logic
  scrollMode : number = 2;
  allIds : string[];
  selectedElement : any;
  selectedSection : string;

  //testing
  allComplete : boolean = false;
  isMobileView : boolean;

  //subscription
  sub : Subscription;
  sub3 : Subscription;
  breakpointSub : Subscription;

  constructor(
    private storeQuery : StoreQuery,
    private storeStore : StoreStore,
    private storeService : StoreService,
    private breakpointObserver : BreakpointObserver,
    private menuService : MenuService
  ) {
    this.breakpointSub = this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  ngOnInit(): void {
    this.sub = this.menuService.selectedModifier$.subscribe(selectedModifier => {
      this.curModifier = deepClone({proto: true})(selectedModifier);
      if(selectedModifier){
        this.checkRequiredSection();
      }
    })

    this.sub3 = this.menuService.updateSelectedSubMod$.subscribe(modifier => {
      if(modifier){
        this.updateSubModifier(modifier);
      }
    })

    this.selectedSection = "ITM" + this.curModifier.subModifierGrps[0].code;
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
    this.sub3.unsubscribe();
    this.breakpointSub?.unsubscribe();
  }

  closeSubDialog(){
    this.storeService.openCustomize$.next(false);
  }

  // this method is call by output when store-item-list -> store-item-content
  checkRequiredSection(){
    let requiredCounter = 0
    requiredCounter = this.curModifier.subModifierGrps.filter(val => (val.isComplete == true) ||  (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length; //since we update isComplete then we should use it

    if(requiredCounter == this.curModifier.subModifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }
  }

  scrollToSection(id){
    // debugger
    this.scrollMode = 1;

    const prefix = "ITM";

    let containerMarginTop = this.isMobileView ? (120 + 24) : (139 + 24);
    const indexs = Object.entries(this.mileStoneNavbar.nativeElement.children.item(0).children).map(val => (val[1] as any).getAttribute('id')).indexOf(prefix + id);
    this.selectedSection = this.mileStoneNavbar.nativeElement.children.item(0).children.item(indexs).getAttribute('id');
    this.selectedElement = "SECTT" + id;

    for(let i = indexs; i > 0 ; i--){
      if(this.curModifier.subModifierGrps[i - 1].qtyGrp >= this.curModifier.subModifierGrps[i - 1].minSelect){
        this.curModifier.subModifierGrps[i - 1].isComplete = true;
      }
      else{
        this.curModifier.subModifierGrps[i - 1].isComplete = false;
      }
    }

    let requiredCounter = 0
    requiredCounter = this.curModifier.subModifierGrps.filter(val => val.isComplete == true).length;

    if(requiredCounter == this.curModifier.subModifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }

    // trigger manual change detection
    this.curModifier = deepClone({proto: true})(this.curModifier);

    let currentElementIndex = Object.entries(this.parentContainer.nativeElement.children.item(3).children.item(0).children)
    .map(val => (val[1] as any).getAttribute('id')).findIndex(element => element == this.selectedElement);
    let currentElement = this.parentContainer.nativeElement.children.item(3).children.item(0).children.item(currentElementIndex);

    this.parentContainer.nativeElement.scrollTo({
      top: currentElement.offsetTop - containerMarginTop,
      behavior: 'smooth'
    })

    setTimeout(() => {
      this.scrollMode = 2;
    }, 600);

  }

  // method for CONTINUE button in submodifier state
  async addToSubModifier(){
    this.scrollMode = 1;
    let sectionPrefix = "SECTT";
    let itemPrefix = "ITM";

    await this.checkIfSubmodifierComplete(); //check if all submodifier complete

    await this.checkIfAllComplete(); //check for all complete flag

    if(this.allComplete == false){
      let incompleteSection = this.curModifier.subModifierGrps.filter(val => (val.isComplete == false) || (val.isComplete == undefined && val.qtyGrp < val.minSelect));
      this.selectedSection = itemPrefix + incompleteSection[0].code;
      this.selectedElement = sectionPrefix + incompleteSection[0].code;

      const el = document.getElementById(this.selectedElement);
      el.scrollIntoView({behavior: 'smooth'});

      setTimeout(() => {
        this.scrollMode = 2;
      }, 1000);
    }
    else{
      let modGrpCode = this.storeService.getModGrpCode();
      this.menuService.updateCustomizeItem$.next({modGrpCode: modGrpCode, curModifier: this.curModifier});
      this.storeService.openCustomize$.next(false);
    }


  }

  onScrollSub(event){
    let curEl = document.getElementById(this.selectedElement);

    if(curEl != null){
      if((event.target.offsetHeight + event.target.scrollTop < event.target.scrollHeight) && this.scrollMode == 1){
        return;
      }
    }

    let containerMarginTop = this.isMobileView ? (120 + 24) : (139 + 24);
    this.allIds = Object.entries(this.mileStoneNavbar.nativeElement.children.item(0).children).map(val => (val[1] as any).getAttribute('id'));
    let itemIds = this.allIds.map(val => val.split('ITM')[1]);
    let ids : number;
    let allItem = this.mileStoneNavbar.nativeElement.children.item(0).children;

    if(itemIds){
      for(let i = 0; i< this.allIds.length; i++){
        let el = this.parentContainer.nativeElement.children.item(3).children.item(0).children.item(i);
        let elInFront = this.parentContainer.nativeElement.children.item(3).children.item(0).children.item(i + 1);

        if(elInFront == null){
          if(event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight){
            ids = allItem.length - 1;

            if(this.curModifier.subModifierGrps[allItem.length - 1].qtyGrp == this.curModifier.subModifierGrps[allItem.length - 1].minSelect){
              this.curModifier.subModifierGrps[allItem.length - 1].isComplete = true;
            }
            else{
              this.curModifier.subModifierGrps[allItem.length - 1].isComplete = false;
            }

            if(this.selectedSection != this.allIds[allItem.length - 1]){
              this.selectedSection = this.allIds[allItem.length - 1];

              if(this.curModifier.subModifierGrps[allItem.length - 2].qtyGrp >= this.curModifier.subModifierGrps[allItem.length - 2].minSelect){
                this.curModifier.subModifierGrps[allItem.length - 2].isComplete = true;
              }
              else{
                this.curModifier.subModifierGrps[allItem.length - 2].isComplete = false;
              }

              // trigger change detection
              this.curModifier = deepClone({proto: true})(this.curModifier);
            }

          }
        }
        else{
          if(event.target.scrollTop >= (el.offsetTop - containerMarginTop) && event.target.scrollTop < (elInFront.offsetTop - containerMarginTop)){
            ids = i;
            if(this.selectedSection != this.allIds[i]){
              this.selectedSection = this.allIds[i];

              if(this.curModifier.subModifierGrps[i - 1]){
                if(this.curModifier.subModifierGrps[i - 1].qtyGrp >= this.curModifier.subModifierGrps[i - 1].minSelect){
                  this.curModifier.subModifierGrps[i - 1].isComplete = true;
                }
                else{
                  this.curModifier.subModifierGrps[i - 1].isComplete = false;
                }

                // trigger change detection
                this.curModifier = deepClone({proto: true})(this.curModifier);
              }

            }
          }
        }
      }
    }

    let requiredCounter = 0
    requiredCounter = this.curModifier.subModifierGrps.filter(val => val.qtyGrp >= val.minSelect).length;

    if(requiredCounter == this.curModifier.subModifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }

    let preItm = allItem[ids - 1]? allItem[ids - 1] : null;
    let psItm = allItem[ids + 1]? allItem[ids + 1] : null;
    let scrollLeft = this.mileStoneNavbar.nativeElement.children.item(0).offsetLeft - 31; //children.item(0)

    if(!preItm){
      scrollLeft = allItem[0].offsetLeft - 31;
    }
    else if(!psItm){
      scrollLeft = allItem[allItem.length - 1].offsetLeft;
    }
    else if(preItm && psItm){
      scrollLeft = psItm.offsetLeft - ((psItm.offsetLeft - preItm.offsetLeft));
    }
    // else{
    //   scrollLeft = scrollLeft;
    // }

    this.mileStoneNavbar.nativeElement.children.item(0).scrollLeft = scrollLeft;

  }

  async checkIfSubmodifierComplete(){
    this.curModifier.subModifierGrps.forEach(subModGrp => {
      if(subModGrp.qtyGrp >= subModGrp.minSelect) {
        subModGrp.isComplete = true;
      }
      else{
        subModGrp.isComplete = false;
      }
    })

    this.curModifier = deepClone({proto: true})(this.curModifier);
  }

  async checkIfAllComplete(){
    let requiredCounter = 0
    requiredCounter = this.curModifier.subModifierGrps.filter(val => (val.isComplete == true) ||  (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length; //since we update isComplete then we should use it

    if(requiredCounter == this.curModifier.subModifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }
  }

  showMaximumMessage(){
    this.displayMaximumMessage.emit();
  }

  async updateSubModifier(subModGrp: SubModifierGrp){
    const index = this.curModifier.subModifierGrps.findIndex(val => val.code == subModGrp.code);
    this.curModifier.subModifierGrps[index] = subModGrp;

    this.curModifier = await this.loopSubModGrpIsMax(this.curModifier);

    // trigger change detection
    this.curModifier = deepClone({proto: true})(this.curModifier);
    this.checkRequiredSection();
  }

  async loopSubModGrpIsMax(curModifier : Modifier){
    let subModGrpLength = curModifier.subModifierGrps.length;
    for(let index = 0; index < subModGrpLength; index++){
      curModifier.subModifierGrps[index].subModifiers = await this.removeAllIsMaxFlag(curModifier.subModifierGrps[index].subModifiers);
    }

    return curModifier;
  }

  async removeAllIsMaxFlag(subModifiers : SubModifier[]){
    let subModLength = subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      subModifiers[index].isMax = false;
    }
    return subModifiers;
  }
}
