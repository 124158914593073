import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { CartService } from 'src/app/core/cart/cart.service';
import { MenuService } from 'src/app/core/menu/menu.service';
import { MenuCatg } from 'src/app/core/models/MenuCatg';
import { UtilsService } from 'src/app/core/services/utils.service';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { StoreItemStatsService } from 'src/app/core/store-item-stats/store-item-stats.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { OrderService } from 'src/app/home/order/order.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { StoreService } from 'src/app/store/store/store.service';
import * as _ from 'lodash';
import { Channel } from 'src/app/home/channel/channel.model';
import { ActivityType } from 'src/app/core/enums/ActivityType';

export const StoreResolver: ResolveFn<Channel> = async(route, state) => {
  const channelService: ChannelService = inject(ChannelService);
  const menuService : MenuService = inject(MenuService);
  const storeService : StoreService = inject(StoreService);
  const storeItemStatsService : StoreItemStatsService = inject(StoreItemStatsService);
  const userQuery : UserQuery = inject(UserQuery);
  const staticQrService : StaticQrService = inject(StaticQrService);
  const utilsService : UtilsService = inject(UtilsService);
  const orderService : OrderService = inject(OrderService);
  const cartService : CartService = inject(CartService);
  const sessionStorageService: SessionStorageService = inject(SessionStorageService);

  let storeId = Number(route.params["storeId"]);
  let user = userQuery.getAll()[0];
  let menu = menuService.getMenu(storeId);
  let store = storeService.getEntityById(storeId);
  let externalCacheMembershipString = sessionStorageService.getItem("externalMembership-data");
  let externalCacheMembershipObj = externalCacheMembershipString ? JSON.parse(externalCacheMembershipString) : null
  let extMembershipData = externalCacheMembershipObj ? externalCacheMembershipObj.membershipDetailResponse : null;

  // get query for URL before going into store
  const query = utilsService.toLowerQueryChar(route.queryParams);
  // check if query have order type or not, if not then assign undefined
  const queryOrderType = query.ordertype ? query.ordertype : undefined;

  // if order type existed then save order type into session storage as "channelOrderType"
  // also set current store order type
  if(queryOrderType){
    staticQrService.saveChannelOrderType(queryOrderType);
    storeService.setCurrentOrderType(queryOrderType);
  }

  if(menu && menu?.menuResponse){
    let menuCatg = menu.menuResponse[0].menuSets[0].menuCatgs;
    if((!user && haveRecommendedSection(menuCatg)) || (user && !haveRecommendedSection(menuCatg))){
      menuService.removeMenu(storeId);
      storeService.remove(storeId);
      storeItemStatsService.removeStoreItemStats(storeId);
    }
  }

  if(store && store?.merchantMemberships && store?.merchantMemberships?.length > 0 && store?.merchantMemberships[0]?.memberType != 2 && !extMembershipData){
    menuService.removeMenu(storeId);
    storeService.remove(storeId);
    storeItemStatsService.removeStoreItemStats(storeId);
  }

  // check for channel data, if no channel data then will call getChannelData API
  let channelData = await channelService.getChannelDataCheck();
  let staticOrderTypeData = staticQrService.getOrderTypeOnlyData();

  // if storeId of cache url data for order type only not the same as the current route
  // then remove the cache data
  if(staticOrderTypeData && (staticOrderTypeData?.storeId != +route.params["storeId"] || query.tableno)){
    staticQrService.removeOrderTypeOnlyData();
  }
  await staticQrService.staticQrProcess(route);

  let customerId = !user ? "null-" + channelData.channelTag : user.customerId.toString();
  let cart = _.cloneDeep(cartService.getCartValue(customerId));

  // if payTranId exist, it means that payment has been initiated and we will have to check cart
  if(cart && cart['payTranId']){
    // get order from getOrderSummary API with payTranId
    let paidOrders = await orderService.isPaymentSuccess(cart['payTranId'], channelData.channelTag);
    let successOrder = paidOrders.filter(val => val.lastActivityType != ActivityType.Payment_failed);
    // if there is orders returned then it means cart item have been converted to order
    if (successOrder && successOrder.length > 0 && cart.cartModel) {
      let orderStoreIds = paidOrders.map(order => order.storeId);
      cart.cartModel.orderHs = cart.cartModel.orderHs.filter(orderH => !orderStoreIds.includes(orderH.storeId));
      // remove payTranId store in cart
      cartService.upsertCart(null);
      // recalculate cart after remove the cart that have been paid
      await cartService.recalculateCartChecking(null, cart.cartModel, null, null);
    }
  }

  return channelData;
};

function haveRecommendedSection(menuCatg : MenuCatg[]){
  return menuCatg && menuCatg[0]?.code == "9999" ? true : false;
}


