import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CXMCustomerAddressResponse } from 'src/app/core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from './../../../shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {
  editUserAddress: CXMCustomerAddressResponse = null;
  isMobileView : boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastService : ToastService,
    private breakpointObserver : BreakpointObserver,
  ) { }

  ngOnInit(): void {
    this.editUserAddress = this.userService.getEditAddress();

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  async onUpdateAddress(data: any) {
    let resp = await this.userService.updateCustomerAddress(data);
    this.router.navigate(["account", "address"], { state: { addressUpdated: true } });
    if(!(resp instanceof HttpErrorResponse) && this.isMobileView){
      let toastData = {} as ToastData;
      toastData.icon = "oda-check";
      toastData.iconColor = "#FFFFFF";
      toastData.iconCircleColor = "#8CD600";
      toastData.showCircleIcon = true;
      toastData.message = "alert.manageAddress.des.11";
      this.toastService.show(toastData);
    }
  }

  backToPreviousPage(){
    this.router.navigate(["account", "address"]);
  }
}
