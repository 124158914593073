import { NavigationBehaviorOptions } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PopupMessage } from 'src/app/core/models/PopupMessage';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  styleUrls: ['./popup-message.component.scss']
})
export class PopupMessageComponent implements OnInit, OnDestroy {
  display: boolean;
  icon: string;
  iconImage: string;
  iconBgColor: string;
  iconColor: string;
  title: string;
  desc: string;
  btn: string;
  btnRound: boolean;
  name: string;
  showMobileOnly: boolean;
  showWebOnly: boolean;
  redirectLink: string;
  popupMsg$: Subscription;
  showPopupMsg$: Subscription;
  isImageIcon: boolean = false;
  navigationSettings : NavigationBehaviorOptions;

  constructor(private popupMessageService: PopupMessageService,
    private renderer: Renderer2,
    private router: Router) { }

  ngOnInit(): void {
    this.showPopupMsg$ = this.popupMessageService.showPopupMsg.subscribe( (data: boolean) => {
      this.display = data;
    });

    this.popupMsg$ = this.popupMessageService.popupMsg.subscribe( (data: PopupMessage) => {
      this.icon = data.icon? data.icon: '';
      this.iconImage = data.iconImage? data.iconImage: '';
      this.iconBgColor = data.iconBgColor? data.iconBgColor: '';
      this.iconColor = data.iconColor? data.iconColor: '';
      this.title = data.title;
      this.desc = data.desc;
      this.btn = data.btn;
      this.btnRound = data.btnRound? data.btnRound: false;
      this.name = data.name? data.name: '';
      this.showMobileOnly = data.showMobileOnly? data.showMobileOnly: false;
      this.showWebOnly = data.showWebOnly? data.showWebOnly: false;
      this.redirectLink = data.redirectLink? data.redirectLink: '';
      this.navigationSettings = data.navigationBehaviourOptions? data.navigationBehaviourOptions : null;

      let isIconBg: boolean;

      if( this.iconBgColor ) {
        this.iconBgColor = this.iconBackColorOption(this.iconBgColor);
        isIconBg = true;
      } else {
        isIconBg = false;
      }

      this.iconColor = this.iconColorOption(this.iconColor, isIconBg);
    });
  }

  onHide() {
    this.display = false;
    this.popupMessageService.onHide.next(this.name);

    if(this.redirectLink && !this.navigationSettings) {
      this.router.navigateByUrl(this.redirectLink);
    }
    else if(this.redirectLink && this.navigationSettings){
      this.router.navigateByUrl(this.redirectLink, this.navigationSettings);
    }
  }

  iconColorOption(iconColor: string, isIconBg: boolean): string {
    let color: string;

    switch(this.iconColor) {
      case "green": {
        color = isIconBg? "#FFFFFF" : "#8CD600";
        break;
      }
      case "red": {
        color = isIconBg? "#FFFFFF" : "#EB3600";
        break;
      }
      case "grey": {
        color = "#B1B1B1";
        break;
      }
      default: {
         color = iconColor;
         break;
      }
   }

    return color;
  }

  iconBackColorOption(iconBgColor: string): string {
    let bgColor: string;

    switch(this.iconBgColor) {
      case "green": {
        bgColor = "#8CD600";
        break;
      }
      case "red": {
        bgColor = "#EB3600";
        break;
      }
      case "grey": {
        bgColor = "#F4F4F4";
        break;
      }
      default: {
         bgColor = iconBgColor;
         break;
      }
   }

    return bgColor;
  }

  @HostListener('window:resize', ['$event'])
  setBlockScroll() {
    if(this.display && ((window.innerWidth <= 991 && this.showMobileOnly) || (window.innerWidth > 991 && this.showWebOnly))) {
      this.renderer.addClass(document.body, 'p-overflow-hidden');
    } else {
      this.renderer.removeClass(document.body, 'p-overflow-hidden');
    }
  }

  ngOnDestroy() {
    this.showPopupMsg$.unsubscribe();
    this.popupMsg$.unsubscribe();
  }
}
