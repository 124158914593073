<div class="search-voucher-code">
    <div class="header-container">
        <i class="oda-times btn-close hidden-sm" (click)="onHide();"></i>
        <i class="oda-chevron-left btn-back hidden-lg" (click)="onHide();"></i>
        <h1 class="hidden-lg">{{ "rewards.voucher.code.title" | translate }}</h1>
    </div>
    <h1 class="hidden-sm">{{ "rewards.voucher.code.title" | translate }}</h1>
    <form (ngSubmit)="onSearch()" [formGroup]="searchFgroup" (keyup.enter)="onSearch();" class="search-bar">
        <i class="oda-ticket icon"></i>
        <input type="text" pInputText placeholder="{{ 'voucher.text.hint.des.1' | translate }}" formControlName="searchCode" #searchBar />
        <i class="oda-times btn-close" *ngIf="searchF.searchCode?.value?.length > 0" (click)="onSearch(true);"></i>
    </form>
    <div class="error hidden-sm" *ngIf="searchVouchers && searchVouchers.length <= 0 && searchedCode">
        <i class="oda-alert icon"></i>
        <p>{{ "rewards.no.voucher.found.error" | translate }}</p>
      </div>
    <div class="search-result-container" *ngIf="searchVouchers && searchVouchers.length > 0">
        <div class="card-container">
            <div class="card-wrapper" *ngFor="let searchVoucher of searchVouchers">
                <app-reward-card [exploreReward]="searchVoucher" [isLoggedIn]="isLoggedIn" [channelId]="channelId" [storeId]="storeId"></app-reward-card>
            </div>
        </div>
    </div>
    <ng-template [ngIf]="!isLoading && searchVouchers && searchVouchers.length <= 0 && searchedCode">
      <div class="no-reward hidden-lg">
          <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
          <h1 class="title">{{ 'rewards.result.empty.des.1' | translate }}</h1>
          <p class="desc">{{ 'rewards.result.empty.des.4' | translate: { searchText: searchedCode } }}</p>
          <button type="button" (click)="onSearch(true);">{{ "merchant.search.again.food.button" | translate }}</button>
      </div>
    </ng-template>
</div>

