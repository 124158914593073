<div class="remove-all-message-container">
  <i class="oda-times hidden-sm" (click)="onClickCloseIcon()"></i>

  <div class="p-d-flex" style="justify-content: center;">
    <div class="trash_confirmation_icon">
      <i class="duotone oda-d-trash" style="color: #b1b1b1;"><span class="path1"></span><span class="path2"></span></i>
    </div>
  </div>

  <h3 class="order-type-title" *ngIf="isOrderType">{{"merchant.delete.cart.dialog.desc.1" | translate}}</h3>

  <div class="p-mt-3 heading-10" style="text-align: center;" *ngIf="(isStorePage && !isOrderType) || (!isStorePage && !isOrderType && (qrTokenResponse || queueResponse))">
    {{"merchant.store.delete.confirmation" | translate}}
  </div>

  <div class="p-mt-3 heading-10" style="text-align: center;" *ngIf="!isStorePage && !isOrderType && !qrTokenResponse && !queueResponse">
    {{"merchant.delete.confirmation" | translate}}
  </div>

  <p class="remove-description p-mt-3 heading-10" style="text-align: center;" *ngIf="isOrderType">
    {{"merchant.delete.cart.dialog.desc.2" | translate}}
  </p>

  <div class="remove-item-container" *ngIf="isStorePage || isOrderType">
    <button class="remove-item-cancel" (click)="onClickNo()">{{"button.cancel" | translate}}</button>
    <button class="remove-item-action-button" (click)="onClickYes()">{{"button.remove.item.with.s" | translate}}</button>
  </div>

  <div class="remove-item-container" *ngIf="!isStorePage && !isOrderType">
    <button class="remove-item-cancel" (click)="onClickNo()">{{"button.cancel" | translate}}</button>
    <button class="remove-item-action-button" (click)="onClickYes()" *ngIf="!qrTokenResponse && !queueResponse">{{"button.remove.merchant" | translate}}</button>
    <button class="remove-item-action-button" (click)="onClickYes()" *ngIf="(qrTokenResponse || queueResponse)">{{"button.remove.item.with.s" | translate}}</button>
  </div>
</div>
