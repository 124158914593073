import { TimeoutError } from 'rxjs';
import { QueueService } from './../../queue/queue/queue.service';
import { JoinQueueRequest } from './../../core/models/JoinQueueRequest';
import { UserService } from './../../core/user/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { mapLoginResp, User } from 'src/app/core/user/user.model';
import { ProfileService } from '../services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { PageName } from 'src/app/core/enums';
import { ConfigService } from 'src/app/config.service';
import { SignupForm } from '../models/SignupForm';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-complete-profile',
  templateUrl: './complete-profile.component.html',
  styleUrls: ['./complete-profile.component.scss'],
  providers: [ProfileService]
})
export class CompleteProfileComponent implements OnInit, OnDestroy {
  fgroup: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    email: ['', [Validators.email, Validators.pattern(this.utilsService.emailPattern)]],
    dob: ['']
  });
  currentUser: User = null;
  otpCodeState: string = '';
  profileError: HttpErrorResponse = null;
  errorCode = ErrorCode;
  pageNames: any = PageName;
  updateProfileResult: any = null;
  isQueue : boolean = false;
  isQueueRegister : boolean = false;
  displayRegisterFailedMsg : boolean = false;
  dialogPosition : string;
  isMobileView : boolean;
  joinQueueRequest : JoinQueueRequest = null;
  canRegister : boolean = false;
  maxYearRange: number;
  maxDate: Date = new Date();
  displayError: boolean = false;
  errorField: string;
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private profileService: ProfileService,
    private router: Router,
    private utilsService: UtilsService,
    private route : ActivatedRoute,
    private configService: ConfigService,
    private queueService : QueueService,
    private breakpointObserver : BreakpointObserver,
    private sessionStorageService: SessionStorageService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.dialogPosition = "bottom";
        this.isMobileView = true;
      }
      else{
        this.dialogPosition = "center";
        this.isMobileView = false;
      }
    })
  }

  ngOnInit(): void {
    const currentDate = new Date();
    this.maxYearRange = currentDate.getFullYear();
    this.currentUser = this.userService.getCustomer();
    this.otpCodeState = history.state?.otpCodeState;
    this.isQueue = this.route.snapshot.data['isQueue'] ? this.route.snapshot.data['isQueue'] : false;
    this.canRegister = this.queueService.getCanQueueRegister();

    this.f.email.statusChanges.subscribe(formStatus => {
      if (formStatus === 'INVALID') {
        this.displayError = false
      }
    });
  }

  ngOnDestroy(): void {
    this.queueService.removeCanQueueRegisterFlag();
  }

  async onSubmit() {
    if (this.fg.invalid) {
      return;
    }

    let user: User = {
      name: this.fgroup.value['name'],
      email: this.fgroup.value['email'],
      dob: this.fgroup.value['dob']
    };

    if(this.isQueue){
      // get join request from session storage
      let queueRequest = {} as JoinQueueRequest;
      queueRequest = JSON.parse(this.sessionStorageService.getItem("queueRequest"));
      queueRequest.customerName = user.name;

      if(!this.isQueueRegister){
        await this.queueService.onJoinQueueInit(queueRequest);
      }
      else{
        this.sessionStorageService.setItem("queueRequest", JSON.stringify(queueRequest));
        await this.onQueueRegister(queueRequest, user.email);
      }
    }
    else{
      this.updateProfileResult = await this.profileService.onUpdateProfile(user, true);

      if(this.updateProfileResult instanceof TimeoutError){
        let errorObj = new HttpErrorResponse({
          status: 500,
          statusText: "API Timeout"
        });
        this.profileError = errorObj;
      } else if (this.updateProfileResult instanceof HttpErrorResponse) {
        if (this.updateProfileResult?.error?.errorCode === this.errorCode.UserRegisterConflict_409) {
          this.displayError = true;
          this.errorField = this.updateProfileResult?.error?.errorData[0]?.errorField ? this.updateProfileResult?.error?.errorData[0]?.errorField : 'activity.log.rider.detail.email';
          this.errorMessage = this.updateProfileResult?.error?.errorData[0]?.errorMessage ? this.updateProfileResult?.error?.errorData[0]?.errorMessage : '';
        }
      } else {
        this.router.navigate(["/setup-password"], { replaceUrl: true });
      }
    }
  }

  get fg() { return this.fgroup; }
  get f() { return this.fg.controls; }

  onTickRegister(){
    this.isQueueRegister = !this.isQueueRegister;
  }

  onNavigate(dt: string) {
    const originCountryUrl = window.location.origin + this.configService.get('appBaseHref');
    switch (dt) {
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tNc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
      default: {
        this.router.navigateByUrl("/");
        break;
      }
    }
  }

  async onQueueRegister(queueRequest : JoinQueueRequest, email : string, ){
    const signupForm : SignupForm = {
      mobileNo: queueRequest.mobileNo,
      name: queueRequest.customerName,
      email: email ? email : undefined,
      dob: this.fgroup.value['dob'] ? this.fgroup.value['dob'] : undefined,
      otpCode: queueRequest.otpCode,
      isMobileVerified: true
    }

    let signupResult = await this.userService.signup({ ...signupForm });

    // need to add checking for http error
    if(signupResult instanceof HttpErrorResponse){
      this.joinQueueRequest  = queueRequest;
      this.displayRegisterFailedMsg = true;
    }
    else{
      await this.userService.updateToken(signupResult);
      let customerProfile = await this.userService.getProfile(false);
      let convertedTokenResponse = mapLoginResp(signupResult);
      this.userService.add({
        ...convertedTokenResponse,
        id: customerProfile.customerId,
        ...customerProfile
      } as User);

      this.router.navigate(["setup-password"]);
    }
  }

  closeFailedPopup(){
    this.displayRegisterFailedMsg = false;
  }

  async proceedToQueue(){
    this.displayRegisterFailedMsg = false;
    await this.queueService.onJoinQueueInit(this.joinQueueRequest);
  }
}
