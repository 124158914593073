import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StoreCatgAttrs } from 'src/app/core/models';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { DefaultService } from 'src/app/home/services/default.service';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { CurrentStatusFlag } from 'src/app/core/enums/CurrentStatusFlag';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { StoreAttrResponse } from 'src/app/core/models/StoreAttrResponse';
import * as _ from 'lodash';
import { AttrCode } from 'src/app/core/enums/AttrCode';

@Component({
  selector: 'app-item-card',
  templateUrl: './item-card.component.html',
  styleUrls: ['./item-card.component.scss']
})
export class ItemCardComponent implements OnInit {
  @Input() storeData: StoreResponse;
  @Input() showMoreImages: boolean;
  @Input() showStoreNearBy: boolean;
  @Output() displayStoreDialog = new EventEmitter();
  currency: CurrencyResponse;
  storeStatusFlag = CurrentStatusFlag;
  orderTypeFlag = OrderTypeFlag;
  attrCode = AttrCode;
  isStoreClosedPreOrder : boolean = false;

  constructor(
    private defaultService: DefaultService,
    private channelService: ChannelService,
  ) { }

  ngOnInit(): void {
    let curChannel = this.channelService.getChannelData();
    if (curChannel) {
      this.currency = curChannel.currency;
    }

    if(this.storeData && this.storeData?.currentStatus == CurrentStatusFlag.Closed){
      this.storeClosedStatusChecking();
    }
  }

  getStoreAttrDesc(storeCatgAttrs?: StoreCatgAttrs[]): string {
    return _.chain(storeCatgAttrs).map((attr) => {

      let longDesc = _.chain(attr.catgAttrOptions).map((attr2) => {
        return attr2.attrOptionDesc;
      }).join(', ')
      .value();

      return longDesc;
    }).join(', ')
    .value();
  }

  onClickOtherStore(storeData: StoreResponse) {
    this.defaultService.showOtherStoreDialog(storeData);
  }

  storeClosedStatusChecking(){
    if(this.storeData.currentOrderType != OrderTypeFlag.DineIn
      && this.storeData.storeOHSchedules && ((this.storeData.storeOHSchedules?.delivery && this.storeData.storeOHSchedules?.delivery?.length > 0)
      || (this.storeData.storeOHSchedules?.pickup && this.storeData.storeOHSchedules?.pickup?.length > 0))){
        this.isStoreClosedPreOrder = true;
    }
    else if((this.storeData.currentOrderType != OrderTypeFlag.DineIn
      && ((this.storeData.storeOHSchedules && (!this.storeData.storeOHSchedules?.delivery || this.storeData.storeOHSchedules?.delivery?.length == 0)
      && (!this.storeData.storeOHSchedules?.pickup || this.storeData.storeOHSchedules?.pickup?.length == 0)) || (!this.storeData.storeOHSchedules)))
      || (this.storeData.currentStatus === CurrentStatusFlag.Closed && this.storeData.currentOrderType == OrderTypeFlag.DineIn)){
        this.isStoreClosedPreOrder = false;
    }
  }
}
