import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CartService } from 'src/app/core/cart/cart.service';
import { Location } from '@angular/common';
import { MiniProgramService } from 'src/app/core/mini-program/mini-program.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  payTranId: string = '';
  isSuccess: boolean = false;
  isApp: boolean = false;
  errorMsg: string = '';
  orderNumber: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cartService: CartService,
    private location: Location,
    private miniProgramService : MiniProgramService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.payTranId = params['PayTranId'] ? params['PayTranId'] : '';
      this.isSuccess = params['IsSuccess'] === 'True' ? true : false;
      this.isApp = params['IsApp'] === 'True' ? true : false;
      this.orderNumber = params['OrderNumber'] ? params['OrderNumber'] : '';

      if (this.isSuccess && this.isApp) {
        // this.router.navigateByUrl("/payment-success");
        this.location.replaceState('/payment-success/' + this.payTranId);
        // window.location.replace('https://www.odaring.com');
      } else if (this.isSuccess) {
        this.cartService.clearCart();
        this.miniProgramService.clearCart();
        let data = { 'payTranId': this.payTranId };

        try {
          this.sessionStorageService.setItem("orderSummary", JSON.stringify(data));
        } catch(e) {
          console.log("Failed to set session storage", e)
        }

        this.router.navigate(['/order-summary']);
      } else {
        this.router.navigateByUrl("/my-order");
      }
    });
  }
}
