<app-download-app class="hidden-sm"></app-download-app>

<div class="hidden-lg" style="text-align: center; padding-top: 36px">
  <h1 class="heading-14">
    {{ "forgotPassword.resetPassword.header.1" | translate }}
  </h1>
</div>

<div class="reset-password-container">
  <div class="sub">
    <img *ngIf="resetPasswordSuccess" src="assets/mascot/Mascot_phone-success@2x.webp" alt="odaring mascot" />
    <img *ngIf="!resetPasswordSuccess" src="assets/mascot/Mascot_sorry-Grey@2x.webp" alt="odaring mascot" />
    <h1 class="heading-8" [innerHTML]="
        resetPasswordSuccess
          ? ('reset.success.header.1' | translate: { second: countdownSec })
          : ('reset.unsuccessful.desc.1' | translate: { second: countdownSec })"></h1>

    <div class="btn-position">
      <button class="reset-proceed-btn" (click)="onProceed()">
        {{ "button.proceed" | translate }}
      </button>
    </div>
  </div>
</div>
