import { HttpErrorResponse } from '@angular/common/http';

import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/user/user.service';
import { UserChnlService } from 'src/app/home/userchnl/userchnl.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-delete-acc',
  templateUrl: './delete-acc.component.html',
  styleUrls: ['./delete-acc.component.scss']
})
export class DeleteAccComponent implements OnInit, AfterViewInit {
  @Output() onHide = new EventEmitter();

  deleteText: string = "delete";
  deleteInput: string = "";
  isError: boolean = false;
  isMobile: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private userChnlService: UserChnlService,
    private analyticsService: AnalyticsService,
  ) {
  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  ngAfterViewInit(): void {
    let inputEl = document.getElementById("deleteText");
    if(inputEl) {
      inputEl.focus();
    }
  }

  onCancel() {
    this.onHide.emit();
  }

  async onDeleteAcc() {
    let curUser = this.userService.getCustomer();
    this.isError = this.deleteInput.replace(/\s/g, '') != this.deleteText;

    if(!this.isError) {
      let result = await this.userService.deleteCustomer();

      if (!(result instanceof(HttpErrorResponse))) {
        this.analyticsService.deleteAccountEvent(curUser.name, curUser.customerId, curUser.email, curUser.mobileNo);
        this.router.navigateByUrl('/deleted-account-success');
        this.userService.clearUser();
        this.userChnlService.clear();
      } else {
        this.router.navigateByUrl('/home');
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let isMobile = window.innerWidth <= 991 ? true: false;

    if(isMobile !== this.isMobile) {
      this.onHide.emit();
    }
  }
}
