import { Injectable } from '@angular/core';
import { ErrorObj } from 'src/app/core/models'
import { ErrorType } from '../enums/ErrorType';
@Injectable({ providedIn: 'root' })
export class ErrorService {

  curErrorObj: ErrorObj | null = null;
  errorObjArr: ErrorObj[] = [];

  constructor() { }

  insertErrorObj(curErrorObj: ErrorObj) {
    this.curErrorObj = curErrorObj;
    this.curErrorObj.datetime = new Date();
    if (this.errorObjArr.length > 10) {
      this.clearErrorObjArr();
    }
    this.errorObjArr.push(curErrorObj);
  }

  getErrorObj() {
    return this.curErrorObj;
  }

  createErrorObj() {
    const newError: ErrorObj = {
      errorData: [],
      type: ErrorType.INTERNAL,
      title: '',
      status: 499,
      detail: '',
      instance: '',
      datetime: new Date()
    }
    return newError;
  }


  clearErrorObjArr() {
    this.errorObjArr = [];
  }
  

}
