import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { SetCode } from 'src/app/core/enums/SetCode';
import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import * as moment from "moment";
import { TimeService } from 'src/app/core/services/time.service';
import { StoreOHPeriod } from 'src/app/core/models/StoreOHPeriod';

@Pipe({
  name: 'nextAvailableTime'
})
export class NextAvailableTimePipe implements PipeTransform {

  constructor(private timeService : TimeService){}

  transform(status: string, storeData : StoreResponse): string {
    let intervalSetting : ChannelPlatformSetResponse;
    let interval : number = 0;
    let nextAvailableTime : string = "";
    let currentStoreOHPeriod : StoreOHPeriod;

    this.timeService.setTimeZoneRegion(storeData.timeZone);

    if(storeData.currentOrderType == OrderTypeFlag.Delivery){
      intervalSetting = storeData.platformSets ? storeData.platformSets.find(setting => setting.setCode == SetCode.DELINTERVALMIN) : null;
      interval = intervalSetting ? +intervalSetting.setValue : 30;
      currentStoreOHPeriod = storeData.storeOHSchedules.delivery[0];
    }
    else if(storeData.currentOrderType == OrderTypeFlag.Pickup){
      intervalSetting = storeData.platformSets ? storeData.platformSets.find(setting => setting.setCode == SetCode.PICKINTERVALMIN) : null;
      interval = intervalSetting ? +intervalSetting.setValue : 30;
      currentStoreOHPeriod = storeData.storeOHSchedules.pickup[0];
    }

    let startTime = moment(moment(currentStoreOHPeriod.date).format("YYYY-MM-DD") + ' ' + currentStoreOHPeriod.min);
    startTime = this.timeService.convertToTimezoned(startTime); //convert to timezone given in case library change to irrelevant timezone

    let calculatedMinutes = this.timeService.formatNextAvailableTime(startTime, interval); //calculate first slot time
    startTime.set('minutes', calculatedMinutes); //set the first slot time
    nextAvailableTime = startTime.format("HH:mm");

    return nextAvailableTime;
  }

}
