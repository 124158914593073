import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastData } from 'src/app/core/models/ToastData';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-join-term-data',
  templateUrl: './join-term-data.component.html',
  styleUrls: ['./join-term-data.component.scss']
})
export class JoinTermDataComponent implements OnInit {
  @Input() isJoin: boolean = false;
  @Input() isQuit: boolean = false;
  @Input() membershipCode: string;
  @Input() isLoggedIn: boolean;
  @Input() channelId: number;
  @Output() clickJoin = new EventEmitter();
  @Output() continueQuit = new EventEmitter();
  @Input() externalMemberLinkMode = false;

  isAgree: boolean = false;
  termsData: string = '';
  constructor(
    private membershipService: MembershipService,
    private toastService: ToastService
  ) { }

  async ngOnInit(): Promise<void> {
    this.membershipService
      .getMembershipTermsData(this.membershipCode)
      .then(respDt => {
        if (!(respDt instanceof (HttpErrorResponse))) {
          this.termsData = respDt;
        } else {
          let errorObj = JSON.parse(respDt.error);
          this.membershipService.joinTermDialog.next(false);
          this.toastService.showApiFailedToast(errorObj);
        }
      });
  }

  onAgree(event: any) {
    this.isAgree = event.target.checked ? true : false;
  }

  async onJoin() {
    this.clickJoin.emit();
  }

  onContinue() {
    this.continueQuit.emit();
  }
}
