import { MembershipWithPointStampResponse } from './../../membership/membership/membership-with-point-stamp-response';
import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';
import { StaticQrService } from './../static-qr/static-qr.service';
import { MenuRecomm } from './../models/MenuRecomm';
import { VoidFlag } from './../enums/VoidFlag';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { StoreService } from 'src/app/store/store/store.service';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';
import { ChannelPlatformSetResponse } from './../models/ChannelPlatformSetResponse';
import { Injectable } from '@angular/core';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { CartModel } from '../models/CartModel';
import { CustomRequest } from '../models/CustomRequest';
import { CustomService } from '../services/custom.service';
import { Cart } from './cart.model';
import { CartStore } from './cart.store';
import * as _ from 'lodash';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { AvailableTime } from '../models/AvailableTime';
import { OrderH } from '../models/OrderH';
import { OrderSourceFlag } from '../enums/OrderSourceFlag';
import { OrderTypeFlag } from '../enums/OrderTypeFlag';
import * as moment from 'moment';
import { TimeInterval } from '../models/TimeInterval';
import { TimeService } from '../services/time.service';
import { CartQuery } from './cart.query';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { StoreOHAdvSchedule } from '../models/StoreOHAdvSchedule';
import { StoreOHPeriod } from '../models/StoreOHPeriod';
import { MenuItem } from '../models/MenuItem';
import { Guid } from 'guid-typescript';
import { OrderD } from '../models/OrderD';
import { OrderModifier } from '../models/OrderModifier';
import { OrderSubModifier } from '../models/OrderSubModifier';
import { OrderDAttr } from '../models/OrderDAttr';
import { User } from '../user/user.model';
import { OrderC } from '../models/OrderC';
import { UserQuery } from '../user/user.query';
import { OrderMenu } from '../models/OrderMenu';
import { OrderData } from '../models/OrderData';
import { Subject } from 'rxjs';
import { Modifier } from '../models/Modifier';
import { SubModifier } from '../models/SubModifier';
import { AddItemObject } from '../models/local/AddItemObject';
import { QrCartService } from '../qr-cart/qr-cart.service';
import { LeadTime } from '../models/LeadTime';
import { StoreResponse } from '../models/StoreResponse';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from '../models/ToastData';
import { EntryMode } from '../enums/EntryMode';
import { MenuSet } from '../models/MenuSet';
import { MenuCatg } from '../models/MenuCatg';
import { ChangeData } from 'ngx-intl-tel-input';
import { UtilsService } from '../services/utils.service';
import { ErrorCode } from '../enums/ErrorCode';
import * as deepClone from 'rfdc';
import { QueueResponse } from '../models/QueueResponse';
import { QueueCartService } from '../queue-cart/queue-cart.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { StaticQrState } from '../static-qr/static-qr.model';
import { MiniProgramService } from '../mini-program/mini-program.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { MenuItemStatus } from '../enums/MenuItemStatus';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class CartService {

  accessToken: string;
  isLoggedIn : boolean;
  customerId : string | number;
  user: User;

  channelId : number;
  channelTag : string;

  localCart : CartModel;

  orderC : OrderC = {} as OrderC;
  onClickCartTrashCan : Subject<boolean> = new Subject();
  preorderPopup : Subject<any> = new Subject();

  phoneInfo : ChangeData;
  // channelTagTest : string;

  tableReqValue : ChannelPlatformSetResponse;
  toKeepPreviousRoute : boolean = false;

  constructor(
    private customService: CustomService,
    private cartStore: CartStore,
    private timeService : TimeService,
    private cartQuery : CartQuery,
    private channelQuery : ChannelQuery,
    private userQuery : UserQuery,
    private storeService : StoreService,
    private qrCartService : QrCartService,
    private toastService : ToastService,
    private utilsService: UtilsService,
    private staticQrService : StaticQrService,
    private queueCartService : QueueCartService,
    private storageService : StorageService,
    private miniProgramService : MiniProgramService,
    private analyticsService : AnalyticsService,
    private sessionStorageService: SessionStorageService
  ) {

    this.userQuery.isLoggedIn$.subscribe(userData => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.isLoggedIn = userData? true : false;
      this.customerId = userData?.customerId ? userData.customerId : "null";
      this.user = userData? userData : null;
    })

    this.channelQuery.selectFirst().subscribe(channel => {
      if(channel){
        this.channelId = channel.channelId;
        this.channelTag = channel.channelTag;
      }
    })

    this.cartQuery.getLocalCart().subscribe(cart => {
      if(cart){
        this.localCart = cart.cartModel ? cart.cartModel : null;
      }
      else{
        this.localCart = null;
      }
    })
  }

  //#region open store remove orderH dialog
  openRemoveOrderHDialog(){
    this.onClickCartTrashCan.next(true);
  }

  closeRemoveOrderHDialog(){
    this.onClickCartTrashCan.next(false);
  }

  //#endregion

  addCart(data: Cart, customerId : number | string) {
    let cartData = _.cloneDeep(data) as Cart;

    this.cartStore.upsert(customerId, cartData);
  }

  processCartModel(cartModel: CartModel) {
    if(cartModel && cartModel.orderHs && cartModel.orderHs?.length > 0) {
      cartModel.orderHs.forEach((orderH: OrderH) => {
        orderH.orderData.orderVs = [];
        orderH.orderData.menuRowVersion = orderH.orderData.menuRowVersion? orderH.orderData.menuRowVersion: "0";
      });
    }

    return cartModel;
  }

  async updateCart(cartModel : CartModel){
    let newAddedTime = this.timeService.getAdjustedLocalTimeInUtc();
    if(this.customerId == "null"){
      let currentId = this.customerId + '-' + this.channelTag;
      this.cartStore.update(currentId, entity => {
        return{
          ...entity,
          cartModel: cartModel,
          dateAdded: newAddedTime.format()
        }
      });
    }
    else{
      this.cartStore.update(this.customerId, entity => {
        return{
          ...entity,
          cartModel: cartModel,
          dateAdded: newAddedTime.format()
        }
      });
    }
  }

  async upsertCart(payTranId) {
    let currentId;
    if (this.customerId == "null") {
      currentId = this.customerId + '-' + this.channelTag;
    } else {
      currentId = this.customerId.toString();
    }

    this.cartStore.upsert(currentId, {
      payTranId: payTranId
    });
  }

  getCartValue(customerId : string){
    return this.cartQuery.getEntity(customerId);
  }

  async recalculateCart(channelId : number, customerId : number | string, cartModel: CartModel, updateState: boolean = false) {
    cartModel = this.processCartModel(cartModel);

    let respData = null;
    respData = await this.reqRecalculateCart(channelId, cartModel);

    if(respData instanceof HttpErrorResponse === false){
      customerId = customerId? customerId : "null";
      let currentId : string = '';

      if(customerId == "null"){
        currentId = customerId + '-' + this.channelTag;
      }
      else{
        currentId = customerId.toString();
      }

      let newTime = this.timeService.getAdjustedLocalTimeInUtc();

      if (updateState) {
        this.addCart({
          id: currentId,
          cartModel: respData['body'],
          dateAdded: newTime.format()
        }, currentId)
      }

      let paymentMethods = respData['body'].paymentMethods;
      this.storageService.setSessionStorage('pms', paymentMethods);

      return respData['body'];
    }
    else{
      return respData;
    }
  }

  async getCustomerCart(channelId: number, channelTag: string, storeId? : number) {
    let respData = null;
    respData = await this.reqGetCustomerCart(channelId, channelTag, storeId);

    return respData;
  }

  //need to check if login or not
  private async reqRecalculateCart(channelId: number, cartModel: CartModel) {
    let newCr = {} as CustomRequest;

    if(this.isLoggedIn){
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest
    }
    else{
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId
        }
      }as CustomRequest
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    if(respInfo.headers && respInfo.headers?.get("X-ServerDateTime")){
      this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"))
      this.timeService.calculateServerTimeDifference();
    }

    return respInfo;
  }

  private async reqGetCustomerCart(channelId: number, channelTag: string, storeId? : number) {
    let currentStoreId = storeId ? storeId : undefined;
    let newCr = {} as CustomRequest;

    if(currentStoreId){
      newCr = {
        httpMethod: HttpMethod.GET,
        requestpath: environment.apis.cart.GetCustomerCart,
        hostPath: environment.hostPath,
        queryParams: {
          channelId: channelId,
          channelTag: channelTag,
          currentStoreId : currentStoreId
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest;
    }
    else{
      newCr = {
        httpMethod: HttpMethod.GET,
        requestpath: environment.apis.cart.GetCustomerCart,
        hostPath: environment.hostPath,
        queryParams: {
          channelId: channelId,
          channelTag: channelTag
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest;
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false).then(value => {
      if (value && value.headers && value.headers.get("X-ServerDateTime")) {
        this.timeService.setServerTime(value.headers.get("X-ServerDateTime"));
      }

      return value['body'];
    });

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }

  removeCartById(id : number | string){
    this.cartStore.remove(id);
  }

  removeCart() {
    this.cartStore.remove();
  }

  clearCart() {
    // if (!this.currentCart) {
    //   return;
    // }
    this.removeCart();
  }

  async checkIfCartTimePast(reqTime : string){
    let date = this.timeService.getAdjustedLocalTimeInUtc();

    let cartTime = moment(reqTime);
    cartTime = this.timeService.convertToUtc(cartTime);

    if(cartTime.isBefore(date)){
      return null;
    }
    else{
      return reqTime;
    }
  }

  //reuseable method for getting confirmedOrderTime for both the cart and the store using orderC.reqTime
  async getConfirmedOrderTime(orderH: OrderH, orderTimeInterval: ChannelPlatformSetResponse[], orderCDate: string, storeOhSchedule : StoreOHAdvSchedule) {
    // debugger
    let curDateFormatted = this.timeService.getAdjustedLocalTimeInUtc(); //local time in utc that has been adjusted
    curDateFormatted = this.timeService.convertToTimezoned(curDateFormatted); //plus timezoned inside the local time utc

    let interval: number;
    let confirmedOrderTime = {} as AvailableTime;
    let deliveryInterval = orderTimeInterval.find(val => val.setCode == "DELINTERVALMIN");
    let pickupInterval = orderTimeInterval.find(val => val.setCode == "PICKINTERVALMIN");
    let curOrderTypeSlot : StoreOHPeriod[];

    //check order type using sourceFlag in orderDate of OrderH
    switch (orderH.orderData.sourceFlag) {
      case OrderSourceFlag.WebDelivery:
        confirmedOrderTime.orderType = OrderTypeFlag.Delivery;
        interval = deliveryInterval ? +deliveryInterval.setValue : 30;
        curOrderTypeSlot = storeOhSchedule.delivery;
        break;
      case OrderSourceFlag.WebPickup:
        confirmedOrderTime.orderType = OrderTypeFlag.Pickup;
        interval = pickupInterval ? +pickupInterval.setValue : 30;
        curOrderTypeSlot = storeOhSchedule.pickup;
        break;
      case OrderSourceFlag.WebDineIn:
        confirmedOrderTime.orderType = OrderTypeFlag.DineIn;
        break;
      default:
        break;
    }

    if(orderCDate == null){
      confirmedOrderTime.date = curDateFormatted.format("YYYY-MM-DD");

      confirmedOrderTime.isToday = true;

      confirmedOrderTime.chosenTime = {} as TimeInterval;
      confirmedOrderTime.chosenTime.label = "ASAP";
      confirmedOrderTime.chosenTime.value = null;
    }
    else{
      let curOrderDate = moment(orderCDate);
      // curOrderDate = this.timeService.convertToTimezoned(curOrderDate);

      //find if the current orderC reqTime exists inside the schedule that backend provides
      let curDaySlot = curOrderTypeSlot.find(orderSlot => moment(orderSlot.date).format("YYYY-MM-DD") == curOrderDate.format("YYYY-MM-DD"));

      if(curDaySlot){
        confirmedOrderTime.date = curOrderDate.format("YYYY-MM-DD");
        confirmedOrderTime.isToday = curDateFormatted.format("YYYY-MM-DD") == confirmedOrderTime.date ? true : false;

        let curOrderTypeDate = moment(curOrderTypeSlot[0].date);
        let currentMinMoment = moment(curOrderTypeDate.format("YYYY-MM-DD") + ' ' + curOrderTypeSlot[0].min);

        if(curOrderDate.isBefore(currentMinMoment)){
          confirmedOrderTime.chosenTime = {} as TimeInterval;
          confirmedOrderTime.chosenTime.label = "ASAP";
          confirmedOrderTime.chosenTime.value = null;
          confirmedOrderTime.isAsap = true;
        }
        else{
          confirmedOrderTime.isAsap = false;
          let tempDate = this.timeService.convertToTimezoned(moment(curOrderDate));
          let timeLabel : string = tempDate.format("HH:mm") + ' - ' + tempDate.add(interval, 'minutes').format("HH:mm");
          confirmedOrderTime.chosenTime = {} as TimeInterval;
          confirmedOrderTime.chosenTime.label = timeLabel;
          confirmedOrderTime.chosenTime.value = this.timeService.formatDateString(curOrderDate);
        }

      }
      else{
        confirmedOrderTime.date = this.timeService.convertToTimezoned(moment(curOrderTypeSlot[0].date)).format("YYYY-MM-DD");
        confirmedOrderTime.isToday = confirmedOrderTime.date == curDateFormatted.format("YYYY-MM-DD") ? true : false;

        let minDateAndTime = this.timeService.convertToTimezoned(moment(confirmedOrderTime.date + ' ' + curOrderTypeSlot[0].min));

        confirmedOrderTime.isAsap = false;
        // this.timeService.convertToUtc(moment(minDateAndTime));
        let tempValue = this.timeService.formatDateString(minDateAndTime);
        let timeLabel : string = minDateAndTime.format("HH:mm") + ' - ' + minDateAndTime.add(interval, 'minutes').format("HH:mm");

        confirmedOrderTime.chosenTime = {} as TimeInterval;
        confirmedOrderTime.chosenTime.label = timeLabel;
        confirmedOrderTime.chosenTime.value = tempValue;

      }
    }

    return confirmedOrderTime;
  }

  //#region guest/local cart if any overwrite remote cart
  //method to before overwrite for checking of conditions
  async overwriteRemoteCartChecking(toUpdate : boolean, storeId? : number){
    let sortedCart = null;
    let cartModelFromDB  = await this.getCustomerCart(this.channelId, this.channelTag, storeId);

    if(this.localCart && this.localCart.orderHs.length != 0){
      let newCart = await this.overwriteRemoteCart(this.localCart, cartModelFromDB);
      let newCartWithCustId = await this.reInsertIdToCart(newCart, +this.customerId);
      sortedCart = await this.sortIsSelectOrder(newCartWithCustId);
      this.removeCartById("null-"+this.channelTag);
    }

    if(sortedCart){
      sortedCart = await this.staticCartChecking(sortedCart, false);
    }
    else{
      cartModelFromDB = await this.staticCartChecking(cartModelFromDB, false);
    }

    if(toUpdate){
      await this.recalculateCart(this.channelId, this.customerId, sortedCart ? sortedCart : cartModelFromDB, true);
    }

    return sortedCart ? sortedCart : cartModelFromDB;
  }

  //overwrite remote cart with local cart when local cart have the same store in remote cart
  async overwriteRemoteCart(localCart: CartModel, remoteCart: CartModel) {
    localCart.orderHs.map(val => {
      let index = remoteCart.orderHs.findIndex(store => store.storeId == val.storeId);
      if (index != -1) {
        remoteCart.orderHs[index] = _.cloneDeep(val);
      }
      else{
        remoteCart.orderHs.push(val);
      }
    });

    return remoteCart;
  }

  //after overwrite reinsert the id to the customer id that is null
  async reInsertIdToCart(cart : CartModel, customerId : number){
    let cloneCart = _.cloneDeep(cart);
    cloneCart.orderHs.map(orderH => {
      if(orderH.customerId == null){
        orderH.customerId = customerId;

        orderH.orderData.orderC.customerId = customerId;
        orderH.orderData.orderC.custName = this.user ? this.user.displayName : null;
        orderH.orderData.orderC.email = this.user? this.user.email : null;
        orderH.orderData.orderC.mobileNo = this.user? this.user.mobileNo : null;

        orderH.orderData.orderDs.map(orderD => {
          if(orderD.customerId == null){
            orderD.customerId = customerId;
          }
        })
      }
    })

    return cloneCart;
  }

  //#endregion

  //#region guest static cart checking
  async staticCartChecking(cartModel : CartModel, toUpdate : boolean){
    if(!cartModel || !cartModel.orderHs || (cartModel.orderHs && cartModel.orderHs.length == 0)){
      return cartModel;
    }

    let staticQrData = this.staticQrService.get();
    if(staticQrData && staticQrData?.tableNo && staticQrData?.storeId){
      cartModel.orderHs = cartModel.orderHs.filter(orderH => (orderH.storeId == staticQrData.storeId && orderH.orderData.tableNo) || (!orderH.orderData.tableNo));
    }
    else if(staticQrData && staticQrData?.tableNo && !staticQrData?.storeId){
      cartModel.orderHs = cartModel.orderHs.filter(orderH => (!orderH.orderData.tableNo) || (orderH.orderData.tableNo == staticQrData.tableNo));
    }
    else if((!staticQrData) || (staticQrData && !staticQrData?.tableNo)){
      cartModel.orderHs = cartModel.orderHs.filter(orderH => !orderH.orderData.tableNo);
    }

    if(toUpdate){
      await this.recalculateCart(this.channelId, this.customerId, cartModel, true);
    }

    return cartModel;
  }
  //#endregion

  //manually sort isSelected order to the front of an array
  async sortIsSelectOrder(cart : CartModel){
    let cloneCart = _.cloneDeep(cart);

    cloneCart.orderHs.forEach((val, i) => {
      if(val.isSelect){
        cloneCart.orderHs.splice(i, 1);
        cloneCart.orderHs.unshift(val);
      }
    })

    return cloneCart;
  }

  //#region for calculate total price pipe during item customization
  async calculateTotalPriceForAddToCart(menuItem : MenuItem){
    let total : number = menuItem.dispPrice;

    if(menuItem.modifierGrps && menuItem.modifierGrps.length != 0){
      for(let i = 0; i < menuItem.modifierGrps.length; i++){
        if(menuItem.modifierGrps[i].qtyGrp > 0){
          for(let modIndex = 0; modIndex < menuItem.modifierGrps[i].modifiers.length; modIndex++){
            if(menuItem.modifierGrps[i].modifiers[modIndex].qty > 0){
              total += menuItem.modifierGrps[i].modifiers[modIndex].qty * menuItem.modifierGrps[i].modifiers[modIndex].price;

              if(menuItem.modifierGrps[i].modifiers[modIndex].subModifierGrps && menuItem.modifierGrps[i].modifiers[modIndex].subModifierGrps.length != 0){
                total += await this.calculateSubModGrpTotal(menuItem.modifierGrps[i].modifiers[modIndex].subModifierGrps);
              }
            }
          }
        }
      }
    }

    return total;
  }

  async calculateSubModGrpTotal(subModifierGrp : SubModifierGrp[]){
    let subModTotal : number = 0;
    let subModGrpLength = subModifierGrp.length;

    for(let index = 0; index < subModGrpLength; index++){
      if(subModifierGrp[index].qtyGrp > 0){
        subModTotal += await this.calculateSubModifier(subModifierGrp[index].subModifiers);
      }
    }

    return subModTotal;
  }

  async calculateSubModifier(subModifier : SubModifier[]){
    let subModTotal = 0;
    let subModLength = subModifier.length;

    for(let index = 0; index < subModLength; index++){
      if(subModifier[index].qty > 0){
        subModTotal += subModifier[index].qty * subModifier[index].price;
      }
    }

    return subModTotal;
  }
  //#endregion

  async addItemToCart(addItemInput: AddItemObject){
    if(addItemInput.orderLineGuid){
      let updatedCartModel = await this.updateCartItem(addItemInput);
      await this.recalculateCartChecking(addItemInput.qrTokenResponse, updatedCartModel, addItemInput.queueResponse, addItemInput.appLinkTokenResponse);
      return true;
    }

    if(addItemInput.menuItem && addItemInput.menuItem.leadTime){
      let processedTime = await this.storeService.currentTimePlusLeadTime(addItemInput.menuItem.leadTime);

      let isAfter = await this.storeService.leadTimeCheck(processedTime, addItemInput.chosenTime);

      if(isAfter){
        this.preorderPopup.next({menuItem: addItemInput.menuItem, processedTime: processedTime});
        return false;
      }
    }

    addItemInput = await this.addCartItem(addItemInput);

    if (addItemInput.orderLineGuid) {
      this.analyticsService.editCartEvent(addItemInput);
    } else {
      this.analyticsService.addToCartEvent(addItemInput);
    }

    await this.recalculateCartChecking(addItemInput.qrTokenResponse, addItemInput.cartModel, addItemInput.queueResponse, addItemInput.appLinkTokenResponse);
    return true;
  }

  //add/construct cart operation for when add to cart
  async addCartItem(addItemInput: AddItemObject){
    let orderData : OrderData = {} as OrderData;
    let orderH : OrderH = {} as OrderH;
    let orderD : OrderD = {} as OrderD;
    let orderC : OrderC = {} as OrderC;
    let curOrderH : OrderH;

    orderC = await this.bindInfoToOrderC(addItemInput.chosenTime ? addItemInput.chosenTime : null, addItemInput.distance,
      addItemInput.orderType, addItemInput.qrTokenResponse, addItemInput.queueResponse, addItemInput.appLinkTokenResponse, addItemInput.membershipResponse);

    orderD = await this.bindInfoToModelOrderD(addItemInput.menuItem, addItemInput.orderType, addItemInput.qrTokenResponse,
      addItemInput.queueResponse, addItemInput.appLinkTokenResponse);

    curOrderH = addItemInput.cartModel?.orderHs?.find(val => val.storeId == addItemInput.storeId);

    if(!addItemInput.cartModel || !curOrderH){
      if(!addItemInput.cartModel){
        addItemInput.cartModel = {} as CartModel;
      }

      orderData = await this.bindInfoToOrderData(addItemInput.menuData, addItemInput.menuRowVersion, addItemInput.orderType, null,
        addItemInput.qrTokenResponse, addItemInput.staticData, addItemInput.queueResponse, addItemInput.appLinkTokenResponse);

      if(orderD.leadType && orderD.leadValue){
        let preorderTime = await this.orderDLeadTimeCheck(orderD, orderC);
        orderC.preorderTime = preorderTime;
      }

      orderData.orderC = orderC;

      if(!orderData.orderDs){
        orderData.orderDs = [];
      }
      orderData.orderDs.push(orderD);

      orderH = await this.initializeOrderH(addItemInput.storeId, addItemInput.storeName, orderData,
        addItemInput.qrTokenResponse? addItemInput.qrTokenResponse.linkId : null, addItemInput.appLinkTokenResponse);

      if(!addItemInput.cartModel.orderHs){
        addItemInput.cartModel.orderHs = [];
      }

      addItemInput.cartModel.orderHs.push(orderH);
    }
    else{
      if(orderD.leadType && orderD.leadValue){
        let preorderTime = await this.orderDLeadTimeCheck(orderD, curOrderH.orderData.orderC);
        orderC.preorderTime = preorderTime;
      }
      else{
        orderC.preorderTime = curOrderH.orderData.orderC.preorderTime;
      }

      curOrderH.orderData.orderC = _.clone(orderC);
      let cloneOrderD = _.cloneDeep(orderD);
      curOrderH.orderData.orderDs.unshift(cloneOrderD);

      let sourceFlag : string;
      if(addItemInput.qrTokenResponse){
        sourceFlag = this.qrCartService.getQrCartSourceFlag(addItemInput.orderType);
      }
      else if(addItemInput.queueResponse){
        sourceFlag = OrderSourceFlag.WebQrDineIn;
      }
      else{
        sourceFlag = this.storeService.getSourceFlag(addItemInput.orderType);
      }
      curOrderH.orderData.sourceFlag = sourceFlag;

      curOrderH = await this.updateOrderSourceFlag(curOrderH, sourceFlag);

      let index = addItemInput.cartModel.orderHs.findIndex(val => val.storeId == addItemInput.storeId);
      addItemInput.cartModel.orderHs[index] = curOrderH;
    }

    return addItemInput;
  }

  //update operation for when edit item
  async updateCartItem(addItemInput : AddItemObject){
    let orderD : OrderD;

    let index = addItemInput.cartModel.orderHs.findIndex(val => val.storeId == addItemInput.storeId);

    if(index != -1){
      orderD = await this.bindInfoToModelOrderD(addItemInput.menuItem, addItemInput.orderType, addItemInput.qrTokenResponse,
        addItemInput.queueResponse, addItemInput.appLinkTokenResponse);

      let curOrderDIndex = addItemInput.cartModel.orderHs[index].orderData.orderDs.findIndex(val => val.orderLineGUID == addItemInput.orderLineGuid);
      addItemInput.cartModel.orderHs[index].orderData.orderDs[curOrderDIndex] = orderD;
    }

    return addItemInput.cartModel;
  }

  //bind menu item into orderD when add to cart, edit menu item or reorder
  async bindInfoToModelOrderD(menuItem : MenuItem, orderType: string, qrTokenResponse? : WebLinkTokenResponse, queueResponse? : QueueResponse,
    appLinkTokenResponse? : AppLinkTokenResponse){
    let orderLineGuid : string = Guid.raw();
    let orderD : OrderD = {} as OrderD;
    let modifiers : OrderModifier[] = [];
    let oneModifier : OrderModifier = {} as OrderModifier;
    let subModifiers : OrderSubModifier[] = [];
    let oneSubModifier : OrderSubModifier = {} as OrderSubModifier;

    if(menuItem.modifierGrps){
      menuItem.modifierGrps.map(modGrp => {
        if(modGrp?.qtyGrp >= 1){
          oneModifier.code = modGrp.code;
          oneModifier.orderLineGUID = Guid.raw();

          modGrp.modifiers.map(mod => {
            if(mod?.qty >= 1){
              oneModifier.subModifiers = [];
              oneModifier.itemCode = mod.itemCode;
              oneModifier.lineDesc = mod.title;
              oneModifier.orderQty = mod.qty;
              oneModifier.unitSellPrice = mod.price;

              if(appLinkTokenResponse){
                oneModifier.sourceFlag = this.miniProgramService.getSourceFlag(orderType);
              }
              else if(qrTokenResponse){
                oneModifier.sourceFlag = this.qrCartService.getQrCartSourceFlag(orderType);
              }
              else if(queueResponse){
                oneModifier.sourceFlag = OrderSourceFlag.WebQrDineIn
              }
              else{
                oneModifier.sourceFlag = this.storeService.getSourceFlag(orderType);
              }

              oneModifier.orderDAttrs = [];
              if(mod.attrs && mod.attrs.length > 0){
                oneModifier.orderDAttrs = this.initModifierAttr(mod);
              }

              if(mod.subModifierGrps && mod.subModifierGrps?.length != 0){
                subModifiers = [];
                mod.subModifierGrps.map(subModGrp => {
                  if(subModGrp.qtyGrp && subModGrp.qtyGrp >= 1){
                    oneSubModifier.code = subModGrp.code;
                    oneSubModifier.orderLineGUID = Guid.raw();

                    subModGrp.subModifiers.map(subMod => {
                      if(subMod.qty && subMod.qty >= 1){
                        oneSubModifier.itemCode = subMod.itemCode;
                        oneSubModifier.lineDesc = subMod.title;
                        oneSubModifier.orderQty = subMod.qty;
                        oneSubModifier.unitSellPrice = subMod.price;
                        if(appLinkTokenResponse){
                          oneSubModifier.sourceFlag = this.miniProgramService.getSourceFlag(orderType);
                        }
                        else if(qrTokenResponse){
                          oneSubModifier.sourceFlag = this.qrCartService.getQrCartSourceFlag(orderType);
                        }
                        else if(queueResponse){
                          oneSubModifier.sourceFlag = OrderSourceFlag.WebQrDineIn
                        }
                        else{
                          oneSubModifier.sourceFlag = this.storeService.getSourceFlag(orderType);
                        }

                        oneSubModifier.orderDAttrs = [];
                        if(subMod.attrs && subMod.attrs.length > 0){
                          oneSubModifier.orderDAttrs = this.initSubModifierAttr(subMod);
                        }

                        let cloneSubMod = _.cloneDeep(oneSubModifier);
                        subModifiers.push(cloneSubMod);
                      }
                    })
                  }
                })
                oneModifier.subModifiers = subModifiers;
              }

              let cloneMod = _.cloneDeep(oneModifier);
              modifiers.push(cloneMod);

            }
          })
        }
      })
    }

    if(menuItem.parentMenuItem){
      orderD.parentItemId = menuItem.parentMenuItem.id;
      orderD.parentItemCode = menuItem.parentMenuItem.itemCode;
    }

    orderD.menuCatgCode = menuItem.menuCatgsCode;
    orderD.itemId = menuItem.id;
    orderD.remarks = menuItem.remarks;
    orderD.modifiers = modifiers;
    orderD.orderLineGUID = orderLineGuid;
    orderD.customerId = this.isLoggedIn && !appLinkTokenResponse ? +this.customerId : null;
    orderD.itemCode = menuItem.itemCode;
    orderD.lineDesc = menuItem.title;
    orderD.orderQty = menuItem.itemQty;

    if(appLinkTokenResponse){
      orderD.sourceFlag = this.miniProgramService.getSourceFlag(orderType);
      orderD.leadType = null;
      orderD.leadValue = 0;
    }
    else if(qrTokenResponse){
      orderD.sourceFlag = this.qrCartService.getQrCartSourceFlag(orderType);
      orderD.leadType = null;
      orderD.leadValue = 0;
    }
    else if(queueResponse){
      orderD.sourceFlag = OrderSourceFlag.WebQrDineIn;
      orderD.leadType = null;
      orderD.leadValue = 0;
    }
    else{
      orderD.leadType = menuItem.leadTime? menuItem.leadTime.type : null;
      orderD.leadValue = menuItem.leadTime? +menuItem.leadTime.value : 0;
      orderD.sourceFlag = this.storeService.getSourceFlag(orderType);
    }

    orderD.entryMode = menuItem.entryMode ? menuItem.entryMode : EntryMode.Menu;

    orderD.unitSellPrice = menuItem.dispPrice;

    orderD.orderDAttrs = [];

    if(menuItem.thumbnail != null && menuItem.thumbnail != 'assets/image-unavailable.svg'){
      let orderAttr = {
        attrCode : 'ITEMIMGURL',
        attrValue : menuItem.thumbnail
      } as OrderDAttr;

      orderD.orderDAttrs.push(orderAttr);
    }

    if(menuItem.attrs){
      menuItem.attrs.forEach(val => {
        let orderAttr = {} as OrderDAttr;

        if(val.value){
          orderAttr.attrCode = val.code;
          orderAttr.attrValue = val.value;

          orderD.orderDAttrs.push(orderAttr);
        }
      })
    }

    orderD.prepareMin = menuItem.prepareMin ? menuItem.prepareMin : 0;

    return orderD;
  }

  //bind necessary info into orderC when add to cart
  async bindInfoToOrderC(chosenTime: string, distance : number, orderType: string, qrTokenResponse : WebLinkTokenResponse,
    queueResponse : QueueResponse, appLinkTokenResponse : AppLinkTokenResponse, membershipResponse? : MembershipWithPointStampResponse){
    this.orderC = {} as OrderC;
    let tokenDataResp = JSON.parse(qrTokenResponse ? qrTokenResponse.tokenData : null);

    if(appLinkTokenResponse){
      this.orderC.custName = appLinkTokenResponse.custName;
      this.orderC.address = appLinkTokenResponse.address;
      this.orderC.distanceKM = distance ? distance : 0;
      this.orderC.reqTime = appLinkTokenResponse.reqTime ? appLinkTokenResponse.reqTime : null;
      this.orderC.mobileNo = appLinkTokenResponse.mobileNo ? appLinkTokenResponse.mobileNo : null;
      this.orderC.longitude = appLinkTokenResponse.longitude ? appLinkTokenResponse.longitude : 0;
      this.orderC.latitude = appLinkTokenResponse.latitude ? appLinkTokenResponse.latitude : 0;
    }
    else if(qrTokenResponse && !queueResponse){
      this.orderC.refNo = this.qrCartService.getRefValue();
      this.orderC.reqTime = null;
      this.orderC.distanceKM = 0;
      if(tokenDataResp){
        this.orderC.custName = tokenDataResp.CustName ? tokenDataResp.CustName : null;
        this.orderC.mobileNo = tokenDataResp.MobileNo ? tokenDataResp.MobileNo : null;
      }
    }
    else if(!qrTokenResponse && !queueResponse){
      this.userQuery.selectedAddress$.subscribe(address => {
        if(address){
          this.orderC.addressDesc = address.addressDesc ? address.addressDesc : null;
          this.orderC.address = address.fullAddress ? address.fullAddress : null;
          this.orderC.addUnit = address.unit? address.unit : null;
          if(orderType == OrderTypeFlag.Delivery){
            this.orderC.remarks = address.remarks ? address.remarks : null;
          }
          else{
            this.orderC.remarks = null;
          }
          this.orderC.addCity = address.city ? address.city : null;
          this.orderC.addPostal = address.postalCode ? address.postalCode : null;
          this.orderC.addState = address.state ? address.state : null;
          this.orderC.addCountry = address.country ? address.country : null;
          this.orderC.longitude = address.longitude ? address.longitude : 0;
          this.orderC.latitude = address.latitude ? address.latitude : 0;
        }
      })

      let todayDateMoment = this.timeService.getAdjustedLocalTimeInUtc(); //get local time in utc and already aligned value
      todayDateMoment = this.timeService.convertToTimezoned(todayDateMoment); //plus timezone into utc local time to convert to a timezoned value

      if(chosenTime){
        let selectedTimeMoment = moment(chosenTime);
        selectedTimeMoment = this.timeService.convertToTimezoned(selectedTimeMoment); //convert back to current timezone

        if(selectedTimeMoment.millisecond(0).isBefore(todayDateMoment.millisecond(0))){
          this.orderC.reqTime = null;
        }
        else{
          this.orderC.reqTime = chosenTime;
        }
      }
      else{
        this.orderC.reqTime = null;
      }

      this.orderC.distanceKM = distance ? distance : 0;
    }

    // if not mini program flow then will run code below, else run code above
    if(!appLinkTokenResponse){
      //if log in then set orderC with user info else if is qr dine in then check state for stored mobile no
      if(this.isLoggedIn){
        this.orderC.mobileNo = this.user && this.user?.mobileNo ? this.user.mobileNo : null;
        this.orderC.email = this.user && this.user?.email? this.user.email : null;
        this.orderC.custName = this.user && this.user?.displayName? this.user.displayName : null;
      }
      else{
        if(qrTokenResponse && !queueResponse){
          let phoneData = this.storageService.getCachedNumber();
          this.orderC.mobileNo = phoneData ? phoneData.dialCode.replace('+', '') + this.utilsService.formatPhoneNo(phoneData) : tokenDataResp ? tokenDataResp.MobileNo : null;
        }
        else{
          this.orderC.mobileNo = null;
          this.orderC.email = null;
          this.orderC.custName = null;
        }
      }
    }

    if(membershipResponse){
      this.orderC = await this.upsertMembership(this.orderC, membershipResponse, appLinkTokenResponse);
    }

    this.orderC.customerId = this.isLoggedIn && !appLinkTokenResponse ? +this.customerId : null;

    return this.orderC;
  }

  //bind data into order data
  async bindInfoToOrderData(menuData : OrderMenu[], menuRowVersion : string, orderType : string, sourceFlag : string,
    qrTokenResponse : WebLinkTokenResponse, staticData : StaticQrState, queueResponse? : QueueResponse, appLinkTokenResponse? : AppLinkTokenResponse){
    let todayDateMoment = this.timeService.getAdjustedLocalTimeInUtc(); //get local time in utc and already aligned value
    todayDateMoment = this.timeService.convertToTimezoned(todayDateMoment); //convert to store timezone using moment-timezone

    let orderData : OrderData = {} as OrderData;
    orderData.menuSetId = menuData[0].menuSets[0].id;
    orderData.menuRowVersion = menuRowVersion;
    orderData.bizDate = this.timeService.formatDateString(todayDateMoment);
    orderData.currCode = menuData[0].currency.code;
    orderData.cutleryFlag = false;

    if(appLinkTokenResponse){
      orderData.guestCount = appLinkTokenResponse.guestCount ? appLinkTokenResponse.guestCount : 0;
      orderData.tableNo = appLinkTokenResponse.tableNo ? appLinkTokenResponse.tableNo : null;
    }
    else if(qrTokenResponse){
      orderData.guestCount = qrTokenResponse.guestCount;
      orderData.tableNo = qrTokenResponse.tableNo ? qrTokenResponse.tableNo : null;
    }
    else if(queueResponse){
      orderData.guestCount = queueResponse.guestCount;
      orderData.tableNo = queueResponse.tableNo;
      orderData.sourceFlag = OrderSourceFlag.WebQrDineIn;
    }
    else{
      orderData.tableNo = staticData && staticData?.tableNo ? staticData.tableNo : null;
      orderData.guestCount = staticData && staticData?.guestCount ? staticData.guestCount : 0;
    }

    if(orderType && appLinkTokenResponse){
      orderData.sourceFlag = this.miniProgramService.getSourceFlag(orderType);
    }
    else if(orderType && qrTokenResponse){
      orderData.sourceFlag = this.qrCartService.getQrCartSourceFlag(orderType);
    }
    else if(orderType && queueResponse){
      orderData.sourceFlag = OrderSourceFlag.WebQrDineIn;
    }
    else if(orderType && !qrTokenResponse && !queueResponse){
      orderData.sourceFlag = this.storeService.getSourceFlag(orderType);
      orderData.tableNo = orderData.sourceFlag == OrderSourceFlag.WebDelivery || orderData.sourceFlag == OrderSourceFlag.AppDelivery ? null : orderData.tableNo;
    }
    else{
      orderData.sourceFlag = sourceFlag;
    }

    return orderData;
  }

  //bind necessary data and order data into orderH
  async initializeOrderH(storeId : number, storeName: string, orderData : OrderData, linkId : number, appLinkTokenResponse? : AppLinkTokenResponse){
    let cartGuid : string = Guid.raw();
    let orderH : OrderH = {} as OrderH;

    orderH.isSelect = true;
    orderH.channelId = this.channelId;

    orderH.customerId = this.isLoggedIn && !appLinkTokenResponse ? +this.customerId : null;

    orderH.cartGUID = cartGuid;
    orderH.storeId = storeId;
    orderH.orderMode = 0;
    orderH.orderData = orderData;
    orderH.storeName = storeName;
    orderH.linkId = linkId;

    return orderH;
  }

  async recalculateCartChecking(qrTokenResponse : WebLinkTokenResponse, cartModel : CartModel, queueResponse : QueueResponse,
    appLinkTokenResponse? : AppLinkTokenResponse){
    let respData = null;
    if(qrTokenResponse){
      respData = await this.qrCartService.recalculateCart(this.channelId, qrTokenResponse.linkId, cartModel, true);
    }
    else if(queueResponse){
      respData = await this.queueCartService.recalculateCart(this.channelId, queueResponse.storeId, cartModel, queueResponse.queueNo, false, true);
    }
    else if(appLinkTokenResponse){
      await this.miniProgramService.recalculateCart(this.channelId, appLinkTokenResponse.linkId, cartModel, true);
    }
    else{
      respData = await this.recalculateCart(this.channelId, this.customerId, cartModel, true);
    }

    // refresh customer's cart when cart is not found after recalculate during item adding
    if(!qrTokenResponse && !appLinkTokenResponse && (respData instanceof HttpErrorResponse) && respData?.error?.errorCode == ErrorCode.CartNotFound_404){
      await this.refreshCustomerCart();
    }

    return respData;
  }

  // update particular orderH reqTime, sourceflag and remarks after checking out of schedule selection
  async updateOrderHTime(currentStoreCart : OrderH, currentTime: AvailableTime, remarks? : string){
    currentStoreCart.orderData.orderC.reqTime = currentTime.chosenTime ? currentTime.chosenTime.value : null;

    if(currentTime.orderType == OrderTypeFlag.Delivery){
      currentStoreCart.orderData.sourceFlag = OrderSourceFlag.WebDelivery;
      currentStoreCart.orderData.orderC.remarks = remarks? remarks : null;
    }
    else if(currentTime.orderType == OrderTypeFlag.Pickup){
      currentStoreCart.orderData.sourceFlag = OrderSourceFlag.WebPickup;
      currentStoreCart.orderData.orderC.remarks = null;
    }
    else if(currentTime.orderType == OrderTypeFlag.DineIn){
      currentStoreCart.orderData.sourceFlag = OrderSourceFlag.WebDineIn;
      currentStoreCart.orderData.orderC.remarks = null;
    }

    currentStoreCart = await this.updateOrderSourceFlag(currentStoreCart, currentStoreCart.orderData.sourceFlag);

    return currentStoreCart;
  }

  async updateCartOrderType(currentStoreCart : OrderH, orderType : string){
    let sourceFlag = this.storeService.getSourceFlag(orderType);
    currentStoreCart.orderData.sourceFlag = sourceFlag;
    if(currentStoreCart.orderData.sourceFlag == OrderSourceFlag.WebDineIn || currentStoreCart.orderData.sourceFlag == OrderSourceFlag.AppDineIn){
      currentStoreCart.orderData.orderC.reqTime = null;
    }
    currentStoreCart = await this.updateOrderSourceFlag(currentStoreCart, sourceFlag);

    return currentStoreCart;
  }

  // update orderD, modifier, submodifier sourceFlag
  async updateOrderSourceFlag(currentStoreCart : OrderH, sourceFlag : string){
    for await(let item of currentStoreCart.orderData.orderDs){
      item.sourceFlag = sourceFlag;

      for await(let mod of item.modifiers){
        mod.sourceFlag = sourceFlag;

        for await(let subMod of mod.subModifiers){
          subMod.sourceFlag = sourceFlag;
        }
      }
    }

    return currentStoreCart;
  }

  // update cart orderC address related field
  async updateCartAddress(currentStoreCart : OrderH, address : any, distance : number){
    let orderType = this.storeService.getOrderType(currentStoreCart.orderData.sourceFlag);

    currentStoreCart.orderData.orderC.addressDesc = address?.addressDesc ? address.addressDesc : null;
    currentStoreCart.orderData.orderC.addUnit = address?.unit ? address.unit : null;
    currentStoreCart.orderData.orderC.address = address?.fullAddress ? address.fullAddress : null;
    currentStoreCart.orderData.orderC.longitude = address?.longitude ? address.longitude : 0;
    currentStoreCart.orderData.orderC.latitude = address?.latitude ? address.latitude : 0;
    if(orderType == OrderTypeFlag.Delivery){
      currentStoreCart.orderData.orderC.remarks = address?.remarks ? address.remarks : null;
    }
    else{
      currentStoreCart.orderData.orderC.remarks = null;
    }
    currentStoreCart.orderData.orderC.addCity = address?.city ? address.city : null;
    currentStoreCart.orderData.orderC.addPostal = address?.postalCode ? address.postalCode : null;
    currentStoreCart.orderData.orderC.addState = address?.state ? address.state : null;
    currentStoreCart.orderData.orderC.addCountry = address?.country ? address.country : null;
    currentStoreCart.orderData.orderC.distanceKM = distance? distance : 0;

    return currentStoreCart;
  }

  // update cart orderC user information after login
  async updateUserInfo(cartModel : CartModel, qrTokenResponse : WebLinkTokenResponse){
    if(this.isLoggedIn){
      for await(let orderH of cartModel.orderHs){
        orderH.customerId = +this.customerId;

        orderH.orderData.orderC.customerId = +this.customerId;
        orderH.orderData.orderC.mobileNo = this.user ? this.user.mobileNo : null;
        orderH.orderData.orderC.email = this.user? this.user.email : null;
        orderH.orderData.orderC.custName = this.user? this.user.displayName : null;

        for await(let item of orderH.orderData.orderDs){
          item.customerId = +this.customerId;
        }
      }
    }
    else{
      for await(let orderH of cartModel.orderHs){
        orderH.customerId = null;
        orderH.orderData.orderC.customerId = null;
        orderH.orderData.orderC.email = null;
        orderH.orderData.orderC.custName = null;

        if(qrTokenResponse){
          let tokenDataResp = JSON.parse(qrTokenResponse.tokenData);
          this.phoneInfo = this.storageService.getCachedNumber();

          if(this.phoneInfo && !(tokenDataResp && tokenDataResp.MobileNo)){
            let dialCode = this.phoneInfo.dialCode.replace('+', ''); //get dial code e.g 60
            let phoneNo = this.utilsService.formatPhoneNo(this.phoneInfo); //get phone no
            orderH.orderData.orderC.mobileNo = dialCode + phoneNo;
          }
          else{
            orderH.orderData.orderC.custName = tokenDataResp ? tokenDataResp.CustName : null;
            orderH.orderData.orderC.mobileNo = tokenDataResp ? tokenDataResp.MobileNo : null;
          }
        }
        else{
          orderH.orderData.orderC.mobileNo = null;
        }

        for await(let item of orderH.orderData.orderDs){
          item.customerId = null;
        }
      }
    }
    return cartModel;
  }

  async updateSelectedCartMobileNumber(cartModel : CartModel, mobileNumber : string){
    let orderLength = cartModel.orderHs.length;

    for(let index = 0; index < orderLength; index++){
      if(cartModel.orderHs[index].isSelect){
        cartModel.orderHs[index].orderData.orderC.mobileNo = mobileNumber;
      }
    }

    return cartModel;
  }

  async setOrderIsSelectToTrue(cartModel : CartModel, orderH : OrderH){
    for await(let order of cartModel.orderHs){
      if(order.cartGUID == orderH.cartGUID){
        order.isSelect = true;
      }
      else{
        order.isSelect = false;
      }
    }

    return cartModel;
  }

  async shiftOrderIsSelectTrue(cartModel : CartModel, orderH : OrderH){
    let index = cartModel.orderHs.findIndex(store => store.cartGUID == orderH.cartGUID);
    if(index != -1){
      let splicedOrderH = cartModel.orderHs.splice(index, 1);
      cartModel.orderHs.unshift(splicedOrderH[0]);
    }

    return cartModel;
  }

  //#region preorder cart logic
  async checkIfPreorderTimeIsEmpty(cartModel : CartModel, storeId : number){
    let index = cartModel.orderHs.findIndex(val => val.storeId == storeId);
    return cartModel.orderHs[index].orderData.orderC.preorderTime? false : true;
  }

  async updateOrderCPreorderTime(cartModel : CartModel, preorderTime : string, storeId: number){
    let index = cartModel.orderHs.findIndex(val => val.storeId == storeId);

    if(index != -1){
      cartModel.orderHs[index].orderData.orderC.preorderTime = preorderTime;
    }

    return cartModel;
  }

  async orderDLeadTimeCheck(orderD : OrderD, orderC : OrderC){
    let leadTime = {} as LeadTime;
    leadTime.type = orderD.leadType;
    leadTime.value = orderD.leadValue.toString();
    let preorderTime = await this.storeService.currentTimePlusLeadTime(leadTime);
    let isAfter : boolean;

    if(orderC.preorderTime){
      isAfter = await this.storeService.leadTimeCheck(preorderTime, orderC.preorderTime);
    }
    else{
      isAfter = true;
    }

    if(isAfter){
      let formattedTime = this.timeService.formatDateString(preorderTime);
      return formattedTime;
    }
    else{
      return orderC.preorderTime;
    }
  }

  async newOrderCPreorderTime(orderDs : OrderD[], isReorder? : boolean){
    let confirmedUnit : string;
    let availableItem = orderDs.filter(item => item.voidFlag == VoidFlag.Available);
    let leadTimeExists = availableItem.some(val => val.leadType && val.leadValue);

    if(!leadTimeExists){
      return null;
    }

    let preorderTime : string;
    for await(let item of orderDs){
      if(item.leadType && item.leadValue){
        let leadTime = {} as LeadTime;
        leadTime.type = item.leadType;
        leadTime.value = item.leadValue.toString();

        let currentTimePlusLeadTime = await this.storeService.currentTimePlusLeadTime(leadTime);
        if(!preorderTime){
          preorderTime = this.timeService.formatDateString(currentTimePlusLeadTime);
          confirmedUnit = leadTime.type;
        }
        else{
          let existingPreorderTime = moment(preorderTime);
          let isBigger = currentTimePlusLeadTime.isSameOrAfter(existingPreorderTime);
          preorderTime = isBigger? this.timeService.formatDateString(currentTimePlusLeadTime) : preorderTime;
          confirmedUnit = leadTime.type;
        }
      }
    }

    return {preorderTime : preorderTime, leadUnit: isReorder? confirmedUnit : ''};
  }

  async setVoidFlagIfPreorderDineIn(orderDs: OrderD[], sourceFlag: string){
    let orderType = this.storeService.getOrderType(sourceFlag);

    for await(let item of orderDs){
      if(orderType == OrderTypeFlag.DineIn && item.leadType && item.leadValue){
        item.voidFlag = VoidFlag.Unavailable;
      }
    }

    return orderDs;
  }
  //#endregion

  updateCartModel(cartModel : CartModel, orderH : OrderH){
    let index = cartModel.orderHs.findIndex(val => val.cartGUID == orderH.cartGUID);

    cartModel.orderHs[index] = orderH;
    return cartModel;
  }

  // initialize order modifier's orderD attr
  initModifierAttr(modifier: Modifier){
    let orderDAttr : OrderDAttr[] = [];
    for(let attrs of modifier.attrs){
      let orderAttr = {} as OrderDAttr;
      if(attrs.value){
        orderAttr.attrCode = attrs.code;
        orderAttr.attrValue = attrs.value;

        orderDAttr.push(orderAttr);
      }
    }

    return orderDAttr;
  }

  // initialize order submodifier's orderD attr
  initSubModifierAttr(submodifier : SubModifier){
    let orderDAttr : OrderDAttr[] = [];
    for(let attrs of submodifier.attrs){
      let orderAttr = {} as OrderDAttr;

      if(attrs.value){
        orderAttr.attrCode = attrs.code;
        orderAttr.attrValue = attrs.value;

        orderDAttr.push(orderAttr);
      }
    }

    return orderDAttr;
  }

  // remove particular item(orderD) for particular orderH
  async removeSelectedItem(orderLineGuid : string, qrTokenResponse: WebLinkTokenResponse, cartModel : CartModel, storeId : number, queueResponse? : QueueResponse,
     appLinkTokenResponse? : AppLinkTokenResponse){
    let storeIndex = cartModel.orderHs.findIndex(val => val.storeId == storeId);
    if(cartModel.orderHs[storeIndex].orderData.orderDs.length == 1){
      let cartTranIndex = cartModel.cartTranIds.findIndex(val => val == cartModel.orderHs[storeIndex].cartTranId);
      cartModel.orderHs.splice(storeIndex, 1);
      if(cartTranIndex != -1){
        cartModel.cartTranIds.splice(cartTranIndex, 1);
      }
    }
    else{
      let index = cartModel.orderHs[storeIndex].orderData.orderDs.findIndex(val => val.orderLineGUID == orderLineGuid);
      cartModel.orderHs[storeIndex].orderData.orderDs.splice(index, 1);
      let preorderTime = await this.newOrderCPreorderTime(cartModel.orderHs[storeIndex].orderData.orderDs);
      cartModel.orderHs[storeIndex].orderData.orderC.preorderTime = preorderTime ? preorderTime.preorderTime : null;
    }

    await this.recalculateCartChecking(qrTokenResponse, cartModel, queueResponse, appLinkTokenResponse);
  }

  async preorderItemInit(menuItem: MenuItem, menuData : OrderMenu[], storeResponse: StoreResponse, cartModel : CartModel,
    qrTokenResponse : WebLinkTokenResponse, orderLineGuid: string, chosenTime : string){
    let addItemInput = {} as AddItemObject;
    addItemInput.menuItem = menuItem;
    addItemInput.menuData = menuData;
    addItemInput.orderType = storeResponse.currentOrderType;
    addItemInput.chosenTime = chosenTime;
    addItemInput.distance = storeResponse.distance;
    addItemInput.menuRowVersion = storeResponse.menuRowVersion;
    addItemInput.storeId = storeResponse.storeId;
    addItemInput.storeName = storeResponse.locDesc;
    addItemInput.qrTokenResponse = qrTokenResponse ? qrTokenResponse : null;
    addItemInput.cartModel = cartModel;
    addItemInput.orderLineGuid = orderLineGuid ? orderLineGuid : null;

    await this.addItemToCart(addItemInput);
    this.preorderToastInit();
  }

  preorderToastInit(){
    let toastData = {} as ToastData;
    toastData.message = "merchant.preorder.desc.3";
    toastData.icon = "oda-check";
    toastData.iconColor = "#FFFFFF";
    toastData.iconCircleColor = "#8CD600";
    toastData.showCircleIcon = true;
    this.toastService.show(toastData);
  }

  //#region crossSell
  async processCrossSellData(menuSet : MenuSet[], orderH : OrderH){
    let crossSellList : string[] = [];
    let menuRecommended : MenuRecomm[] = [];

    for await(let set of menuSet){
      for await(let recomItem of set.menuRecomm){
        if(!recomItem.itemCode || recomItem.itemCode == ''){
          menuRecommended.push(recomItem);
        }
        else{
          let foundRecommendedItem = orderH.orderData.orderDs.find(val => val.itemCode == recomItem.itemCode);

          if(foundRecommendedItem){
            menuRecommended.push(recomItem);
          }
        }
      }
    }

    for await(let recomItem of menuRecommended){
      for await(let item of recomItem.recommItems){
        let foundItem = crossSellList.find(val => val == item);

        if(!foundItem){
          crossSellList.push(item);
        }
      }
    }

    crossSellList = await this.removeCrossSellItemThatExists(orderH.orderData.orderDs, crossSellList);

    let menuCatg = menuSet[0].menuCatgs;
    let crossSellItemList = await this.findCrossSellMenuItem(menuCatg, crossSellList);

    crossSellItemList = _.shuffle(crossSellItemList);
    // let slicedCrossSellItem = crossSellItemList.slice(0, 4);

    return crossSellItemList;
  }

  async findCrossSellMenuItem(menuCatgs : MenuCatg[], itemCodeList : string[]){
    let crossSellItemList : MenuItem[] = [];

    for await(let menuCatg of menuCatgs){
      if(menuCatg.code != "9999"){
        for await(let itemCode of itemCodeList){
          let foundItem = menuCatg.menuItems.find(item => item.itemCode == itemCode);

          if(foundItem && foundItem.status !== MenuItemStatus.Unavailable && crossSellItemList.findIndex(item => item.itemCode === foundItem.itemCode) == -1){
            let cloneFoundItem = _.cloneDeep(foundItem);
            cloneFoundItem.menuCatgsCode = menuCatg.code;
            crossSellItemList.push(cloneFoundItem);
          }
        }
      }
    }

    return crossSellItemList;
  }

  async removeCrossSellItemThatExists(orderD : OrderD[], itemCodes : string[]){
    for await(let item of orderD){
      let index = itemCodes.findIndex(code => code == item.itemCode);
      if(index != -1){
        itemCodes.splice(index, 1);
      }
    }

    return itemCodes;
  }

  //#endregion

  removePreviousFormatCart(){
    this.cartStore.remove((cart : Cart) => {
      let idToRemove : string | number = '';
      if(cart.id.toString().includes("null")){
        let splittedId = cart.id.toString().split('null-');
        idToRemove = splittedId.length == 1 ? cart.id : '';
      }
      return idToRemove;
    })
  }

  removeInactiveCart(){
    this.cartStore.remove((cart: Cart) => {
      let localTime = this.timeService.getAdjustedLocalTimeInUtc();
      let timeIsAfter = this.timeService.compareIsTimeAfter(localTime.format(), cart.dateAdded, environment.timeToRemove, 'days');
      return timeIsAfter ? cart.id : '';
    })
  }

  async refreshCustomerCart(){
    let cartModelFromDB  = await this.getCustomerCart(this.channelId, this.channelTag);
    let dateAdded = this.timeService.getAdjustedLocalTimeInUtc();
    let currentId = this.isLoggedIn ? this.customerId : "null-" + this.channelTag
    this.addCart({
      id: currentId,
      cartModel: cartModelFromDB,
      dateAdded: dateAdded.format()
    }, currentId);
  }

  // update table no checking for both after scanned and initially in store and cart page
  async updateTableNoWithCondition(currentStoreCart : OrderH, tableNo? : string, guestCount? : number){
    if(currentStoreCart.orderData.sourceFlag == OrderSourceFlag.WebDineIn || currentStoreCart.orderData.sourceFlag == OrderSourceFlag.AppDineIn ||
      currentStoreCart.orderData.sourceFlag == OrderSourceFlag.WebPickup || currentStoreCart.orderData.sourceFlag == OrderSourceFlag.AppPickup){
      currentStoreCart.orderData.tableNo = tableNo ? tableNo : null;
      currentStoreCart.orderData.guestCount = guestCount ? guestCount : 0;
    }
    else{
      currentStoreCart.orderData.tableNo = null;
      currentStoreCart.orderData.guestCount = 0;
    }

    return currentStoreCart;
  }

  async homeCartsetOrderIsSelect(cart : CartModel){
    this.removeIsFromStoreFlag();
    let cartModel = _.cloneDeep(cart);
    if (cartModel && cartModel?.orderHs.length > 1) {
      cartModel.orderHs = cartModel.orderHs.map(order => ({
        ...order,
        isSelect: false,
      }));
    }
    else {
      cartModel.orderHs[0].isSelect = true;
    }

    return cartModel;
  }

  async generateVariationList(orderD : OrderD, menuCatg : MenuCatg[]){
    // if parent item code and itemId exists(menu variations exists)
    let parentMenuItem : MenuItem = null;
    let variationList: MenuItem[];
    if(orderD.parentItemId || orderD.parentItemCode){
      // get parent menu item by itemId or itemCode
      parentMenuItem = await this.storeService.searchByItemIdOrItemCode(orderD.parentItemCode, orderD.parentItemId, menuCatg);
      // double check if parent item found contain variations list or not
      if(parentMenuItem && parentMenuItem.variations && parentMenuItem.variations?.length > 0){
        variationList = await this.findCrossSellMenuItem(menuCatg, parentMenuItem.variations);
      }
      else{
        variationList = [];
      }
    }
    else{
      variationList = [];
    }

    return variationList;
  }

  saveIsFromStoreFlag(storeId : number, storeTag : string){
    let storeFlagData = { storeId : storeId, storeTag : storeTag };
    this.sessionStorageService.setItem("isFromStore", JSON.stringify(storeFlagData));
  }

  //#region fromStore flag get/set
  getFromStoreFlag(){
    let isFromStorePageFlag = JSON.parse(this.sessionStorageService.getItem("isFromStore"));
    return isFromStorePageFlag ? isFromStorePageFlag : null;
  }

  removeIsFromStoreFlag(){
    this.sessionStorageService.removeItem("isFromStore");
  }
  //#endregion

  //#region membership
  async removeMembership(orderC : OrderC){
    orderC.membershipId = null;
    orderC.membershipNo = null;
    orderC.membershipCode = null;
    orderC.levelDesc = null;
    orderC.cardImageData = null;
    orderC.logoImageData = null;
    orderC.membershipDesc = null;
    orderC.stampEarned = 0;
    orderC.totalStamps = 0;
    orderC.membershipLevel = null;
    orderC.closingPoint = 0;
    orderC.memberType = 0;
    orderC.extMembershipCode = null;
    orderC.extMembershipLevel = null;
    orderC.extMembershipNo = null;

    return orderC;
  }

  async upsertMembership(orderC : OrderC, membershipResponse : MembershipWithPointStampResponse, appLinkTokenResponse? : AppLinkTokenResponse){
    if(membershipResponse.memberType !== 2){
      orderC.extMembershipCode = membershipResponse.membershipCode ? membershipResponse.membershipCode : null;
      orderC.extMembershipLevel = membershipResponse.membershipLevel ? membershipResponse.membershipLevel.toString() : null;
      orderC.extMembershipNo = membershipResponse.membershipNo ? membershipResponse.membershipNo : null;
      orderC.memberType = membershipResponse.memberType ? membershipResponse.memberType : null;
    }
    else{
      orderC.membershipNo = membershipResponse.membershipNo ? membershipResponse.membershipNo : null;
      orderC.membershipCode = membershipResponse.membershipCode ? membershipResponse.membershipCode : null;
      orderC.membershipLevel = membershipResponse.membershipLevel ? membershipResponse.membershipLevel : 0;
      orderC.memberType = membershipResponse.memberType ? membershipResponse.memberType : null;
    }

    orderC.membershipId = membershipResponse.membershipId ? membershipResponse.membershipId : null;
    orderC.levelDesc = membershipResponse.levelDesc ? membershipResponse.levelDesc : null;
    orderC.cardImageData = membershipResponse.cardImageData ? membershipResponse.cardImageData : null;
    orderC.logoImageData = membershipResponse.logoImageData ? membershipResponse.logoImageData : null;
    orderC.membershipDesc = membershipResponse.membershipDesc ? membershipResponse.membershipDesc : null;
    orderC.stampEarned = membershipResponse.stampEarned ? membershipResponse.stampEarned : 0;
    orderC.totalStamps = membershipResponse.totalStamp ? membershipResponse.totalStamp : 0;
    orderC.closingPoint = membershipResponse.closingPoint ? membershipResponse.closingPoint : 0;

    return orderC;
  }

  async updateMembershipInit(orderH : OrderH, membershipResponse : MembershipWithPointStampResponse[], appLinkTokenResponse? : AppLinkTokenResponse){
    if(membershipResponse && membershipResponse.length > 0){
      orderH.orderData.orderC = await this.upsertMembership(orderH.orderData.orderC, membershipResponse[0], appLinkTokenResponse);
    }
    else{
      orderH.orderData.orderC = await this.removeMembership(orderH.orderData.orderC);
    }

    return orderH;
  }
  //#endregion

  //#region keepCartRoute flag get/set
  setKeepCartRouteFlag(toKeepRoute : boolean){
    this.toKeepPreviousRoute = toKeepRoute;
  }

  getKeepCartRouteFlag(){
    return this.toKeepPreviousRoute;
  }
  //#endregion

  async getTotalOrderItemIncart(curStoreCart: OrderH) {
    let combinedCartList: any[] = [];
    let totalOrderQtyInCartPerItem: any[] = [];

    for (let index = 0; index < curStoreCart.orderData.orderDs.length; index++) {
      let cartListing = curStoreCart.orderData.orderDs[index];
      combinedCartList.push({
        itemCode: cartListing.itemCode,
        orderQty: cartListing.orderQty,
      });

      if(cartListing.modifiers){
        for(let modifiersIndex = 0; modifiersIndex < cartListing.modifiers.length; modifiersIndex++) {
          let modifiersListing = cartListing.modifiers[modifiersIndex];
          combinedCartList.push({
            itemCode: modifiersListing.itemCode,
            orderQty: modifiersListing.orderQty * cartListing.orderQty,
          })
        }
      }
    }

    if (combinedCartList && combinedCartList.length != 0) {
      const summedCart = combinedCartList.reduce((acc, currentItem) => {
        if (acc[currentItem.itemCode]) {
          acc[currentItem.itemCode].orderQty += currentItem.orderQty;
        } else {
          acc[currentItem.itemCode] = { ...currentItem };
        }
        return acc;
      }, {});


     totalOrderQtyInCartPerItem = Object.values(summedCart);
    }

    return totalOrderQtyInCartPerItem;
  }
}
