import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ExternalMemberData } from 'src/app/core/models/local/ExternalMembershipData';

/**
 * Create initial state
 */
 export function createInitialState(): ExternalMemberData {
  return {
    membershipCode: undefined,
    channelId: undefined,
    membershipDetailResponse: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'externalMembership-data' })
export class ExternalMemberDataStore extends Store<ExternalMemberData> {

  constructor() {
    super(createInitialState());
  }
}
