<div class="store_item_content_container" (scroll)="onScroll($event)" *ngIf="!upSellLoading" #scrollContainer>
  <div *ngIf="menuItemForMutation as itm">
    <div class="restaurant-image-container hidden-sm" (click)="clickEnlargeItemImage()">
      <div *ngIf="parentMenuItem && ((changeImageSetting && changeImageSetting?.setValue == '1') || !itm.thumbnail) then parentImage else itemImage"></div>

      <!-- menu variation and no change image, thumbnail container -->
      <ng-template #parentImage>
        <!-- container that controls the height of the image(uses parentMenuItem) -->
        <div class="image-height-container">
          <img [src]="parentMenuItem.thumbnail ? parentMenuItem.thumbnail : 'assets/image-unavailable-banner.svg'"
          [alt]="parentMenuItem.title" class="restaurant-image" onerror="this.src='assets/errorMsg_image/imageError.webp'"
          (error)="imageCantLoad()" loading="lazy" >
        </div>
      </ng-template>

      <!-- normal container for normal scenario -->
      <ng-template #itemImage>
         <!-- container that controls the height of the image(uses menuItemForMutation) -->
        <div class="image-height-container">
          <img [src]="itm.thumbnail" [alt]="itm.title" class="restaurant-image"
          onerror="this.src='assets/errorMsg_image/imageError.webp'" (error)="imageCantLoad()"
          *ngIf="itm.thumbnail != 'assets/image-unavailable.svg' && itm.thumbnail" loading="lazy">
          <img src="assets/image-unavailable-banner.svg" [alt]="itm.title" class="restaurant-image" onerror="this.src='assets/errorMsg_image/imageError.webp'"
          (error)="imageCantLoad()" *ngIf="itm.thumbnail == 'assets/image-unavailable.svg' || !itm.thumbnail">
        </div>
      </ng-template>

      <span class="icon-3d-container" (click)="show3DModelDialog(true); $event.stopPropagation" *ngIf="itm.mediaFiles && itm.mediaFiles.length > 0"><i class=" oda-icon-3d"><span></span></i></span>
      <span class="preorder-image-tag hidden-sm" (click)="emptyEvent($event)" *ngIf="menuItemForMutation.leadTime && menuItemForMutation.thumbnail">
        <app-preorder-tag [menuItem]="menuItemForMutation" [roundedSide]="'whole'"></app-preorder-tag>
      </span>
    </div>

    <!-- mobile view image container -->
    <div class="restaurant-image-container hidden-lg" (click)="clickEnlargeItemImage()">
      <div *ngIf="parentMenuItem && (changeImageSetting && changeImageSetting?.setValue == '1' || !itm.thumbnail); then mobileParentImage else mobileItemImage"></div>

      <!-- mobile thumbnail container for no change image scenario -->
      <ng-template #mobileParentImage>
        <!-- container that controls the height of the image(uses parentMenuItem) -->
        <div appStoreImageHeight [isItemDetail]="true" [imageSrc]="parentMenuItem.thumbnail" class="mobile-image-container">
          <img [src]="parentMenuItem.thumbnail ? parentMenuItem.thumbnail : 'assets/image-unavailable-banner.svg'"
          [alt]="parentMenuItem.title" class="restaurant-image" onerror="this.src='assets/errorMsg_image/imageError.webp'"
          (error)="imageCantLoad()" loading="lazy">
        </div>
      </ng-template>

      <!-- mobile thumbnail container normal scenario -->
      <ng-template #mobileItemImage>
        <!-- container that controls the height of the image(uses menuItemForMutation) -->
        <div appStoreImageHeight [isItemDetail]="true" [imageSrc]="itm.thumbnail" class="mobile-image-container">
          <img [src]="itm.thumbnail" [alt]="itm.title" class="restaurant-image"
          *ngIf="itm.thumbnail != null && itm.thumbnail != 'assets/image-unavailable.svg'" onerror="this.src='assets/errorMsg_image/imageError.webp'"
          (error)="imageCantLoad()" loading="lazy">
          <img src="assets/image-unavailable-banner.svg" [alt]="itm.title" class="restaurant-image" *ngIf="!itm.thumbnail || itm.thumbnail == 'assets/image-unavailable.svg'"
          onerror="this.src='assets/errorMsg_image/imageError.webp'" (error)="imageCantLoad()">
        </div>
      </ng-template>
    </div>

    <!-- desktop view close icon -->
    <div class="custom_close_icon_container hidden-sm" [ngClass]="{'close_icon_style_when_sticky': isStickyTitle == true}" (click)="closeDialog()">
      <i class="oda-times" [ngClass]="{'close_icon_color_when_sticky': isStickyTitle == true}"></i>
    </div>

    <!-- mobile view close icon -->
    <div class="mobile-close-click-area hidden-lg" (click)="closeDialog()" *ngIf="!isStickyTitle">
      <div class="mobile-custom-icon-container">
        <i class="oda-chevron-left"></i>
      </div>
    </div>

    <span class="icon-3d-container hidden-lg" (click)="show3DModelDialog(true)" *ngIf="itm.mediaFiles && itm.mediaFiles.length > 0"><i class=" oda-icon-3d"><span></span></i></span>
  </div>

  <div class="sticky-title-container" id="sticky-title-container">
    <i class="oda-chevron-left hidden-lg" (click)="closeDialog()"></i>
    <span class="sticky-title" [innerHtml]="menuItemForMutation.title"></span>
  </div>

  <!-- desktop view of item details -->
  <div class="item_description_container hidden-sm" id="description-container">
    <span class="preorder-tag-container hidden-sm" *ngIf="menuItemForMutation.leadTime && !menuItemForMutation.thumbnail">
      <app-preorder-tag
        [menuItem]="menuItemForMutation"
        [roundedSide]="'whole'">
      </app-preorder-tag>
    </span>

    <app-store-item-description
      [priceSymbol]="currencyCode"
      [selectedMenuItem]="menuItemForMutation">
    </app-store-item-description>
  </div>

  <!-- mobile container of item details -->
  <div class="hidden-lg mobile_item_description_container"
    [ngClass]="{'item-description-padding': !menuItemForMutation.modifierGrps && variationList.length == 0}"
    id="description-container-mobile">
    <div class="inner_container_design" [style.padding-top]="menuItemForMutation.leadTime ? '5px' : '15px'"
    [style.margin-top]="menuItemForMutation.thumbnail || !menuItemForMutation.thumbnail ? '-63px' : '0'">
      <span class="mobile-preorder-tag hidden-lg" *ngIf="menuItemForMutation.leadTime">
        <app-preorder-tag
          [menuItem]="menuItemForMutation"
          [roundedSide]="'right'">
        </app-preorder-tag>
      </span>
      <app-store-item-description
        [priceSymbol]="currencyCode"
        [selectedMenuItem]="menuItemForMutation">
      </app-store-item-description>
    </div>
  </div>

  <!-- menu variation -->
  <div class="menu-variation-outer-container"
    [ngClass]="{'padding-without-modifier': !menuItemForMutation.modifierGrps}"
    *ngIf="variationList.length > 0">
    <app-menu-variation
      [variationItem]="variationList"
      [orderLineGuid]="orderLineGuid"
      [currentMenuItem]="menuItemForMutation"
      (onClickVariationItem)="onClickVariationItem($event)">
    </app-menu-variation>
  </div>

  <!-- modifier navigation/tab menu -->
  <div class="modifierGrp_tab_container" *ngIf="menuItemForMutation.modifierGrps && ((parentMenuItem && isVariationChosen) || !parentMenuItem)" id="menu_nav_bar" #menu_nav_bar>
    <div class="p-d-flex modifierGrp_tab_bar">
      <div class="p-d-flex inner-bar" *ngFor="let item of menuItemForMutation.modifierGrps; let i = index" [id]="'ITM' +  item.code" (click)="scrollToSection(item.code)">
        <div class="modifierGrp_tab_tag" [ngClass]="{'padding-when-complete': item.isComplete}">
          <div class="section_number_tag" *ngIf="item.isComplete == undefined || item.isComplete == false"
          [ngClass]="{'current-section-tag': selectedSection == 'ITM' + item.code}">{{i + 1}}</div>
          <i class="oda-check-alt" *ngIf="item.isComplete == true"></i>
        </div>

        <div class="modifierGrp_tab_title">
          <label [ngClass]="{'current_tab_item' : selectedSection == 'ITM' + item.code, 'tab_title_default_tab': selectedSection != 'ITM' + item.code,
          'section-done-title': item.isComplete}" [innerHTML]="item.title"></label>
        </div>
      </div>
    </div>
  </div>

  <!-- container that is use to display modifier -->
  <div class="modifier_container" *ngIf="(parentMenuItem && isVariationChosen) || !parentMenuItem">
    <app-store-item-content
      [storeId]="storeId"
      [currencyCode]="currencyCode"
      [isMobileView]="isMobileView"
      [modifierGrps]="this.menuItemForMutation.modifierGrps"
      [tempSelectedQty]="this.menuItemForMutation.itemQty"
      [curStoreCart]="curStoreCart"
      (notifyBaseDisplayMaximum)="showMaximumMessage()"
      *ngIf="menuItemForMutation.modifierGrps != null">
    </app-store-item-content>

    <div class="remark-container" *ngIf="showRemarkLabel">
      <div class="remark_title" id="remark_section">{{"merchant.notetomerchant" | translate}}</div>

      <textarea name="remark" rows="4" cols="100" class="remark_input" placeholder="{{'merchant.notetomerchant.hint' | translate}}" style="resize: none;"
      maxlength="100" [(ngModel)]="remarks"></textarea>
    </div>
  </div>
</div>

<div class="upsell-container" *ngIf="upSellList.length > 0 && !upSellLoading">
  <div class="title-container">
    <span class="upsell-box"><img src="assets/upsell-box.svg" alt="odaring image"></span>
    <span class="upsell-title">{{"merchant.upgrade.your.choice" | translate}}</span>
    <span class="expand-container" (click)="expandCollapseControl()">
      <i class="oda-chevron-down" *ngIf="!isCollapse"></i>
      <i class="oda-chevron-up" *ngIf="isCollapse"></i>
    </span>
  </div>

  <app-popoular-order-slider
    [isUpsell]="true"
    [currencyCode]="currencyCode"
    [crossSellItem]="upSellList"
    [currentItem]="menuItemForMutation"
    (clickItem)="onClickUpsellItem($event)"
    [hidden]="isCollapse">
  </app-popoular-order-slider>
</div>

<div class="minMaxPerTranError" *ngIf="isShowMaxPerTranError || isShowMinPerTranError">
  <div class="error-container">
   <i class="oda-times"></i>
   <h1 *ngIf="isShowMinPerTranError">{{'min.per.tran.error.text' | translate: { minQty: menuItemForMutation.minQtyPerTran} }}</h1>
   <h1 *ngIf="isShowMaxPerTranError">{{'max.per.tran.error.text' | translate: { maxQty: menuItemForMutation.maxQtyPerTran} }}</h1>
   </div>
 </div>

<div class="store_item_footer" *ngIf="!upSellLoading">
  <div class="p-grid p-nogutter" style="width: 100%;">
    <div class="p-col-5">
      <div class="p-d-flex item_picker_container" *ngIf="!displayRequestRemove">
        <div class="footer_picker_button p-mr-4" (click)="minusItemQuantity()"
        [ngClass]="{'footer_minus_button_disable': (menuItemForMutation.itemQty == 1 && !orderLineGuid),
        'footer_minus_button_enable':(menuItemForMutation.itemQty > 1 || orderLineGuid)}">
          <i class="oda-minus"></i>
        </div>

        <div class="footer_quantity_indicator p-mr-4" *ngIf="menuItemForMutation != null">
          {{menuItemForMutation.itemQty}}
        </div>

        <button class="footer_picker_button plus-button" (click)="plusItemQuantity()"
        [ngClass]="{'plus_button_disable': isBlockAddItemQty ||
        (!menuItemForMutation.qtyBalance && menuItemForMutation.maxQtyPerTran && menuItemForMutation.itemQty >= remaningMaxQtyPerTran[menuItemForMutation.itemCode]) ||
        (balanceGreaterThanMaxQty && menuItemForMutation.itemQty >= remaningMaxQtyPerTran[menuItemForMutation.itemCode]) }"
        [disabled]="
        ((menuItemForMutation.qtyBalance && menuItemForMutation.qtyBalance != 0 && (!menuItemForMutation.maxQtyPerTran || !balanceGreaterThanMaxQty)) && menuItemForMutation.itemQty >= remaningQtyBalance[menuItemForMutation.itemCode])">
          <i class="oda-plus"></i>
        </button>
      </div>

      <div class="p-d-flex remove-action-button-container" *ngIf="displayRequestRemove">
        <button class="cancel-action-button" (click)="onClickCancel()">{{"button.cancel" | translate}}</button>
        <button class="remove-action-button" (click)="onClickRemove()">{{"button.remove" | translate}}</button>
      </div>
    </div>

    <div class="p-col-7">
      <button class="add_to_cart_button" [ngClass]="{'add_to_cart_disable': allComplete == false || !isVariationChosen,
        'add_to_cart_enable': (allComplete && !isVariation) || (allComplete && isVariationChosen && isVariation)}" (click)="addToCart()" *ngIf="!disableOrder">
        <span *ngIf="(!orderLineGuid && !menuItemForMutation.leadTime) || (orderLineGuid && isUpSell)">
          {{"button.add.for" | translate: {price: menuItemForMutation.dispPrice | itemTotalPrice : menuItemForMutation | async | currency : currencyCode : 'symbol-narrow' } }}
        </span>
        <span *ngIf="orderLineGuid && !isUpSell">
          {{"button.update.for" | translate : {price : menuItemForMutation.dispPrice | itemTotalPrice : menuItemForMutation | async | currency : currencyCode : 'symbol-narrow'} }}
        </span>
        <span *ngIf="!orderLineGuid && menuItemForMutation.leadTime">
          {{"button.preorder.for" | translate : {price : menuItemForMutation.dispPrice | itemTotalPrice : menuItemForMutation | async | currency : currencyCode : 'symbol-narrow'} }}
        </span>
      </button>
      <button *ngIf="disableOrder" class="disableAddCartBtn">
        {{"button.total.amount" | translate: {price: menuItemForMutation.dispPrice | itemTotalPrice : menuItemForMutation | async | currency : currencyCode : 'symbol-narrow' } }}
      </button>
    </div>
  </div>

</div>

<app-skeleton-placeholder class="skeleton-placeholder" *ngIf="upSellLoading"></app-skeleton-placeholder>
