import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { Region } from 'src/app/core/enums/Region';
import * as momentTz from 'moment-timezone';

import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { VoucherExtra } from 'src/app/core/models/VoucherExtra';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { VoucherHistory } from 'src/app/core/models/VoucherHistory';
import { HttpErrorResponse } from '@angular/common/http';
import { RewardService } from '../../services/reward.service';
import { OrderVoucher } from 'src/app/core/models/OrderVoucher';
import { TimeService } from 'src/app/core/services/time.service';
import { VoucherCatgCode } from 'src/app/core/enums/VoucherCatgCode';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { OrderData } from 'src/app/core/models/OrderData';

@Component({
  selector: 'app-reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.scss'],
})
export class RewardCardComponent implements OnInit, OnDestroy {
  @Input() exploreReward?: VoucherTypeExtraResponse;
  @Input() myReward?: VoucherExtra;
  @Input() historyReward?: VoucherHistory;
  @Input() showStoreName?: boolean;
  @Input() storeId: number;
  @Input() channelId: number;
  @Input() isLoggedIn?: boolean;
  @Input() customCardType?: VoucherCardType;
  @Input() searchText?: string;
  @Input() selectedReward?: OrderVoucher[];
  @Input() orderData?: OrderData;

  cardTypes: any = VoucherCardType;
  errorCode: any = ErrorCode;
  vchCatgCodes: any = VoucherCatgCode;
  displayHr: string = "";
  displayMin: string = "";
  displaySc: string = "";
  sendNotification: boolean = true;
  cardType: VoucherCardType;
  imageData: string = "";
  merchantDesc: string = "";
  vchTypTitle: string = "";
  vchTypDesc: string = "";
  vchCatgCode: string = "";
  effectiveDate: string = "";
  occuredDate: string = "";
  expiryDate: string = "";
  claimStartDate: string = "";
  claimEndDate: string = "";
  vchTypId: number = 0;
  totalIssuedPercentage: number = 0;
  issueLimitMode: number = 0;
  minNetAmt: number = 0;
  isChecked: boolean = false;
  isBelowMinAmt: boolean = false;
  serverTime: any;
  countDownTime: number[] = [];

  timer$: Subscription;

  constructor(private rewardService: RewardService,
    private timeService: TimeService,
    private toastService: ToastService) {}

  ngOnInit(): void {
    this.setData();
  }

  setData() {
    if(this.exploreReward) {
      this.cardType = this.customCardType && this.exploreReward.vchCatgCode !== this.vchCatgCodes.ODARING? this.customCardType: this.rewardService.checkRewardType(this.exploreReward);
      this.imageData = this.exploreReward.imageData? this.exploreReward.imageData: "";
      this.merchantDesc = this.exploreReward.merchantDesc? this.exploreReward.merchantDesc: "";
      this.vchTypTitle = this.exploreReward.vchTypTitle? this.exploreReward.vchTypTitle: "";
      this.vchTypDesc = this.exploreReward.vchTypDesc? this.exploreReward.vchTypDesc: "";
      this.vchCatgCode = this.exploreReward.vchCatgCode? this.exploreReward.vchCatgCode: "";
      this.effectiveDate = this.exploreReward.effectiveDate? this.exploreReward.effectiveDate: "";
      this.claimStartDate = this.exploreReward.claimStartDate? this.exploreReward.claimStartDate: "";
      this.claimEndDate = this.exploreReward.claimEndDate? this.exploreReward.claimEndDate: "";
      this.expiryDate = this.exploreReward.expiryDate? this.exploreReward.expiryDate: "";
      this.totalIssuedPercentage = this.exploreReward.totalIssuedPercentage? this.exploreReward.totalIssuedPercentage: 0;
      this.issueLimitMode = this.exploreReward.issueLimitMode? this.exploreReward.issueLimitMode: 0;

      if(this.exploreReward.claimEndDate && this.cardType === this.cardTypes.claim) {
        this.claimEndDate = this.exploreReward.claimEndDate
        //this.timer(this.claimEndDate);
      } else {
        this.claimEndDate = "";
      }
    }

    if(this.myReward){
      this.cardType = this.customCardType? this.customCardType: this.cardTypes.use;
      this.imageData = this.myReward.imageData? this.myReward.imageData: "";
      this.merchantDesc = this.myReward.merchantDesc? this.myReward.merchantDesc: "";
      this.vchTypId = this.myReward.vchTypId? this.myReward.vchTypId: 0;
      this.vchTypTitle = this.myReward.vchTypTitle? this.myReward.vchTypTitle: "";
      this.vchTypDesc = this.myReward.vchTypDesc? this.myReward.vchTypDesc: "";
      this.vchCatgCode =  this.myReward.vchCatgCode? this.myReward.vchCatgCode: "";
      this.effectiveDate = this.myReward.effectiveDate? this.myReward.effectiveDate: "";
      this.expiryDate = this.myReward.expiryDate? this.myReward.expiryDate: "";
      this.minNetAmt = this.myReward.minNetAmt? this.myReward.minNetAmt: 0;
      this.isChecked = this.selectedReward && this.selectedReward.map((selectedReward: VoucherExtra) => selectedReward.voucherNo).includes(this.myReward.voucherNo);
      this.isBelowMinAmt = this.minNetAmt && this.minNetAmt > this.orderData?.netAmt && (this.vchTypId && !this.orderData?.vouchers.find((voucher: OrderVoucher) => voucher.vchTypId === this.vchTypId))? true: false;
    }

    if(this.historyReward){
      this.cardType = this.customCardType? this.customCardType: this.cardTypes.none;
      this.imageData = this.historyReward.imageData? this.historyReward.imageData: "";
      this.vchTypTitle = this.historyReward.vchTypTitle? this.historyReward.vchTypTitle: "";
      this.vchTypDesc = this.historyReward.vchTypDesc? this.historyReward.vchTypDesc: "";
      this.vchCatgCode =  this.historyReward.vchCatgCode? this.historyReward.vchCatgCode: "";
      this.occuredDate = this.historyReward.occuredDate? this.historyReward.occuredDate: "";
      this.expiryDate = this.historyReward.expiryDate? this.historyReward.expiryDate: "";
      this.merchantDesc = this.historyReward.merchantDesc? this.historyReward.merchantDesc: "";
    }
  }

  timer(endDate: string) {
    this.serverTime = momentTz.tz(this.serverTime, Region.UTC);
    this.serverTime = this.timeService.convertToTimezoned(this.serverTime);

    let endDateMoment = moment(endDate);
    let interval = endDateMoment.diff(this.serverTime, "seconds");

    if(interval > 0) {
      let source = timer(1000, 1000);

      this.timer$ = source.subscribe(val => {
        let timeLeft = interval - val;

        if(timeLeft >= 0) {
          let hours = Math.floor(timeLeft / 3600);
          let minutes = Math.floor((timeLeft - (hours * 3600)) / 60);
          let seconds = timeLeft - (hours * 3600) - (minutes * 60);

          this.countDownTime = [hours, minutes, seconds];
          this.displayHr = hours < 10 ? "0" + hours: hours.toString();
          this.displayMin = minutes < 10 ? "0" + minutes: minutes.toString();
          this.displaySc = seconds < 10 ? "0" + seconds: seconds.toString();
        } else {
          this.countDownTime = [];
          this.cardType = this.cardType === this.cardTypes.claim? this.cardTypes.none: this.cardType;
          this.timer$.unsubscribe();
        }
      });
    }
  }

  onShowDetail() {
    if(this.exploreReward) {
      this.rewardService.detailDialog.next({isShow: true, vchTypId: this.exploreReward.vchTypId, cardType: this.cardType});
    }

    if(this.myReward) {
      this.rewardService.detailDialog.next({isShow: true, voucherNo: this.myReward.voucherNo, vchCatgId: this.myReward.vchCatgId, cardType: this.cardType});
    }
  }

  onShowOtherStore() {
    let storeList: any = this.exploreReward? this.exploreReward.storeResponseList: this.myReward.storeResponseList;
    this.rewardService.storeDialog.next({isShow: true, storeList: storeList});
  }

  onShowQr() {
    this.rewardService.qrDialog.next({isShow: true, voucherNo: this.myReward? this.myReward.voucherNo: null});
  }

  onClickCard(event: any) {
    switch(this.cardType) {
      case this.cardTypes.singleSelect: {
        this.onClickRadio();
        break;
      }
      case this.cardTypes.multiSelect: {
        this.onClickCheckbox();
        break;
      }
      default: {
        this.onShowDetail();
        break;
      }
    }
  }

  onClickRadio(event?: any) {
    let btnEl = event? event.target: document.getElementById('select-voucher-' + this.myReward?.voucherNo) as HTMLInputElement;
    let btnCheck = event? btnEl.checked: !btnEl.checked;

    if(btnEl && !this.isBelowMinAmt) {
      this.rewardService.selectVoucher.next({selected: btnCheck, voucher: this.myReward, type: this.cardTypes.singleSelect});
    }
  }

  onClickCheckbox(event?: any) {
    let btnEl = event? event.target: document.getElementById('select-voucher-' + this.myReward?.voucherNo) as HTMLInputElement;
    let btnCheck = event? btnEl.checked: !btnEl.checked;

    if(btnEl && !this.isBelowMinAmt) {
      this.rewardService.selectVoucher.next({selected: btnCheck, voucher: this.myReward, type: this.cardTypes.multiSelect});
    }
  }

  async onClaim() {
    let response: any;
    let cardType: VoucherCardType;

    if(this.exploreReward && this.isLoggedIn) {
      let manualAssignSerialList : string[] = [];
      manualAssignSerialList.push(this.exploreReward.vchSerialNo ? this.exploreReward.vchSerialNo : null);

      response = await this.rewardService.claimVoucher(this.exploreReward.vchTypId, this.storeId, this.channelId, this.sendNotification, manualAssignSerialList);

      if(response instanceof(HttpErrorResponse)){
        if(response.error.errorCode === this.errorCode.VoucherFullyClaimed_400) {
          cardType = this.cardTypes.full;

          let toastData = {} as ToastData;
          toastData.icon = "oda-alert";
          toastData.iconColor = "#FFFFFF";
          toastData.message = "alert.rewards.fully.claimed";
          this.toastService.show(toastData);
        }

        if(response.error.errorCode === this.errorCode.VoucherClaimLimitReached_400) {
          cardType = this.cardTypes.claimed;
        }
      } else {
        cardType = this.cardTypes.claimed;
        this.cardType = this.cardTypes.claimed;

        let toastData = {} as ToastData;
        toastData.icon = "oda-check-alt";
        toastData.iconColor = "#8CD600";
        toastData.message = "alert.voucher.claimed.successful";
        this.toastService.show(toastData);
      }

      this.rewardService.updateVoucherClaimStatus(this.storeId, this.exploreReward.vchTypId, cardType);
    }

    if(!this.isLoggedIn) {
      this.rewardService.loginDialog.next(true);
    }
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }
}
