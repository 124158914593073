import { StoreService } from 'src/app/store/store/store.service';
import { StoreMenuResponseExtra } from 'src/app/core/models/StoreMenuResponseExtra';
import { Pipe, PipeTransform } from '@angular/core';
import { OrderH } from 'src/app/core/models/OrderH';
import { Store } from 'src/app/store/store/store.model';
import { CartStoreItem } from '../cart.component';
import * as _ from 'lodash';
import { Menu } from 'src/app/core/menu/menu.model';
import { StoreItemStatsModel } from 'src/app/core/store-item-stats/store-item-stats.model';
import * as deepClone from 'rfdc';

@Pipe({
  name: 'cartStoreBind'
})
export class CartStoreBindPipe implements PipeTransform {

  constructor(
    private storeService : StoreService
  ){ }

  async transform(value: OrderH[], orderH : OrderH[], storeData : Store[], menu : Menu[], storeItemStats : StoreItemStatsModel[]) {
    // push and relate one orderH with one store
    let cartStoreItem = [];
    let arrayOfStoreAndCart = [];

    if(!orderH || (orderH && orderH.length == 0)){
      return cartStoreItem;
    }
    arrayOfStoreAndCart = await this.cartStoreItemInit(orderH, storeData, menu, storeItemStats);

    cartStoreItem = _.cloneDeep(arrayOfStoreAndCart);
    return cartStoreItem;
  }

  async cartStoreItemInit(orderH : OrderH[], storeData : Store[], menu : Menu[], storeItemStats : StoreItemStatsModel[]){
    let orderLength = orderH.length;
    let arrayOfStoreAndCart = [];

    for(let index = 0; index < orderLength; index++){
      let oneStoreAndCart = {} as CartStoreItem;
      oneStoreAndCart.orderH = orderH[index];

      let currentStore = storeData && storeData?.length > 0 ? storeData?.find(store => store.id == orderH[index].storeId) : null;

      if(currentStore){
        let currentMenu = menu && menu?.length > 0 ? menu?.find(menu => menu.id == orderH[index].storeId) : null;
        let currentStoreItemStats = storeItemStats && storeItemStats?.length > 0 ? storeItemStats.find(itemStats => itemStats.id == orderH[index].storeId) : null;
        let store = {} as StoreMenuResponseExtra;
        store.storeResponses = currentStore ? currentStore.storeResponse : null;
        store.storeVoucherType = currentStore ? currentStore.storeVoucherType : null;
        store.storePromotion = currentStore ? currentStore.storePromotion : null;
        store.storeItemStats = currentStoreItemStats ? currentStoreItemStats.storeItemStats : null;

        if(currentMenu){
          let menuResponseClone = deepClone({proto : true})(currentMenu.menuResponse);
          let menuCatgs = await this.storeService.storeItemStatsFilter(store.storeItemStats, menuResponseClone[0].menuSets[0].menuCatgs);

          menuResponseClone[0].menuSets[0].menuCatgs = menuCatgs;
          store.menuResponse = menuResponseClone;
        }
        else{
          store.menuResponse = null;
        }

        store.MerchantMemberships = currentStore ? currentStore.merchantMemberships: null;
        oneStoreAndCart.store = storeData && storeData?.length > 0 ? store : null;
      }
      else{
        oneStoreAndCart.store = null;
      }

      arrayOfStoreAndCart.push(oneStoreAndCart);
    }

    return arrayOfStoreAndCart;
  }

}
