export enum OrderSourceFlag{
  POS = "POS", //0
  WebDineIn = "WebDineIn", //1
  WebPickup = "WebPickup", //3
  WebDelivery = "WebDelivery", //4
  AppDineIn = "AppDineIn", //5
  AppPickup = "AppPickup", //7
  AppDelivery = "AppDelivery", //8
  WebQrDineIn = "WebQrDineIn", //9
  WebQrTakeaway = "WebQrTakeaway", //10
  AppQrDineIn = "AppQrDineIn", //11
  AppQrTakeaway = "AppQrTakeaway", //12
}
