export enum ErrorCode{
  InternalError_500 = 'InternalError_500',
  RemoteServiceFailed_500 = 'RemoteServiceFailed_500',
  UserRegisterConflict_409 = 'UserRegisterConflict_409',
  NotFound_404 = 'NotFound_404',
  InvalidOTP = 'InvalidOTP',
  InvalidRequest = 'InvalidRequest',
  InvalidFormat_400 = 'InvalidFormat_400',
  IsRequired_400 = 'IsRequired_400',
  ForbiddenChannelAccess_403 = 'ForbiddenChannelAccess_403',
  PendingApprovalChannelAccess_403 = 'PendingApprovalChannelAccess_403',
  ExpiredChannelAccess_403 = 'ExpiredChannelAccess_403',
  ChannelNotFound_404 = 'ChannelNotFound_404',
  AddressNotFound_404 = 'AddressNotFound_404',
  CustomerChannelNotFound_404 = 'CustomerChannelNotFound_404',
  GoogleServiceFailed_500 = 'GoogleServiceFailed_500',
  UnauthorizeAccess_401 = 'UnauthorizeAccess_401',
  PaymentServiceFailed_500 = 'PaymentServiceFailed_500',
  ContentNotSupported_415 = 'ContentNotSupported_415',
  DuplicatedField_409 = 'DuplicatedField_409',
  AccessKeyNull_400 = 'AccessKeyNull_400',
  LocationCodeNull_400 = 'LocationCodeNull_400',
  ChannelIdNull_400 = 'ChannelIdNull_400',
  StoreIdNull_400 = 'StoreIdNull_400',
  SourceIdNull_400 = 'SourceIdNull_400',
  VersionNoNull_400 = 'VersionNoNull_400',
  StoreIdLocCodeInvalid_400 = 'StoreIdLocCodeInvalid_400',
  AccessKeyInvalid_400 = 'AccessKeyInvalid_400',
  VoucherNotFound_404 = "VoucherNotFound_404",
  VoucherStatusError_400 = "VoucherStatusError_400",
  PosSalesNotFound_404 = 'PosSalesNotFound_404',
  PosSalesNotAllowedToRevert_400 = "PosSalesNotAllowedToRevert_400",
  StoreReceiptImageFailed_500 = 'StoreReceiptImageFailed_500',
  MembershipNotFound_404 = 'MembershipNotFound_404',
  MembershipLevelNotFound_404 = 'MembershipLevelNotFound_404',
  PosSalesNull_400 = 'PosSalesNull_400',
  PointInsufficient_400 = 'PointInsufficient_400',
  MembershipNotOpenForPurchase_400 = 'MembershipNotOpenForPurchase_400',
  CustomerNotFound_404 = 'CustomerNotFound_404',
  MemberNoCounterSettingError_400 = 'MemberNoCounterSettingError_400',
  VoucherDocCounterSettingError_400 = "VoucherDocCounterSettingError_400",
  MembershipNoNotFound_404 = 'MembershipNoNotFound_404',
  CustomerRegistered_400 = 'CustomerRegistered_400',
  MembershipActivated_400 = 'MembershipActivated_400',
  MembershipCardNotFound_404 = 'MembershipCardNotFound_404',
  MembershipCardNotAllowedRegister_400 = 'MembershipCardNotAllowedRegister_400',
  CardNoRegistered_400 = 'CardNoRegistered_400',
  WalletCodeNotFound_404 = 'WalletCodeNotFound_404',
  InvalidWebLinkToken_400 = 'InvalidWebLinkToken_400',
  ReqTimePast_400 = 'ReqTimePast_400',
  OutOfCoverage_400 = 'OutOfCoverage_400',
  OrderPaid_400 = 'OrderPaid_400',
  CartNotFound_404 = 'CartNotFound_404',
  InvalidateTokenFailed_500 = 'InvalidateTokenFailed_500',
  RefundTimePast_400 = 'RefundTimePast_400',
  OrderNotFound_404 = 'OrderNotFound_404',
  DistanceMatrixAPIServiceFailed_500 = 'DistanceMatrixAPIServiceFailed_500',
  VoucherFullyClaimed_400 = "VoucherFullyClaimed_400",
  VoucherClaimLimitReached_400 = 'VoucherClaimLimitReached_400',
  PromotionFullyClaimed_400 = 'PromotionFullyClaimed_400',
  PromotionClaimLimitReached_400 = 'PromotionClaimLimitReached_400',
  JoinMembershipFailed_500 = 'JoinMembershipFailed_500',
  GuestAccessDenied_401 = 'GuestAccessDenied_401',
  InvalidUserOperation = 'InvalidUserOperation',
  InvalidOrderType = 'InvalidOrderType',
  EmailVerificationLinkExpired_400 = 'EmailVerificationLinkExpired_400',
  CustomerNotJoinedMembership_400 = 'CustomerNotJoinedMembership_400',
  ExcessiveOTPRequest_400 = 'ExcessiveOTPRequest_400',
  StoreCloseUnableToOrder_400 = 'StoreCloseUnableToOrder_400',
  MembershipRdmNotFound_404 = 'MembershipRdmNotFound_404',
  VoidItemUnableToOrder_400 = 'VoidItemUnableToOrder_400',
  MobileNotVerified_400 = 'MobileNotVerified_400',
  SystemMaintenance_400 = 'SystemMaintenance_400',
  PasswordMisMatch_400 = 'PasswordMisMatch_400',
  OrderNotMatchPromotionCriteria_400 = 'OrderNotMatchPromotionCriteria_400',
  TrigQtyMoreThanOrderTrigQty_400 = 'TrigQtyMoreThanOrderTrigQty_400',
  InvalidVoucher_400 = 'InvalidVoucher_400',
  InvalidPromotion_400 = 'InvalidPromotion_400',
  InvalidVoucherOrPromotion_400 = 'InvalidVoucherOrPromotion_400',
  PromotionNotFound_404= 'PromotionNotFound_404',
  Payment_InsufficientFund_400 = 'Payment_InsufficientFund_400',
  Payment_InvalidCardNumber_400 = 'Payment_InvalidCardNumber_400',
  Payment_InvalidSavedPayment_400 = 'Payment_InvalidSavedPayment_400',
  Payment_TemporaryError_500 = 'Payment_TemporaryError_500',
  Payment_InsufficientFunds_400 = 'Payment_InsufficientFunds_400',
  Payment_ExpiredCard_400 = 'Payment_ExpiredCard_400',
  Payment_ConfigError_400 = 'Payment_ConfigError_400',
  Payment_InvalidServiceCode_400 = 'Payment_InvalidServiceCode_400',
  Payment_SecurityViolation_406 = 'Payment_SecurityViolation_406',
  Payment_ActivityLimitExceeded_400 = 'Payment_ActivityLimitExceeded_400',
  Payment_IssuerorSwitchisUnavailable_404 = 'Payment_IssuerorSwitchisUnavailable_404',
  Payment_SystemError_400 = 'Payment_SystemError_400',
  Payment_CustomerRequestedStop_400 = 'Payment_CustomerRequestedStop_400',
  Payment_SignatureError_400 = 'Payment_SignatureError_400',
  Payment_IPNotWhitelisted_400 = 'Payment_IPNotWhitelisted_400',
  Payment_DuplicateOrderID_400 = 'Payment_DuplicateOrderID_400',
  Payment_OverTransactionLimit_400 = 'Payment_OverTransactionLimit_400',
  Payment_InvalidRefundAmount_400 = 'Payment_InvalidRefundAmount_400',
  Payment_InvalidSavedPaymentToken_400 = 'Payment_InvalidSavedPaymentToken_400',
  Payment_UnknownError_500 = 'Payment_UnknownError_500',
  PageSizeNotAllowed_400 = 'PageSizeNotAllowed_400',
  QRNotTurnedOnForThisStore_400 = 'QRNotTurnedOnForThisStore_400',
  StoreNotFoundInThisChannel_404 = 'StoreNotFoundInThisChannel_404',
  InvalidRowVersion_400 = 'InvalidRowVersion_400',
  DeletedAccount_401 = 'DeletedAccount_401',
  NotAllowMerged_400 = 'NotAllowMerged_400',
  NotAllowUpdate_400 = 'NotAllowUpdate_400',
  OrderProcessing_400 = 'OrderProcessing_400',
  QueueNotFound_404 = "QueueNotFound_404",
  QueueExpired_400 = "QueueExpired_400",
  InvalidQueueStatus_400 = "InvalidQueueStatus_400",
  QueueNotTurnedOnForThisStore_400 = "QueueNotTurnedOnForThisStore_400",
  MembershipExpired_400 = "MembershipExpired_400",
  OngoingPendingPayment_400 = "OngoingPendingPayment_400",
  LoginRequiredToUsePromo_400 = "LoginRequiredToUsePromo_400",
  LoginIDPasswordInvalid_400 = "LoginIDPasswordInvalid_400",
  MinOrderAmt_400 = "MinOrderAmt_400",
  OrderItemBalanceQtyInvalid_400 = "OrderItemBalanceQtyInvalid_400",
  ItemBlockedNotSync_204 = "ItemBlockedNotSync_204",
  InvalidZeroAmtCheckout = "InvalidZeroAmtCheckout",
  OrderReqLogin_401 = 'OrderReqLogin_401',
  RequiredTableNo_400 = 'RequiredTableNo_400'
}
