<div class="setup-password-container" [ngClass]="{ 'inProfile': isProfile }">
    <div class="content-container">
        <div class="header">
            <div class="hidden-lg"><app-back-button></app-back-button></div>
            <h1 class="hidden-lg">{{ "setup.password.title" | translate }}</h1>
            <button type="button" class="hidden-lg btn-skip" (click)="onSkip()" *ngIf="!isProfile">{{ "otp.countdown.skip" | translate }}</button>
        </div>
        <img src="assets/mascot/Mascot_login_reset-password@2x.webp" alt="odaring mascot"/>
        <h1 class="heading-5 hidden-sm setup-title">{{ "setup.password.title" | translate }}</h1>
        <p class="setup-msg">{{ "setup.password.desc" | translate }}</p>

        <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
            <div class="input-field">
                <label for="password">{{ 'input.label.2' | translate }}</label>
                <div class="inline-btn">
                    <input id="password" [type]="showPw? 'text': 'password'" pInputText formControlName="password" placeholder="{{ 'input.placeholder.1' | translate }}">
                    <i class="oda-eye-shut" *ngIf="f.password.value.length > 0" [ngClass]="{'oda-eye-shut': !showPw, 'oda-eye-open': showPw }" (click)="showPw = !showPw"></i>
                </div>
                <div class="error" *ngIf="f.password.value.length > 0" [ngClass]="{'active': !f.password.errors?.minlength}"><i class="oda-check-alt"></i><p [innerHTML]="'register.form.password.length.validation' | translate"></p></div>
            </div>
            <button type="submit" class="btn-complete" [disabled]="!fgroup.valid">{{ "button.complete.password" | translate }}</button>
        </form>

        <button type="button" class="hidden-sm btn-skip" (click)="onSkip()" *ngIf="!isProfile">{{ "otp.countdown.skip" | translate }}</button>
    </div>
</div>
