import { Component, ViewEncapsulation, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent {

  displayAppDownloadDialog: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() { }

  showAppDialog() {
    this.displayAppDownloadDialog = true;
  }

  onDownloadNavigate(dt: string) {
    switch (dt) {
      case 'apple-store': {
        window.open("https://apps.apple.com/my/app/odaring/id1586588150", '_blank');
        break;
      }
      case 'google-play': {
        window.open("https://play.google.com/store/apps/details?id=com.evolftech.odaring", '_blank');
        break;
      }
      case 'huawei-gallery': {
        window.open("https://appgallery.huawei.com/app/C104529893", '_blank');
        break;
      }
    }
  }

}
