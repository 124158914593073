import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-technical-error',
  templateUrl: './technical-error.component.html',
  styleUrls: ['./technical-error.component.scss']
})
export class TechnicalErrorComponent implements OnInit {

  showTechnicalErrorPageFound: boolean = false;

  constructor(
    private loc: Location,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showTechnicalErrorPageFound = true;
    }
  }

  reload() {
    if (window.navigator.onLine) {
      this.loc.back();
    }
  }

  onClickNavigation(){
    this.router.navigate(['/']);
  }

}
