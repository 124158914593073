<div class="item-card-v-container">

  <div [hidden]="!stores.length">
    <h1 class="item-card-v-heading-1">{{channelSec?.data?.sectionDesc}}</h1>
  </div>

  <div class="p-grid" *ngIf="stores.length">
    <div class="p-col-12 p-lg-3 store-panel" *ngFor="let storeData of stores">
      <!--desktop menu card-->
      <app-item-card class="hidden-sm" [storeData]="storeData" [showMoreImages]="true"
                     [showStoreNearBy]="showStoreNearBy" (click)="onClickStore(storeData)"></app-item-card>

      <!--mobile mene card-->
      <app-mobile-item-card-channel-stores class="hidden-lg" [storeData]="storeData" [showMoreImages]="true"
                                           [showStoreNearBy]="showStoreNearBy" (click)="onClickStore(storeData)"></app-mobile-item-card-channel-stores>
    </div>
  </div>

  <ng-template [ngIf]="isOnLoad">
    <div class="infinity-loading">
      <img src="assets/Loading-animation.gif" class="spinner" alt="loading image"/>
    </div>
  </ng-template>

  <ng-template [ngIf]="!isContLoadData">
    <div class="hidden-lg end-load-text">
      {{'home.end.scroll.desc' | translate}}
    </div>
  </ng-template>
</div>

