import { Component, OnInit } from '@angular/core';
import { OtpService } from './../services/otp.service';


@Component({
  selector: 'app-reset-mobile',
  templateUrl: './reset-mobile.component.html',
  styleUrls: ['./reset-mobile.component.scss']
})
export class ResetMobileComponent implements OnInit {

  constructor(
    private otpService: OtpService
  ) { }

  ngOnInit(): void {
  }
}
