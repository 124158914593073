import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { Cart, CartD, CartObject, ModGrps, SelectMod, SelectModGrp, SelectSubModGrp } from '../models/Cart';
import { UtilsService } from './utils.service';
import { ChannelService } from 'src/app/home/channel/channel.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  itemList: any = [];

  itemList$: Subject<any> = new Subject<any>();//Change Detection

  cartObj: CartObject = {
    cart: [
      {
        cartTranId: null,
        cartGUID: null,
        storeName: "Original Penang Nasi Kandar",
        channelId: 4,
        channelTag: "Odaring",
        storeId: 2,
        custId: null,
        guestCount: null,
        currCode: null,
        grossAmt: null,
        discAmt: null,
        netAmt: null,
        grossAmtExc: null,
        discAmtExc: null,
        netAmtExc: null,
        taxAmtInc: null,
        taxAmtExc: null,
        chargeAmtInc: null,
        chargeAmtExc: null,
        otherChargeAmtInc: null,
        otherChargeAmtExc: null,
        voidAmt: null,
        adjAmt: null,
        itemQty: null,
        comboQty: null,
        modQty: null,
        totalItemQty: null,
        voidQty: null,
        cutleryFlag: null,
        cancelFlag: null,
        sourceFlag: null,
        createdDate: null,
        lastRevDate: null,
        cartC: {
          cartTranId: 0,
          custId: 21,
          memId: null,
          memLvl: null,
          custName: "julius chooi",
          addr1: null,
          addr2: null,
          addr3: null,
          city: null,
          email: "kengleong92@gmail.com",
          mobileNo: "0132947608",
          refNo: null,
          reqTime: null,
          estTime: null,
          stampEarned: null,
          totalStamps: null,
          awardPoint: null,
          redeemPoint: null,
          closingPoint: null,
          yTDPurchase: null,
          rewardSalesAmt: null,
          creditLimit: null,
          creditUsage: null,
          balCreditLimit: null,
          lat: 3.1390017,
          lng: 101.6868533,
          dis: null,
          remark: null,
          rowVersion: null
        },
        cartD: [
          {
            cartTranId: 0,
            custId: 21,
            olGUID: "12bc2b1d-726c-401c-b3ef-94a3f00c5b19",
            itemCode: "A012",
            proImg: "https://i.imgur.com/NN3wLAd.jpg",
            proName: "Breakfast Set",
            remarks: "",
            orderQty: 1,
            unitSellPrice: 16,
            grossAmt: 16,
            discAmt: null,
            netAmt: null,
            grossAmtExc: null,
            discAmtExc: null,
            netAmtExc: null,
            taxAmtInc: null,
            taxAmtExc: null,
            chargeAmtInc: null,
            chargeAmtExc: null,
            unitPointAward: null,
            unitPointRedeem: null,
            pointAwarded: null,
            pointRedeemed: null,
            voidFlag: 0,
            sourceFlag: null,
            comboGroup: null,
            selectModGrps: [
              {
                modGrps: {
                  Code: "1",
                  Title: "Beverage",
                  SeqNo: 1,
                  Status: "A",
                  MinSelect: 0,
                  MaxSelect: 1,
                  Modifiers: [
                    {
                      ItemCode: "B016C",
                      Title: "2x Breakfast Cold Pearl Milk Tea",
                      SeqNo: 0,
                      Status: "A",
                      Price: 400,
                      MinSelect: 2,
                      MaxSelect: 2,
                      SubModifierGrps: [
                        {
                          Code: "TOPPING",
                          Title: "Milk Tea Topping",
                          SeqNo: 1,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 11,
                          SubModifiers: [
                            {
                              ItemCode: "T01",
                              Title: "Pearl ??",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T02",
                              Title: "Aiyu",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T03",
                              Title: "Pudding",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T04",
                              Title: "Grass Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T05",
                              Title: "QQ",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T06",
                              Title: "Coconut Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T07",
                              Title: "Oreo Cookie Pieces",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T08",
                              Title: "Aloe Vera",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T09",
                              Title: "Rainbow Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T10",
                              Title: "Coffee Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T11",
                              Title: "Red Bean",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "COLDDRINK",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 1,
                          SubModifiers: [
                            {
                              ItemCode: "O005",
                              Title: "Less Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O006",
                              Title: "No Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "B016C-L",
                      Title: "Breakfast Cold Pearl Milk Tea (Large)",
                      SeqNo: 2,
                      Status: "A",
                      Price: 400,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "TOPPING",
                          Title: "Milk Tea Topping",
                          SeqNo: 1,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 11,
                          SubModifiers: [
                            {
                              ItemCode: "T01",
                              Title: "Pearl ??",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T02",
                              Title: "Aiyu",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T03",
                              Title: "Pudding",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T04",
                              Title: "Grass Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T05",
                              Title: "QQ",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T06",
                              Title: "Coconut Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T07",
                              Title: "Oreo Cookie Pieces",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T08",
                              Title: "Aloe Vera",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T09",
                              Title: "Rainbow Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T10",
                              Title: "Coffee Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T11",
                              Title: "Red Bean",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "COLDDRINK",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 1,
                          SubModifiers: [
                            {
                              ItemCode: "O005",
                              Title: "Less Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O006",
                              Title: "No Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "B013",
                      Title: "Breakfast Milk Coffee",
                      SeqNo: 2,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "BEVERAGE",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 3,
                          SubModifiers: [
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O008",
                              Title: "Less Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O009",
                              Title: "No Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "COFFEE",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 1,
                          SubModifiers: [
                            {
                              ItemCode: "O010",
                              Title: "Extra Shot",
                              SeqNo: 0,
                              Status: "A",
                              Price: 106,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O011",
                              Title: "Decaffeinated",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "B014",
                      Title: "2x Breakfast Milk Tea",
                      SeqNo: 2,
                      Status: "A",
                      Price: 200,
                      MinSelect: 2,
                      MaxSelect: 2,
                      SubModifierGrps: [
                        {
                          Code: "BEVERAGE",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 3,
                          SubModifiers: [
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O008",
                              Title: "Less Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O009",
                              Title: "No Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "B015",
                      Title: "Breakfast Cham",
                      SeqNo: 3,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "BEVERAGE",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 3,
                          SubModifiers: [
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O008",
                              Title: "Less Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O009",
                              Title: "No Sweet",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                selectMod: [
                  {
                    mod: {
                      ItemCode: "B016C",
                      Title: "2x Breakfast Cold Pearl Milk Tea",
                      SeqNo: 0,
                      Status: "A",
                      Price: 400,
                      MinSelect: 2,
                      MaxSelect: 2,
                      SubModifierGrps: [
                        {
                          Code: "TOPPING",
                          Title: "Milk Tea Topping",
                          SeqNo: 1,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 11,
                          SubModifiers: [
                            {
                              ItemCode: "T01",
                              Title: "Pearl ??",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T02",
                              Title: "Aiyu",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T03",
                              Title: "Pudding",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T04",
                              Title: "Grass Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T05",
                              Title: "QQ",
                              SeqNo: 0,
                              Status: "A",
                              Price: 150,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T06",
                              Title: "Coconut Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T07",
                              Title: "Oreo Cookie Pieces",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T08",
                              Title: "Aloe Vera",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T09",
                              Title: "Rainbow Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T10",
                              Title: "Coffee Jelly",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "T11",
                              Title: "Red Bean",
                              SeqNo: 0,
                              Status: "A",
                              Price: 200,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "COLDDRINK",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 1,
                          SubModifiers: [
                            {
                              ItemCode: "O005",
                              Title: "Less Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O006",
                              Title: "No Ice",
                              SeqNo: 0,
                              Status: "A",
                              Price: 55,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    total: 1,
                    subModGrps: []
                  }
                ]
              },
              {
                modGrps: {
                  Code: "2",
                  Title: "Main",
                  SeqNo: 2,
                  Status: "A",
                  MinSelect: 1,
                  MaxSelect: 1,
                  Modifiers: [
                    {
                      ItemCode: "W008",
                      Title: "Breakfast Hawaiian Sensation",
                      SeqNo: 0,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "W003",
                          Title: "Choice Of Cheese",
                          SeqNo: 0,
                          Status: "A",
                          MinSelect: 2,
                          MaxSelect: 1,
                          SubModifiers: [
                            {
                              ItemCode: "O019",
                              Title: "Swiss Cheese",
                              SeqNo: 0,
                              Status: "A",
                              Price: 100,
                              MinSelect: 0,
                              MaxSelect: 0
                            },
                            {
                              ItemCode: "O018",
                              Title: "Cheddar Cheese",
                              SeqNo: 2,
                              Status: "A",
                              Price: 100,
                              MinSelect: 0,
                              MaxSelect: 0
                            }
                          ]
                        },
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A001",
                      Title: "Breakfast Nasi Lemak Chicken",
                      SeqNo: 1,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A003",
                      Title: "Breakfast Dry Chili Pan Mee",
                      SeqNo: 3,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A004",
                      Title: "Breakfast Prawn Mee",
                      SeqNo: 4,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "A001",
                          Title: "Noodle",
                          SeqNo: 0,
                          Status: "A",
                          MinSelect: 1,
                          MaxSelect: 2,
                          SubModifiers: [
                            {
                              ItemCode: "O043",
                              Title: "Mihun",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O044",
                              Title: "Mee",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O045",
                              Title: "Kuew Tiao",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A005",
                      Title: "Breakfast Curry Mee",
                      SeqNo: 5,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "A001",
                          Title: "Noodle",
                          SeqNo: 0,
                          Status: "A",
                          MinSelect: 1,
                          MaxSelect: 2,
                          SubModifiers: [
                            {
                              ItemCode: "O043",
                              Title: "Mihun",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O044",
                              Title: "Mee",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O045",
                              Title: "Kuew Tiao",
                              SeqNo: 0,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        },
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                selectMod: [
                  {
                    mod: {
                      ItemCode: "A001",
                      Title: "Breakfast Nasi Lemak Chicken",
                      SeqNo: 1,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    total: 1,
                    subModGrps: []
                  }
                ]
              },
              {
                modGrps: {
                  Code: "3",
                  Title: "Extras",
                  SeqNo: 3,
                  Status: "A",
                  MinSelect: 1,
                  MaxSelect: 4,
                  Modifiers: [
                    {
                      ItemCode: "A017",
                      Title: "Breakfast Extra Nasi Lemak",
                      SeqNo: 1,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A018",
                      Title: "Breakfast Extra Chicken Rice",
                      SeqNo: 2,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    {
                      ItemCode: "A019",
                      Title: "Breakfast Extra Noodle",
                      SeqNo: 3,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                selectMod: [
                  {
                    mod: {
                      ItemCode: "A017",
                      Title: "Breakfast Extra Nasi Lemak",
                      SeqNo: 1,
                      Status: "A",
                      Price: 200,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: [
                        {
                          Code: "FOOD",
                          Title: "Remark",
                          SeqNo: 10,
                          Status: "A",
                          MinSelect: 0,
                          MaxSelect: 7,
                          SubModifiers: [
                            {
                              ItemCode: "O046",
                              Title: "Less Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O047",
                              Title: "More Salt",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O048",
                              Title: "Less Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O049",
                              Title: "More Spicy",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O054",
                              Title: "No Onion",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O055",
                              Title: "Less Oil",
                              SeqNo: 2,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            },
                            {
                              ItemCode: "O007",
                              Title: "Server Later",
                              SeqNo: 5,
                              Status: "A",
                              Price: 0,
                              MinSelect: 0,
                              MaxSelect: 1
                            }
                          ]
                        }
                      ]
                    },
                    total: 1,
                    subModGrps: []
                  }
                ]
              },
              {
                modGrps: {
                  Code: "4",
                  Title: "Starter",
                  SeqNo: 4,
                  Status: "A",
                  MinSelect: 1,
                  MaxSelect: 1,
                  Modifiers: [
                    {
                      ItemCode: "ND013",
                      Title: "Starter",
                      SeqNo: 0,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: null
                    },
                    {
                      ItemCode: "ND014",
                      Title: "Side Salad",
                      SeqNo: 0,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: null
                    }
                  ]
                },
                selectMod: [
                  {
                    mod: {
                      ItemCode: "ND014",
                      Title: "Side Salad",
                      SeqNo: 0,
                      Status: "A",
                      Price: 0,
                      MinSelect: 1,
                      MaxSelect: 1,
                      SubModifierGrps: null
                    },
                    total: 1,
                    subModGrps: []
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  };

  submitedCartObj$: Subject<boolean> = new Subject<boolean>();//Change Detection

  constructor(
    private utilsService: UtilsService,
    private channelService: ChannelService
  ) { }


  addItem(itm: any) {
    const itmDt = _.cloneDeep(itm);
    this.itemList.push(itmDt);
    this.triggerUpdate();
  }

  updateItem(itm: any, ind: number) {
    delete itm.itmInd
    this.itemList[ind] = itm;
    this.triggerUpdate();
  }

  deleteItem(indItm: number[]) {
    this.itemList = this.itemList.filter((val: any, ind: number) => !indItm.includes(ind));
    this.triggerUpdate();
  }

  clearItem() {
    this.itemList = [];
    this.triggerUpdate();
  }

  getItemList() {
    return this.itemList$.asObservable();
  }

  triggerUpdate() {
    this.itemList$.next(this.itemList);
  }

  get submitedCartObj() {
    return this.submitedCartObj$.asObservable();
  }

  async placeOrder(itm: any) {
      const cartObj = this.formatCartObj(itm);
      const resp = await this.channelService.submitOrder(cartObj);
      this.clearItem();
      this.changeSubmittedCartObj();
  }

  private changeSubmittedCartObj() {
    this.submitedCartObj$.next(true);
  }

  private formatCartObj(itm: any) {
    let cartDt = { } as Cart,
    cartDList = [] as CartD[],
    itemQty = 0,
    comboQty = 0;
    let cartMd = itm;

    cartMd.forEach((dt: any) => {
          const itmDt = dt.itmDt;
          let cartD = {} as CartD,
              selectModGrps = [] as SelectModGrp[];
          cartD.unitSellPrice = itmDt.DispPrice;
          cartD.itemCode = itmDt.ItemCode;
          cartD.proName = itmDt.Title;
          cartD.orderQty = itmDt.Qty;
          itmDt.ModifierGrps.forEach((modGrpDt: any) => {
              let selectModGrp = {} as SelectModGrp,
                  modGrpDtL = _.cloneDeep(modGrpDt);
              selectModGrp.modGrps = this.removeProp(modGrpDtL, 'Qty') as ModGrps;
              if (!(modGrpDtL.Qty && modGrpDtL.Qty > 0)) {
                  return;
              }
              selectModGrp.selectMod = [];
              modGrpDtL.Modifiers.filter((mod: any) => !!mod.Qty).forEach((mod: any) => {
                  let selectModL = {} as SelectMod;
                  selectModL.mod = this.removeProp(mod, 'Qty');
                  selectModL.total = mod.Qty;
                  if (!(mod.SubModifierGrps && mod.SubModifierGrps.length > 0)) {
                      return;
                  }
                  mod.SubModifierGrps.filter((subModGrp: any) => !!subModGrp.Qty).forEach((subModGrp: any) => {
                      if (!(subModGrp.SubModifiers && subModGrp.SubModifiers.length > 0)) {
                          return;
                      }
                      selectModL.subModGrps = [];
                      subModGrp.SubModifiers.filter((subMod: any) => !!subMod.Qty && subMod.Qty > 0).forEach((subMod: any) => {
                          let selectSubModGrp = {} as SelectSubModGrp;
                          selectSubModGrp.total = subMod.Qty && subMod.Qty > 0? subMod.Qty : 0;
                          selectSubModGrp.subMod = this.removeProp(subMod, 'Qty');
                          selectModL.subModGrps.push(selectSubModGrp);
                      });
                  });
                  selectModGrp.selectMod.push(selectModL);
              });
              selectModGrps.push(selectModGrp);
          });
          cartD.selectModGrps = selectModGrps;
          cartDList.push(cartD);
    });

    cartDt.itemQty = itemQty;
    cartDt.comboQty = comboQty;
    cartDt.cartD = cartDList;

    return cartDt;
  }

  private removeProp(dt: any, prop: string,) {
    return this.utilsService.removeProp(_.cloneDeep(dt), prop)
  }




}


