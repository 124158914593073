<div class="upSell-skeleton">
  <p-skeleton class="image-placeholder"></p-skeleton>

  <div class="item-title-container">
    <p-skeleton class="item-title"></p-skeleton>
    <p-skeleton class="item-description1"></p-skeleton>
    <p-skeleton class="item-description2"></p-skeleton>
  </div>

  <div class="item-modifier-container">
    <p-skeleton class="modifier-title"></p-skeleton>
    <p-skeleton class="modifier-item" *ngFor="let item of [1, 2, 3]"></p-skeleton>
  </div>
</div>

