import { Resolve, Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from 'src/app/core/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileResolver implements Resolve<any> {
  constructor(
    private userService: UserService
    ) {}

   resolve() {
    return this.userService.getProfile();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserProfileCanActivate implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    ) {}

    canActivate() {
      if (!this.userService.isUserExist()) {
        return this.router.parseUrl("/login");
      }
      return true;
    }


}


