import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-order-paid-message',
  templateUrl: './order-paid-message.component.html',
  styleUrls: ['./order-paid-message.component.scss']
})
export class OrderPaidMessageComponent implements OnInit {

  @Output() onCloseMessage = new EventEmitter();
  @Input() isOrderPaid : boolean;

  constructor() { }

  ngOnInit(): void {
  }

  onClickOk(){
    this.onCloseMessage.emit();
  }

}
