import { QueueService } from './../../../queue/queue/queue.service';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PageName } from 'src/app/core/enums';
import { JoinQueueRequest } from 'src/app/core/models/JoinQueueRequest';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { ProfileService } from '../../services/profile.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-setup-password-form',
  templateUrl: './setup-password-form.component.html',
  styleUrls: ['./setup-password-form.component.scss']
})
export class SetupPasswordFormComponent implements OnInit {
  @Input() isProfile: boolean = false;
  @Input() isQueue : boolean = false;

  user: User;
  showPw: boolean = false;

  fgroup: UntypedFormGroup = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
  });

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private previousRouteService: PreviousRouteService,
    private userService: UserService,
    private queueService : QueueService,
    private profileService : ProfileService,
    private sessionStorageService: SessionStorageService
  ) { }

  get fg() { return this.fgroup; }

  get f() { return this.fg.controls; }

  ngOnInit(): void {
    this.user = this.userService.getCustomer();
  }

  async onSkip() {
    if(this.isQueue){
      // get join request from session storage
      let queueRequest = {} as JoinQueueRequest;
      queueRequest = JSON.parse(this.sessionStorageService.getItem("queueRequest"));
      await this.queueService.onJoinQueueInit(queueRequest);
    }
    else{
      this.goToPreviousRoute();
    }
  }

  goToPreviousRoute() {
    let route = this.previousRouteService.getPreviousRoute().routeName;
    this.previousRouteService.removePreviousRoute();
    this.router.navigateByUrl(route, {replaceUrl: true, state: {reinit : true}});
  }

  async onSubmit() {
    if(this.isQueue){
      // get join request from session storage
      let queueRequest = {} as JoinQueueRequest;
      queueRequest = JSON.parse(this.sessionStorageService.getItem("queueRequest"));
      await this.profileService.onUpdatePassword("", this.fgroup.value['password']);
      await this.queueService.onJoinQueueInit(queueRequest);
    }
    else{
      this.userService.setupPw.next(this.fgroup.value['password']);
    }
  }
}
