import { CommonAddressResponse } from './../../core/models/CommonAddressResponse';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChannelStore } from './channel.store';
import { Channel } from './channel.model';
import { StoreMode } from 'src/app/core/enums';
import { ChannelQuery } from './channel.query';
import { CustomService } from 'src/app/core/services/custom.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomRequest } from 'src/app/core/models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelSecService } from '../channelsec/channelsec.service';
import { ChannelData, ChannelRequest, ChannelSection } from 'src/app/core/models';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { Cart } from 'src/app/core/models/Cart';
import { Observable, Subject, TimeoutError } from 'rxjs';
import { ChannelCustomerResponse } from 'src/app/core/models/ChannelCustomerResponse';
import { UserQuery } from 'src/app/core/user/user.query';
import { UserChnlQuery } from '../userchnl/userchnl.query';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import { ChannelSec } from '../channelsec/channelsec.model';
import { ChannelSectionResponseGenericDataResponse } from 'src/app/core/models/ChannelSectionResponseGenericDataResponse';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/config.service';
import { TimeService } from 'src/app/core/services/time.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { AddrSysFlag } from 'src/app/core/enums/AddrSysFlag';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { FilterItem } from 'src/app/core/models/local/FilterItem';
import { SetCode } from 'src/app/core/enums/SetCode';
import { MiniProgramService } from 'src/app/core/mini-program/mini-program.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { UserChnlService } from '../userchnl/userchnl.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Injectable({ providedIn: 'root' })
export class ChannelService {
  accessToken: string = "";
  curUser: User | null = null;
  curChannel: Channel = null;
  channelReq: ChannelRequest = {
    skip: 0,
    take: 10,
    channelTag: environment.odaringChannel,
    orderType: OrderTypeFlag.All,
    storeAttrs: undefined
  };
  selectedAddress: CommonAddressResponse;
  isUserLogin: boolean = false;
  isSubDomain: boolean = false;
  channelInfo$: Subject<any> = new Subject<any>();
  defaultCurrency: CurrencyResponse = {
    currCode: 'MYR',
    currDesc: 'malaysia countrycode',
    currPrefix: 'RM',
    currSuffix: undefined,
    rateUDF: undefined,
    lastRevDate: undefined,
    currExponent: undefined
  };
  selectedChannel: ChannelCustomerResponse = null;
  gpsLocation: any = null;
  subdomainChannelTag: string = '';
  parseURL = location.href.split("/");
  initialChannelTag: string = '';
  switchChannelResult: any;

  currentOrderType : string;
  switchChannelData : ChannelCustomerResponse;

  constructor(private channelStore: ChannelStore,
    private http: HttpClient,
    private customService: CustomService,
    private utilsService: UtilsService,
    private channelQuery: ChannelQuery,
    private userService: UserService,
    private chnlsecService: ChannelSecService,
    private userQuery: UserQuery,
    private userchnlQry: UserChnlQuery,
    private router: Router,
    private configService: ConfigService,
    private timeService: TimeService,
    private addressService: AddressService,
    private staticQrService: StaticQrService,
    private authService: AuthService,
    private qrCartService: QrCartService,
    private route: ActivatedRoute,
    private miniProgramService : MiniProgramService,
    private sessionStorageService: SessionStorageService,
    private userchnService: UserChnlService,
    private analyticsService: AnalyticsService,
  ) {
    this.get(StoreMode.Internal).subscribe((channelData: Channel) => {
      if (channelData) {
        this.curChannel = channelData;
        this.recentAddressCheck(channelData);
      } else {
       this.initialChannelTag = localStorage.getItem('channelTag');
      }
    });

    this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.curUser = userData ? userData : null;
      this.isUserLogin = userData ? true : false;
    });

    this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });

    this.userchnlQry.isSubDomain$.subscribe((isSubDomain) => {
      this.isSubDomain = isSubDomain;
      if (isSubDomain) {
        const hostname = window.location.hostname;
        this.subdomainChannelTag = hostname.split(".")[0];
      }
    });
  }

  get(storeMode: StoreMode): Observable<Channel> {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.channelStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.channelStore.setLoading(false);
    }
    return this.channelQuery.selectFirst();
  }

  add(store: Channel) {
    this.channelStore.add(store);
  }

  update(id: any, store: Channel) {
    this.channelStore.update(id, store);
  }

  remove(id: ID) {
    this.channelStore.remove(id);
  }

  clear() {
    if (!this.curChannel) {
      return;
    }
    this.remove(this.curChannel.id);
  }

  error(err: any) {
    this.channelStore.setError(err);
  }

  destroy() {
    this.channelStore.destroy();
  }

  getChannelId() {
    return this.curChannel.channelId;
  }

  getChannelData() {
    return this.curChannel?.data;
  }

  async getChannelDataCheck() {
    await this.userchnService.checkUpdateDomainFlag();

    if (this.curChannel && this.curChannel.channelId) {
      return await this.curChannel;
    } else {
      await this.setupChannelTag();
      await this.switchChannelVerify();
      return this.curChannel;
    }
  }

  async recallGetChannelData() {
    await this.userchnService.checkUpdateDomainFlag();
    await this.setupChannelTag();
    await this.switchChannelVerify();
  }

  async setupChannelTag() {
    let channelRequest: ChannelRequest = null;
    let localChannelTag = '';

    if (this.isSubDomain) {
      const hostname = window.location.hostname;

      channelRequest = {
        channelTag: this.subdomainChannelTag === 'www' ? hostname.split(".")[1] : this.subdomainChannelTag
      }
    } else {
      if (this.curChannel) {
        localChannelTag = this.curChannel.channelTag;
      } else if (localStorage.getItem('channelTag')) {
        if (localStorage.getItem('channelTag') == this.parseURL[3].toUpperCase()) {
          localChannelTag = localStorage.getItem('channelTag');
        } else {
          localChannelTag = this.parseURL[3].toUpperCase();
        }
      } else {
        localChannelTag = this.parseURL[3].toUpperCase();
      }

      channelRequest = {
        channelTag: localChannelTag
      }
    }

    this.channelReq.channelTag = channelRequest?.channelTag;
  }

  async switchChannelVerify(getChannel : boolean = true) {
    this.switchChannelResult = await this.switchChannelCheck();
    if (this.switchChannelResult instanceof TimeoutError) {
      return;
    }

    if (this.switchChannelResult instanceof HttpErrorResponse) {
      if (this.switchChannelResult['status'] !== 400 && this.switchChannelResult['error']['detail'] !== 'SystemMaintenance_400' && this.switchChannelResult['status'] !== 401) {
        this.router.navigateByUrl('channel-not-found');
      }
    } else {
      await this.staticQrService.staticDataClearCheck(this.switchChannelResult.channelId);
      await this.switchChannelClearCheck();
      localStorage.removeItem('channelTag');
      localStorage.setItem('channelTag', this.parseURL[3].toUpperCase());
      this.configService.set('appBaseHref', '/' + (this.isSubDomain ? this.subdomainChannelTag : this.parseURL[3]));

      if(getChannel){
        this.curChannel = {
          ...this.curChannel,
          channelId: this.switchChannelResult.channelId
        };

        if (this.switchChannelResult.addrSysFlag === AddrSysFlag.Predefined || this.selectedAddress?.type === AddrSysFlag.Predefined) {
          this.selectedAddress = null;
        }
        await this.getChannel();
      }
      else{
        return this.switchChannelResult;
      }
    }
  }

  async switchChannelClearCheck() {
    let currentTag = localStorage.getItem('channelTag');

    if (currentTag) {
      let channelTag = this.isSubDomain ? this.subdomainChannelTag : this.parseURL[3];
      if (channelTag.toLowerCase() != currentTag.toLowerCase()) {
        this.staticQrService.remove();
        this.staticQrService.removeChannelOrderType();
      }
    }
  }

  async setupStaticQr() {
    let staticQr = this.staticQrService.get();
    if (staticQr && staticQr?.orderType) {
      this.channelReq = { ...this.channelReq, orderType: staticQr.orderType };
    }
  }

  private setActive(tizId: number) {
    this.channelStore.setActive(tizId);
  }

  async submitOrder(cart: Cart) {
    return cart;
  }

  async getCustomerChannel() {
    let respDt = null;
    respDt = await this.reqCustomerChannel(this.accessToken);
    return respDt;
  }

  // using
  async getChannel(channelReq?: Partial<ChannelRequest>) {
    let respDt = null;

    if (channelReq) {
      this.channelReq.channelTag = channelReq.channelTag;
    }

    // get order type store in sessions storage if it existed
    let channelOrderType = this.staticQrService.getChannelOrderType();
    if(!channelOrderType){
      channelOrderType = this.staticQrService.getOrderTypeFromurl();
    }

    await this.filterAttrVerify();

    let reqDt: ChannelRequest = {
      ...this.channelReq,
      latitude: this.selectedAddress ? this.selectedAddress.latitude : 0,
      longitude: this.selectedAddress ? this.selectedAddress.longitude : 0,
      ...channelReq,
      orderType: channelOrderType ? channelOrderType : OrderTypeFlag.All
    };
    respDt = await this.reqChannelData(this.accessToken, reqDt.latitude, reqDt.longitude, reqDt.skip, reqDt.take,
      reqDt.channelId, reqDt.channelTag, reqDt.orderType, reqDt.storeAttrs, reqDt.minPrice, reqDt.maxPrice, reqDt.rate);

    if (respDt instanceof HttpErrorResponse === false) {
      this.addChannel(respDt['data'], reqDt);
      localStorage.setItem('channelTag', respDt['data'].channelTag);
      localStorage.setItem('countryCode', respDt['data'].countryCode);
    } else {
      this.router.navigate(['/channel-not-found']);
    }

    return respDt;
  }

  async switchChannel(channelTag?: string, channelId?: string, countryCode?: string): Promise<ChannelCustomerResponse> {
    let respDt = null;
    respDt = await this.reqSwitchChannel(this.accessToken, channelTag, channelId, countryCode);
    return respDt;
  }

  async getHomeChannelSection(homeSecId: number, skip: number, take: number): Promise<ChannelSectionResponseGenericDataResponse> {
    let respDt = null;

    await this.filterAttrVerify();

    let reqDt: ChannelRequest = {
      ...this.channelReq,
      latitude: this.selectedAddress ? this.selectedAddress.latitude : 0,
      longitude: this.selectedAddress ? this.selectedAddress.longitude : 0,
      homeSecId: homeSecId,
    };

    respDt = await this.reqHomeChannelSection(this.accessToken, reqDt.latitude, reqDt.longitude, skip, take,
      this.curChannel.channelId, homeSecId, this.curChannel.orderType, this.curChannel.channelTag, reqDt.storeAttrs, undefined, reqDt.minPrice, reqDt.maxPrice, reqDt.rate);
    return respDt;
  }

  async deleteCustomerChannel() {
    let respDt = null;
    respDt = await this.reqDeleteCustomerChannel(this.accessToken, 'MadamLow');
    return respDt;
  }

  async getPlaceAutoComplete(channelTag: string, keyword: string): Promise<CommonAddressResponse[]> {
    let respDt = null;
    respDt = await this.reqPlaceAutoComplete(this.accessToken, channelTag, keyword);
    return respDt;
  }

  async getPlaceDetail(addressId: string, type: string): Promise<CommonAddressResponse> {
    let respDt = null;
    respDt = await this.reqPlaceDetail(this.accessToken, addressId, type);
    return respDt;
  }

  async getLocationAddress(longitude: number, latitude: number, channelId?: number): Promise<CommonAddressResponse> {
    let respDt = null;
    let currentChannelId = channelId ? channelId : this.curChannel ? this.curChannel.channelId : 1;
    respDt = await this.reqGetLocationAddress(this.accessToken, longitude, latitude, currentChannelId);
    return respDt;
  }

  async getInfoPanelData(infoId: number) {
    let respDt = null;
    respDt = await this.reqGetInfoPanelData(this.accessToken, infoId);
    return respDt;
  }

  private async reqCustomerChannel(accessToken: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqChannelData(accessToken: string, latitude: number, longitude: number, skip?: number, take?: number,
    channelId?: number, channelTag: string = environment.odaringChannel, orderType: string = 'All', storeAttrs?: string,
    minPrice?: number, maxPrice?: number, rates?: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetChannelData,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      queryParams: {
        latitude: latitude ? latitude : 0,
        longitude: longitude ? longitude : 0,
        skip: skip,
        take: take,
        channelId: channelId,
        channelTag: channelTag,
        orderType: orderType,
        storeAttrs: storeAttrs,
        minPrice: minPrice,
        maxPrice: maxPrice,
        rates: rates ? rates : undefined,
      },
      httpHeaderType: this.accessToken ? HttpHeaderType.Auth : HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqSwitchChannel(accessToken: string, channelTag: string, channelId?: string, countryCode?: string) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channel.SwitchChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelTag: channelTag,
        channelId: channelId,
        countryCode: countryCode
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqHomeChannelSection(accessToken: string, latitude: number, longitude: number, skip: number, take: number,
    channelId: number, homeSectionId: number, orderType?: string, channelTag?: string, storeAttrs?: string, excludeMerchantIds?,
    minPrice?: number, maxPrice?: number, rates?: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetChannelHomeSection,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      queryParams: {
        latitude: latitude,
        longitude: longitude,
        skip: skip,
        take: take,
        channelId: channelId,
        homeSectionId: homeSectionId,
        orderType: orderType,
        channelTag: channelTag,
        storeAttrs: storeAttrs,
        excludeMerchantIds: excludeMerchantIds,
        minPrice: minPrice,
        maxPrice: maxPrice,
        rates: rates
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqDeleteCustomerChannel(accessToken: string, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channel.DeleteCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqPlaceAutoComplete(accessToken: string, channelTag: string, keyword: string): Promise<CommonAddressResponse[]> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.PlaceAutoComplete,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        ChannelTag: channelTag,
        keyword: keyword
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqPlaceDetail(accessToken: string, addressId: string, type: string): Promise<CommonAddressResponse> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetPlaceDetails,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        addressid: addressId,
        type: type
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqGetLocationAddress(accessToken: string, longtitude: number, latitude: number, channelId: number): Promise<CommonAddressResponse> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetlocationAddress,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        longtitude: longtitude,
        latitude: latitude,
        channelId: channelId
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);

    if (respInfo instanceof HttpErrorResponse === false) {
      this.analyticsService.locationRequestEvent();
    }

    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqGetInfoPanelData(accessToken: string, infoId: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.infoPanel.GetInfoPanelData,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
        channelTag: this.curChannel.channelTag
      },
      queryParams: {
        infoId: infoId
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => {
      if (dd && dd.headers) {
        this.timeService.setServerTime(dd.headers.get("X-ServerDateTime"));
        this.timeService.calculateServerTimeDifference();
      }
      return dd && dd['body'] ? dd['body'] : dd
    });
  }

  private formatSrvDateStr(date: Date) {
    const utilSv = this.utilsService;
    return utilSv.formatSrvDateStr(date);
  }

  getCurrentChannel(): Channel {
    return this.curChannel;
  }


  // Using
  private addChannel(data: any, channelReq?: Partial<ChannelRequest>) {
    const chnsecSv = this.chnlsecService;
    const chnDt = data as ChannelData;
    const chnsecDt = chnDt.channelHomeSections as ChannelSection[];

    if (!(this.curUser)) {
      this.curUser = { id: environment.localGenCustomerId } as User;
    }

    const fmtDt = chnsecDt.map(dd => {
      let nDt = {
        id: (this.curUser?.id || environment.localGenCustomerId) + "-" + chnDt.channelId + "-" + chnDt.channelTag + "-" + dd.sectionId,
        channelCode: chnDt.channelCode,
        channelId: chnDt.channelId,
        channelTag: chnDt.channelTag,
        // homeSecId: dd.homeSectionId,
        secId: dd.sectionId,
        data: dd
      };
      nDt = chnsecSv.format(nDt) as any;
      return nDt;
    });

    chnDt.channelHomeSections = fmtDt as ChannelSec[];

    if (!chnDt.currency) {
      chnDt.currency = this.defaultCurrency;
    }

    this.channelStore.remove();
    this.channelStore.upsert(this.curUser.id, {
      id: this.curUser.id || environment.localGenCustomerId,
      channelCode: chnDt.channelCode,
      channelId: chnDt.channelId,
      channelTag: chnDt.channelTag,
      orderType: channelReq && channelReq.orderType ? channelReq.orderType : 'All',
      data: chnDt
    });

    if (chnDt.channelHomeSections.length > 0) {
      chnsecSv.resetChannelHomeSection(chnDt.channelHomeSections);
    }
  }

  async updateChannelData(channelDataResponse: ChannelSectionResponseGenericDataResponse) {
    let chnHmSecData = this.curChannel.data.channelHomeSections as ChannelSec[];
    let matchIndex = chnHmSecData.findIndex(data => data.secId === channelDataResponse.data.sectionId);

    let currentStoreData = this.curChannel.data.channelHomeSections[matchIndex].data['data_1'];
    let incomingStoreData = channelDataResponse.data['data_1'];
    let combinedStoreData = _.concat(currentStoreData, incomingStoreData);

    let newCurChannel: any = _.cloneDeep(this.curChannel);
    newCurChannel.data.channelHomeSections[matchIndex].data.data[1].dataSet = _.cloneDeep(combinedStoreData);
    newCurChannel.data.channelHomeSections[matchIndex].data['data_1'] = _.cloneDeep(combinedStoreData);

    if (newCurChannel) {
      this.channelStore.upsert(this.curUser.id, {
        id: this.curUser.id || environment.localGenCustomerId,
        channelCode: this.curChannel.channelCode,
        channelId: this.curChannel.channelId,
        channelTag: this.curChannel.channelTag,
        orderType: this.curChannel.orderType,
        data: newCurChannel.data
      });
    }
  }

  getSwitchChannel() {
    return this.channelInfo$.asObservable();
  }

  triggerSwitchChannel(channelId: string, channelTag: string) {
    this.channelInfo$.next({ channelId, channelTag });
  }

  async switchChannelCheck() {
    return await this.switchChannel(this.isSubDomain ? this.subdomainChannelTag : this.parseURL[3]);
  }

  async getLocationChannel(longitude: number, latitude: number) {
    await this.getLocationAddress(longitude, latitude).then(async address => {
      if (address instanceof HttpErrorResponse === false) {
        let addressPlaceDetail = await this.getPlaceDetail(address.addressId, address.type);
        if (addressPlaceDetail instanceof HttpErrorResponse === false) {
          this.selectedAddress = addressPlaceDetail;
          this.userService.updateSelectedAddress(addressPlaceDetail);
          await this.getChannel({ ...this.channelReq, longitude: addressPlaceDetail.longitude, latitude: addressPlaceDetail.latitude });
        }
      } else {
        await this.getChannel({ ...this.channelReq, longitude: 0, latitude: 0 });
      }
    });
  }

  async selectAddress(addressId: string, type: string) {
    this.getPlaceDetail(addressId, type).then(data => {
      if (data instanceof HttpErrorResponse === false) {
        this.selectedAddress = data;
        this.userService.updateSelectedAddress(data);
      }
    });
  }

  recentAddressCheck(channelData: Channel) {
    if (this.initialChannelTag && this.initialChannelTag !== channelData.channelTag) {
      localStorage.removeItem('recent-address');
    }
  }

  async filterAttrVerify() {
    let filterItem: FilterItem = JSON.parse(this.sessionStorageService.getItem('filter-item'));
    if (filterItem) {
      this.channelReq = {
        ...this.channelReq,
        storeAttrs: filterItem?.storeAttrs ? filterItem.storeAttrs : undefined,
        minPrice: filterItem?.minPrice ? filterItem.minPrice : undefined,
        maxPrice: filterItem?.maxPrice ? filterItem.maxPrice : undefined,
        rate: filterItem?.rate ? filterItem.rate : undefined
      }
    } else {
      this.channelReq = {
        ...this.channelReq,
        storeAttrs: undefined,
        minPrice: undefined,
        maxPrice: undefined,
        rate: undefined,
      }
    }
  }

  getPlatformSetValue(setCode: SetCode) {
    return this.curChannel.data.platformSets.find(pFormSet => pFormSet.setCode === setCode);
  }

  setCurrentOrderType(orderType : string){
    this.currentOrderType = orderType;
  }

  getCurrentOrderType(){
    return this.currentOrderType ? this.currentOrderType : OrderTypeFlag.All;
  }

  async getOrderTypeCount(data) : Promise<number> {
    if(!data){
      return 0;
    }

    let orderTypeCount: number = 0;

    if (data.deliveryFlag) {
      orderTypeCount++;
    }

    if (data.pickupFlag) {
      orderTypeCount++;
    }

    if (data.dineFlag && environment.enableDineIn) {
      orderTypeCount++;
    }

    return orderTypeCount;
  }

  async getOrderTypeWhenOnlyOneType(channelData){
    let currentOrderType : string = OrderTypeFlag.All;

    if (channelData.deliveryFlag) {
      currentOrderType = OrderTypeFlag.Delivery;
    }

    if (channelData.pickupFlag) {
      currentOrderType = OrderTypeFlag.Pickup;
    }

    if (channelData.dineFlag && environment.enableDineIn) {
      currentOrderType = OrderTypeFlag.DineIn;
    }

    return currentOrderType;
  }

  setSwitchChannelData(channelData : ChannelCustomerResponse){
    this.switchChannelData = channelData;
  }

  getSwitchChannelData(){
    return this.switchChannelData ? this.switchChannelData : null;
  }
}
