import { QrCartService } from './../../../core/qr-cart/qr-cart.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, HostListener, Inject, PLATFORM_ID  } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserService } from 'src/app/core/user/user.service';
import { PageName, StoreMode } from 'src/app/core/enums';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OtpService } from 'src/app/account/services/otp.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { PrimeNGConfig } from 'primeng/api';
import { ConfigService } from 'src/app/config.service';
import { UserChannel } from 'src/app/home/userchnl/userchnl.model';
import { ChannelData } from 'src/app/core/models';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { ChannelCustomerStatusFlag } from 'src/app/core/enums/StatusFlag';
import { Customer } from '../productS';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { ChannelCustomerResponse } from 'src/app/core/models/ChannelCustomerResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { UserChnlService } from 'src/app/home/userchnl/userchnl.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';

import { ElementRef, ViewChild } from '@angular/core';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsEvent } from 'src/app/core/enums/AnalyticsEvent';
import { TooltipService } from 'src/app/core/services/tooltip.service';
import { StoreService } from 'src/app/store/store/store.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { QrScannerService } from 'src/app/core/services/qr-scanner.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { SetCode } from 'src/app/core/enums/SetCode';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {

  @Output() onChangeChannel = new EventEmitter();
  @Input() curOrderType = OrderTypeFlag.All;
  @Output() onOrderTypeChanged = new EventEmitter();
  @Output() onLocation = new EventEmitter();
  @Output() onOrderTypeLocation = new EventEmitter();
  @Input() curRouter: string;
  @Input() showMobileHeader: boolean;

  menuSidebar!: boolean;
  curUser: any;
  sub: Subscription = new Subscription();
  telInpSetting = DefaultSettingService.telInpSetting;
  fgroup: UntypedFormGroup = this.fb.group({
    mobileNo: ['', Validators.required],
    switchChannel: ['', Validators.required],
  });
  orderTypes = Object.entries(OrderTypeFlag);
  curOrderTypeInd = 0;

  show = false;
  displayChannel!: boolean;
  userchnlList: UserChannel[] = [];
  isUserLogin: boolean = false;
  isDeleteMode: boolean = false;
  rowGroupMetadata: any;
  customers!: Customer[];
  switchChannelError: HttpErrorResponse = null;
  selectedChnlStatus: string;
  deleteChnlData: ChannelCustomerResponse;
  isDefaultDomain: boolean = false;
  selectedChannel: ChannelData = undefined;
  odaringChannel = environment.odaringChannel;
  errorCode = ErrorCode;
  displayAddressTooltip : boolean;

  //address display checking
  currentOrderType : string;
  orderTypeFlag = OrderTypeFlag;
  storeSub : Subscription;

  //sticky
  @ViewChild("header") headerEl: ElementRef;
  @ViewChild("headerMobile") headerMobileEl: ElementRef;
  isSticky: boolean = false;
  isMobileSticky: boolean = false;
  allowStick: boolean = false;

  public localStorage = localStorage;
  isSubChannel: boolean = false;
  switchChannelNotFound: boolean = false;
  userChnlSub: Subscription = new Subscription();

  //show current orderType and location for mobile view
  orderTypeDesc : string = '';
  orderTypeicon: string = '';
  orderType : string;
  fulladdress: string;

  channelSub$ : Subscription;
  shareTable : boolean = false;
  tblNoLbl : string;
  staticTableNo : string;
  staticQrSub$ : Subscription;

  isBlockBack: any;
  channelId : number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private otpService: OtpService,
    private fb: UntypedFormBuilder,
    private primengConfig: PrimeNGConfig,
    private configService: ConfigService,
    private userchnlQry: UserChnlQuery,
    private channelService: ChannelService,
    private userChnlService: UserChnlService,
    private channelQuery: ChannelQuery,
    private analyticsService: AnalyticsService,
    private tooltipService : TooltipService,
    private storeService : StoreService,
    private userchnService: UserChnlService,
    private routingService: RoutingService,
    private qrScannerService: QrScannerService,
    private qrCartService : QrCartService,
    private staticQrService : StaticQrService,
    private userQuery: UserQuery,
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit() {
    this.curOrderTypeInd = Object.entries(OrderTypeFlag).findIndex(dd => dd[1] === this.curOrderType);
    this.sub = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.curUser = userData ? userData : null;
      this.isUserLogin = userData ? true : false;
      this.setFormControlValue("mobileNo", this.curUser?.mobileNo || '');
    });

    this.userchnlQry.selectAll().subscribe((obs) => {
      if (obs.length > 0) {
        this.userchnlList = _.cloneDeep(obs);
      } else {
        this.userchnlList = [
          { id: 1, status: ChannelCustomerStatusFlag.Active, data: [] }
        ];
      }
    });

    this.f.switchChannel.statusChanges.subscribe(formStatus => {
      if (formStatus === 'INVALID') {
        this.switchChannelError = null;
        this.switchChannelNotFound = false;
      }
    });

    this.channelQuery.currentChannelData$.subscribe((data) => {
      this.selectedChannel = data;
      if (data) {
        this.channelId = data.channelId;
        this.isSubChannel = data.channelTag === environment.odaringChannel ? false : true;
      }
    });

    this.userchnlQry.isSubDomain$.subscribe((data) => {
      this.isDefaultDomain = !data;
    });

    this.channelService.getSwitchChannel().subscribe((data) => {
      this.switchChannelByTag(data.channelTag, data.channelId);
    });

    if (isPlatformBrowser(this.platformId)) {
      this.tooltipService.showTooltip$.subscribe(val => {
        if(val){
          this.displayAddressTooltip = val;
          document.body.style.overflowY = "hidden";
        }
        else{
          this.displayAddressTooltip = val;
          document.body.style.overflowY = "scroll";
        }
      })
    }

    this.storeSub = this.storeService.headerAddressDisplay$.subscribe(orderType => {
      this.currentOrderType = orderType;
    })

    this.createFormValidation();
    this.primengConfig.ripple = true;
    this.selectedChnlStatus = ChannelCustomerStatusFlag.Active;

    // Sticky header
    this.allowStick = this.router.url === '/home' || this.router.url.includes('/promotion-details-page') || this.router.url.includes('search-result');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.allowStick = event.url === '/home' || this.router.url.includes('/promotion-details-page') || this.router.url.includes('search-result');
    });

    this.channelSub$ = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      if (channel) {
        let channelSetting = channel?.data?.platformSets ? channel?.data?.platformSets : [];
        let shareTableSetting = channelSetting.find(setting => setting.setCode === SetCode.SHARETABLE)?.setValue;
        this.shareTable = shareTableSetting && shareTableSetting == '1' ? true : false;

        let reqTblNoLblSetting = channelSetting.find(setting => setting.setCode === SetCode.DITBLNOLBL)?.setValue;
        this.tblNoLbl = reqTblNoLblSetting ? reqTblNoLblSetting : '';

        // get order type info such as order type and order type icon
        let orderTypeInfo = await this.getOrderTypeInfo(channel.orderType.toLocaleLowerCase());
        this.orderTypeDesc = orderTypeInfo.orderType;
        this.orderTypeicon = orderTypeInfo.orderTypeicon;
        this.orderType = channel.orderType ? channel.orderType : OrderTypeFlag.All;
        const orderTypeCount = await this.getOrderTypeCount(channel.data);

        // if order type count == 1, then will run below logic to overwrite current order type and its icon
        if (orderTypeCount == 1) {
          if (channel.data.deliveryFlag) {
            this.orderTypeDesc = 'order.type.select.delivery';
            this.orderTypeicon = 'oda-delivery';
            this.orderType == OrderTypeFlag.Delivery;
          }

          if (channel.data.pickupFlag) {
            this.orderTypeDesc = 'order.type.select.pick.up';
            this.orderTypeicon = 'oda-bag';
            this.orderType = OrderTypeFlag.Pickup;
          }

          if (channel.data.dineFlag && environment.enableDineIn) {
            this.orderTypeDesc = 'order.type.select.dine.in';
            this.orderTypeicon = 'oda-dinein';
            this.orderType = OrderTypeFlag.DineIn;
          }
        }
      }
    });

    this.staticQrSub$ = this.staticQrService.getStaticQrData().subscribe(staticQrData => {
      this.staticTableNo = staticQrData.tableNo ? staticQrData.tableNo : null;
    });

    //curent selected address
    this.userQuery.selectedAddress$.subscribe(address => {
      if(address) {
        this.fulladdress = address.fullAddress;
      }
    });

    this.isBlockBack = this.sessionStorageService.getItem('isBlockBack');

  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.storeSub?.unsubscribe();
    this.userChnlSub?.unsubscribe();
    this.channelSub$?.unsubscribe();
    this.staticQrSub$?.unsubscribe();
  }

  async getOrderTypeCount(data): Promise<number> {
    let orderTypeCount: number = 0;

    if (data.deliveryFlag) {
      orderTypeCount++;
    }

    if (data.pickupFlag) {
      orderTypeCount++;
    }

    if (data.dineFlag && environment.enableDineIn) {
      orderTypeCount++;
    }

    return orderTypeCount;
  }

  async getOrderTypeInfo(channelOrderType : string){
    let orderTypeInfo = {} as any;

    if (channelOrderType == OrderTypeFlag.Delivery.toLocaleLowerCase()) {
      orderTypeInfo = {
        orderType : 'order.type.select.delivery',
        orderTypeicon : 'oda-delivery'
      }
    }
    else if (channelOrderType == OrderTypeFlag.Pickup.toLocaleLowerCase()) {
      orderTypeInfo = {
        orderType : 'order.type.select.pick.up',
        orderTypeicon : 'oda-bag'
      }
    }
    else if (channelOrderType == OrderTypeFlag.DineIn.toLocaleLowerCase()) {
      orderTypeInfo = {
        orderType : 'order.type.select.dine.in',
        orderTypeicon : 'oda-dinein'
      }
    }
    else {
      orderTypeInfo = {
        orderType : 'order.type.select.all.order.type',
        orderTypeicon : 'duotone oda-d-logo'
      }
    }

    return orderTypeInfo;
  }

  onLogin() {
    this.router.navigate(["/login"]);
  }

  onNavigate(dt: string) {
    const originCountryUrl = this.isDefaultDomain ? (window.location.origin + this.configService.get('appBaseHref')) : window.location.origin;
    switch (dt) {
      case '': {
        this.router.navigateByUrl("/home/profile");
        break;
      }
      case 'MyOrder': {
        this.router.navigateByUrl("/home/profile/(otl-profile:my-odr)", { skipLocationChange: true });
        break;
      }
      case 'Rewards': {
        this.router.navigateByUrl("/home/profile/(otl-profile:rewards)", { skipLocationChange: true });
        break;
      }
      case 'RefFrd': {
        this.router.navigateByUrl("/home/profile/(otl-profile:ref-frd)", { skipLocationChange: true });
        break;
      }
      case 'Notis': {
        this.router.navigateByUrl("/home/profile/(otl-profile:notis)", { skipLocationChange: true });
        break;
      }
      default: {
        this.router.navigateByUrl("/");
        break;
      }
      case 'email': {
        let mailText = "mailto:customerservice@odaring.com?subject=Order Issue";
        window.location.href = mailText;
        break;
      }
      case 'aboutUs': {
        window.open("https://www.evolftech.com/odaring/", "_blank");
        break;
      }
      case 'beComeAPartner': {
        window.open("https://www.evolftech.com/odaring/merchant-partner/", "_blank");
        break;
      }
      case 'faq': {
        window.open(originCountryUrl + '/faq', '_blank');
        break;
      }
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tNc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
    }
  }

  onProfile() {
    this.router.navigate(["profile"], { relativeTo: this.activatedRoute });
  }

  onClickLocation() {
    this.tooltipService.close();
    this.onLocation.emit(true);
  }

  onClickOrderTypeLocation() {
    this.onOrderTypeLocation.emit(true);
  }

  createFormValidation() {
    this.getFormControl("mobileNo").disable();
  }

  // using
  onOrderTypeChangedFunc(selectedOrderType: any) {
    this.curOrderType = selectedOrderType;
    this.onOrderTypeChanged.emit(selectedOrderType);
  }

  onSwitchChannel(channelTag: string) {
    this.onChangeChannel.emit(channelTag);
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }


  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  private getFormControlValue(formControlName: string) {
    return this.getFormControl(formControlName).value;
  }

  private setFormControlValue(formControlName: string, val: string) {
    return this.getFormControl(formControlName).setValue(val);
  }

  clickLogo() {
    let qrTokenResponse = this.qrCartService.get().qrTokenResponse;
    let channelData = this.channelService.getChannelData();
    let staticData = this.staticQrService.get();

    // if dynamic qr dine in mode OR static data exists and channel tag not equals to channel's home channel tag
    // else use navigation service that change channel
    if((qrTokenResponse) || (staticData && staticData?.tableNo && channelData && channelData?.homeChannelTag && channelData?.channelTag != channelData?.homeChannelTag)){
      this.router.navigate(["home"]);
    }
    else{
      this.routingService.navigate(PageName.HomePage, this.selectedChannel);
    }
  }

  onSort() {
    this.updateRowGroupMetaData();
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.customers) {
      for (let i = 0; i < this.customers.length; i++) {
        let rowData = this.customers[i] as any;
        let representativeName = rowData.representative.name;

        if (i == 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.customers[i - 1] as any;
          let previousRowGroup = previousRowData.representative.name;
          if (representativeName === previousRowGroup)
            this.rowGroupMetadata[representativeName].size++;
          else
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
        }
      }
    }
  }

  getCustomerChannelStatus(channelStatus: string): string {
    if (channelStatus === ChannelCustomerStatusFlag.Active) {
      return 'toolbar.customer.channel.status.recent';
    } else if (channelStatus === ChannelCustomerStatusFlag.PendingApproval) {
      return 'toolbar.customer.channel.status.pending';
    } else if (channelStatus === ChannelCustomerStatusFlag.Rejected) {
      return 'toolbar.customer.channel.status.rejected';
    } else if (channelStatus === ChannelCustomerStatusFlag.Quit) {
      return 'toolbar.customer.channel.status.quit';
    } else if (channelStatus === ChannelCustomerStatusFlag.BlockedExpired) {
      return 'toolbar.customer.channel.status.blockedExpired';
    } else {
      return '';
    }
  }

  onSwitchChnlStatus(status: string) {
    this.selectedChnlStatus = status;
  }

  async switchChannelByTag(channelTag?: string, channelId?: string) {
    if (!channelTag) {
      channelTag = "MY";
      this.setFormControlValue("switchChannel", channelTag);
    }
    const result = await this.channelService.switchChannel(channelTag, channelId);
    if (result instanceof HttpErrorResponse) {
      this.showSwitchChannelError(result);
      if (this.switchChannelError.error.errorCode === this.errorCode.PendingApprovalChannelAccess_403) {
        this.analyticsService.logEvents(AnalyticsEvent.c_request_channel_event, {
          c_channel_id: channelId,
          c_channel_tag: channelTag,
        });
      }
    } else {
      if (result.status === ChannelCustomerStatusFlag.Hidden) {
        this.showSwitchChannelError(result);
      } else {
        this.analyticsService.logEvents(AnalyticsEvent.c_switch_channel_event, {
          c_channel_id: result.channelId,
          c_channel_tag: result.channelTag,
        });

        this.routingService.navigateChannelUrl(channelTag, 'home');
      }
    }
  }

  showSwitchChannelError(result: any) {
    this.resetErrorMessage();
    this.displayChannel = true;
    if (result instanceof HttpErrorResponse) {
      this.switchChannelError = result;
    } else {
      this.switchChannelNotFound = true;
    }
  }

  async switchChannel(channelId?: string, channelTag?: string) {
    await this.switchChannelByTag(channelTag, channelId);
  }

  async deleteChannel(channel: ChannelCustomerResponse) {
    if (channel.channelTag) {
      let result = await this.userChnlService.deleteCustomerChannel(channel.channelTag);
      if (!(result instanceof HttpErrorResponse)) {
        this.analyticsService.logEvents(AnalyticsEvent.c_delete_channel_event, {
          c_channel_id: channel.channelId,
          c_channel_tag: channel.channelTag,
        });
        this.successChannelApiCall();
      }
    }
  }

  successChannelApiCall() {
    this.userChnlService.getCustomerChannel();
    this.resetErrorMessage();
  }

  resetErrorMessage() {
    this.switchChannelError = null;
    this.switchChannelNotFound = false;
  }

  onSubmit() {
    this.switchChannelByTag(this.getFormControlValue("switchChannel"));
  }

  showChannel() {
    this.setFormControlValue("switchChannel", '');
    this.getFormControl("switchChannel").setErrors(null);
    if (this.isUserLogin) {
      this.userchnService.getCustomerChannel();
    }
    this.displayChannel = true;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.allowStick && this.headerEl !== undefined) {
      let headerPosition = this.headerEl.nativeElement.offsetTop;
      this.isSticky = window.pageYOffset >= headerPosition;
    } else {
      this.isSticky = false;
    }

    if (this.allowStick && this.headerMobileEl !== undefined) {
      let headerMobilePosition = this.headerMobileEl.nativeElement.offsetTop;
      this.isMobileSticky = window.pageYOffset >= headerMobilePosition;
    } else {
      this.isMobileSticky = false;
    }
  }

  showScannerDialog() {
    this.qrScannerService.show(true, false);
  }
}
