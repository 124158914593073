<div class="toast-container bottom-center"
  id="toast-container"
  [attr.data-name]="toastData?.name"
  [style.visibility]="toastState == 'show'? 'visible' : 'hidden'"
  [style.bottom]="toastData && toastData?.bottomValue ? toastData.bottomValue : 0">
  <div class="toast" [ngStyle]="{'background-color': toastData?.backgroundColorCode ? toastData.backgroundColorCode : '#000000'}"
    id="toast" [@toast]="toastState" (@toast.done)="animationDone($event)">
    <div class="message-container">
      <i class="{{toastData?.icon}} message-icon" [style.color]="iconColor" *ngIf="toastData && !toastData?.showCircleIcon && toastData?.icon"></i>
      <span class="message-circle-container" *ngIf="toastData && toastData?.showCircleIcon && toastData?.icon">
        <i class="{{toastData?.icon}}" [style.color]="iconColor"></i>
      </span>
      <span class="toast-message" *ngIf="!toastData?.messageData">{{toastData?.message | translate}}</span>
      <span class="toast-message" *ngIf="toastData?.messageData">{{toastData?.message | translate: toastData.messageData}}</span>
    </div>
    <i [hidden]="toastData?.hideCloseBtn" class="oda-times" (click)="closeToast()"></i>
  </div>
</div>




