import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CommonAddressResponse } from '../../../core/models/CommonAddressResponse';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { ErrorLocation } from 'src/app/core/enums/ErrorLocation';
import { UserQuery } from 'src/app/core/user/user.query';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/core/user/user.service';
import { AddressService } from '../../services/address.service';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-address-search',
  templateUrl: './address-search.component.html',
  styleUrls: ['./address-search.component.scss']
})
export class AddressSearchComponent implements OnInit, AfterViewInit {

  @ViewChild("addressSearch", { static: false }) addressSearchElementRef: ElementRef | any;
  @Input() set searchValue(address : string){
    this.addressSearchValue = address;
  };

  get searchValue(){
    return this.addressSearchValue;
  }
  @Input() selectedChannelTag: string;
  @Input() selectedChannelId: number;
  @Input() isAddNewAddress: boolean;
  @Input() isAddNewAddressDialog: boolean;
  @Input() events: Observable<void>;
  @Output() outputSelectedAddress = new EventEmitter<CommonAddressResponse>();
  @Output() errorLocationCode = new EventEmitter<number>();

  modelChanged: Subject<string> = new Subject<string>();
  placeList: CommonAddressResponse[] = [];
  displayPlaceLayout: boolean = false;
  addressSearchValue: string = '';
  locationNotFound: boolean = false;
  selectedAddress: CommonAddressResponse;
  addressSearchError: HttpErrorResponse = null;
  resetAddressSubscription$: Subscription = new Subscription();
  addressSub$: Subscription = new Subscription();
  stopDebounce : boolean = false;

  constructor(
    private channelService: ChannelService,
    private userQuery: UserQuery,
    private userService: UserService,
    private addressService: AddressService,
    private sessionStorageService: SessionStorageService
  ) {
    this.modelChanged.pipe(
      debounceTime(1000))
      .subscribe(async searchText => {
        if(!this.stopDebounce){
          await this.getPlace(searchText);
        }
      });
  }

  ngOnInit(): void {
    if (!this.isAddNewAddress) {
      this.addressSub$ = this.userQuery.selectedAddress$.subscribe(address => {
        if (address) {
          this.selectedAddress = address;
          if (!this.searchValue) {
            this.addressSearchValue = address.fullAddress;
          }
        } else {
          this.clearAddressField();
        }
      });
    }

    if (this.events) {
      this.resetAddressSubscription$ = this.events.subscribe(() => this.removeAddress());
    }
  }

  changed(text: string) {
    this.stopDebounce = false;
    this.modelChanged.next(text);
  }

  selectAddress(addressId: string, type: string) {
    localStorage.setItem('recent-address', JSON.stringify(this.selectedAddress));

    this.channelService.getPlaceDetail(addressId, type).then(data => {
      if (data instanceof HttpErrorResponse === false) {
        this.displayPlaceLayout = false;
        this.addressSearchValue = data.fullAddress;
        this.outputSelectedAddress.emit(data);
        this.errorLocationCode.emit(ErrorLocation.NONE);
        this.userService.setRecentAddress();
      }
    }).catch(error => {
      console.log(error)
    });
  }

  searchCurrentLocation() {
    if (navigator.geolocation) {
      this.addressService.getUserGpsLocation()
        .then((location) => {
          this.channelService.getLocationAddress(location.longitude, location.latitude, this.selectedChannelId).then(address => {
            if (address) {
              this.selectAddress(address.addressId, address.type);
            }
          }).catch(error => {
            console.log(error)
            this.locationNotFound = false;
            this.errorLocationCode.emit(ErrorLocation.NOT_AVAILABLE);
          });
        }).catch(error => {
          console.log(error)
          this.locationNotFound = false;
          this.errorLocationCode.emit(ErrorLocation.BLOCK_GPS);
        });
    }
  }

  removeAddress() {
    this.clearAddressField();
    if (!this.isAddNewAddress && !this.isAddNewAddressDialog) {
      this.addressSearchValue = '';
    }

    this.errorLocationCode.emit(ErrorLocation.EMPTY_ADDRESS);
  }

  clearAddressField() {
    this.addressSearchValue = '';
    this.displayPlaceLayout = false;
    this.placeList = [];
    this.locationNotFound = false;
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.resetAddressSubscription$?.unsubscribe();
    this.addressSub$?.unsubscribe()
  }

  async getPlace(searchText : string){
    if (searchText) {
      let channelTag = this.selectedChannelTag ? this.selectedChannelTag : this.channelService.getChannelData()?.channelTag;
      this.channelService.getPlaceAutoComplete(channelTag, searchText).then(data => {
        if (data instanceof HttpErrorResponse === false) {
          this.placeList = data;
          if (this.placeList.length === 0) {
            this.displayPlaceLayout = false;
            this.locationNotFound = true;
          } else {
            this.displayPlaceLayout = true;
            this.locationNotFound = false;
          }
        }
      }).catch(error => {
        this.placeList = [];
        this.displayPlaceLayout = false;
        this.addressSearchError = error;
      });
      this.errorLocationCode.emit(ErrorLocation.NONE);
    } else {
      this.placeList = [];
      this.displayPlaceLayout = false;
      this.locationNotFound = false;
      this.errorLocationCode.emit(ErrorLocation.EMPTY_ADDRESS);
    }
  }

  async addressInputSubmit(){
    try{
      this.stopDebounce = true;
      await this.getPlace(this.addressSearchValue);
    }catch(error){
      this.stopDebounce = false;
    }
  }
}
