import { Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-quit-member-term',
  templateUrl: './quit-member-term.component.html',
  styleUrls: ['./quit-member-term.component.scss']
})
export class QuitMemberTermComponent implements OnInit{
  @Output() onHide = new EventEmitter();
  @Output() onContinue = new EventEmitter();

  showLoading = false;
  src: SafeResourceUrl;

  isMobile: boolean = false;
  isAgree: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
  ) {}

  async ngOnInit(){
    this.isMobile = window.innerWidth <= 991 ? true: false;

    this.showLoading = true;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.evolftech.com/odaring/quit-membership/');
    console.log('src: ', this.src)
  }

  onAgree(event: any) {
    this.isAgree = event.target.checked? true: false;
  }

  onClickContinue() {
    this.onContinue.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let isMobile = window.innerWidth <= 991 ? true: false;

    if(isMobile !== this.isMobile) {
      this.onHide.emit();
    }
  }

  onLoad() {
    this.showLoading = false;
  }

}
