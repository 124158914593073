import { HomeResolver } from './core/resolvers/home.resolver';
import { StoreGuard } from './core/guard/store.guard';
import { SubmittingOrderComponent } from './shared/components/submitting-order/submitting-order.component';
import { QuitQrGuardGuard } from './core/guard/quit-qr-guard.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './account/login/login.component';
import { LoadingComponent } from './account/loading/loading.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { OtpComponent } from './account/otp/otp.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { OtpCanActivate } from './account/resolvers/otp.resolver';

import { MyOrderComponent } from './account/my-order/my-order.component';
import { LandingPagesComponent } from './shared/components/landing-pages/landing-pages.component'

import { ReturnRefundEmailComponent } from './home/payment-summary-order/return-refund/return-refund-email/return-refund-email.component'
import { ReturnRefundComponent } from './home/payment-summary-order/return-refund/return-refund.component'
import { HomeGuardGuard } from './core/guard/home-guard.guard';
import { RefundEmailGuardGuard } from './core/guard/refund-email-guard.guard';
import { RefundGuardGuard } from './core/guard/refund-guard.guard';
import { PaymentSuccessComponent } from './payment/payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment/payment-failed/payment-failed.component';
import { LostNetworkConnectionComponent } from './shared/components/lost-network-connection/lost-network-connection.component';
import { TechnicalErrorComponent } from './shared/components/technical-error/technical-error.component'
import { AccountComponent } from 'src/app/account/account.component';
import { ManageAddressComponent } from 'src/app/account/manage-address/manage-address.component';
import { EditProfileComponent } from "src/app/account/edit-profile/edit-profile.component";
import {MyFavStoreComponent} from 'src/app/account/my-fav-store/my-fav-store.component'
import { CardDetailComponent } from 'src/app/account/card-Detail/card-detail.component';
import { RewardsComponent } from './account/rewards/rewards.component';

import { OrderDetailGuardGuard } from './core/guard/order-detail-guard.guard';
import { OrderSummaryGuardGuard } from './core/guard/order-summary-guard.guard';
import { LoginGuardGuard } from './core/guard/login-guard.guard';

import { FaqComponent } from 'src/app/shared/components/faq/faq.component';
import { PrivacyPolicyComponent } from 'src/app/shared/components/privacy-policy/privacy-policy.component';
import { TermNconditionsComponent } from 'src/app/shared/components/term-nconditions/term-nconditions.component';
import { StoreNotFoundErrorComponent } from './shared/components/store-not-found-error/store-not-found-error.component';
import { ProfileNavMobileComponent } from './shared/components/profile-nav-mobile/profile-nav-mobile.component';
import { ResetPasswordResultComponent } from './account/reset-password-result/reset-password-result.component';
import { MascotLoadingComponent } from './shared/components/mascot-loading/mascot-loading.component';
import { InvalidQrComponent } from './shared/components/invalid-qr/invalid-qr.component';
import { RewardHistoryComponent } from './account/rewards/reward-history/reward-history/reward-history.component';
import { SelectVoucherComponent } from './account/rewards/select-voucher/select-voucher.component';
import { VerifyEmailComponent } from './account/verify-email/verify-email.component';
import { VerifyEmailFailedComponent } from './account/verify-email-failed/verify-email-failed.component';
import { VerifyEmailSuccessComponent } from './account/verify-email-success/verify-email-success.component';
import { VerifyEmailExpiredComponent } from './account/verify-email-expired/verify-email-expired.component';
import { VerifyLinkSentComponent } from './account/verify-link-sent/verify-link-sent.component';
import { VerifyEmailGuard } from './core/guard/verify-email.guard';
import { CompleteProfileComponent } from './account/complete-profile/complete-profile.component';
import { ForgotPasswordGuard } from './core/guard/forgot-password.guard';
import { SettingsComponent } from './account/settings/settings/settings.component';
import { DeleteAccSuccessComponent } from './shared/components/delete-acc-success/delete-acc-success/delete-acc-success.component';
import { QrMobileDetectedComponent } from './shared/components/qr-mobile-detected/qr-mobile-detected.component';
import { NewsComponent } from 'src/app/shared/components/news/news.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ChannelNotFoundComponent } from './shared/components/channel-not-found/channel-not-found.component';
import { MaintenanceComponent } from './shared/components/maintenance/maintenance.component';
import { PasswordLoginComponent } from './account/password-login/password-login/password-login.component';
import { SetupPasswordComponent } from './account/setup-password/setup-password.component';
import { AuthGuard } from './core/guard/auth.guard';
import { SearchResultComponent } from './home/search-result/search-result.component';
import { QueueDataCheckGuard } from './core/guard/queue-data-check.guard';
import { GuestInfoCheckGuard } from './core/guard/guest-info-check.guard';
import { NewsDetailComponent } from './shared/components/news-detail/news-detail.component';
import { QuitStoreGuard } from './core/guard/quit-store.guard';
import { DynamicQrGuard } from './core/guard/dynamic-qr.guard';
import { FullPagePromotionDetailComponent } from 'src/app/account/rewards/full-page-promotion-detail/full-page-promotion-detail.component'
import { UniversalGuard } from './core/guard/universal.guard';
import { StoreResolver } from './account/resolvers/store.resolver';

const routes: Routes = [
  {
    path: '', component: LandingPagesComponent,
    data: { showHeader: false, showFooter: true },
    canActivate: [HomeGuardGuard, QueueDataCheckGuard]
  },
  {
    path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    data: { showHeader: true, showFooter: true },
    resolve: {
      channelData : HomeResolver
    },
    canActivate: [QuitQrGuardGuard, QueueDataCheckGuard, UniversalGuard]
  },
  {
    path: 'cart', loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
    data: { showHeader: true, showFooter: false },
    canActivate: [UniversalGuard]
  },
  {
    path: 'account', loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    data: { showHeader: true, showFooter: true },
    canActivate: [QuitQrGuardGuard, AuthGuard, QueueDataCheckGuard, UniversalGuard]
  },
  {
    path: 'store/:storeId/:storeTag', loadChildren: () => import('./store/store.module').then((m) => m.StoreModule),
    data: { showHeader: true, showFooter: true },
    resolve: {
      channelData: StoreResolver
    },
    canActivate: [StoreGuard, UniversalGuard],
    canDeactivate: [QuitStoreGuard]
  },
  {
    path: 'store/:storeId', loadChildren: () => import('./store/store.module').then((m) => m.StoreModule),
    data: { showHeader: true, showFooter: true },
    resolve: {
      channelData: StoreResolver
    },
    canActivate: [StoreGuard, UniversalGuard],
    canDeactivate: [QuitStoreGuard]
  },
  {
    path: 'order-payment', loadChildren: () => import('./order-payment/order-payment.module').then(m => m.OrderPaymentModule),
    data: { showHeader: true, showFooter: true },
    canActivate: [UniversalGuard]
  },
  {
    path: 'order-summary', loadChildren: () => import('./order-summary/order-summary.module').then(m => m.OrderSummaryModule),
    data: { showHeader: true, showFooter: false },
    canActivate: [OrderSummaryGuardGuard, UniversalGuard]
  },
  {
    path: 'order-detail', loadChildren: () => import('./order-summary/order-summary.module').then(m => m.OrderSummaryModule),
    data: { showHeader: true, showFooter: false },
    canActivate: [OrderDetailGuardGuard, UniversalGuard]
  },

  { path: 'payment-success', component: PaymentSuccessComponent },
  { path: 'payment-failed', component: PaymentFailedComponent },

  {
    path: 'login', component: LoginComponent,
    canActivate: [LoginGuardGuard, UniversalGuard]
  },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [ForgotPasswordGuard], data: { showHeader: false, showFooter: true } },
  { path: 'otp', component: OtpComponent, canActivate: [OtpCanActivate], data: { showHeader: false, showFooter: true } },
  { path: 'otp/login', component: OtpComponent, data: { showHeader: false, showFooter: true } },
  { path: 'otp/verify-phone-no', component: OtpComponent, data: { showHeader: false, showFooter: true } },
  {
    path: 'complete-profile',
    component: CompleteProfileComponent,
    data: { showHeader: false, showFooter: true },
    canActivate: [UniversalGuard]
  },
  { path: 'loading', component: LoadingComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-success', component: ResetPasswordResultComponent, data: { showHeader: false, showFooter: true, resetSuccess: true } },
  { path: 'reset-failed', component: ResetPasswordResultComponent, data: { showHeader: false, showFooter: true, resetSuccess: false } },
  {
    path: 'my-order', component: MyOrderComponent, data: { showHeader: true, showFooter: true }, children: [
      { path: '', pathMatch: 'full', redirectTo: 'active' },
      { path: 'active', component: MyOrderComponent },
      { path: 'history', component: MyOrderComponent },
    ],
    canActivate: [QuitQrGuardGuard, QueueDataCheckGuard, UniversalGuard]
  },
  {
    path: 'refund', component: ReturnRefundEmailComponent,
    data: { showHeader: true, showFooter: false },
    canActivate: [RefundEmailGuardGuard]
  },
  {
    path: 'refund-detail', component: ReturnRefundComponent,
    data: { showHeader: true, showFooter: true },
    canActivate: [RefundGuardGuard]
  },
  { path: 'lost-connection', component: LostNetworkConnectionComponent, data: { showHeader: true, showFooter: false } },
  { path: 'technical-error', component: TechnicalErrorComponent, data: { showHeader: true, showFooter: false } },
  { path: 'store-not-found', component: StoreNotFoundErrorComponent, data: { showHeader: true, showFooter: false } },
  {
    path: 'account-profile',
    component: AccountComponent,
    canActivate: [UniversalGuard]
  },
  {
    path: 'address',
    component: ManageAddressComponent,
    canActivate: [UniversalGuard]
  },
  {
    path: 'editProfile',
    component: EditProfileComponent,
    canActivate: [UniversalGuard]
  },
  {
    path: 'favourite-store', component: MyFavStoreComponent,
    data: { showHeader: true, showFooter: false },
    canActivate: [QuitQrGuardGuard, UniversalGuard]
  },
  {
    path: 'paymentMethod',
    component: CardDetailComponent,
    canActivate: [UniversalGuard]
  },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'term-conditions', component: TermNconditionsComponent },
  { path: 'profiles', component: ProfileNavMobileComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'deleted-account-success', component: DeleteAccSuccessComponent, data: { showHeader: true, showFooter: true } },
  { path: 'odaringqr/:tokenId', component: MascotLoadingComponent, canActivate: [DynamicQrGuard] },
  { path: 'app/:appId/:tokenId', component: MascotLoadingComponent, data: { isMiniProgram: true} },
  { path: 'invalid-qr', component: InvalidQrComponent, data: { showHeader: true, showFooter: false } },
  {
    path: 'reward', component: RewardsComponent, data: { showHeader: true, showFooter: true }, children: [
      { path: '', pathMatch: 'full', redirectTo: 'explore' },
      { path: 'explore', component: MyOrderComponent },
      { path: 'my-voucher', component: MyOrderComponent },
      { path: 'my-membership', component: RewardsComponent}
    ],
  },
  { path: 'select-voucher/:storeId', component: SelectVoucherComponent, data: { showHeader: true, showFooter: false }, canActivate: [UniversalGuard] },
  { path: 'voucher-history', component: RewardHistoryComponent, data: { showHeader: true, showFooter: false } },
  { path: 'verifyemail', component: VerifyEmailComponent, canActivate: [UniversalGuard] },
  { path: 'verifyemail-success', component: VerifyEmailSuccessComponent, canActivate: [VerifyEmailGuard] },
  { path: 'verifyemail-failed', component: VerifyEmailFailedComponent, canActivate: [VerifyEmailGuard] },
  { path: 'verifyemail-expired', component: VerifyEmailExpiredComponent, canActivate: [VerifyEmailGuard] },
  { path: 'verification-link-sent', component: VerifyLinkSentComponent, canActivate: [VerifyEmailGuard] },
  { path: 'mobile-detected', component:  QrMobileDetectedComponent, data: { showFooter: true }},
  { path: 'account-detected', component:  QrMobileDetectedComponent, data: { showFooter: true, isAccountDetected : true }, canActivate: [GuestInfoCheckGuard]},
  { path: 'are-you-interested', component: QrMobileDetectedComponent, data: { showFooter: true, isInterested : true } },
  { path: 'news', component: NewsComponent, data: { showHeader: true, showFooter: false }, canActivate: [UniversalGuard] },
  { path: 'news/:infoId/:infoDesc', component: NewsDetailComponent, data: { showHeader: true, showFooter: true }, canActivate: [UniversalGuard] },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'channel-not-found', component: ChannelNotFoundComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'setup-password', component: SetupPasswordComponent, data: { showHeader: false, showFooter: true } },
  { path: 'password-login', component: PasswordLoginComponent, data: { showHeader: false, showFooter: true } },
  { path: 'search-result', component: SearchResultComponent, data: { showHeader: true, showFooter: true } },
  { path: 'submitting-order', component: SubmittingOrderComponent, data: { showHeader: true, showFooter: false }},
  { path: 'queue', loadChildren: () => import('./queue/queue.module').then(m => m.QueueModule) },
  { path: 'membership/:membershipCode/:storeTag', loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule),
    data: { showHeader: true, showFooter: false },
    canActivate: [UniversalGuard]
  },
  { path: 'promotion-details-page/:vchTypId', component: FullPagePromotionDetailComponent, data: { showHeader: true, showFooter: false }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
