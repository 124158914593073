export enum VoucherCardType {
    store = "store",
    claim = "claim",
    claimed = "claimed",
    full = "full",
    use = "use",
    none = "none",
    used = "used",
    expired = "expired",
    multiSelect = "multiSelect",
    singleSelect = "singleSelect",
    membership = "membership"
}