import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicQrGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private channelService: ChannelService,
    private sessionStorageService: SessionStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let qrScanData = this.storageService.getIsQrScan();
    let channelData = this.channelService.getChannelData();
    let qrTokenResponse = JSON.parse(this.sessionStorageService.getItem("qrTokenResponse"));

    // use the channelData to determine it's first time load or not,
    // if not and isScan then not prevent user come back this route
    if (qrScanData && qrScanData.isScan && channelData) {
      history.pushState(null, null, qrTokenResponse);
      return false;
    } else {
      return true;
    }
  }

}
