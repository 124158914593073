import { ID } from '@datorama/akita';
import { UserLoginMode } from '../enums/UserLoginMode';
import { UserRole } from '../enums/UserRole';
import { components } from 'src/app/core/models/Schema';

export interface Claim {
  type: string;
  value: string;
}
export interface User {
  id?: ID;
  mobileNo?: string;
  name?: string,
  firstName?: string;
  lastName?: string;
  displayName?: string;
  password?: string;
  email?: string;
  address?: components['schemas']['Address'];
  dob?: string;
  genderCode?: string;
  referralCustID?:number;
  referralCode?: string;
  referralURL?: string;
  encryptedCustID?: string;
  customerId?: number;
  customerNo?: string;
  customerGuid?: string;
  idToken?: string;
  accessToken?: string;
  refreshToken?: string;
  notifyToken?: string;
  claims?: Claim[];
  otpCode?: string;
  otpStatus?: string;
  otpStatusMsg?: string;
  otpSuccess?: boolean;
  isMobileVerified?: boolean;
  isForceRegister?: boolean;
  isEmailVerified?: boolean;
  userLoginMode?: UserLoginMode;
  userRole?: UserRole;
  isAnonymous?: boolean;
}

/**
 * A factory function that creates User
 */
export function createUser(params: Partial<User>) {
  return {
    mobileNo: params.mobileNo,
    name: params.name,
    firstName: params.firstName,
    lastName: params.lastName,
    password: params.password,
    email: params.email,
    dob: params.dob,
    isMobileVerified: params.isMobileVerified,
    isForceRegister: params.isForceRegister,
    referralCode: params.referralCode,
    otpCode: params.otpCode
  } as User;
}

export function mapProfileReq(params: Partial<User>) {
  return {
    name: params.name,
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    dob: params.dob,
    gender: params.genderCode
  };
}

export function mapLoginResp(param: any) {
  return {
    idToken: param['id_token'],
    accessToken: param['access_token'],
    refreshToken: param['refresh_token'],
    claims: param['claims']
  };
}

export function mapUpdatePasswordResp(param: any) {
  return {
    notifyToken: param['notifyToken'],
    customerId: param['customerId'],
    customerGuid: param['customerGuid']
  };
}

export function mapProfileResp(param: any) {
  return {
    address: param['address'],
    customerId: param['customerId'],
    customerNo: param['customerNo'],
    displayName: param['displayName'],
    email: param['emailAddress'],
    isEmailVerified: param['isEmailVerified'],
    isMobileVerified: param['isMobileVerified'],
    mobileNo: param['mobileNumber'],
    referralCode: param['referralCode'],
    genderCode: param['genderCode'],
    dob: param['dob'],
    firstName: param['firstName'],
    lastName: param['lastName'],
    name: param['name'],
    encryptedCustID: param['encryptedCustID'],
    referralCustID: param['lastName'],
    referralURL: param['referralURL'],
  };
}

export function mapSendOTPResp(param: any) {
  return {
    otpSuccess: param['isSuccess'],
    mobileNo: param['mobileNumber'],
    otpStatus: param['status'],
    otpStatusMsg: param['statusMessage']
  };
}
