import { Pipe, PipeTransform } from '@angular/core';
import { ItemMenuTags } from 'src/app/core/models/ItemMenuTags';

@Pipe({
  name: 'formatInnerHtml'
})
export class FormatInnerHtmlPipe implements PipeTransform {

  transform(value: any, itemMenuTags?: ItemMenuTags[]): string {
    let formatTitle = '';
    if (itemMenuTags) {
      for (let index = 0; index < itemMenuTags.length; index++) {
        formatTitle += "<img class='menu-tag-img' src=" + itemMenuTags[index].imageUrl + ">";
      }
    }
    formatTitle += "<span class='menu-item-name'>" + value + " </span>";
    return formatTitle;
  }

}
