import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { MiniProgramCart } from "./mini-program.model";
import { MiniProgramState, MiniProgramStore } from "./mini-program.store";

@Injectable({
  providedIn: 'root'
})
export class MiniProgramQuery extends QueryEntity<MiniProgramState, MiniProgramCart> {

  appLinkResponse$ = this.select(state => state.appLinkTokenResponse);

  constructor(protected miniProgramStore: MiniProgramStore) {
    super(miniProgramStore);
  }

}
