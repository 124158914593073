import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from '../../services/toast.service';
import { toastAnimation } from './animation/ToastAnimation';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimation]
})
export class ToastComponent implements OnInit, OnDestroy{
  @Output() onClose= new EventEmitter();

  toastState : string = "bottom-center";

  toastData : ToastData;
  toastSub$ : Subscription;
  timeOutFunc : any;
  iconColor: string = "#FFFFFF";

  constructor(
    private toastService : ToastService
  ) { }

  ngOnInit(): void {
    this.toastSub$ = this.toastService.showToast.subscribe(toastData => {
      if(toastData){
        if(this.toastState == 'show'){
          this.closeToast();
          this.showToast(toastData);
        }
        else{
          // if(this.toastData == "bottom-center")
          this.showToast(toastData);
        }
      }
      else{
        this.toastState = "bottom-center";
      }
    })
  }

  ngOnDestroy(): void {
    this.toastSub$?.unsubscribe();
  }

  animationDone($event){
    if($event.fromState == 'show' && $event.toState == "bottom-center"){
      this.toastData = null;
      this.onClose.emit();
    }
  }

  closeToast(){
    this.toastState = "bottom-center";
    clearTimeout(this.timeOutFunc);
  }

  showToast(toastData : ToastData){
    this.toastData = toastData;
    this.toastState = 'show';
    let timeToClose = toastData.timeToClose ? toastData.timeToClose : 3000;
    this.iconColor = toastData.iconColor;

    this.timeOutFunc = setTimeout(() => {
      this.toastState = "bottom-center";
    }, timeToClose);
  }

}
