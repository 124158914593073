<div class="mascot-container" *ngIf="channelId | isShowMascot">
  <img src="assets/mascot/Mascot_cart@2x.webp" alt="odaring mascot"/>
</div>

<p class="qr-quit-title">{{"button.qr.dine.in.des.1" | translate}}?</p>

<p class="qr-quit-description">{{ "button.qr.dine.in.des.2" | translate}}</p>

<div class="qr-quit-action-container">
  <button class="quit-cancel-button" (click)="remainQrDineInMode()">{{"button.cancel" | translate}}</button>
  <button class="quit-action-button" (click)="quitQrDineInMode()">{{"button.qr.dine.in.des.1" | translate}}</button>
</div>
