import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupAddressFormService {

  popupAddressForm = new Subject<true>();

  constructor() { }

  showAddressForm() {
    this.popupAddressForm.next(true);
  }
}
