import { WebLinkTokenResponse } from './../../../core/models/WebLinkTokenResponse';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { UserQuery } from "src/app/core/user/user.query";
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { ChannelData } from 'src/app/core/models';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { CartModel } from 'src/app/core/models/CartModel';
import * as _ from 'lodash';
import { QrCartQuery } from 'src/app/core/qr-cart/qr-cart.query';
import { CartService } from 'src/app/core/cart/cart.service';
import { QueueDataQuery } from 'src/app/core/queue-data/queue-data.query';
import { QueueResponse } from 'src/app/core/models/QueueResponse';
import { RouteName } from 'src/app/core/enums/RouteName';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output() onShowChannel = new EventEmitter();
  @Input() curRouter: string;

  displayLoginButton: string = '';
  telInpSetting = DefaultSettingService.telInpSetting;
  isDefaultDomain: boolean = false;
  selectedChannel: ChannelData = undefined;
  odaringChannel = environment.odaringChannel;

  customerId : number | string;
  cart : CartModel
  cartNetTotal : number; //might not need it anymore
  numberOfOrders : number = 0;
  localCart : CartModel;
  currency : string;
  qrTokenResponse : WebLinkTokenResponse
  isLoggedIn : boolean;
  queueResponse : QueueResponse;

  constructor(
    private router: Router,
    private userchnlQry: UserChnlQuery,
    private userQuery: UserQuery,
    private channelQuery: ChannelQuery,
    private cartQuery: CartQuery,
    private qrCartQuery : QrCartQuery,
    private cartService : CartService,
    private queueDataQuery : QueueDataQuery
  ) {
    this.userQuery.isLoggedIn$.subscribe(userData => {
      if (userData && userData.id !== 999) {
        this.displayLoginButton = userData.displayName;
        this.customerId = userData.customerId;
        this.isLoggedIn = true;
      } else {
        this.displayLoginButton = '';
        this.customerId = "null";
        this.isLoggedIn = false;
      }
    });

    this.qrCartQuery.select(state => state.qrTokenResponse).subscribe(qrToken => {
      this.qrTokenResponse = qrToken ? qrToken : null;
    })

    this.queueDataQuery.select(state => state.queueResponse).subscribe(queueResponse => {
      this.queueResponse = queueResponse ? queueResponse : null;
    })

    this.cartQuery.getCurrentCart().subscribe(cart => {
      if(cart && cart.cartModel){
        this.cart = _.cloneDeep(cart.cartModel);
        this.cart.orderHs = Object.keys(cart.cartModel.orderHs).map(i => cart.cartModel.orderHs[i]);
        this.numberOfOrders = this.cart.orderHs ? this.cart.orderHs.length : 0 ;
      }
      else{
        this.cart = null;
        this.numberOfOrders = 0;
      }
    })
  }

  ngOnInit() {
    this.channelQuery.currentChannelData$.subscribe((data) => {
      this.selectedChannel = data;
      this.currency = data && data.currency ? data.currency.currCode : "MYR";
    });

    this.userchnlQry.isSubDomain$.subscribe((data) => {
      this.isDefaultDomain = !data;
    });
  }

  onLogin() {
    let routeName = this.router.url.split('/')[1];
    if(routeName == RouteName.Cart){
      this.cartService.setKeepCartRouteFlag(true);
    }
    this.router.navigateByUrl('/login', { state: { isFromHomePage: true, showContGuest: true } });
  }

  showChannel() {
    this.onShowChannel.emit(true);
  }

  async onNavigateToCart(){
    this.cartService.removeIsFromStoreFlag();
    this.cart = await this.cartService.homeCartsetOrderIsSelect(this.cart);
    await this.cartService.updateCart(this.cart);
    this.router.navigateByUrl("/cart");
  }
}

