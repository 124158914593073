<div class="p-text-center payment-success-title">
  {{'payment.response.order.number' | translate: { orderNumber: orderNumber } }}
</div>
<div class="payment-mascot-center">
  <img class="mascot" src="assets/mascot/Mascot_phone-success@2x.webp" alt="odaring mascot"/>
  <p>{{ 'payment.success.content.msg' | translate }}</p>
  <p class="payment-id-text">
    {{ 'payment.success.id' | translate: { paymentId: payTranId } }}
  </p>
</div>
