import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { CommonAddressResponse } from 'src/app/core/models/CommonAddressResponse';
import { UserQuery } from 'src/app/core/user/user.query';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { CartModel } from 'src/app/core/models/CartModel';

@Component({
  selector: 'app-my-fav-store',
  templateUrl: './my-fav-store.component.html',
  styleUrls: ['./my-fav-store.component.scss']
})
export class MyFavStoreComponent implements OnInit, OnDestroy {

  selectedAddress: CommonAddressResponse;
  favouriteStoreList: StoreResponse[] = [];

  isLoading : boolean = false;
  cartSub$ : Subscription;
  cartModel : CartModel;
  channelTag: string;

  constructor(
    private userService: UserService,
    private userQuery: UserQuery,
    private channelService: ChannelService,
    private cartQuery : CartQuery
  ) {
    this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    await this.channelService.getChannelDataCheck();

    this.channelTag = this.channelService.getChannelData().channelTag;

    this.cartSub$ = this.cartQuery.getCurrentCart().subscribe(cart => {
      this.cartModel = cart && cart.cartModel ? cart.cartModel : null;
    })

    const latitude = this.selectedAddress ? this.selectedAddress.latitude : 0;
    const longitude = this.selectedAddress ? this.selectedAddress.longitude : 0;

    let favouriteStore = await this.userService.getFavouriteStore(this.channelTag, latitude, longitude);

    this.favouriteStoreList = favouriteStore.data;
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.cartSub$?.unsubscribe();
  }

}
