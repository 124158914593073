import { StaticQrStore } from './static-qr.store';
import { StaticQrState } from './static-qr.model';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

/**
 * Guest query
 *
 * @export
 * @class StaticQrQuery
 * @extends {Query<StaticQrState>}
 */
@Injectable({ providedIn: 'root' })
export class StaticQrQuery extends Query<StaticQrState> {

  constructor(protected store: StaticQrStore) {
    super(store);
  }
}
