import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public config: any = {
    appBaseHref: "/",
    country: "",
    currency: ""
  };

  constructor() { }

  set(k: string, v: any): void {
    this.config[k] = v;
  }

  get(k: string): any {
    return k ? this.config[k] : this.config;
  }

  defaultBaseHref(): string {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;
    const path1 = pathname.split("/")[1];

    let firstHostName = hostname.split(".")[0];

    if (firstHostName === 'www') {
      firstHostName = hostname.split(".")[1];
    }

    if (environment.hostName == "localhost") {
      firstHostName = "localhost";
    }

    if (firstHostName === environment.hostName && !pathname.includes('verifyemail')) {
      return "/" + (path1 || "");
    } else {
      return '';
    }
  }
}
