import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/store/store/store.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { PageName } from 'src/app/core/enums/PageName';
import { QueueResponse } from 'src/app/core/models/QueueResponse';
import { CartService } from 'src/app/core/cart/cart.service';
import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';

@Component({
  selector: 'app-empty-dish-message',
  templateUrl: './empty-dish-message.component.html',
  styleUrls: ['./empty-dish-message.component.scss']
})
export class EmptyDishMessageComponent implements OnInit {

  @Input() isStorePage : boolean;
  @Input() isCartPage : boolean;
  @Input() qrTokenResponse : WebLinkTokenResponse;
  @Input() queueResponse : QueueResponse;
  @Input() appLinkTokenResponse : AppLinkTokenResponse;
  @Input() channelId: number;

  isMobileView : boolean;

  constructor(
    private breakpointObserver : BreakpointObserver,
    private router: Router,
    private storeService : StoreService,
    private channelService : ChannelService,
    private routingService : RoutingService,
    private cartService : CartService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  ngOnInit(): void {
  }

  async navigateToStore(){
    if(this.qrTokenResponse){
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.qrTokenResponse.locDesc);
      this.router.navigate(['store', this.qrTokenResponse.storeId, locDescWithoutSpaces]);
    }
    else if(this.queueResponse){
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.queueResponse.locShortDesc);
      this.router.navigate(['store', this.queueResponse.storeId, locDescWithoutSpaces], { replaceUrl: true });
    }
    else if(this.appLinkTokenResponse){
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.appLinkTokenResponse.locDesc);
      this.router.navigate(['store', this.appLinkTokenResponse.storeId, locDescWithoutSpaces], { replaceUrl: true });
    }
  }

  async normalFlowEmptyNavigation(){
    let channelData = this.channelService.getChannelData();
    let fromStorePageData = this.cartService.getFromStoreFlag();

    if(fromStorePageData && fromStorePageData?.storeId && this.isCartPage){
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(fromStorePageData.storeTag);
      this.router.navigate(['store', fromStorePageData.storeId, locDescWithoutSpaces], { replaceUrl: true });
      this.cartService.removeIsFromStoreFlag();
    }
    else{
      this.routingService.navigate(PageName.HomePage, channelData);
    }
  }

}
