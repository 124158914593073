import 'firebase/analytics';
import { AnalyticsEvent } from 'src/app/core/enums/AnalyticsEvent';
import { OrderData } from 'src/app/core/models/OrderData';
import { DetailActivity } from 'src/app/home/order/order.model';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { MenuItem } from 'src/app/core/models/MenuItem';
import { AddItemObject } from 'src/app/core/models/local/AddItemObject';
import * as moment from 'moment';
import { OrderModifier } from 'src/app/core/models/OrderModifier';
import { OrderSubModifier } from 'src/app/core/models/OrderSubModifier';
import { OrderD } from 'src/app/core/models/OrderD';
import { OrderH } from 'src/app/core/models/OrderH';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp, getApps } from 'firebase/app';
import { environment } from 'src/environments/environment';

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private analytics: Analytics;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const apps = getApps();
      if (apps.length === 0) {
        initializeApp(environment.firebase);
      }
      this.analytics = getAnalytics();
    }
  }

  logEvents(eventName: string, params?: { [key: string]: any }): void {
    if (isPlatformBrowser(this.platformId)) {
      logEvent(this.analytics, eventName, params);
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  detectBrowserVersion() {
    var userAgent = navigator.userAgent, tem,
      matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];

    if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  getCoupon(orderData: OrderData) {
    let voucherList = [];
    let promoList = [];
    orderData.vouchers.forEach(item => {
      voucherList.push(item.voucherId);
    });

    orderData.promotions.forEach(item => {
      promoList.push(item.promotionId);
    });

    let joinVoucher = voucherList.join(', ');
    let joinPromo = promoList.join(', ');

    let couponString = "promotionId: [" + joinPromo + "], voucherId: [" + joinVoucher + "]";

    return couponString;
  }

  async getItemList(eventName: AnalyticsEvent, itemList: any[], storeName?: string) {
    let formatItemList = [];

    if (eventName === AnalyticsEvent.items && itemList) {
      formatItemList = await this.setItemList(itemList, storeName);
    }

    return formatItemList;
  }

  async setItemList(itemList: any[], storeName: string) {
    let formatItemList = [];
    for await (let item of itemList) {
      formatItemList.push({
        affiliation: storeName,
        item_id: item.itemCode,
        item_name: item.lineDesc,
        item_category: item.itemCode,
        quantity: item.orderQty,
        price: item.unitSellPrice,
        discount: item.discAmt
      });
    }

    return formatItemList;
  }

  async purchaseEvent(detailActivities: DetailActivity[]) {
    for await (let order of detailActivities) {
      this.logEvents(AnalyticsEvent.purchase, {
        value: order.netAmt,
        coupon: this.getCoupon(order.orderData),
        tax: order.orderData.taxAmtInc,
        transaction_id: order.orderTranId,
        currency: order.currCode,
        shipping: order.deliveryCost,
        affiliation: order.storeInfo.locDesc,
        items: await this.getItemList(AnalyticsEvent.items, order.orderData.orderDs, order.storeInfo.locDesc),
      }
      );
    }
  }

  viewStoreEvent(storeResponse: StoreResponse) {
    this.logEvents(AnalyticsEvent.c_view_store_event,
      {
        c_store_id: storeResponse.storeId,
        c_store_name: storeResponse.locDesc,
        c_merchant_id: storeResponse.merchantId,
        origin: storeResponse.fullAddress,
        c_latitide: storeResponse.latitude,
        c_longitude: storeResponse.longitude,
        c_rating: storeResponse.ratingAverage,
        c_average_price: storeResponse.avgUnitPrice
      });
  }

  viewItemEvent(menuItem: MenuItem, currencyCode: string, menuSetId: number, storeId: number) {
    this.logEvents(AnalyticsEvent.view_item,
      {
        currency: currencyCode,
        value: menuItem.origPrice,
        item_id: menuItem.id,
        item_name: menuItem.title,
        item_category: menuItem.menuCatgsCode,
        item_location_id: menuSetId,
        price: menuItem.dispPrice ? menuItem.dispPrice : menuItem.origPrice,
        c_store_id: storeId
      });
  }

  threeDModelViewerEvent(storeId: number, storeName: string, itemCode: string) {
    this.logEvents(AnalyticsEvent.c_3D_model_viewer_event,
      {
        store_id: storeId,
        store_name: storeName,
        item_code: itemCode
      });
  }

  refundEvent(orderTranId: number, custMobileNo: string, custName: string) {
    this.logEvents(AnalyticsEvent.c_refund_event,
      {
        order_tran_id: orderTranId,
        customer_mobile_no: custMobileNo,
        customer_name: custName
      });
  }

  requestReceiptEvent(activityId: number, email: string) {
    this.logEvents(AnalyticsEvent.c_request_receipt_event,
      {
        activity_id: activityId,
        email: email
      });
  }

  cancelOrderEvent(orderTranId: number, custMobileNo: string, custName: string) {
    this.logEvents(AnalyticsEvent.c_cancel_order_event,
      {
        order_tran_id: orderTranId,
        customer_mobile_no: custMobileNo,
        customer_name: custName
      });
  }

  acceptOrderEvent(orderTranId: number, custMobileNo: string, custName: string) {
    this.logEvents(AnalyticsEvent.c_accept_order_event,
      {
        order_tran_id: orderTranId,
        customer_mobile_no: custMobileNo,
        customer_name: custName
      });
  }

  confirmPickUpOrderEvent(orderTranId: number, custMobileNo: string, custName: string) {
    this.logEvents(AnalyticsEvent.c_confirm_pick_up_order_event,
      {
        order_tran_id: orderTranId,
        customer_mobile_no: custMobileNo,
        customer_name: custName
      });
  }

  deleteAccountEvent(custName: string, custId: number, custEmail: string, custMobileNo) {
    this.logEvents(AnalyticsEvent.c_delete_account_event,
      {
        name: custName,
        id: custId,
        email: custEmail,
        mobileNo: custMobileNo
      });
  }

  filterActionEvent(totalCountItem: number, filterList: any) {
    this.logEvents(AnalyticsEvent.c_filter_action_event,
      {
        filter_num: totalCountItem,
        filter_list: filterList,
      });
  }

  shortCutEvent(title: string, para1: string, para2: string, functionCode: string) {
    this.logEvents(AnalyticsEvent.c_short_cut_event,
      {
        title: title,
        para1: para1,
        para2: para2,
        functionCode: functionCode
      });
  }

  selectDeliveryModeEvent(orderType: string) {
    this.logEvents(AnalyticsEvent.c_select_delivery_mode_event,
      {
        delivery_mode: orderType,
      });
  }

  addToCartEvent(addItemInput: AddItemObject) {
    let value = addItemInput.menuItem.dispPrice * addItemInput.menuItem.itemQty;
    this.logEvents(AnalyticsEvent.add_to_cart,
      {
        currency: addItemInput.menuData[0].currency.code,
        value: value.toFixed(2),
        item_id: addItemInput.menuItem.id,
        item_name: addItemInput.menuItem.title,
        item_category: addItemInput.menuItem.menuCatgsCode,
        quantity: addItemInput.menuItem.itemQty,
        price: addItemInput.menuItem.dispPrice.toFixed(2),
        origin: addItemInput.menuData[0].menuSets[0].id,
        item_location_id: addItemInput.storeId,
        destination: addItemInput.storeName,
        start_date: moment().format("yyyy-MM-DD"),
        end_date: moment().format("yyyy-MM-DD"),
      });

    let selectedOrderHs = addItemInput.cartModel.orderHs.find(order => order.storeId === addItemInput.storeId);
    let modifiers = selectedOrderHs ? selectedOrderHs.orderData.orderDs[0].modifiers : [];
    if (modifiers && modifiers.length > 0) {
      this.cartModifierEvent(addItemInput, modifiers);
    }
  }

  cartModifierEvent(addItemInput: AddItemObject, modifiers: OrderModifier[]) {
    modifiers.forEach(modifier => {
      let value = modifier.unitSellPrice * modifier.orderQty;
      this.logEvents(AnalyticsEvent.c_cart_modifier_event,
        {
          currency: addItemInput.menuData[0].currency.code,
          value: value.toFixed(2),
          item_id: modifier.itemCode,
          item_name: modifier.lineDesc,
          item_category: addItemInput.menuItem.menuCatgsCode,
          quantity: modifier.orderQty,
          price: modifier.unitSellPrice,
          origin: addItemInput.menuData[0].menuSets[0].id,
        });

      let subModifiers = modifier.subModifiers;
      if (subModifiers && subModifiers.length > 0) {
        this.cartSubModifierEvent(addItemInput, subModifiers);
      }
    });
  }

  cartSubModifierEvent(addItemInput: AddItemObject, subModifiers: OrderSubModifier[]) {
    subModifiers.forEach(subModifier => {
      let value = subModifier.unitSellPrice * subModifier.orderQty;
      this.logEvents(AnalyticsEvent.c_cart_sub_modifier_event,
        {
          currency: addItemInput.menuData[0].currency.code,
          value: value.toFixed(2),
          item_id: subModifier.itemCode,
          item_name: subModifier.lineDesc,
          item_category: addItemInput.menuItem.menuCatgsCode,
          quantity: subModifier.orderQty,
          price: subModifier.unitSellPrice,
          origin: addItemInput.menuData[0].menuSets[0].id,
        });
    });
  }

  editCartEvent(addItemInput: AddItemObject) {
    let value = addItemInput.menuItem.dispPrice * addItemInput.menuItem.itemQty;
    this.logEvents(AnalyticsEvent.c_edit_cart,
      {
        currency: addItemInput.menuData[0].currency.code,
        value: value.toFixed(2),
        item_id: addItemInput.menuItem.id,
        item_name: addItemInput.menuItem.title,
        item_category: addItemInput.menuItem.menuCatgsCode,
        quantity: addItemInput.menuItem.itemQty,
        price: addItemInput.menuItem.dispPrice.toFixed(2),
        origin: addItemInput.menuData[0].menuSets[0].id,
        item_location_id: addItemInput.storeId,
        destination: addItemInput.storeName,
        start_date: moment().format("yyyy-MM-DD"),
        end_date: moment().format("yyyy-MM-DD"),
      });

    let selectedOrderHs = addItemInput.cartModel.orderHs.find(order => order.storeId === addItemInput.storeId);
    let modifiers = selectedOrderHs ? selectedOrderHs.orderData.orderDs[0].modifiers : [];
    if (modifiers && modifiers.length > 0) {
      this.cartModifierEvent(addItemInput, modifiers);
    }
  }

  editCartModifierEvent(addItemInput: AddItemObject, modifiers: OrderModifier[]) {
    modifiers.forEach(modifier => {
      let value = modifier.unitSellPrice * modifier.orderQty;
      this.logEvents(AnalyticsEvent.c_edit_cart_modifier_event,
        {
          currency: addItemInput.menuData[0].currency.code,
          value: value.toFixed(2),
          item_id: modifier.itemCode,
          item_name: modifier.lineDesc,
          item_category: addItemInput.menuItem.menuCatgsCode,
          quantity: modifier.orderQty,
          price: modifier.unitSellPrice,
          origin: addItemInput.menuData[0].menuSets[0].id,
        });

      let subModifiers = modifier.subModifiers;
      if (subModifiers && subModifiers.length > 0) {
        this.cartSubModifierEvent(addItemInput, subModifiers);
      }
    });
  }

  editCartSubModifierEvent(addItemInput: AddItemObject, subModifiers: OrderSubModifier[]) {
    subModifiers.forEach(subModifier => {
      let value = subModifier.unitSellPrice * subModifier.orderQty;
      this.logEvents(AnalyticsEvent.c_edit_cart_sub_modifier_event,
        {
          currency: addItemInput.menuData[0].currency.code,
          value: value.toFixed(2),
          item_id: subModifier.itemCode,
          item_name: subModifier.lineDesc,
          item_category: addItemInput.menuItem.menuCatgsCode,
          quantity: subModifier.orderQty,
          price: subModifier.unitSellPrice,
          origin: addItemInput.menuData[0].menuSets[0].id,
        });
    });
  }

  async qrDineInSubmitOrderEvent(detailActivities: DetailActivity[]) {
    for await (let order of detailActivities) {
      this.logEvents(AnalyticsEvent.c_qr_dine_in_submit_order_event, {
        qr_token: order.tokenId,
        value: order.netAmt,
        coupon: this.getCoupon(order.orderData),
        tax: order.orderData.taxAmtInc,
        transaction_id: order.orderTranId,
        currency: order.currCode,
        affiliation: order.storeInfo.locDesc,
        items: await this.getItemList(AnalyticsEvent.items, order.orderData.orderDs, order.storeInfo.locDesc)
      }
      );
    }
  }

  viewCrossSellItemsEvent(menuItem: MenuItem, currencyCode: string, menuSetId: number, storeId: number) {
    this.logEvents(AnalyticsEvent.c_view_cross_sell_items_event,
      {
        currency: currencyCode,
        value: menuItem.origPrice,
        item_id: menuItem.id,
        item_name: menuItem.title,
        item_category: menuItem.menuCatgsCode,
        item_location_id: menuSetId,
        price: menuItem.dispPrice ? menuItem.dispPrice : menuItem.origPrice,
        c_store_id: storeId
      });
  }

  viewUpsellItemsEvent(menuItem: MenuItem, currencyCode: string, menuSetId: number, storeId: number) {
    this.logEvents(AnalyticsEvent.c_view_upsell_items_event,
      {
        currency: currencyCode,
        value: menuItem.origPrice,
        item_id: menuItem.id,
        item_name: menuItem.title,
        item_category: menuItem.menuCatgsCode,
        item_location_id: menuSetId,
        price: menuItem.dispPrice ? menuItem.dispPrice : menuItem.origPrice,
        c_store_id: storeId
      });
  }

  viewVariationItemsEvent(menuItem: MenuItem, currencyCode: string, menuSetId: number, storeId: number, parentItemCode: string) {
    this.logEvents(AnalyticsEvent.c_view_variation_items_event,
      {
        currency: currencyCode,
        value: menuItem.origPrice,
        item_id: menuItem.id,
        item_name: menuItem.title,
        item_category: menuItem.menuCatgsCode,
        item_location_id: menuSetId,
        price: menuItem.dispPrice ? menuItem.dispPrice : menuItem.origPrice,
        c_store_id: storeId,
        parent_item_code: parentItemCode
      });
  }

  removeFromCart(orderD: OrderD, orderH: OrderH) {
    this.logEvents(AnalyticsEvent.remove_from_cart,
      {
        currency: orderH.orderData.currCode,
        value: orderD.netAmt,
        item_id: orderD.itemId,
        item_name: orderD.lineDesc,
        item_category: orderD.menuCatgCode,
        quantity: orderD.orderQty ? orderD.orderQty : 1,
        price: orderD.unitSellPrice,
        origin: orderH.orderData.menuSetId,
        item_location_id: orderH.storeId,
        destination: orderH.storeName,
        start_date: moment().format("yyyy-MM-DD"),
        end_date: moment().format("yyyy-MM-DD"),
      });
  }

  removeCartStore(orderH: OrderH) {
    if (orderH) {
      orderH.orderData.orderDs.forEach(item => {
        this.removeFromCart(item, orderH);
      })
    }
  }

  locationRequestEvent() {
    this.logEvents(AnalyticsEvent.c_location_request, {
      c_platform: 'WEB',
      c_device_info: '',
      c_browser_type: this.detectBrowserName(),
      c_location_flag: ''
    });
  }
}
