import { CustomerProfileStatusResponse } from 'src/app/core/models/CustomerProfileStatusResponse';
import { QueueCartStore } from './../queue-cart/queue-cart.store';
import { CxmCustomerCCard } from './../models/CxmCustomerCCard';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserStore } from './user.store';
import { createUser, User, mapLoginResp, mapProfileResp, mapSendOTPResp, mapProfileReq } from './user.model';
import { StoreMode } from '../enums/StoreMode';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { UserLoginMode } from '../enums/UserLoginMode';
import { UserRole } from '../enums/UserRole';
import { CustomService } from '../services/custom.service';
import { CustomHeader, CustomRequest } from '../models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { UtilsService } from '../services/utils.service';
import { UserQuery } from './user.query';
import { CXMCustomerAddressRegisterRequest } from '../models/CXMCustomerAddressRegisterRequest';
import { CXMCustomerAddressResponse } from '../models/CXMCustomerAddressResponse';
import { CXMCustomerAddressUpdateRequest } from '../models/CXMCustomerAddressUpdateRequest';
import { of, Subject } from 'rxjs';
import { CartStore } from '../cart/cart.store';
import { UserChnlStore } from 'src/app/home/userchnl/userchnl.store';
import { StoreResponseListGenericDataResponse } from '../models/StoreResponseListGenericDataResponse';
import { RoutingService } from 'src/app/account/services/routing.service';
import { PageName } from '../enums';
import { EmailUpdateConfirmationToken } from '../models/EmailUpdateConfirmationToken';
import { LoginRequest } from '../models/LoginRequest';
import { TokenResponse } from '../models/TokenResponse';
import { TimeService } from '../services/time.service';
import { LoginForm } from 'src/app/account/models';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
@Injectable({ providedIn: 'root' })
export class UserService {

  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;
  editAddress: CXMCustomerAddressResponse = null;
  isLoggedOut$: Subject<boolean> = new Subject<boolean>();
  setupPw: Subject<string> = new Subject<string>();

  debounceFunc = _.debounce((func: any) => {
    return func();
  }, 300, { "leading": true, "trailing": false })

  debounceFunc2 = _.debounce((func: any) => {
    return func();
  }, environment.optResendInSec * 1000, { "leading": true, "trailing": false })

  private refreshTokenTimeout;
  recentAddress$: Subject<boolean> = new Subject<boolean>();
  confirmEmailStatus: string = '';
  sentVerifyEmail: boolean = false;
  loginRegistResptData: any;
  showContinueAsGuest: boolean = true;
  customerProfileStatus : CustomerProfileStatusResponse;
  displayQrPopup$: Subject<boolean> = new Subject<boolean>();

  constructor(private userStore: UserStore,
    private http: HttpClient,
    private customService: CustomService,
    private utilsService: UtilsService,
    private userQuery: UserQuery,
    private cartStore: CartStore,
    private channelStore: UserChnlStore,
    private routingService: RoutingService,
    private timeService: TimeService,
    private queueCartStore : QueueCartStore,
    private sessionStorageService: SessionStorageService
  ) {

    this.get(StoreMode.Internal).subscribe(async (userData: any) => {
      if (userData) {
        if (userData['accessToken'] && userData['refreshToken']) {
          this.accessToken = userData['accessToken'];
          this.refreshToken = userData['refreshToken'];

          this.loginRegistResptData = {
            accessToken: userData['accessToken'],
            refreshToken: userData['refreshToken'],
            idToken: userData['idToken'],
            claims: userData['claims']
          }
        }
        this.curUser = userData;
      }
      else{
        this.curUser = null;
      }
    });
  }

  get(storeMode: StoreMode) {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.userStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.userStore.setLoading(false);
    }
    return this.userQuery.selectFirst();
  }

  add(user: User) {
    const genId = environment.localGenCustomerId;

    if (!user.id) {
      if (this.curUser?.id === genId) {
        this.remove(genId);
      }
      user.id = genId;
    }

    this.remove(genId);
    this.setActive(Number(user.id));
    this.userStore.add(user);
  }

  update(id: any, user: Partial<User>) {
    this.userStore.update(id, user);
  }

  remove(id: ID) {
    this.userStore.remove(id);
  }

  clearUser() {
    if (!this.curUser) {
      return;
    }
    this.remove(this.curUser.id);
  }

  isUserExist() {
    return !!this.accessToken;
  }

  isGuestUser() {
    return !this.curUser;
  }

  getCustomer() {
    return this.curUser;
  }

  async login(loginRequest: LoginRequest, isGuest?: boolean): Promise<any> {
    let respDt = null,
      respProf = null;
    respDt = await this.reqLogin(loginRequest);
    if (respDt instanceof HttpErrorResponse) {
      return respDt;
    }

    this.loginRegistResptData = respDt;
    this.refreshToken = respDt.refreshToken;

    if (!isGuest) {
      respProf = await this.reqProfile(respDt.accessToken);
      if (respProf instanceof HttpErrorResponse) {
        return respDt;
      } else {
        if (this.curUser) {
          this.remove(this.curUser.id);
        }

        this.add({
          id: respProf.customerId,
          ...respProf,
          ...respDt,
          userLoginMode: UserLoginMode.LoggedIn,
          userRole: UserRole.User,
          isAnonymous: false
        } as any);

        return true;
      }
    }
    else {
      return respDt;
    }
  }

  logout(skipLogoutApi?: boolean) {
    if (!skipLogoutApi) {
      this.logoutUser();
    }
    this.clearUser();
    this.cartStore.remove();
    this.channelStore.remove();
    this.queueCartStore.remove();
    // this.stopRefreshTokenTimer();

    if(this.sessionStorageService.getItem('shownOtpToast')) {
      this.sessionStorageService.removeItem('shownOtpToast');
    }

    if(this.sessionStorageService.getItem('externalMembership-data')){
      this.sessionStorageService.removeItem('externalMembership-data');
    }
  }

  async logoutInit(isFromLandingPage: boolean) {
    const rtSv = this.routingService;
    this.logout();
    rtSv.navigate(isFromLandingPage ? PageName.LandingPage : PageName.HomePage);
  }

  async getProfile(update : boolean = true) {
    let respProf = null;
    respProf = await this.reqProfile(this.accessToken);
    if (respProf?.dob) {
      respProf.dob = this.utilsService.formatToDateObj(respProf.dob);
    }
    if(update){
      this.update(respProf.customerId, respProf);
    }
    return respProf;
  }

  async signup(user: any) {
    let respDt = null;
    user.dob = user.dob ? this.formatSrvDateStr(user.dob) : undefined;
    user.mobileNo = user.mobileNo;
    respDt = await this.reqRegister(user);
    return respDt;
  }

  async updateToken(respDt: TokenResponse) {
    respDt = mapLoginResp(respDt);
    await this.add({ ...respDt } as User);
  }

  async updatePassword(oldPassword: string, newPassword: string) {
    let respDt = null;
    respDt = await this.debounceFunc(() => this.reqUpdatePassword(this.accessToken, oldPassword, newPassword));
    return respDt;
  }

  async sendOTP(mobileNo: string, otpPrefix: string, emailRedirectLink?: string, isSendEmail?: boolean) {
    let newOtpPrefix = otpPrefix;
    let respDt = null;
    respDt = await this.reqSendOTP(mobileNo, newOtpPrefix, emailRedirectLink, isSendEmail);

    return respDt;
  }

  async verifyOTP(mobileNo: string, otpCode: string) {
    let respDt = null;
    respDt = await this.debounceFunc(() => this.reqVerifyOTP(mobileNo, otpCode));

    return respDt;
  }

  refreshAuthToken() {
    let respDt = null;
    respDt = this.reqRefreshToken(this.refreshToken);
    return of(respDt);
  }

  async verifyUpdateMobileNo(mobileNo: string, otpCode: string) {
    let respDt = null;
    respDt = await this.reqVerifyUpdateMobileNo(this.accessToken, mobileNo, otpCode);
    return respDt;
  }

  async updateProfile(user: any, otpRegisterUpdate?: boolean) {
    let respDt = null
    user.dob = user?.dob ? this.formatSrvDateStr(user.dob) : undefined;
    respDt = await this.debounceFunc(() => this.reqUpdateProfile(this.accessToken, user));
    respDt.email = respDt.emailAddress;

    if (otpRegisterUpdate) {
      this.add({
        ...this.curUser,
        id: respDt.customerId,
        ...respDt,
        accessToken: this.loginRegistResptData ? this.loginRegistResptData.accessToken : undefined,
        refreshToken: this.loginRegistResptData ? this.loginRegistResptData.refreshToken : undefined,
        idToken: this.loginRegistResptData ? this.loginRegistResptData.idToken : undefined
      } as User);
    } else {
      this.update(respDt.customerId, respDt);
    }
    return respDt;
  }

  async forgotPassword(mobileNo: string, newPassword: string, otpCode: string) {
    let respDt = null;
    respDt = await this.debounceFunc(() => this.reqForgotPassword({
      mobileNo: mobileNo,
      password: newPassword,
      otpCode: otpCode
    }));
    this.update(this.curUser?.id, { ...this.curUser, password: newPassword });
    return respDt;
  }

  async updateMobileNoStatus(mobileNo: string, otpCode: string) {
    let respDt = null;
    respDt = await this.reqUpdateMobileNoStatus(this.accessToken, mobileNo, otpCode);
    this.update(this.curUser?.id, { ...this.curUser, ...respDt });
    return respDt;
  }

  async sendEmailVerification(): Promise<EmailUpdateConfirmationToken> {
    let respDt = null;
    respDt = await this.reqSendEmailVerification(this.accessToken);
    return respDt;
  }

  async registerCustomerAddress(data: CXMCustomerAddressRegisterRequest): Promise<CXMCustomerAddressResponse> {
    let respDt = null;
    respDt = await this.reqRegisterCustomerAddress(this.accessToken, data);
    if (!(respDt instanceof HttpErrorResponse)) {
      this.update(this.curUser?.id, { address: respDt })
    }
    return respDt;
  }

  async updateCustomerAddress(data: CXMCustomerAddressUpdateRequest): Promise<CXMCustomerAddressResponse> {
    let respDt = null;
    respDt = await this.reqUpdateCustomerAddress(this.accessToken, data);
    return respDt;
  }

  async deleteCustomerAddress(addressId: number, channelId: number) {
    let respDt = null;
    respDt = await this.reqDeleteCustomerAddress(this.accessToken, addressId, channelId);
    return respDt;
  }

  async getCustomerAddress(channelId: number): Promise<CXMCustomerAddressResponse[]> {
    let respDt = null;
    respDt = await this.reqGetCustomerAddress(this.accessToken, channelId);
    return respDt;
  }

  async getCustomerSavedCard(channelId: number, customerId: number): Promise<CxmCustomerCCard[]> {
    let respDt = null;
    respDt = await this.reqGetCustomerSavedCard(this.accessToken, channelId, customerId);
    return respDt;
  }

  async deleteCustomerSavedCard(card: CxmCustomerCCard, channelId: number) {
    let respDt = null;
    respDt = await this.reqDeleteCustomerSavedCard(this.accessToken, card, channelId);
    return respDt;
  }

  async addFavouriteStore(storeId: number) {
    let respDt = null;
    respDt = this.reqAddFavouriteStore(this.accessToken, storeId);
    return respDt;
  }

  async removeFavouriteStore(storeId: number) {
    let respDt = null;
    respDt = this.reqRemoveFavouriteStore(this.accessToken, storeId);
    return respDt;
  }

  async getFavouriteStore(channelTag : string, latitude?: number, longitude?: number, orderTypeFlag?: string): Promise<StoreResponseListGenericDataResponse> {
    let respDt = null;
    respDt = this.reqGetFavouriteStore(this.accessToken, channelTag, latitude, longitude, orderTypeFlag);
    return respDt;
  }

  async getConfirmEmailStatus(code: string, userid: string, subscriptionId: string): Promise<any> {
    let respDt = null;
    respDt = this.reqGetConfirmEmailStatus(code, userid, subscriptionId);
    return respDt;
  }

  async deleteCustomer() {
    let respDt = null;
    respDt = this.reqDeleteCustomer(this.accessToken);
    return respDt;
  }

  async getCustomerProfileStatus(mobileNo: string, emailAddress: string): Promise<any> {
    let respDt = null;
    respDt = this.reqGetCustomerProfileStatus(mobileNo, emailAddress);
    return respDt;
  }

  setEditAddress(address: CXMCustomerAddressResponse) {
    this.editAddress = address;
  }

  getEditAddress(): CXMCustomerAddressResponse {
    return this.editAddress;
  }

  error(err: any) {
    this.userStore.setError(err);
  }

  destroy() {
    this.userStore.destroy();
  }

  private setActive(tizId: number) {
    this.userStore.setActive(tizId);
  }

  private async reqProfile(accessToken: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.Profile,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    respInfo = mapProfileResp(respInfo);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqEncryptedProfile(accessToken: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.EncryptedProfile,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    respInfo = mapProfileResp(respInfo);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqLogin(loginRequest: LoginRequest) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.Login,
      hostPath: environment.hostPath,
      body: {
        mobileNo: loginRequest.mobileNo,
        password: loginRequest?.password,
        otpCode: loginRequest?.otpCode
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    if (respInfo instanceof HttpErrorResponse === false) {
      respInfo = mapLoginResp(respInfo);
    }
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqRegister(user: Partial<User>) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.Register,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken
      },
      body: createUser(user),
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    let userInfo = _.cloneDeep(user);
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);

    this.loginRegistResptData = mapLoginResp(respInfo);

    if (!(respInfo instanceof HttpErrorResponse)) {
      respInfo = {
        ...userInfo,
        ...respInfo
      } as User;
      this.add(respInfo);
    }

    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqUpdatePassword(accessToken: string, oldPassword: string, newPassword: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.user.UpdatePassword,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      body: {
        oldPassword: oldPassword? oldPassword: undefined,
        newPassword: newPassword
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqUpdateMobileNoStatus(accessToken: string, mobileNo: string, otpCode: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.user.UpdateMobileStatus,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      body: {
        mobileNo: mobileNo,
        otpCode: otpCode
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    respInfo = mapProfileResp(respInfo);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqSendOTP(mobileNo: string, otpPrefix?: string, emailRedirectLink?: string, isSendEmail?: boolean) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.SendOTP,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken,
      },
      body: {
        mobileNo: mobileNo,
        otpPrefix: otpPrefix,
        emailRedirectLink: emailRedirectLink? emailRedirectLink: undefined,
        isSendEmail: isSendEmail
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    if (respInfo instanceof HttpErrorResponse === false) {
      respInfo = mapSendOTPResp(respInfo);
    }
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqVerifyOTP(mobileNo: string, otpCode: string) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.VerifyOTP,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken,
      },
      body: {
        mobileNo: mobileNo,
        otpCode: otpCode
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqRefreshToken(refreshToken: string) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.RefreshToken,
      hostPath: environment.hostPath,
      body: {
        refreshToken: refreshToken,
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;

    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);

    if (respInfo instanceof HttpErrorResponse === false) {
      respInfo = mapLoginResp(respInfo);
      respInfo = {
        ...this.curUser,
        idToken: respInfo.idToken,
        accessToken: respInfo.accessToken,
        refreshToken: respInfo.refreshToken,
        claims: respInfo.claims
      };
      this.update(this.curUser.id, respInfo);
    } else {
      if (this.refreshTokenTimeout) {
        this.logout();
      }
    }

    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqVerifyUpdateMobileNo(accessToken: string, mobileNumber: string, otpCode: string) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.VerifyUpdateMobileNo,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      body: {
        mobileNo: mobileNumber,
        otpCode: otpCode,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqSendEmailVerification(accessToken: string): Promise<EmailUpdateConfirmationToken> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.SendEmailVerification,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo
  }

  private async reqUpdateProfile(accessToken: string, user: Partial<User>) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.user.UpdateProfile,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      body: mapProfileReq(user),
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    if(!(respInfo instanceof HttpErrorResponse)){
      respInfo = mapProfileResp(respInfo);
    }
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqForgotPassword(user: Partial<User>) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.user.ForgotPassword,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken,
      },
      body: {
        mobileNo: user.mobileNo,
        password: user.password,
        otpCode: user.otpCode
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqRegisterCustomerAddress(accessToken: string, data: CXMCustomerAddressRegisterRequest): Promise<CXMCustomerAddressResponse> {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.RegisterCustomerAddress,
      hostPath: environment.hostPath,
      body: {
        addressDesc: data.addressDesc,
        addressIcon: data.addressIcon,
        channelId: data.channelId,
        latitude: data.latitude,
        longitude: data.longitude,
        delUnit: data.delUnit,
        delAddress: data.delAddress,
        delAddCity: data.delAddCity,
        delAddPostal: data.delAddPostal,
        delCountryCode: data.delCountryCode,
        delStateCode: data.delStateCode,
        remarks: data.remarks,
        defaultFlag: data.defaultFlag
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqUpdateCustomerAddress(accessToken: string, data: CXMCustomerAddressUpdateRequest): Promise<CXMCustomerAddressResponse> {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.user.UpdateCustomerAddress,
      hostPath: environment.hostPath,
      body: {
        addressId: data.addressId,
        addressDesc: data.addressDesc,
        addressIcon: data.addressIcon,
        unitNo: data.unitNo,
        addrSysFlag: data.addrSysFlag,
        channelId: data.channelId,
        latitude: data.latitude,
        longitude: data.longitude,
        delAddress: data.delAddress,
        delAddCity: data.delAddCity,
        delAddPostal: data.delAddPostal,
        delCountryCode: data.delCountryCode,
        delStateCode: data.delStateCode,
        remarks: data.remarks,
        defaultFlag: data.defaultFlag
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqDeleteCustomerAddress(accessToken: string, addressId: number, channelId: number) {
    let newCr = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.user.DeleteCustomerAddress,
      hostPath: environment.hostPath,
      body: {
        addressId: addressId,
        channelId: channelId,
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetCustomerAddress(accessToken: string, channelId: number): Promise<CXMCustomerAddressResponse[]> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.GetCustomerAddress,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      queryParams: {
        ChannelId: channelId
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetCustomerSavedCard(accessToken: string, channelId: number, customerId: number) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.GetCustomerSavedCard,
      hostPath: environment.hostPath,
      queryParams: {
        ChannelId: channelId,
        CustomerId: customerId
      },
      headers: {
        accessToken: accessToken
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqDeleteCustomerSavedCard(accessToken: string, card: CxmCustomerCCard, channelId: number) {
    let newCr = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.user.DeleteCustomerSavedCard,
      hostPath: environment.hostPath,
      queryParams: {
        CardDesc : card.cCardDesc,
        ChannelId : channelId,
        CustomerId : card.customerId
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqAddFavouriteStore(accessToken: string, storeId: number) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.AddFavouriteStore,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      } as CustomHeader,
      body: storeId,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqRemoveFavouriteStore(accessToken: string, storeId: number) {
    let newCr = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.user.DeleteFavouriteStore,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      } as CustomHeader,
      body: storeId,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetFavouriteStore(accessToken: string, channelTag : string, latitude?: number, longitude?: number, orderTypeFlag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.GetFavouriteStore,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      } as CustomHeader,
      queryParams: {
        latitude: latitude,
        longitude: longitude,
        orderTypeFlag: orderTypeFlag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetConfirmEmailStatus(code: string, userid: string, subscriptionId: string) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.ConfirmEmailStatus,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken,
        subId: subscriptionId
      } as CustomHeader,
      body: {
        code: code,
        userid: userid,
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);

    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqDeleteCustomer(accessToken: string) {
    let newCr = {
      httpMethod: HttpMethod.DELETE,
      requestpath: environment.apis.user.DeleteCustomer,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => dd['body'] || dd['body'] === '' ? dd['body'] : dd);
  }

  private formatSrvDateStr(date: Date) {
    const utilSv = this.utilsService;
    return utilSv.formatSrvDateStr(date);
  }

  updateSelectedAddress(address: any) {
    this.userStore.update({
      selectedAddress: address
    });
  }

  public getRefreshToken(): string | null {
    return this.refreshToken;
  }

  setRecentAddress() {
    return this.recentAddress$.next(true);
  }

  private async logoutUser() {
    let cusreq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.Logout,
      hostPath: environment.hostPath,
      httpHeaderType: HttpHeaderType.Normal,
      body: {
        refreshToken: this.refreshToken,
      },
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);

    respInfo = await this.reqCustomHttpCall(cusreq);

    this.userStore.setLoading(false);
    return respInfo;
  }

  async reqGetCustomerProfileStatus(mobileNo?: string, emailAddress?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.GetCustomerProfileStatus,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken,
      } as CustomHeader,
      queryParams: {
        mobileno: mobileNo? mobileNo: undefined,
        emailAddress: emailAddress? emailAddress: undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  isAccessTokenExpired(accessToken: string) {
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expiresDateTime = new Date(jwtToken.exp * 1000);
    const currentDateTime = new Date();

    return this.timeService.isAfterTime(currentDateTime, expiresDateTime);
  }

  setLoginForm(loginForm : LoginForm){
    this.sessionStorageService.setItem('loginForm', JSON.stringify(loginForm));
  }

  getLoginForm(){
    let loginForm = JSON.parse(this.sessionStorageService.getItem('loginForm'));
    return loginForm ? loginForm : null;
  }

  setShowContinueAsGuestFlag(toShow? : boolean){
    let toShowFlag = toShow ? toShow : false;
    this.sessionStorageService.setItem("showContinueAsGuest", JSON.stringify(toShowFlag));
  }

  getShowContinueAsGuestFlag(){
    let toShowData = this.sessionStorageService.getItem("showContinueAsGuest");
    let toShowFlag = toShowData ? JSON.parse(toShowData) : true;
    return toShowFlag ? toShowFlag : false;
  }

  removeShowContinueAsGuestFlag(){
    this.sessionStorageService.removeItem("showContinueAsGuest");
  }

  setCustomerProfileStatus(customerStatus : CustomerProfileStatusResponse){
    this.customerProfileStatus = customerStatus;
  }

  getCustomerStatusCached(){
    return this.customerProfileStatus;
  }

  showQrPopup() {
    this.displayQrPopup$.next(true);
  }

  closeQrPopup() {
    this.displayQrPopup$.next(false);
  }
}
