import { AutoMap } from "@automapper/classes";
import { StoreResponse } from "src/app/core/models/StoreResponse";
import { VoucherTypeExtraResponse } from "src/app/core/models/VoucherTypeExtraResponse";
import { FullStampChartResponse } from "src/app/membership/membership/full-stamp-chart-response";

export class MembershipDetailResponse {
  @AutoMap()
  merchantId: number;

  @AutoMap()
  membershipCode?: string;

  @AutoMap()
  membershipDesc?: string;

  @AutoMap()
  cardImageData?: string;

  @AutoMap()
  logoImageData?: string;

  @AutoMap()
  membershipId?: number;

  @AutoMap()
  membershipNo?: string;

  @AutoMap()
  rewardType?: string;

  @AutoMap()
  pointName?: string;

  @AutoMap()
  membershiplevel?: number;

  @AutoMap()
  levelDesc?: string;

  @AutoMap()
  upgradeLevel?: number;

  @AutoMap()
  upgradeYTD: number;

  @AutoMap()
  ytdPurchase: number;

  @AutoMap()
  joinDate?: string;

  @AutoMap()
  expiryDate?: string;

  @AutoMap()
  closingPoint: number;

  @AutoMap()
  stampEarned: number;

  @AutoMap()
  colCount: number;

  @AutoMap()
  totalStamp: number;

  @AutoMap()
  schartId?: number;

  @AutoMap()
  customerMbrSChartId?: number;

  @AutoMap()
  nextRewardStampId?: number;

  @AutoMap()
  externalMemberLinkMode: number;

  @AutoMap()
  storeResponseList: StoreResponse[];

  @AutoMap()
  voucherList: VoucherTypeExtraResponse[];

  @AutoMap()
  stampChart: FullStampChartResponse;

  @AutoMap()
  memberType : number;

  @AutoMap()
  printedName?: string;
}
