import { Component, OnInit } from '@angular/core';
import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { RewardService } from 'src/app/account/services/reward.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserQuery } from 'src/app/core/user/user.query';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { Channel } from 'src/app/home/channel/channel.model';
import { Subscription } from 'rxjs';
import { RewardSectionType } from 'src/app/core/enums/RewardSectionType';
import { HttpErrorResponse } from '@angular/common/http';
import { ChannelData } from 'src/app/core/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-full-page-promotion-detail',
  templateUrl: './full-page-promotion-detail.component.html',
  styleUrls: ['./full-page-promotion-detail.component.scss']
})
export class FullPagePromotionDetailComponent implements OnInit {

  channel: Channel;
  channelId: number;
  vchTypId: number;
  latitude: number = 3.0858793;
  longitude: number = 101.586459;
  fullPageRewardDetail$: Subscription;
  exploreRewardDetail: VoucherTypeExtraResponse;
  rewardSectionType: RewardSectionType;
  rewardSectionTypes: any = RewardSectionType;
  customCardTypeDetail: VoucherCardType;
  rewards: VoucherTypeExtraResponse[] = [];

  showShare: boolean = false;
  shareDialog$: Subscription;
  shareStoreName: string;

  channelData: ChannelData;
  shareUrl: string;

  isCopied: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private channelService: ChannelService,
    private rewardService: RewardService,
    private userQuery: UserQuery,
    private router: Router,
  ) { }

  async ngOnInit() {

    const routeParams = this.route.snapshot.paramMap;
    this.vchTypId = Number(routeParams.get('vchTypId'));

    this.channel = await this.channelService.getChannelDataCheck();
    this.channelData = this.channelService.getChannelData();
    this.channelId = this.channel?.channelId;

    this.userQuery.selectedAddress$.subscribe(address => {
      this.latitude = address ? address.latitude : 0;
      this.longitude = address ? address.longitude : 0;
    });

    this.fullPageRewardDetail$ = this.rewardService.fullPageRewardDetail.subscribe((value: any) => {
      this.onShowDetail(this.rewardSectionType === this.rewardSectionTypes.Explore && value.vchTypId ? value.vchTypId : 0,
        this.rewardSectionType !== this.rewardSectionTypes.Explore ? value.voucherNo : null, value.cardType);
    });

    this.exploreRewardDetail = <VoucherTypeExtraResponse>await this.rewardService.getVoucherTypeByVchTypId(this.vchTypId, this.latitude, this.longitude, this.channelId);
    if (this.exploreRewardDetail instanceof (HttpErrorResponse)) {
      this.router.navigate(["page-not-found"]);
    }

    this.shareDialog$ = this.rewardService.shareDialog.subscribe((value: any) => {
      if (value) {
        this.onShowShare(value.isShow);
        let countryCode: string = this.channelData.countryCode ? this.channelData.countryCode + '/' : '';
        this.shareUrl = value.vchTypId ? environment.shareRewardDomain + countryCode + 'promotion-details-page/' + value.vchTypId : '';
      }
    });

    this.onUrlCopied = this.onUrlCopied.bind(this);
  }

  async onShowDetail(vchTypId?: number, voucherNo?: string, cardType?: VoucherCardType) {
    this.customCardTypeDetail = cardType;
    this.exploreRewardDetail = null;
    if (this.rewardSectionType === this.rewardSectionTypes.Explore) {
      this.exploreRewardDetail = this.rewards && vchTypId ? this.rewards.filter(reward => reward.vchTypId === vchTypId)[0] : null;
    }
  }

  onBackHome() {
    this.router.navigate(['/home']);
  }

  onShowShare(isShow: any) {
    this.showShare = isShow;
    if (isShow) {
      this.shareStoreName = this.rewardSectionType === this.rewardSectionTypes.Explore ? this.exploreRewardDetail?.merchantDesc : this.exploreRewardDetail?.merchantDesc;
    }
  }

  onUrlCopied(e) {
    this.isCopied = true;

    setTimeout(() => {
      this.isCopied = false;
    }, 1500);
  }
}
