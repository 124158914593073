import { Component, OnInit, Input } from '@angular/core';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';

import { OrderTimeLineStatus } from 'src/app/core/enums/OrderTimeLineStatus';
import { ActivitySummary } from 'src/app/home/order/order.model';

@Component({
  selector: 'app-activity-timeline',
  templateUrl: './activity-timeline.component.html',
  styleUrls: ['./activity-timeline.component.scss']
})
export class ActivityTimelineComponent implements OnInit {
  @Input() set orderTimelineStatus(timelineStatus : OrderTimeLineStatus){
    this.activityTimeline = this.checkTimeline(timelineStatus);
  };

  get orderTimeLineStatus() : OrderTimeLineStatus{
    return this.orderTimeLineStatus;
  }
  @Input() activitySourceFlag : string;
  activityTimeline: number;
  orderSourceFlag = OrderSourceFlag;

  constructor() { }

  ngOnInit(): void {
  }

  checkTimeline(timelineStatus: OrderTimeLineStatus): number {
    let progressNo: number = 0;

    switch(timelineStatus) {
      case OrderTimeLineStatus.None: {
        progressNo = 1;
        break;
      }
      case OrderTimeLineStatus.Received: {
        progressNo = 2;
        break;
      }
      case OrderTimeLineStatus.Preparing: {
        progressNo = 3;
        break;
      }
      case OrderTimeLineStatus.Delivering: {
        progressNo = 4;
        break;
      }
      case OrderTimeLineStatus.Completed: {
        progressNo = 5;
        break;
      }
      default: {
        break;
      }
    }

    return progressNo;
  }

}
