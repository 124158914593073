import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { VoucherTypeExtraResponse } from "src/app/core/models/VoucherTypeExtraResponse";
import { MembershipService } from 'src/app/membership/membership/membership.service';

@Component({
  selector: 'app-member-voucher',
  templateUrl: './member-voucher.component.html',
  styleUrls: ['./member-voucher.component.scss']
})
export class MemberVoucherComponent implements OnInit {
  @Input() membershipVoucher: VoucherTypeExtraResponse;
  @Input() isShowPointAmount: boolean = true;
  @Output() onShowVoucher = new EventEmitter();

  constructor(
    private membershipService: MembershipService,
    ) { }

  ngOnInit(): void {
  }

  onShowDetail() {
    this.onShowVoucher.emit();
    this.membershipService.detailDialog.next({isShow: true, vchTypId: this.membershipVoucher.vchTypId});
  }
}
