<div class="searchResult-contianer" *ngIf="!isLoading">
  <div class="sticky-search-header">
    <!--mobile header-->
    <div class="hidden-lg" style="display: flex">
      <i class="oda-chevron-left" style="font-size: 15px; color: #8E8E8E" [routerLink]="['/home']"></i>
      <h1 class="heading-14" style="display: block; margin-left: auto; margin-right: auto;">
        {{'merchant.search.title' | translate}}
      </h1>
    </div>

    <div class="search-position">
      <app-search-filter [inputSearchValue]="searchInput" [recentSearch]="recentSearch"
        [routeSearchText]="routeSearchText" (clearSearchInput)="clearSearchField()"
        (outputSearchStore)="outputSearchStore($event)" (outputRecentSearch)="outputRecentSearch()"></app-search-filter>
    </div>

    <div class="search-content" *ngIf="showMobileSelection">
      <div class="content">
        <div class="hidden-lg">
          <div class="heading-11 recent-delete-heading-mobile" [hidden]="this.recentSearch.length == 0">
            {{ 'search.result.recent.search' | translate }}
            <button type="button" class="clearAll-btn" (click)="ondelete()">
              <i class="duotone oda-d-trash"></i>
            </button>
          </div>

          <div class="recent-list">
            <div *ngFor="let searchText of recentSearch; let i = index">
              <h1 class="heading-9 recent-heading" (click)="onRecentClick(searchText)">
                {{ searchText }}
                <button type="button"
                  style="outline: none; border: none; background-color: transparent; padding-right: 5px;"
                  (click)="removeRecentRecord(i); $event.stopPropagation()">
                  <i class="oda-times" style="font-size: 10px; color: #B1B1B1; "></i>
                </button>
              </h1>
              <hr />
            </div>
          </div>

          <div class="hot-search-mobile">
            <h1 class="heading-11"> <i class="duotone oda-d-firetag"></i> {{'merchant.search.category.hot.search' |
              translate}}</h1>
            <div class="p-d-flex p-flex-wrap">
              <div *ngFor="let cuisine of cuisines" (click)="onRecentClick(cuisine.attrOptionDesc)"
                class="p-mr-2 p-mb-2 p-mt-1 heading-17 hot-search-heading-1">
                {{cuisine.attrOptionDesc}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- total search result -->
    <section class="found-content" *ngIf="storesData && storesData.length !== 0"
      [hidden]="showMobileSelection && isMobile">
      <h1 class="heading-4 hidden-sm">
        {{ totalSearchResult }} {{resultSearchText}} {{'search.result.found' | translate}}
      </h1>

      <h1 class="hidden-lg mobile-found-heading">
        {{totalSearchResult }} {{resultSearchText}} {{'search.result.found' | translate}}
      </h1>

      <!-- sort component -->
      <div class="p-ml-auto">
        <app-sort [sortList]="searchSortList" [currentFilter]="sortList.label" [dropdownRight]="true"
          (outputSorting)="outputSorting($event)">
        </app-sort>
      </div>
    </section>

  </div>

  <div *ngIf="storesData && storesData.length == 0" [hidden]="showMobileSelection && isMobile">
    <div class="not-found-error-msg"
      [ngClass]="{'not-found-error-msg-center': isMobile, 'not-found-error-msg-text-center': !isMobile }">
      <img src="assets/mascot/Mascot_sleep-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
      <h1 class="heading-8 p-mt-3">{{'merchant.search.error.text.2' | translate}} {{resultSearchText}}.
        {{'merchant.search.error.text.3' | translate}}.</h1>
      <div class="search-again-btn">
        <button class="heading-11" (click)="onClickSearchAgain()">
          {{'search.result.button.search.again' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div class="p-grid" style="margin-top: 0px;" [hidden]="showMobileSelection && isMobile">
    <div class="p-col-12 p-lg-3 store-panel" *ngFor="let storeData of storesData">
      <!--desktop menu card-->
      <app-item-card class="hidden-sm" [storeData]="storeData" [showStoreNearBy]="true"
       (click)="onClickItemCard(storeData)" ></app-item-card>

      <!--mobile menu card-->
      <app-mobile-item-card-channel-stores class="hidden-lg" [storeData]="storeData" [showStoreNearBy]="true"
      (click)="onClickItemCard(storeData)"></app-mobile-item-card-channel-stores>
    </div>

    <ng-template [ngIf]="!isContLoadData && totalSearchResult !== 0">
      <div class="hidden-lg end-load-text">
        {{'home.end.scroll.desc' | translate}}
      </div>
    </ng-template>
  </div>
</div>

<div class="other-store-dialog">
  <p-dialog *ngIf="displayStoreDialog" [(visible)]="displayStoreDialog" [modal]="true" [dismissableMask]="true">
    <app-nearby-store
      [storeList]="storeData.otherStores"
      [currency]="currency"
      [currentCart]="cartModel">
    </app-nearby-store>
  </p-dialog>
</div>
