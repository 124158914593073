import { StaticQrState } from './../core/static-qr/static-qr.model';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { RouteName } from './../core/enums/RouteName';
import { ChangeData } from 'ngx-intl-tel-input';
import { AnalyticsService } from './../shared/services/analytics.service';
import { StoreItemStatsModel } from './../core/store-item-stats/store-item-stats.model';
import { StoreMenuResponseExtra } from 'src/app/core/models/StoreMenuResponseExtra';
import { QueueStatusFlag } from './../core/enums/QueueStatusFlag';
import { QueueCartService } from 'src/app/core/queue-cart/queue-cart.service';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { Store } from './../store/store/store.model';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { CartModel } from 'src/app/core/models/CartModel';
import * as _ from 'lodash';
import { OrderH } from 'src/app/core/models/OrderH';
import { Router } from '@angular/router';
import { ChannelService } from '../home/channel/channel.service';
import { CartService } from '../core/cart/cart.service';
import { UserService } from '../core/user/user.service';
import { User } from '../core/user/user.model';
import { forkJoin, Subscription, TimeoutError } from 'rxjs';
import { ChannelData } from '../core/models/Channel';
import { StoreService } from '../store/store/store.service';
import { StoreQuery } from '../store/store/store.query';
import { DetailActivity } from '../home/order/order.model';
import { WebLinkTokenResponse } from '../core/models/WebLinkTokenResponse';
import { OrderService } from '../home/order/order.service';
import { QrCartQuery } from '../core/qr-cart/qr-cart.query';
import { QrCartService } from '../core/qr-cart/qr-cart.service';
import { QrCartStore } from '../core/qr-cart/qr-cart.store';
import { BillCombineOption } from '../core/enums/BillCombineOption';
import { Location } from '@angular/common';
import { RewardService } from '../account/services/reward.service';
import { ToastService } from '../shared/services/toast.service';
import { ToastData } from '../core/models/ToastData';
import { OrderVoucher } from '../core/models/OrderVoucher';
import { OrderPromotion } from '../core/models/OrderPromotion';
import { PageName, StoreMode } from '../core/enums';
import { VoucherCatgWithVoucherList } from '../core/models/VoucherCatgWithVoucherList';
import { VoucherTypeExtraResponse } from '../core/models/VoucherTypeExtraResponse';
import { VoucherExtra } from '../core/models/VoucherExtra';
import { VoucherCardType } from '../core/enums/CardType';
import { environment } from 'src/environments/environment';
import { OtpService } from '../account/services/otp.service';
import { RoutingService } from '../account/services/routing.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCode } from '../core/enums/ErrorCode';
import { MenuService } from '../core/menu/menu.service';
import { Menu } from '../core/menu/menu.model';
import { StoreItemStatsService } from '../core/store-item-stats/store-item-stats.service';
import { LoaderService } from '../core/loader-services/loader.service';
import { OrderData } from '../core/models/OrderData';
import { QueueResponse } from '../core/models/QueueResponse';
import { PreviousRoute } from '../core/models/PreviousRoute';
import { StorageService } from '../shared/services/storage.service';
import { AttentionMessage } from '../core/models/AttentionMessage';
import { MiniProgramService } from '../core/mini-program/mini-program.service';
import { AppLinkTokenResponse } from '../core/models/AppLinkTokenResponse';
import { TimeService } from '../core/services/time.service';
import { AuthService } from '../core/services/auth.service';
import { SessionStorageService } from '../shared/storage/session-storage.service';
import { MembershipService } from '../membership/membership/membership.service';
import { SetCode } from '../core/enums/SetCode';
import { ChannelPlatformSetResponse } from '../core/models/ChannelPlatformSetResponse';
import { CheckoutOrderRequest } from '../core/models/CheckoutOrderRequest';
import { PopupMessageService } from '../core/services/popup-message.service';
import { QrScannerService } from '../core/services/qr-scanner.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: []
})
export class CartComponent implements OnInit, OnDestroy {

  //cart dialog
  attentionOrders : OrderH[] = [];
  cartModel : CartModel;
  orderH : OrderH[] = [];
  cartId : number | string;
  cartModelFromDB : CartModel;
  noOfSelected : number;
  allSelected : boolean;

  //isLogged in status
  user : User
  isLoggedIn : boolean = false;
  customerId : number | string;
  mobileNo: string = "";

  //channel Data
  channelData : ChannelData;
  channelId : number;
  channelTag : string;
  currCode : string;

  //total price
  totalPrice : number = 0;

  //flag when to display dialog when user not logged in
  displayLoginPrompt : boolean = false;
  displayExternalLoginPrompt: boolean = false;
  dialogPosition : string;
  dismissable : boolean;
  isMobileView : boolean;

  displayAttentionDialog : boolean = false;
  displayVerifyPhoneDialog: boolean = false;

  //store id
  storeId : number;

  locDesc : string;

  cartStoreItem : CartStoreItem[];
  storeData: Store[];
  menuState : Menu[];
  storeItemStatsData : StoreItemStatsModel[];

  //qr dine in
  qrTokenResponse : WebLinkTokenResponse;
  submittedOrder : DetailActivity[] = [];
  displayQuitQrDialog : boolean;
  displayMergeMessage : boolean = false;
  mergeCustName : string;

  //reward
  @ViewChild('searchCode') searchCodeEl: ElementRef;
  applicableVoucher: VoucherCatgWithVoucherList[];
  appliedVoucher: OrderVoucher[];
  displayPromoDialog: boolean = false;
  displaySelectVoucherDialog: boolean = false;
  displaySearchVoucherDialog: boolean = false;
  displayVoucherDetailDialog: boolean = false;
  displayShareDialog: boolean = false;
  promoStoreId: number;
  selectVoucherStoreId: number;
  selectVoucherOrderData: OrderData;
  selectVoucherCatg: VoucherCatgWithVoucherList;
  selectVoucherDetail: VoucherExtra;
  isVoucherSelected: boolean;
  cardTypes: any = VoucherCardType;
  searchPromoCode: string = "";
  searchSelectCode: string = "";
  searchVouchers: VoucherTypeExtraResponse[];
  shareUrl: string = "";
  shareStoreName: string = "";
  isCopied: boolean = false;
  invalidPromo: boolean = false;

  //subscription for unsubscribe
  sub : Subscription;
  sub2 : Subscription;
  qrQuerySub : Subscription;
 promoDialogSub: Subscription;
 selectVoucherDialogSub: Subscription;
 searchVoucherDialogSub: Subscription;
 voucherDetailDialogSub: Subscription;
 shareDialogSub: Subscription;
 selectVoucherSub: Subscription;
 applyVoucherSub: Subscription;
 removeRewardSub: Subscription;
 removePromoSub: Subscription;
 userSub: Subscription = new Subscription();
 menuSub$ : Subscription;
 itemStatsSub$ : Subscription;
 queueDataSub$ : Subscription;
 removeQueueSub$ : Subscription;

 isLoading : boolean;
 isProcessing : boolean = false;
 displayMaximumMessage : boolean = false;
 timerId : any;
 isOrderPaid : boolean;
 displayOrderPaid : boolean = false;
 queueResponse : QueueResponse;
 queueStatusFlag = QueueStatusFlag;
 displayRemoveQueueCart : boolean;
 displayQueueError : boolean;
 displaySubmitPhoneNo : boolean = false;
 staticQrData : StaticQrState;
 staticQrSub$ : Subscription;
 orderAllTable : boolean = false;
 appLinkSub$ : Subscription;
 appLinkTokenResponse : AppLinkTokenResponse;

 toKeepRoute : boolean = false;

  showEnterVoucherCode: boolean = true;
  membershipCode: string;
  externalMemberCardImageData: string;
  externalMemberLogoImageData: string;
  externalMemberMembershipDesc: string;
  onLoginExtMembershipSub$: Subscription;
  displayScanTableNo: boolean = false;
  maxQtyPertran: number = 0;

  constructor(
    private cartQuery : CartQuery,
    private cartService : CartService,
    private router: Router,
    private channelService : ChannelService,
    private userService : UserService,
    private breakpointObserver : BreakpointObserver,
    private storeQuery : StoreQuery,
    private storeService : StoreService,
    private previousRouterService : PreviousRouteService,
    private qrCartQuery : QrCartQuery,
    private qrCartService : QrCartService,
    private qrCartStore : QrCartStore,
    private orderService : OrderService,
    private rewardService: RewardService,
    private location : Location,
    private toastService: ToastService,
    private otpService : OtpService,
    private routingService : RoutingService,
    private analyticsService : AnalyticsService,
    private menuService : MenuService,
    private storeItemStatsService : StoreItemStatsService,
    private loaderService : LoaderService,
    private queueService : QueueService,
    private queueCartService : QueueCartService,
    private storageService : StorageService,
    private staticQrService : StaticQrService,
    private miniProgramService : MiniProgramService,
    private timeService : TimeService,
    private authService : AuthService,
    private sessionStorageService: SessionStorageService,
    private membershipService: MembershipService,
    private popupMessageService: PopupMessageService,
    private qrScannerService : QrScannerService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.dialogPosition = "bottom";
        this.dismissable = true;
        this.isMobileView = true;
      }
      else{
        this.dialogPosition = "center";
        this.dismissable = false;
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit(){
    this.isLoading = true;
    this.storeService.removeOrderTranId();
    this.previousRouterService.removePreviousRoute();
    this.cartService.setKeepCartRouteFlag(false);
    this.userService.removeShowContinueAsGuestFlag();
    await this.channelService.getChannelDataCheck();
    this.loaderService.startManualLoad();

    this.storeId = history.state.storeId ? history.state.storeId : null;

    this.userSub = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.user = userData;
      this.mobileNo = userData && userData.mobileNo? userData.mobileNo: userData && userData.mobileNumber? userData.mobileNumber: "";

      if (userData) {
        this.isLoggedIn = true;
        this.customerId = this.user.customerId;
      } else {
        this.isLoggedIn = false;
        this.customerId = "null";
      }
    });

    this.removeQueueSub$ = this.queueService.toRemoveQueueCart$.subscribe(toRemoveQueue => {
      if(toRemoveQueue){
        this.displayRemoveQueueCart = true;
      }
      else{
        this.displayRemoveQueueCart = false;
      }
    })

    // get qr dine in token response
    this.getQrTokenResponse();

    // get queue response
    this.getQueueData();

    // get static QR data
    this.getStaticData();

    // get app link token response
    this.getAppLinkTokenResponse();

    this.channelData = this.channelService.getChannelData();
    this.channelId = this.channelData.channelId;
    this.channelTag = this.channelData.channelTag;
    this.currCode = this.channelData.currency? this.channelData.currency.currCode : "RM";

    this.getAllStore();
    this.getAllMenu();
    this.getAllStoreItemStats();

    if(this.qrTokenResponse){
      this.submittedOrder = await this.qrCartService.getActivityByLinkId(this.qrTokenResponse.linkId, false);
      this.getQrCart();
    }
    else if(this.queueResponse){
      this.getQueueCart();
    }
    else if(this.appLinkTokenResponse){
      this.getAppLinkCart();
    }
    else{
      this.getCart();

      if(this.isLoggedIn){
        this.cartModel = await this.cartService.overwriteRemoteCartChecking(false, this.storeId);
      }
    }

    if(this.cartModel != null){
      if(this.cartModel.orderHs.length != 0 && !this.appLinkTokenResponse){
        this.cartModel = await this.cartService.updateUserInfo(this.cartModel, this.qrTokenResponse);
      }

      await this.getStoreIfOneOrder(this.cartModel);
      this.cartModel = await this.updateMembership(this.cartModel);

      if(this.sessionStorageService.getItem("appliedPromoCode")) {
        let appliedPromoCode = JSON.parse(this.sessionStorageService.getItem("appliedPromoCode"));
        let cartIndex = this.cartModel.orderHs.findIndex((order: OrderH) => order.storeId === appliedPromoCode.storeId);

        if(cartIndex >= 0) {
          this.cartModel.orderHs[cartIndex].orderData.promotions.push({ promotionCode: appliedPromoCode.promoCode });
        }

        this.sessionStorageService.removeItem("appliedPromoCode");
      }

      await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }

    // reward
    this.selectVoucherDialogSub = this.rewardService.selectDialog.subscribe(async (value: any) => {
      let storeData = this.storeService.getEntityById( this.cartModel && this.cartModel.orderHs[0].storeId);
      let memberType : any;
      if(storeData){
        let selectedIndex = this.cartModel.orderHs.findIndex(orderH => orderH.isSelect);
        let selectedStore = this.storeData.findIndex(store => store.id == this.cartModel.orderHs[selectedIndex].storeId);
        let merchantMemberships = this.storeData[selectedStore].merchantMemberships;
        if( merchantMemberships && merchantMemberships !=null && merchantMemberships.length != 0) {
          memberType = merchantMemberships[0].memberType ? merchantMemberships[0].memberType : null;
        }
      }

      // if not logged in and not external program then prompt login
      if (!this.isLoggedIn && value.orderData && (!memberType || (memberType && memberType == 2)) && !this.appLinkTokenResponse) {
        this.displayLoginPrompt = true;
        this.sessionStorageService.setItem("fromVoucher", JSON.stringify({ fromVoucher: true }));
        this.sessionStorageService.setItem("isLoginFromCart", JSON.stringify({ isLoginFromCart: true }));
      }
      // if external membership, allow odaring guest then prompt external membership login
      else if(value.orderData && value.orderData && (memberType && memberType != 2) && !value.orderData?.orderC?.extMembershipNo && !this.appLinkTokenResponse){
        this.displayExternalLoginPrompt = true;
        this.sessionStorageService.setItem("fromVoucher", JSON.stringify({ fromVoucher: true }));
        this.sessionStorageService.setItem("isLoginFromCart", JSON.stringify({ isLoginFromCart: true }));

        if(this.cartModel && this.cartModel?.orderHs && this.cartModel?.orderHs[0].orderData?.orderC) {
          this.membershipCode = this.cartModel.orderHs[0].orderData?.orderC.extMembershipCode;
          this.externalMemberCardImageData =  this.cartModel.orderHs[0].orderData?.orderC.cardImageData;
          this.externalMemberLogoImageData = this.cartModel.orderHs[0].orderData?.orderC.logoImageData;
          this.externalMemberMembershipDesc = this.cartModel.orderHs[0].orderData?.orderC.membershipDesc;
        }

        this.onLoginExtMembershipSub$ = this.membershipService.onLoginExtMembership.subscribe(async externalmembershipDetail => {
          if (externalmembershipDetail) {

            let cartIndex: number;
            let selectedIndex = this.cartModel.orderHs.findIndex(orderH => orderH.isSelect);
            cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.orderH[selectedIndex].cartGUID);

            this.cartModel.orderHs[cartIndex].orderData.orderC.extMembershipNo = externalmembershipDetail.membershipNo;
            this.cartModel.orderHs[cartIndex].orderData.orderC.levelDesc = externalmembershipDetail.levelDesc;
            this.cartModel.orderHs[cartIndex].orderData.orderC.membershipLevel = externalmembershipDetail.membershiplevel;
            this.cartModel.orderHs[cartIndex].orderData.orderC.stampEarned = externalmembershipDetail.stampEarned;
            this.cartModel.orderHs[cartIndex].orderData.orderC.totalStamps = externalmembershipDetail.totalStamp;
            this.cartModel.orderHs[cartIndex].orderData.orderC.closingPoint = externalmembershipDetail.closingPoint;

            await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);

            this.displayExternalLoginPrompt = false;
          }
        });

      }
      //if mini program then close toast and open voucher dialog logic and hide enter voucher code UI
      // if logged in OR mini program then close toast and open voucher dialog
      else {
        this.toastService.closeToast();
        this.displaySelectVoucherDialog = value.isShow;

        //if mini program then close toast and open voucher dialog logic and hide enter voucher code UI
        if (this.appLinkTokenResponse) {
          this.showEnterVoucherCode = false;
        }
      }

      // displaySelectVoucherDialog is true then get voucher list
      if (this.displaySelectVoucherDialog == true) {
        this.selectVoucherStoreId = value.storeId;
        this.selectVoucherOrderData = value.orderData;
        let currentOrderH = this.cartModel.orderHs.find(orderH => orderH.storeId == this.selectVoucherStoreId);
        await this.getApplicableVoucher(value.storeId,
          currentOrderH.orderData.orderC.extMembershipCode ? currentOrderH.orderData.orderC.extMembershipCode : currentOrderH.orderData.orderC.membershipCode ? currentOrderH.orderData.orderC.membershipCode : null,
          currentOrderH.orderData.orderC.extMembershipNo ? currentOrderH.orderData.orderC.extMembershipNo : currentOrderH.orderData.orderC.membershipNo ?  currentOrderH.orderData.orderC.membershipNo : null);
        this.appliedVoucher = _.cloneDeep(this.cartModel.orderHs.find((orderH: OrderH) => orderH.storeId === this.selectVoucherStoreId)?.orderData.vouchers);
      }

      //close displaySelectVoucherDialog
      if (!value.isShow) {
        this.displaySelectVoucherDialog = false;
      }
    });

    this.promoDialogSub = this.rewardService.promoDialog.subscribe((value: any) => {
      this.toastService.closeToast();
      this.searchPromoCode = "";
      this.invalidPromo = false;
      this.displayPromoDialog = value.isShow;
      this.promoStoreId = value.storeId;
    });

    this.searchVoucherDialogSub = this.rewardService.searchVoucherDialog.subscribe((value: any) => {
      this.displaySearchVoucherDialog = value.isShow;

      if(!value.isShow) {
        this.rewardService.selectDialog.next({isShow: true, storeId: this.selectVoucherStoreId});
      }

      this.searchSelectCode = "";
    });

    this.voucherDetailDialogSub = this.rewardService.detailDialog.subscribe((value: any) => {
      if(value.isShow && value.voucherNo && value.vchCatgId) {
        this.displaySelectVoucherDialog = false;
        this.toastService.closeToast();

        this.selectVoucherCatg = this.applicableVoucher && value.vchCatgId? this.applicableVoucher.find((voucherCatg: VoucherCatgWithVoucherList) => voucherCatg.vchCatgId === value.vchCatgId): null;
        this.selectVoucherDetail = this.selectVoucherCatg? this.selectVoucherCatg.voucherList.find((voucher: VoucherExtra) => voucher.voucherNo === value.voucherNo): null;
        this.isVoucherSelected = this.appliedVoucher.map((selectedVoucher: VoucherExtra) => selectedVoucher.voucherNo).includes(value.voucherNo);
      }

      this.displayVoucherDetailDialog = value.isShow;

      if(!value.isShow && value.isBack) {
        this.displaySelectVoucherDialog = true;
      }
    });

    this.selectVoucherSub = this.rewardService.selectVoucher.subscribe(async (value: any) => {
      if(!this.displaySelectVoucherDialog){
        this.selectVoucherStoreId = value.storeId;
        this.displaySelectVoucherDialog = true;
        // search cart for current orderH to get membershipNo and membershipCode
        let currentOrderH = this.cartModel.orderHs.find(orderH => orderH.storeId == value.storeId);

        // if external membership then use ext membershipNo and membershipCode
        // else normal membershipNo and membershipCode
        if(currentOrderH.orderData.orderC.memberType != 2){
          await this.getApplicableVoucher(value.storeId, currentOrderH ? currentOrderH.orderData.orderC.extMembershipCode : null,
            currentOrderH ? currentOrderH.orderData.orderC.extMembershipNo : null);
        }
        else{
          await this.getApplicableVoucher(value.storeId, currentOrderH ? currentOrderH.orderData.orderC.membershipCode : null,
            currentOrderH ? currentOrderH.orderData.orderC.membershipNo : null);
        }

        this.toastService.closeToast();
      }

      this.selectVoucher(value.selected, value.voucher, value.type);
    });

    this.applyVoucherSub = this.rewardService.applyVoucher.subscribe(async (value: any) => {
      this.displaySelectVoucherDialog = false;

      if(value.selectedVoucher && value.storeId) {
        let orderInd: number = this.cartModel.orderHs.findIndex((order: OrderH) => order.storeId === value.storeId);

        this.cartModel.orderHs[orderInd].orderData.vouchers = value.selectedVoucher;

        await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
        this.checkAttentionMessage(value.storeId);
        this.appliedVoucher = this.cartModel.orderHs[orderInd].orderData.vouchers;

        if(!this.displayAttentionDialog) {
          let toastMsg: string = "rewards.applied.voucher";
          let toastMsgData: {} = { "noOfVoucher": value.selectedVoucher.length };
          this.showSuccessToast(toastMsg, toastMsgData);
        }
      }
    });

    this.removeRewardSub = this.rewardService.removeReward.subscribe((value: any) => {
      if(value && value.voucherNo && value.storeId) {
        this.removeReward(value.storeId, value.voucherNo);
      }
    });

    this.removePromoSub = this.rewardService.removePromo.subscribe((value: any) => {
      if(value && value.promotionCode && value.storeId) {
        this.removePromo(value.storeId, value.promotionCode);
      }
    });

    this.onUrlCopied = this.onUrlCopied.bind(this);

    this.shareDialogSub = this.rewardService.shareDialog.subscribe((value: any) => {
      this.displayVoucherDetailDialog = !value.isShow;
      let countryCode: string = this.channelData.countryCode? this.channelData.countryCode + '/' : '';
      this.shareUrl = value.vchTypId? environment.shareRewardDomain + countryCode + 'promotion-details-page/' + value.vchTypId : '';
      this.shareStoreName = this.selectVoucherDetail?.merchantDesc;
      this.displayShareDialog = value.isShow;
    });

    if(this.sessionStorageService.getItem("requiredTableNo")){
     this.displayScanTableNo = true;
    }

    this.isLoading = false;
    this.loaderService.stopManualLoad();
  }

  ngOnDestroy(){
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
    this.promoDialogSub?.unsubscribe();
    this.selectVoucherDialogSub?.unsubscribe();
    this.searchVoucherDialogSub?.unsubscribe();
    this.voucherDetailDialogSub?.unsubscribe();
    this.shareDialogSub?.unsubscribe();
    this.selectVoucherSub?.unsubscribe();
    this.applyVoucherSub?.unsubscribe();
    this.removeRewardSub?.unsubscribe();
    this.removePromoSub?.unsubscribe();
    this.attentionOrders = [];
    this.toastService.closeToast();
    this.userSub?.unsubscribe();
    this.menuSub$?.unsubscribe();
    this.itemStatsSub$?.unsubscribe();
    this.removeQueueSub$?.unsubscribe();
    this.staticQrSub$?.unsubscribe();
    this.onLoginExtMembershipSub$?.unsubscribe();

    let keepCartRoute = this.cartService.getKeepCartRouteFlag();
    if(!this.toKeepRoute && !keepCartRoute){
      this.previousRouterService.removePreviousRoute();
    }
  }

  getQrTokenResponse(){
    this.qrQuerySub = this.qrCartQuery.qrWebLinkResponse$.subscribe(webLinkData => {
      if(webLinkData){
        this.qrTokenResponse = webLinkData;
      }
      else{
        this.qrTokenResponse = null;
      }
    })
  }

  getQueueData(){
    this.queueDataSub$ = this.queueService.getQueueResponseObservable().subscribe(queueResponse => {
      this.queueResponse = queueResponse ? queueResponse : null;
    })
  }

  getStaticData(){
    this.staticQrSub$ = this.staticQrService.getStaticQrData().subscribe(staticQrData => {
      this.staticQrData = staticQrData ? staticQrData : null;
    })
  }

  getAppLinkTokenResponse(){
    this.appLinkSub$ = this.miniProgramService.getAppTokenRespObservable().subscribe(appLinkResponse => {
      this.appLinkTokenResponse = appLinkResponse ? appLinkResponse : null;
    })
  }

  getCart(){
    this.sub = this.cartQuery.getCurrentCart().pipe(
      map(val =>
        {
          if(val){
            let cart = _.cloneDeep(val);
            cart.cartModel.orderHs.map(orderH => {
              orderH.orderData.sourceFlag = this.getCorrectSourceFlag(orderH.orderData.sourceFlag);
            });
            return cart;
          }
          else{
            return val;
          }

        })
      ).subscribe(cart => {
        if(cart && cart.cartModel){
        this.cartModel = _.cloneDeep(cart.cartModel);
        this.orderH = _.cloneDeep(cart.cartModel.orderHs);

        this.cartId = cart.id;

        this.noOfSelected = this.cartModel.orderHs.filter(val => val.isSelect).length;
        this.allSelected = this.noOfSelected == this.cartModel.orderHs.length ? true : false;

        // check if all order have table number to hide select all checkbox
        this.orderAllTable = this.cartModel.orderHs.every(orderH => orderH.orderData.tableNo);

        this.totalPrice = 0;
        this.totalPrice = this.cartModel.netTotal;

        this.checkAttentionMessage();
      }
      else{
        this.orderH = [];
        this.cartModel = null;
        this.noOfSelected = 0;
        this.attentionOrders = [];
      }
    })
  }

  getQrCart(){
    this.sub = this.qrCartQuery.selectEntity(this.qrTokenResponse.linkId).subscribe(qrCart => {
      if(qrCart && qrCart.cartModel){
        this.cartModel = _.cloneDeep(qrCart.cartModel);
        this.orderH = _.cloneDeep(qrCart.cartModel.orderHs);

        this.checkAttentionMessage();
      }
      else{
        this.cartModel = null;
        this.orderH = [];
        this.attentionOrders = [];
      }
    })
  }

  getQueueCart(){
    this.sub = this.queueCartService.getCartByEntity(this.queueResponse.storeId).subscribe(queueCart => {
      if(queueCart && queueCart.cartModel){
        this.cartModel = _.cloneDeep(queueCart.cartModel);
        this.orderH = _.cloneDeep(queueCart.cartModel.orderHs);

        this.checkAttentionMessage();
      }
      else{
        this.cartModel = null;
        this.orderH = [];
        this.attentionOrders = [];
      }
    })
  }

  getAppLinkCart(){
    this.sub = this.miniProgramService.getAppCartByEntityId(this.appLinkTokenResponse.linkId).subscribe(cart => {
      if(cart && cart.cartModel){
        this.cartModel = _.cloneDeep(cart.cartModel);
        this.orderH = _.cloneDeep(cart.cartModel.orderHs);

        this.totalPrice = 0;
        this.totalPrice = this.cartModel.netTotal;

        this.checkAttentionMessage();
      }
      else{
        this.cartModel = null;
        this.orderH = [];
        this.attentionOrders = [];
        this.totalPrice = 0;
      }
    })
  }

  getAllStore(){
    this.sub2 = this.storeQuery.selectAll().subscribe(val => {
      if(val){
        this.storeData = val ? val : [];
      }
    })
  }

  getAllMenu(){
    this.menuSub$ = this.menuService.getAllMenuObservable().subscribe(menus => {
      this.menuState = menus && menus.length > 0 ? menus : [];
    })
  }

  getAllStoreItemStats(){
    this.itemStatsSub$ = this.storeItemStatsService.getAlllStoreItemStats().subscribe(storeItemStats => {
      this.storeItemStatsData = storeItemStats && storeItemStats.length > 0 ? storeItemStats : [];
    })
  }


  async onClickPay() {
    // check if user is idle for a certain amount of time
    let isIdle = await this.timeService.isIdleCheck(new Date());
    // if is idle will route to respective route based on mode
    if(isIdle){
      this.timeService.isIdleRouting(this.qrTokenResponse, this.queueResponse, this.staticQrData && this.staticQrData.tableNo ? this.staticQrData : null);
      return;
    }

    let currentRoute = this.router.url;
    this.previousRouterService.savedPaymentBackUrl(currentRoute);

    if(this.appLinkTokenResponse){
      this.router.navigate(["order-payment"]);
      return;
    }

    if(!this.isLoggedIn){
      // filter out selected cart to get selected cart count
      let selectedCartCount = this.cartModel.orderHs.filter(orderH => orderH.isSelect);

      let selectedCartList = selectedCartCount.find(orderH => orderH.orderData.sourceFlag);
      let sourceFlag = selectedCartList.orderData.sourceFlag;

      if (selectedCartCount.length > 0) {
        // take the first orderH store platformset setting value
        let storeData = this.storeService.getEntityById(selectedCartCount[0].storeId);
        let storePlatformSet = storeData?.storeResponse?.platformSets;

        if (storePlatformSet) {
          let setCodeList = storePlatformSet.filter(setting => (setting.setCode == SetCode.DI_NOREQLOGIN) || (setting.setCode == SetCode.PI_NOREQLOGIN) || (setting.setCode == SetCode.DE_NOREQLOGIN) ||
          (setting.setCode == SetCode.DYNAREQTEL) || setting.setCode == SetCode.STATICREQTEL);

          let reqTelSetCode = this.qrTokenResponse ? SetCode.DYNAREQTEL : SetCode.STATICREQTEL;
          let mobileCompulsorySetting: ChannelPlatformSetResponse = setCodeList.find(settings => settings.setCode == reqTelSetCode);

          if (sourceFlag == OrderSourceFlag.WebDineIn || sourceFlag == OrderSourceFlag.AppDineIn || sourceFlag == OrderSourceFlag.WebQrDineIn || sourceFlag == OrderSourceFlag.AppQrDineIn) {
            let dine_NOREQLOGIN: ChannelPlatformSetResponse = setCodeList.find(setting => setting.setCode == SetCode.DI_NOREQLOGIN);
            await this.checkRequestLogin(dine_NOREQLOGIN, mobileCompulsorySetting );
          } else if (sourceFlag == OrderSourceFlag.WebPickup || sourceFlag == OrderSourceFlag.AppPickup || sourceFlag == OrderSourceFlag.WebQrTakeaway || sourceFlag == OrderSourceFlag.AppQrTakeaway) {
             let pickup_NOREQLOGIN: ChannelPlatformSetResponse = setCodeList.find(setting => setting.setCode == SetCode.PI_NOREQLOGIN);
            await this.checkRequestLogin(pickup_NOREQLOGIN, mobileCompulsorySetting );
          } else if (sourceFlag == OrderSourceFlag.WebDelivery || sourceFlag == OrderSourceFlag.AppDelivery) {
            let delivery_NOREQLOGIN: ChannelPlatformSetResponse = setCodeList.find(setting => setting.setCode == SetCode.DE_NOREQLOGIN);
            await this.checkRequestLogin(delivery_NOREQLOGIN, mobileCompulsorySetting );
          } else {
            await this.processOrderPayment();
          }
        }
      }
    } else if (this.user && !this.user.isMobileVerified) {
      this.displayVerifyPhoneDialog = true;
    } else {
      await this.processOrderPayment();
    }
  }

  async checkRequestLogin(setCode: any, mobileCompulsorySetting? : any){
    if (setCode && setCode.setValue == '1') {
      this.displayLoginPrompt = false;
      await this.checkSubmitMobileNo(mobileCompulsorySetting);
    } else {
      this.userService.setShowContinueAsGuestFlag(false);
      this.displayLoginPrompt = true;
    }
  }

  async checkSubmitMobileNo(mobileCompulsorySetting?: any) {
    let cachedNumber = this.storageService.getCachedNumber();
    this.userService.setShowContinueAsGuestFlag(true);
    if (mobileCompulsorySetting && mobileCompulsorySetting?.setValue === '1'){
      if(cachedNumber){
        this.onSubmitMobileNumber(cachedNumber);
      }else {
        this.displaySubmitPhoneNo = true;
      }
    }else {
      if(cachedNumber){
        this.onSubmitMobileNumber(cachedNumber);
      }else {
        await this.processOrderPayment();
      }
    }
  }

  async processOrderPayment() {
    if (this.cartModel && this.cartModel.netAmt == 0 && this.cartModel.savingAmt > 0) {
      let checkoutInfo: CheckoutOrderRequest = {} as CheckoutOrderRequest;
      checkoutInfo.channelId = this.channelId;
      checkoutInfo.paymentId = -1;
      checkoutInfo.netTotal = this.cartModel.netAmt != null ? this.cartModel.netAmt : 0;
      if(this.isLoggedIn){
        checkoutInfo.cartTranIds = [];
        this.orderH.forEach(val => checkoutInfo.cartTranIds.push(val.cartTranId));
      }
      else{
        checkoutInfo.orderHs = this.orderH;
      }
      checkoutInfo.currCode = this.currCode ? this.currCode : "RM";
     let response = await this.orderService.requestCheckOut(checkoutInfo);
      if ((response instanceof HttpErrorResponse)) {
       if(response?.error?.errorCode == ErrorCode.InvalidZeroAmtCheckout){
        this.popupMessageService.show({
          icon: "oda-remove-circle",
          iconColor: "red",
          title: "invalid.total.amount",
          desc: "invalid.total.amount.desc",
          btn: "merchant.reorder.button.desc.1",
        });
       } else  if(response?.error?.errorCode == ErrorCode.OrderReqLogin_401){
        this.userService.setShowContinueAsGuestFlag(false);
        this.sessionStorageService.setItem("fromVoucher", JSON.stringify({ fromVoucher: true }));
        this.displayLoginPrompt = true;
       }
      } else {
        let data = { 'activityId': response.activityIds};
        try {
          this.sessionStorageService.setItem("orderDetail", JSON.stringify(data));
        } catch(e) {
          console.log("Failed to set session storage", e)
        }

        this.router.navigate(['/order-detail']);
      }
    } else {
      let response = await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);
      if ((response instanceof HttpErrorResponse) && response?.error?.errorCode == ErrorCode.CartNotFound_404) {
        this.displayOrderPaid = true;
        this.isOrderPaid = false;
      }else{
        let orders: OrderH[] = response.OrderH ? response.OrderH.filter(val => val.attentionMessages.length != 0 && val.isSelect) : [];
        orders.forEach((order: OrderH) => {
          let index = order.attentionMessages.findIndex((msg: AttentionMessage) => msg.messageCode === ErrorCode.ItemBlockedNotSync_204 || msg.messageCode  === ErrorCode.OrderItemBalanceQtyInvalid_400 || msg.messageCode == "MinOrderAmount");
          if ((index >= 0 && order.attentionMessages.length > 1) || (index < 0 && order.attentionMessages.length > 0)) {
            this.attentionOrders.push(order);
          }
        });

        let OrderItemBalanceQtyInvalid_400 = this.attentionOrders.find(item => item.attentionMessages.find(val => val.messageCode == ErrorCode.OrderItemBalanceQtyInvalid_400));
        let ItemBlockedNotSync_204 = this.attentionOrders.find(item => item.attentionMessages.find(val => val.messageCode == ErrorCode.ItemBlockedNotSync_204));
        let MinOrderAmount = this.attentionOrders.find(item => item.attentionMessages.find(val => val.messageCode == "MinOrderAmount"));
        if(OrderItemBalanceQtyInvalid_400 || ItemBlockedNotSync_204 || MinOrderAmount) {
          this.displayAttentionDialog = true;
          return;
        }else {
          this.router.navigate(["order-payment"]);
        }
      }
    }
  }

  //#region submit order method
  async onClickConfirm(){
    try{
      this.isProcessing = true;

      let resp = await this.qrCartService.submitOrder(this.channelId, this.qrTokenResponse.linkId, this.cartModel, null);
      this.isProcessing = false;

      if(resp){
        if(!resp.toConfirmBillCombineOption) {
          await this.analyticsService.qrDineInSubmitOrderEvent(resp.activityDetail);
          this.navigateToOrderSummary(resp.activityDetail, resp.activityId[0]);
        }
        else{
          this.displayMergeMessage = true;
          this.mergeCustName = resp.custNameToCombineBill;
        }
      }

    }catch(error){
      this.isProcessing = false;
    }

  }

  async mergeOrder(){
    this.displayMergeMessage = false;
    let resp = await this.qrCartService.submitOrder(this.channelId, this.qrTokenResponse.linkId, this.cartModel, BillCombineOption.Merge);
    if(resp){
      await this.analyticsService.qrDineInSubmitOrderEvent(resp.activityDetail);
      this.navigateToOrderSummary(resp.activityDetail, resp.activityId[0]);
    }
  }

  async splitOrder(){
    this.displayMergeMessage = false;
    let resp = await this.qrCartService.submitOrder(this.channelId, this.qrTokenResponse.linkId, this.cartModel, BillCombineOption.Split);
    if(resp){
      await this.analyticsService.qrDineInSubmitOrderEvent(resp.activityDetail);
      this.navigateToOrderSummary(resp.activityDetail, resp.activityId[0]);
    }
  }

  navigateToOrderSummary(activityDetails : DetailActivity[], activityId? : number){
    this.qrCartService.setShowSubmitPopup(true);
    this.qrCartStore.remove(this.qrTokenResponse.linkId);
    this.orderService.qrOrderSummaryPreNavigation(activityDetails);
    if(activityId){
      this.sessionStorageService.setItem("qrActivityId", activityId.toString());
    }
    this.router.navigate(['submitting-order']);
  }

  closeMergeMessageDialog(){
    this.displayMergeMessage = false;
  }

  //#endregion

  navigateToLogin(){
    this.router.navigateByUrl('/login', { state: { isFromCartPage: true } });
    this.previousRouterService.savedPaymentBackUrl(this.router.url);
    this.sessionStorageService.setItem("navigateToPayment", JSON.stringify({ toPayment: true }));
  }

  backToHomePage(){
    this.router.navigate(["home"]);
  }

  closeLoginDialog(){
    this.displayLoginPrompt = false;

    if(this.sessionStorageService.getItem('appliedPromoCode')) {
      this.sessionStorageService.removeItem('appliedPromoCode');
    }
  }

  visibleChgLoginDialog(visible: any) {
    if(!visible && this.sessionStorageService.getItem('appliedPromoCode')) {
      this.sessionStorageService.removeItem('appliedPromoCode');
    }
  }

  checkAttentionMessage(storeId? : number){
    this.attentionOrders = [];
    let orders: OrderH[] = [];

    if(storeId){
      orders = this.orderH? this.orderH.filter(val => val.storeId == storeId && val.attentionMessages.length != 0) : [];
    } else {
      orders = this.orderH? this.orderH.filter(val => val.attentionMessages.length != 0 && val.isSelect) : [];
    }

    orders.forEach((order: OrderH) => {
      let index = order.attentionMessages.findIndex((msg: AttentionMessage) => msg.messageCode === 'Invalid Promotion');

      if(index >= 0) {
        order.attentionMessages.splice(index, 1);
        this.invalidPromo = true;
      }

      if((index >= 0 && order.attentionMessages.length > 1) || (index < 0 && order.attentionMessages.length > 0)) {
       order.attentionMessages.forEach(item => {
          if(item.messageCode == ErrorCode.RequiredTableNo_400) {
            this.displayScanTableNo = true;
            this.displayAttentionDialog = false;
          }else if(item.messageCode != ErrorCode.OrderReqLogin_401){
          this.attentionOrders.push(order);
          }
        });
      }
    });

    if(this.attentionOrders.length != 0){
      this.displayAttentionDialog = true;
      this.displayPromoDialog = false;
    }
    else{
      this.displayAttentionDialog = false;
    }
  }

  async selectAllStore(){
    let isAllSelected = await this.checkIfAllSelect(this.cartModel);

    if(isAllSelected){
      this.cartModel.orderHs.forEach(val => {
        val.isSelect = false;
        val.orderData.vouchers = [];
      })

      await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
    }
    else{
      try{
        // manual loading screen init
        this.loaderService.isManualLoad$.next(true);
        this.loaderService.startLoading();

        forkJoin(this.cartModel.orderHs.map((orderH, index) => {
          let orderType = this.storeService.getOrderType(orderH.orderData.sourceFlag);

          let orderC = orderH.orderData.orderC;
          return this.storeService.getStoreMenuData(orderH.storeId, orderType, this.channelId, orderH.orderData.orderC.reqTime,
            this.channelTag, orderH.orderData.orderC.longitude, orderH.orderData.orderC.latitude, true,
            this.qrTokenResponse ? this.qrTokenResponse.linkId : null, orderC.extMembershipCode ? orderC.extMembershipCode : orderC.membershipCode,
            orderC.extMembershipNo ? orderC.extMembershipNo : orderC.membershipNo, false);
        })).subscribe(async storeDatas => {
          let successIndex = await this.multiStoreIndex(storeDatas);
          this.cartModel.orderHs = await this.updateOrderIsSelect(this.cartModel.orderHs, successIndex, storeDatas);

          await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);

          // stop loading after recalculate is complete
          this.loaderService.stopLoading();
          this.loaderService.isManualLoad$.next(false);

          isAllSelected = this.cartModel.orderHs.every(val => val.isSelect == true);
          if(isAllSelected){
            this.checkAttentionMessage();
          }
        },
        error =>{
          // stop loading screen and change isManual to false if reached error
          this.loaderService.stopLoading();
          this.loaderService.isManualLoad$.next(false);
        })
      }catch(error){
        this.loaderService.stopLoading();
        this.loaderService.isManualLoad$.next(false);
      }

    }
  }

  async checkIfAllSelect(cartModel : CartModel){
    let noOfIsSelect = cartModel.orderHs.filter(val => val.isSelect == true).length;

    if(noOfIsSelect == cartModel.orderHs.length && noOfIsSelect != 0){
      return true;
    }
    else{
      return false;
    }
  }

  getCorrectSourceFlag(sourceFlag : string){
    switch(sourceFlag){
      case OrderSourceFlag.WebDelivery:
      case OrderSourceFlag.AppDelivery:
      {
        return OrderSourceFlag.WebDelivery;
      }
      case OrderSourceFlag.AppPickup:
      case OrderSourceFlag.WebPickup:{
        return OrderSourceFlag.WebPickup;
      }
      case OrderSourceFlag.AppDineIn:
      case OrderSourceFlag.WebDineIn:{
        return OrderSourceFlag.WebDineIn;
      }
      case OrderSourceFlag.WebQrDineIn:
      case OrderSourceFlag.AppQrDineIn:{
        return OrderSourceFlag.WebQrDineIn;
      }
      case OrderSourceFlag.WebQrTakeaway:
      case OrderSourceFlag.AppQrTakeaway:{
        return OrderSourceFlag.WebQrTakeaway;
      }
      default:
        return OrderSourceFlag.WebDelivery;
    }
  }

  trackByFn(index, item) {
    return index;
  }

 async closeAttentionDialog(){
    let isItemBlockedNotSync = this.attentionOrders.find(item => item.attentionMessages.find(val => val.messageCode == ErrorCode.ItemBlockedNotSync_204));

    if(isItemBlockedNotSync) {
      await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);
      this.checkIsCartNull();
    }else {
      this.displayAttentionDialog = false;
    }

  }

  openAttentionDialog(){
    this.checkAttentionMessage();
    this.displayAttentionDialog = true;
  }

  checkIfStoreExists(){
    if(this.cartModel.orderHs.length == 1 && this.cartModel){
      let store = this.storeData? this.storeData.find(val => val.id == this.cartModel.orderHs[0].storeId) : null;
      if(!store){
        let orderType = this.storeService.getOrderType(this.cartModel.orderHs[0].orderData.sourceFlag);
        let longitude = this.cartModel.orderHs[0].orderData.orderC.longitude;
        let latitude = this.cartModel.orderHs[0].orderData.orderC.latitude;
        this.storeService.getStoreMenuData(this.cartModel.orderHs[0].storeId, orderType, this.channelId, this.cartModel.orderHs[0].orderData.orderC.reqTime, this.channelTag, longitude, latitude, true);
      }
    }
  }

  // voucher & promo
  async getApplicableVoucher(storeId: number, membershipCode? : string, membershipNo? : string) {
    this.applicableVoucher = <VoucherCatgWithVoucherList[]> await this.rewardService.getApplicableVoucher(storeId, this.channelId, membershipCode, membershipNo);

    if(this.applicableVoucher) {
      this.applicableVoucher = this.applicableVoucher.filter((applicableVoucher: VoucherCatgWithVoucherList) => applicableVoucher.voucherList.length > 0);
    }
  }

  selectVoucher(selected: boolean, voucher: VoucherExtra, type: VoucherCardType) {
    let selectedVoucherEl = document.getElementById("select-voucher-" + voucher.voucherNo) as HTMLInputElement;
    let selectedCardEl = selectedVoucherEl.closest(".reward-card-wrapper");
    let cardsEl = Array.from(document.getElementsByName("select-voucher-" + voucher.vchCatgId));

    // Handle reward card selected ui
    this.toastService.closeToast();

    if(selected && type === this.cardTypes.singleSelect) {
      let index: number = this.appliedVoucher.findIndex((appliedVoucher: VoucherExtra) => voucher.vchCatgId == appliedVoucher.vchCatgId);

      if(index >= 0) {
        this.appliedVoucher.splice(index, 1);
      }

      for(let i = 0; i < cardsEl.length; i++) {
        let cardEl = cardsEl[i] as HTMLInputElement;

        if(cardEl !== selectedVoucherEl && cardEl.checked){
          cardEl.checked = false;
        }

        cardEl.closest(".reward-card-wrapper").classList.remove("active");
      }
    }

    let index: number = this.appliedVoucher.findIndex((appliedVoucher: VoucherExtra) => voucher.voucherNo == appliedVoucher.voucherNo);

    // Reward card selected
    if(selected && selectedCardEl && index < 0) {
      selectedCardEl.classList.add("active");

      let selectedVoucher: OrderVoucher = {
        voucherId: voucher.voucherId,
        vchTypId: voucher.vchTypId,
        voucherNo: voucher.voucherNo,
        vchCatgId: voucher.vchCatgId,
        vchTypDesc: voucher.vchTypDesc? voucher.vchTypDesc: "",
        vchTypTitle: voucher.vchTypTitle? voucher.vchTypTitle: "",
        promotionId: voucher.promotionId? voucher.promotionId: null
      };

      this.appliedVoucher.push(selectedVoucher);
    }

    // Reward card deselected
    if(!selected && selectedCardEl && index >= 0) {
      selectedCardEl.classList.remove("active");
      this.appliedVoucher.splice(index, 1);
    }

    selectedVoucherEl.checked = selected;
  }

  removeReward(storeId: number, voucherNo: string) {
    this.cartModel.orderHs.forEach(async (order: OrderH) => {
      if(order.storeId === storeId && voucherNo) {
        let index = order.orderData.vouchers.findIndex((voucher: OrderVoucher) => voucher.voucherNo == voucherNo);

        if(index >= 0) {
          order.orderData.vouchers.splice(index, 1);
          await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, true);

          let toastData = {} as ToastData;
          toastData.icon = "oda-check-alt";
          toastData.iconColor = "#8CD600";
          toastData.message = "rewards.voucher.removed";
          toastData.name = "rewardAppliedToast";
          this.toastService.show(toastData);
        }
      }
    });
  }

  removePromo(storeId: number, promotionCode: string) {
    this.cartModel.orderHs.forEach(async (order: OrderH) => {
      if(order.storeId === storeId && promotionCode) {
        let index = order.orderData.promotions.findIndex((promo: OrderPromotion) => promo.promotionCode === promotionCode);

        if(index >= 0) {
          order.orderData.promotions.splice(index, 1);
          await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);

          let toastData = {} as ToastData;
          toastData.icon = "oda-check-alt";
          toastData.iconColor = "#8CD600";
          toastData.message = "rewards.promo.removed";
          toastData.name = "rewardAppliedToast";
          this.toastService.show(toastData);
        }
      }
    });
  }

  showSuccessToast(successMsg: string, msgData?: {}) {
    let toastData = {} as ToastData;
    toastData.icon = "oda-check-alt";
    toastData.iconColor = "#8CD600";
    toastData.message = successMsg;
    toastData.messageData = msgData;
    toastData.name = "rewardPromoToast";
    this.toastService.show(toastData);
  }

  async onApplyPromo(value: any){
    this.invalidPromo = false;
    this.searchPromoCode = value? value: "";

    if(this.promoStoreId && this.searchPromoCode) {
      let orderInd: number = this.cartModel.orderHs.findIndex((order: OrderH) => order.storeId === this.promoStoreId);
      let orderH: OrderH = orderInd >= 0? this.cartModel.orderHs[orderInd]: null;
      let promoIsAppliedInd: number = orderH? orderH.orderData.promotions.findIndex((promo: OrderPromotion) => promo.promotionCode === this.searchPromoCode): null;

      if(promoIsAppliedInd < 0) {
        this.cartModel.orderHs[orderInd].orderData.promotions.push({
          promotionCode: this.searchPromoCode
        });
      }

      let resp = await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse, this.appLinkTokenResponse);

      if(resp instanceof HttpErrorResponse && resp.error.errorCode == ErrorCode.LoginRequiredToUsePromo_400) {
        this.displayPromoDialog = false;
        this.displayLoginPrompt = true;

        let data = {storeId: this.cartModel.orderHs[orderInd].storeId, promoCode: this.searchPromoCode};
        this.sessionStorageService.setItem('appliedPromoCode', JSON.stringify(data));

        // remove promo from cart model
        if(this.cartModel?.orderHs[orderInd]?.orderData?.promotions && this.cartModel.orderHs[orderInd].orderData.promotions.length > 0) {
          let promoNeedLoginInd = this.cartModel.orderHs[orderInd].orderData.promotions.findIndex((promo: OrderPromotion) => promo.promotionCode === this.searchPromoCode);

          if(promoNeedLoginInd >= 0) {
            this.cartModel.orderHs[orderInd].orderData.promotions.splice(promoNeedLoginInd, 1);
          }
        }
      } else {
        this.checkAttentionMessage(this.promoStoreId);

        if(!this.displayAttentionDialog && !this.invalidPromo) {
          this.displayPromoDialog = false;
          let toastMsg: string = "rewards.applied.promo";
          this.showSuccessToast(toastMsg);
          this.searchPromoCode = "";
        }
      }
    }
  }

  onUrlCopied(e) {
    this.isCopied = true;

    setTimeout(() => {
      this.isCopied = false;
    }, 1500);
  }

  async onClickVerifyPhoneNo() {
    await this.otpService.onSendOTP(this.mobileNo, PageName.VerifyMobilePage);
    this.routingService.navigate(PageName.OTPPage, {pageName: PageName.VerifyMobilePage});
  }

  backToPreviousPage(){
    this.location.back();
  }

  displayMaximumQuantityDialog(){
    if(!this.displayMaximumMessage){
      this.displayMaximumMessage = true;
      this.timerId = setTimeout(() => {
        this.displayMaximumMessage = false;
      }, 3000);
    }
  }

  onMobileMaximumHide(){
    clearTimeout(this.timerId);
    this.displayMaximumMessage = false;
  }

  //#region cart not found handler
  closeOrderPaidDialog(){
    this.displayOrderPaid = false;
  }

  async onOrderPaidClose(){
    await this.cartService.refreshCustomerCart();
  }
  //#endregion

  async getStoreIfOneOrder(cartModel : CartModel){
    if(cartModel?.orderHs.length == 1 && cartModel){
      let storeData = this.storeService.getEntityById(cartModel.orderHs[0].storeId);

      if(!storeData || (storeData && !storeData?.storeResponse)){
        let orderType = this.storeService.getOrderType(cartModel.orderHs[0].orderData.sourceFlag);

        let longitude : number;
        let latitude : number;
        if(!this.qrTokenResponse){
          longitude = cartModel.orderHs[0].orderData.orderC.longitude;
          latitude = cartModel.orderHs[0].orderData.orderC.latitude;
        }
        else{
          longitude = 0;
          latitude = 0;
        }

        let orderC = cartModel.orderHs[0].orderData.orderC;

        await this.storeService.getStoreMenuData(cartModel.orderHs[0].storeId, orderType, this.channelId, cartModel.orderHs[0].orderData.orderC.reqTime,
          this.channelTag, longitude, latitude, true, this.qrTokenResponse ? this.qrTokenResponse.linkId : null,
          orderC.extMembershipCode ? orderC.extMembershipCode : orderC.membershipCode, orderC.extMembershipNo ? orderC.extMembershipNo : orderC.membershipNo);
      }
    }
    else if(cartModel?.orderHs.length > 1 && cartModel){
      let selectedIndex = cartModel.orderHs.findIndex(orderH => orderH.isSelect);

      if(selectedIndex != -1){
        let storeData = this.storeService.getEntityById(cartModel.orderHs[selectedIndex].storeId);

        if(!storeData || (storeData && !storeData?.storeResponse)){
          let orderType = this.storeService.getOrderType(cartModel.orderHs[selectedIndex].orderData.sourceFlag);
          let orderC = cartModel.orderHs[selectedIndex].orderData.orderC;

          await this.storeService.getStoreMenuData(cartModel.orderHs[selectedIndex].storeId, orderType, this.channelId,
            orderC.reqTime, this.channelTag, orderC.longitude,
            orderC.latitude, true, this.qrTokenResponse ? this.qrTokenResponse.linkId : null,
            orderC.extMembershipCode ? orderC.extMembershipCode : orderC.membershipCode,
            orderC.extMembershipNo ? orderC.extMembershipNo : orderC.membershipNo);
        }
      }
    }
  }

  backToQueue(){
    this.queueService.navigateToQueueInfo(this.queueResponse.storeId, this.queueResponse.locShortDesc, this.queueResponse.rsvTokenId, false);
  }

  cancelRemove(){
    this.displayRemoveQueueCart = false;
    this.queueService.closeRemoveQueueCart();
  }

  // remove item logic for when queue status is called
  async removeItem(){
    this.displayRemoveQueueCart = false;
    this.queueService.closeRemoveQueueCart();
    this.cartModel.cartTranIds = [];
    this.cartModel.orderHs = [];

    await this.queueService.acknowledgeQueueInit(this.channelId, this.queueResponse.contactId, this.queueResponse.storeId, false);
    await this.queueService.navigateToQueueInfo(this.queueResponse.storeId, this.queueResponse.locShortDesc, this.queueResponse.rsvTokenId);
    await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse);
  }

  async queueConfirmOrder(){
    let currentCart = this.cartModel.orderHs.find(orderH => orderH.storeId == this.queueResponse.storeId);

    if(currentCart){
      let resp = await this.queueService.acknowledgeQueueInit(this.channelId, this.queueResponse.contactId, this.queueResponse.storeId, true, currentCart);
      if(resp instanceof HttpErrorResponse){
        this.displayQueueError = true;
      }
      else{
        await this.queueService.navigateToQueueInfo(this.queueResponse.storeId, this.queueResponse.locShortDesc, this.queueResponse.rsvTokenId);
        this.queueCartService.removeCart();
      }
    }
  }

  closeErrorMessage(){
    this.displayQueueError = false;
  }

  // manual cart back
  manualCartBack(){
    if(this.queueResponse && this.queueResponse.statusFlag == QueueStatusFlag.Active){
      this.location.back();
    }
    else if(this.queueResponse && this.queueResponse.statusFlag == QueueStatusFlag.Called){
      this.queueService.navigateToQueueInfo(this.queueResponse.storeId, this.queueResponse.locShortDesc, this.queueResponse.rsvTokenId);
    }
    else{
      let currentRoute = this.previousRouterService.getCartBackUrl();
      this.previousRouterService.removeCartBackUrl();
      this.router.navigateByUrl(currentRoute);
    }
  }

  closeSubmitPhoneNoDialog(){
    this.displaySubmitPhoneNo = false;
  }

  async onSubmitMobileNumber(phoneValue : ChangeData){
    this.displaySubmitPhoneNo = false;
    this.sessionStorageService.setItem("isLoginFromCart", JSON.stringify({ isLoginFromCart: true }));

    // save phone number data into local storage
    // localStorage.setItem("submittedPhoneNumber", JSON.stringify(phoneValue));
    this.storageService.saveSubmittedNumber(phoneValue);

    // update cart mobile number & recalculate cart
    await this.updateCartMobileNo(phoneValue);
    await this.cartService.recalculateCartChecking(this.qrTokenResponse, this.cartModel, this.queueResponse);

    let phoneNo = this.formatPhoneNo(phoneValue);
    let dialCode = phoneValue.dialCode.replace('+', '');

    let resp = await this.userService.getCustomerProfileStatus(dialCode + phoneNo, "");

    if(resp instanceof TimeoutError){
      // handle timeout error
    }
    else if(resp instanceof HttpErrorResponse){
      // go to not found page
      if(resp?.error?.errorCode == ErrorCode.CustomerNotFound_404){
        // save navigate to payment page flag in session
        this.sessionStorageService.setItem("navigateToPayment", JSON.stringify({toPayment: true}));

        // save order payment route to previous route to navigate to order payment page when continue as guest
        let previousRoute = {} as PreviousRoute;
        previousRoute.routeName = "/" + RouteName.OrderPayment;
        this.previousRouterService.savedPreviousRoute(previousRoute);
        // go to account detected page
        this.toKeepRoute = true;
        this.cartService.setKeepCartRouteFlag(true);
        this.router.navigate(["are-you-interested"]);
      }
    }
    else{
      // save customer profile status to user service
      this.userService.setCustomerProfileStatus(resp);

      // save navigate to payment page flag in session
      this.sessionStorageService.setItem("navigateToPayment", JSON.stringify({toPayment: true}));

      // save order payment route to previous route to navigate to order payment page when continue as guest
      let previousRoute = {} as PreviousRoute;
      previousRoute.routeName = "/" + RouteName.OrderPayment;
      this.previousRouterService.savedPreviousRoute(previousRoute);

      // go to password or otp login page
      this.toKeepRoute = true;
      this.cartService.setKeepCartRouteFlag(true);
      await this.authService.loginDataInit(phoneValue);
    }
  }

  // update selected cart's mobile number
  async updateCartMobileNo(phoneValue : ChangeData) {
    let phoneNo = this.formatPhoneNo(phoneValue);
    let dialCode = phoneValue.dialCode.replace('+', '');

    this.cartModel = await this.cartService.updateSelectedCartMobileNumber(this.cartModel, dialCode + phoneNo);
  }

  private formatPhoneNo(phoneObj: ChangeData) {
      return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

  async updateMembership(cartModel : CartModel){
    let selectedIndex = cartModel.orderHs.findIndex(orderH => orderH.isSelect);

    let selectedStore : number = -1;
    if(this.storeData && this.storeData.length > 0 && selectedIndex != -1){
      selectedStore = this.storeData.findIndex(store => store.id == cartModel.orderHs[selectedIndex].storeId);
    }

    if(selectedStore != -1){
      cartModel.orderHs[selectedIndex] = await this.cartService.updateMembershipInit(cartModel.orderHs[selectedIndex],
        this.storeData[selectedStore].merchantMemberships, this.appLinkTokenResponse)
    }

    return cartModel;
  }

  async multiStoreIndex(storeData : any[]){
    let successIndex = [];
    if(storeData && storeData.length > 0){
      for(let i = 0; i < storeData.length; i++){
        if(!(storeData[i] instanceof HttpErrorResponse) && !(storeData[i] instanceof TimeoutError) && storeData[i]){
          successIndex.push(i);
        }
      }
    }
    return successIndex;
  }

  async updateOrderIsSelect(orderH : OrderH[], successIndexs : number[], storeData : any[]){
    for await(let index of successIndexs){
      orderH[index].isSelect = !orderH[index].orderData.tableNo ? true : false;
      orderH[index] = await this.cartService.updateMembershipInit(orderH[index], storeData[index].MerchantMemberships, this.appLinkTokenResponse);
    }

    return orderH;
  }

  constructInvalidQtySentence(messageData: string): { itemDesc: string, balance: string } {
    const parsedData = JSON.parse(messageData);
    const itemDesc = parsedData.Desc;
    const balance = parsedData.Balance;

    return { itemDesc, balance };
  }

  onCloseOrScanTable(event? : any){
    this.displayScanTableNo = false;
    this.sessionStorageService.removeItem("requiredTableNo");
    if(event == 'scan'){
      this.qrScannerService.show(false);
    }
  }

  async checkIsCartNull(){
    if(
      ((this.cartModel == null || this.orderH.length == 0) && !this.qrTokenResponse) ||
    (((this.cartModel == null || this.orderH.length == 0) && this.submittedOrder && this.submittedOrder?.length == 0) && this.qrTokenResponse)
    ) {
      console.log('ddd')
      if(this.qrTokenResponse){
        console.log('1')
        let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.qrTokenResponse.locDesc);
        this.router.navigate(['store', this.qrTokenResponse.storeId, locDescWithoutSpaces]);
      }
      else if(this.queueResponse){
        console.log('2')
        let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.queueResponse.locShortDesc);
        this.router.navigate(['store', this.queueResponse.storeId, locDescWithoutSpaces], { replaceUrl: true });
      }
      else if(this.appLinkTokenResponse){
        console.log('3')
        let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.appLinkTokenResponse.locDesc);
        this.router.navigate(['store', this.appLinkTokenResponse.storeId, locDescWithoutSpaces], { replaceUrl: true });
      }else {
        console.log('4')
        let fromStorePageData = this.cartService.getFromStoreFlag();

    if(fromStorePageData && fromStorePageData?.storeId){
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(fromStorePageData.storeTag);
      this.router.navigate(['store', fromStorePageData.storeId, locDescWithoutSpaces], { replaceUrl: true });
      this.cartService.removeIsFromStoreFlag();
    }
    else{
      this.routingService.navigate(PageName.HomePage, this.channelData);
    }
      }
    }
  }
}

export interface CartStoreItem{
  orderH : OrderH,
  // store : Store
  store : StoreMenuResponseExtra
}
