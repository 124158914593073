import { Injectable } from '@angular/core';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { HMacObj } from '../models/CustomRequest';
import { v4 } from 'uuid';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ChangeData } from 'ngx-intl-tel-input';
import { AbstractControl } from '@angular/forms';
import { Params, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { parsePhoneNumberFromString, parsePhoneNumber } from 'libphonenumber-js';

@Injectable({ providedIn: 'root' })
export class UtilsService {

  emailPattern: string = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$";

  constructor() {
  }

  createHttpHeaderHMac(hMacObj: HMacObj) {
    const hmac = hMacObj;
    const unixTimeStmp = this.createUnixTimeStamp(hmac.date);
    return this.createHMac(hmac.httpmethod, hmac.requestpath, hmac.queryParams ? this.createHttpQueryString(hmac.queryParams) : '', unixTimeStmp, hmac.guid, hmac.body);
  }

  createHttpQueryString(obj: object) {
    return Object.entries(obj).map(dd => {
      return dd[1] !== undefined ? dd[0] + '=' + encodeURIComponent(dd[1]) : undefined
    }).filter(data => data !== undefined).join('&');
  }

  createUUID() {
    return v4();
  }

  formatSrvDateStr(date: Date) {
    const dt = _.clone(date);
    return `${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${("0" + dt.getDate()).slice(-2)}`
  }

  formatToDateObj(dateStr: string) {
    let oDateTime = null;
    const dtStr = _.clone(dateStr);

    const s = dtStr.split("T"),
      a = s[0],
      ab = a.split('-').map(dd => Number(dd));

    let b, bb, timeZoneOps, tzHr, tzMins, curTimeOffset, rsMins;
    if (s[1].includes('+') || s[1].includes('-')) {
      if (s[1].includes('+')) {
        timeZoneOps = '+';
        b = s[1].split('+');
      } else if (s[1].includes('-')) {
        timeZoneOps = '-';
        b = s[1].split('-');
      }
      if (!b) {
        return;
      }
      bb = b[0].split(':').map(dd => Number(dd));
      tzHr = b[1].substr(0, 2);
      tzMins = b[1].substr(2, 2);

      oDateTime = new Date(ab[0], ab[1] - 1, ab[2], bb[0], bb[1], bb[2]);
      curTimeOffset = oDateTime.getTimezoneOffset();

      if (timeZoneOps === '+') {
        rsMins = oDateTime.getMinutes() + curTimeOffset + ((Number(tzHr) * 60) + Number(tzMins));
      } else if (timeZoneOps === '-') {
        rsMins = oDateTime.getMinutes() + curTimeOffset - ((Number(tzHr) * 60) + Number(tzMins));
      }
      oDateTime = new Date(oDateTime.getFullYear(), oDateTime.getMonth(), oDateTime.getDate(), oDateTime.getHours(), rsMins, oDateTime.getSeconds());
    } else {
      b = s[1];
      bb = b.split(':').map(dd => Number(dd));
      oDateTime = new Date(ab[0], ab[1] - 1, ab[2], bb[0], bb[1], bb[2]);
    }

    return oDateTime;
  }

  formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

  createOTPPrefix() {
    return this.createRandomStr(4);
  }

  removeProp(obj: any, propToDelete: any) {
    for (var property in obj) {
      if (typeof obj[property] == "object") {
         let objectToCheck = obj[property];
         delete obj.property
         let newJsonData= this.removeProp(obj[property], propToDelete);
         obj[property]= newJsonData
      } else {
          if (property === propToDelete) {
            delete obj[property];
          }
        }
    }
    return obj
  }

  // check if url contain 'odaring'
  async checkUrlValidity(url : string){
    // 1. create URL object
    let scannedUrl = new URL(url);

    // 2. check if url hsotname is localhost if it is then return true
    // for testing/development purpose
    if(scannedUrl.hostname == ('localhost')){
      return true;
    }

    // 3. check if url contains "odaring"
    let containOdaring = scannedUrl.hostname.includes("odaring");
    return containOdaring ? true : false;
  }

  // lowercase url search param key by accepting UrlSearchParams object
  lowerCaseSearchParam(params : any){
    let lowerCaseParam = new URLSearchParams();
    for(const [name, value] of params){
      lowerCaseParam.append(name.toLowerCase(), value);
    }

    return lowerCaseParam;
  }

  // lowercase url param key by accepting router queryParam objects
  toLowerQueryChar(params : Params){
    const lowerKeyParams : Params = {};
    for(const key in params){
      lowerKeyParams[key.toLowerCase()] = params[key];
    }

    return lowerKeyParams;
  }

  async parseGivenPhoneNumber(phoneNo : string){
    let parsedPhoneData = parsePhoneNumber(phoneNo);
    return parsedPhoneData ? parsedPhoneData : null;
  }

  private createHMac(httpmethod: HttpMethod, requestpath: string, querystring: string, unixtimestamp: number, guid: string, body: any) {
    let hmacString = `${httpmethod}${requestpath}${querystring ? "?" + querystring : ""}${unixtimestamp}${guid}${body ? JSON.stringify(body) : ""}`;
    let hmacSha256 = CryptoJS.HmacSHA256(hmacString, environment.secretKey);
    let hmacValue = CryptoJS.enc.Hex.stringify(hmacSha256) + `:${unixtimestamp}:${guid}`;
    return hmacValue;
  }

  private createUnixTimeStamp(date: Date) {
    return Math.trunc(date.getTime() / 1000);
  }

  private createRandomStr(charlength: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; // abcdefghijklmnopqrstuvwxyz0123456789
    const charactersLength = characters.length;
    for (let i = 0; i < charlength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  convertStringToObject(inputString: string): any {
    const keyValuePairs = inputString.split('&'); // Split by '&' to get individual key-value pairs
    const obj = {};
    for (let index = 0; index < keyValuePairs.length; index++) {
      const [key, value] = keyValuePairs[index].split('=');
      obj[key] = value;
    }
    return this.toLowerQueryChar(obj);
  }

  async phoneNoValidation(mobileNo: any){
    let isInvalidMobileNo: boolean = false;
    const phoneNo = parsePhoneNumberFromString(mobileNo);
    if(phoneNo && phoneNo.isValid()){
      isInvalidMobileNo = false;
    }else{
      isInvalidMobileNo = true;
    }
    return isInvalidMobileNo;
  }
}

export function encodeBase64(data: string) {
  return btoa(data);
}

export function decodeBase64(data: string) {
  return atob(data);
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (control: AbstractControl) => {
      const input = control.get(controlName);
      const matchingInput = control.get(matchingControlName);

      if (input === null || matchingInput === null) {
        return null;
    }

      if (matchingInput?.errors && !matchingInput.errors.mustMatch) {
          // return if another validator has already found an error on the matchingInput
          return null;
      }

      // set error on matchingControl if validation fails
      if (input.value !== matchingInput.value) {
          matchingInput.setErrors({ mustMatch: true });
          return ({ mustMatch: true });
      } else {
          matchingInput.setErrors(null);
          return null;
      }
  }
}
