import { Injectable } from '@angular/core';
import { PreviousRoute } from '../models/PreviousRoute';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

  previousRoute : PreviousRoute;

  constructor(
    private sessionStorageService: SessionStorageService
  ) {}

  savedPreviousRoute(url : PreviousRoute){
    this.sessionStorageService.setItem('previousRoute', JSON.stringify(url));
  }

  getPreviousRoute(){
    let routeString = this.sessionStorageService.getItem('previousRoute');
    this.previousRoute = JSON.parse(routeString);

    if(this.previousRoute){
      return this.previousRoute;
    }
    else{
      this.previousRoute = {} as PreviousRoute;
      this.previousRoute.routeName = "/home";
      return this.previousRoute;
    }
  }

  removePreviousRoute(){
    this.previousRoute = null;
    if(this.sessionStorageService.getItem('previousRoute')){
      this.sessionStorageService.removeItem('previousRoute')
    }
  }

  //#region payment back url
  savedPaymentBackUrl(url : string){
    this.sessionStorageService.setItem("paymentBack", url);
  }

  getPaymentBackUrl(){
    let route = this.sessionStorageService.getItem("paymentBack");
    return route ? route : '/home';
  }

  removePaymentBackUrl(){
    this.sessionStorageService.removeItem("paymentBack");
  }
  //#endregion

  //#region cart back url
  saveCartBackUrl(url : string){
    this.sessionStorageService.setItem("cartBack", url);
  }

  getCartBackUrl(){
    let currentRoute = this.sessionStorageService.getItem("cartBack");
    return currentRoute ? currentRoute : '/home';
  }

  removeCartBackUrl(){
    this.sessionStorageService.removeItem("cartBack");
  }
  //#endregion

  //#region news back url
  setPreviousNewsRoute(url: string) {
    this.sessionStorageService.setItem('previousNewsRoute', url);
  }

  getPreviousNewsRoute() {
    let routeString = this.sessionStorageService.getItem('previousNewsRoute');
    return routeString;
  }

  removePreviousNewsRoute() {
    this.sessionStorageService.removeItem("previousNewsRoute");
  }
  //#endregion
}
