export enum SectionDataType {
    PredefinedStores = 'PredefinedStores', 
    ChannelStores = 'ChannelStores', 
    InfoPanel = 'InfoPanel', 
    Shortcut = 'Shortcut', 
    OrderHistory = 'OrderHistory', 
    StoreProducts = 'StoreProducts',
    SplashCarousell = 'SplashCarousell'
}

