import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-acc-success',
  templateUrl: './delete-acc-success.component.html',
  styleUrls: ['./delete-acc-success.component.scss']
})
export class DeleteAccSuccessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    setTimeout(() => {
      this.router.navigateByUrl('/login');  
    }, 3000);
  }

}
