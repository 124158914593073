import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';

@Pipe({
  name: 'orderTypeIcon'
})
export class OrderTypeIconPipe implements PipeTransform {

  transform(value: string): string {
    switch(value){
      case OrderSourceFlag.WebDelivery:
      case OrderSourceFlag.AppDelivery:
      case OrderTypeFlag.Delivery:{
        return "oda-delivery";
      }
      case OrderSourceFlag.WebPickup:
      case OrderSourceFlag.AppPickup:
      case OrderTypeFlag.Pickup:{
        return "oda-bag";
      }
      case OrderSourceFlag.WebDineIn:
      case OrderSourceFlag.AppDineIn:
      case OrderTypeFlag.DineIn:{
        return "oda-dinein";
      }
      case OrderSourceFlag.WebQrDineIn:
      case OrderSourceFlag.AppQrDineIn:
      case OrderTypeFlag.QRDineIn:{
        return "oda-qr-dinein";
      }
      case OrderSourceFlag.WebQrTakeaway:
      case OrderSourceFlag.AppQrTakeaway:
      case OrderTypeFlag.QRTakeaway:{
        return "oda-qr-takeaway";
      }
      default:{
        return "";
      }
    }
  }

}
