import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeGuardGuard implements CanActivate {

  constructor(
    public router: Router,
  ) { }

  canActivate(): boolean {
    const hostname = window.location.hostname;
    let firstHostName = hostname.split(".")[0];

    if (firstHostName === 'www') {
      firstHostName = hostname.split(".")[1];
    }

    if(environment.hostName == "localhost"){
      firstHostName = "localhost";
    }

    // if it's sub domain not allow navigate to the landing page, navigate to home page.
    if (firstHostName !== environment.hostName) {
      this.router.navigate(['/home']);
      return false;
    }

    return true;
  }

}
