import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CompressionService {
  private new_zip = new JSZip();

  constructor() { }

  async getMultipartFile(dt : any){
    var boundaryCode = '';
    var contentTypeHeaderValue = dt.headers.get('Content-Type');
    var contentTypeArray = contentTypeHeaderValue.split(';');
    var boundary = contentTypeArray[1].split('boundary=')[1];
    boundaryCode = boundary.substring(1, boundary.length - 1);

    var bufferToExt = new Uint8Array(dt.body);
    var parts = this.parseMultipart(bufferToExt, boundaryCode);

    let combineData = {} as any;

    for (let i = 0; i < parts.length; i++) {
      let part = parts[i];

      // let blob = new Blob([part.data], {
      //   type: 'application/zip'
      // });
      // let url = window.URL.createObjectURL(blob);
      // window.open(url);

      let splittedStr = part.type.split(';');
      let filename = splittedStr[1].split("=")[1];

      if(splittedStr[0] == "application/json"){

        let jsonBytesToString = this.arrayBufferToBase64(part.data);
        let jsonFromString = JSON.parse(jsonBytesToString);

        Object.defineProperty(combineData, filename,
          {value: jsonFromString, writable: true, enumerable: true, configurable: true});
      }
      else if(splittedStr[0] == "attachment"){
        combineData = await JSZip.loadAsync(part.data)
        .then(function (zip) {
          var fileName = '';
          zip.forEach(function (relativePath, file) {
            fileName = relativePath;
          });
          return zip.file(fileName).async("string");

        }).then(function (text) {
          var data = JSON.parse(text);

          Object.defineProperty(combineData, filename,
            {value: data, writable: true, enumerable: true, configurable: true});
          return combineData
        });
      }

    }
    return combineData;
  }

  parseMultipart(multipartBodyBuffer : any, boundary : any){
    var process = function (part : any) {
      // will transform this object:
      // { header: 'Content-Disposition: form-data; name="uploads[]"; filename="A.txt"',
      //	 info: 'Content-Type: text/plain',
      //	 part: 'AAAABBBB' }
      // into this one:
      // { filename: 'A.txt', type: 'text/plain', data: <Buffer 41 41 41 41 42 42 42 42> }
      var obj = function (str : any) {
        var k = str.split('=');
        var a = k[0].trim();
        var b = k[1].trim();
        var o = {};
        Object.defineProperty(o, a,
          { value: b, writable: true, enumerable: true, configurable: true })
        return o;
      }
      var header = part.info.split(';');
      var file = obj(header[1]);
      var newBuffer = Uint8Array.from(part.part);
      var contentType = part.info.split(':')[1].trim();
      Object.defineProperty(file, 'type',
        { value: contentType, writable: true, enumerable: true, configurable: true })
      Object.defineProperty(file, 'data',
        { value: newBuffer, writable: true, enumerable: true, configurable: true })
      return file;
    }

    var lastline = '';
    var header = '';
    var info = ''; var state = 0; var buffer = [];
    var allParts = [];

    for (let i = 0; i < multipartBodyBuffer.length; i++) {
      var oneByte = multipartBodyBuffer[i];
      var prevByte = i > 0 ? multipartBodyBuffer[i - 1] : null;
      var newLineDetected = ((oneByte == 0x0a) && (prevByte == 0x0d)) ? true : false;
      var newLineChar = ((oneByte == 0x0a) || (oneByte == 0x0d)) ? true : false;

      if (!newLineChar)
        lastline += String.fromCharCode(oneByte);
      if ((0 == state) && newLineDetected) {
        if (("--" + boundary) == lastline) {
          state = 1;
        }
        lastline = '';
      } else if ((1 == state) && newLineDetected) {
        header = lastline;
        state = 2;
        lastline = '';
      } else if ((2 == state) && newLineDetected) {
        info = lastline;
        state = 3;
        lastline = '';
      } else if ((3 == state) && newLineDetected) {
        state = 4;
        buffer = [];
        lastline = '';
      } else if (4 == state) {
        if (lastline.length > (boundary.length + 4)) lastline = ''; // mem save
        if (((("--" + boundary) == lastline))) {
          var j = buffer.length - lastline.length;
          var part = buffer.slice(0, j - 1);
          var p = { header: header, info: info, part: part };
          allParts.push(process(p));
          buffer = []; lastline = ''; state = 5; header = ''; info = '';
        } else {
          buffer.push(oneByte);
        }
        if (newLineDetected) lastline = '';
      } else if (5 == state) {
        if (newLineDetected)
          state = 1;
      }
    }
    return allParts;
  }

  arrayBufferToBase64( bytes ) {
    let binary = '';
    binary = this.utf8Decode(bytes);
    return binary;
  }

  utf8Decode(bytes) {
    var textDecoder = new TextDecoder("utf-8");
    return textDecoder.decode(bytes);

  }
}
