import { WebLinkTokenResponse } from './../../../core/models/WebLinkTokenResponse';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QueueResponse } from 'src/app/core/models/QueueResponse';

@Component({
  selector: 'app-remove-all-message',
  templateUrl: './remove-all-message.component.html',
  styleUrls: ['./remove-all-message.component.scss']
})
export class RemoveAllMessageComponent implements OnInit {

  @Input() isStorePage : boolean = false;
  @Input() isOrderType : boolean = false;
  @Input() qrTokenResponse : WebLinkTokenResponse = null;
  @Input() queueResponse : QueueResponse = null;

  @Output() removeAll = new EventEmitter();
  @Output() cancelRemove = new EventEmitter();
  @Output() closeDialog = new EventEmitter();

  @Output() orderTypeRemove = new EventEmitter();
  @Output() orderTypeRemain = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickYes(){
    if(this.isOrderType){
      this.orderTypeRemove.emit();
    }
    else{
      this.removeAll.emit();
    }
  }

  onClickNo(){
    if(this.isOrderType){
      this.orderTypeRemain.emit();
    }
    else{
      this.cancelRemove.emit();
    }
  }

  onClickCloseIcon(){
    this.closeDialog.emit();
  }
}
