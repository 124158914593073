<div class="mascot-container" *ngIf="channelId | isShowMascot">
  <img src="assets/mascot/Mascot_phone@2x.webp" alt="odaring mascot">
</div>

<h3 class="message-title">{{"cart.submit.button.desc.2" | translate}}</h3>

<p class="message-description">{{"cart.submit.button.desc.3" | translate : {name : custname} }}</p>

<div class="action-button-container">
  <button class="no-action-btn" (click)="onClickNo()">{{"cart.submit.button.desc.1" | translate}}</button>
  <button class="yes-action-btn" (click)="onClickYes()">{{"button.qr.dinein.combine" | translate}}</button>
</div>
