<div class="empty-dish-container" [ngClass]="{'store-page-height': isStorePage, 'store-background': isStorePage,
'cart-page-height': !isStorePage, 'cart-background': !isStorePage}">
  <div class="store-mascot" *ngIf="isStorePage && (channelId | isShowMascot)">
    <img src="assets/mascot/Mascot_chef-waiting-Grey@2x.webp" alt="odaring mascot"/>
  </div>

  <div class="cart-mascot" *ngIf="isCartPage && (channelId | isShowMascot)">
    <img src="assets/mascot/Mascot_cart-empty-Grey@2x.webp" alt="odaring mascot"/>
  </div>

  <div class="empty-message" *ngIf="isStorePage">{{"merchant.menu.unavailable" | translate}}</div>
  <div class="empty-message" *ngIf="isCartPage && !qrTokenResponse && !queueResponse && !appLinkTokenResponse">
    {{"cart.empty.message" | translate}}
  </div>
  <div class="empty-message" *ngIf="isCartPage && (qrTokenResponse || queueResponse || appLinkTokenResponse)">
    {{"cart.qr.empty" | translate}}
  </div>

  <button [ngClass]="{'cart-button-style': isStorePage != true, 'store-button-style': isStorePage == true}"
    (click)="normalFlowEmptyNavigation()"
    *ngIf="(isCartPage && !qrTokenResponse && !queueResponse && !appLinkTokenResponse) || isStorePage">
    {{"merchant.browse.food.button" | translate}}
  </button>

  <button class="qr-cart-button" *ngIf="isCartPage && (qrTokenResponse || queueResponse || appLinkTokenResponse)" (click)="navigateToStore()">
    {{"button.back.to.store" | translate}}
  </button>
</div>

