
<div class="p-d-flex order-item-container">
  <!--item image-->
  <div class="item_image" *ngIf="thumbnail">
    <img [src]="thumbnail" class="item_image" onerror="this.src='assets/errorMsg_image/imageError.webp'" *ngIf="thumbnail" alt="error image" loading="lazy"/>
    <span class="image-unavailable" *ngIf="item.voidFlag == voidFlag.Unavailable">{{"merchant.card.status.unavailable" | translate}}</span>
  </div>

  <div class="p-d-flex p-flex-column" style="width: 100%;" [style.opacity]="item.voidFlag == voidFlag.Unavailable ? '0.3' : '1'">
    <!--item name-->
    <div class="item-name-container p-d-flex">
      <h1 class="item-name" [innerHtml]="item.lineDesc" [style.margin-right]="isOrderSummaryPage ? '12px' : '0px'"></h1>
      <span class="item-quantity-indicator" *ngIf="isOrderSummaryPage">x{{item.orderQty}}</span>
    </div>

    <!-- qr dine in item info -->
    <div class="item-order-info p-d-flex" *ngIf="isOrderSummaryPage && linkId">
      <span class="item-sourceflag-container">
        <i class="item-icon {{item.sourceFlag | orderTypeIcon}}"></i>
        <p class="item-time-description">{{item.orderDate | date : 'HH:mm'}}</p>
      </span>

      <span class="item-cust-info" *ngIf="item.custName">
        <i class="oda-number-pax"></i>
        <p class="item-cust-name">{{item.custName}}</p>
      </span>
    </div>
    <!--item des-->
    <div class="item-mod-container p-d-flex p-flex-column">
      <div *ngFor="let itemMod of item.modifiers">
        <p class="item-des"  [ngStyle]="{ 'margin-bottom': itemMod.itemBalanceQty || itemMod.itemBalanceQty == 0 ?  '8px': '0px'}">
          x{{itemMod.orderQty}} <span [innerHtml]="itemMod.lineDesc"></span>
          <i class="itemMod-price" *ngIf="itemMod.unitSellPrice > 0"> +{{itemMod.unitSellPrice | currency:currCode:'symbol-narrow'}}</i>
          <span class="itemMod-itemAvailability" *ngIf="(itemMod.itemBalanceQty || itemMod.itemBalanceQty == 0) && item.voidFlag == voidFlag.Available ">{{ 'item.quantity.availability' | translate:{ qtyBalance: itemMod.itemBalanceQty > 99 ? '99+' : itemMod.itemBalanceQty } }}</span>
        </p>
        <div class="p-d-flex p-flex-column" *ngIf="checkHasSubModifiers(itemMod)">
          <div style="margin-left: 10px;" *ngFor="let itemSubMod of itemMod.subModifiers">
            <p class="item-des" [ngStyle]="{ 'margin-bottom': itemSubMod.itemBalanceQty || itemSubMod.itemBalanceQty == 0 ? '8px': '0px', 'margin-top': (itemSubMod.itemBalanceQty || itemSubMod.itemBalanceQty == 0) && !itemMod.itemBalanceQty ? '8px': '0px'}">
              -<span class="item-des" *ngIf="itemSubMod.orderQty > 1"> {{itemSubMod.orderQty}}x</span> {{itemSubMod.lineDesc}}
              <span class="itemMod-price" *ngIf="itemSubMod.unitSellPrice != 0">+{{itemSubMod.unitSellPrice | currency:currCode:'symbol-narrow'}}</span>
              <span class="itemMod-itemAvailability" *ngIf="(itemSubMod.itemBalanceQty || itemSubMod.itemBalanceQty == 0) && item.voidFlag == voidFlag.Available">{{ 'item.quantity.availability' | translate:{ qtyBalance: itemSubMod.itemBalanceQty > 99 ? '99+' : itemSubMod.itemBalanceQty } }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--note-->
    <p class="item_note" *ngIf="item.remarks != null && item.remarks != ''">
      {{'order.item.title.2' | translate}} :
      <span> {{item.remarks}}</span>
    </p>
    <!--price-->
    <div class="price-container">
      <p class="item-price">{{item.netAmt | currency:currCode : 'symbol-narrow'}}</p>
      <p class="slash-price" *ngIf="item.netAmt !== item.grossAmt">{{item.grossAmt | currency:currCode : 'symbol-narrow'}}</p>
    </div>
  </div>

</div>
