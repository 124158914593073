<div class="fullPage-promotionDetail-container" *ngIf="exploreRewardDetail">
  <div class="back-tbn  hidden-sm" (click)="onBackHome()">
    <i class="oda-chevron-left"></i>
    <h1>{{'button.back' | translate}}</h1>
  </div>

  <div class="fullPage-promotionDetail-content">
    <app-reward-detail [exploreReward]="exploreRewardDetail" [customCardType]="customCardTypeDetail"
      [channelId]="channelId" [fullpagePromotion]=" true">
    </app-reward-detail>
  </div>
</div>

<!-- Share Dialog -->
<p-dialog [(visible)]="showShare" [modal]=true [draggable]=false class="dialog-share" *ngIf="showShare">
  <h1 class="title">{{ 'button.share' | translate }}</h1>
  <div class="share-btn">
    <app-social-share [url]="shareUrl" [title]="shareStoreName"
      [description]="shareStoreName? ('share.store.description' | translate: { storeName: shareStoreName }): ''">
    </app-social-share>
  </div>
  <h2 class="sub-title">{{ 'copy.link' | translate}}</h2>
  <div class="url-container">
    <div class="heading-9 url-sub-content">
      <h1 class="heading-9 text-truncate" name="url-content" id="url-content">{{ shareUrl }}</h1>
    </div>
    <div class="copy-btn">
      <ngx-copy-to-clipboard target="#url-content" [success]="onUrlCopied"><i class="oda-copy"></i>
      </ngx-copy-to-clipboard>
    </div>
    <div class="copied-text" *ngIf="isCopied">{{ "rewards.copied" | translate }}</div>
  </div>
</p-dialog>
