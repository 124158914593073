import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { UtilsService} from 'src/app/core/services/utils.service';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { UserService } from 'src/app/core/user/user.service';
import { StoreMode } from 'src/app/core/enums';

import { GetMembershipByLoginRequest } from 'src/app/core/models/LoginRequest';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { ChangeData } from 'ngx-intl-tel-input';
import { StorageService } from 'src/app/shared/services/storage.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-external-membership-login',
  templateUrl: './external-membership-login.component.html',
  styleUrls: ['./external-membership-login.component.scss']
})
export class ExternalMembershipLoginComponent implements OnInit {
  @Input() channelId: number;
  @Input() membershipCode: string;
  @Input() cardImageData : string;
  @Input() logoImageData : string;
  @Input() membershipDesc : string;

  fgroup: UntypedFormGroup = this.fb.group({
    phoneNo: ['', Validators.compose([Validators.required])],
    password: ['', [Validators.required,]],
    rememberAccount: '',
  });

  telInpSetting = DefaultSettingService.telInpSetting;

  hide = true;
  pwErr: HttpErrorResponse = null;

  user$: Subscription;
  isLoggedIn: boolean = false;
  isRememberMe: boolean = true;
  cardImageError: boolean = false;
  loginErrror$: Subscription;
  errorText: string = "";
  showTextError: boolean = false;
  countryCode : string = "MY";
  userMobileNo : string;
  statePhoneNo : ChangeData;
  existingPhoneNo : string = '';
  isInvalid: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private membershipService: MembershipService,
    private storageService : StorageService,
    private utilsService : UtilsService,
    private sessionStorageService: SessionStorageService,
  ) { }

  async ngOnInit() {
    this. user$ = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      if (userData) {
        this.isLoggedIn = true;
        this.userMobileNo = userData.mobileNo;
      } else {
        this.isLoggedIn = false;
        this.userMobileNo = '';
      }
    });

    let phoneInfo = this.storageService.getCachedNumber();
    if(this.isLoggedIn){
      let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + this.userMobileNo);

      this.countryCode = parsedPhoneData.country;
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
    }
    else if(phoneInfo && !this.isLoggedIn){
      this.statePhoneNo = phoneInfo;
      // get mobile number
      this.existingPhoneNo = this.formatPhoneNo(phoneInfo);
      // get dial code e.g 60
      let dialCode = phoneInfo.dialCode.replace('+', '');
      // throw in mobile number with '+' to a library to know its origin
      let parsedPhoneData = await this.utilsService.parseGivenPhoneNumber("+" + dialCode + this.existingPhoneNo);

      this.countryCode = parsedPhoneData.country;
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
    }
    else {
      this.statePhoneNo = null;
      this.existingPhoneNo = '';
      this.countryCode = localStorage.getItem('countryCode') ? localStorage.getItem('countryCode') : 'MY';
      this.getFormControl('phoneNo').setValue(this.existingPhoneNo);
    }


    this.getFormControl('rememberAccount').setValue(this.isRememberMe);

    this.getFormControl('phoneNo').valueChanges.subscribe((dt) => {
      this.isInvalid = false;
    })
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }

  private getFormControl(formControlName: string) {
    return this.fg.controls[formControlName];
  }


  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number ? phoneObj.e164Number.replace(phoneObj.dialCode || '', '') : '';
  }

  remeberExternalMember(event: any) {
    this.isRememberMe = event.target.checked? true: false;

    this.getFormControl('rememberAccount').setValue(this.isRememberMe);
  }

  async onSubmit() {
    let phoneNo = this.getFormControl('phoneNo').value;
    if(phoneNo){
      const mobile = phoneNo && phoneNo.e164Number;
      let isInvalid  = await this.utilsService.phoneNoValidation(mobile);
      if(!isInvalid) {
        this.isInvalid = false;
        this.onLogin();
      }else {
        this.isInvalid = true;
      }
    }
  }

  async onLogin() {
    this.pwErr = null;

    let phoneNo = this.formatPhoneNo(this.getFormControl('phoneNo').value);
    let dialCode = this.getFormControl('phoneNo').value.dialCode.replace('+', '');

    let loginReq = {} as GetMembershipByLoginRequest;
    loginReq.membershipCode = this.membershipCode;
    loginReq.channelId = this.channelId;
    loginReq.loginId = dialCode + phoneNo;
    loginReq.loginValue = this.f.password.value ? this.f.password.value : '';
    loginReq.rememberAccount = this.f.rememberAccount.value ? this.f.rememberAccount.value: '';

    this.sessionStorageService.setItem('externalMembershipRequest', JSON.stringify(loginReq));

    let respDt: any;
    respDt = await this.membershipService.onExternalMember(loginReq);

    this.loginErrror$ = this.membershipService.getExternalLoginErrorData().subscribe((dt:any) => {
      this.errorText = dt && dt.errorText ? dt.errorText : this.errorText;

      if(this.errorText) {
        this.showTextError = true;
      }
    })
  }

  onImgError() {
    this.cardImageError = true
  }

  onResetPhone() {
    this.fg.controls['phoneNo'].reset();
    this.showTextError = false;
  }

  onResetPassword() {
    this.fg.controls['password'].reset();
    this.showTextError = false;
  }


  ngOnDestroy() {
    this.user$?.unsubscribe();
    this.loginErrror$?.unsubscribe();
  }

}
