import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(error => {
        if (error.error && error?.error['errorCode'] === 'SystemMaintenance_400') {
          this.sessionStorageService.setItem('maintenanceMsg', error.error['detail']);
          this.router.navigate(['maintenance']);
        }
        return throwError(error);
    }));
  }
}
