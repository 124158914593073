import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormStage } from 'src/app/account/enums/FormStage';
import { LoginForm } from 'src/app/account/models';
import { ChangeData } from "ngx-intl-tel-input";
import { ErrorObj } from 'src/app/core/models';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { UtilsService} from 'src/app/core/services/utils.service';
import { ConfigService } from 'src/app/config.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {

  @Input() errorObj: ErrorObj = null as any;
  @Input() mobileNo: string;
  @Input() showContGuest: boolean = false;
  @Output() onLogin = new EventEmitter();

  fgroup: UntypedFormGroup = this.fb.group({
    phoneNo: ['', Validators.compose([Validators.required])],
  });

  telInpSetting = DefaultSettingService.telInpSetting;
  submitted = false;
  isInvalid: boolean = false;

  //autofocus
  @ViewChild("mobilefocus") myInputField: ElementRef;
  ngAfterViewInit() {
    if (this.myInputField.nativeElement) {
      this.myInputField.nativeElement.focus();
    }
  }

  constructor(
    private fb: UntypedFormBuilder,
    private configService: ConfigService,
    private routingService: RoutingService,
    private storageService: StorageService,
    private utilService : UtilsService,
    private location: Location,
    private router: Router,
    private previousRouteService: PreviousRouteService,
  ) { }

  async ngOnInit() {
    let phoneData = this.storageService.getCachedNumber();
    if (phoneData) {
      let phoneNo = this.formatPhoneNo(phoneData);
      let dialCode = phoneData.dialCode.replace('+', '');
      let parsedPhoneData = await this.utilService.parseGivenPhoneNumber("+" + dialCode + phoneNo);
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
    }

    if (this.mobileNo) {
      this.getFormControl('phoneNo').setValue(this.mobileNo);
    }

    this.getFormControl('phoneNo').valueChanges.subscribe((dt) => {
      this.isInvalid = false;
    })
  }

  onClickLogin() {
    const loginForm: LoginForm = {
      dialCode: this.getFormControl('phoneNo').value.dialCode.replace('+', ''),
      phoneNo: this.formatPhoneNo(this.getFormControl('phoneNo').value),
      password: '',
      stage: FormStage.None,
      mobileObj: this.getFormControl('phoneNo').value,
      otpCode: '',
    };

    this.onLogin.emit(loginForm);
    this.submitted = true;
  }

  async onSubmit() {
    let phoneNo = this.getFormControl('phoneNo').value;
    if(phoneNo) {
      const mobile = phoneNo && phoneNo.e164Number;
      let isInvalid  = await this.utilService.phoneNoValidation(mobile);
      if(!isInvalid) {
        this.isInvalid = false;
        this.onClickLogin();
      }else {
        this.isInvalid = true;
      }
    }
  }

  continueAsGuest() {
    this.routingService.navigateHomeUrl();
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  onNavigate(dt: string) {
    const originCountryUrl = window.location.origin + this.configService.get('appBaseHref');

    switch (dt) {
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tNc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
    }
  }

  onClickBack(){
    let backRoute = this.previousRouteService.getPaymentBackUrl();
    if(backRoute){
      this.router.navigateByUrl(backRoute);
    }else {
      this.location.back();
    }
  }
}
