import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { Subject } from 'rxjs';

import { StoreMode } from 'src/app/core/enums';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { User } from 'src/app/core/user/user.model';
import { HttpHeaderType } from "src/app/core/enums/HttpHeaderType";
import { CustomRequest, CustomHeader } from "src/app/core/models/CustomRequest";
import { CustomService } from "src/app/core/services/custom.service";
import { ReviewCategoryType } from "src/app/core/enums/ReviewCategoryType";
import { UpdateStoreReviewRequest } from '../models/UpdateStoreReviewRequest';
import { DetailActivity } from 'src/app/home/order/order.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;

  isShow = new Subject<boolean>();

  constructor(private userService: UserService,
    private customService: CustomService) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.accessToken = dt && dt['accessToken']? dt['accessToken']: '';
      this.refreshToken = dt && dt['refreshToken']? dt['accessToken']: '';
      this.curUser = dt? dt : null;
    });
  }

  async getChannelReviewText(channelId: number, reviewCategoryType: ReviewCategoryType) {
    let respDt = null;
    respDt = await this.reqGetChannelReviewText(this.accessToken, channelId, reviewCategoryType);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async updateStoreReview(reviewData: UpdateStoreReviewRequest){
    let respDt = null;
    respDt = await this.reqUpdateStoreReview(this.accessToken, reviewData);
    if(!(respDt instanceof(HttpErrorResponse))){
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getStoreReviewData(skip : number, take : number, sortRating : string, storeId : number, channelId : number, reviewCategory: string){
    let respDt = null;

    respDt = await this.reqGetStoreReviewData(skip, take, sortRating, storeId, channelId, reviewCategory);
    return respDt;
  }

  private async reqGetChannelReviewText(accessToken: string, channelId: number, reviewCategoryType: ReviewCategoryType) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.review.GetChannelReviewText,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelId: channelId,
        reviewCategoryType: reviewCategoryType
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqUpdateStoreReview(accessToken: string, reviewData: UpdateStoreReviewRequest) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.review.UpdateStoreReview,
      hostPath: environment.hostPath,
      body:{
        reviewId : reviewData.reviewId,
        channelId : reviewData.channelId,
        storeId : reviewData.storeId,
        activityId : reviewData.activityId,
        reviewCategoryType : reviewData.reviewCategoryType,
        reviewScore: reviewData.reviewScore,
        reviewData: reviewData.reviewData,
        anonymousFlag: reviewData.anonymousFlag,
        itemReviewDatas: reviewData.itemReviewDatas
      },
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Auth
    }as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqGetStoreReviewData(skip : number, take : number, sortRating : string, storeId : number, channelId : number, reviewCategory: string){
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.review.GetStoreReviewData,
      hostPath: environment.hostPath,
      queryParams: {
        skip: skip,
        take: take,
        SortRating : sortRating,
        StoreId : storeId,
        ChannelId : channelId,
        ReviewCategoryType : reviewCategory
      }
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isOriBody?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, false, isOriBody).then((dd: any) => dd);
  }
}
