import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { VoucherTypeExtraResponse } from "src/app/core/models/VoucherTypeExtraResponse";
import { UserService } from 'src/app/core/user/user.service';
import { StoreMode } from 'src/app/core/enums';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-membership-voucher-detail',
  templateUrl: './membership-voucher-detail.component.html',
  styleUrls: ['./membership-voucher-detail.component.scss']
})
export class MembershipVoucherDetailComponent implements OnInit {
  @Input() membershipVoucherDetail?: VoucherTypeExtraResponse;
  @Output() onClickClaim = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Input() channelId: number;
  @Input() onshowInfo: boolean;

  user$: Subscription;
  isLoggedIn: boolean;
  isMobileView: boolean;

  constructor(
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileView = true;
      }
      else {
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit() {
    this.user$ = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.isLoggedIn = userData? true: false;
    });
  }

  async onClaim() {
    this.onClickClaim.emit();
  }

  async onClickClose() {
    this.onClose.emit();
  }


}
