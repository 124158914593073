import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmed-error',
  templateUrl: './confirmed-error.component.html',
  styleUrls: ['./confirmed-error.component.scss']
})
export class ConfirmedErrorComponent implements OnInit {

  @Output() retry = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickRetry(){
    this.retry.emit();
  }

}
