export enum FormStage {
    None = 'None',
    VerifyMobile = 'VerifyMobile',
    VerifyEmail = 'VerifyEmail',
    VerifiedEmail = 'VerifiedEmail',
    VerifyReCaptcha = 'VerifyReCaptcha',
    SendOTP = 'SendOTP',
    SkipSendOTP = 'SkipSendOTP',
    Login = 'Login',
    Signup = 'Signup',
    GuestLogin = 'GuestLogin',
    UpdateMobile = 'UpdateMobile',
    UpdatePassword = 'UpdatePassword',
    UpdateUserProfile = 'UpdateUserProfile',
  }