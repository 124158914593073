import { StaticQrState } from 'src/app/core/static-qr/static-qr.model';
import { QrCartQuery } from 'src/app/core/qr-cart/qr-cart.query';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreService } from 'src/app/store/store/store.service';
import { RouteName } from '../enums/RouteName';
import { QrCartService } from '../qr-cart/qr-cart.service';
import { StaticQrService } from '../static-qr/static-qr.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class QuitQrGuardGuard implements CanActivate {

  qrTokenResponse : WebLinkTokenResponse;
  sessionTokenResponse : WebLinkTokenResponse;
  staticData : StaticQrState;
  sessionStaticData : StaticQrState;
  routeName = RouteName;

  constructor(
    private qrCartQuery : QrCartQuery,
    private storeService : StoreService,
    private router : Router,
    private qrCartService : QrCartService,
    private staticQrService : StaticQrService,
    private channelService : ChannelService,
    private sessionStorageService: SessionStorageService
  ){
    this.qrCartQuery.select(val => val.qrTokenResponse).subscribe(tokenResponse => {
      this.qrTokenResponse = tokenResponse? tokenResponse : null;
    })
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];
    let channelData = this.channelService.getChannelData();

    this.sessionTokenResponse = JSON.parse(this.sessionStorageService.getItem("qrTokenResponse"));
    this.staticData = this.staticQrService.get();

    let isBlockBack = this.sessionStorageService.getItem('isBlockBack');

    if(this.sessionTokenResponse && this.qrTokenResponse && !isBlockBack && (currentRoute == this.routeName.Cart ||
      currentRoute == this.routeName.Store || currentRoute == this.routeName.OrderPayment || currentRoute == this.routeName.OrderSummary)){
      this.storeService.openQuitQrDialog();
      return false;
    }
    else if(this.staticData && this.staticData?.tableNo && !isBlockBack
      && (currentRoute == this.routeName.Cart || currentRoute == this.routeName.Store || currentRoute == this.routeName.OrderPayment || currentRoute == this.routeName.OrderSummary)){
      this.storeService.openQuitQrDialog();
      return false;
    }
    else if(currentRoute == this.routeName.OrderSummary || currentRoute == this.routeName.OrderDetail){
      this.qrCartService.orderSummaryQuitCheck();
      return true;
    }
    else{
      this.qrCartService.orderSummaryQuitCheck();
      return true;
    }
  }

}
