import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor() { }

  getUserGpsLocation(): Promise<{ longitude, latitude }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        setTimeout(() => {
          navigator.geolocation.getCurrentPosition((resp) => {
            resolve({ longitude: resp.coords.longitude, latitude: resp.coords.latitude });
          }, (err) => {
            console.log('err: ', err)
            reject(err);
          }, { timeout: 10000 });
        }, 500);
      }
    });
  }
}
