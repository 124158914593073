import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-out-of-service-message',
  templateUrl: './out-of-service-message.component.html',
  styleUrls: ['./out-of-service-message.component.scss']
})
export class OutOfServiceMessageComponent implements OnInit {

  @Input() isCartPage : boolean = false;
  @Input() storeResponse : StoreResponse;
  @Input() isMiniProgram : boolean = false;
  @Input() channelId : number;

  @Output() closeDialog = new EventEmitter();
  @Output() resetAddress = new EventEmitter();
  @Output() switchOrderType = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickSwitchOrderType(){
    this.switchOrderType.emit();
  }

  onClickCloseIcon(){
    this.closeDialog.emit();
  }

  onClickResetButton(){
    this.resetAddress.emit();
  }

}
