import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';
import { MembershipService } from '../membership/membership.service';
import { StoreService } from 'src/app/store/store/store.service';
import { MembershipDetailResponse } from '../membership/membership-detail-response';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-quit-member',
  templateUrl: './quit-member.component.html',
  styleUrls: ['./quit-member.component.scss']
})
export class QuitMemberComponent implements OnInit {
  @Input() membershipCode: string;
  @Input() memberDetail: MembershipDetailResponse;
  @Output() onHide = new EventEmitter();

  quitText: string = "quit";
  quitInput: string = "";
  isMobile: boolean = false;
  isError: boolean = false;

  constructor(
    private router: Router,
    private toastService: ToastService,
    private membershipService: MembershipService,
    private storeService: StoreService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  ngAfterViewInit(): void {
    let inputEl = document.getElementById("quitText");
    if(inputEl) {
      inputEl.focus();
    }
  }

  onCancel() {
    this.onHide.emit();
  }

  async onQuitMember() {
    this.isError = this.quitInput.replace(/\s/g, "") != this.quitText;
    if (!this.isError) {
       let quiteMemberResult = await this.membershipService.quitMembership(this.membershipCode);
       if (quiteMemberResult instanceof(HttpErrorResponse)) {
        let errorObj = quiteMemberResult.error;
        this.toastService.showApiFailedToast(errorObj);
      } else {
        let isFromPage = this.sessionStorageService.getItem('isFromPage');
        this.sessionStorageService.removeItem('isFromPage');
        if (isFromPage == 'store') {
          this.storeService.setCurrentOrderType(this.memberDetail.storeResponseList[0].currentOrderType);
          let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.memberDetail.storeResponseList[0].locDesc);
          this.router.navigate(['store', this.memberDetail.storeResponseList[0].storeId, locDescWithoutSpaces], { state: { currentStatus: this.memberDetail.storeResponseList[0].currentStatus } });
        } else if (isFromPage == 'reward') {
          this.router.navigateByUrl('/reward/my-membership');
        } else {
          this.router.navigateByUrl('/home');
        }

        let toastData = {} as ToastData;
        toastData.icon = "oda-check-alt";
        toastData.iconColor = "#8CD600";
        toastData.message = "quit.your.membership.desc";
        this.toastService.show(toastData);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let isMobile = window.innerWidth <= 991 ? true: false;

    if(isMobile !== this.isMobile) {
      this.onHide.emit();
    }
  }

}
