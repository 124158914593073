import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading$: Subject<boolean> = new Subject();
  isManualLoad$ : Subject<boolean> = new Subject();

  constructor() { }

  startLoading() {
    this.loading$.next(true);
  }

  stopLoading() {
    this.loading$.next(false);
  }

  startManualLoad(){
    this.isManualLoad$.next(true);
    this.startLoading();
  }

  stopManualLoad(){
    this.stopLoading();
    this.isManualLoad$.next(false);
  }
}
