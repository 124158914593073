import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState  } from '@datorama/akita';
import { User } from './user.model';

export interface UserState extends EntityState<User>, ActiveState {
  selectedAddress: any;
}

export function createInitialState() {
  return {
    selectedAddress: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends EntityStore<UserState, User> {

  constructor() {
    super(createInitialState());
  }
}

