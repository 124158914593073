<div class="account-container">
  <div class=" bg">
    <div class="profile-container">
      <!--profile navigation menu-->
      <div class="profile-sideMenu hidden-sm">
        <div class="sub-content">
          <h1 class="profileNav-heading-1">{{ 'profile.side.menu.header1' | translate }}</h1>
          <a *ngFor="let itm of profileNav_routeLinkList"
             [routerLink]="itm.nav"
             [routerLinkActive]="'active'"
             [ngStyle]="{ 'display': itm.hidden? 'none': 'block'}">
            <i [class]="itm.icon" class="p-mr-2"></i>
            {{ itm.desc | translate }}
          </a>
        </div>
      </div>
      <!--profile child page-->
      <div class="profile-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="hidden-sm" *ngIf="isLoggedIn === false">
    <div class="guest-profile">
      <h1 class="heading-8">{{'profile.guest.mode' | translate}}</h1>
      <h2 routerLink="/login">{{'button.login' | translate}}</h2>
    </div>
  </div>
</div>
