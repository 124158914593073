<div class="reward-history-container">
    <div class="reward-wrapper">
        <div class="header-container" [ngClass]="{ 'sticky': isHeaderSticky }" #menuContainer>
            <div class="header-wrapper" id="menuContainer">
                <div class="header">
                    <div class="hidden-lg title">
                        <div class="btn-back"><app-back-button></app-back-button></div>
                        <span>{{ 'rewards.history.title' | translate }}</span>
                    </div>
                    <div class="hidden-sm title" (click)="onBack()">
                        <div class="btn-back"><app-back-button></app-back-button></div>
                        <span class="hidden-sm">{{ 'button.back' | translate }}</span>
                    </div>
                    <div class="menu-container">
                        <div class="menu">
                            <button type="button" class="btn-menu-mobile hidden-lg" (click)="showMobileMenu = true;"><i class="oda-sort"></i></button>
                            <swiper [slidesPerView]="'auto'" [grabCursor]="true" #swiper>
                                <ng-template swiperSlide>
                                    <input type="radio" name="category" value="" id="menu-0" class="radio"
                                        (change)="onMenuChange();" [checked]="!voucherStatus">
                                    <label class="category not-selectable" for="menu-0">{{ "cart.all.button" | translate }}</label>
                                </ng-template>
                                <ng-template swiperSlide>
                                    <input type="radio" name="category" value="{{ voucherStatusFlag.Used }}" id="menu-{{ voucherStatusFlag.Used }}" class="radio"
                                        (change)="onMenuChange(voucherStatusFlag.Used);" [checked]="voucherStatus === voucherStatusFlag.Used">
                                    <label class="category not-selectable" for="menu-{{ voucherStatusFlag.Used }}">{{ "rewards.used" | translate }}</label>
                                </ng-template>
                                <ng-template swiperSlide>
                                    <input type="radio" name="category" value="{{ voucherStatusFlag.Expired }}" id="menu-{{ voucherStatusFlag.Expired }}" class="radio"
                                        (change)="onMenuChange(voucherStatusFlag.Expired);" [checked]="voucherStatus === voucherStatusFlag.Expired">
                                    <label class="category not-selectable" for="menu-{{ voucherStatusFlag.Expired }}">{{ "rewards.expired" | translate }}</label>
                                </ng-template>
                            </swiper>
                        </div>
                        <form (ngSubmit)="onSearch()" [formGroup]="searchFgroup" class="search-bar">
                            <i class="oda-search"></i>
                            <input type="text" pInputText placeholder="{{ 'rewards.history.search.hint' | translate }}" formControlName="searchText" #searchBar />
                            <button type="button" class="btn-close" *ngIf="searchText" (click)="onSearch(true);"><i class="oda-times"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-container" *ngIf="histories && histories.length !== 0">
            <div class="card-container">
                <div class="card-wrapper" *ngFor="let history of histories">
                    <app-reward-card [historyReward]="history" [showStoreName]="true" [customCardType]="checkCardType(history.statusFlag)"></app-reward-card>
                </div>
            </div>
            <ng-template [ngIf]="isOnListLoad">
                <div class="pagination-loader">
                  <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
                </div>
            </ng-template>
        </div>

        <ng-template [ngIf]="histories && histories.length <= 0 && !isOnListLoad && !isOnLoad">
            <div class="no-reward">
                <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
                <h1 class="title">{{ 'rewards.result.empty.des.1' | translate }}</h1>
                <p class="desc" *ngIf="!searchedText">{{ 'rewards.result.empty.des.3' | translate }}</p>
                <p class="desc" *ngIf="searchedText">{{ 'rewards.result.empty.des.4' | translate: { searchText: searchedText } }}</p>
                <button type="button" [routerLink]="['/reward']" *ngIf="!searchedText">{{ "button.rewards.explore.voucher" | translate }}</button>
                <button type="button" (click)="onSearch(true);" *ngIf="searchedText">{{ "merchant.search.again.food.button" | translate }}</button>
            </div>
        </ng-template>

        <ng-template [ngIf]="isOnLoad">
            <div class="list-loader">
              <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
            </div>
        </ng-template>

        <!-- Menu Dialog -->
        <p-dialog [(visible)]="showMobileMenu" [modal]=true [dismissableMask]=true [draggable]=false [blockScroll]="true" class="dialog-menu hidden-lg">
            <ng-template pTemplate="header">
                <h1><i class="oda-sort"></i>{{ 'rewards.voucher.category' | translate }}</h1>
            </ng-template>
            <ul class="menu-list">
                <li>
                    <input type="radio" name="category-mobile" value=""  id="menu-mobile-0" class="radio" (change)="onMenuChange();" [checked]="!voucherStatus">
                    <label for="menu-mobile-0">{{ "cart.all.button" | translate }}</label>
                </li>
                <li>
                    <input type="radio" name="category-mobile" value="{{ voucherStatusFlag.Used }}"  id="menu-mobile-{{ voucherStatusFlag.Used }}" class="radio" (change)="onMenuChange(voucherStatusFlag.Used);" [checked]="voucherStatus === voucherStatusFlag.Used">
                    <label for="menu-mobile-{{ voucherStatusFlag.Used }}">{{ "rewards.used" | translate }}</label>
                </li>
                <li>
                    <input type="radio" name="category-mobile" value="{{ voucherStatusFlag.Expired }}"  id="menu-mobile-{{ voucherStatusFlag.Expired }}" class="radio" (change)="onMenuChange(voucherStatusFlag.Expired);" [checked]="voucherStatus === voucherStatusFlag.Expired">
                    <label for="menu-mobile-{{ voucherStatusFlag.Expired }}">{{ "rewards.expired" | translate }}</label>
                </li>
            </ul>
        </p-dialog>
    </div>
</div>
