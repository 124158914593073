import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChannelSecStore, ChannelSecState } from './channelsec.store';
import { ChannelSec } from './channelsec.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelSecQuery extends QueryEntity<ChannelSecState, ChannelSec> {

  constructor(protected store: ChannelSecStore) {
    super(store);
  }

}
