<div class="reward-detail-container" [ngClass]="[ cardType ]">
    <div class="header hidden-sm" *ngIf="!fullpagePromotion">
        <i class="btn-close oda-times" (click)="onBack();"></i>
    </div>
    <div class="mobile-header hidden-lg">
        <i class="btn-back oda-chevron-left" (click)="onBack();" *ngIf="!fullpagePromotion"></i>
        <i class="btn-back oda-chevron-left" (click)="onBackHome()" *ngIf="fullpagePromotion"></i>
        <h1>{{ "rewards.details.title" | translate}}</h1>
        <i class="btn-share oda-upload" (click)="onShowShare();" *ngIf="!appLinkTokenResponse && environment.enableShareVch"></i>
    </div>
    <div class="reward-detail-wrapper"  [ngClass]="{'fullpage-detail-wrapper': fullpagePromotion}">
        <div class="content-container">
            <div class="content-header">
                <img *ngIf="imageData" src="{{ imageData }}" alt="reward image" loading="lazy"/>
                <div class="desc-container">
                    <div class="title">
                        <h1>{{ vchTypTitle }}</h1>
                        <i class="oda-upload btn-share hidden-sm" (click)="onShowShare();" *ngIf="!appLinkTokenResponse && environment.enableShareVch"></i>
                    </div>
                    <div class="desc">
                      <p>{{ vchTypDesc }}</p>
                    </div>
                    <div class="progressbar-container" *ngIf="exploreReward && issueLimitMode > 0">
                        <p-progressBar [value]="totalIssuedPercentage" [showValue]="false"></p-progressBar>
                        <p>{{ totalIssuedPercentage }}% <span class="text-claimed" *ngIf="cardType !== cardTypes.claimed">{{ "button.voucher.has.claim" | translate }}</span><span *ngIf="cardType === 'claimed'">{{ "rewards.used" | translate }}</span></p>
                    </div>
                </div>
            </div>
            <div class="partition"><div class="line"></div></div>
            <div class="content">
                <div class="content-wrapper" *ngIf="(isUserClaimed && effectiveDate && expiryDate) || (!isUserClaimed && claimStartDate && claimEndDate)">
                    <div class="label">{{ 'valid.date.label' | translate }}</div>
                    <p>{{ 'menu.item.from' | translate | titlecase }} {{ (isUserClaimed? effectiveDate: claimStartDate) | date: "dd/MM/yyyy" }} {{ 'merchant.delivery.time.until' | translate }} {{ (isUserClaimed? expiryDate: claimEndDate) | date: "dd/MM/yyyy" }}</p>
                </div>
                <div class="content-wrapper" *ngIf="termsData">
                    <div [innerHtml]="termsData"></div>
                </div>
            </div>
        </div>
        <div class="partition" *ngIf="!fullpagePromotion"><div class="line"></div></div>
        <div class="button-container">
            <button type="button" class="store" (click)="onNavigate();">{{ "button.voucher.gotostore" | translate }}</button>
            <button type="button" class="claim" (click)="onClaim();">{{ "button.voucher.claimable" | translate }}</button>
            <button type="button" class="claimed" [disabled]="true">{{ "button.voucher.has.claim" | translate }}</button>
            <button type="button" class="use" (click)="onUse()">{{ "button.rewards.use.show.qr" | translate }}</button>
            <button type="button" class="select" (click)="onSelect(true)" *ngIf="!active">{{ "button.select" | translate }}</button>
            <button type="button" class="remove" (click)="onSelect(false)" *ngIf="active">{{ "button.remove" | translate | uppercase }}</button>
            <button type="button" class="full" [disabled]="true">{{ "button.voucher.fully.claimed" | translate | uppercase }}</button>
        </div>
    </div>
    <div class="node left" *ngIf="!fullpagePromotion"></div>
    <div class="node right" *ngIf="!fullpagePromotion"></div>
</div>

