
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';

import { PageName } from 'src/app/core/enums';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { MobileResetPassword } from 'src/app/core/models/MobileResetPassword';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { ProfileService } from './../services/profile.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [ProfileService]
})
export class ResetPasswordComponent implements OnInit {
  pageName = PageName.ResetPasswordPage;
  data: MobileResetPassword;
  errorCodes: any = ErrorCode;
  errorCode: any;
  isMobile: boolean = false;

  constructor(private popupMessageService: PopupMessageService,
    private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  async onFormSubmit(dt: any) {
    let newPw = dt?.newPassword? dt.newPassword: "";
    let currentPw = dt?.currPassword? dt.currPassword: '';

    let resp: any = await this.profileService.onUpdatePassword(currentPw, newPw);

    if(resp instanceof HttpErrorResponse) {
      if(resp.error.errorCode === this.errorCodes.PasswordMisMatch_400) {
        this.errorCode = resp.error.errorCode;
      } else {
        this.popupMessageService.show({
          icon: "oda-alert-alt",
          iconColor: "red",
          desc: "change.password.unsuccessful.desc",
          btn: "button.ok"
        });
      }
    } else {
      this.popupMessageService.show({
        icon: "oda-check-alt",
        iconColor: 'green',
        desc: "change.password.successful.desc",
        btn: "button.ok",
        redirectLink: this.isMobile? "/profiles": "/account/profile"
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }
}


