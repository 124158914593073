import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderService } from '../loader-services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class TimeInterceptor implements HttpInterceptor {
  defaultTimeout : number;

  constructor(
    private router : Router,
    private loaderService : LoaderService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.defaultTimeout = this.timeToTimeoutChecking(req);

    return next.handle(req).pipe(
      timeout(this.defaultTimeout),
      catchError(error => {
        if(error.name == 'TimeoutError'){
          this.loaderService.stopLoading();
          this.router.navigate(['technical-error']);
        }
        return throwError(error);
      })
    );
  }

  timeToTimeoutChecking(req : HttpRequest<any>){
    let timeoutTime = 15000;

    if(req.url.includes(environment.apis.order.CheckoutOrder) || req.url.includes(environment.apis.order.SubmitOrder)){
      timeoutTime = 180000;
    }
    else if(req.url.includes(environment.apis.user.SendOTP) || req.url.includes(environment.apis.cart.RecalculateCart)){
      timeoutTime = 30000;
    }

    return timeoutTime;
  }
}

