import { Injectable } from '@angular/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Injectable({
  providedIn: 'root'
})
export class DefaultSettingService {

  constructor() { }

  static get telInpSetting() {

    let countryCode = localStorage.getItem('countryCode') ? localStorage.getItem('countryCode') : 'MY';
    let index = Object.values(CountryISO).indexOf(countryCode.toLowerCase() as unknown as CountryISO);
    let countryValues = "my";
    if(index != -1){
      countryValues = Object.values(CountryISO)[index];
    }

    return {
      cssClass: '',
      preferredCountries: [CountryISO.Malaysia, CountryISO.Singapore, CountryISO.Brunei],
      onlyCountries: [CountryISO.Malaysia, CountryISO.Singapore, CountryISO.Brunei],
      enableAutoCountrySelect: false,
      enablePlaceholder: false,
      customPlaceholder: 'input.phone.number.placeholder',
      numberFormat: PhoneNumberFormat.International,
      searchCountryFlag: true,
      searchCountryField: [SearchCountryField.Iso2, SearchCountryField.Name],
      searchCountryPlaceholder: 'Search Country',
      maxLength: 15,
      selectFirstCountry: false,
      phoneValidation: true,
      inputId: 1,
      selectedCountryISO: countryValues as CountryISO,
      separateDialCode: true,
      countryChange: null
    };
  }
}
