<div class="toolbar_container">
  <!--channel button-->
  <div class="p-mr-2 btn-channel " (click)="showChannel()" *ngIf="isDefaultDomain && selectedChannel && curRouter === 'home'">
    <i class="oda-channel"></i>
    <h1 class="heading-11 text-truncate" style=" padding: 0px 3px; margin-top: 12px;">
      {{selectedChannel.channelTag === odaringChannel ? ('button.channel' | translate) : selectedChannel.channelTag}}
    </h1>
    <i class="oda-d-switch duotone"></i>
  </div>

  <!--display username and dropdown menu-->
  <div class="p-mr-2" *ngIf="displayLoginButton === ''">
    <button type="button" class="heading-11 btn-login" (click)="onLogin()">
      <i class="oda-user-open"></i> {{'button.login' | translate}}
    </button>
  </div>

  <div class="p-mr-2" *ngIf="displayLoginButton !== ''">
    <app-user-menu-dropdown></app-user-menu-dropdown>
  </div>

  <!--shopping cart-->
  <div (click)="onNavigateToCart()" *ngIf="!qrTokenResponse && !queueResponse">
    <button type="button" class="text-truncate btn-cart" [style.background-color]="numberOfOrders > 0 ? 'var(--iconTextButton-primary)' : 'var(--background)'"
       [style.color]="numberOfOrders > 0 ? 'var(--background)' : 'var(--text-primary)'">
      <i class="oda-shop p-mr-2" [style.color]="numberOfOrders > 0 ? 'var(--background)' : 'var(--iconTextButton-primary)'"></i>
      <span class="item-number">{{"cart.total.store.order" | translate : {order: numberOfOrders} }}</span>
    </button>
  </div>

</div>
