import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { ApplinkService } from 'src/app/core/services/applink.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { WeblinkService } from '../../services/weblink.service';
import { StorageService } from '../../services/storage.service';
import { isPlatformBrowser } from '@angular/common';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-mascot-loading',
  templateUrl: './mascot-loading.component.html',
  styleUrls: ['./mascot-loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MascotLoadingComponent implements OnInit {

  isMiniProgram: boolean = false;
  isShowMascot: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private weblinkService: WeblinkService,
    private loaderService: LoaderService,
    private channelService: ChannelService,
    private storageService : StorageService,
    private appLinkService : ApplinkService,
    @Inject(PLATFORM_ID) private platformId: object,
    private platformLocation: PlatformLocation,
  ) { }

  options: AnimationOptions = {
    path: '/assets/mascot/animation/mascot-loading.json',
    autoplay: true,
    loop: true
  };

  async ngOnInit() {
    try{
      if (isPlatformBrowser(this.platformId)) {
        this.storageService.storeIsQrScan();
        let href = this.platformLocation.href;
        let convertHref = href.toLowerCase();
        if (convertHref.includes('orientalcoffee')) {
          this.isShowMascot = false;
        } else {
          this.isShowMascot = true
        }

        let channelData = await this.channelService.switchChannelCheck();
        // change logic to be executed based on route
        this.isMiniProgram = this.route.snapshot.data['isMiniProgram'] ? this.route.snapshot.data['isMiniProgram'] : false;

        const tokenId = this.route.snapshot.paramMap.get('tokenId');

        if(this.isMiniProgram){
          const appId = this.route.snapshot.paramMap.get('appId');
          this.appLinkService.getAppLinkToken(appId, tokenId, channelData.channelTag);
        }
        else{
          let webLinkResp = await this.weblinkService.validateWebLinkToken(tokenId, true);
          if(!(webLinkResp instanceof HttpErrorResponse)){
            // store qr info into session storage
            this.storageService.storeQrInfo(webLinkResp.storeId, webLinkResp.webURL);
          }
        }
      }
      this.loaderService.stopLoading();
    }catch(error){
      this.loaderService.stopLoading();
    }

  }
}
