import { HttpErrorResponse } from '@angular/common/http';
import { Component, Output, EventEmitter, ElementRef, OnDestroy, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PageName } from 'src/app/core/enums';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { ChannelData } from 'src/app/core/models';
import { LoginRequest } from 'src/app/core/models/LoginRequest';
import { MobileResetPassword } from 'src/app/core/models/MobileResetPassword';
import { AuthService } from 'src/app/core/services/auth.service';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { UserChnlQuery } from 'src/app/home/userchnl/userchnl.query';
import { environment } from 'src/environments/environment';
import { FormStage } from '../../enums/FormStage';
import { LoginForm } from '../../models';
import { SignupForm } from '../../models/SignupForm';
import { OtpService } from '../../services/otp.service';
import { RegisterService } from '../../services/register.service';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
  providers: [RegisterService]
})
export class OtpFormComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() isPhoneNoExist: boolean = false;
  @Input() loginForm: LoginForm;
  @Input() isSignUpForm: boolean;
  @Input() isQueue : boolean;
  @Output() onNavigatePage = new EventEmitter();
  @Output() onShowBackBtn = new EventEmitter<boolean>();
  navigatePage(pageName?: string) {
    this.onNavigatePage.emit({
      pageName: pageName,
    });
  }

  // Control
  mobileNo: any = null;
  pageName: PageName | null = null;

  formStage: FormStage = FormStage.SendOTP;
  otpPrefix: string = "";
  otp: string = "";
  curOtp: string = "";

  otpFA = this.fb.array([
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
    this.fb.control('', [Validators.required, Validators.maxLength(1), Validators.pattern("^[0-9]*$")]),
  ], [Validators.required, Validators.maxLength(6)]);

  fgroup: UntypedFormGroup = this.fb.group({
    otpArr: this.otpFA,
    mobileNo: [null, Validators.required],
    pageName: ['']
  });

  @ViewChild('formotpArr') formotpRow: ElementRef | null = null;

  optFailCount = 0;
  canSkip = false;
  preInpLength = 0;
  sub: Subscription = new Subscription();
  timeInSec: BehaviorSubject<number> = new BehaviorSubject<number>(environment.optResendInSec);
  numberOfTimeRefresh: number = 0;
  numberOfTimeResend: number = environment.optMaxFailBypass;
  showOptions: boolean = false;
  verifyOTPError: HttpErrorResponse = null;
  ErrorCode = ErrorCode;
  validLength: number = 0;
  invalidOTPError: boolean = false;
  hideTimer: boolean = false;
  userVerifiedEmail: string = '';
  isEmailRedirect: boolean = false;
  isMobile: boolean = false;
  isSubDomain : boolean = false;
  pathName: string = "";
  channelData: ChannelData;
  pageNames: any = PageName;

  constructor(
    private fb: UntypedFormBuilder,
    private otpService: OtpService,
    private router: Router,
    private authService: AuthService,
    private registerService: RegisterService,
    private userService: UserService,
    private route: ActivatedRoute,
    private popupMessageService: PopupMessageService,
    private channelService: ChannelService,
    private userchnlQry: UserChnlQuery,
    private utilsService: UtilsService,
    private queueService : QueueService,
    private sessionStorageService: SessionStorageService,
    private previousRouteService : PreviousRouteService,
  ) { }

  async ngOnInit(): Promise<void> {
    const otpObj = this.otpService.getOtpObj();
    this.otpPrefix = otpObj.otpPrefix? otpObj.otpPrefix: this.otpPrefix;
    this.mobileNo = otpObj.mobileObj? otpObj.mobileObj: otpObj.mobileNo;
    this.pageName = otpObj.pageName;
    this.isMobile = window.innerWidth <= 991 ? true: false;
    this.pathName = this.router.url;
    this.channelData = this.channelService.getChannelData();
    this.isSubDomain = this.userchnlQry.getValue().isSubDomain;

    this.route.queryParams.subscribe((param) => {
      this.mobileNo = param['phoneNo']? param['phoneNo']: this.mobileNo;
      this.otpPrefix = param['prefix']? param['prefix']: this.otpPrefix;
      this.otp = param['otp']? param['otp']: this.otp;
      this.isEmailRedirect = param['phoneNo'] && param['otp'];
    });

    this.getFormControl('mobileNo').setValue(this.mobileNo);
    this.getFormControl('pageName').setValue(otpObj.pageName);

    this.sub =  this.otpArrs.valueChanges.subscribe((dt) => {
      const valLeng = dt.filter((dd: any) => !!dd).length;
      this.validLength = valLeng;
      this.curOtp = dt.join('');
      let otpLength = this.curOtp.length;

      if(this.otp === this.curOtp && otpLength === 6) {
        setTimeout(() => { this.onSubmit(); }, 1000);
      }
    });

    this.setTimerConfig();

    if(this.isEmailRedirect) {
      this.showOptions = true;
      this.hideTimer = true;
    }

    if (this.mobileNo) {
      let mobileNo = typeof(this.mobileNo) == 'string' ? this.mobileNo : this.mobileNo.e164Number.replace('+', '');
      let resp: any = await this.userService.getCustomerProfileStatus(mobileNo, "");

      if (!(resp instanceof HttpErrorResponse)) {
        this.userVerifiedEmail = resp.isEmailVerified? resp.mobileNumber: '';
      }
    }
  }

  ngAfterViewInit(): void {
    if(this.formotpRow) {
      this.formotpRow.nativeElement.form[0].focus();
    }

    if(this.isEmailRedirect) {
      this.pasteOtp(this.otp);
    }
  }


  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async onSubmit() {
    try {
      if (this.fg.invalid) {
        this.invalidOTPError = true;
        this.clearOtpArrs();
        return;
      }

      let otpCode = this.otpPrefix + this.fg.value.otpArr.join('');
      this.queueService.removeCanQueueRegisterFlag();

      if(this.pageName === PageName.VerifyMobilePage || this.pathName.includes('otp/verify-phone-no')) {
        let otpResult = await this.otpService.onUpdateMobileNoStatus(this.mobileNo, otpCode);

        if(!(otpResult instanceof HttpErrorResponse) && this.isMobile) {
          this.popupMessageService.show({
            icon: "oda-check-alt",
            iconColor: "green",
            desc: "alert.editProfile.des.1",
            btn: "button.ok",
            showMobileOnly: true
          });
        }

        if(!(otpResult instanceof HttpErrorResponse)) {
          this.router.navigateByUrl("/account/profile");
        } else {
          this.verifyOTPError = otpResult;
          this.clearOtpArrs();
        }
      } else if(this.pageName === PageName.ForgotPasswordPage) {
        let otpResult = await this.otpService.onVerifyOTP(this.mobileNo, this.pageName as PageName, otpCode);
        let forgetPwData: MobileResetPassword = {
          mobileNo: this.mobileNo? this.mobileNo.dialCode?.replace('+', '') + this.utilsService.formatPhoneNo(this.mobileNo): "",
          otpCode: otpCode
        };

        if(!(otpResult instanceof HttpErrorResponse)) {
          this.router.navigate(["forgot-password"], { state: { data: forgetPwData } });
        } else {
          this.verifyOTPError = otpResult;
          this.clearOtpArrs();
        }
      } else if (this.isPhoneNoExist) {
        this.otpLoginRequest(otpCode);
      } else if(this.isQueue){
        this.otpQueueVerify(otpCode);
      } else {
        this.otpSignupRequest(otpCode);
      }
    } catch (err) {
      console.log('error: ', err)
    }
  }

  get fg() { return this.fgroup; }

  get f() { return this.fg.controls; }

  get otpArrs() {
    return this.getFormControl('otpArr') as UntypedFormArray;
  }

  async onSendOTP(manualSendOTP?: boolean, isSendEmail?: boolean) {
    if (!!this.mobileNo) {
      this.setTimerConfig();
      this.otpPrefix = this.otpService.createOtpPrefix();

      let emailRedirectLink = null;
      let url = window.location.origin;

      if(manualSendOTP && isSendEmail) {
        let channelTag: string = this.isSubDomain? '': this.channelData?.channelTag? '/' + this.channelData.channelTag: '/' + environment.odaringChannel;
        let subdomain = this.pageName === PageName.VerifyMobilePage || this.pathName.includes('verify-phone-no')? '/otp/verify-phone-no': '/otp/login';
        emailRedirectLink = url + channelTag + subdomain;
      }

      await this.otpService.onSendOTP(this.mobileNo, PageName.OTPPage, { }, this.otpPrefix, emailRedirectLink, isSendEmail);

      if (manualSendOTP) {
        this.showOptions = false;
        this.hideTimer = false;
      }

      this.verifyOTPError = null;
      this.clearOtpArrs();
    }
  }

  onTimerCallback(dt?: any) {
    const otpTrgObj = this.otpService.getOtpObj();
    this.optFailCount = otpTrgObj.otpFailCount;
    this.canSkip = otpTrgObj.canSkip;
    this.numberOfTimeRefresh++;
    this.hideTimer = true;
    this.numberOfTimeResend--;
    this.showOptions = true;
  }

  onShowBackButton(showBtn?: boolean) {
    this.onShowBackBtn.emit(showBtn);
  }

  onBackLogin() {
    this.otpService.navigateToPage(PageName.LoginPage, { });
  }

  onOtpInput(event: any, index: number) {
    const nextInputEl = document.getElementById('otpInput-'+ (index+1)) as HTMLInputElement;

    if(event.target.value && event.target.value.length === 1 && nextInputEl) {
      nextInputEl.focus();
    }

    if(event.target.value && event.target.value.length > 1) {
      const inputVal = event.target.value.replace(' ', '').split('');
      const lastInputInd = inputVal.length >= this.otpFA.controls.length? this.otpFA.controls.length - 1: inputVal.length;
      event.preventDefault();

      this.otpFA.controls.forEach((input, index) => {
        let data = inputVal[index]? inputVal[index]: '';
        input.setValue(data);
      });

      //focus on the last input
      document.getElementById("otpInput-" + (lastInputInd)).focus();
    }
  }

  onOtpKeydown(event: any, index: number) {
    let inputEl = document.getElementById('otpInput-'+ index) as HTMLInputElement;
    const prevInputEl = document.getElementById('otpInput-'+ (index-1)) as HTMLInputElement;
    const nextInputEl = document.getElementById('otpInput-'+ (index+1)) as HTMLInputElement;

    // arrow keycode 37-40
    if(inputEl && event.key !== 'Backspace' && event.key !== 'Enter' && !(event.keyCode >= 37 && event.keyCode <= 40)) {
      inputEl.value = "";
    }

    if(prevInputEl && !inputEl.value && event.key === 'Backspace'){
      prevInputEl.focus();
    }

    if(prevInputEl && event.key === 'ArrowLeft'){
      prevInputEl.focus();
      this.setCursorOnEnd(prevInputEl);
    }

    if(nextInputEl && event.key === 'ArrowRight'){
      nextInputEl.focus();
    }
  }

  onOtpFocus(event: any, index: number){
    const inputEl = document.getElementById('otpInput-'+ index) as HTMLInputElement;
    this.setCursorOnEnd(inputEl);
  }

  private pasteOtp(otpInString: string) {
    const inputVal = otpInString.replace(' ', '').split('');
    const lastInputInd = inputVal.length >= this.otpFA.controls.length? this.otpFA.controls.length - 1: inputVal.length;

    this.otpFA.controls.forEach((input, index) => {
      let data = inputVal[index]? inputVal[index]: '';
      input.setValue(data);
    });

    //focus on the last input
    document.getElementById("otpInput-" + (lastInputInd)).focus();
  }


  private setCursorOnEnd(inputEl: HTMLInputElement) {
    if(inputEl){
      let inputLength = inputEl.value.length * 2;
      setTimeout(() => { inputEl.setSelectionRange(inputLength, inputLength); }, 0);
    }
  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  private setTimerConfig() {
    this.timeInSec.next(environment.optResendInSec);
  }

  clearOtpArrs() {
    this.otpArrs.reset();
    this.formotpRow.nativeElement.form[0].focus();
  }

  manualResendOTP() {
    this.hideTimer = false;
    this.onSendOTP(true);
  }

  async skipOtp() {
    await this.otpSignupRequest('');
    this.router.navigate(["complete-profile"]);
  }

  async otpLoginRequest(otpCode: string) {
    let mobileNo = this.loginForm? this.loginForm.dialCode + this.loginForm.phoneNo: this.mobileNo;

    let loginReq: LoginRequest = {
      mobileNo: mobileNo,
      otpCode: otpCode,
    };

    // get navigate to payment flag from session storage
    let cachePaymentFlag = JSON.parse(this.sessionStorageService.getItem("navigateToPayment"));
    let fromVoucher =JSON.parse(this.sessionStorageService.getItem("fromVoucher"));
    // if needed to route to payment page then set route name to order payment else null
    let routeName :any;
    if(fromVoucher) {
      routeName = this.previousRouteService.getPaymentBackUrl();
    }else {
      routeName =  cachePaymentFlag ? "order-payment" : null;
    }


    let successLogin = await this.authService.login(loginReq, routeName, this.isQueue);

    if (successLogin instanceof HttpErrorResponse) {
      this.verifyOTPError = successLogin;
      this.clearOtpArrs();
    }
  }

  async otpSignupRequest(otpCode: string) {
    const signupForm: SignupForm = {
      mobileNo: this.loginForm.dialCode + this.loginForm.phoneNo,
      otpCode: otpCode ? otpCode : undefined,
      isMobileVerified: otpCode ? undefined : false
    }

    let signupResult = await this.registerService.otpSignUp(signupForm);

    if (signupResult instanceof HttpErrorResponse) {
      this.verifyOTPError = signupResult;
      this.clearOtpArrs();
    } else {
      await this.userService.updateToken(signupResult);
      this.router.navigate(["complete-profile"], { state: { otpCodeState: otpCode } });
    }
  }

  // queue otp verify mobile code
  async otpQueueVerify(otpCode : string){
    let otpResult = await this.otpService.onVerifyOTP(this.mobileNo, this.pageName as PageName, otpCode);

    if(!(otpResult instanceof HttpErrorResponse)){
      let queueRequest = JSON.parse(this.sessionStorageService.getItem('queueRequest'));
      queueRequest.otpCode = otpCode;
      this.sessionStorageService.setItem('queueRequest', JSON.stringify(queueRequest));
      this.router.navigate(['queue/complete-queue-profile'], { replaceUrl: true });
    }
    else{
      this.verifyOTPError = otpResult;
      this.clearOtpArrs();
    }
  }

  sendOtpEmail() {
    this.onSendOTP(true, true);
  }
}
