import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserChannel } from './userchnl.model';

export interface UserChnlState extends EntityState<UserChannel> {
  isSubDomain: boolean;
}

export function createInitialState() {
  return {
    isSubDomain: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'userchnl' })
export class UserChnlStore extends EntityStore<UserChnlState, UserChannel> {

  constructor() {
    super(createInitialState());
  }

}

