<div *ngIf="isStorePage">
  <div class="membership-card-container" *ngFor="let itm of membership" (click)="onClickMember(itm)">
    <!--non-member : normal membership-->
    <ng-template [ngIf]="!itm.membershipId && !itm.membershipNo && (itm.externalMemberLinkMode === externalMemberNone || itm.externalMemberLinkMode === externalMemberRegister)">
      <div class="non-member">
        <h1 class="heading-11" *ngIf="!isMobileView">{{'membership.become.a.member.desc.1'| translate}}</h1>
        <h1 class="heading-11" *ngIf="isMobileView">{{'membership.become.member.desc.1'| translate}}</h1>
        <p class="membershipCard-heading-1" *ngIf="!isMobileView"
          [innerHtml]="'membership.become.a.member.desc.2' | translate: { join_now: '<span style=\'font-weight: bold; color: var(--iconTextButton-primary); text-transform: uppercase; cursor: pointer\'>' + ('membership.button.join.now' | translate) + '</span>' } | safeHtml">
        </p>
        <p class="membershipCard-heading-1" *ngIf="isMobileView"
          [innerHtml]="'membership.become.member.desc.2' | translate: { join_now: '<span style=\'font-weight: bold; color: var(--iconTextButton-primary); text-transform: uppercase; cursor: pointer\'>' + ('membership.button.join.now' | translate) + '</span>' } | safeHtml">
        </p>
      </div>
    </ng-template>

     <!--non-member : external membership-->
    <ng-template [ngIf]="itm.externalMemberLinkMode === externalMemberLogin && !itm.membershipNo">
      <div class="non-member">
        <h1 class="heading-11">{{'external.membership.header' | translate}}</h1>
        <p  class="membershipCard-heading-1"  [innerHtml]="'external.membership.desc' | translate: { login: '<span style=\'font-weight: bold; color: var(--iconTextButton-primary); text-transform: uppercase; cursor: pointer\'>' + ('button.login' | translate) + '</span>' } | safeHtml"></p>
      </div>
    </ng-template>

    <!--member-->
    <ng-template [ngIf]="itm.membershipId || itm.membershipNo">
      <div class="member-info">
        <div class="info-detail">
          <!--show when reward type = pointstamp and point-->
          <h1 class="info-heading-1"  *ngIf="itm.rewardType === checkRewardType.pointStamp || itm.rewardType === checkRewardType.point">
            <i class="oda-membership-point"></i>
            <span>{{itm.closingPoint | number}}</span>{{'membership.points.unit.label' | translate}}
          </h1>
          <!--show when reward type = pointstamp-->
          <div class="grap-line" *ngIf="itm.rewardType === checkRewardType.pointStamp && !isMobileView"></div>
          <!--show when reward type = pointstamp and stamp-->
          <h1 class="info-heading-1" *ngIf="itm.rewardType === checkRewardType.pointStamp || itm.rewardType === checkRewardType.stamp">
            <i class="oda-membership-stamp"></i>
            <span>{{itm.stampEarned}}/{{itm.totalStamp}}</span>{{'membership.stamps.unit.label'| translate}}
          </h1>
        </div>

        <!--membership expired date-->
        <p class="expired-heading" *ngIf="itm.expiryDate">{{'membership.expiry.soon.desc' | translate: {date: itm.expiryDate | date: "dd/MM/yyyy"} }}</p>
      </div>
    </ng-template>

    <!--membership logo-->
    <div class="membership-logo">
      <!--with card image-->
      <img [src]="itm.cardImageData" (error)="onImgError()" alt="membership card" *ngIf="itm.cardImageData && this.cardImageError == false"
        class="card-image" loading="lazy" />
      <!--with logo image-->
      <div class="logoImage-card" *ngIf="!itm.cardImageData || this.cardImageError == true">
        <img [src]="itm.logoImageData" onerror="this.src='assets/image-unavailable.svg'" alt="image unavailable" loading="lazy"/>
        <h1 class="desc-heading">{{itm.membershipDesc}}</h1>
      </div>
    </div>
  </div>
</div>

<!--membership card: reward page-->
<div *ngIf="isRewardPage" class="reward-membership-card-container" (click)="onClickStore()">
  <!--card image-->
  <div class="card-image" *ngIf="membershipReward.cardImageData">
    <img [src]="membershipReward.cardImageData" (error)="onImgError()" alt="membership card" loading="lazy">
  </div>
  <!--logo image-->
  <div class="logo-image"
    *ngIf="!membershipReward.cardImageData && membershipReward.logoImageData || this.cardImageError == true">
    <div class="image-content">
      <img [src]="membershipReward.logoImageData" onerror="this.src='assets/image-unavailable.svg'" alt="image unavailable" loading="lazy"/>
    </div>
    <h1 class="desc-heading">{{membershipReward.membershipDesc}}</h1>
  </div>
  <div class="detail">
    <h1>{{membershipReward.membershipDesc}}</h1>
    <div class="p-d-flex p-flex-wrap">
      <!--show when reward type = pointstamp and point-->
      <div class="member-reward-heading"
        *ngIf="membershipReward.rewardType === checkRewardType.pointStamp || membershipReward.rewardType === checkRewardType.point">
        {{membershipReward.closingPoint | number}}<span> {{'membership.points.unit.label' | translate}}</span></div>
      <!--show when reward type = pointstamp-->
      <div class="grap-line" *ngIf="membershipReward.rewardType === checkRewardType.pointStamp"></div>
      <!--show when reward type = pointstamp and stamp-->
      <div class="member-reward-heading"
        *ngIf="membershipReward.rewardType === checkRewardType.pointStamp || membershipReward.rewardType === checkRewardType.stamp">
        {{membershipReward.stampEarned}}/{{membershipReward.totalStamp}}<span> {{'membership.stamps.unit.label' |
          translate}}</span></div>
    </div>
  </div>
</div>
