export enum RouteName{
  Cart = "cart",
  Store = "store",
  OrderPayment = "order-payment",
  OrderSummary = "order-summary",
  OrderDetail = "order-detail",
  Profile = "profile",
  Home = "home",
  Login = "login",
  OdaringQr = "odaringqr",
  AreYouInterested = "are-you-interested",
  MobileDetected = "mobile-detected",
  Queue = "queue",
  App = "app"
}
