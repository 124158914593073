import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';
import { NIL } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailGuardGuard implements CanActivate {
  id: number;

  constructor(
    private router: Router,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (isPlatformServer(this.platformId)) {
      return false;
    }

    let activityGuid = route.queryParams.activityGUID;
    let orderTranId = route.queryParams.orderTranId;

    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];

    if(this.sessionStorageService.getItem("orderSummary")) {
      this.sessionStorageService.removeItem("orderSummary");
    }

    if(activityGuid || orderTranId){
      return true;
    }
    else if(currentRoute == "order-payment" && this.sessionStorageService.getItem("qrPayment")){
      let activityJson = {activityId: JSON.parse(this.sessionStorageService.getItem("qrPayment")).activityId}
      this.sessionStorageService.setItem("orderDetail", JSON.stringify(activityJson) );
      return true;
    }
    else if (this.sessionStorageService.getItem("orderDetail") && JSON.parse(this.sessionStorageService.getItem("orderDetail")).activityId) {
      return true;
    } else {
      this.router.navigate(['/my-order']);
      return false;
    }
  }
}
