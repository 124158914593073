import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UniversalGuard implements CanActivate {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  canActivate(): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    } else {
      return true;
    }
  }
}
