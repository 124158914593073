import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressType } from 'src/app/core/enums';
import { CommonAddressResponse } from 'src/app/core/models/CommonAddressResponse';
import { CXMCustomerAddressResponse } from 'src/app/core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import * as _ from 'lodash';
import { UserQuery } from 'src/app/core/user/user.query';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {
  @Input() savedAddress: CXMCustomerAddressResponse[];
  @Input() updateAddressSuccess: boolean;
  @Input() isHideHeader: boolean;
  @Input() isCartPage : boolean;
  @Input() cartGuid : string;
  @Input() customerId : number;
  @Output() displayAddAddressForm = new EventEmitter();
  @Output() editSelectedAddress = new EventEmitter();
  @Output() deleteSelectedAddress = new EventEmitter();
  @Output() outputSelectedAddress = new EventEmitter();
  @Output() closeDialog = new EventEmitter();

  addressType = AddressType;

  selectedAddress: CommonAddressResponse;
  addressSub$: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private userQuery: UserQuery,
  ) {}

  ngOnInit(): void {
    this.addressSub$ = this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });
  }

  addNewAddress() {
    this.displayAddAddressForm.emit(true);
  }

  editAddress(address: CXMCustomerAddressResponse) {
    this.editSelectedAddress.emit(address);
  }

  deleteAddress(address: CXMCustomerAddressResponse) {
    this.deleteSelectedAddress.emit(address.addressId);
  }

  selectAddres(address: CXMCustomerAddressResponse) {
    const addressData = {
      type: address.addrSysFlag,
      addressId: address.addressId + '',
      addressDesc: address.addressDesc,
      unit: address.unitNo,
      postalCode: address.delAddPostal,
      city: address.delAddCity,
      country: null,
      countryShortName: address.delCountryCode,
      fullAddress: address.delAddress,
      longitude: address.longitude,
      latitude: address.latitude,
      remarks: address.remarks
    };

    localStorage.setItem('recent-address', JSON.stringify(this.selectedAddress));
    this.userService.updateSelectedAddress(addressData);

    this.outputSelectedAddress.emit(addressData)
  }

  ngOnDestroy() {
    this.addressSub$?.unsubscribe();
  }
}
