import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GuestInfoCheckGuard implements CanActivate {

  constructor(
    private userService : UserService,
    private location : Location
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let customerProfileStatus = this.userService.getCustomerStatusCached();
    if(!customerProfileStatus){
      this.location.back();
      return false;
    }
    else{
      return true;
    }
  }

}
