import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

import { MembershipRoutingModule } from './membership-routing.module';
import { MembershipComponent } from './membership.component';
import { MembershipCardComponent } from './membership-card/membership-card.component';
import { MembershipOrderSummaryComponent } from './membership-order-summary/membership-order-summary.component';
import { JoinTermDataComponent } from './join-term-data/join-term-data.component';
import { MembershipRewardHistoryComponent } from './membership-reward-history/membership-reward-history.component';
import { QuitMemberTermComponent } from './quit-member-term/quit-member-term.component';
import { QuitMemberComponent } from './quit-member/quit-member.component';
import { MemberVoucherComponent } from './member-voucher/member-voucher.component';
import { MembershipVoucherDetailComponent } from './membership-voucher-detail/membership-voucher-detail.component';
import { MembershipStampComponent } from './membership-stamp/membership-stamp.component';
import { ExternalMembershipLoginComponent } from './external-membership-login/external-membership-login.component';

@NgModule({
  declarations: [
    MembershipComponent,
    MembershipCardComponent,
    MembershipOrderSummaryComponent,
    JoinTermDataComponent,
    MembershipRewardHistoryComponent,
    QuitMemberTermComponent,
    QuitMemberComponent,
    MemberVoucherComponent,
    MembershipVoucherDetailComponent,
    MembershipStampComponent,
    ExternalMembershipLoginComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MembershipRoutingModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [
    MembershipCardComponent,
    MembershipOrderSummaryComponent,
    JoinTermDataComponent,
    ExternalMembershipLoginComponent,
  ]
})
export class MembershipModule { }
