import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { StoreService } from 'src/app/store/store/store.service';
import { environment } from 'src/environments/environment';
import { StoreMode } from '../enums';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { MiniProgramService } from '../mini-program/mini-program.service';
import { CustomHeader, CustomRequest } from '../models/CustomRequest';
import { UserService } from '../user/user.service';
import { CustomService } from './custom.service';

@Injectable({
  providedIn: 'root'
})
export class ApplinkService {

  accessToken : string;
  refreshToken : string;

  constructor(
    private customService: CustomService,
    private userService : UserService,
    private storeService : StoreService,
    private router : Router,
    private miniProgramService : MiniProgramService
  ) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      if(dt){
        this.accessToken = dt && dt['accessToken'] ? dt['accessToken'] : '';
        this.refreshToken = dt && dt['refreshToken'] ? dt['refreshToken'] : '';
      }
    });
  }

  async getAppLinkToken(appId : string, tokenId : string, channelTag : string) {
    let respDt = null;
    respDt = await this.reqGetAppLinkToken(this.accessToken, channelTag, appId, tokenId);

    if(respDt instanceof HttpErrorResponse){

    }
    else{
      respDt = respDt && respDt['body'] ? respDt['body'] : null;

      this.miniProgramService.updateAppLinkTokenResponse(respDt);
      // we will set current order type like normal flow for now
      this.storeService.setCurrentOrderType(respDt.orderTypeFlag);
      // value is temporarily hardcoded, will be change to locDesc given by backend
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(respDt['locDesc']);
      this.router.navigate(['store', respDt.storeId, locDescWithoutSpaces]);
    }

    return respDt;
  }

  private async reqGetAppLinkToken(accessToken: string, channelTag : string, appId : string, tokenId: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.appLink.GetAppLinkToken,
      hostPath: environment.hostPath,
      queryParams: {
        appId: appId,
        tokenId: tokenId
      },
      headers: {
        accessToken: accessToken ? accessToken : undefined,
        channelTag: channelTag
      } as CustomHeader,
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }
}
