import { Pipe, PipeTransform } from '@angular/core';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';

@Pipe({
  name: 'modGroupProcess'
})
export class ModGroupProcessPipe implements PipeTransform {

  transform(value: any, modifierGrp : any[], subModifierGrp : SubModifierGrp[], isSubModifier : boolean){
    if((!modifierGrp || (modifierGrp && modifierGrp.length == 0)) && (!subModifierGrp || (subModifierGrp && subModifierGrp.length == 0))){
      return [];
    }

    if(isSubModifier){
      return subModifierGrp;
    }
    else{
      return modifierGrp;
    }
  }

}
