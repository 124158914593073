<div class="address-search-container">
  <form>
    <span class="p-input-icon-left" (click)="showScannerDialog()" *ngIf="!staticTableNo">
      <i class="oda-pin pin-icon"></i>
      <input class="text-truncate" type="text" pInputText [(ngModel)]="staticTableNo"
        name="locationSearchValue" placeholder="{{'location.scan.desc' | translate}}" (click)="showScannerDialog()"
        style="padding-right: 102px; opacity: 1 !important;" autocomplete="off">
      <i class="oda-scan scan-icon"></i>
    </span>

    <span class="p-input-icon-left" style="display: flex;" *ngIf="staticTableNo">
      <i class="oda-pin pin-icon"></i>
      <input class="text-truncate" type="text" pInputText [(ngModel)]="staticTableNo"
        name="locationSearchValue" placeholder="{{'location.scan.desc' | translate}}" (click)="showScannerDialog()"
        style="padding-right: 102px; opacity: 1 !important; cursor: pointer;" autocomplete="off">
      <div class="static-scan-change" (click)="showScannerDialog()">
        <i class="oda-scan static-scan-icon"></i>
        <p class="change-label">{{'dinein.qr.change.label' | translate}}</p>
      </div>
    </span>

    <div class="p-mt-auto hidden-sm">
      <button type="submit" class="submit-btn" (click)="closeLocationDialog()">
        {{'button.done' | translate}}
      </button>
    </div>
  </form>
</div>
