import { ChannelCustomerResponse } from './../../core/models/ChannelCustomerResponse';
import { ChannelData } from 'src/app/core/models';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageName } from 'src/app/core/enums/PageName';
import { UserChnlQuery } from '../userchnl/userchnl.query';

@Injectable({ providedIn: 'root' })
export class RoutingService {

  private homePageUrl: string = window.location.origin + '/' + environment.odaringChannel + '/home';
  private homePath = 'home';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userchnlQry: UserChnlQuery,
  ) {
    this.userchnlQry.isSubDomain$.subscribe((isSubDomain) => {
      if (isSubDomain) {
        this.homePageUrl = window.location.origin + '/home';
      } else {
        this.homePageUrl = window.location.origin + '/' + environment.odaringChannel + '/home';
      }
    });
  }

  navigate(pageName: PageName, channelData?: ChannelData) {
    switch (pageName) {
      case PageName.LoginPage: {
        this.router.navigate(["login"], { relativeTo: this.activatedRoute });
        break;
      }
      case PageName.ForgotPasswordPage: {
        this.router.navigate(["forgot-password"], { relativeTo: this.activatedRoute });
        break;
      }
      case PageName.SignupPage: {
        this.router.navigate(["register"], { relativeTo: this.activatedRoute });
        break;
      }
      case PageName.HomePage: {
        if (channelData && channelData?.homeChannelTag && channelData?.homeChannelTag !== channelData.channelTag) {
          this.navigateHomeUrl(channelData.homeChannelTag);
        } else {
          this.router.navigate([this.homePath], {  relativeTo: this.activatedRoute });
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  navigateHomeCheck(channelResponse : ChannelCustomerResponse){
    if(channelResponse.homeChannelTag != channelResponse.channelTag){
      this.navigateHomeUrl(channelResponse.homeChannelTag);
    }
    else{
      this.router.navigate(["home"], {  relativeTo: this.activatedRoute });
    }
  }

  navigateHomeUrl(specificChannel?: string) {
    if (specificChannel) {
      window.location.href = environment.origin + specificChannel + '/home';
    } else {
      window.location.href = this.homePageUrl;
    }
  }

  navigateChannelUrl(channelTag: string, page: string) {
    let upperCaseChannel = channelTag.toUpperCase();
    window.location.href = window.location.origin + '/' + upperCaseChannel + '/' + page;
  }
}
