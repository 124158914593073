<div class="activity-timeline" *ngIf="activityTimeline">
    <div class="activity" [class.active]="activityTimeline >= 2">
        <i class="color oda-c-menu-bell"><span class="path1"></span></i>
        <p>{{ 'order.form.received' | translate }}</p>
    </div>
    <hr>
    <div class="activity" [class.active]="activityTimeline >= 3">
        <i class="color oda-c-dish-timer"><span class="path1"></span></i>
        <p>{{ 'order.form.preparing' | translate }}</p>
    </div>
    <hr>
    <div class="activity" [class.active]="activityTimeline >= 4">
        <i class="color oda-c-delivery" [ngClass]="{ 'oda-c-delivery': activitySourceFlag == orderSourceFlag.WebDelivery || activitySourceFlag == orderSourceFlag.AppDelivery,
         'oda-c-takeaway': activitySourceFlag == orderSourceFlag.AppPickup || activitySourceFlag == orderSourceFlag.WebPickup}"
        *ngIf="activitySourceFlag != orderSourceFlag.WebQrDineIn && activitySourceFlag != orderSourceFlag.WebQrTakeaway &&
        activitySourceFlag != orderSourceFlag.AppQrDineIn && activitySourceFlag != orderSourceFlag.AppQrTakeaway &&
        activitySourceFlag != orderSourceFlag.WebDineIn && activitySourceFlag != orderSourceFlag.AppDineIn">
          <span class="path1"></span>
        </i>
        <i class="color duotone oda-d-served"
        *ngIf="activitySourceFlag == orderSourceFlag.WebQrDineIn || activitySourceFlag == orderSourceFlag.WebQrTakeaway ||
        activitySourceFlag == orderSourceFlag.AppQrDineIn || activitySourceFlag == orderSourceFlag.AppQrTakeaway ||
        activitySourceFlag == orderSourceFlag.WebDineIn || activitySourceFlag == orderSourceFlag.AppDineIn"></i>
        <p *ngIf="(activitySourceFlag == orderSourceFlag.WebDelivery || activitySourceFlag == orderSourceFlag.AppDelivery)">{{ 'order.form.delivering' | translate }}</p>
        <p *ngIf="(activitySourceFlag == orderSourceFlag.AppPickup || activitySourceFlag == orderSourceFlag.WebPickup)">{{ 'order.form.ready.pickup' | translate }}</p>
        <p *ngIf="activitySourceFlag == orderSourceFlag.WebQrDineIn || activitySourceFlag == orderSourceFlag.WebQrTakeaway ||
        activitySourceFlag == orderSourceFlag.AppQrDineIn || activitySourceFlag == orderSourceFlag.AppQrTakeaway ||
        activitySourceFlag == orderSourceFlag.WebDineIn || activitySourceFlag == orderSourceFlag.AppDineIn">{{"order.form.served" | translate}}</p>
    </div>
    <hr>
    <div class="activity" [class.active]="activityTimeline === 5">
        <i class="color oda-c-utensils"><span class="path1"></span></i>
        <p>{{ 'order.form.completed' | translate }}</p>
    </div>
</div>
