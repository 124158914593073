import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-register-failed',
  templateUrl: './register-failed.component.html',
  styleUrls: ['./register-failed.component.scss']
})
export class RegisterFailedComponent implements OnInit {

  @Output() tryAgain = new EventEmitter();
  @Output() proceed = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickTryAgain(){
    this.tryAgain.emit();
  }

  onClickProceed(){
    this.proceed.emit();
  }
}
