import { Pipe, PipeTransform } from '@angular/core';
import { ItemMenuTags } from 'src/app/core/models/ItemMenuTags';

@Pipe({
  name: 'highlightSearchText'
})
export class HighlightSearchTextPipe implements PipeTransform {

  transform(value: string, args : string, itemMenuTags?: ItemMenuTags[]): any {
    let menuTagValue = '';
    if (itemMenuTags) {
      for (let index = 0; index < itemMenuTags.length; index++) {
        menuTagValue += "<img class='menu-tag-img' src=" + itemMenuTags[index].imageUrl + ">";
      }
    }

    if(!args){
      return menuTagValue + "<span>" + value + "</span>";
    }

    const regex = new RegExp(args, 'gi');
    // value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const match = value.match(regex);

    if(!match){
      return value;
    }

    return menuTagValue + "<span>" + value.replace(regex, `<span class='highlight'>${match[0]}</span>`) + "</span>";
  }

}
