import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'evenCenterGift'
})
export class EvenCenterGiftPipe implements PipeTransform {

  transform(pointers: any, index: number): boolean {
    let stampTotalCount = pointers.length - 1;
    let isEvenNumber = stampTotalCount % 2 == 0;
    let isMiddleStamp = stampTotalCount / 2 == index ? true : false;
    return isEvenNumber && isMiddleStamp;
  }

}
