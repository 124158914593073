<app-download-app></app-download-app>

<div class="otp-container">
  <div class="top-header hidden-sm">
    <!--register heading-->
    <div class="header-content">
      <h1 class="heading-2">{{ 'otp.forgotPassword.header.des.2' | translate }}</h1>
    </div>
    <div class="mascot-image">
      <img src="assets/mascot/Mascot_phone@2x.webp" alt="odaring mascot"/>
    </div>
  </div>

  <!--mobile : header-->
  <div class="hidden-lg">
    <div class="mobile-header">
      <div class="sub-header">
        <app-back-button class="back-btn" *ngIf="showHeaderBackBtn" [manualBackEvent]="true" (onManualBackEvent)="onClickBack()"></app-back-button>
        <h1 class="heading-14">{{'otp.forgotPassword.header.des.2' | translate}}</h1>
      </div>
    </div>
  </div>

  <div class="otp-content">
    <div class="otp-content-view">
      <img class="hidden-lg" src="assets/mascot/Mascot_phone@2x.webp" alt="odaring mascot"/>
      <div class="heading-12 otp-description">{{ 'otp.description.1' | translate }}
      </div>
      <span *ngIf="mobileNo" class="otp-phone" (click)="changeOtpPhone()">
        +{{mobileNo}}
        <button class="otp-phone-edit">
          <i class="oda-d-edit duotone"></i>
        </button>
      </span>

      <div class="form-content">
        <app-otp-form [isPhoneNoExist]="isPhoneNoExist" [loginForm]="loginForm" [isSignUpForm]="isSignUpForm" [isQueue]="isQueue"
        (onShowBackBtn)="onShowBackBtn($event)"></app-otp-form>

        <button class="continue-as-guest-btn" (click)="onClickContinueAsGuest()" *ngIf="showContinueAsGuest">
          {{'button.continue.as.guest' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
