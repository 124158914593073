<div class="profile-nav-container">
  <!--header: logout button, QR, profile-->
  <div class="header">
    <div class="scan-logout-btn" [ngClass]="{'scan-btn-not-loggedIn ': isLoggedIn === false }">
      <button (click)="showScannerDialog()"><i class="oda-scan"></i></button>
      <button (click)="onLogout()" *ngIf="isLoggedIn === true"><i class="oda-sign-out"></i></button>
    </div>

    <div class="sub-content">
      <!--guest-->
      <div class="guest" *ngIf="isLoggedIn === false && (channelId | isShowMascot)">
        <img src="assets/mascot/Mascot_avatar-white-bg@2x.webp" alt="odaring mascot"/>
      </div>
      <!--user-->
      <div class="qr-content" (click)="showQRDialog()" *ngIf="isLoggedIn === true">
        <ejs-qrcodegenerator width="160px" height="110px"
                             #barcode id="barcode" mode="SVG"
                             value="{{encryptedCustID}}"></ejs-qrcodegenerator>
      </div>
      <button class="login-btn" (click)="onLogin()" *ngIf="isLoggedIn === false">{{ 'landingPage-header.5' | translate }}</button>

      <div class="user-info" (click)="onNavigate('editProfile')" *ngIf="isLoggedIn === true">
        <h1 class="text-truncate">{{name}}</h1>
        <h2>{{phoneNumber}}</h2>
        <!--edit user profile detail-->
        <button type="button" class="edit-btn">
          <i class="duotone oda-d-edit"></i>
        </button>
      </div>
    </div>
  </div>

  <!--content lists-->
  <div class="content">
    <!--manage address-->
    <div *ngIf="isLoggedIn === true">
      <h1 class="profile-heading-1" (click)="onNavigate('MngAddr')">
        <i class="oda-pin"></i>
        {{'navigation.Menu.des.2' | translate}}
      </h1>
      <hr />
    </div>
    <!--my fav-->
    <div *ngIf="isLoggedIn === true">
      <h1 class="profile-heading-1" (click)="onNavigate('fav')">
        <i class="oda-heart"></i>
        {{'navigation.Menu.des.6' | translate}}
      </h1>
      <hr />
    </div>
    <!--payment method-->
    <div *ngIf="isLoggedIn === true">
      <h1 class="profile-heading-1" (click)="onNavigate('paymentMtd')">
        <i class="oda-wallet"></i>
        {{'navigation.Menu.des.3' | translate}}
      </h1>
      <hr />
    </div>
    <!--setup Password-->
    <div *ngIf="isLoggedIn === true && !passwordFlag">
      <h1 class="profile-heading-1" (click)="onNavigate('setupPw')">
        <i class="oda-pass-lock"></i>
        {{'setup.password.title' | translate}}
      </h1>
      <hr />
    </div>
    <!--change Password-->
    <div *ngIf="isLoggedIn === true && passwordFlag">
      <h1 class="profile-heading-1" (click)="onNavigate('changePw')">
        <i class="oda-pass-lock"></i>
        {{'change.password.header' | translate}}
      </h1>
      <hr />
    </div>
    <!--refer friend: logged in user-->
    <div *ngIf="isLoggedIn === true && false">
      <h1 class="profile-heading-1" (click)="onNavigate('RefFrd')">
        <i class="oda-switch-user"></i>
        {{'navigation.Menu.des.8' | translate}}
      </h1>
      <hr />
    </div>
    <!--refer friends: guest-->
    <div *ngIf="isLoggedIn === true && false">
      <h1 class="profile-heading-1" (click)="onLogin()">
        <i class="oda-switch-user"></i>
        {{'navigation.Menu.des.8' | translate}}
      </h1>
      <hr />
    </div>
    <!--email-->
    <h1 class="profile-heading-1" (click)="onNavigate('emailus')">
      <i class="oda-mail"></i>
      {{'navigation.Menu.des.12' | translate}}
    </h1>
    <hr />
    <div *ngIf="isLoggedIn === false">
      <!--faq-->
      <h1 class="profile-heading-1" (click)="onNavigate('faq')">
        <i class="oda-faq"></i>
        {{'navigation.Menu.des.13' | translate}}
      </h1>
      <hr />
      <!--privacy and policy-->
      <h1 class="profile-heading-1" (click)="onNavigate('privacyPolicy')">
        <i class="oda-shield-lock"></i>
        {{'navigation.Menu.des.14' | translate}}
      </h1>
      <hr />
      <!--term and condition-->
      <h1 class="profile-heading-1" (click)="onNavigate('tnc')">
        <i class="oda-card-info"></i>
        {{'navigation.Menu.des.15' | translate}}
      </h1>
      <hr />
      <!--languages-->
      <app-language></app-language>
    </div>
    <!--setting-->
    <div *ngIf="isLoggedIn === true">
      <h1 class="profile-heading-1" (click)="onNavigate('setting')">
        <i class="oda-settings"></i>
        {{'navigation.Menu.des.settings' | translate}}
      </h1>
    </div>

    <div class="heading-13 version-content">{{'profile.version' | translate}} {{buildVersion}}</div>
  </div>

</div>
