import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderH } from 'src/app/core/models/OrderH';
import { DetailActivity } from 'src/app/home/order/order.model';

@Component({
  selector: 'app-applied-promo-voucher',
  templateUrl: './applied-promo-voucher.component.html',
  styleUrls: ['./applied-promo-voucher.component.scss']
})
export class AppliedPromoVoucherComponent implements OnInit {
  @Input() orderH: OrderH | DetailActivity;
  @Input() isStorePage?: boolean;
  @Input() isQrTokenResponse? : boolean;

  @Output() onApplyPromo = new EventEmitter();
  @Output() OnRemovePromo = new EventEmitter<string>();
  @Output() onSelectVoucher = new EventEmitter();
  @Output() onRemoveVoucher = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickApplyPromo() {
    this.onApplyPromo.emit();
  }

  onClickRemovePromo(promotionCode: string) {
    this.OnRemovePromo.emit(promotionCode);
  }

  onClickSelectVoucher() {
    this.onSelectVoucher.emit();
  }

  onClickRemoveVoucher(voucherNo: string) {
    this.onRemoveVoucher.emit(voucherNo);
  }

}
