<div class="register-failed-content">
  <div class="mascot-container">
    <img src="assets/mascot/Mascot_sad-Grey@2x.webp" alt="odaring mascot">
  </div>

  <h3 class="failed-title">{{"register.failed.title.1" | translate}}</h3>
  <p class="failed-desc heading-9">{{"register.failed.desc.1" | translate}}</p>

  <div class="action-button-container">
    <button class="try-again-button" (click)="onClickTryAgain()">{{"button.try.again" | translate}}</button>
    <button class="proceed-button" (click)="onClickProceed()">{{"button.proceed.to.queue" | translate}}</button>
  </div>
</div>
