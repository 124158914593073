import { Component, OnInit } from '@angular/core';
import { LoadingService } from './../services/loading.service';
import { RegisterService } from './../services/register.service';
import { ProfileService } from './../services/profile.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  providers: [LoadingService, RegisterService, ProfileService],
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    const pgName = history.state.pageName;
    this.loadingService.processLoading(pgName);
  }


}
