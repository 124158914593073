import { Pipe, PipeTransform } from '@angular/core';
import { Modifier } from 'src/app/core/models/Modifier';
import { SubModifier } from 'src/app/core/models/SubModifier';

@Pipe({
  name: 'formatSubmodifiers'
})
export class FormatSubmodifiersPipe implements PipeTransform {

  transform(modifier: any) {
    let subModifier : SubModifier[] = [];

    if(modifier.subModifierGrps){
      (modifier as Modifier).subModifierGrps.map(subModGrp => {
        if(subModGrp.qtyGrp > 0){
          let filterSubMod = subModGrp.subModifiers.filter(subMod => subMod.qty > 0);
          subModifier = subModifier.concat(filterSubMod);
        }
      })
    }
    return subModifier;
  }

}
