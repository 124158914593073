import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChannelStore, ChannelState } from './channel.store';
import { Channel } from './channel.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelQuery extends QueryEntity<ChannelState, Channel> {

  currentChannelData$ = this.selectFirst(state => state.data);
  currentChannel$ = this.selectFirst();
  currentOrderTyedata$ = this.selectFirst(state => state.data);

  constructor(protected store: ChannelStore) {
    super(store);
  }

}
