import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ChannelData } from 'src/app/core/models';
import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { RewardService } from '../../services/reward.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-voucher-code',
  templateUrl: './search-voucher-code.component.html',
  styleUrls: ['./search-voucher-code.component.scss']
})
export class SearchVoucherCodeComponent implements OnInit {
  @Input() storeId: number;
  @Input() isLoggedIn: boolean;
  @ViewChild('searchBar') searchBarEl: ElementRef;

  channelData: ChannelData;
  searchVouchers: VoucherTypeExtraResponse[] = [];
  channelId: number;
  searchCode: string = "";
  searchedCode: string = "";

  searchFgroup: UntypedFormGroup = this.fb.group({
    searchCode: ['']
  });

  isLoading : boolean = false;

  constructor(private rewardService: RewardService,
    private channelService: ChannelService,
    private fb: UntypedFormBuilder) { }

  get searchFg() { return this.searchFgroup }

  get searchF() { return this.searchFgroup.controls; }

  async ngOnInit() {
    await this.channelService.getChannelDataCheck();
    this.channelData = this.channelService.getChannelData();
    this.channelId = this.channelData.channelId;

    this.searchBarEl.nativeElement.focus();

    this.searchFgroup.get('searchCode')!.valueChanges
    .pipe(
      debounceTime(1000)
    )
    .subscribe(value => {
      this.onSearch();
    });
  }

  onHide() {
    this.rewardService.searchVoucherDialog.next({isShow: false});
  }

  async onSearch(clear?: boolean) {
    this.isLoading = true;
    this.searchCode = this.searchedCode = clear? "": this.searchFgroup.value.searchCode;

    if(!clear && this.searchCode) {
      this.searchVouchers = <VoucherTypeExtraResponse[]>await this.rewardService.getStoreVoucherType(this.storeId, this.channelId, this.searchCode);
    } else {
      this.searchVouchers = [];
      this.searchFg.get('searchCode').reset();
      this.searchBarEl.nativeElement.focus();
    }

    this.isLoading = false;
  }
}
