<div class="delete-acc-tnc-container">
    <div class="header hidden-lg">
        <i class="oda-chevron-left" (click)="onHideDialog()"></i>
        <h1>{{ 'button.delete.account' | translate }}</h1>
    </div>
    <div class="content">
        <h2>{{ 'navigation.Menu.des.19' | translate }}</h2>
        <div>
            <ol type="1">
                <li>For account security purposes, only you can delete your Odaring official account and delete its Odaring official account at any time.</li>
                <li>When you proceed with the deletion procedure of the Odaring Official Account, you shall immediately lose the right to use the Odaring Official Account.</li>
                <li>Deleting your Odaring account is permanent, and data loss is irretrievable.</li>
                <li>
                    When the account deletion is completed, all related contractual relationships between you and Odaring are terminated. The consequences of account deletion include to the following: -
                    <ol type="i">
                        <li>You will not be able to use this account to log in to the Odaring apps.</li>
                        <li>You will not be able to perform any operation that requires account access.</li>
                        <li>You will not be able to retrieve the personal information and historical information under the account.</li>
                        <li>You will not be able to use Odaring apps services.</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
    <div class="footer">
        <div class="check-box-content">
            <label class="checkbox-container" for="anonymous">
              <input id="anonymous" type="checkbox"  [value]="isAgree" (change)="onAgree($event)" [checked]="isAgree" />
              <span class="checkmark"></span>
              <span>{{'delete.acc.agree.tnc.desc' | translate}}</span>
            </label>
        </div>
        <button type="button" [disabled]="!isAgree" (click)="onClickContinue()">{{ 'button.continue' | translate }}</button>
    </div>
</div>
