import { environment } from './../../../../environments/environment';
import { Country } from './../../../core/models/Country';
import { Component, OnInit } from "@angular/core";
import { CommonAddressResponse } from './../../../core/models/CommonAddressResponse';
import { ErrorLocation } from 'src/app/core/enums/ErrorLocation';
import { UserService } from "src/app/core/user/user.service";
import { UserQuery } from "src/app/core/user/user.query";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AnimationOptions } from 'ngx-lottie';
import { QrCartService } from "src/app/core/qr-cart/qr-cart.service";
import { ChannelService } from "src/app/home/channel/channel.service";
import { RoutingService } from 'src/app/home/services/routing.service';
import { ChannelCustomerResponse } from 'src/app/core/models/ChannelCustomerResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionStorageService } from '../../storage/session-storage.service';



@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.scss'],
})
export class LandingPagesComponent implements OnInit {
  displayPosition: boolean;
  position: string;
  selectedCity3: string;
  countries: Country[] = [
    {
      countryCode: 'MY',
      name: 'Malaysia',
      image: 'assets/countryFlag/malaysiaFlag.png'
    },
    {
      countryCode: 'BN',
      name: 'Brunei',
      image: 'assets/countryFlag/BruneiFlag.png'
    },
    {
      countryCode: 'SG',
      name: 'Singapore',
      image: 'assets/countryFlag/SingaporeFlag.png'
    },
  ];
  selectedCountryCode: string = 'MY';
  item: string;
  errorLocationCode: number = 0;
  errorLocation = ErrorLocation;
  selectedAddress: CommonAddressResponse = null;
  loginDisplayName: string = '';
  eventsSubject: Subject<void> = new Subject<void>();
  channelData: ChannelCustomerResponse = null;
  firstRetryDefaultCountry: boolean = true;

  constructor(
    private userService: UserService,
    private router: Router,
    private userQuery: UserQuery,
    private qrCartService : QrCartService,
    private channelService: ChannelService,
    private routingService: RoutingService,
    private sessionStorageService: SessionStorageService
  ) {
    this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
      this.countryChecking(address);
    });

    this.userQuery.isLoggedIn$.subscribe(userData => {
      if (userData && userData.id !== 999) {
        this.loginDisplayName = userData.displayName;
      } else {
        this.loginDisplayName = '';
      }
    });
  }

  ngOnInit() {
    let parseURL: string = location.href.split("/")[3];

    if (parseURL && parseURL.toUpperCase()) {
      this.selectedCountryCode = parseURL.toUpperCase();
      this.countryChange(parseURL.toUpperCase());
    } else {
      this.getChannelByCountryCode(this.selectedCountryCode);
    }

    this.qrCartService.quitQrDineInCheck();
    this.qrCartService.orderSummaryQuitCheck();
  }

  selectAddress(address: CommonAddressResponse) {
    this.selectedAddress = address;
    this.countryChecking(address);
  }

  navigateHome() {
    if (this.selectedAddress && this.errorLocationCode === this.errorLocation.NONE) {
      this.userService.updateSelectedAddress(this.selectedAddress);
      this.routingService.navigateHomeUrl(this.channelData.channelTag);
    } else if (!this.selectedAddress) {
      this.errorLocationCode = this.errorLocation.EMPTY_ADDRESS;
    }
  }

  navigateLogin() {
    this.router.navigateByUrl('/login', { state: { isFromLanding: true, showContGuest: true } });
  }

  showPositionDialog(position: string) {
    this.position = position;
    this.displayPosition = true;
  }

  async countryChange(selectedCountryCode: string) {
    await this.getChannelByCountryCode(selectedCountryCode);

    if (this.selectedAddress && this.selectedAddress.countryShortName !== selectedCountryCode) {
      this.userService.updateSelectedAddress(null);
      this.eventsSubject.next();
    }

    this.channelService.selectedChannel = this.channelData;
  }

  countryChecking(address: CommonAddressResponse) {
    if (address) {
      if (this.countries.find(country => country.countryCode === address.countryShortName)) {
        this.errorLocationCode = this.errorLocation.NONE;
        this.selectedCountryCode = address.countryShortName;
      } else if (!address.countryShortName) {
        this.userService.updateSelectedAddress(null);
      }
    }
  }

  async getChannelByCountryCode(countryCode: string) {
    let channelData = await this.channelService.switchChannel(undefined, undefined, countryCode);

    if (channelData instanceof HttpErrorResponse && this.firstRetryDefaultCountry) {
      this.countryChange(this.countries[0].countryCode);
      this.firstRetryDefaultCountry = false;
    } else {
      this.channelData = channelData;
    }
  }

  options: AnimationOptions = {
    path: '/assets/Landing-Page-Animation.json',
    autoplay: true,
    loop: true
  };
}

