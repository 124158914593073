import { RouteName } from 'src/app/core/enums/RouteName';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { Router } from '@angular/router';
import { Inject, PLATFORM_ID  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService {

  activeRequests: number = 0;
  isManualLoad : boolean = false;

  /**
   * URLs for which the loading screen should not be enabled
   */
   skippUrls = [
    '/odaringqr',
  ];

  constructor(
    private loadingScreenService: LoaderService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.loadingScreenService.isManualLoad$.subscribe(isManual => {
      this.isManualLoad = isManual ? true : false;
    })
   }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];
    let displayLoadingScreen = true;

    if (request.url.includes(environment.apis.order.GetActivitySummary) || request.url.includes(environment.apis.user.UpdateChatId) ||
      request.url.includes(environment.apis.voucher.GetNearbyVoucherType) || request.url.includes(environment.apis.voucher.GetCustomerClaimedVoucher) ||
      request.url.includes(environment.apis.voucher.GetVoucherTypeCategories) || request.url.includes(environment.apis.voucher.GetCustomerVoucherHistory) ||
      request.url.includes(environment.apis.channel.GetChannelHomeSection) || request.url.includes(environment.apis.review.GetStoreReviewData) ||
      (currentRoute == RouteName.OrderSummary && (request.url.includes(environment.apis.order.GetOrderSummary) ||
      request.url.includes(environment.apis.order.GetActivityByLinkId) || request.url.includes(environment.apis.order.GetActivityByOrders) ||
      request.url.includes(environment.apis.order.GetActivityByRsvTokenId))) ||
      (currentRoute == RouteName.OrderDetail && (request.url.includes(environment.apis.order.GetActivityDetail))) ||
      request.url.includes(environment.apis.membership.GetCustomerMemberships) || request.url.includes(environment.apis.membership.GetMembershipRewardHistory)
      || currentRoute == RouteName.App || (currentRoute == RouteName.Queue && request.url.includes(environment.apis.queue.GetQueueByToken))
      ) {
      displayLoadingScreen = false;
    }

    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(this.router.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    if (displayLoadingScreen && !this.isManualLoad) {
      if (this.activeRequests === 0 || !isPlatformBrowser(this.platformId)) {
        this.loadingScreenService.startLoading();
      }

      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          // added isManualLoad flag to stop interceptor from stopping the loader
          if (this.activeRequests === 0 && !this.isManualLoad && isPlatformBrowser(this.platformId)) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  };
}
