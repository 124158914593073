import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-quit-queue-message',
  templateUrl: './quit-queue-message.component.html',
  styleUrls: ['./quit-queue-message.component.scss']
})
export class QuitQueueMessageComponent implements OnInit {

  @Input() channelId: number;
  @Output() onCancel = new EventEmitter();
  @Output() backToOdaring = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickCancel(){
    this.onCancel.emit();
  }

  onClickBackToOdaring(){
    this.backToOdaring.emit();
  }

}
