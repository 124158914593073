import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { DefaultComponent } from './home/template/default/default.component';

import { OtpComponent } from './account/otp/otp.component';
import { CartModule } from './cart/cart.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';
import { ShortcutPanelComponent } from './shared/components/shortcut-panel/shortcut-panel.component';
import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';

import { DefaultService } from './home/services/default.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxImageCompressorModule } from 'ngx-image-compressor';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json?v=' + environment.version);
}

export function TranslateCacheFactory(translateService: any, translateCacheSettings: any) {
  return new TranslateCacheService(translateService, translateCacheSettings);
}
import { HomeComponent } from './home/home.component';
import { SwiperModule } from 'swiper/angular';
import { akitaConfig } from '@datorama/akita';
import { APP_BASE_HREF } from '@angular/common';
import { ConfigService } from './config.service';
import { PaymentSuccessComponent } from './payment/payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment/payment-failed/payment-failed.component';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';

import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { LoaderInterceptorService } from 'src/app/core/loader-services/loader-interceptor.service';
import { LandingPagesComponent } from './shared/components/landing-pages/landing-pages.component'
import { TokenInterceptor } from './interceptor/token.interceptor';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { TimeInterceptor } from './core/timeInterceptor/time-interceptor.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SeoService } from 'src/app/shared/services/seo.service';
import { ApplicationInsights, IConfig, IConfiguration } from '@microsoft/applicationinsights-web';

akitaConfig({ resettable: true });

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    HomeComponent,
    DefaultComponent,
    OtpComponent,
    ShortcutPanelComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent
  ],
  imports: [
    // FilterQtyPipe,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SharedModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    CartModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    SwiperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TranslateCacheModule.forRoot({
      cacheService: {
        provide: TranslateCacheService,
        useFactory: TranslateCacheFactory,
        deps: [TranslateService, TranslateCacheSettings]
      }
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    NgxImageCompressorModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' },
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (config: ConfigService) => config.defaultBaseHref(),
      deps: [ConfigService]
    },
    DefaultService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    LandingPagesComponent,
    NgxImageCompressService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeInterceptor,
      multi: true
    },
    SeoService,
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory
    },

  ],
  exports: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }

export function appInsightsFactory(): ApplicationInsights {
  const config: IConfig & IConfiguration = {
    instrumentationKey: environment.appInsights.instrumentationKey,
    disableTelemetry: environment.appInsights.instrumentationKey === "",
    enableCorsCorrelation: true
  };

  // send telemetry immediately
  config.maxBatchInterval = 0;
  config.maxBatchSizeInBytes = 0;
  config.loggingLevelConsole = 2;

  return new ApplicationInsights({ config: config });
}
