<div class="membership-reward-history-container" *ngIf="!isOnLoad">
  <div class="mrh-header" *ngIf="!isMobileView">
    <i class="oda-times" (click)="onClickClose()"></i>
    <h1>{{'membership.my.reward.history.label' | translate}}</h1>
  </div>
  <div class="mrh-header" *ngIf="isMobileView">
    <i class="oda-chevron-left" (click)="onClickClose()"></i>
    <h1>{{'membership.my.reward.history.label' | translate}}</h1>
  </div>

  <div class="mrh-content" *ngIf="((myHistoryList && myHistoryList.length > 0) && myHistoryDateList); else noHistory"
    (scroll)='onHistoryScroll($event)'>
    <div class="hideline"></div>
    <div *ngFor="let myHistoryList of myHistoryDateList; let i = index;">
      <div class="MRHistory-date">
        <div class="line"></div>
        <div class="mrh-date">
          {{ myHistoryList.tranDate | date: "dd/MM/yyyy"}}
        </div>
        <div class="line-2"></div>
      </div>
      <div *ngFor="let itm of myHistoryList.historyList">
        <div class="MRHistory-detail "
          [ngClass]="{'MRHistory-detail-2': itm.tranCode === tranCode.SRD || itm.tranCode === tranCode.ERD || itm.tranCode === tranCode.QRS }">
          <div class="detail-info"
            [ngClass]="{'textAlignStart': itm.tranCode === tranCode.SRD || itm.tranCode === tranCode.ERD || itm.tranCode === tranCode.QRS }">
            <h1 class="detail-orderNo" *ngIf="itm.orderNo">#{{itm.orderNo}}</h1>
            <h1 class="detail-title">{{itm.locShortDesc}}</h1>
            <h1 class="detail-title">{{itm.vchTypTitle}}</h1>
            <h1 class="detail-title">{{itm.levelDesc}}</h1>
            <div
              *ngIf="itm.tranCode === tranCode.ERW || itm.tranCode === tranCode.SRW || itm.tranCode === tranCode.STU">
              <!--receive voucher-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.voucher">
                <h1 class="desc-1">{{'membership.receive.voucher' | translate}}</h1>
                <h1 class="desc-2">+{{itm.tranQty}} <span>{{'order.summary.qr.dinein.voucher' | translate}}</span></h1>
              </div>
              <!--earn stamp-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.stamp">
                <h1 class="desc-1">{{'membership.collect.stamp' | translate}}</h1>
                <h1 class="desc-2">+{{itm.tranQty}} <span>{{'membership.stamp.unit.label' | translate}}</span></h1>
              </div>
              <!--earn point-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.point">
                <h1 class="desc-1">{{'membership.earn.point' | translate}}</h1>
                <h1 class="desc-2">+{{itm.tranQty}} <span>{{'membership.points.unit.label' | translate}}</span></h1>
              </div>
              <!--renew membership-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.renewMembership">
                <h1 class="desc-1">{{'membership.renewal.desc' | translate}}</h1>
              </div>
              <!--upgrade membership-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.upgradeMembership">
                <h1 class="desc-1">{{'membership.upgrade.desc' | translate}}</h1>
              </div>
            </div>
            <div
              *ngIf="itm.tranCode === tranCode.SRD || itm.tranCode === tranCode.ERD || itm.tranCode === tranCode.QRS">
              <!--Use Voucher-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.voucher">
                <h1 class="desc-1">{{'membership.use.voucher' | translate}}</h1>
                <h1 class="desc-3">-{{itm.tranQty}} <span>{{'order.summary.qr.dinein.voucher' | translate}}</span></h1>
              </div>
              <!--redeem point-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.point">
                <h1 class="desc-1">{{'membership.redeem.point' | translate}}</h1>
                <h1 class="desc-3">-{{itm.tranQty}} <span>{{'membership.points.unit.label' | translate}}</span></h1>
              </div>
              <!--downgrade membership-->
              <div class="detail-desc" *ngIf="itm.rewardType === rewardType.downgradeMembership">
                <h1 class="desc-1">{{'membership.downgrade.desc' | translate}}</h1>
              </div>
            </div>
          </div>
          <div class="partition-line">
            <div class="dot"
              [ngClass]="{'dot-red': itm.tranCode === tranCode.SRD || itm.tranCode === tranCode.ERD || itm.tranCode === tranCode.QRS }">
            </div>
          </div>
          <div class="detail-date"
            [ngClass]="{'textAlignEnd': itm.tranCode === tranCode.SRD || itm.tranCode === tranCode.ERD || itm.tranCode === tranCode.QRS }">
            <h1 class="date">{{itm.tranDate | date: 'hh:mm a'}}</h1>
          </div>
        </div>
      </div>
    </div>

    <ng-template [ngIf]="isOnListLoad">
      <div class="loadMore-loader">
        <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
      </div>
    </ng-template>
  </div>

  <ng-template #noHistory>
    <div class="mrh-noHistory">
      <img src="assets/mascot/Mascot_sad-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot">
      <h1>{{'membership.rewards.history.empty.desc' | translate}}</h1>
      <button *ngIf="isMobileView" (click)="onClickClose()">{{'button.back' | translate}}</button>
    </div>
  </ng-template>
</div>

<div class="list-loader" *ngIf="isOnLoad && !isOnFirstLoad">
  <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
</div>
