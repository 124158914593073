import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qr-merge-message',
  templateUrl: './qr-merge-message.component.html',
  styleUrls: ['./qr-merge-message.component.scss']
})
export class QrMergeMessageComponent implements OnInit {

  @Output() mergeOrder = new EventEmitter();
  @Output() splitOrder = new EventEmitter();
  @Input() custname : string;
  @Input() channelId: number;

  constructor() { }

  ngOnInit(): void {
  }

  onClickNo(){
    this.splitOrder.emit();
  }

  onClickYes(){
    this.mergeOrder.emit();
  }

}
