<div class="p-d-flex p-jc-center">
  <div class="wink-mascot" *ngIf="!sessionExpired && (channelId | isShowMascot)">
    <img src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot"/>
  </div>

  <div class="sleep-mascot" *ngIf="sessionExpired && (channelId | isShowMascot)">
    <img src="assets/mascot/Mascot_sleep-Grey@2x.webp" alt="odaring mascot"/>
  </div>
</div>

<div class="login-prompt-title heading-11" *ngIf="!sessionExpired">{{"alert.cart.title" | translate}}</div>
<div class="login-prompt-title heading-11" *ngIf="sessionExpired">{{"login.prompt.session.expired" | translate}}</div>

<div class="login-prompt-description heading-10" *ngIf="!sessionExpired">{{"alert.cart.description" | translate}}</div>
<div class="login-prompt-description heading-10" *ngIf="sessionExpired">{{"session.expired" | translate}}</div>

<div class="login-button-container" style="margin-top: 12px;" *ngIf="!sessionExpired">
  <button class="login-action-button heading-11" (click)="onClickLogin()">
    {{"button.login.now" | translate}}
  </button>
</div>

<div class="p-d-flex p-jc-center" *ngIf="sessionExpired">
  <button class="session-expired-button" (click)="onClickLogin()">{{"button.login" | translate}}</button>
</div>
