import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray} from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { ReviewService } from 'src/app/core/services/review.service';
import { ReviewCategoryType } from 'src/app/core/enums/ReviewCategoryType';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { ChannelReviewTextResponse } from 'src/app/core/models/ChannelReviewTextResponse';
import { OrderD } from 'src/app/core/models/OrderD';
import { UpdateStoreReviewRequest } from 'src/app/core/models/UpdateStoreReviewRequest';
import { DetailActivity } from 'src/app/home/order/order.model';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { UserQuery } from 'src/app/core/user/user.query';

@Component({
  selector: 'app-rate-form',
  templateUrl: './rate-form.component.html',
  styleUrls: ['./rate-form.component.scss']
})
export class RateFormComponent implements OnInit, OnDestroy {
  @Input() reviewCategory: ChannelReviewTextResponse[];
  @Input() order: DetailActivity;
  @Output() onShowRate = new EventEmitter<boolean>();
  @Output() onShowRateCompleted = new EventEmitter<boolean>();
  rateForm: UntypedFormGroup;
  order$: Subscription;
  orderDatas: OrderD[];
  selectedStar: number;
  showRateReview: boolean;
  mascotImage: string;
  channelId: number;
  isAnonymous: boolean = true;
  isLoggedIn: boolean = false;
  emoji: string = "";
  questionText: string = "";

  constructor(private reviewService: ReviewService,
    private channelService: ChannelService,
    private formBuilder: UntypedFormBuilder,
    private popupMessageService: PopupMessageService,
    private userQuery: UserQuery) {}

  async ngOnInit() {
    this.initForm();
    this.mascotImage = 'Mascot_holding-cutlery@2x.webp';

    await this.channelService.getChannelDataCheck();
    this.channelId = this.channelService.getChannelId();

    this.userQuery.isLoggedIn$.subscribe(val => {
      this.isLoggedIn = val? true : false;
    });

    if (this.order) {
      this.orderDatas = this.order? this.order.orderData.orderDs.slice(): null;

      this.items.clear();
      for(let i=0; i < this.orderDatas.length; i++) {
        const control = this.formBuilder.group({
          itemCode: this.orderDatas[i].itemCode,
          isLike: true
        });
        this.items.push(control);
      }
    }

    this.reviewService.isShow.subscribe(value => {
      if(!value) {
        this.onReset();
      }
    });
  }

  private initForm() {
    this.rateForm = this.formBuilder.group({
      score: [this.selectedStar, Validators.required],
      categories: this.formBuilder.array([]),
      items: this.formBuilder.array([])
    });
  }

  get f() {
    return this.rateForm.controls;
  }

  get categories(): UntypedFormArray {
    return this.rateForm.controls["categories"] as UntypedFormArray;
  }

  get items(): UntypedFormArray {
    return this.rateForm.controls["items"] as UntypedFormArray;
  }

  onRate(event: HTMLInputElement) {
    let rating = +event.value;

    if(rating > 0) {
      this.showRateReview = true;
    }

    if(rating < 3) {
      this.mascotImage = 'Mascot_sad@2x.webp';
      this.questionText = 'order.completed.rate.des.2';
      this.emoji = '😥'; // disappointed emoji
    }else {
      this.mascotImage = 'Mascot_love@2x.webp';
      this.questionText = 'order.completed.rate.des.5';
      this.emoji = '😀'; // smiley emoji
    }
  }

  onCategoryChanged(event: any, category_id: number) {
    if(event.target.checked){
      const control = this.formBuilder.group({
        Id: category_id,
        Text: event.target.value
      });
      this.categories.push(control);
    } else {
      this.categories.removeAt(this.categories.value.findIndex(category => category.Id === category_id));
    }
  }

  onItemChanged(event: any, itemCode: string) {
    this.items.removeAt(this.items.value.findIndex(item => item.itemCode === itemCode));
    const control = this.formBuilder.group({
      itemCode: itemCode,
      isLike: event.target.value === 'true'? true: false
    });
    this.items.push(control);
  }

  onAnonymousChanged(event: any) {
    this.isAnonymous = event.target.checked? true: false;
  }

  async onSubmit(){
    let rateData: UpdateStoreReviewRequest = {
      channelId: this.channelId,
      storeId: this.order.storeId,
      activityId: this.order.activityId,
      reviewCategoryType: ReviewCategoryType.Food,
      reviewScore: this.f.score.value,
      reviewData: JSON.stringify(this.categories.value),
      anonymousFlag: this.isAnonymous,
      itemReviewDatas: this.items.value
    }

    let resp: any = await this.reviewService.updateStoreReview(rateData);

    this.onShowRate.emit(false);
    this.reviewService.isShow.next(false);

    if(!(resp instanceof(HttpErrorResponse))){
      this.popupMessageService.show({
        iconImage: "assets/mascot/Mascot_delight@2x.webp",
        title: "alert.completed.rate.des.1",
        desc: "alert.completed.rate.des.2",
        btn: "button.ok",
        name: "successRate"
      });
    } else {
      this.popupMessageService.show({
        iconImage: "assets/mascot/Mascot_sorry-Grey@2x.webp",
        title: "alert.failed.rate.des.1",
        desc: "alert.failed.rate.des.2",
        btn: "button.retry"
      });
    }

    this.showRateReview = false;
    this.rateForm.patchValue({ 'score': 0 });
  }

  onReset() {
    this.mascotImage = 'Mascot_holding-cutlery@2x.webp';
    this.showRateReview = false;
    this.rateForm.patchValue({ 'score': 0 });
    this.items.clear();
  }

  ngOnDestroy() {
    if(this.order$) {
      this.order$.unsubscribe();
    }
  }
}
