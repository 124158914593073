import { ToastData } from '../../core/models/ToastData';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  showToast : Subject<ToastData> = new Subject<ToastData>();

  constructor() { }

  show(toastData : ToastData){
    this.showToast.next(toastData);
  }

  closeToast(){
    this.showToast.next(null);
  }

  showApiFailedToast(errorObj: any) {
    let toastData = {} as ToastData;
    toastData.icon = "oda-alert";
    toastData.iconColor = "#ffffff";
    toastData.message = errorObj.title;
    this.show(toastData);
  }
}
