<div class="cart-bg cart-form">
  <!--store name and order-more button-->
  <div class="order-info-container">
    <!-- p-d-flex -->
    <div class="order-no-container">
      <p class="order-no-description heading-7">{{'order.summary.title.3' | translate}} #{{ detailActivity.orderNo }}</p>
      <span class="order-status-container">
        <span class="status-dot"></span>
        <p class="status-description">{{detailActivity.lastActivityType | translate}}</p>
      </span>
    </div>

    <div class="trandate-container">
      <span class="date-time">{{ detailActivity.tranDate | date: "dd MMM yyyy HH:mm" }}</span>
    </div>
  </div>

  <div class="sub-content">
    <!--address form-->
    <div class="address-container">
      <!--delivery status-->
      <div class="p-d-flex time-schedule-container">
        <app-order-info *ngIf="detailActivity" [detailActivity]="detailActivity"></app-order-info>
      </div>

    </div>

    <div *ngIf="detailActivity">
      <!-- order item title -->
      <div class="order-list-title">
        <h2 class="heading-7">{{'order.summary.title.4' | translate}}</h2>
      </div>

      <!-- order item container -->
      <section class="item-cart-container" *ngFor="let item of detailActivity.orderData.orderDs; let i = index">

        <!--order item list-->
        <div class="order-item-outer-container">
          <div class="order-item-container">
            <app-order-item [item]="item" [currCode]="detailActivity.orderData.currCode" [isOrderSummaryPage]="true" [linkId]="detailActivity.linkId" [isShowSlashPrice]="true"></app-order-item>
          </div>
        </div>

        <app-void-item-message *ngIf="item.voidFlag == voidFlag.Unavailable"></app-void-item-message>

        <hr class="border-between-item" *ngIf="i != detailActivity.orderData.orderDs.length - 1">

      </section>
    </div>

    <div class="price-content" *ngIf="detailActivity">
      <app-order-amount [orderData]="detailActivity.orderData"></app-order-amount>
    </div>

    <div class="wave-pattern hidden-lg"></div>
  </div>
</div>
