import { StoreMenuResponseExtra } from 'src/app/core/models/StoreMenuResponseExtra';
import { StoreItemStatsService } from './../../core/store-item-stats/store-item-stats.service';
import { StoreItemStatsModel } from './../../core/store-item-stats/store-item-stats.model';
import { Menu } from './../../core/menu/menu.model';
import { WebLinkTokenResponse } from './../../core/models/WebLinkTokenResponse';
import { LeadTimeType } from 'src/app/core/enums/LeadTimeType';
import { ItemStatsStatusFlag } from '../../core/enums/ItemStatsStatusFlag';
import { StoreItemStats } from './../../core/models/StoreItemStats';
import { VoidFlag } from './../../core/enums/VoidFlag';
import { CurrentStatusFlag } from './../../core/enums/CurrentStatusFlag';
import { OrderTypeFlag } from '../../core/enums/OrderTypeFlag';
import { CompressionService } from '../../core/services/compression.service';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { StoreStore } from './store.store';
import { Store } from './store.model';
import { StoreMode } from 'src/app/core/enums';
import { StoreQuery } from './store.query';
import { CustomService } from 'src/app/core/services/custom.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomRequest } from 'src/app/core/models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OrderMenu } from 'src/app/core/models/OrderMenu';
import { MenuCatg } from 'src/app/core/models/MenuCatg';
import { MenuSet } from 'src/app/core/models/MenuSet';
import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { AvailableTime } from 'src/app/core/models/AvailableTime';
import { TimeInterval } from 'src/app/core/models/TimeInterval';
import { StoreOHPeriod } from 'src/app/core/models/StoreOHPeriod';
import { TimeService } from 'src/app/core/services/time.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { MenuItem } from 'src/app/core/models/MenuItem';
import { OrderD } from 'src/app/core/models/OrderD';
import { Subject } from 'rxjs';

import { SetCode } from 'src/app/core/enums/SetCode';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { Router } from '@angular/router';
import { MenuItemStatus } from 'src/app/core/enums/MenuItemStatus';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';
import { TranslateService } from '@ngx-translate/core';
import { LeadTime } from 'src/app/core/models/LeadTime';
import { StoreOHAdvSchedule } from 'src/app/core/models/StoreOHAdvSchedule';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { UserQuery } from 'src/app/core/user/user.query';
import { MenuService } from 'src/app/core/menu/menu.service';
import { Modifier } from 'src/app/core/models/Modifier';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { SubModifier } from 'src/app/core/models/SubModifier';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class StoreService {
  accessToken: string = "";
  curUser: User | null = null;
  isUserLogin : boolean;

  serverTime : Date;
  timeDifference : number;

  menuCatgs: MenuCatg[] = [];
  menuSets: MenuSet[];

  orderTranId : number;
  currentOrderType : string;
  showQrSelection : boolean;
  isRemoveMenuRowVersion : boolean;
  directLinkTableNo : string;

  currentModGrp: string;

  private orderInterval : ChannelPlatformSetResponse[] = [];

  //for time schedule and filtering
  filteredTime : AvailableTime[] = [];

  triggeredAddressDialog$ : Subject<boolean> = new Subject<boolean>();
  addressChangeChecking$ : Subject<boolean> = new Subject<boolean>();
  timeChangeChecking$ : Subject<AvailableTime> = new Subject<AvailableTime>();
  openOutOfCoverage$ : Subject<boolean> = new Subject<boolean>();
  headerAddressDisplay$ : Subject<string> = new Subject<string>();
  toQuitQrDineIn$ : Subject<boolean> = new Subject<boolean>();
  item3DModel$: Subject<any> = new Subject<{isShow: boolean, menuItem?: MenuItem, selectBtn?: boolean}>();
  openCustomize$ : Subject<boolean> = new Subject();
  updateMembership$ : Subject<boolean> = new Subject();
  nutriFooterRemarkDisplay$ : Subject<boolean> = new Subject();

  tableReqValue : ChannelPlatformSetResponse;
  address : any;

  closeExpandedItem$ = new Subject<boolean>();

  constructor(private storeStore: StoreStore,
    private http: HttpClient,
    private customService: CustomService,
    private utilsService: UtilsService,
    private storeQuery: StoreQuery,
    private userService: UserService,
    private compressionService : CompressionService,
    private timeService : TimeService,
    private router : Router,
    private qrCartService : QrCartService,
    private translateService : TranslateService,
    private toastService : ToastService,
    private channelQuery : ChannelQuery,
    private userQuery : UserQuery,
    private menuService : MenuService,
    private storeItemStatsService : StoreItemStatsService,
    private sessionStorageService: SessionStorageService
  ) {
    this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.accessToken = userData && userData['accessToken']? userData['accessToken']: '';
      this.curUser = userData? userData : null;
      this.isUserLogin = userData? true : false;
    });

    this.channelQuery.selectFirst().subscribe(channel => {
      if(channel){
        this.tableReqValue = channel.data.platformSets.find(val => val.setCode == SetCode.DIREQTBLNO);
      }
    })

    this.userQuery.selectedAddress$.subscribe(address => {
      if(address){
        this.address = address;
      }
      else{
        this.address = null;
      }
    })
  }

  get(storeMode: StoreMode) {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.storeStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.storeStore.setLoading(false);
    }
    return this.storeQuery.selectAll();
  }

  getEntityById(id : ID){
    return this.storeQuery.getEntity(id);
  }

  add(store: Store, formatSourceFlag: boolean = false) {
    let storeDt = _.cloneDeep(store) as Store;
    this.storeStore.upsert(storeDt.id, storeDt);
  }

  update(id: any, store: Partial<Store>) {
    this.storeStore.update(id, store);
  }

  // when time/schedule changed, this method will be run to update necessary state
  timeChangeUpdateState(storeId : number, currentTime : AvailableTime, storeData : any){
    let existingStoreData = this.storeQuery.getEntity(storeId);
    let existingStoreResponse = existingStoreData && existingStoreData?.storeResponse ? existingStoreData.storeResponse : null

    let storeObj = {} as Store;
    storeObj.id = storeId;
    storeObj.storeResponse = storeData.StoreResponse;
    storeObj.storePromotion = storeData.StorePromotion;
    storeObj.storeVoucherType = storeData.StoreVoucherType;
    storeObj.confirmedOrderTime = currentTime;
    storeObj.merchantMemberships  = storeData.MerchantMemberships;
    this.storeStore.upsert(storeId, storeObj);

    this.upsertStoreItemStats(storeId, storeData.StoreItemStats);

    // if no existing store OR existing store menu row version and current store menu row version different then update menu
    if(!existingStoreResponse || (existingStoreResponse && existingStoreData.storeResponse.menuRowVersion != storeObj.storeResponse.menuRowVersion)){
      this.upsertMenuState(storeId, storeData.storeMenuData);
    }
  }

  // for QR uses
  upsertStoreState(storeId : number, storeData : any){
    let storeObj = {} as Store;
    storeObj.id = storeId;
    storeObj.storeResponse = storeData.StoreResponse;
    storeObj.storePromotion = storeData.StorePromotion;
    storeObj.storeVoucherType = storeData.StoreVoucherType;
    storeObj.merchantMemberships = storeData.MerchantMemberships;
    this.storeStore.upsert(storeId, storeObj);
  }

  // update menu state
  upsertMenuState(storeId : number, menuResponse : OrderMenu[]){
    let menuObj = {} as Menu;
    menuObj.id = storeId;
    menuObj.menuResponse = menuResponse;
    this.menuService.upsertMenu(storeId, menuObj);
  }

  // update storeItemStats
  upsertStoreItemStats(storeId : number, storeItemStats : StoreItemStats[]){
    let itemStats = {} as StoreItemStatsModel;
    itemStats.id = storeId;
    itemStats.storeItemStats = storeItemStats;
    this.storeItemStatsService.upsertStoreItemStats(storeId, itemStats);
  }

  remove(id: ID) {
    this.storeStore.remove(id);
  }

  error(err: any) {
    this.storeStore.setError(err);
  }

  destroy() {
    this.storeStore.destroy();
  }

  addStore(data: Store) {

    if (!data) {
      return;
    }
    this.add(data);
  }

  private setActive(tizId: number) {
    this.storeStore.setActive(tizId);
  }

  async getQrStoreMenuData(storeId : number, channelId : number, channelTag : string, orderType : string, linkId: number, toUpdate: boolean,
    membershipCode? : string, membershipNo? : string){
    let respDt : any;
    respDt = await this.getStoreMenuData(storeId, orderType, channelId, null, channelTag, 0, 0, toUpdate, linkId, membershipCode, membershipNo);

    if(respDt.storeResponse && respDt.storeResponse?.currentStatus == CurrentStatusFlag.Closed){
      this.qrCartService.removeCart();
    }
    else if(respDt.StoreResponse && respDt.StoreResponse?.currentStatus == CurrentStatusFlag.Closed){
      this.qrCartService.removeCart();
    }

    return respDt;
  }

  async getStore(storeId : number, orderType : string, channelId : number, reqTime : string, channelTag : string, longitude : number, latitude : number, isUpdate : boolean = false){
    try{
      let respDt = null;
      respDt = await this.reqGetStore(storeId, reqTime, channelId, channelTag, longitude, latitude, orderType);

      if(respDt instanceof (HttpErrorResponse)){
        if(respDt.status == 404){
          this.router.navigate(["store-not-found"]);
        }
        else if(respDt.status == 503 || respDt.status == 500){
          this.router.navigate(["technical-error"]);
        }
        else{
          if (respDt.status !== 400 && respDt.error['detail'] !== 'SystemMaintenance_400') {
            this.router.navigate(["technical-error"]);
          }
        }
        return respDt;
      }
      else{
        if(isUpdate){
          this.storeStore.update(storeId, entity =>{
            return{
              ...entity,
              storeResponse: respDt["body"]
            }
          })
        }
        else{
          return respDt["body"];
        }
      }
    }
    catch(error){
      console.log(error);
    }
  }

  async getStoreMenuData(storeId : number, orderType : string, channelId : number, reqTime : string, channelTag : string,
    longitude : number, latitude : number, isUpsert : boolean = false, linkId?: number, membershipCode? : string, membershipNo? : string, toNavigateError : boolean = true){

    try{
      let qrTokenResponse : WebLinkTokenResponse;
      // get linkId if qrTokenResponse existed
      if(linkId){
        qrTokenResponse = this.qrCartService.get().qrTokenResponse;
      }

      this.menuSets = [];
      let respDt = null;
      respDt = await this.reqGetStoreMenuData(storeId, reqTime, channelId, channelTag, longitude, latitude, orderType, linkId, membershipCode, membershipNo);

      if(respDt instanceof (HttpErrorResponse)){
        if(toNavigateError){
          if(respDt.status == 404){
            this.router.navigate(["store-not-found"]);
          }
          else if(respDt.status == 503 || respDt.status == 500){
            this.router.navigate(["technical-error"]);
          }
          else{
            if (respDt.status !== 400 && respDt.error['detail'] !== 'SystemMaintenance_400') {
              this.router.navigate(["technical-error"]);
            }
          }
        }
        return respDt;
      }
      else{
        // to determine whether to show the address input box in header
        this.headerAddressDisplay$.next(respDt.StoreResponse.currentOrderType);
        this.updateMembership$.next(true);

        // get existing store in state to be use to determine whether to update menu or not
        let existingStoreData = this.storeQuery.getEntity(storeId);
        let existingStoreResponse = existingStoreData && existingStoreData?.storeResponse ? existingStoreData.storeResponse : null;

        // get existing store in state to be use to determine whether to update menu or not
        let existingMenuData = this.menuService.getMenu(storeId);
        let existingMenu = existingMenuData && existingMenuData?.menuResponse ? existingMenuData.menuResponse : null;

        // set interval minutes(delivery/pickup)
        this.setTimeSetting(respDt.StoreResponse);

        // calculate time difference between server time and local time
        this.timeService.calculateServerTimeDifference();

        // init store state object(store response, promotion, voucher)
        let storeObj = {} as Store;
        storeObj.id = storeId;
        storeObj.storeResponse = respDt.StoreResponse;
        storeObj.storeVoucherType = respDt.StoreVoucherType;
        storeObj.storePromotion = respDt.StorePromotion;
        storeObj.merchantMemberships = respDt.MerchantMemberships;

        // init store item stats object(storeItemStats)
        let storeItemStatsObject = {} as StoreItemStatsModel;
        storeItemStatsObject.id = storeId;
        storeItemStatsObject.storeItemStats = respDt.StoreItemStats;

        if(!existingMenu || !existingStoreResponse || (existingStoreResponse && existingStoreResponse?.menuRowVersion != respDt.StoreResponse.menuRowVersion)){
          // if menu response existed then run processing else init null
          if(respDt.storeMenuData && respDt.storeMenuData[0]?.menuSets && respDt.storeMenuData[0]?.menuSets[0]?.menuCatgs){
            // remove menu item that is empty/null
            respDt.storeMenuData[0].menuSets[0].menuCatgs = respDt.storeMenuData[0].menuSets[0].menuCatgs.filter(val => val.menuItems != null);

            // process menu catg, menu item thumbnail(image) and price format
            respDt.storeMenuData[0].menuSets[0].menuCatgs = await this.formatMenu(respDt.storeMenuData);
          }
          else{
            this.menuCatgs = null;
          }
        }

        if(isUpsert){
          // update store state and store item stats
          this.storeStore.upsert(storeId, storeObj);
          this.storeItemStatsService.upsertStoreItemStats(storeId, storeItemStatsObject);

          // if existing menu does not exists OR existing storeResponse does not exists OR
          // existing storeResponse menuRowVersion has different menuRowVersion compare to the new store response
          if(!existingMenu || !existingStoreResponse || (existingStoreResponse && existingStoreResponse?.menuRowVersion != respDt.StoreResponse.menuRowVersion)){
            // init menu state object(menu response)
            this.upsertMenuState(storeId, respDt.storeMenuData);
          }
          // rebind all store data return into a model that start with small letter for every field and return it
          return this.storeDataCombinedInit(respDt);
        }
        else{
          return respDt;
        }

      }
    }catch(error){
      console.log(error);
    }

  }

  async getStoreForQueue(storeId : number, mobileNo : string, channelTag : string){
    try{
      let respDt = null;
      respDt = await this.reqGetStoreForQueue(storeId, mobileNo, channelTag);

      if(!(respDt instanceof HttpErrorResponse)){
        return respDt['body'];
      }
      else{
        if(respDt.status == 404){
          this.router.navigate(["store-not-found"]);
        }
        else if(respDt.status == 503 || respDt.status == 500){
          this.router.navigate(["technical-error"]);
        }
        else if(respDt.status == 400 && respDt.error.errorCode == ErrorCode.QueueNotTurnedOnForThisStore_400){
          this.router.navigate(["technical-error"]);
        }
        else{
          if (respDt.status !== 400 && respDt.error['detail'] !== ErrorCode.SystemMaintenance_400) {
            this.router.navigate(["technical-error"]);
          }
        }
        return null;
      }
    }catch(error){
      console.log(error);
    }
  }

  private async reqGetStoreForQueue(storeId : number, mobileNo : string, channelTag : string){
    let newCr = {} as CustomRequest;
    newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.store.GetStoreForQueue,
      hostPath: environment.hostPath,
      queryParams:{
        storeId: storeId,
        mobileNo: mobileNo ? mobileNo : undefined
      },
      headers: {
        accessToken: this.accessToken ? this.accessToken : undefined,
        channelTag: channelTag ? channelTag : environment.odaringChannel
      },
      httpHeaderType: this.accessToken ? HttpHeaderType.Auth : undefined
    } as CustomRequest;

    let respInfo : any;
    this.storeStore.setLoading(true);

    respInfo = await this.reqCustomHttpCall(newCr);
    this.storeStore.setLoading(false);

    return respInfo;
  }

  private async reqGetStoreMenuData(storeId : number, reqTime : string, channelId : number, channelTag : string, longitude : number, latitude : number,
    orderType : string, linkId?: number, membershipCode? : string, membershipNo? : string){
    let newCr = {} as CustomRequest;
    newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.store.GetStoreMenuData,
      hostPath: environment.hostPath,
      queryParams:{
        storeid: storeId,
        latitude: latitude ? latitude : 0,
        longitude: longitude ? longitude : 0,
        channelId : channelId,
        channelTag : channelTag,
        ReqTime : reqTime? reqTime : undefined,
        orderTypeFlag: orderType ? orderType : undefined,
        linkId: linkId? linkId : undefined,
        MembershipCode : membershipCode ? membershipCode : undefined,
        MembershipNo: membershipNo ? membershipNo : undefined
      },
      headers: {
        accessToken: this.accessToken ? this.accessToken : undefined
      },
      httpHeaderType: this.accessToken ? HttpHeaderType.Auth : undefined
    } as CustomRequest;

    let respInfo : any;
    this.storeStore.setLoading(true);
    await this.reqCustomHttpCall(newCr, true).then(value => {

      if(!(value instanceof(HttpErrorResponse))){
        respInfo = this.compressionService.getMultipartFile(value).then(data => {
          this.timeService.setTimeZoneRegion(data.StoreResponse.timeZone);
          this.timeService.setServerTime(value.headers.get("X-ServerDateTime"));
          return data;
        });
      }
      else{
        respInfo = value;
      }

    });

    this.storeStore.setLoading(false);
    return respInfo;
  }

  private async reqGetStore(storeId : number, reqTime : string, channelId : number, channelTag : string, longitude : number, latitude : number, orderType : string){
    let newCr = {} as CustomRequest;
    newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.store.GetStore,
      hostPath: environment.hostPath,
      queryParams:{
        storeid: storeId,
        latitude: latitude,
        longitude: longitude,
        channelId : channelId,
        channelTag : channelTag,
        ReqTime : reqTime? reqTime : undefined,
        orderTypeFlag: orderType
      },
      headers: {
        accessToken: this.accessToken ? this.accessToken : undefined
      },
      httpHeaderType: this.accessToken ? HttpHeaderType.Auth : undefined
    } as CustomRequest;

    let respInfo : any;
    this.storeStore.setLoading(true);

    respInfo = await this.reqCustomHttpCall(newCr);
    this.storeStore.setLoading(false);

    return respInfo;
  }

  async getStores(latitude: number, longitude: number, skip: number, take: number,
    channelId: number, merchantId: number, orderType: string, platformCodes: string,
    platformSets: string, searchText: string, sortedBy: string, sortRating: string) {
    try {
      let respDt = null;
      respDt = await this.reqGetStores(latitude, longitude, skip, take, channelId, merchantId, orderType, platformCodes, platformSets, searchText, sortedBy, sortRating);
      return respDt;
    } catch(error){
      console.log(error);
    }
  }

  private async reqGetStores(latitude : number, longitude : number, skip: number, take: number, channelId: number,
    merchantId: number, orderType: string, platformCodes: string, platformSets: string, searchText: string, sortedBy: string, sortRating: string) {

    let newCr = {} as CustomRequest;
    newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.store.GetStores,
      hostPath: environment.hostPath,
      queryParams:{
        latitude: latitude,
        longitude: longitude,
        skip: skip,
        take: take,
        channelId : channelId,
        merchantId: merchantId,
        orderType: orderType,
        platformCodes: platformCodes,
        platformSets: platformSets,
        searchText: searchText,
        sortedBy: sortedBy,
        sortRating: sortRating
      },
      headers: {
        accessToken: this.accessToken ? this.accessToken : undefined
      },
      httpHeaderType: this.accessToken ? HttpHeaderType.Auth : undefined
    } as CustomRequest;

    let respInfo : any;
    this.storeStore.setLoading(true);

    respInfo = await this.reqCustomHttpCall(newCr);
    this.storeStore.setLoading(false);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression? : boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => {
      return dd;
    });
  }

  private formatSrvDateStr(date: Date) {
    const utilSv = this.utilsService;
    return utilSv.formatSrvDateStr(date);
  }

  //#region open and close qr dine in controller
  closeQuitQrDialog(){
    this.toQuitQrDineIn$.next(false);
  }

  openQuitQrDialog(){
    this.toQuitQrDineIn$.next(true);
  }
  //#endregion

  //#region settings of platform setCode, etc
  private setTimeSetting(storeResponse : StoreResponse){
    this.orderInterval = storeResponse.platformSets.filter(val =>
      val.setCode == SetCode.DELINTERVALMIN || val.setCode == SetCode.PICKINTERVALMIN
    );
  }

  getSpecificTimeSetting(setCode : string){
    return this.orderInterval.find(val => val.setCode == setCode);
  }

  getOrderInterval(){
    return this.orderInterval;
  }

  //#endregion

  //#region time scheduling/time processing logic
  //get current time for delivery/pickup now
  getCurrentTime(date : string, currentMin : string, isAsap: boolean, intervalTime : number = 30){
    let curDate = moment(date);
    curDate = this.timeService.convertToTimezoned(curDate);

    let minimumDateAndTime = moment(curDate.format("YYYY-MM-DD") + ' ' + currentMin);

    let calculatedMinutes = Math.ceil(+minimumDateAndTime.format("mm") * (1 / intervalTime)) * intervalTime; //calculate first slot time
    minimumDateAndTime.set('minutes', calculatedMinutes); //set the first slot time

    let timeString : string;
    let value : string;

    // value = isAsap ? null : this.timeService.convertToUtc(moment(minimumDateAndTime)).format();
    value = isAsap ? null : this.timeService.formatDateString(minimumDateAndTime);
    timeString = isAsap? "ASAP": minimumDateAndTime.format("HH:mm") + " - " + minimumDateAndTime.add(intervalTime, 'minutes').format("HH:mm");

    return {label: timeString, value: value};
  }

  //get first schedule for reschedule dialog prompt
  async getFirstSchedule(storeOHSchedule : StoreOHPeriod, interval : number = 30){
    let curDate = moment(storeOHSchedule.date); //schedule date in moment
    curDate = this.timeService.convertToTimezoned(curDate);

    let startTime = moment(curDate.format("YYYY-MM-DD") + ' ' + storeOHSchedule.min);
    startTime = this.timeService.convertToTimezoned(startTime); //convert to timezone given in case library change to irrelevant timezone

    let calculatedMinutes = Math.ceil(+startTime.format("mm") * (1 / interval)) * interval; //calculate first slot time
    startTime.set('minutes', calculatedMinutes); //set the first slot time

    return startTime.format();
  }

  // StoreOHAdvSchedule
  async filterTime(storeOHScheduleObject : any, orderIntervalTime: ChannelPlatformSetResponse[], storeResponse : StoreResponse, isPreorder?: boolean): Promise<AvailableTime[]> {
    this.filteredTime = [];

    if(storeOHScheduleObject){
      return new Promise(async (resolve) => {

        let deliveryInterval = orderIntervalTime.find(val => val.setCode == "DELINTERVALMIN");

        let pickupInterval = orderIntervalTime.find(val => val.setCode == "PICKINTERVALMIN");

        if(storeOHScheduleObject.delivery){
          await this.deliverySchedule(storeOHScheduleObject.delivery, deliveryInterval, storeResponse, isPreorder);
        }

        if(storeOHScheduleObject.pickup){
          await this.pickupSchedule(storeOHScheduleObject.pickup, pickupInterval, storeResponse, isPreorder);
        }

        resolve(this.filteredTime);
      })
    }
    else{
      return this.filteredTime;
    }

  }

  //schedule logic for delivery
  async deliverySchedule(storeOHScheduleObject : StoreOHPeriod[], orderIntervalTime: ChannelPlatformSetResponse, storeResponse: StoreResponse, isPreorder?: boolean){
    return Promise.all(storeOHScheduleObject.map(async (val) => {
      if(orderIntervalTime){
        this.plusTimeInterval(OrderTypeFlag.Delivery, val.date, val.max, val.min, storeResponse, isPreorder, +orderIntervalTime.setValue);
      }
      else{
        this.plusTimeInterval(OrderTypeFlag.Delivery, val.date, val.max, val.min, storeResponse, isPreorder);
      }
    }))
  }

  //schedule logic for pickup
  async pickupSchedule(storeOHScheduleObject : StoreOHPeriod[], orderIntervalTime: ChannelPlatformSetResponse, storeResponse: StoreResponse, isPreorder? : boolean){
    let storeOhsLength = storeOHScheduleObject.length;
    for(let ohIndex = 0; ohIndex < storeOhsLength; ohIndex++){
      if(orderIntervalTime){
        await this.plusTimeInterval(OrderTypeFlag.Pickup, storeOHScheduleObject[ohIndex].date, storeOHScheduleObject[ohIndex].max,
          storeOHScheduleObject[ohIndex].min, storeResponse, isPreorder, +orderIntervalTime.setValue);
      }
      else{
        await this.plusTimeInterval(OrderTypeFlag.Pickup, storeOHScheduleObject[ohIndex].date, storeOHScheduleObject[ohIndex].max,
          storeOHScheduleObject[ohIndex].min, storeResponse, isPreorder);
      }
    }
  }

  //method for interval increment
  async plusTimeInterval(orderType: string, date: string, max: string, min: string, storeResponse: StoreResponse, isPreorder: boolean, interval: number = 30) {
    let curDate = moment(date); //schedule date in moment
    curDate = this.timeService.convertToTimezoned(curDate);

    let nextDay //variable to store next day date - undefined if empty

    //set a next day when max smaller than min
    if(max < min){
      nextDay = moment(curDate).add(1, 'days');
    }

    this.serverTime = this.timeService.getServerTime();

    let serverTimeMoment = moment(this.serverTime) //server time - moment auto change it to current timezone
    serverTimeMoment = this.timeService.convertToTimezoned(serverTimeMoment);

    let oneOrderFilteredTime : AvailableTime = {} as AvailableTime;
    oneOrderFilteredTime.timeInterval = []; //for storing label and value
    oneOrderFilteredTime.orderType = orderType; //current order type -e.g. delivery/pickup

    let existingASAP : TimeInterval

    if(this.filteredTime.length != 0){
      let existsTimeIndex = this.filteredTime.findIndex(val => moment(val.date).format("YYYY-MM-DD") == curDate.format("YYYY-MM-DD") && val.orderType == orderType);
      existingASAP = existsTimeIndex != -1 ? this.filteredTime[existsTimeIndex].timeInterval.find(val => val.label == "ASAP") : undefined;
    }

    if (curDate.format("YYYY-MM-DD") == serverTimeMoment.format("YYYY-MM-DD") && storeResponse.actualStatus == CurrentStatusFlag.Open) {
      oneOrderFilteredTime.isToday = true;

      let hideAsapSetting = storeResponse.platformSets.find(setting => setting.setCode == SetCode.HIDEASAP);
      let isHideAsap = hideAsapSetting ? hideAsapSetting.setValue === "1" : false;

      if(!isPreorder && !isHideAsap){
        let asapInterval : TimeInterval = {} as TimeInterval; //prepare to store time interval
        asapInterval.label = "ASAP"; //assign "ASAP" cause the date is today
        asapInterval.value = null; //if ASAP then value is null

        //will only push if there is no ASAP existed
        if(!existingASAP){
          oneOrderFilteredTime.timeInterval.push(asapInterval);
        }
      }
    }
    else if(curDate.format("YYYY-MM-DD") == serverTimeMoment.format("YYYY-MM-DD") && storeResponse.actualStatus != CurrentStatusFlag.Open){
      oneOrderFilteredTime.isToday = true;
    }
    else{
      oneOrderFilteredTime.isToday = false;
    }

    oneOrderFilteredTime.date = curDate.format("YYYY-MM-DD");
    oneOrderFilteredTime.minTime = min; //original min time
    oneOrderFilteredTime.interval = interval; //interval for incrementation

    let minDateAndTime = moment(curDate.format("YYYY-MM-DD") + ' ' + min); //date that contains minimum time in utc format, value retain
    minDateAndTime = this.timeService.convertToTimezoned(minDateAndTime); //convert to timezone given in case library change to irrelevant timezone

    if(nextDay){
      nextDay = this.timeService.convertToTimezoned(nextDay);
    }

    let maxDateAndTime = nextDay ? moment(nextDay.format("YYYY-MM-DD") + ' ' + max) :  moment(curDate.format("YYYY-MM-DD") + ' ' + max);
    maxDateAndTime = this.timeService.convertToTimezoned(maxDateAndTime); //convert to timezone given in case library change to irrelevant timezone

    let calculatedMinutes = Math.ceil(+minDateAndTime.format("mm") * (1 / interval)) * interval; //calculate first slot time
    minDateAndTime.set('minutes', calculatedMinutes); //set the first slot time

    while (minDateAndTime.isBefore(maxDateAndTime)) {
      let timeBeforeIncrement = this.timeService.convertToTimezoned(moment(minDateAndTime));
      let value : string = this.timeService.formatDateString(timeBeforeIncrement);
      minDateAndTime.add(interval, 'minutes');

      let combineTime: TimeInterval = {} as TimeInterval;
      combineTime.label = timeBeforeIncrement.format("HH:mm") + " - " + minDateAndTime.format("HH:mm");
      combineTime.value = value;

      oneOrderFilteredTime.timeInterval.push(combineTime);

    }

    let dataClone = _.cloneDeep(oneOrderFilteredTime);

    let foundIndex = this.filteredTime.findIndex(val => moment(val.date).format("YYYY-MM-DD") == moment(dataClone.date).format("YYYY-MM-DD") && val.orderType == dataClone.orderType);

    if(foundIndex != -1 && this.filteredTime.length != 0){
      this.filteredTime[foundIndex].timeInterval = await this.checkScheduleInit(this.filteredTime[foundIndex].timeInterval, dataClone.timeInterval);
    }
    else{
      this.filteredTime.push(dataClone);
    }
  }

  async checkScheduleInit(currentSchedule : TimeInterval[], newSchedule : TimeInterval[]){
    let newLength = newSchedule.length;
    for(let currIndex = 0; currIndex < newLength; currIndex++){
      currentSchedule = await this.checkIfTimeSlotExist(currentSchedule, newSchedule[currIndex]);
    }
    return currentSchedule;
  }

  async checkIfTimeSlotExist(currentTimeSlot : TimeInterval[], newTimeSlot : TimeInterval){
    let foundTime = currentTimeSlot.find(timeSlot => timeSlot.value == newTimeSlot.value);
    if(!foundTime){
      currentTimeSlot.push(newTimeSlot);
    }
    return currentTimeSlot;
  }

  async checkTimeIfItExists(confirmedTime : AvailableTime){
    let confirmTimeMoment = moment(confirmedTime.chosenTime.value);

    let currentTimeMoment = this.timeService.getAdjustedLocalTimeInUtc();
    currentTimeMoment = this.timeService.convertToTimezoned(currentTimeMoment);

    if(confirmTimeMoment.isSameOrBefore(currentTimeMoment)){
      confirmedTime.chosenTime.label = "ASAP";
      confirmedTime.chosenTime.value = null;
    }

    return confirmedTime;
  }

  async currentTimePlusLeadTime(leadTime : LeadTime){
    let currentDateMoment = this.timeService.getAdjustedLocalTimeInUtc();
    currentDateMoment = this.timeService.convertToTimezoned(currentDateMoment);

    let unit : any;

    if(leadTime.type == LeadTimeType.Hour){
      unit = "hours";
    }
    else if(leadTime.type == LeadTimeType.Day){
      unit = "days";
    }

    currentDateMoment.add(+leadTime.value, unit);

    if(unit == 'days'){
      currentDateMoment.set('hours', 0);
      currentDateMoment.set('minutes', 0);
      currentDateMoment.set('seconds', 0);
      currentDateMoment.set('milliseconds', 0);
    }
    else{
      currentDateMoment.set('seconds', 0);
      currentDateMoment.set('milliseconds', 0);
    }
    return currentDateMoment;
  }

  async leadTimeCheck(processedTime : moment.Moment, chosenTime : string){
    let isAfter : boolean;
    if(chosenTime){
      isAfter = processedTime.isAfter(chosenTime);
    }
    else{
      let curDateMoment = this.timeService.getAdjustedLocalTimeInUtc();
      curDateMoment = this.timeService.convertToTimezoned(curDateMoment);

      isAfter = processedTime.isAfter(curDateMoment);
    }
    return isAfter;
  }

  async formatLeadTime(leadTime : moment.Moment, interval: number){
    let calculatedMinutes = Math.ceil(+leadTime.format("mm") * (1 / interval)) * interval;
    leadTime.set('minutes', calculatedMinutes);
    leadTime.set('seconds', 0);
    leadTime.set('milliseconds', 0);

    return leadTime;
  }

  async checkIfLeadTimeAvailable(schedule : StoreOHPeriod[], currentLeadTime: moment.Moment, leadUnit: string, interval : number){
    let leadTimeType = LeadTimeType;
    let formattedDateString : string;
    let formattedDate : moment.Moment;

    if(leadUnit == leadTimeType.Day){
      formattedDateString = this.timeService.formatDateString(currentLeadTime);
    }
    else{
      let cloneDate = _.cloneDeep(currentLeadTime);
      cloneDate.set('hours', 0);
      cloneDate.set('minutes', 0);
      cloneDate.set('seconds', 0);
      cloneDate.set('milliseconds', 0);

      formattedDateString = this.timeService.formatDateString(cloneDate);
    }

    let index = schedule.findIndex(schedule => schedule.date == formattedDateString);

    //if index is -1 then search for the first date that is after given date
    if(index == -1){
      index = schedule.findIndex(schedule => this.timeService.isSameOrAfterGivenDate(schedule.date, formattedDateString));
    }

    if(index != -1){
      if(leadUnit == leadTimeType.Day){
        let date = moment(currentLeadTime.format("YYYY-MM-DD") + ' ' + schedule[index].min);
        formattedDate = await this.formatLeadTime(date, interval);
        return formattedDate.format();
      }
      else{
        let dateMoment = moment(schedule[index].date);
        let dateWithMin = moment(dateMoment.format("YYYY-MM-DD") + ' ' + schedule[index].min);
        let dateWithMax = moment(dateMoment.format("YYYY-MM-DD") + ' ' + schedule[index].max);

        let isBefore = currentLeadTime.isBefore(dateWithMax);
        let isAfter = currentLeadTime.isAfter(dateWithMin);

        let newSchedule : StoreOHPeriod;
        if(!isBefore && isAfter){
          newSchedule = schedule[index + 1];
          let newScheduleDateMoment = moment(newSchedule.date);
          let date = moment(newScheduleDateMoment.format("YYYY-MM-DD") + ' ' + newSchedule.min);
          formattedDate = await this.formatLeadTime(date, interval);
          return formattedDate.format();
        }
        else if(!isAfter && isBefore){
          let scheduleDateMoment = moment(schedule[index].date);
          let date = moment(scheduleDateMoment.format("YYYY-MM-DD") + ' ' + schedule[index].min);
          formattedDate = await this.formatLeadTime(date, interval);
          return formattedDate.format();
        }
        else{
          return currentLeadTime.format();
        }
      }
    }
    else{
      return null;
    }
  }

  async filterLeadTimeSchedule(storeOHSchedule : StoreOHAdvSchedule, leadTime: string){
    if(storeOHSchedule.delivery){
      storeOHSchedule.delivery = await this.leadPeriodScheduleFilter(storeOHSchedule.delivery, leadTime);
      storeOHSchedule.delivery = await this.checkScheduleMin(storeOHSchedule.delivery, leadTime);
    }

    if(storeOHSchedule.pickup){
      storeOHSchedule.pickup = await this.leadPeriodScheduleFilter(storeOHSchedule.pickup, leadTime);
      storeOHSchedule.pickup = await this.checkScheduleMin(storeOHSchedule.pickup, leadTime);
    }

    return storeOHSchedule;
  }

  async leadPeriodScheduleFilter(storeOHPeriod : StoreOHPeriod[], leadTime: string){
    let leadTimeMoment = moment(leadTime);

    let filteredTime = storeOHPeriod.filter(schedule => {
      let scheduleDateMoment = moment(schedule.date);
      if(scheduleDateMoment.isSameOrAfter(leadTimeMoment, 'days')){
        return schedule;
      }
      else{
        return null;
      }
    })

    return filteredTime;
  }

  async checkScheduleMin(storeOHPeriod : StoreOHPeriod[], leadTime: string){
    let leadTimeMoment = moment(leadTime);

    let index = storeOHPeriod.findIndex(schedule => {
      let scheduleDateMoment = moment(schedule.date);
      if(scheduleDateMoment.isSame(leadTimeMoment, 'days')){
        return schedule;
      }
      else{
        return -1;
      }
    })

    let currentScheduleDate = moment(storeOHPeriod[index].date);
    let currentScheduleMoment = moment(currentScheduleDate.format("YYYY-MM-DD") + ' ' + storeOHPeriod[index].min);
    let currentScheduleMax = moment(currentScheduleDate.format("YYYY-MM-DD") + ' ' + storeOHPeriod[index].max);

    let isBeforeMax = leadTimeMoment.isSameOrBefore(currentScheduleMax);
    let isAfterMin = leadTimeMoment.isSameOrAfter(currentScheduleMoment);

    let currentScheduleIsBeforeLead = currentScheduleMoment.isSameOrBefore(leadTimeMoment);

    if(isAfterMin && !isBeforeMax){
      storeOHPeriod.splice(index, 1);
      return storeOHPeriod;
    }
    else if(!isAfterMin && isBeforeMax){
      return storeOHPeriod;
    }
    else if(isAfterMin && isBeforeMax && currentScheduleIsBeforeLead){
      storeOHPeriod[index].min = leadTimeMoment.format("HH:mm");
      return storeOHPeriod;
    }
    else{
      return storeOHPeriod;
    }
  }

  async constructChosenTime(timeString : string, interval : number){
    let chosenTime : TimeInterval = {} as TimeInterval;

    let dateBeforeIncrement = moment(timeString);
    let dateAfterIncrement = moment(timeString);
    dateAfterIncrement.add(interval, 'minutes');

    chosenTime.label = dateBeforeIncrement.format("HH:mm") + ' - ' + dateAfterIncrement.format("HH:mm");
    chosenTime.value = this.timeService.formatDateString(dateBeforeIncrement);
    return chosenTime;
  }

  //#endregion

  //#region thumbnail processing workings
  async formatMenu(menuResponse : OrderMenu[]){
    let menuCatgs = menuResponse[0].menuSets[0].menuCatgs;
    let exponent = menuResponse[0].currency.exponent;

    let menuLength = menuCatgs.length;
    for(let i = 0; i < menuLength; i++){
      let thumbnailCount = 0;
      thumbnailCount = await this.checkIfItemHaveThumbnail(menuCatgs[i]);
      if(thumbnailCount == 0){
        menuCatgs[i].uiMode = "1";
      }
      menuCatgs[i] = await this.assignThumbnail(menuCatgs[i], thumbnailCount, exponent);
    }

    return menuCatgs;
  }

  async checkIfItemHaveThumbnail(menuCatg: MenuCatg){
    let count = 0;
    let menuLength = menuCatg.menuItems.length;
    for(let index = 0; index < menuLength; index++){
      if(menuCatg.menuItems[index].thumbnail != "" && menuCatg.menuItems[index].thumbnail && !menuCatg.menuItems[index].variationOnly){
        count += 1;
      }
    }
    return count;
  }

  async assignThumbnail(menuCatg : MenuCatg, thumbnailCount : number, exponent : number){
    let menuItemLength = menuCatg.menuItems.length;
    for(let index = 0; index < menuItemLength; index++){
      if(thumbnailCount >= 1){
        if(!menuCatg.menuItems[index].thumbnail && !menuCatg.menuItems[index].variationOnly){
          menuCatg.menuItems[index].thumbnail = "assets/image-unavailable.svg";
        }
      }
      else{
        menuCatg.menuItems[index].thumbnail = null;
      }

      menuCatg.menuItems[index].dispPrice = menuCatg.menuItems[index].dispPrice / (Math.pow(10, exponent));
      if(menuCatg.menuItems[index].priceRange){
        menuCatg.menuItems[index].priceRange.min = menuCatg.menuItems[index].priceRange.min / (Math.pow(10, exponent));
        menuCatg.menuItems[index].priceRange.max = menuCatg.menuItems[index].priceRange.max / (Math.pow(10, exponent));
      }

      if(menuCatg.menuItems[index].modifierGrps != null){
        menuCatg.menuItems[index].modifierGrps = await this.formatModifierGrps(menuCatg.menuItems[index].modifierGrps, exponent);
      }
    }

    return menuCatg;
  }

  async formatModifierGrps(modifierGrp : ModifierGrp[], exponent : number){
    let modGrpLength = modifierGrp.length;
    for(let index = 0; index < modGrpLength; index++){
      modifierGrp[index].modifiers = await this.formatModifier(modifierGrp[index].modifiers, exponent);
    }

    return modifierGrp;
  }

  async formatModifier(modifiers : Modifier[], exponent : number){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      modifiers[index].price = modifiers[index].price / (Math.pow(10, exponent));
      if(modifiers[index].thumbnail == "" || !modifiers[index].thumbnail){
        modifiers[index].thumbnail = null;
      }

      if(modifiers[index].subModifierGrps){
        modifiers[index].subModifierGrps = await this.formatSubModGrps(modifiers[index].subModifierGrps, exponent);
      }
    }

    return modifiers;
  }

  async formatSubModGrps(subModifierGrps : SubModifierGrp[], exponent : number){
    let subModGrpLength = subModifierGrps.length;
    for(let index = 0; index < subModGrpLength; index++){
      subModifierGrps[index].subModifiers = await this.formatSubModifiers(subModifierGrps[index].subModifiers, exponent);
    }
    return subModifierGrps;
  }

  async formatSubModifiers(subModifiers : SubModifier[], exponent : number){
    let subModLength = subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      subModifiers[index].price = subModifiers[index].price / (Math.pow(10, exponent));
      if(subModifiers[index].thumbnail == "" || !subModifiers[index].thumbnail){
        subModifiers[index].thumbnail = null;
      }
    }

    return subModifiers;
  }

  async variationImageCheck(menuItems : MenuItem[]){
    let menuWithImage = menuItems.filter(item => item.thumbnail);
    if(menuWithImage.length > 0){
      menuItems.forEach(item => {
        if(!item.thumbnail){
          item.thumbnail = "assets/image-unavailable.svg";
        }
      })
    }

    return menuItems;
  }

  //#endregion

  //#region clear and reassign cart item order qty into menu item
  //reassign the quantity in orderD to menu item
  reassignQuantity(menuCatgs : MenuCatg[], curOrderH : OrderH){
    let cloneMenuCatgs = _.cloneDeep(menuCatgs);
    // filter out order that have parentItemCode
    let parentItem = curOrderH.orderData.orderDs.filter(orderD => orderD.parentItemCode);
    // filter out order that dont have parentItemCode
    let itemWithoutVariation = curOrderH.orderData.orderDs.filter(orderD => !orderD.parentItemCode);
    // merge both filtered order item into a single list
    let mergeValue = _.merge(_.groupBy(parentItem, "parentItemCode"), _.groupBy(itemWithoutVariation, "itemCode"));

    cloneMenuCatgs.forEach(menuGrp => {
      menuGrp.menuItems.forEach(item => {
        if(mergeValue[item.itemCode]){
          let availableItem = mergeValue[item.itemCode].filter(val => val.voidFlag == VoidFlag.Available);

          item.selectedQty = availableItem.reduce((sum, current) => sum + current.orderQty, 0);
        }
        else{
          item.selectedQty = 0
        }
      })
    })

    return cloneMenuCatgs;
  }

  clearAllSelectedQty(menuCatgs : MenuCatg[]){
    if (menuCatgs) {
      menuCatgs.forEach(menuCategory => {
        menuCategory.menuItems.forEach(menuItem => {
          if(menuItem.selectedQty > 0){
            menuItem.selectedQty = 0;
          }
        })
      })
    }
    return _.cloneDeep(menuCatgs);
  }

  //#endregion

  //#region search item for edit method
  async searchByMenuCatgsCodeAndItemId(menuCatgsCode : string, itemId : number, itemCode: string, menuCatgs : MenuCatg[]){
    let foundIndex = menuCatgs.findIndex(val => val.code == menuCatgsCode && val.menuItems.some(item => item.id == itemId && item.itemCode == itemCode));

    if(foundIndex != -1){
      let itemFound = menuCatgs[foundIndex].menuItems.find(item => item.id == itemId && item.itemCode == itemCode);
      return itemFound;
    }
    else{
      return null;
    }
  }

  async searchByItemIdOrItemCode(itemCode : string, itemId : number, menuCatgs : MenuCatg[]){
    let foundIndex : number;
    let itemFound : MenuItem = null;
    if(itemId){
      foundIndex = menuCatgs.findIndex(catgs => catgs.menuItems.some(item => item.id == itemId));
      if(foundIndex != -1){
        itemFound = menuCatgs[foundIndex].menuItems.find(item => item.id == itemId);
      }
      else{
        foundIndex = menuCatgs.findIndex(catgs => catgs.menuItems.some(item => item.itemCode == itemCode));
        if(foundIndex != -1){
          itemFound = menuCatgs[foundIndex].menuItems.find(item => item.itemCode == itemCode);
        }
      }
    }
    else{
      foundIndex = menuCatgs.findIndex(catgs => catgs.menuItems.some(item => item.itemCode == itemCode));
      if(foundIndex != -1){
        itemFound = menuCatgs[foundIndex].menuItems.find(item => item.itemCode == itemCode);
      }
    }

    return itemFound ? itemFound : null;
  }

  async getMenuCatgCode(itemCode : string, menuCatgs : MenuCatg[]){
    let foundIndex = menuCatgs.findIndex(catgs => catgs.menuItems.some(item => item.itemCode == itemCode));

    return foundIndex != -1? menuCatgs[foundIndex].code : null;
  }

  //map orderD value/data back to menu item for edit
  async mapBackData(orderD : OrderD, menuItem : MenuItem){
    let menuItemClone = _.cloneDeep(menuItem); //clone menu item for manipulation
    menuItemClone.itemQty = 0;
    menuItemClone.itemQty = orderD.orderQty;
    menuItemClone.remarks = orderD.remarks;

    for(let i = 0; i < orderD.modifiers.length; i++){
      // find the array index of the modifier group where the order modifier belong to
      let modGrpIndex = menuItemClone.modifierGrps.findIndex(val => val.code == orderD.modifiers[i].code);

      if(modGrpIndex == -1){
        return null;
      }
      else{
        //if qtyGrp is undefined assign 0
        if(!menuItemClone.modifierGrps[modGrpIndex].qtyGrp){
          menuItemClone.modifierGrps[modGrpIndex].qtyGrp = 0;
        }

        menuItemClone.modifierGrps[modGrpIndex].isComplete = true; //assign modifierGrp isComplete to true to indicate completion of this section
        menuItemClone.modifierGrps[modGrpIndex].qtyGrp += orderD.modifiers[i].orderQty; //assign orderD modifier orderQty back to qtyGrp

         //find the modifier array index with the orderD modifier itemCode
        let modIndex = menuItemClone.modifierGrps[modGrpIndex].modifiers.findIndex(mod => mod.itemCode == orderD.modifiers[i].itemCode);
        menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].qty = orderD.modifiers[i].orderQty; //assign orderD orderQty to respective modifier qty

        if(orderD.modifiers[i].subModifiers.length != 0){
          menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].isComplete = true; //assign modifier isComplete to true

          for(let j = 0; j < orderD.modifiers[i].subModifiers.length; j++){
            //find the submodifierGrps array index in which the order modifier's submodifier belong to
            let subModGrpIndex = menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps.findIndex(val => val.code == orderD.modifiers[i].subModifiers[j].code);

            if(subModGrpIndex == -1){
              return null;
            }
            else{
              //assign 0 to submodifierGrp qtyGrp when it is undefined
              if(!menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].qtyGrp){
                menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].qtyGrp = 0;
              }

              //assign current submodifierGrp isComplete to true
              menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].isComplete = true;
              // assign back order modifier's submodifier order qty back to respective menu item's submodifierGrp
              menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].qtyGrp += orderD.modifiers[i].subModifiers[j].orderQty;

              //search array index of submodifier using the order modifier submodifier's itemCode
              let subModIndex = menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].subModifiers.findIndex(subMod => subMod.itemCode == orderD.modifiers[i].subModifiers[j].itemCode);
              menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].subModifiers[subModIndex].qty = orderD.modifiers[i].subModifiers[j].orderQty;
            }
          }
        }
        else{
          if(menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps &&
            menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps.length > 0){
              let isRequired = await this.checkIfSubModIsRequired(menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps);
              if(isRequired){
                menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].isComplete = false;
              }
              else{
                menuItemClone.modifierGrps[modGrpIndex].modifiers[modIndex].isComplete = true;
              }
          }
        }
      }
    }

    return menuItemClone;
  }

  //initial method that will be execute when edit item
  async editItem(item : OrderD, menuCatgs : MenuCatg[]){
    let searchItem = await this.searchByMenuCatgsCodeAndItemId(item.menuCatgCode, item.itemId, item.itemCode, menuCatgs);
    let mappedData : MenuItem;

    if(searchItem){
      mappedData = await this.mapBackData(item, searchItem);
      mappedData.menuCatgsCode = item.menuCatgCode;
    }
    else{
      searchItem = await this.searchByItemIdOrItemCode(item.itemCode, item.itemId, menuCatgs);
      mappedData = await this.mapBackData(item, searchItem);
      mappedData.menuCatgsCode = await this.getMenuCatgCode(item.itemCode, menuCatgs);
    }

    mappedData.entryMode = item.entryMode;
    return mappedData;
  }

  async checkIfSubModIsRequired(subModifierGrp : SubModifierGrp[]){
    let counter : SubModifierGrp[] = [];

    counter = subModifierGrp.filter(val => val.minSelect >= 1);

    if (counter.length >= 1) {
      return true;
    }
    else {
      return false;
    }
  }
  //#endregion

  //#region get order type and source flag
  getOrderType(sourceflag : string){
    switch(sourceflag){
      case OrderSourceFlag.WebDelivery:{
        return OrderTypeFlag.Delivery;
      }
      case OrderSourceFlag.WebPickup:{
        return OrderTypeFlag.Pickup;
      }
      case OrderSourceFlag.WebDineIn:{
        return OrderTypeFlag.DineIn;
      }
      case OrderSourceFlag.WebQrDineIn:{
        return OrderTypeFlag.DineIn;
      }
      case OrderSourceFlag.WebQrTakeaway:{
        return OrderTypeFlag.Pickup;
      }
      case OrderSourceFlag.AppDelivery:{
        return OrderTypeFlag.Delivery;
      }
      case OrderSourceFlag.AppPickup:{
        return OrderTypeFlag.Pickup;
      }
      case OrderSourceFlag.AppDineIn:{
        return OrderTypeFlag.DineIn;
      }
      default:{
        return OrderTypeFlag.Delivery;
      }
    }
  }

  getSourceFlag(orderType : string){
    switch(orderType){
      case OrderTypeFlag.Delivery:{
        return OrderSourceFlag.WebDelivery;
      }
      case OrderTypeFlag.Pickup:{
        return OrderSourceFlag.WebPickup;
      }
      case OrderTypeFlag.DineIn:{
        return OrderSourceFlag.WebDineIn;
      }
      default:{
        return OrderSourceFlag.WebDelivery;
      }
    }
  }

  //#endregion

  //#region orderTranId
  setOrderTranId(orderTranId : number){
    this.orderTranId = orderTranId;
  }

  async getOrderTranId(){
    return this.orderTranId ? this.orderTranId : null;
  }

  removeOrderTranId(){
    this.orderTranId = null;
  }
  //#endregion

  //#region current order type get/set
  setCurrentOrderType(orderType : string){
    this.sessionStorageService.setItem("storeOrderType", orderType);
  }

  getCurrentOrderType(){
    let currOderType: string = "";
    if (this.sessionStorageService.getItem("storeOrderType")) {
      currOderType = this.sessionStorageService.getItem("storeOrderType");
    }
    return currOderType;
  }

  removeCurrentOrderType(){
    this.sessionStorageService.removeItem("storeOrderType");
  }

  //#endregion

  //#region showQrSelection get/set
  setShowQrSelection(toShowDialog : boolean){
    this.showQrSelection = toShowDialog;
  }

  getShowQrSelection(){
    return this.showQrSelection;
  }

  //#endregion

  //#region menu row version toast
  setIsRemoveMenuRowVersion(isMenuDiff : boolean){
    this.isRemoveMenuRowVersion = isMenuDiff;
  }

  getIsRemoveMenuRowVersion(){
    return this.isRemoveMenuRowVersion;
  }
  //#endregion

  async checkIfModItemAvailable(item : OrderD, menuItem : MenuItem){
    if(item.modifiers.length != 0){
      for(let i = 0; i < item.modifiers.length; i++){
        let modGrpIndex = menuItem.modifierGrps.findIndex(val => val.code == item.modifiers[i].code);

        if(modGrpIndex == -1){
          return false;
        }
        else{
          let modIndex = menuItem.modifierGrps[modGrpIndex].modifiers.findIndex(mod => mod.itemCode == item.modifiers[i].itemCode);

          if(modIndex == -1){
            return false;
          }
          else{

            if(item.modifiers[i].subModifiers.length != 0){
              for(let j = 0; j < item.modifiers[i].subModifiers.length; j++){
                let subModGrpIndex = menuItem.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps.findIndex(subMod => subMod.code == item.modifiers[i].subModifiers[j].code);

                if(subModGrpIndex == -1){
                  return false;
                }
                else{
                  let isExists = menuItem.modifierGrps[modGrpIndex].modifiers[modIndex].subModifierGrps[subModGrpIndex].subModifiers.some(val => val.itemCode == item.modifiers[i].subModifiers[j].itemCode);

                  if(!isExists){
                    return false;
                  }
                  else{
                    return true;
                  }
                }
              }
            }
            else{
              return true;
            }

          }

        }
      }
    }
    else{
      return true;
    }

    return false;
  }

  async replaceWhiteSpaceWithDash(value : string){
    let stringWithSpaces = _.cloneDeep(value);
    let stringWithoutBrackets = stringWithSpaces.replace(/[)]/g, '');
    let stringWithoutSpaces = stringWithoutBrackets.replace(/[^\p{L}0-9]/gu , "-");
    let formattedString = stringWithoutSpaces.replace(/-{2,}/g, '-');

    return formattedString;
  }

  async preStoreNavigation(orderType : string, storeData : StoreResponse, showRewardDialog : boolean = false){
    this.setCurrentOrderType(orderType);
    let locDescWithoutSpaces = await this.replaceWhiteSpaceWithDash(storeData.locDesc);
    let stateObj = {
      currentStatus : storeData.currentStatus,
      showReward : showRewardDialog
    };
    this.router.navigate(['store', storeData.storeId, locDescWithoutSpaces], { state: stateObj });
  }

  async storeSameChannelNavigate(storeId : number, locDesc : string){
    let locDescWithoutSpaces = await this.replaceWhiteSpaceWithDash(locDesc);

    this.router.navigate(['store', storeId, locDescWithoutSpaces]);
  }

  async storeDiffChannelNavigate(channelTag : string, storeId : number, locDesc : string){
    let upperCaseChannel = channelTag.toUpperCase();
    let locDescWithoutSpaces = await this.replaceWhiteSpaceWithDash(locDesc);

    window.location.href = window.location.origin + '/' + upperCaseChannel + '/store/' + storeId + '/' + locDescWithoutSpaces;
  }

  //#region store item stats
  async storeItemStatsFilter(storeItemStats : StoreItemStats[], menuCatgs: MenuCatg[], isHideBlockedItem?: boolean){
    let itemStatsLength = storeItemStats.length;

    for(let i = 0; i < itemStatsLength; i++){
      menuCatgs = await this.storeItemStatsProcess(storeItemStats[i], menuCatgs, isHideBlockedItem);
    }

    return menuCatgs;
  }

  async storeItemStatsProcess(storeItemStats : StoreItemStats, menuCatgs: MenuCatg[], isHideBlockedItem: boolean){
    menuCatgs = await this.menuStatsProcess(menuCatgs, storeItemStats, isHideBlockedItem);
    return menuCatgs;
  }

  async menuStatsProcess(menuCatgs : MenuCatg[], storeItemStats : StoreItemStats, isHideBlockedItem: boolean){
    let menuLength = menuCatgs.length;
    let finalMenuCatgs = [];
    for(let index = 0; index < menuLength; index++){
      menuCatgs[index].menuItems = await this.menuItemProcess(menuCatgs[index].menuItems, storeItemStats, menuCatgs, isHideBlockedItem);

      // check found any one menu item is available, then it will show it.
      if (isHideBlockedItem) {
        let availableItem = menuCatgs[index].menuItems.find(menuItem => menuItem.status === MenuItemStatus.Available && !menuItem.variationOnly);
        if (availableItem) {
          finalMenuCatgs.push(menuCatgs[index]);
        }
      } else {
        finalMenuCatgs.push(menuCatgs[index]);
      }
    }
    return finalMenuCatgs;
  }

  async menuItemProcess(menuItems : MenuItem[], storeItemStats : StoreItemStats, menuCatgs: MenuCatg[], isHideBlockedItem: boolean){
    let itemLength = menuItems.length;
    for(let index = 0; index < itemLength; index++){
      if(menuItems[index].modifierGrps){
        menuItems[index].modifierGrps = await this.modifierGrpProcess(menuItems[index].modifierGrps, storeItemStats);

        // check if one of the mandatory modifier group is unavailable
        let mandatoryGroupBlock = menuItems[index].modifierGrps.some(modGrp => modGrp.minSelect >= 1 && modGrp.status == MenuItemStatus.Unavailable);
        // if one of the group is unavailable then change menu item status to unavailable
        // ELSE use back status provided by backend if it is given, if not, will use available status
        if(menuItems[index].status){
          menuItems[index].status = mandatoryGroupBlock ? MenuItemStatus.Unavailable : menuItems[index].status;
        }
        else{
          menuItems[index].status = mandatoryGroupBlock ? MenuItemStatus.Unavailable : MenuItemStatus.Available;
        }
      }

      if(menuItems[index].itemCode == storeItemStats.itemCode){
        // if store item stats is blocked and current menu item is not unavailable status, then we will assign Unavailable status
        if(storeItemStats.statusFlag == ItemStatsStatusFlag.blocked && menuItems[index].status != MenuItemStatus.Unavailable){
          menuItems[index].status = MenuItemStatus.Unavailable;
        }

        menuItems[index].prepareMin = storeItemStats.prepareTimeMinute ? storeItemStats.prepareTimeMinute : 0;

         //get item avaibality balance
         if (storeItemStats.allowQty && storeItemStats.allowQty != null && storeItemStats.allowQty != undefined && storeItemStats.allowQty > 0) {
          let usedQty = storeItemStats.usedQty ? storeItemStats.usedQty : 0;
          let reserveQty = storeItemStats.reservedQty ? storeItemStats.reservedQty : 0;
          menuItems[index].qtyBalance = storeItemStats.allowQty - usedQty - reserveQty;
          if (menuItems[index].qtyBalance == 0 || menuItems[index].qtyBalance < 0) {
            menuItems[index].status = MenuItemStatus.Unavailable;
          }
        }
      }

      // check and update variation status
      let variations = menuItems[index].variations;
      if (variations) {
        let blockVariationNo = 0;
        for(let index = 0; index < variations.length; index++) {
          let itemCode = variations[index];
          let variation = await this.searchByItemIdOrItemCode(itemCode, null, menuCatgs);
          if (variation && variation.status === MenuItemStatus.Unavailable) {
            blockVariationNo += 1;
          }
        }

        if (blockVariationNo === menuItems[index].variations.length) {
          menuItems[index].status = MenuItemStatus.Unavailable;
        }
      }

      // set the menu item status to hide at the end
      if (menuItems[index].status === MenuItemStatus.Unavailable && isHideBlockedItem) {
        menuItems[index].status = MenuItemStatus.Hide;
      }
    }

    return menuItems;
  }

  async modifierGrpProcess(modifierGrp : ModifierGrp[], storeItemStats : StoreItemStats){
    let modGrpLength = modifierGrp.length;
    for(let index = 0; index < modGrpLength; index++){
      if(modifierGrp[index].modifiers){
        modifierGrp[index].modifiers = await this.modifierProcess(modifierGrp[index].modifiers, storeItemStats);

        // check every modifiers of current modifierGrps to see if all of it is blocked
        let allBlock = modifierGrp[index].modifiers.every(modifier => modifier.status == MenuItemStatus.Unavailable);
        // if modifier group is mandatory AND all of its modifiers is blocked then change modifier group status to unavailable
         // else use back the status provided by backend if the status is given, if not given, use available status instead
        if(modifierGrp[index].status){
          modifierGrp[index].status = modifierGrp[index].minSelect >= 1 && allBlock ? MenuItemStatus.Unavailable : modifierGrp[index].status;
        }
        else{
          modifierGrp[index].status = modifierGrp[index].minSelect >= 1 && allBlock ? MenuItemStatus.Unavailable : MenuItemStatus.Available;
        }
      }
    }
    return modifierGrp;
  }

  async modifierProcess(modifiers : Modifier[], storeItemStats : StoreItemStats){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      if(modifiers[index].subModifierGrps){
        modifiers[index].subModifierGrps = await this.subModGrpProcess(modifiers[index].subModifierGrps, storeItemStats);

        // check if one of the mandatory submodifier group is unavailable
        let mandatorySubGroupBlock = modifiers[index].subModifierGrps.some(subModGrp => subModGrp.minSelect >= 1 && subModGrp.status == MenuItemStatus.Unavailable);
        // if one of the group is unavailable then change modifier status to unavailable
        // ELSE use back status provided by backend if it is given, if not, will use available status
        if(modifiers[index].status){
          modifiers[index].status = mandatorySubGroupBlock ? MenuItemStatus.Unavailable : modifiers[index].status;
        }
        else{
          modifiers[index].status = mandatorySubGroupBlock ? MenuItemStatus.Unavailable : MenuItemStatus.Available;
        }

      }

      if(modifiers[index].itemCode == storeItemStats.itemCode ){
        if(storeItemStats.statusFlag == ItemStatsStatusFlag.blocked && modifiers[index].status != MenuItemStatus.Unavailable){
          modifiers[index].status = MenuItemStatus.Unavailable;
        }

        //get item avaibality balance
        if (storeItemStats.allowQty && storeItemStats.allowQty != null && storeItemStats.allowQty != undefined && storeItemStats.allowQty > 0) {
          let usedQty = storeItemStats.usedQty ? storeItemStats.usedQty : 0;
          let reserveQty = storeItemStats.reservedQty ? storeItemStats.reservedQty : 0;
          modifiers[index].qtyBalance = storeItemStats.allowQty - usedQty - reserveQty;
          if (modifiers[index].qtyBalance == 0 || modifiers[index].qtyBalance < 0) {
            modifiers[index].status = MenuItemStatus.Unavailable;
          }
        }
      }
    }
    return modifiers;
  }

  async subModGrpProcess(subModifierGrps : SubModifierGrp[], storeItemStats : StoreItemStats){
    let subModGrpLength = subModifierGrps.length;
    for(let index = 0; index < subModGrpLength; index++){
      if(subModifierGrps[index].subModifiers){
        subModifierGrps[index].subModifiers = await this.subModifierProcess(subModifierGrps[index].subModifiers, storeItemStats);

        // check every submodifiers of current submodifierGrps to see if all of it is blocked
        let allBlock = subModifierGrps[index].subModifiers.every(submodifier => submodifier.status == MenuItemStatus.Unavailable);
        // if submodifier group is mandatory AND all of its submodifiers is blocked then change submodifier group status to unavailable
        // else use back the status provided by backend if the status is given, if not given, use available status instead
        if(subModifierGrps[index].status){
          subModifierGrps[index].status = subModifierGrps[index].minSelect >= 1 && allBlock ? MenuItemStatus.Unavailable : subModifierGrps[index].status;
        }
        else{
          subModifierGrps[index].status = subModifierGrps[index].minSelect >= 1 && allBlock ? MenuItemStatus.Unavailable : MenuItemStatus.Available;
        }

      }
    }
    return subModifierGrps;
  }

  async subModifierProcess(subModifiers : SubModifier[], storeItemStats : StoreItemStats){
    let subModLength = subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      if(subModifiers[index].itemCode == storeItemStats.itemCode){
        if(storeItemStats.statusFlag == ItemStatsStatusFlag.blocked && subModifiers[index].status != MenuItemStatus.Unavailable){
          subModifiers[index].status = MenuItemStatus.Unavailable;
        }

         //get item avaibality balance
         if (storeItemStats.allowQty && storeItemStats.allowQty != null && storeItemStats.allowQty != undefined && storeItemStats.allowQty > 0) {
          let usedQty = storeItemStats.usedQty ? storeItemStats.usedQty : 0;
          let reserveQty = storeItemStats.reservedQty ? storeItemStats.reservedQty : 0;
          subModifiers[index].qtyBalance = storeItemStats.allowQty - usedQty - reserveQty;
          if (subModifiers[index].qtyBalance == 0 || subModifiers[index].qtyBalance < 0) {
            subModifiers[index].status = MenuItemStatus.Unavailable;
          }
        }
      }
    }

    return subModifiers;
  }
  //#endregion

  async processMenuAvailability(menuCatgs : MenuCatg[], reqTime : string, orderType : string){
    let menuCatgLength = menuCatgs.length;
    let chosenTime : any;

    chosenTime = reqTime ? reqTime : this.timeService.getServerTime();
    let currentDay = new Date(chosenTime).getDay();

    for(let index = 0; index < menuCatgLength; index++){
      let isValid : boolean = true;

      // check menu category start and end date if it exists
      if(menuCatgs[index].startDate && !menuCatgs[index].endDate){
        isValid = this.timeService.isSameOrAfterGivenDate(chosenTime, menuCatgs[index].startDate);
      }
      else if(!menuCatgs[index].startDate && menuCatgs[index].endDate){
        isValid = this.timeService.isSameOrBeforeGivenDate(chosenTime, menuCatgs[index].endDate);
      }
      else if(menuCatgs[index].startDate && menuCatgs[index].endDate){
        isValid = this.timeService.isBetweenGivenDate(chosenTime, menuCatgs[index].startDate, menuCatgs[index].endDate);
      }

      // if not valid then change respective menu category status to Hide
      // and will not proceed to check the logic below
      if(!isValid){
        menuCatgs[index].status = MenuItemStatus.Hide;
        continue;
      }

      // new menu time handing which refer to availablePeriods
      // will not be using it in live as RMS havent used new structure yet
      if(menuCatgs[index].availablePeriods && menuCatgs[index]?.availablePeriods.length > 0){
        isValid = false;

        let periodLength = menuCatgs[index].availablePeriods.length;
        for(let periodIndex = 0; periodIndex < periodLength; periodIndex++){
          let convertedDays = this.timeService.convertDayToNumber(menuCatgs[index].availablePeriods[periodIndex].dayOfWeek);

          if(convertedDays.includes(currentDay) || convertedDays.length == 0){
            if(menuCatgs[index].availablePeriods[periodIndex].startTime && !menuCatgs[index].availablePeriods[periodIndex].endTime){
              isValid = this.timeService.isSameOrAfterGivenTime(chosenTime, menuCatgs[index].availablePeriods[periodIndex].startTime);
            }
            else if(!menuCatgs[index].availablePeriods[periodIndex].startTime && menuCatgs[index].availablePeriods[periodIndex].endTime){
              isValid = this.timeService.isBeforeGivenTime(chosenTime, menuCatgs[index].availablePeriods[periodIndex].endTime);
            }
            else if(menuCatgs[index].availablePeriods[periodIndex].startTime && menuCatgs[index].availablePeriods[periodIndex].endTime){
              isValid = this.timeService.isBetweenGivenTime(menuCatgs[index].availablePeriods[periodIndex].startTime,
                menuCatgs[index].availablePeriods[periodIndex].endTime, chosenTime);
            } else {
              isValid = true;
            }
          }

          if(isValid)
            break;
        }
      }
      // old menu time handling method, will remove in the future
      // temporary used as RMS side havent used new structure
      else{
        if(menuCatgs[index].startTime && !menuCatgs[index].endTime){
          isValid = this.timeService.isSameOrAfterGivenTime(chosenTime, menuCatgs[index].startTime);
        }
        else if(!menuCatgs[index].startTime && menuCatgs[index].endTime){
          isValid = this.timeService.isBeforeGivenTime(chosenTime, menuCatgs[index].endTime);
        }
        else if(menuCatgs[index].startTime && menuCatgs[index].endTime){
          isValid = this.timeService.isBetweenGivenTime(menuCatgs[index].startTime, menuCatgs[index].endTime, chosenTime);
        }
      }
      menuCatgs[index].status = isValid ? menuCatgs[index].status : MenuItemStatus.Hide;

      // if menu category status is already hide then no need to check item status anymore
      if(menuCatgs[index].status == MenuItemStatus.Hide){
        continue;
      }

      let menuItemLength = menuCatgs[index].menuItems.length;
      for(let itemIndex = 0; itemIndex < menuItemLength; itemIndex++){
        let itemValid : boolean = true;

        // if current item have lead time and current order type is DineIn then make that item unavailable
        // no need to run start end time logic/checking
        if(menuCatgs[index].menuItems[itemIndex].leadTime && orderType == OrderTypeFlag.DineIn){
          itemValid = false;
        }
        // the second condition it checking old structure
        // temporarily using it as RMS side havent use new structure
        else if(menuCatgs[index].menuItems[itemIndex].availablePeriod){
          if(menuCatgs[index].menuItems[itemIndex].availablePeriod.startTime && !menuCatgs[index].menuItems[itemIndex].availablePeriod.endTime){
            itemValid = this.timeService.isSameOrAfterGivenTime(chosenTime, menuCatgs[index].menuItems[itemIndex].availablePeriod.startTime);
          }
          else if(!menuCatgs[index].menuItems[itemIndex].availablePeriod.startTime && menuCatgs[index].menuItems[itemIndex].availablePeriod.endTime){
            itemValid = this.timeService.isBeforeGivenTime(chosenTime, menuCatgs[index].menuItems[itemIndex].availablePeriod.endTime);
          }
          else if(menuCatgs[index].menuItems[itemIndex].availablePeriod.startTime && menuCatgs[index].menuItems[itemIndex].availablePeriod.endTime){
            itemValid = this.timeService.isBetweenGivenTime(menuCatgs[index].menuItems[itemIndex].availablePeriod.startTime,
              menuCatgs[index].menuItems[itemIndex].availablePeriod.endTime, chosenTime);
          }
        }
        // this condition checks new structure
        // will not run this condition for now as RMS side havent use new structure
        else if(menuCatgs[index].menuItems[itemIndex].availablePeriods && menuCatgs[index].menuItems[itemIndex]?.availablePeriods?.length > 0){
          itemValid = false;

          let itemPeriodLength = menuCatgs[index].menuItems[itemIndex].availablePeriods.length;
          for(let itemPeriodIndex = 0; itemPeriodIndex < itemPeriodLength; itemPeriodIndex++){
            let convertedDays = this.timeService.convertDayToNumber(menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].dayOfWeek);

            if(convertedDays.includes(currentDay) || convertedDays.length == 0){
              if(menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].startTime &&
                !menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].endTime){
                itemValid = this.timeService.isSameOrAfterGivenTime(chosenTime, menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].startTime);
              }
              else if(!menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].startTime &&
                menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].endTime){
                itemValid = this.timeService.isBeforeGivenTime(chosenTime, menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].endTime);
              }
              else if(menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].startTime &&
                menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].endTime){
                itemValid = this.timeService.isBetweenGivenTime(menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].startTime,
                  menuCatgs[index].menuItems[itemIndex].availablePeriods[itemPeriodIndex].endTime, chosenTime);
              } else {
                itemValid = true;
              }
            }

            if(itemValid)
              break;
          }
        }
        menuCatgs[index].menuItems[itemIndex].status = itemValid ? menuCatgs[index].menuItems[itemIndex].status : MenuItemStatus.Unavailable;
      }
    }

    return menuCatgs;
  }

  async isItemAvailable(menuItem : MenuItem, reqTime : string){
    let chosenTime : any;
    let isValid : boolean = true;

    chosenTime = reqTime ? reqTime : this.timeService.getServerTime();

    if(menuItem.availablePeriod){
      if(menuItem.availablePeriod.startTime && !menuItem.availablePeriod.endTime){
        isValid = this.timeService.isSameOrAfterGivenTime(chosenTime, menuItem.availablePeriod.startTime);
      }
      else if(!menuItem.availablePeriod.startTime && menuItem.availablePeriod.endTime){
        isValid = this.timeService.isBeforeGivenTime(chosenTime, menuItem.availablePeriod.endTime);
      }
      else if(menuItem.availablePeriod.startTime && menuItem.availablePeriod.endTime){
        isValid = this.timeService.isBetweenGivenTime(menuItem.availablePeriod.startTime, menuItem.availablePeriod.endTime, chosenTime);
      }
    }

    return isValid;
  }

  async recommendedSectionProcess(storeResponse : StoreResponse, menuCatgs : MenuCatg[], qrTokenResponse : WebLinkTokenResponse){
    // if store response store item history is not empty
    if(storeResponse.storeItemHistory && storeResponse?.storeItemHistory?.length > 0){
      // search and init recommended item
      let recommendedGrp = await this.recommendSectionInit(storeResponse.storeItemHistory, menuCatgs,
        storeResponse.currentOrderType, qrTokenResponse);

      // if recommended item list is not empty OR if it is exist then put it in front of menuCatg list
      if(recommendedGrp){
        menuCatgs.unshift(recommendedGrp);
      }
    }

    return menuCatgs;
  }

  //#region getModGrp code
  setModGrpCode(code : string){
    this.currentModGrp = code;
  }

  getModGrpCode(){
    return this.currentModGrp;
  }
  //#endregion

  //#region recommended section logic/processing
  async searchRecommendedItemGrp(itemCode : string[], menuCatgs : MenuCatg[], orderType : string, qrTokenResponse? : WebLinkTokenResponse){
    let foundedMenu : MenuItem[] = [];
    let foundItem : MenuItem[] = [];
    let previousCode : string[] = [];

    for await(let code of itemCode){
      for await(let menuCatg of menuCatgs){
        if(menuCatg.menuItems && menuCatg.status == MenuItemStatus.Available){
          let item =  menuCatg.menuItems.find(item => item.itemCode == code && !item.variationOnly && item.status != MenuItemStatus.Hide &&
            ((qrTokenResponse && !item.leadTime) || (!qrTokenResponse && orderType == OrderTypeFlag.DineIn && !item.leadTime) ||
                (!qrTokenResponse && orderType != OrderTypeFlag.DineIn)));

          if(item){
            let isItemExist = foundItem.find(addedItem => addedItem.itemCode == item.itemCode);

            if(!isItemExist){
              let cloneItem = _.cloneDeep(item);
              foundItem.push(cloneItem);
              if(foundedMenu.length == 0){
                previousCode.push(menuCatg.code);
                let cloneMenuItem = _.cloneDeep(menuCatg.menuItems);
                foundedMenu.push(...cloneMenuItem);
              }
              else{
                let isCodeExists = previousCode.find(addedCode => addedCode == menuCatg.code);
                if(!isCodeExists){
                  previousCode.push(menuCatg.code);
                  let cloneMenuItem = _.cloneDeep(menuCatg.menuItems);
                  foundedMenu.push(...cloneMenuItem);
                }
              }
            }

            break;
          }
        }
      }
    }

    if(foundItem.length > 0){
      return {menuItemList: foundedMenu, menuItem: foundItem};
    }
    else{
      return null;
    }

  }

  async fillRemainingSpace(displayMenuItem : MenuItem[], combinedList: MenuItem[], orderType : string, qrTokenResponse? : WebLinkTokenResponse){
    const maximumList = 10;
    let remainder = maximumList - displayMenuItem.length;
    for await(let item of displayMenuItem){
      let itemIndex = combinedList.findIndex(val => val.itemCode == item.itemCode);

      if(itemIndex != -1){
        combinedList.splice(itemIndex, 1);
      }
    }

    // filter out menu item that are variation item
    // when QR mode or Dine In and got lead time
    combinedList = combinedList.filter(val => !val.variationOnly && val.status != MenuItemStatus.Hide &&
      ((qrTokenResponse && !val.leadTime) || (!qrTokenResponse && orderType == OrderTypeFlag.DineIn && !val.leadTime) ||
          (!qrTokenResponse && orderType != OrderTypeFlag.DineIn)));

    let shuffledList : MenuItem[];
    let remainingItem : MenuItem[];

    if(combinedList.length > remainder){
      shuffledList = _.shuffle(combinedList);
      remainingItem = shuffledList.slice(0, remainder);
      displayMenuItem = displayMenuItem.concat(remainingItem);
    }
    else{
      displayMenuItem = displayMenuItem.concat(combinedList);
    }

    return displayMenuItem;
  }

  async recommendSectionInit(itemCode : string[], menuCatgs : MenuCatg[], orderType : string, qrTokenResponse? : WebLinkTokenResponse){
    let recommendedMenuCatg = {} as MenuCatg;
    recommendedMenuCatg.code = "9999";
    let translatedValue = this.translateService.instant("merchant.home.recommended.des.1");
    recommendedMenuCatg.title = translatedValue;
    recommendedMenuCatg.seqNo = 100;
    recommendedMenuCatg.startDate = null;
    recommendedMenuCatg.endDate = null;
    recommendedMenuCatg.startTime = "";
    recommendedMenuCatg.endTime = "";
    recommendedMenuCatg.showFlag = "0",
    recommendedMenuCatg.uiMode = "3",
    recommendedMenuCatg.status = MenuItemStatus.Available;
    let recommendedMenuGrp = await this.searchRecommendedItemGrp(itemCode, menuCatgs, orderType, qrTokenResponse);

    if(recommendedMenuGrp){
      recommendedMenuCatg.menuItems = recommendedMenuGrp.menuItem;
      recommendedMenuCatg.menuItems = await this.fillRemainingSpace(recommendedMenuCatg.menuItems, recommendedMenuGrp.menuItemList, orderType, qrTokenResponse);

      return recommendedMenuCatg;
    }
    else{
      return null;
    }

  }
  //#endregion

  removeCartToastInit(){
    let toastData = {} as ToastData;
    toastData.message = "alert.qr.dinein.items.removed.desc.1";
    toastData.icon = "oda-alert";
    toastData.iconColor = "#FFFFFF";
    this.toastService.show(toastData);
  }

  //#region set alcohol consent session
  setIsLegalAge(isLegalAge : boolean){
    let data = {isLegalAge : isLegalAge}
    this.sessionStorageService.setItem('legalAge', JSON.stringify(data));
  }

  removeIsLegalAge(){
    if(this.sessionStorageService.getItem('legalAge')){
      this.sessionStorageService.removeItem('legalAge');
    }
  }

  getIsLegalAge(){
    let isLegalAge : boolean;
    if(this.sessionStorageService.getItem('legalAge')){
      isLegalAge = JSON.parse(this.sessionStorageService.getItem('legalAge')).isLegalAge;
    }
    else{
      isLegalAge = false;
    }

    return isLegalAge;
  }

  //#endregion

  async getPlatformSetting(platformSet : ChannelPlatformSetResponse[], setCode : string, storeId : number){
    let filteredSetting =  platformSet.filter(val => val.setCode == setCode);
    let currentSetting : ChannelPlatformSetResponse;

    currentSetting = filteredSetting.find(val => val.storeId == storeId);
    if(!currentSetting){
      currentSetting = filteredSetting.find(val => val.storeId == 0);
    }

    return currentSetting ? currentSetting : null;
  }

  // order type checking
  async isOrderTypeRequiredAction(orderH : OrderH, currentOrderType : string, tableNo : string){
    let cartOrderType = this.getOrderType(orderH.orderData.sourceFlag);
    let requiredAction : boolean;

    if(currentOrderType != cartOrderType){
      if(currentOrderType == OrderTypeFlag.DineIn){
        if(this.tableReqValue && this.tableReqValue.setValue == "1" && !tableNo){
          requiredAction = true;
        }
        else{
          requiredAction = false;
        }
      }
      else if(currentOrderType == OrderTypeFlag.Delivery){
        if(!this.address){
          requiredAction = true;
        }
        else{
          requiredAction = false;
        }
      }
      else{
        requiredAction = false;
      }
    }
    else{
      requiredAction = false;
    }

    return requiredAction;
  }

  isItemModifierEmpty(menuItem : MenuItem){
    return menuItem.modifierGrps && menuItem.modifierGrps?.length > 0 ? false : true;
  }

  storeDataCombinedInit(storeResp : any){
    let storeData = {} as StoreMenuResponseExtra;
    storeData.storeResponses = storeResp.StoreResponse;
    storeData.menuResponse = storeResp.storeMenuData;
    storeData.storeItemStats = storeResp.StoreItemStats;
    storeData.storePromotion = storeResp.StorePromotion;
    storeData.storeVoucherType = storeResp.StoreVoucherType;
    storeData.MerchantMemberships = storeResp.MerchantMemberships;

    return storeData;
  }

  //#region get/set isDisplayedFlag
  saveIsDisplayedFlag(){
    this.sessionStorageService.setItem("displayed", JSON.stringify({isDisplayed: true}));
  }

  getIsDisplayedFlag(){
    let isDisplayed = JSON.parse(this.sessionStorageService.getItem("displayed"))?.isDisplayed;
    return isDisplayed ? true : false;
  }

  removeIsDisplayedFlag(){
    this.sessionStorageService.removeItem("displayed");
  }
  //#endregion

  saveMembershipStoreInfo(storeInfo : Partial<StoreResponse>){
    if(storeInfo){
      this.sessionStorageService.setItem("storeInfo", JSON.stringify(storeInfo));
    }
  }

  getMembershipStoreInfo(){
    let storeInfo = JSON.parse(this.sessionStorageService.getItem("storeInfo"));
    return storeInfo ? storeInfo : null;
  }

  removeMembershipStoreInfo(){
    this.sessionStorageService.removeItem("storeInfo");
  }

  getStorePlatformSet(storeId: number, setCode: SetCode) {
    let existingStoreData = this.storeQuery.getEntity(storeId);
    let storePlatformSets = existingStoreData?.storeResponse?.platformSets ? existingStoreData.storeResponse.platformSets : null;
    let data: any;
    if(storePlatformSets) {
      data = storePlatformSets.find(platformSet => platformSet.setCode === setCode)?.setValue;
    }
    return data;
  }

  showNutriRemakImage() {
    this.nutriFooterRemarkDisplay$.next(true);
  }
}
