
import { UserService } from './../../core/user/user.service';
import { LoginForm } from '../models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from "@angular/forms";
import { ChangeData } from "ngx-intl-tel-input";
import { OtpService } from './../services/otp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorObj } from 'src/app/core/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  //form
  loginForm!: UntypedFormGroup;
  submitted = false;
  //form above

  mobileNo: string = '';

  // Control
  errorObj: ErrorObj = null as any;
  isFromLandingPage: boolean = false;
  isFromHomePage : boolean = false;
  isFromCartPage : boolean = false;
  mobileObj: ChangeData | null = null;
  showContGuest: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private otpService: OtpService,
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.isFromLandingPage = this.router.getCurrentNavigation().extras.state?.isFromLanding;
      this.isFromHomePage = this.router.getCurrentNavigation().extras.state?.isFromHomePage;
      this.isFromCartPage = this.router.getCurrentNavigation().extras.state?.isFromCartPage;
      this.showContGuest = history?.state?.showContGuest ? history.state.showContGuest : false;
    }
  }

  ngOnInit(): void {
    //form
    this.loginForm = this.formBuilder.group({
      phone: new UntypedFormControl("", [Validators.required]),
      phoneNo: ['', Validators.required],
    });
    //form above
    this.mobileNo = history?.state?.mobileNo;
  }

  async onLogin(dt: LoginForm) {
    let phoneNoExist: boolean = false;
    let pwIsSet: boolean = false;
    let resp: any = await this.userService.getCustomerProfileStatus(dt.dialCode.replace('+', '') + dt.phoneNo, "");

    if (!(resp instanceof HttpErrorResponse)) {
      phoneNoExist = resp.mobileNumber? true: false;
      pwIsSet = resp.passwordFlag? resp.passwordFlag: false;
    }

    if(phoneNoExist && pwIsSet) {
      this.router.navigate(["password-login"], { state: { data: dt, phoneNoExist: phoneNoExist }, replaceUrl: true });
    } else {
      await this.otpService.onSendOTP(dt.mobileObj);
      this.router.navigate(["otp"], { state: { loginForm: dt, phoneNoExist: phoneNoExist, signupForm: !phoneNoExist }, replaceUrl: true });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

}

