import { StoreResponse } from './../../../core/models/StoreResponse';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { StoreMode } from 'src/app/core/enums';
import { ChannelData } from 'src/app/core/models';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { DefaultService } from 'src/app/home/services/default.service';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FilterItem } from 'src/app/core/models/local/FilterItem';
import { ChannelSecService } from 'src/app/home/channelsec/channelsec.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AnalyticsService } from '../../services/analytics.service';
import { AnalyticsEvent } from 'src/app/core/enums/AnalyticsEvent';
import { SetCode } from 'src/app/core/enums/SetCode';
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements OnInit {
  @Input() isFromHome: boolean = false;
  @Input() set inputSearchValue(inputValue: string) {
    this.searchInputValue = inputValue;
    this.getFormControl('searchInputText').setValue(inputValue);
    if (!inputValue && this.searchValue) {
      this.searchValue.nativeElement.focus();
    }
  }
  @Input() routeSearchText: string = '';
  @Input() storesData: StoreResponse[] = null;
  @Input() recentSearch: string[] = [];
  @Output() clearSearchInput = new EventEmitter<boolean>();
  @Output() outputSearchStore = new EventEmitter<string>();
  @Output() outputRecentSearch = new EventEmitter<boolean>();

  searchInputValue: string = '';
  channelSub: Subscription = new Subscription();
  cuisines: any;
  displaySearchDialog: boolean = false;
  displayFilterDropdown: boolean = false;
  selectedChannel: ChannelData = undefined;
  rateList: any = [1, 2, 3, 4, 5];
  selectedRateValue: number;
  catgAttrs_1: any;
  catgAttrs_2: any;
  catgAttrsDes_1: any;
  catgAttrsDes_2: any;
  catgAttrsList_1: any;
  catgAttrsList_2: any;
  catgAttrsValue_1: any[] = [];
  catgAttrsValue_2: any[] = [];

  public products: StoreResponse[] = null;
  displayStoreDialog: boolean = false;
  storeData: StoreResponse;
  currency: CurrencyResponse;
  isMobileView: boolean = false;
  totalCountItem: any = 0;
  priceRangeValues: number[] = [0, 100];
  priceRangeActive: boolean = false;

  @ViewChild('searchValue') searchValue: ElementRef;

  displaySearchDropdown: boolean = false;
  searchFgroup: UntypedFormGroup = this.fb.group({
    searchInputText: ['']
  });
  filterList = [];

  blockScroll: boolean = false;

  constructor(
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private defaultService: DefaultService,
    private channelService: ChannelService,
    private breakpointObserver: BreakpointObserver,
    private channelSecService: ChannelSecService,
    private fb: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private sessionStorageService: SessionStorageService
  ) {
    if (localStorage.getItem('recent-search') === '' || localStorage.getItem('recent-search') === null) {
      localStorage.removeItem('recent-search');
    } else {
      this.recentSearch = JSON.parse(localStorage.getItem('recent-search'));
    }

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileView = true;
        this.blockScroll = true
      } else {
        this.isMobileView = false;
        this.blockScroll = false;
      }
    });
  }

  filterColorSet: any = null;

  async ngOnInit(): Promise<void> {
    this.primengConfig.ripple = true;
    this.channelSub = this.channelService.get(StoreMode.Internal).subscribe((dt) => {
      if (dt) {
        this.selectedChannel = dt.data;
        this.cuisines = dt.data.channelCatgAttrs[0] ? dt.data.channelCatgAttrs[0].catgAttrOptions : [];

        this.catgAttrs_1 = dt.data.channelCatgAttrs[0] ? dt.data.channelCatgAttrs[0] : null;
        this.catgAttrsDes_1 = dt.data.channelCatgAttrs[0] ? dt.data.channelCatgAttrs[0].attrDesc : null;
        this.catgAttrsList_1 = dt.data.channelCatgAttrs[0] ? dt.data.channelCatgAttrs[0].catgAttrOptions : [];

        this.catgAttrs_2 = dt.data.channelCatgAttrs[1] ? dt.data.channelCatgAttrs[1] : null;
        this.catgAttrsDes_2 = dt.data.channelCatgAttrs[1] ? dt.data.channelCatgAttrs[1].attrDesc : null;
        this.catgAttrsList_2 = dt.data.channelCatgAttrs[1] ? dt.data.channelCatgAttrs[1].catgAttrOptions : [];

        this.priceRangeValues = [dt.data.filterMinVal, dt.data.filterMaxVal];
        this.rebindStoreAttrData();
        this.filterColorSet = this.channelService.getPlatformSetValue(SetCode.FILTERCOLORSET);
      }
    });

    this.defaultService.searchFilterDialogObserve().subscribe(data => {
      if (data && data.displayFilter) {
        this.onClickDisplayFilterDropdown();
        if (!this.isMobileView) {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
      }

      if (data && data.displaySearch) {
        this.displaySearchDropdown = true;
        this.searchValue.nativeElement.focus();
      }
    });
  }

  rebindStoreAttrData() {
    let filterItem: FilterItem = JSON.parse(this.sessionStorageService.getItem('filter-item'));
    this.catgAttrsValue_1 = [];
    this.catgAttrsValue_2 = [];

    if (filterItem) {
      if (filterItem.storeAttrs.length > 0) {
        let storeAttrList = filterItem.storeAttrs.split(',');

        if (storeAttrList && storeAttrList.length > 0) {
          this.catgAttrsList_1.forEach(value => {
            let index = storeAttrList.findIndex(attr => attr == value.attrOptionId);
            if (index >= 0) {
              this.catgAttrsValue_1.push(value);
              storeAttrList.splice(index, 1);
            }
          });

          this.catgAttrsList_2.forEach(value => {
            let index = storeAttrList.findIndex(attr => attr == value.attrOptionId);
            if (index >= 0) {
              this.catgAttrsValue_2.push(value);
              storeAttrList.splice(index, 1);
            }
          });
        }
      }

      if (filterItem.rate) {
        this.selectedRateValue = filterItem.rate;
      }

      if ((filterItem.minPrice && filterItem.maxPrice) || (!filterItem.minPrice && filterItem.maxPrice)) {
        this.priceRangeValues = [filterItem.minPrice, filterItem.maxPrice];
        this.priceSlide(this.priceRangeValues);
      }

      this.countTotalItem();
    }
  }

  onClickCloseSearchDropdown() {
    this.displaySearchDropdown = false;
    this.displayFilterDropdown = false;
  }

  ondelete() {
    this.recentSearch = [];
    localStorage.setItem('recent-search', JSON.stringify(this.recentSearch));
    this.outputRecentSearch.emit(true);
  }

  async onSearch() {
    this.searchValue.nativeElement.blur();
    this.searchInputValue = this.searchFgroup.value.searchInputText;

    this.displaySearchDropdown = false;
    if (this.searchInputValue) {
      this.analyticsService.logEvents(AnalyticsEvent.search, { search_term: this.searchInputValue, c_search_type: 'outlet' });
      this.router.navigate(['search-result'], { queryParams: { searchInputText: this.searchInputValue } });
    }
    if (this.searchInputValue && this.searchInputValue == this.routeSearchText) {
      this.outputSearchStore.emit(this.searchInputValue);
    }
  }

  onClickDisplaySearchDropdown() {
    this.displaySearchDropdown = true;
    this.displayFilterDropdown = false;
  }

  clearSearchField() {
    this.searchInputValue = '';
    this.getFormControl('searchInputText').setValue('');
    this.clearSearchInput.emit(true);
    this.onClickDisplaySearchDropdown();
  }

  onRecentClick(searchText: string): void {
    this.displaySearchDropdown = false;
    this.router.navigate(['search-result'], { queryParams: { searchInputText: searchText } });
    this.outputSearchStore.emit(searchText);
  }

  showSearchDialog() {
    this.router.navigate(['search-result']);
    this.displaySearchDialog = true;
  }

  closeSearchDialog() {
    this.displaySearchDialog = false;
  }

  onClickDisplayFilterDropdown() {
    this.displayFilterDropdown = true;
    this.displaySearchDropdown = false;
  }

  onClickItem() {
    this.countTotalItem();
  }

  countTotalItem() {
    this.totalCountItem = 0;

    if (this.catgAttrsValue_1) {
      this.totalCountItem += this.catgAttrsValue_1.length;
    }

    if (this.catgAttrsValue_2) {
      this.totalCountItem += this.catgAttrsValue_2.length;
    }

    if (this.selectedRateValue) {
      this.totalCountItem += 1;
    }

    if (this.priceRangeActive) {
      this.totalCountItem += 1;
    }

    this.totalCountItem = this.totalCountItem + '';
  }

  onClickRate(itm: any) {
    if (itm == this.selectedRateValue) {
      this.selectedRateValue = null
    }
    else {
      this.selectedRateValue = itm;
    }

    this.countTotalItem();

  }

  clearAllFilter() {
    let checkFilterItem = JSON.parse(this.sessionStorageService.getItem('filter-item'));
    if (checkFilterItem) {
      this.catgAttrsValue_2 = [];
      this.selectedRateValue = null;
      this.catgAttrsValue_1 = [];
      this.totalCountItem = 0;
      this.priceRangeActive = false;
      this.sessionStorageService.removeItem('filter-item');
      this.channelSecService.removeAll();
      this.filterResult();
    }
    else if(!checkFilterItem && this.totalCountItem > 0) {
      this.catgAttrsValue_2 = [];
      this.selectedRateValue = null;
      this.catgAttrsValue_1 = [];
      this.totalCountItem = 0;
      this.priceRangeActive = false;
      this.priceRangeValues = [this.selectedChannel.filterMinVal, this.selectedChannel.filterMaxVal];
      this.priceSlide(this.priceRangeValues);
    }
  }

  async onClickFilterBtn() {
    let checkFilterItem = JSON.parse(this.sessionStorageService.getItem('filter-item'));
    if (this.totalCountItem > 0) {
      this.displayFilterDropdown = false;
      let selectedStoreAttrs = '';
      this.filterList = [];

      if (this.catgAttrsValue_1) {
        this.catgAttrsValue_1.forEach(value => {
          if (value.attrOptionId) {
            selectedStoreAttrs += value.attrOptionId + ',';
          };

          this.addFilterList(this.catgAttrs_1.attrDesc, value.attrOptionDesc);
        });
      }

      if (this.catgAttrsValue_2) {
        this.catgAttrsValue_2.forEach(value => {
          if (value.attrOptionId) {
            selectedStoreAttrs += value.attrOptionId + ',';
          };

          this.addFilterList(this.catgAttrs_2.attrDesc, value.attrOptionDesc);
        });
      }

      // GA filter list
      this.filterList.push({
        filter_type: 'min_price',
        filter_value: this.priceRangeValues[0]
      }, {
        filter_type: 'max_price',
        filter_value: this.priceRangeValues[1]
      }, {
        filter_type: 'rating',
        filter_value: this.selectedRateValue
      });

      let filterItem: FilterItem = {
        storeAttrs: selectedStoreAttrs,
        minPrice: this.priceRangeValues[0],
        maxPrice: this.priceRangeValues[1],
        rate: this.selectedRateValue,
      };

      this.channelSecService.removeAll();
      this.sessionStorageService.setItem('filter-item', JSON.stringify(filterItem));
      this.analyticsService.filterActionEvent(this.totalCountItem, this.filterList);
      this.filterResult(filterItem);
    }
    else if (checkFilterItem && this.totalCountItem == 0) {
      this.clearAllFilter();
      this.displayFilterDropdown = false;
    }
    else {
      this.displayFilterDropdown = false;
    }

    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  addFilterList(type: string, value: string) {
    this.filterList.push({
      filter_type: type,
      filter_value: value
    });
  }

  filterResult(filterItem?: FilterItem) {
    this.channelService.getChannel({
      storeAttrs: filterItem ? filterItem.storeAttrs : undefined,
      minPrice: filterItem ? filterItem.minPrice : undefined,
      maxPrice: filterItem ? filterItem.maxPrice : undefined,
      rate: filterItem ? filterItem.rate : undefined,
      channelTag: this.selectedChannel.channelTag
    });
  }

  removeRecentRecord(index: number) {
    this.recentSearch.splice(index, 1);
    localStorage.setItem('recent-search', JSON.stringify(this.recentSearch));
  }

  onClickCloseFilterDropdown() {
    this.displayFilterDropdown = false;
  }

  priceSlide(priceRangeValues: any) {
    if (priceRangeValues[0] == this.selectedChannel.filterMinVal && priceRangeValues[1] == this.selectedChannel.filterMaxVal) {
      this.priceRangeActive = false;
    } else {
      this.priceRangeActive = true;
    }

    this.countTotalItem();
  }

  private getFormControl(formControlName: string) {
    return this.searchFgroup.controls[formControlName];
  }
}
