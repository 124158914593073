import { RoutingService } from './../../../home/services/routing.service';
import { Component, OnInit, Inject, PLATFORM_ID   } from '@angular/core';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  showPageNotFound: boolean = false;

  constructor(
    private routingService: RoutingService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showPageNotFound = true;
    }
  }

  navigateHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }

}
