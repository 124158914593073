import { Subscription } from 'rxjs';
import { Component, EventEmitter, OnInit, Output, HostListener } from '@angular/core';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { Channel } from 'src/app/home/channel/channel.model';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { AnalyticsService } from '../../services/analytics.service';
import { DefaultService } from 'src/app/home/services/default.service';
import { ChannelService } from 'src/app/home/channel/channel.service';

interface Country {
  name: string,
  icon: string,
  value: string
}

@Component({
  selector: 'app-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.scss']
})
export class OrderTypeComponent implements OnInit {
  @Output() onOrderTypeChanged = new EventEmitter();
  orderTypeList: Country[] = [{
    name: 'All Order',
    icon: 'duotone oda-d-logo',
    value: OrderTypeFlag.All
  }];
  selectedOrderType: string = '';
  selectedChannel: Channel = null;
  channelSub$: Subscription = new Subscription();
  staticQrSub$: Subscription = new Subscription();
  qrScannerTrigger$: Subscription = new Subscription();
  isMobileView: boolean = false;

  constructor(
    private channelQuery: ChannelQuery,
    private breakpointObserver : BreakpointObserver,
    private staticQrService: StaticQrService,
    private analyticsService: AnalyticsService,
    private defaultService: DefaultService,
    private channelService : ChannelService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    });
  }

  ngOnInit(): void {
    this.channelSub$ = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      if (channel) {
        this.orderTypeList = [];
        this.selectedOrderType = channel.orderType? channel.orderType: '';
        const orderTypeCount = await this.getOrderTypeCount(channel.data);

        if (orderTypeCount > 1) {
          this.orderTypeList.push({
            name: 'order.type.select.all.order.type',
            icon: 'duotone oda-d-logo',
            value: OrderTypeFlag.All
          });
        }

        if (channel.data.deliveryFlag) {
          this.orderTypeList.push({
            name: 'order.type.select.delivery',
            icon: 'oda-delivery',
            value: OrderTypeFlag.Delivery
          });
        }

        if (channel.data.pickupFlag) {
          this.orderTypeList.push({
            name: 'order.type.select.pick.up',
            icon: 'oda-bag',
            value: OrderTypeFlag.Pickup
          });
        }

        if (channel.data.dineFlag && environment.enableDineIn) {
          this.orderTypeList.push({
            name: 'order.type.select.dine.in',
            icon: 'oda-dinein',
            value: OrderTypeFlag.DineIn
          });
        }

        if(orderTypeCount == 1){
          this.selectedOrderType = this.orderTypeList[0].value;
        }

        if (this.selectedChannel && this.selectedChannel.channelTag !== channel.data.channelTag) {
          this.selectedOrderType = this.orderTypeList[0].value;
        }

        this.channelService.setCurrentOrderType(this.selectedOrderType);
        this.selectedChannel = channel;
      }
    });
  }

  orderTypeChange(orderType: string) {
    this.selectedOrderType = orderType;

    if(!this.isMobileView){
      this.staticQrService.saveChannelOrderType(orderType);
      this.analyticsService.selectDeliveryModeEvent(orderType);
    }

    this.onOrderTypeChanged.emit(orderType);
  }

  async getOrderTypeCount(data): Promise<number> {
    let orderTypeCount: number = 0;

    if (data.deliveryFlag) {
      orderTypeCount++;
    }

    if (data.pickupFlag) {
      orderTypeCount++;
    }

    if (data.dineFlag && environment.enableDineIn) {
      orderTypeCount++;
    }

    return orderTypeCount;
  }

  ngOnDestroy() {
    this.channelSub$.unsubscribe();
    this.staticQrSub$.unsubscribe();
  }
}
