import { StoreItemStatsModel } from './store-item-stats.model';
import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";

export interface StoreItemStatsState extends EntityState<StoreItemStatsModel> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'storeItemStats' })
export class StoreItemStatsStore extends EntityStore<StoreItemStatsState, StoreItemStatsModel> {

  constructor() {
    super();
  }

}
