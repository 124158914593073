<div class="app-download-container hidden-sm">
  <div class="oda-mobile-panel">
    <i class="oda-mobile"></i>
    <h1>{{'downloadApp.header.1' | translate}}</h1>
    <button type="button" (click)="showAppDialog()">{{'downloadApp.header.2' | translate}}</button>
  </div>
</div>

<div class="app-download-dialog">
  <p-dialog position="top"  [modal]=true [dismissableMask]="true" [draggable]="false" [(visible)]="displayAppDownloadDialog">
    <div class="header">
      <h1>{{'downloadApp.dialog.header.1' | translate}}</h1>
      <i class="oda-times" (click)="displayAppDownloadDialog = false"></i>
    </div>
    <p>{{'downloadApp.dialog.header.2' | translate}}</p>

    <div class="app-list">
      <!--apple store-->
      <button (click)="onDownloadNavigate('apple-store')" routerLinkActive="active">
        <img src="assets/app_icon/apple_store.webp" alt="appleStore logo"/>
      </button>
      <!--google_play-->
      <button (click)="onDownloadNavigate('google-play')" routerLinkActive="active">
        <img src="assets/app_icon/google_play.webp" alt="googlePlay logo"/>
      </button>
      <!--huawei_gallery-->
      <button (click)="onDownloadNavigate('huawei-gallery')" routerLinkActive="active">
        <img src="assets/app_icon/huawei_gallery.webp" alt="huaweiPlay logo"/>
      </button>
    </div>

  </p-dialog>
</div>
