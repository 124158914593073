import { CurrentStatusFlag } from './../../core/enums/CurrentStatusFlag';
import { OrderSourceFlag } from './../../core/enums/OrderSourceFlag';
import { QrScannerService } from './../../core/services/qr-scanner.service';
import { ChannelPlatformSetResponse } from './../../core/models/ChannelPlatformSetResponse';
import { StaticQrService } from './../../core/static-qr/static-qr.service';
import { OrderTypeFlag } from './../../core/enums/OrderTypeFlag';
import { StoreService } from '../../store/store/store.service';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { AvailableTime } from 'src/app/core/models/AvailableTime';
import { TimeInterval } from 'src/app/core/models/TimeInterval';
import { CartModel } from 'src/app/core/models/CartModel';
import { OrderH } from 'src/app/core/models/OrderH';
import { UserService } from 'src/app/core/user/user.service';
import { User } from 'src/app/core/user/user.model';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { TimeService } from 'src/app/core/services/time.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { Subscription } from 'rxjs';
import { SetCode } from 'src/app/core/enums/SetCode';
import { Channel } from 'src/app/home/channel/channel.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-store-odr-time',
  templateUrl: './store-odr-time.component.html',
  styleUrls: ['./store-odr-time.component.scss']
})
export class StoreOdrTimeComponent implements OnInit, AfterViewInit, OnDestroy{

  @Input() availableTimeSlot : AvailableTime[] = [];
  @Input() todayTime : AvailableTime;
  @Input() currentOrderDateSlot : AvailableTime[] = [];
  @Input() storeResponse : StoreResponse;
  @Input() isReschedule : boolean = false;
  @Input() isOutOfCoverage : boolean = false;
  @Input() isCartPage : boolean = false;
  @Input() orderH : OrderH;
  @Input() isMobileView : boolean;

  @Output() checkoutCurrentOrderTime = new EventEmitter(); //continue/checkout action
  @Output() onCloseDialog = new EventEmitter(); //close icon action

  todayDate : Date = new Date(); //might not need

  currentId : string = "Delivery";
  previousDate : AvailableTime;

  user : User
  customerId : number | string;
  longitude : number;
  latitude : number;

  channelId : number;
  channelTag : string;

  //cart model for storing cart data
  cartModel : CartModel

  //order type mode
  orderTypeFlag = OrderTypeFlag;
  sourceflag = OrderSourceFlag;

  scrollLeft : boolean = false;
  scrollRight : boolean = true;
  isOverflow : boolean;

  tableNo : string;
  isTableReq : ChannelPlatformSetResponse;
  tableLabel : string;
  isChanged : boolean = false;
  statusFlag = CurrentStatusFlag;

  displayQrCodeEnlarge : boolean = false;
  currentStaticUrl : string;

  channelData : Channel;

  staticQrSub$ : Subscription;
  channelSub$ : Subscription;
  qrScannerTrigger$ : Subscription;

  constructor(
    private userService : UserService,
    private channelQuery : ChannelQuery,
    private storeService : StoreService,
    private timeService : TimeService,
    private userQuery : UserQuery,
    private staticQrService : StaticQrService,
    private qrScannerService : QrScannerService,
  ) { }

  ngOnInit(): void {
    this.todayDate.setHours(0,0,0,0);

    this.user = this.userService.getCustomer();
    this.customerId = this.user?.customerId ? this.user.customerId : "null";

    this.channelSub$ = this.channelQuery.selectFirst().subscribe(channel =>{
      if(channel){
        this.channelId = channel.channelId ? channel.channelId : null;
        this.channelTag = channel.channelTag ? channel.channelTag : null;
        this.channelData = channel;
      }
    })

    this.userQuery.selectedAddress$.subscribe(address => {
      this.longitude = address ? address.longitude : 0;
      this.latitude = address ? address.latitude : 0;
    })

    this.staticQrSub$ = this.staticQrService.getStaticQrData().pipe(
      map(staticData => {
        if(staticData.tableNo && ((staticData?.storeId && staticData?.storeId == this.storeResponse.storeId) || !staticData?.storeId)){
          return staticData;
        }
        else{
          return null;
        }
      })
    ).subscribe(staticQrData => {
      if(staticQrData){
        this.tableNo = staticQrData.tableNo ? staticQrData.tableNo : null;
        if(this.tableNo){
          this.generateStaticUrl(this.tableNo);
        }
      }
    })

    this.qrScannerTrigger$ = this.staticQrService.qrScannerTrigger$.subscribe(val => {
      if(val){
        this.isChanged = true;
        this.checkoutOfChooseTime();
      }
      else{
        this.isChanged = false;
      }
    })

    // find table required settings from store first
    // if cannot find then search for channel's platform set instead
    this.isTableReq = this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.DIREQTBLNO);

    if(!this.isTableReq){
      this.channelData.data.platformSets.find(setting => setting.setCode == SetCode.DIREQTBLNO);
    }

    let tableLabelSetting : any;
    if(this.orderH && this.orderH.platformSets){
      tableLabelSetting = this.orderH.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    }
    else if(this.storeResponse){
      tableLabelSetting = this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    }
    else{
      tableLabelSetting = null;
    }

    this.tableLabel = tableLabelSetting && tableLabelSetting.setValue ? tableLabelSetting.setValue : null;
  }

  ngAfterViewInit(){
    this.isOverflown();
  }

  ngOnDestroy(): void {
    this.staticQrSub$?.unsubscribe();
    this.channelSub$?.unsubscribe();
    this.staticQrSub$?.unsubscribe();
    this.qrScannerTrigger$?.unsubscribe();
  }

  //on click date
  changeDate(day){
    let curTimeSlot = this.availableTimeSlot.find(val => (val.date == day.date) && (val.orderType == day.orderType));
    this.previousDate = this.todayTime;
    this.todayTime = _.cloneDeep(curTimeSlot);
    this.todayTime.chosenTime = curTimeSlot.timeInterval[0];
  }

  //on click the time slot
  changeTime(time : TimeInterval){

    //this map is run to remove previous time changes made to the available time slot variable
    this.availableTimeSlot.map(val => {
      if(val.chosenTime != null){
        val.chosenTime = null;
      }

      if(val.isAsap != false){
        val.isAsap = false;
      }
    })

    //check if the time label is ASAP, if it is then do ASAP logic
    //else just do normal time logic
    if(time.label == "ASAP"){
      this.availableTimeSlot.map(val => {
        if((val.date == this.todayTime.date) && (val.orderType == this.todayTime.orderType)){
          val.chosenTime = time;
          val.isAsap = true;
        }
      })

      this.todayTime.isAsap = true;
      this.todayTime.chosenTime = _.cloneDeep(time);
    }
    else{
      this.availableTimeSlot.map(val => {
        if((val.date == this.todayTime.date) && (val.orderType == this.todayTime.orderType)){
          val.chosenTime = time;
        }
      })

      this.todayTime.isAsap = false;
      this.todayTime.chosenTime = _.cloneDeep(time);
    }
  }

  async changeOrderType(orderType : string){
    if(orderType == OrderTypeFlag.Delivery && !this.storeResponse.deliveryFlag){
      return;
    }
    else if(orderType == OrderTypeFlag.Pickup && !this.storeResponse.pickupFlag){
      return;
    }

    this.currentId = orderType; //change order type to delivery/pickup for UI

    await this.resetAvailableTimeSlot(); //call this method to reset the whole available time so that previous chosen time is cleared

    if(orderType == OrderTypeFlag.DineIn){
      this.todayTime = {} as AvailableTime;
      let curDateFormatted = this.timeService.getAdjustedLocalTimeInUtc();
      curDateFormatted = this.timeService.convertToTimezoned(curDateFormatted);

      this.todayTime.date = curDateFormatted.format("YYYY-MM-DD");
      this.todayTime.timeInterval = [];
      this.todayTime.chosenTime = {} as TimeInterval;
      this.todayTime.chosenTime.label = "ASAP";
      this.todayTime.chosenTime.value = null;
      this.todayTime.isToday = true;
      this.todayTime.orderType = orderType;
    }
    else{
      this.currentOrderDateSlot = this.availableTimeSlot.filter(val => val.orderType == orderType); //get all the schedule slot for a specific order type

      if(this.currentOrderDateSlot.length > 0){
        this.todayTime = this.currentOrderDateSlot[0]; //assign the first one for current schedule/today time
        this.todayTime.chosenTime = this.currentOrderDateSlot[0].timeInterval[0]; //set currecnt chosen time with the first time interval from the first time slot
      }
      else{
        this.todayTime = {} as AvailableTime;
        this.todayTime.timeInterval = [];
        this.todayTime.orderType = orderType;
      }

      this.isOverflown();
    }
  }

  async checkoutOfChooseTime(){
    if(this.todayTime != null && (this.todayTime.timeInterval.length != 0 || this.todayTime.orderType == this.orderTypeFlag.DineIn)){
      if(!this.todayTime.chosenTime && this.todayTime.orderType != this.orderTypeFlag.DineIn){
        this.todayTime = this.previousDate;
      }

      this.currentId = this.todayTime.orderType;

      if(!this.isCartPage){
        this.storeService.timeChangeChecking$.next(this.todayTime);
      }
      else{
        this.checkoutCurrentOrderTime.emit({chosenSchedule: this.todayTime, toChangeValue: this.isChanged});
        this.isChanged = false;
      }
    }
  }

  closeDialog(){
    this.currentId = this.todayTime.orderType;
    this.onCloseDialog.emit({isReschedule: this.isReschedule, isOutOfCoverage: this.isOutOfCoverage});
  }

  async resetAvailableTimeSlot(){
    this.availableTimeSlot.map(val => {
      if(val.chosenTime != null){
        val.chosenTime = null;
      }

      if(val.isAsap != false){
        val.isAsap = false;
      }
    })
  }

  scroll(element: HTMLElement, direction: number) {
    element.children.item(0).scrollLeft += 100 * direction;

    if(this.canScrollStart(element) && this.canScrollEnd(element)){
      this.scrollLeft = true;
      this.scrollRight = true;
    }
    else if(!this.canScrollStart(element) && this.canScrollEnd(element)){
      this.scrollLeft = false;
      this.scrollRight = true;
    }
    else if(this.canScrollStart(element) && !this.canScrollEnd(element)){
      this.scrollLeft = true;
      this.scrollRight = false;
    }
    else{
      this.scrollLeft = false;
      this.scrollRight = false;
    }
  }

  isOverflown() {
    setTimeout(() => {
      let element = document.getElementById("list");

      if(element){
        if(element.scrollWidth > element.clientWidth){
          this.isOverflow = true;
          this.scrollLeft = false;
          this.scrollRight = true;
        }
        else{
          this.isOverflow = false;
          this.scrollLeft = false;
          this.scrollRight = false;
        }
      }
    })

  }

  canScrollStart(element: HTMLElement) {
    return element.children.item(0).scrollLeft > 0;
  }

  canScrollEnd(element: HTMLElement) {
    return Math.round(element.children.item(0).scrollLeft + element.children.item(0).clientWidth) + 2 < element.children.item(0).scrollWidth;
  }

  onClickScanQr(){
    this.qrScannerService.show(false);
  }

  onClickQrCode(){
    this.displayQrCodeEnlarge = true;
  }

  async generateStaticUrl(tableNo : string){
    let currentRouteUrl = new URL(window.location.href);

    let baseUrl = currentRouteUrl.origin + '/' + this.channelTag + '/store/' + this.storeResponse.storeId + '/';
    let formattedLocDesc = await this.storeService.replaceWhiteSpaceWithDash(this.storeResponse.locDesc);
    baseUrl = baseUrl + formattedLocDesc;
    this.currentStaticUrl = baseUrl + '?orderType=DineIn&tableNo=' + tableNo;
  }
}
