import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonParse'
})
export class JsonParsePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]) {
    try {
      JSON.parse(value);
      return value ? JSON.parse(value): '';
    } catch(err) {
      return '';
    }
  }

}
