import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { QueueCart } from './queue-cart.model';
import { QueueCartState, QueueCartStore } from './queue-cart.store';

@Injectable({
  providedIn: 'root'
})
export class QueueCartQuery extends QueryEntity<QueueCartState, QueueCart> {

  constructor(protected queueCartStore: QueueCartStore) {
    super(queueCartStore);
  }

}
