<div class="myOrder-container" #container>
  <div class="p-text-center hidden-lg title" [ngStyle]="{'padding-bottom': cacheGuestOrder && !isLoggedIn ? '16px' : '0px'}">
    {{'my.order.order' | translate }}
  </div>
  <div class="myOrder-menu-container" [ngClass]="{ 'sticky': isSticky }" *ngIf="isLoggedIn" #menuContainer>
    <div class="myOrder-menu p-d-flex p-jc-center">
      <div class="p-text-center" [ngClass]="{'active': isActive }">
        <span (click)="onSwitchActivity(true)">{{'my.order.active' | translate }}<mark>{{'my.order.active' | translate }}</mark></span>
      </div>
      <div class="p-text-center" [ngClass]="{'active': !isActive }">
        <span (click)="onSwitchActivity(false)">{{'my.order.history' | translate }}<mark>{{'my.order.history' | translate }}</mark></span>
      </div>
    </div>
  </div>
  <div class="myOrder-content p-d-flex p-flex-wrap p-ml-auto p-mr-auto" *ngIf="activities" #content
    [ngStyle]="{'padding-top': cacheGuestOrder && !isLoggedIn && !isMobile ? '24px' : '9px'}">
    <div class="myOrder-cards" *ngFor="let activity of activities">
      <app-order-form [order]="activity" (onShowRate)="onShowRate($event);" (onReload)="onReload()"></app-order-form>
    </div>
    <ng-template [ngIf]="isOnLoad">
      <div class="myOrder-loading">
        <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
      </div>
    </ng-template>
  </div>
  <ng-template [ngIf]="activities === null && isLoggedIn">
    <div class="myOrder-not-found">
      <app-empty-order-alert [isActive]="isActive" [channelId]="channelId"></app-empty-order-alert>
    </div>
  </ng-template>
  <ng-template [ngIf]="!isLoggedIn && (activities == null || activities.length == 0)">
    <div class="myOrder-no-login">
      <img class="mascot" src="assets/mascot/Mascot_avatar-yellow-bg@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
      <p>{{ 'order.info.alert.description' | translate }}</p>
      <a routerLink="/login" class="btn-login" >{{ 'button.login' | translate }}</a>
    </div>
  </ng-template>
  <p-dialog [(visible)]="showRate" [modal]=true [dismissableMask]=true [draggable]=false (onHide)="onHideRate();" *ngIf="showRate" class="dialog dialog-rate">
    <app-rate-form (onShowRate)="onSuccessRate($event)" [reviewCategory]="reviewCategory" [order]="order"></app-rate-form>
  </p-dialog>
</div>

