<div class="email-success-container">
  <div class="content">
    <img src="assets/mascot/Mascot_phone-success@2x.webp" alt="odaring mascot"/>
    <h1 class="heading-11" style="text-align: center">
      {{'email.verification.link.send.title' | translate}}
    </h1>
    <h1 *ngIf="emailVerifyData" class="heading-13 description" style="text-align: center"
      [innerHTML]="'email.verification.link.send.content' | translate: { email: emailVerifyData.email, duration: emailVerifyData.expiredTimeTotalSeconds | formatTime : 'days' }">
    </h1>
  </div>
</div>
<app-bottom-odaring-back (onClickNavigation)="navigateBackToHome()"></app-bottom-odaring-back>
