import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';

import { UserQuery } from 'src/app/core/user/user.query';
import { VoucherHistory } from 'src/app/core/models/VoucherHistory';
import { VoucherStatusFlag } from 'src/app/core/enums/StatusFlag';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { RewardService } from 'src/app/account/services/reward.service';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ChannelQuery } from 'src/app/home/channel/channel.query';


@Component({
  selector: 'app-reward-history',
  templateUrl: './reward-history.component.html',
  styleUrls: ['./reward-history.component.scss']
})
export class RewardHistoryComponent implements OnInit, OnDestroy {
  @ViewChild("menuContainer") headerEl: ElementRef;
  @ViewChild("swiper", { static: false }) swiper?: SwiperComponent;
  @ViewChild("searchBar") searchBarEl: ElementRef;

  userLoggedIn$: Subscription;

  isLoggedIn: boolean = false;
  isMobile: boolean = false;
  isHeaderSticky: boolean = false;
  isOnLoad: boolean = false;
  isOnListLoad: boolean = false;
  isMaxLoad: boolean = false;
  showMobileMenu: boolean = false;

  histories: VoucherHistory[] = [];
  searchText: string = '';
  searchedText: string = '';
  skip: number = 0;
  take: number = 10;

  voucherStatus: string = '';
  cardType: VoucherCardType;

  cardTypes: any = VoucherCardType;
  voucherStatusFlag: any = VoucherStatusFlag;

  channelId : number;

  searchFgroup: UntypedFormGroup = this.fb.group({
    searchText: ['']
  });

  constructor(private userQuery: UserQuery,
    private router: Router,
    private rewardService: RewardService,
    private location: Location,
    private fb: UntypedFormBuilder,
    private channelQuery : ChannelQuery,
  ) { }

  get searchFg() { return this.searchFgroup }

  get searchF() { return this.searchFgroup.controls; }

  async ngOnInit() {
    this.getScreenSize();

    this.userLoggedIn$ = this.userQuery.isLoggedIn$.subscribe(userData => {
      this.isLoggedIn = userData? true: true;

      if(!this.isLoggedIn && !this.isMobile) {
        this.router.navigate(['/login']);
      }
    });

    if(this.isLoggedIn) {
      this.isOnLoad = true;
      await this.getHistory(this.skip, this.searchText, this.voucherStatus);
      this.isOnLoad = false;
    };

    this.channelQuery.selectFirst().subscribe(channel => {
      if(channel){
        this.channelId = channel.channelId;
      }
    });
  }

  async getHistory(skip: number, searchText?: string, voucherStatus?: string) {
    let histories: VoucherHistory[] = [];

    histories = <VoucherHistory[]>await this.rewardService.getCustomerVoucherHistory(skip, this.take, searchText, voucherStatus? voucherStatus: '');
    histories.forEach((history: VoucherHistory) => this.histories.push(history));

    this.skip = skip + histories.length;
    this.isMaxLoad = histories && histories.length === this.take? false: true;
  }

  async onMenuChange(voucherStatus?: VoucherStatusFlag) {
    this.voucherStatus = voucherStatus? voucherStatus: '';
    this.searchText = '';
    this.searchedText = '';
    this.searchFg.get('searchText').reset();
    this.skip = 0;
    this.histories = [];
    this.onSwipe();

    this.searchBarEl.nativeElement.focus();

    this.isOnLoad = true;
    await this.getHistory(this.skip, this.searchText, this.voucherStatus);
    this.isOnLoad = false;

    this.showMobileMenu = false;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  async onSearch(clear?: boolean) {
    this.searchText = this.searchedText = clear? "": this.searchFgroup.value.searchText;

    if(clear) {
      this.searchFg.get('searchText').reset();
    }

    this.skip = 0;
    this.histories = [];
    this.searchBarEl.nativeElement.focus();

    this.isOnLoad = true;
    await this.getHistory(0, this.searchText, this.voucherStatus);
    this.isOnLoad = false;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  onSwipe(){
    // Menu swiper scroll
    let menuSwiper = document.getElementById('menu-' + this.voucherStatus? this.voucherStatus: '0');

    if (menuSwiper && this.swiper) {
      let menuSwiperIndex = +menuSwiper.parentElement.getAttribute("data-swiper-slide-index");
      this.swiper.swiperRef.slideTo(menuSwiperIndex, 700);
    }

    if(!menuSwiper && this.swiper) {
      this.swiper.swiperRef.slideTo(0, 700);
    }
  }

  onBack() {
    this.location.back();
  }

  checkCardType(statusFlag: string) {
    let cardType: VoucherCardType;

    switch(statusFlag) {
      case this.voucherStatusFlag.Used:
        cardType = this.cardTypes.used;
        break;
      case this.voucherStatusFlag.Expired:
        cardType = this.cardTypes.expired;
        break;
      default:
        cardType = this.cardTypes.none;
    }

    return cardType;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  @HostListener('window:scroll', ['$event'])
  async onWindowScroll() {
    // Header sticky
    if(this.headerEl) {
      let headerPosition = this.headerEl.nativeElement.getBoundingClientRect().top;
      this.isHeaderSticky = headerPosition < 0 ? true : false;
    }

    // Pagination
    if(!this.isOnListLoad && !this.isMaxLoad && this.histories.length > 0) {
      if(Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight)) {
        this.isOnListLoad = true;
        await this.getHistory(this.skip, this.searchText, this.voucherStatus);
        this.isOnListLoad = false;
      }
    }
  }

  ngOnDestroy() {
    this.userLoggedIn$.unsubscribe();
  }
}
