<div class="joinTermData-container">
  <div class="content">
    <div [innerHTML]="termsData | safeHtml"></div>
  </div>

  <div class="joinTermData-footer" *ngIf="isJoin">
    <div class="check-box-content">
      <label class="checkbox-container" for="anonymous">
        <input id="anonymous" type="checkbox" [value]="isAgree" (change)="onAgree($event)" [checked]="isAgree" />
        <span class="checkmark"></span>
        <span>{{'delete.acc.agree.tnc.desc' | translate}}</span>
      </label>
    </div>
    <button type="button" [disabled]="!isAgree" (click)="onJoin();">{{ 'membership.button.join.now' | translate }}</button>
  </div>

  <div class="joinTermData-footer" *ngIf="isQuit && externalMemberLinkMode">
    <button type="button" [ngClass]="{'isQuitButton' : isQuit}" (click)="onContinue()">{{ 'button.quit.membership.desc' | translate }}</button>
  </div>

  <div class="joinTermData-footer" *ngIf="isQuit && !externalMemberLinkMode">
    <button type="button" [ngClass]="{'isQuitButton' : isQuit}" (click)="onContinue()">{{ 'unlink.membership' | translate }}</button>
  </div>
</div>
