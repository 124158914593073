<!--desktop view-->
<div class="app-language-desktop" *ngIf="!isMobileLayout && !isStoreLang">
  <div class="btn-group dropdown-outline" dropdown>
    <button id="button-autoclose1" dropdownToggle type="button" class="btn-userDetail"
            aria-controls="dropdown-autoclose1">
            <i class="oda-language"></i>
            {{ _currentLang | languageDesc : true }}
            <i class="oda-chevron-down"></i>
    </button>


    <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu"
        aria-labelledby="button-autoclose1">
      <li role="menuitem">
        <label class="lang-text" (click)="changeLang('en')">English</label>
      </li>
      <li role="menuitem">
        <label class="lang-text" (click)="changeLang('bm')">Bahasa Melayu</label>
      </li>
      <li role="menuitem">
        <label class="lang-text" (click)="changeLang('cn')">中文</label>
      </li>
    </ul>
  </div>
</div>

<!--mobile view-->
<div class="app-language-mobile" *ngIf="isMobileLayout && !isStoreLang" (click)="showLangDialog()">
  <h1 class="language-heading-1">
    <i class="oda-language"></i>
    {{'navigation.Menu.des.20' | translate}}
  </h1>

  <div class="p-d-flex">
    <h1 class="heading-2">{{ _currentLang | languageDesc : true }}</h1>

    <i class="oda-chevron-right-circle"></i>
  </div>
</div>

<div class="mobile-store-langSelection" *ngIf="isStoreLang">
  <h1 class="language-title">
    <span><i class="oda-language"></i></span>
    {{'navigation.Menu.des.20' | translate}}
  </h1>

  <div class="language-content">
    <span class="lang-desc" [ngClass]="{'current-lang-desc' : _currentLang == 'en'}" (click)="changeLang('en')">English</span>
    <hr class="lang-divider"/>

    <span class="lang-desc" [ngClass]="{'current-lang-desc' : _currentLang == 'cn'}" (click)="changeLang('cn')">中文</span>
    <hr class="lang-divider"/>

    <span class="lang-desc" [ngClass]="{'current-lang-desc' : _currentLang == 'bm'}" (click)="changeLang('bm')">Bahasa Melayu</span>
    <hr class="lang-divider"/>
  </div>
</div>

<!--pop out dialog for mobile: language selection-->
<div class="hidden-lg mobile-langSelection-dialog">
  <p-dialog [(visible)]="displayLanguage">

    <div class="lang-header">
      <i class="oda-chevron-left" (click)="this.displayLanguage = false;"></i>
      <h1 class="heading-14">{{'navigation.Menu.des.20' | translate}}</h1>
    </div>

    <div class="mascot-image">
      <img src="assets/mascot/Mascot_speak-languages@2x.webp" alt="odaring mascot"/>
    </div>

    <h2 class="heading-15">{{ 'language.title' | translate }}</h2>

    <div class="lang-content">
      <div class="lang-cirlce-en" [ngClass]="{ 'circle-color': _currentLang == 'en'}">
        <div class="en-content" (click)="changeLang('en')">
          <h1 class="heading-2" [ngClass]="{ 'font-color': _currentLang == 'en'}">Hello</h1>
          <p class="heading-13" [ngClass]="{ 'font-color': _currentLang == 'en'}">English</p>
        </div>
      </div>

      <div class="lang-cirlce-cn">
        <div class="cn-content" [ngClass]="{ 'circle-color': _currentLang == 'cn'}" (click)="changeLang('cn')">
          <div class="sub-content">
            <h1 class="heading-2" [ngClass]="{ 'font-color': _currentLang == 'cn'}">您好</h1>
            <p class="heading-13" [ngClass]="{ 'font-color': _currentLang == 'cn'}">中文</p>
          </div>
        </div>
      </div>

      <div class="lang-cirlce-bm">
        <div class="bm-content" [ngClass]="{ 'circle-color': _currentLang == 'bm'}" (click)="changeLang('bm')">
          <div class="sub-content">
            <h1 class="heading-2" [ngClass]="{ 'font-color': _currentLang == 'bm'}">Apa Khabar</h1>
            <p class="heading-13" [ngClass]="{ 'font-color': _currentLang == 'bm'}">Bahasa Melayu</p>
          </div>
        </div>
      </div>

    </div>
  </p-dialog>
</div>
