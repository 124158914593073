import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'src/app/core/models/MenuItem';
import * as _ from 'lodash';
import { MenuItemStatus } from 'src/app/core/enums/MenuItemStatus';

@Component({
  selector: 'app-store-item-description',
  templateUrl: './store-item-description.component.html',
  styleUrls: ['./store-item-description.component.scss']
})
export class StoreItemDescriptionComponent implements OnInit {

  @Input() priceSymbol : string; //must not change input value
  @Input() selectedMenuItem : MenuItem;

  itemStatus = MenuItemStatus;

  constructor(
  ) { }

  ngOnInit(): void {

  }

}
