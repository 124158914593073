import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { UserService } from 'src/app/core/user/user.service';
import { OrderService } from 'src/app/home/order/order.service';
import { StoreMode } from 'src/app/core/enums';
import { NgxImageCompressService } from 'ngx-image-compress';
import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { DetailActivity } from 'src/app/home/order/order.model';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
  selector: 'app-return-refund-email',
  templateUrl: './return-refund-email.component.html',
  styleUrls: ['./return-refund-email.component.scss']
})
export class ReturnRefundEmailComponent implements OnInit {
  @ViewChild('email') emailElement: ElementRef;
  @ViewChild('message') messageElement: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  orderTranId: number;
  email: string;
  mobileNo: string;
  name: string;
  refundForm: UntypedFormGroup;
  images: string[] = [];
  imagesFile: File[] = [];
  compressedImages: string[] = [];
  maxImage: number = 3;
  isMaxSizeError: boolean = false;
  curTotalImage: number = 0;

  constructor( private formBuilder: UntypedFormBuilder,
    private orderService: OrderService,
    private userService: UserService,
    private imageCompress: NgxImageCompressService,
    private loaderService: LoaderService,
    private popupMessageService: PopupMessageService,
    private router: Router,
    private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.orderTranId = history.state.orderTranId || null;

    this.userService.get(StoreMode.Internal).subscribe( (data: any) => {
      this.email = data['email'] ? data['email'] : null;
      this.mobileNo = data['mobileNo'] ? data['mobileNo'] : null;
      this.name = data['name'] ? data['name'] : null;
    });

    this.initForm();
  }

  ngAfterViewInit() {
    if(!this.email){
      this.emailElement.nativeElement.focus();
    } else {
      this.messageElement.nativeElement.focus();
    }
  }

  get f() {
    return this.refundForm.controls;
  }

  private initForm(){
    this.refundForm = this.formBuilder.group({
      email: [this.email, Validators.required],
      message: ['', Validators.required],
      image: [''],
    });
  }

  private imageCompression(file: File) {
    const reader = new FileReader();

    return new Promise<File>((resolve, reject) => {
      reader.onload = (event: any) => {
        let imageDataUrl = event.target.result;
        const dimensionLimit = 500;
        const orientation = 1;
        let ratio = 100;
        let quality = 100;

        let self = this;
        let imageObj = new Image();
        imageObj.src = imageDataUrl;

        imageObj.onload = function () {
          let imageWidth = imageObj.width;
          let imageHeight = imageObj.height;

          if(imageWidth > imageHeight) { // Horizontal image
            ratio = ( dimensionLimit / imageWidth ) * 100;
          } else { // Vertical image
            ratio = ( dimensionLimit / imageHeight ) * 100;
          }

          if (ratio > 100) {
            ratio = 100;
          }

          self.imageCompress.compressFile(imageDataUrl, orientation, ratio, quality).then(
            (result: any) => {
              const compressedImageFile = self.dataURLtoFile(result, file.name);
              if(compressedImageFile.size > file.size) {
                resolve(file);
              }else {
                resolve(compressedImageFile);
              }
            }
          );
        };
      }
      reader.readAsDataURL(file);
    });
  }

  private dataURLtoFile(dataurl: string, filename: string) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  onImageChange(event: any) {
    let files = event.target.files;
    let maxSize = 5000000; //5mb

    this.isMaxSizeError = false;

    if (files && files[0]) {
      //extra image selected won't upload
      for (var i = 0; (i < files.length) && (this.curTotalImage < this.maxImage); i++) {
        let imageSize = files[i].size;

        if (imageSize <= maxSize) {
          var reader = new FileReader();

          reader.onload = (event: any) => {
            this.images.push(event.target.result);
          }

          reader.readAsDataURL(files[i]);
          this.imagesFile.push(files[i]);
          this.curTotalImage++;
        } else {
          this.isMaxSizeError = true;
        }

        // Show error only when number of image less than 3
        if(this.curTotalImage >= this.maxImage) {
          this.isMaxSizeError = false;
        }
      }
    }

    //clear to allow upload same image
    event.target.value = null;
  }

  onRemoveImage(index: number) {
    this.images.splice(index, 1);
    this.imagesFile.splice(index, 1);
    this.curTotalImage = this.imagesFile.length;
    this.isMaxSizeError = false;
  }

  async onSubmit() {
    let formData: any = new FormData();
    let response: any;
    const maxSizeToCompress: number = 500000; // 500kb

    this.loaderService.startLoading();

    //append all value as formData
    formData.append("Email", this.f.email.value);
    formData.append("Remark", this.f.message.value);

    for( var i = 0; i < this.images.length; i++ ) {
      //compress image file which larger than 500kb
      if(this.imagesFile[i].size > maxSizeToCompress ) {
        this.imagesFile[i] = await this.imageCompression(this.imagesFile[i]);
      }
      formData.append("FormFiles", this.imagesFile[i]);
    }

    formData.append("OrderTranId", this.orderTranId);

    response = await this.orderService.requestRefundOrder(formData);

    if (!(response instanceof(HttpErrorResponse))) {
      this.analyticsService.refundEvent(this.orderTranId, this.mobileNo, this.name);
      let order = <DetailActivity[]>await this.orderService.getActivityDetail(response, true);

      let refundData: any = {
        orderNo: order[0].orderNo,
        storeName: order[0].storeInfo.locDesc,
        tranDate: order[0].tranDate,
        logData: order[0].activityList[0].logData,
        currCode: order[0].currCode,
        netAmt: order[0].netAmt
      }

      this.router.navigate(['/refund-detail'], { state: { refundData: JSON.stringify(refundData), success: true } });
    } else {
      this.popupMessageService.show({
        icon: "oda-alert-alt",
        iconColor: "red",
        title: "alert.return.refund.title",
        desc: "alert.return.refund.des",
        btn: "button.retry"
      });
    }

    this.loaderService.stopLoading();
  }
}

