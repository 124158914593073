import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-delete-acc-tnc',
  templateUrl: './delete-acc-tnc.component.html',
  styleUrls: ['./delete-acc-tnc.component.scss']
})
export class DeleteAccTncComponent implements OnInit {
  @Output() onHide = new EventEmitter();
  @Output() onContinue = new EventEmitter();

  isMobile: boolean = false;
  isAgree: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  onHideDialog(){
    this.onHide.emit();
  }

  onAgree(event: any) {
    this.isAgree = event.target.checked? true: false;
  }

  onClickContinue() {
    this.onContinue.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let isMobile = window.innerWidth <= 991 ? true: false;

    if(isMobile !== this.isMobile) {
      this.onHide.emit();
    }
  }
}
