import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserChnlStore } from './userchnl.store';
import { StoreMode } from 'src/app/core/enums';
import { UserChnlQuery } from './userchnl.query';
import { CustomService } from 'src/app/core/services/custom.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomRequest } from 'src/app/core/models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelCustomerResponse } from 'src/app/core/models/ChannelCustomerResponse';
import * as _ from 'lodash';
import { ChannelCustomerStatusFlag } from 'src/app/core/enums/StatusFlag';
import { UserQuery } from 'src/app/core/user/user.query';
import { CommonAddressResponse } from 'src/app/core/models/CommonAddressResponse';

@Injectable({ providedIn: 'root' })
export class UserChnlService {
  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;

  curUserChnl: any = null;
  selectedAddress: CommonAddressResponse;

  constructor(
    private channelStore: UserChnlStore,
    private http: HttpClient,
    private customService: CustomService,
    private utilsService: UtilsService,
    private channelQuery: UserChnlQuery,
    private userService: UserService,
    private userQuery: UserQuery,
  ) {

    this.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUserChnl = dt ? dt : null;
    });

    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.accessToken = dt && dt['accessToken'] ? dt['accessToken'] : '';
      this.refreshToken = dt && dt['refreshToken'] ? dt['accessToken'] : '';
      this.curUser = dt ? dt : null;
    });

    this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });
  }

  get(storeMode: StoreMode) {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.channelStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.channelStore.setLoading(false);
    }
    return this.channelQuery.selectAll();
  }

  remove(id: ID) {
    this.channelStore.remove(id);
  }

  clear() {
    if (!this.curUserChnl) {
      return;
    }
    this.remove(this.curUserChnl.id);
  }

  error(err: any) {
    this.channelStore.setError(err);
  }

  destroy() {
    this.channelStore.destroy();
  }

  // Using
  addUserChnl(data: ChannelCustomerResponse[]) {
    if (this.curUserChnl) {
      this.clear();
    }

    if (!data) {
      return;
    }

    let respData = _.cloneDeep(data);
    let pendingData = respData.filter(data => data.status === ChannelCustomerStatusFlag.PendingApproval);

    this.channelStore.add([
      {
        id: 1,
        status: ChannelCustomerStatusFlag.Active,
        data: respData.filter(data => data.status === ChannelCustomerStatusFlag.Active),
      }
    ]);

    if (pendingData && pendingData.length > 0) {
      this.channelStore.add([
        {
          id: 2,
          status: ChannelCustomerStatusFlag.PendingApproval,
          data: pendingData,
        }
      ]);
    }
  }

  private setActive(tizId: number) {
    this.channelStore.setActive(tizId);
  }

  // Using
  async getCustomerChannel() {
    let respDt: ChannelCustomerResponse[] = [];
    respDt = await this.reqCustomerChannel(this.accessToken);

    if (!(respDt instanceof HttpErrorResponse)) {
      this.addUserChnl(respDt);
    }

    return respDt;
  }

  async switchChannel(channelTag: string) {
    let respDt = null;
    respDt = await this.reqSwitchChannel(this.accessToken, channelTag);
    return respDt;
  }

  async deleteCustomerChannel(channelTag: string) {
    let respDt = null;
    respDt = await this.reqDeleteCustomerChannel(this.accessToken, channelTag);
    return respDt;
  }

  private async reqCustomerChannel(accessToken: string): Promise<ChannelCustomerResponse[]> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqSwitchChannel(accessToken: string, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.channel.SwitchChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private async reqDeleteCustomerChannel(accessToken: string, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channel.DeleteCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelStore.setLoading(false);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => {
      if (dd && dd['body']) {
        return dd['body'];
      } else if (dd && dd.status === 401) { //logout user
        this.userService.clearUser();
        return null;
      }
    });
  }

  private formatSrvDateStr(date: Date) {
    const utilSv = this.utilsService;
    return utilSv.formatSrvDateStr(date);
  }

  updateDomainFlag(isSubDomain: boolean) {
    this.channelStore.update({
      isSubDomain: isSubDomain
    })
  }

  async checkUpdateDomainFlag() {
    const hostname = window.location.hostname;
    let firstHostName = hostname.split(".")[0];

    if (firstHostName === 'www') {
      firstHostName = hostname.split(".")[1];
    }

    if (environment.hostName == "localhost") {
      firstHostName = "localhost";
    }

    if (firstHostName === environment.hostName) {
      this.updateDomainFlag(false);
    } else {
      this.updateDomainFlag(true);
    }
  }
}
