import { SetCode } from 'src/app/core/enums/SetCode';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivityType } from 'src/app/core/enums/ActivityType';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import * as moment from 'moment';
import { DetailActivity } from 'src/app/home/order/order.model';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { QueueResponse } from 'src/app/core/models/QueueResponse';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { OrderH } from 'src/app/core/models/OrderH';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss']
})
export class OrderInfoComponent implements OnInit, OnChanges {
  @Input() detailActivity?: DetailActivity;
  @Input() qrTokenResponse?: WebLinkTokenResponse;
  @Input() queueResponse?: QueueResponse;
  @Input() orderH?: OrderH;
  @Input() storeResponse?: StoreResponse;

  sourceFlag: OrderSourceFlag | string;
  lastActivityType: ActivityType;
  estimatedTime: string | Date;
  reqTime: string | Date;
  todayDate: Date;
  dateTime: string | Date;
  isToday: boolean;
  tableNo: string;
  queueNo: string;
  guestCount: number;

  orderSourceFlag: any = OrderSourceFlag;
  orderTypeFlag: any = OrderTypeFlag;
  activityTypes: any = ActivityType;
  tableLabel : string;

  constructor() { }

  ngOnInit(): void {
    this.setData();
    this.getTableLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setData();
  }

  setData() {
    // Set Data
    if(this.queueResponse) {
      this.queueNo = this.queueResponse.queueNo;
      this.guestCount = this.queueResponse.guestCount;
    } else if(this.qrTokenResponse) {
      this.tableNo = this.qrTokenResponse.tableNo;
      this.guestCount = this.qrTokenResponse.guestCount;
    } else if(this.orderH) {
      this.sourceFlag = this.orderH.orderData?.sourceFlag;
      this.estimatedTime = this.orderH.orderData?.orderC.estimatedTime;
      this.reqTime = this.orderH.orderData?.orderC?.reqTime;
      this.tableNo = this.orderH.orderData?.tableNo;
      this.guestCount = this.orderH.orderData?.guestCount;
    } else if(this.detailActivity) {
      this.sourceFlag = this.detailActivity.sourceFlag;
      this.lastActivityType = this.detailActivity.lastActivityType;
      this.estimatedTime = this.detailActivity.estimatedTime;
      this.reqTime = this.detailActivity.reqTime;
      this.tableNo = this.detailActivity.tableNo;
      this.queueNo = this.detailActivity.queueNo;
      this.guestCount = this.detailActivity.orderData.guestCount;
    }

    this.todayDate = new Date();

    if((this.estimatedTime && this.detailActivity) || (this.reqTime && !this.detailActivity)) {
      let date = this.detailActivity? this.estimatedTime: this.reqTime;
      let dateTime = new Date(date);

      this.isToday = moment(this.todayDate).isSame(dateTime, 'day');
    } else {
      this.isToday = true;
    }
  }

  timeFormatCorrection(curTime : string | Date){
    let orderIntervalTime = this.storeResponse?.platformSets.filter(val => val.setCode == "DELINTERVALMIN" || val.setCode == "PICKINTERVALMIN");
    let correctTimeFormat = moment(curTime);

    let interval : number; //for storing time interval e.g. 30mins

    if(orderIntervalTime?.length != 0){
      if(this.orderH.orderData.sourceFlag == OrderSourceFlag.WebDelivery){
        interval = orderIntervalTime?.find(val => val.setCode == "DELINTERVALMIN") ? +orderIntervalTime.find(val => val.setCode == "DELINTERVALMIN").setValue : 30;
      }
      else if(this.orderH.orderData.sourceFlag == OrderSourceFlag.WebPickup){
        interval = orderIntervalTime?.find(val => val.setCode == "PICKINTERVALMIN") ? +orderIntervalTime.find(val => val.setCode == "PICKINTERVALMIN").setValue : 30;
      }

      return correctTimeFormat.format("HH:mm") + " - " + correctTimeFormat.add(interval, 'minutes').format("HH:mm");
    }
    else{
      return correctTimeFormat.format("HH:mm");
    }
  }

  getTableLabel(){
    let tableLabelSetting : any;
    if(this.orderH){
      tableLabelSetting = this.orderH.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    } else if(this.detailActivity){
      tableLabelSetting = this.detailActivity.platformSets.find(setting => setting.setCode == SetCode.DITBLNOLBL);
    } else{
      tableLabelSetting = null;
    }

    this.tableLabel = tableLabelSetting ? tableLabelSetting.setValue : null;
  }
}
