export enum MenuTags{
  ChefChoice = '1',
  MostPopular = '2',
  HotSelling = '3',

  // nutri-grade code
  NG01A= 'NG01A',
  NG01B= 'NG01B',
  NG01C= 'NG01C',
  NG01D= 'NG01D',
  NG01AS= 'NG01AS',
  NG01BS= 'NG01BS',
  NG01CS= 'NG01CS',
  NG01DS= 'NG01DS',
}
