import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringSplit'
})
export class StringSplitPipe implements PipeTransform {

  transform(value: string, splitBy: string): string[] {
    if(!value){
      return null;
    }

    let valueAfterSplit = value.split(splitBy);
    return valueAfterSplit;
  }

}
