<div class="invalid-qr-container">
  <div class="hidden-lg invalidQR-mobileHeader">
    {{'qr.code.qr.expired.title' | translate}}
  </div>
  <div class="invalid-qr-content">
    <img [src]="storeImageUrl" alt="odaring image" loading="lazy">
    <h1 class="storeName">{{locDesc}}</h1>
    <h1 class="invalid-desc">{{'qr.code.qr.expired.desc' | translate}}</h1>
    <h1 class="home-btn" (click)="onClickBackHome()">{{'button.back.home' | translate}}</h1>
  </div>
</div>
