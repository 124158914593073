import { OrderTypeFlag } from './../../../core/enums/OrderTypeFlag';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ChannelSec } from 'src/app/home/channelsec/channelsec.model';
import { StoreCatgAttrs } from 'src/app/core/models';
import { Router } from '@angular/router';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { StoreService } from 'src/app/store/store/store.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import * as _ from 'lodash';
import { CartModel } from 'src/app/core/models/CartModel';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { OrderH } from 'src/app/core/models/OrderH';

@Component({
  selector: 'app-item-card-v',
  templateUrl: './item-card-v.component.html',
  styleUrls: ['./item-card-v.component.scss']
})
export class ItemCardVComponent {
  @Input() channelSec: ChannelSec | null = null;
  @Input() stores: StoreResponse[];
  @Input() isOnLoad: boolean = false;
  @Input() isContLoadData: boolean = false;
  @Input() currentCart : CartModel;
  @Output() onLoad = new EventEmitter<boolean>();
  @Output() contLoadData = new EventEmitter<boolean>();

  // products: any[] = null;
  sortOrder!: number;
  sortField!: string;
  showStoreNearBy: boolean = true;
  isMobile: boolean = false;

  posY: number;
  recordPerLoad: number = 10;

  constructor(
    private primengConfig: PrimeNGConfig,
    private router : Router,
    private storeService : StoreService,
    private channelService: ChannelService,
    private staticQrService : StaticQrService
    ) { }

  ngOnInit() {
    this.showStoreNearBy = this.channelSec ? true : false;
    this.primengConfig.ripple = true;
  }

  getStoreAttrDesc(storeCatgAttrs?: StoreCatgAttrs[]): string {
    return _.chain(storeCatgAttrs).map((attr) => {
      return attr.attrDesc;
    }).join(', ')
    .value();
  }

  async onClickStore(storeData : StoreResponse){
    this.storeService.removeIsDisplayedFlag();
    let globalOrderType = this.staticQrService.getChannelOrderType();
    let storeCart : OrderH = null;
    let orderType : string;

    if((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.currentCart && this.currentCart?.orderHs && this.currentCart.orderHs?.length > 0){
      storeCart = this.currentCart.orderHs.find(orderH => orderH.storeId == storeData.storeId);
    }

    if(storeCart){
      orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
    }
    else{
      orderType = storeData.currentOrderType;
    }
    this.storeService.preStoreNavigation(orderType, storeData);
  }

  @HostListener('window:scroll', ['$event'])
  async onWindowScroll() {
    if (!this.isOnLoad && this.channelSec && this.isContLoadData) {
      const reduce = this.isMobile ? 0: 380;
      if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - reduce)) {
        this.onLoad.emit(true);
        let channelSecResponse = await this.channelService.getHomeChannelSection(this.channelSec.data.sectionId, this.stores.length, this.recordPerLoad);
        await this.channelService.updateChannelData(channelSecResponse);

        if (channelSecResponse.data['data_1'] && channelSecResponse.data['data_1'].length >= this.recordPerLoad) {
          this.contLoadData.emit(true);
        } else {
          this.contLoadData.emit(false);
        }
        this.onLoad.emit(false);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  trackByFn(index, item) {
    return index;
  }

}
