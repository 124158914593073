import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrScannerService {

  showPopupScanner$: Subject<any> = new Subject();

  constructor() { }

  show(isGeneralScan: boolean, headerDesc: boolean = true) {
    this.showPopupScanner$.next({
      isGeneralScan: isGeneralScan,
      isShow: true,
      headerDesc: headerDesc
    });
  }

  hide() {
    this.showPopupScanner$.next({
      isShow: false,
      headerDesc: true
    });
  }
}
