import { Injectable } from '@angular/core';
import { ChangeData } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/core/services/error.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserService } from 'src/app/core/user/user.service';
import { ErrorObj } from 'src/app/core/models';
import { RoutingService } from './routing.service';
import { PageName } from 'src/app/core/enums';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ToastData } from 'src/app/core/models/ToastData';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  mobileNo: string = '';
  optFailCount = this.resetOtpFailCount();
  canSkip = false;
  mobileObj: ChangeData | null = null;
  pageName: PageName | null = null;
  data: any = null;
  otpCode: string = '';
  otpPrefix: string = '';
  sendOtpResp: any;

  constructor(
    private utilsService: UtilsService,
    private userService: UserService,
    private errorService: ErrorService,
    private routingService: RoutingService,
    private toastService : ToastService,
  ) { }

  async onSendOTP(mobileNo: any, pageName?: PageName, data?: any, otpPrefix: string = this.utilsService.createOTPPrefix(), emailRedirectLink?: string, isSendEmail?: boolean) {
    try {
      if (!mobileNo) {
        return ;
      }

      if (mobileNo) {
        this.mobileObj = mobileNo.e164Number? mobileNo: null;
        this.pageName = pageName? pageName: PageName.OTPPage;
      }

      this.triggerOtpFailCount();
      this.mobileNo = typeof(mobileNo) == 'string' ? mobileNo : this.formatPhoneNo(this.mobileObj);
      this.sendOtpResp = await this.userService.sendOTP(this.mobileNo, otpPrefix, emailRedirectLink, isSendEmail);

      if (this.sendOtpResp instanceof HttpErrorResponse) {
        let toastData = {} as ToastData;
        toastData.message = this.sendOtpResp.error['title'];
        toastData.backgroundColorCode = '#EB3600';
        toastData.hideCloseBtn = true;
        this.toastService.show(toastData);
      } else {
        this.otpPrefix = otpPrefix;
      }
    } catch (err) {
      this.setError(err);
    }
  }

  async onVerifyOTP(mobileNo: any, pageName: PageName, optCode: string, data?: any, otpPrefix: string = this.utilsService.createOTPPrefix()) {
    try {
      if (!(this.mobileObj && this.pageName)) {
        return;
      }
      this.mobileNo = this.mobileObj? this.formatPhoneNo(this.mobileObj): mobileNo;
      let verifiedOTP = null;
      // if (environment.production) {
      verifiedOTP = await this.userService.verifyOTP(this.mobileNo, optCode);

      if(!(verifiedOTP instanceof HttpErrorResponse)) {
        this.otpCode = verifiedOTP.otpCode;
      }
      return verifiedOTP;

    } catch (err) {
      this.setError(err);
    }
  }

  async onUpdateMobileNoStatus(mobileNo: any, otpCode: string) {
    try {
      let formattedMobileNo = typeof(mobileNo) == 'string' ? mobileNo : this.formatPhoneNo(mobileNo);
      return await this.userService.updateMobileNoStatus(formattedMobileNo, otpCode);
    } catch (err) {
      this.setError(err);
    }
  }

  triggerOtpFailCount() {
    this.optFailCount = this.optFailCount + 1;
    if (this.optFailCount >= environment.optMaxFailBypass) {
      this.canSkip = true;
    }
  }

  createOtpPrefix() {
    return this.utilsService.createOTPPrefix();
  }

  getOtpObj() {
    return {
      mobileNo: this.mobileNo,
      otpFailCount: this.optFailCount,
      canSkip: this.canSkip,
      mobileObj: this.mobileObj,
      data: this.data,
      pageName: this.pageName,
      otpCode: this.otpCode,
      otpPrefix: this.otpPrefix,
      otpSuccess: this.sendOtpResp? this.sendOtpResp.otpSuccess: ''
    };
  }

  setError(errorObj: ErrorObj) {
    this.errorService.insertErrorObj(errorObj);
    this.userService.error(errorObj);
  }

  navigateToHome(mobileNo: ChangeData, pageName: PageName) {
    if (pageName === PageName.SignupPage) {
      this.data.signupForm.isMobileVerified = false;
      this.navigateToPage(PageName.LoadingPage, { pageName: pageName });
    } else {
      this.navigateToPage(PageName.HomePage, { mobileNo: mobileNo, pageName: pageName });
    }

  }

  navigateToPageWithOtpCode(otpCode: string, pageName: PageName) {
    this.otpCode = otpCode;
    if (pageName === PageName.SignupPage) {
      this.data.signupForm.isMobileVerified = true;
      this.data.signupForm.otpCode = otpCode;
      this.navigateToPage(PageName.CompleteOtpProfile, { pageName: pageName });
    } else if (pageName === PageName.ProfilePage || pageName === PageName.ResetMobilePage) {
      this.navigateToPage(PageName.LoadingPage, { pageName: pageName });
    } else {
      this.navigateToPage(pageName, { });
    }
  }

  navigateToPage(pageName: PageName, data: any) {
    const rtSv = this.routingService;
    rtSv.navigate(pageName, data);
  }

  resetOtpObj() {
    this.mobileNo = '';
    this.resetOtpFailCount();
    this.mobileObj = null;
    this.pageName = null;
    this.data = null;
    this.otpCode = '';
    this.otpPrefix = '';
  }

  private resetOtpFailCount() {
    const initOtpFailCount = 0;
    this.optFailCount = initOtpFailCount;
    this.canSkip = false;
    return initOtpFailCount;
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    const utilsSv = this.utilsService;
    return phoneObj.dialCode?.replace('+', '') + utilsSv.formatPhoneNo(phoneObj);
  }
}
