<div class="addr-tooltip" [ngClass]="{'top-center': arrowPosition == tooltipArrowPos.TopCenter, 'top-left': arrowPosition == tooltipArrowPos.TopLeft,
  'top-right': arrowPosition == tooltipArrowPos.TopRight, 'bottom-left': arrowPosition == tooltipArrowPos.BottomLeft,
   'bottom-right': arrowPosition == tooltipArrowPos.BottomRight, 'bottom-center': arrowPosition == tooltipArrowPos.BottomCenter }" *ngIf="displayTooltip && message">

  <div class="image-container" *ngIf="mascot">
    <img [src]="mascot" alt="odaring mascot">
  </div>

  <span class="tooltip-msg">{{message | translate}}</span>

  <button class="tooltip-button" (click)="closeTooltip()" *ngIf="button">{{button | translate}}</button>
</div>
