import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { StoreMode } from 'src/app/core/enums';
import { UserQuery } from "src/app/core/user/user.query";

@Component({
  selector: 'app-user-qr-code',
  templateUrl: './user-qr-code.component.html',
  styleUrls: ['./user-qr-code.component.scss']
})
export class UserQrCodeComponent implements OnInit {
  @Input() hideCloseBtn: boolean = false;
  @Output() closeDialog = new EventEmitter();
  @Output() enlarging = new EventEmitter();

  displayLoggedIn: string = '';
  curUser: any;
  sub: Subscription = new Subscription();
  encryptedCustID: any;
  customerNo: any;

  displayQR: boolean = false;
  displaybarCode: boolean = false;
  isMobile: boolean = false;

  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
  ) {
    this.userQuery.isLoggedIn$.subscribe(userData => {
      if (userData && userData.id !== 999) {
        this.displayLoggedIn = userData.encryptedCustID;
      }
      else {
        this.displayLoggedIn = '';
      }
    });
  }

  ngOnInit(): void {
    this.sub = this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUser = dt ? dt : null;
    });

    this.encryptedCustID = this.curUser ? this.curUser.encryptedCustID : null;
    this.customerNo = this.curUser ? this.curUser.customerNo : null;


    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  showQRDialog() {
    if (this.isMobile) {
      this.displayQR = true;
      this.enlarging.emit(true);
    }
  }

  showBarCodeDialog() {
    if (this.isMobile) {
      this.displaybarCode = true;
      this.enlarging.emit(true);
    }
  }

  closeQrDialog() {
    this.closeDialog.emit();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }
}
