<div class="mascotLoading-container"  *ngIf="isShowMascot">
  <ng-lottie
  [options]="options"
  width="320px"
  height="320px"
  style="background-size: cover"
></ng-lottie>
</div>

<div class="non-mascot-loading" *ngIf="!isShowMascot">
  <img src="assets/Loading-animation.gif" class="spinner" alt="loading animation"/>
</div>
