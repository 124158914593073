import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangeData } from 'ngx-intl-tel-input';
import { PageName } from 'src/app/core/enums';
import { ErrorObj } from 'src/app/core/models';
import { ErrorService } from 'src/app/core/services/error.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import { OtpService } from './otp.service';
import { RoutingService } from './routing.service';

@Injectable({
  providedIn: "root"
})
export class ForgotPasswordService {

  constructor(
    private otpService: OtpService,
    private utilsService: UtilsService,
    private userService: UserService,
    private errorService: ErrorService,
    private routingService: RoutingService,
  ) { }

  async onForgotPassword(mobileNo: string, newPassword: string, otpCode: string) {
    try {
      return await this.userService.forgotPassword(mobileNo, newPassword, otpCode);
    } catch (err) {
      this.setError(err);
    }
  }
  async onForgotPasswordSuccess() {
    try {
      const usr = this.getUser();
      if(!(usr && usr.mobileNo && usr.password)) {
        return;
      }
      this.navigateToPage(PageName.LoginPage, { });
    } catch (err) {
      this.navigateToPage(PageName.LoginPage, { });
      this.setError(err);
    }
  }

  navigateToLoginPage(data?: any) {
    this.navigateToPage(PageName.LoginPage, data);
  }


  private setError(errorObj: ErrorObj) {
    this.errorService.insertErrorObj(errorObj);
    this.userService.error(errorObj);
  }

  private navigateToPage(pageName: PageName, data: any) {
    const rtSv = this.routingService;
    rtSv.navigate(pageName, data);
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    const utilsSv = this.utilsService;
    return phoneObj.dialCode?.replace('+', '') + utilsSv.formatPhoneNo(phoneObj);
  }

  private getUser() {
    return this.userService.curUser;
  }


}
