import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItemStatus } from 'src/app/core/enums/MenuItemStatus';
import { MenuItem } from 'src/app/core/models/MenuItem';
import * as _ from 'lodash';

@Component({
  selector: 'app-menu-variation',
  templateUrl: './menu-variation.component.html',
  styleUrls: ['./menu-variation.component.scss']
})
export class MenuVariationComponent implements OnInit {
  @Input() variationItem: MenuItem[];
  @Input() orderLineGuid : string;
  @Input() currentMenuItem : MenuItem;

  @Output() onClickVariationItem = new EventEmitter();

  itemStatus = MenuItemStatus;
  initialSlide : number = 0;

  constructor() { }

  ngOnInit(): void {
    if(this.orderLineGuid && this.variationItem.length > 0){
      let variationIndex = this.variationItem.findIndex(item => item.itemCode == this.currentMenuItem.itemCode);
      this.initialSlide = variationIndex;
      if(variationIndex != -1){
        this.variationItem[variationIndex].isChosenVariation = true;
      }
    }
  }

  onClickVariation(menuItem : MenuItem){
    this.onClickVariationItem.emit(menuItem);
  }

}
