<svg attr.width="{{imageSet.width}}" attr.height="{{imageSet.height}}">
  <g>
    <image attr.xlink:href="{{ itemMenuTag.imageUrl }}" width="100%" />
    <text *ngIf="itemMenuTag.value" attr.x="{{imageSet.x}}" attr.y="{{imageSet.y}}" dominant-baseline="middle" text-anchor="middle"
      attr.font-size="{{imageSet.size}}" font-family="var(--font-style)" font-weight="bold" fill="#000">
      {{itemMenuTag.value}}
    </text>
    <text *ngIf="itemMenuTag.value" attr.x="{{imageSet.uomX}}" attr.y="{{imageSet.uomY}}" dominant-baseline="middle" text-anchor="middle"
      attr.font-size="{{imageSet.uomSize}}" font-family="var(--font-style)" font-weight="bold" fill="#000">
      {{itemMenuTag.uomDesc}}
    </text>
  </g>
</svg>
