import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  showExpiredQrErrorData = new BehaviorSubject<{ locDesc?: string, storeImageUrl?: string }>(null);

  closeDialogKey$ : Subject<string> = new Subject();
  openDialogKeys: string [] = [];

  constructor() { }

  getExpiredQrErrorData() {
    return this.showExpiredQrErrorData.asObservable();
  }

  closeDialogKey() {
    let popedKey = this.openDialogKeys.pop();
    this.closeDialogKey$.next(popedKey);
  }

  removeDialogKey() {
    this.openDialogKeys.pop();
  }
}
