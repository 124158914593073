import { ActivityStatus } from './../../../core/enums/ActivityStatus';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { ActivityTypeButton } from 'src/app/core/enums/ActivityTypeButton';
import { OrderService } from 'src/app/home/order/order.service';
import { ActivitySummary } from 'src/app/home/order/order.model';
import { ActivityType } from 'src/app/core/enums/ActivityType';
import { OrderTimeLineStatus } from 'src/app/core/enums/OrderTimeLineStatus';
import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { ChannelSecService } from 'src/app/home/channelsec/channelsec.service';
import { PreviousRoute } from 'src/app/core/models/PreviousRoute';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { CartModel } from 'src/app/core/models/CartModel';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { CartService } from 'src/app/core/cart/cart.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { StoreService } from 'src/app/store/store/store.service';
import { ChannelData } from 'src/app/core/models';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {
  @Input() order: ActivitySummary;
  @Output() onShowRate = new EventEmitter<any>();
  @Output() onReload = new EventEmitter();
  buttonTypes: any = ActivityTypeButton;
  environment: any = environment;
  isActive: boolean = false;
  isCancel: boolean = false;
  isComplete: boolean = false;
  isFail: boolean = false;
  isDelivery: boolean = false;
  isReceived: boolean = false;
  user : User;
  customerId : number | string;
  cartModel : CartModel;
  channelId : number;
  channelData : ChannelData;
  activityStatus = ActivityStatus;
  orderTimelineStatus = OrderTimeLineStatus;
  activityType = ActivityType;

  constructor(private router: Router,
    private orderService: OrderService,
    private channelSecService: ChannelSecService,
    private previousRouteService : PreviousRouteService,
    private userService : UserService,
    private cartQuery : CartQuery,
    private cartService : CartService,
    private channelService : ChannelService,
    private storeService : StoreService,
    private analyticsService: AnalyticsService,
    private queueService : QueueService,
    private routingService : RoutingService,
    private sessionStorageService: SessionStorageService
  ) { }

  async ngOnInit(){
    this.user = this.userService.getCustomer();
    this.customerId = this.user?.customerId ? this.user.customerId : "null";
    this.channelId = this.channelService.getChannelId();

    // get cart from state
    this.cartQuery.getCurrentCart().subscribe(cart => {
      if(cart && cart.cartModel){
        this.cartModel = _.cloneDeep(cart.cartModel);
      }
      else{
        this.cartModel = null;
      }
    })

    this.checkCardType(this.order.lastActivityType);

    if(this.order) {
      this.isDelivery = this.order.activitySourceFlag === OrderSourceFlag.WebDelivery || this.order.activitySourceFlag === OrderSourceFlag.AppDelivery ? true: false;
    }
  }

  checkCardType(activityType: string) {
    let active = [
      ActivityType.Order_submitted, ActivityType.Order_confirmed, ActivityType.Order_received,
      ActivityType.Preparing_order, ActivityType.Order_ready , ActivityType.Rider_assigned, ActivityType.Delivering_order,
      ActivityType.Store_cancel_failed, ActivityType.Cancel_failed,
      ActivityType.Cancel_order_requested, ActivityType.Store_cancel_requested
    ];
    let cancellation = [
      ActivityType.Order_cancelled, ActivityType.Store_cancelled
    ];
    let completed = [ActivityType.Order_refunded, ActivityType.Order_completed];
    let failed = [
      ActivityType.Payment_failed, ActivityType.Pickup_failed, ActivityType.Assign_failed,
      ActivityType.Delivery_failed, ActivityType.Refund_failed
    ];
    let received = [ ActivityType.Refund_requested ]

    if(active.some(e => e === activityType)) {
      this.isActive = true;
    } else if(cancellation.some(e => e === activityType)) {
      this.isCancel = true;
    } else if(completed.some(e => e === activityType)) {
      this.isComplete = true;
    } else if (failed.some(e => e === activityType)) {
      this.isFail = true;
    } else if (received.some(e => e === activityType)) {
      this.isReceived = true;
    }
  }

  async onPayment(event: any) {
    let currentRoute = this.router.url;
    this.previousRouteService.savedPaymentBackUrl(currentRoute);

    event.stopPropagation();
    let activityDetail = await this.orderService.getActivityDetailWithoutState(this.order.activityId);
    if(this.order.linkId){
      this.sessionStorageService.setItem("qrPayment", JSON.stringify(activityDetail));
    }
    else{
      this.sessionStorageService.setItem("staticQrActivity", JSON.stringify(activityDetail));
    }
    this.router.navigate(['/order-payment']);
  }

  async onAccept(event: any) {
    let respDt = null;
    event.stopPropagation();
    respDt = await this.orderService.acceptOrder(this.order.orderTranId);

    if(!(respDt instanceof(HttpErrorResponse))){
      this.analyticsService.acceptOrderEvent(this.order.orderTranId, this.user.mobileNo, this.user.name);
      this.channelSecService.removeAll();
      this.onReload.emit();
    }
  }

  async onRate(event: any) {
    event.stopPropagation();
    this.onShowRate.emit({isShow: true, activityId: this.order.activityId});
  }

  onViewIssue(event: any) {
    event.stopPropagation();

    let refundData: Partial<ActivitySummary> = {
      orderNo: this.order.orderNo,
      storeInfo: this.order.storeInfo,
      tranDate: this.order.tranDate,
      logData: this.order.logData,
      currCode: this.order.currCode,
      netAmt: this.order.netAmt
    }
    this.router.navigate(['/refund-detail'], { state: { refundData: JSON.stringify(refundData) } });
  }

  async onReorder() {
    let previousRoute = {} as PreviousRoute;
    previousRoute.routeName = this.router.url;
    previousRoute.activityId = this.order.activityId;
    this.previousRouteService.savedPreviousRoute(previousRoute);

    if(this.cartModel && this.cartModel?.orderHs.length != 0){
      let curStoreIndex = this.cartModel.orderHs.findIndex(val => val.storeId == this.order.storeInfo.storeId);

      if(curStoreIndex != -1){
        let cartTranIndex = this.cartModel.cartTranIds.findIndex(val => val == this.cartModel.orderHs[curStoreIndex].cartTranId);
        if(cartTranIndex != -1){
          this.cartModel.cartTranIds.splice(cartTranIndex, 1);
        }
        this.cartModel.orderHs.splice(curStoreIndex, 1);
        await this.cartService.recalculateCart(this.channelId, this.customerId, this.cartModel, false);
        this.cartService.removeCartById(this.customerId);
      }
    }

    this.storeService.setOrderTranId(this.order.orderTranId);
    this.storeService.removeIsDisplayedFlag();
    let storeNameWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.order.storeInfo.locDesc);
    this.router.navigate(['store', this.order.storeInfo.storeId, storeNameWithoutSpaces]);

  }

  async onNavigate() {
    this.channelId = this.channelService.getChannelId();
    if(this.order.lastActivityType == ActivityType.Queuing){
      // remove empty spaces from location description
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(this.order.storeInfo.locDesc);
      this.queueService.setManualBack(true);

      if(this.order.channelId != this.channelId){
        let urlToRoute = "queue/" + this.order.storeInfo.storeId + "/" + locDescWithoutSpaces + "/" + this.order.rsvTokenId;
        this.routingService.navigateChannelUrl(this.order.channelTag, urlToRoute);
      }
      else{
        this.router.navigate(["queue", this.order.storeInfo.storeId, locDescWithoutSpaces, this.order.rsvTokenId]);
      }
    }
    else{
      let data = { 'activityId': this.order.activityId };

      try {
        this.sessionStorageService.setItem("orderDetail", JSON.stringify(data));
      } catch(e) {
        console.log("Failed to set session storage", e)
      }

      this.router.navigate(['/order-detail']);
    }
  }
}
