import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from './../../../shared/services/toast.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CXMCustomerAddressResponse } from 'src/app/core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import { ToastData } from 'src/app/core/models/ToastData';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-new-address',
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.scss']
})
export class NewAddressComponent implements OnInit {
  editUserAddress: CXMCustomerAddressResponse;
  isMobileView : boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private toastService : ToastService,
    private breakpointObserver : BreakpointObserver
  ) { }

  ngOnInit(): void {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  async onSubmitAddAddress(data: any) {
    let resp = await this.userService.registerCustomerAddress(data);
    this.router.navigate(["account", "address"]);
    if(!(resp instanceof HttpErrorResponse) && this.isMobileView){
      let toastData = {} as ToastData;
      toastData.icon = "oda-check";
      toastData.iconColor = "#FFFFFF";
      toastData.iconCircleColor = "#8CD600";
      toastData.showCircleIcon = true;
      toastData.message = "add.addess.success.toast.1";
      this.toastService.show(toastData);
    }
  }

  backToPreviousPage(){
    this.router.navigate(["account", "address"]);
  }
}
