import { ToastService } from './../../shared/services/toast.service';
import { WebLinkTokenResponse } from './../models/WebLinkTokenResponse';
import { QrCartQuery } from './../qr-cart/qr-cart.query';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ID } from '@datorama/akita';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { of } from 'rxjs';
import { ChannelSecService } from 'src/app/home/channelsec/channelsec.service';
import { UserChnlService } from 'src/app/home/userchnl/userchnl.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { environment } from 'src/environments/environment';
import { StoreMode, UserLoginMode, UserRole } from '../enums';
import { AnalyticsEvent } from '../enums/AnalyticsEvent';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { CustomRequest } from '../models/CustomRequest';
import { LoginRequest } from '../models/LoginRequest';
import { mapLoginResp, mapProfileResp, User } from '../user/user.model';
import { UserQuery } from '../user/user.query';
import { UserStore } from '../user/user.store';
import { CustomService } from './custom.service';
import { ToastData } from '../models/ToastData';
import { ChangeData } from 'ngx-intl-tel-input';
import { LoginForm } from 'src/app/account/models';
import { UserService } from '../user/user.service';
import { FormStage } from 'src/app/account/enums/FormStage';
import { OtpService } from 'src/app/account/services/otp.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;
  qrTokenResponse : WebLinkTokenResponse;

  constructor(
    private http: HttpClient,
    private customService: CustomService,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private analyticsService: AnalyticsService,
    private userchnService: UserChnlService,
    private router: Router,
    private location : Location,
    private channelSecService: ChannelSecService,
    private qrCartQuery : QrCartQuery,
    private toastService : ToastService,
    private queueService : QueueService,
    private userService : UserService,
    private otpService : OtpService,
    private sessionStorageService: SessionStorageService
  ) {
    this.get(StoreMode.Internal).subscribe(async (userData: any) => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.refreshToken = userData && userData['refreshToken'] ? userData['refreshToken'] : '';
      this.curUser = userData;
    });

    this.qrCartQuery.select(state => state.qrTokenResponse).subscribe(qrTokenResponse => {
      this.qrTokenResponse = qrTokenResponse ? qrTokenResponse : null;
    })
  }

  get(storeMode: StoreMode) {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.userStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.userStore.setLoading(false);
    }
    return this.userQuery.selectFirst();
  }

  add(user: User) {
    if (!user.id) {
      const genId = environment.localGenCustomerId;
      if (this.curUser?.id === genId) {
        this.remove(genId);
      }
      user.id = genId;
    }
    this.setActive(Number(user.id));
    this.userStore.add(user);
  }

  update(id: any, user: Partial<User>) {
    this.userStore.update(id, user);
  }

  remove(id: ID) {
    this.userStore.remove(id);
  }

  private setActive(tizId: number) {
    this.userStore.setActive(tizId);
  }

  async login(loginRequest: LoginRequest, routeName : string, isQueue : boolean = false): Promise<any> {
    let respDt = null, respProf = null;

    respDt = await this.reqLogin(loginRequest);
    if (respDt instanceof HttpErrorResponse) {
      return respDt;
    }

    this.accessToken = respDt.accessToken;

    respProf = await this.reqProfile();
    if (respProf instanceof HttpErrorResponse) {
      return respDt;
    } else {
      if (this.curUser) {
        this.remove(this.curUser.id);
      }

      this.add({
        id: respProf.customerId,
        ...respDt,
        ...respProf,
        userLoginMode: UserLoginMode.LoggedIn,
        userRole: UserRole.User,
        isAnonymous: false
      } as any);


      if (respProf.displayName == '') {
        this.router.navigateByUrl("complete-profile");
        return false;
      } else {
        await this.successLoginUser(routeName, isQueue);
      };

      return true;
    }
  }

  private async successLoginUser(routeName : string, isQueue : boolean) {
    this.analyticsService.logEvents(AnalyticsEvent.login, { method: 'web' });
    this.userchnService.getCustomerChannel();
    this.channelSecService.removeAll();

    this.sessionStorageService.removeItem('previousRoute');
    this.sessionStorageService.removeItem('navigateToPayment');

    if(!isQueue){
      // if given route name will be route to that page instead of back
      if(routeName){
        this.router.navigate([routeName], {replaceUrl: true, state: { reinit : true }});
      }
      else{
        this.location.back();
      }

      if(this.qrTokenResponse){
        this.loginToastInit();
      }
    }
    else{
      let queueRequest = JSON.parse(this.sessionStorageService.getItem("queueRequest"));
      queueRequest.customerName = this.curUser.name;
      await this.queueService.onJoinQueueInit(queueRequest, true);
    }
  }

  private async reqLogin(loginRequest: LoginRequest) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.Login,
      hostPath: environment.hostPath,
      body: {
        mobileNo: loginRequest.mobileNo,
        otpCode: loginRequest.otpCode? loginRequest.otpCode: undefined,
        password: loginRequest.password? loginRequest.password: undefined,
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    if (respInfo instanceof HttpErrorResponse === false) {
      respInfo = mapLoginResp(respInfo);
    }
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqProfile() {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.user.Profile,
      hostPath: environment.hostPath,
      headers: {
        accessToken: this.accessToken
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    respInfo = mapProfileResp(respInfo);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => dd['body'] ? dd['body'] : dd);
  }

  // refresh token api logic
  getAccessToken() {
    return this.accessToken;
  }

  refreshTokenApi() {
    let respDt = null;
    if (this.refreshToken) {
      respDt = this.reqRefreshToken(this.refreshToken);
    }
    return of(respDt);
  }

  private async reqRefreshToken(refreshToken: string) {
    let cusreq: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.user.RefreshToken,
      hostPath: environment.hostPath,
      body: {
        refreshToken: refreshToken,
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;

    this.userStore.setLoading(true);

    respInfo = await this.reqCustomHttpCall(cusreq);

    if (respInfo instanceof HttpErrorResponse === false) {
      respInfo = mapLoginResp(respInfo);
      if (this.curUser) {
        this.updateStoreState(respInfo);
      }
    }

    this.userStore.setLoading(false);
    return respInfo;
  }

  updateStoreState(respInfo: any) {
    respInfo = {
      ...this.curUser,
      idToken: respInfo.idToken,
      accessToken: respInfo.accessToken,
      refreshToken: respInfo.refreshToken,
      claims: respInfo.claims
    };

    this.userStore.update(this.curUser.id, respInfo);
  }

  loginToastInit(){
    let toastData : ToastData = {} as ToastData;

    toastData.message = "toast.login.success";
    toastData.icon = "color oda-check-alt";
    toastData.iconColor = "#8CD600";
    toastData.timeToClose = 4000;
    this.toastService.show(toastData);
  }

  // login data init when submit phone number
  async loginDataInit(phoneData : ChangeData){
    const loginForm: LoginForm = {
      dialCode: phoneData.dialCode.replace('+', ''),
      phoneNo: this.formatPhoneNo(phoneData),
      stage: FormStage.Login,
      password: '',
      mobileObj: phoneData,
      otpCode: '',
    };

    let phoneNoExist : any;
    let pwIsSet : boolean;
    let customerProfileStatus = this.userService.getCustomerStatusCached();

    // set stored data to null after using it
    this.userService.setCustomerProfileStatus(null);

    // set is submitted login flag
    this.setSubmittedLogin(true);

    phoneNoExist = customerProfileStatus.mobileNumber? true: false;
    pwIsSet = customerProfileStatus.passwordFlag? customerProfileStatus.passwordFlag : false;

    if(phoneNoExist && pwIsSet) {
      this.router.navigate(["password-login"], { state: { data: loginForm, phoneNoExist: phoneNoExist } });
    } else {
      await this.otpService.onSendOTP(loginForm.mobileObj);
      this.router.navigate(["otp"], { state: { loginForm: loginForm, phoneNoExist: phoneNoExist, signupForm: !phoneNoExist } });
    }
  }

  //#region get set of submitted login
  setSubmittedLogin(isSubmittedLogin : boolean){
    this.sessionStorageService.setItem("isSubmittedLogin", JSON.stringify(isSubmittedLogin));
  }

  getSubmittedLogin(){
    let submittedLoginData = JSON.parse(this.sessionStorageService.getItem("isSubmittedLogin"));
    return submittedLoginData ? true : false;
  }

  removeSubmittedLoginFlag(){
    this.sessionStorageService.removeItem("isSubmittedLogin");
  }
  //#endregion

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }
}
