import { Component, Input, OnInit } from '@angular/core';
import { VoidFlag } from 'src/app/core/enums/VoidFlag';
import { OrderD } from 'src/app/core/models/OrderD';
import { OrderModifier } from 'src/app/core/models/OrderModifier';

@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss']
})
export class OrderItemComponent implements OnInit {
  @Input() item : OrderD;
  @Input() currCode : string;
  @Input() isOrderSummaryPage : boolean;
  @Input() linkId : number;
  @Input() isShowSlashPrice: boolean;

  voidFlag = VoidFlag;
  thumbnail : string = "";

  constructor(
  ) { }

  ngOnInit(): void {

    let thumbnailStatus = this.item.orderDAttrs.find(val => val.attrCode == 'ITEMIMGURL');
    if(thumbnailStatus){
      this.thumbnail = thumbnailStatus.attrValue;
    }
  }

  checkHasSubModifiers(modifiers : OrderModifier){
    if(modifiers.subModifiers){
      return true;
    }
    else{
      return false;
    }
  }

}
