import { Component, Input, OnInit } from '@angular/core';
import { LeadTimeType } from 'src/app/core/enums/LeadTimeType';
import { MenuItem } from 'src/app/core/models/MenuItem';

@Component({
  selector: 'app-preorder-tag',
  templateUrl: './preorder-tag.component.html',
  styleUrls: ['./preorder-tag.component.scss']
})
export class PreorderTagComponent implements OnInit {

  @Input() menuItem : MenuItem;
  @Input() roundedSide : string;

  leadTimeType = LeadTimeType;

  constructor() { }

  ngOnInit(): void {
  }

}
