import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefundEmailGuardGuard implements CanActivate {
  id: number;

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (history.state && history.state != null && history.state != undefined && !history.state.orderTranId) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
