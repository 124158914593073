<div class="out-of-service-container">

  <i class="oda-times hidden-sm" (click)="onClickCloseIcon()" [hidden]="isMiniProgram"></i>

  <div class="mascot-image-container" *ngIf="channelId | isShowMascot">
  <div class="image-size">
      <img src="assets/mascot/Mascot_delivery@2x.webp" alt="odaring mascot">
    </div>
  </div>

  <div class="heading-11" style="text-align: center; margin-top: 10px;">{{"home.location.outofservice" | translate}}</div>

  <div class="out-of-service-description" style="text-align: center;" *ngIf="!isCartPage">{{"home.location.outofservice.description" | translate}}</div>
  <div class="out-of-service-description" style="text-align: center;" *ngIf="isCartPage">{{"home.location.outofservice.description.2" | translate}}</div>

  <div class="out-of-service-button-container" *ngIf="!isCartPage">
    <button class="switch-button" (click)="onClickSwitchOrderType()" *ngIf="storeResponse.pickupFlag || storeResponse.dineFlag" [hidden]="isMiniProgram">
      {{"reorder.switch.order.type" | translate}}
    </button>
    <button class="reset-button" (click)="onClickResetButton()" [hidden]="isMiniProgram">{{"reset.address" | translate}}</button>
  </div>

  <div class="okay-button-container"  [ngStyle]="{'margin-top': isMiniProgram ? '0px' : '20px'}">
    <button class="okay-button heading-11" (click)="onClickCloseIcon()" *ngIf="isCartPage" [hidden]="isMiniProgram">{{"merchant.reorder.button.desc.1" | translate}}</button>
    <button class="okay-button heading-11" (click)="onClickCloseIcon()" *ngIf="!isCartPage && isMiniProgram">{{"merchant.reorder.button.desc.1" | translate}}</button>
  </div>

</div>
