import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-order-alert',
  templateUrl: './empty-order-alert.component.html',
  styleUrls: ['./empty-order-alert.component.scss']
})
export class EmptyOrderAlertComponent implements OnInit {
  @Input() isActive: boolean = true;
  @Input() channelId: number;

  constructor() { }

  ngOnInit(): void {
  }

}
