import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {Clipboard} from '@angular/cdk/clipboard';

import { SocialShareIcon } from 'src/app/core/enums/SocialShareIcon';
import { InfoPanelD } from 'src/app/core/models/InfoPanelD';
import { InfoPanelResponse } from 'src/app/core/models/InfoPanelResponse';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { Meta, Title } from '@angular/platform-browser';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { Channel } from 'src/app/home/channel/channel.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {
  channel$: Subscription;

  infoPanel: InfoPanelResponse;
  currChannel: Channel = null;

  socialShareIcons = SocialShareIcon;

  infoId: number;

  title: string;
  subtitle: string;
  content: string;
  imageUrl: string;
  shareUrl: string;

  showHeader: boolean = true;
  copiedText: boolean = false;
  showShare: boolean = false;


  constructor(private channelService: ChannelService,
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private titleService: Title,
    private metaService: Meta,
    private previousRouteService: PreviousRouteService,
    private location: Location) {
      this.currChannel = this.channelService.curChannel;
      let prevRoute = this.router.getCurrentNavigation().previousNavigation? this.router.getCurrentNavigation().previousNavigation?.finalUrl.toString(): null

      if(prevRoute) {
        this.previousRouteService.setPreviousNewsRoute(prevRoute);
      }
    }

  async ngOnInit() {
    await this.channelService.getChannelDataCheck();

    this.infoId = this.route.snapshot.params?.infoId? this.route.snapshot.params?.infoId: null;

    if(this.router.url) {
      let index = this.router.url.indexOf('?');
      let url: string;

      if(index >= 0) {
        url = this.router.url.slice(0, index);
      } else {
        url = this.router.url;
      }
    }

    this.route.queryParams.subscribe(params => {
      this.showHeader = params?.showHeader === 'false'? false: true;
    });

    if(this.infoId) {
      let result = await this.channelService.getInfoPanelData(this.infoId);

      if (!(result instanceof(HttpErrorResponse))) {
        this.infoPanel = result;

        this.infoPanel.infoPanelDs.forEach((infoData: InfoPanelD) => {
          switch (infoData["sectionCode"]) {
            case "101": {
              this.title = infoData.sectionData;
              break;
            }
            case "102": {
              this.subtitle = infoData.sectionData;
              break;
            }
            case "103": {
              this.content = infoData.sectionData;
              break;
            }
            case "202": {
              this.imageUrl = infoData.sectionData;
              break;
            }
          }
        });
      }
    }
  }

  copyUrl() {
    this.copiedText = this.clipboard.copy(this.infoPanel.infoUrl);
    setTimeout(() => {
      this.copiedText = false;
    }, 1500);
  }

  onClickBack() {
    let prevRoute = this.previousRouteService.getPreviousNewsRoute();

    if(prevRoute && !this.currChannel) {
      // from odaring but refreshed
      this.router.navigateByUrl(prevRoute);
      this.location.replaceState(prevRoute);
    } else if(prevRoute && this.currChannel) {
      // from odaring
      this.location.back();
    } else {
      // from share
      this.router.navigateByUrl('/home');
      this.location.replaceState('/home');
    }

    this.previousRouteService.removePreviousNewsRoute();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    let headerEl = document.getElementById('header');
    let headerBtnEl = document.getElementById('headerBtn');
    let titleEl = document.getElementById('title');
    let backBtnEl = document.getElementById('backBtn');
    let uploadBtnEl = document.getElementById('uploadBtn');

    if(headerEl && headerBtnEl && titleEl) {
      let scrollPosition = window.scrollY;
      let headerHeight = headerEl.offsetHeight;
      let heightToFade = headerHeight / 2;
      let heightToSticky = headerHeight - headerBtnEl.offsetHeight;
      let distance = headerEl.getBoundingClientRect().bottom
      let opacity = (1 - (distance / headerHeight)).toString();

      if (scrollPosition < heightToFade && scrollPosition < heightToSticky) {
        titleEl.style.opacity = '0';
        headerBtnEl.style.backgroundColor = 'transparent';
        backBtnEl.style.color = '#FDEF23';
        uploadBtnEl.style.color = '#FDEF23';
      } else if(scrollPosition > heightToFade && scrollPosition < heightToSticky) {
        titleEl.style.opacity = opacity;
        headerBtnEl.style.backgroundColor = 'rgba(255,255,255,' + opacity +')';
        backBtnEl.style.color = '#FDEF23';
        uploadBtnEl.style.color = '#FDEF23';
      }
      else if (scrollPosition > heightToSticky) {
        titleEl.style.opacity = '1';
        headerBtnEl.style.backgroundColor = 'rgba(255,255,255, 1)';
        backBtnEl.style.color = '#EB3600';
        uploadBtnEl.style.color = '#EB3600';
      }
    }
  }

  ngOnDestroy(): void {
    this.channel$?.unsubscribe();
    this.previousRouteService.removePreviousNewsRoute();
  }
}
