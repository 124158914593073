import { Component, OnInit } from '@angular/core';
import { RoutingService } from 'src/app/home/services/routing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email-success',
  templateUrl: './verify-email-success.component.html',
  styleUrls: ['./verify-email-success.component.scss']
})
export class VerifyEmailSuccessComponent implements OnInit {

  constructor(
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
  }

  navigateToHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }

}
