import { ChannelPlatformSetResponse } from 'src/app/core/models/ChannelPlatformSetResponse';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'platformSetCPSR'
})
export class PlatformSetCPSRPipe implements PipeTransform {

  transform(platformSet : ChannelPlatformSetResponse[], setCode : string, storeId : number) {
    if(platformSet.length == 0){
      return null;
    }

    let filteredSetting =  platformSet.filter(val => val.setCode == setCode);
    let currentSetting : ChannelPlatformSetResponse;

    currentSetting = filteredSetting.find(val => val.storeId == storeId);
    if(!currentSetting){
      currentSetting = filteredSetting.find(val => val.storeId == 0);
    }

    return currentSetting ? currentSetting : null;
  }

}
