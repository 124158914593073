<!-- item group container -->
<div class="one_modifier_container" *ngFor="let item of emptyTempArray | modGroupProcess : curItemGrps : curSubItemGrps : isSubModifier; trackBy: trackByFn"
[id]="!isSubModifier ? 'SECT' + item.code : 'SECTT' + item.code"
style="scroll-margin-top: 150px;">

  <!-- item group title -->
  <div class="group-title-container">
    <h4 class="title-desc">
      <span class="p-mr-2 modifiersGrp_title" [innerHtml]="item.title"></span>
      <span class="quantity_indicator_font" *ngIf="item.minSelect === 1 && item.maxSelect == 1">(Pick 1)</span>
      <span class="quantity_indicator_font" *ngIf="item.minSelect == item.maxSelect && item.minSelect != 1 && item.maxSelect != 1">(Pick {{item.minSelect}})</span>
      <span class="quantity_indicator_font" *ngIf="item.minSelect == 0 && item.maxSelect >= 1">(Max {{item.maxSelect}})</span>
      <span class="quantity_indicator_font" *ngIf="item.minSelect == 1 && item.maxSelect == 0">(Pick {{item.minSelect}})</span>
      <span class="quantity_indicator_font" *ngIf="item.minSelect >= 1 && item.maxSelect > 1 && item.minSelect != item.maxSelect">(Pick {{item.minSelect}}, Max {{item.maxSelect}})</span>
    </h4>

    <span class="optional-desc" *ngIf="item.minSelect == 0">{{"merchant.detail.optional" | translate}}</span>
    <span class="required-desc" *ngIf="item.minSelect > 0">{{'required.tag' | translate}}</span>
  </div>

  <!-- alert message if item group is required and there is no modifiers chosen yet -->
  <div class="error_alert" *ngIf="item.isComplete == false && item.qtyGrp < item.minSelect">
    <i class="oda-alert"></i>
    <span class="error_message">Please choose {{item.minSelect}} item(s)</span>
  </div>

  <!-- item modifiers-->
  <div *ngIf="isSubModifier == false">
    <div *ngFor="let itemDetails of item.modifiers; trackBy: trackByFn">
      <app-store-item-list
        [storeId]="storeId"
        [curMod]="itemDetails"
        [currencyCode]="currencyCode"
        [isMobileView]="isMobileView"
        [curModGrp]="item"
        [tempSelectedQty]="tempSelectedQty"
        [curStoreCart]="curStoreCart"
        (displayMaximumMessage)="displayMaximumMessage()">
      </app-store-item-list>
    </div>
  </div>

  <!-- item submodifiers -->
  <div *ngIf="isSubModifier == true">
    <div *ngFor="let itemDetails of item.subModifiers">
      <app-store-item-list
        [storeId]="storeId"
        [curMod]="itemDetails"
        [currencyCode]="currencyCode"
        [isMobileView]="isMobileView"
        [curModGrp]="item"
        [isSubModifier]="isSubModifier"
        (displayMaximumMessage)="displayMaximumMessage()">
      </app-store-item-list>
    </div>
  </div>
</div>


