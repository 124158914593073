import { NavigationBehaviorOptions } from '@angular/router';

import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PageName } from 'src/app/core/enums';
import { ErrorObj } from 'src/app/core/models';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { ForgotPasswordService } from './../services/forgot-password.service';
import { QueueService } from 'src/app/queue/queue/queue.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  errorObj: ErrorObj = null as any;
  pageName = PageName.ForgotPasswordPage;
  mobileNo: string;
  otpCode: string;
  isQueue : boolean;

  constructor(private forgotPasswordService: ForgotPasswordService,
    private popupMessageService: PopupMessageService,
    private queueService : QueueService) { }

  ngOnInit(): void {
    let queueData = this.queueService.getQueueData();
    this.isQueue = queueData ? true : false;
    this.mobileNo = history.state.data?.mobileNo? history.state.data.mobileNo: "";
    this.otpCode = history.state.data?.otpCode? history.state.data.otpCode: "";
  }

  async onFormSubmit(dt: any) {
    let resp: any = await this.forgotPasswordService.onForgotPassword(this.mobileNo, dt, this.otpCode);

    let navigationSetting : NavigationBehaviorOptions = {} as NavigationBehaviorOptions;
    navigationSetting.replaceUrl = true;

    let redirectRoute = '/login';
    if(this.isQueue){
      redirectRoute = await this.queueService.getPreQueueRoute();
    }

    if(resp instanceof(HttpErrorResponse)) {
      this.popupMessageService.show({
        icon: "oda-alert-alt",
        iconColor: "red",
        desc: "reset.password.unsuccessful.desc",
        btn: "button.ok",
        redirectLink: redirectRoute,
        navigationBehaviourOptions: navigationSetting
      });
    } else {
      this.popupMessageService.show({
        icon: "oda-check-alt",
        iconColor: 'green',
        desc: "reset.password.successful.desc",
        btn: "button.ok",
        redirectLink: redirectRoute,
        navigationBehaviourOptions: navigationSetting
      });
    }
  }

  onBackLogin() {
    this.forgotPasswordService.navigateToLoginPage({ });
  }

}
