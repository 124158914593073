<i class="close-icon oda-times" (click)="clickClose()"></i>

<div class="content-container">
  <div class="holding-phone-image-container" *ngIf="channelId | isShowMascot">
    <img src="assets/holding-phone.svg" alt="odaring mascot">
  </div>

  <h3 class="submit-phone-title heading-11">{{"qr.phone.submit.title.2" | translate}}</h3>
  <p class="submit-phone-desc heading-9">{{"qr.phone.submit.desc.1" | translate}}</p>

  <form class="submit-input-container" [formGroup]="fgroup" (submit)="onSubmit()">
    <h3 class="submit-label heading-11">{{"input.label.1" | translate}}</h3>

    <div class="submit-input">
      <ngx-intl-tel-input
        [cssClass]="'custom'"
        [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
        [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
        [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
        [selectFirstCountry]="telInpSetting.selectFirstCountry"
        [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
        [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
        [selectedCountryISO]="countryCode | telInputCountryCode" name="phone" formControlName="phoneNo"
        (keyup.enter)="onSubmit()"
        id="mobileInput">
      </ngx-intl-tel-input>

      <button type="reset" class="clear-input"><i class="oda-times" *ngIf="phoneNoVal"></i></button>

      <div class="invalid-feeback" *ngIf="isInvalid">
        <i class="oda-alert"></i>
        {{ 'login.form.mobile.number.invalid' | translate }}
      </div>
    </div>

    <button type="submit" class="submit-action-button heading-11"
    [ngClass]="{'no-input-value': !phoneNoVal , 'input-value-exists': phoneNoVal}">
      {{"button.submit" | translate}}
    </button>
  </form>

  <p class="term-and-condition">
    {{"login.term.and.privacy.policy.5" | translate}}
     <span class="important-terms" (click)="onNavigate('tNc')">{{"login.term.and.privacy.policy.2" | translate}}</span>
     {{"login.term.and.privacy.policy.3" | translate}}
     <span class="important-terms" (click)="onNavigate('privacyPolicy')">{{"login.term.and.privacy.policy.4" | translate}}</span>
  </p>
</div>

