<div class="membership-voucher-container" (click)="onShowDetail()">
  <div class="voucher-card cardLeft">
    <img *ngIf="membershipVoucher.imageData" [src]="membershipVoucher.imageData" onerror="this.src='assets/image-unavailable.svg'" alt="voucher image" loading="lazy">
  </div>
  <div class="voucher-card cardRight">
    <div class="voucher-info">
      <h1 class="vchTypDesc">{{membershipVoucher.vchTypTitle}}</h1>
      <h1 class="expiryDate" *ngIf="membershipVoucher.expiryDate">{{'rewards.myvoucher.desc.valid' | translate}} {{membershipVoucher.expiryDate | date: "dd/MM/yyyy"}}</h1>
    </div>
    <div class="redeem-point">
      <h1 class="pointAmount" *ngIf="membershipVoucher.pointAmount && isShowPointAmount">{{membershipVoucher.pointAmount | number}}<span>{{'membership.points.unit.label' | translate}}</span></h1>
    </div>
  </div>
</div>
