import { Injectable } from '@angular/core';
import { ChangeData } from 'ngx-intl-tel-input';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { ErrorObj } from 'src/app/core/models';
import { ErrorService } from 'src/app/core/services/error.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { UserService } from 'src/app/core/user/user.service';
import { PageName } from 'src/app/core/enums';
import { RoutingService } from './routing.service';
import { OtpService } from './otp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  otpInfo = new BehaviorSubject<{mobileNo: string, prefix: string, pageName?: PageName}>(null);

  constructor(
    private routingService: RoutingService,
    private utilsService: UtilsService,
    private userService: UserService,
    private errorService: ErrorService,
    private otpService: OtpService,
    private toastService: ToastService
  ) { }

  async onSendOTP(mobileNo: any, otpPrefix: string = this.utilsService.createOTPPrefix(), emailRedirectLink?: string, isSendEmail?: boolean) {
    try {
      let formattedMobileNo = typeof(mobileNo) == 'string' ? mobileNo : this.formatPhoneNo(mobileNo);
      let resp = await this.userService.sendOTP(formattedMobileNo, otpPrefix, emailRedirectLink, isSendEmail);
  
      if (resp instanceof HttpErrorResponse) {
        let toastData = {} as ToastData;
        toastData.message = resp.error['title'];
        toastData.backgroundColorCode = '#EB3600';
        toastData.hideCloseBtn = true;
        this.toastService.show(toastData);
      } else {
        this.otpInfo.next({
          mobileNo: formattedMobileNo,
          prefix: otpPrefix,
        });
      }
    } catch (err) {
      this.setError(err);
    }
  }

  async onVerifyUpdateMobileNo(mobileNo: any, otpCode: string) {
    try {
      let formattedMobileNo = typeof(mobileNo) == 'string' ? mobileNo : this.formatPhoneNo(mobileNo);
      return await this.userService.verifyUpdateMobileNo(formattedMobileNo, otpCode);
    } catch (err) {
      this.setError(err);
      this.navigateWithOtpPageName();
    }
  }

  async onUpdatePassword(oldPassword: string, newPassword: string) {
    try {
      return await this.userService.updatePassword(oldPassword, newPassword);
    } catch (err) {
      this.setError(err);
    }
  }

  async onUpdateProfile(user: any, otpRegisterUpdate?: boolean) {
    try {
      return await this.userService.updateProfile(user, otpRegisterUpdate);
    } catch (err) {
      this.setError(err);
    }
  }

  async onUpdateMobileNoStatus(mobileNo: any, otpCode: string) {
    try {
      let formattedMobileNo = typeof(mobileNo) == 'string' ? mobileNo : this.formatPhoneNo(mobileNo);
      return await this.userService.updateMobileNoStatus(formattedMobileNo, otpCode);
    } catch (err) {
      this.setError(err);
    }
  }

  async onVerifyEmail() {
    try {
      await this.userService.sendEmailVerification();
      this.onVerifyEmailSuccess();
    } catch (err) {
      this.setError(err);
    }
  }

  getOtpInfo() { 
    return this.otpInfo.asObservable();
  }

  setError(errorObj: ErrorObj) {
    this.errorService.insertErrorObj(errorObj);
    this.userService.error(errorObj);
  }

  onUpdateSuccess() {
    this.navigateToPage(PageName.ProfilePage, { });
  }

  onVerifyEmailSuccess() {
    this.navigateToPage(PageName.ProfilePage, { });
  }

  navigateToResetMobile() {
    this.navigateToPage(PageName.ResetMobilePage, { });
  }

  navigateToResetPass() {
    this.navigateToPage(PageName.ResetPasswordPage, { });
  }

  navigateWithOtpPageName() {
    const pageName = this.otpService.pageName;
    this.navigateToPage(pageName || PageName.LoginPage, { PageName: pageName });
  }

  private navigateToPage(pageName: PageName, data: any) {
    const rtSv = this.routingService;
    rtSv.navigate(pageName, data);
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    const utilsSv = this.utilsService;
    return phoneObj.dialCode?.replace('+', '') + utilsSv.formatPhoneNo(phoneObj);
  }

}
