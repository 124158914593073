<div class="orderForm-container p-d-flex p-flex-column"
  [ngClass]="{ 'cancel': isCancel, 'complete': isComplete, 'fail': isFail, 'receive': isReceived }"
  (click)="onNavigate();">
  <div class="p-d-flex header-content">
    <div>
      <div class="p-d-flex p-ai-center heading-9">
        <i class="status-icon"
          [ngClass]="{
          'oda-loading': isActive || isReceived || order.lastActivityType == activityType.Queuing,
          'oda-remove-circle': isCancel,
          'oda-check-alt': isComplete,
          'oda-alert-alt': isFail}">
        </i>
        <div class="order-no">
          <span *ngIf="order.lastActivityType != activityType.Queuing">{{ 'order.form.order' | translate }} <span class="bold-text">#{{ order.orderNo }}</span></span>
          <span *ngIf="order.lastActivityType == activityType.Queuing">{{"queue.title.1" | translate}} <span class="bold-text">{{ order.queueNo }}</span></span>
        </div>
      </div>
    </div>
    <div class="p-ml-auto">
      <i class="delivery-icon {{ order.activitySourceFlag | orderTypeIcon }}" *ngIf="order.lastActivityType != activityType.Queuing"></i>
      <i class="color oda-clock-alt" *ngIf="order.lastActivityType == activityType.Queuing"></i>
      <i class="enter-icon oda-chevron-right"></i>
    </div>
  </div>

  <div class="p-d-flex sub-content">
    <div class="img-container hidden-sm"><img [src]="order.storeInfo.imageUrl? order.storeInfo.imageUrl: 'assets/image-unavailable.svg'"
      onerror="this.src='assets/errorMsg_image/imageError.webp'" alt="error image" loading="lazy"/></div>
    <div class="p-d-flex p-flex-column orderForm-sub">
      <div class="channelTag" *ngIf="order.channelTag && channelId === 1 && order.channelTag !== environment.odaringChannel">#{{ order.channelTag }}</div>
      <div class="orderForm-store p-d-flex">
        <div class="p-mr-auto">
          <div class="p-mb-1 store-name">{{ order.storeInfo.locDesc }}</div>
          <div class="date">{{ order.tranDate | date: "dd MMM yyyy HH:mm" }}</div>
        </div>
        <div class="p-ml-3 p-text-right" *ngIf="order.lastActivityType != activityType.Queuing">
          <div class="p-ml-auto p-mb-auto price"> {{ order.netAmt | currency: order.currCode : 'symbol-narrow' }}</div>
          <div class="p-ml-auto quantity">{{ 'order.form.item' | translate : {itemQty : order.totalItemQty} }}</div>
        </div>
      </div>

      <div class="orderForm-des heading-9 hidden-sm" [innerHtml]="order.orderDesc"></div>

      <div class="orderForm-activity-timeline" *ngIf="order.activeFlag && !order.completeFlag && order.orderTimeLineStatus !== orderTimelineStatus.Undefined">
        <app-activity-timeline [orderTimelineStatus]="order.orderTimeLineStatus"
        [activitySourceFlag]="order.activitySourceFlag"></app-activity-timeline>
      </div>

      <div class="orderForm-btn p-d-flex p-flex-wrap p-ai-center">
        <div class="p-mt-1 p-mb-1 p-mr-2 activity-type"><span class="dot p-mr-2"></span>{{ ( order.logData | jsonParse ).Title | translate }}</div>
        <div class="p-ml-auto p-mt-1 p-mb-1">
          <ng-container *ngIf="order.allowPayFlag; else gotIt">
            <button type="button" class="btn-activity" *ngIf="order.allowPayFlag" (click)="onPayment($event)">{{ 'order.summary.payment.now.title' | translate }}</button>
          </ng-container>
          <ng-template #gotIt>
            <ng-container *ngIf="order.allowAcceptFlag; else rate">
              <button type="button" class="btn-activity" *ngIf="order.allowAcceptFlag" (click)="onAccept($event)"><span>{{ 'button.got.it' | translate }}!</span></button>
            </ng-container>
          </ng-template>
          <ng-template #rate>
            <ng-container *ngIf="order.allowRateFlag; else viewIssue">
              <button type="button" class="btn-activity" *ngIf="order.allowRateFlag" (click)="onRate($event)">{{ 'button.rate.order' | translate }}</button>
            </ng-container>
          </ng-template>
          <ng-template #viewIssue>
            <ng-container *ngIf="order.allowViewIssuesFlag; else reorder">
              <button type="button" class="btn-activity" *ngIf="order.allowViewIssuesFlag" (click)="onViewIssue($event)">{{ 'button.view.issue' | translate }}</button>
            </ng-container>
          </ng-template>
          <ng-template #reorder>
            <ng-container *ngIf="order.allowReorderFlag">
              <button type="button" class="btn-activity" *ngIf="order.allowReorderFlag" (click)="onReorder() ; $event.stopPropagation()">{{ 'button.reorder' | translate }}</button>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
