<div class="store_submodifier_content_container" (scroll)="onScrollSub($event)" #parent>
  <div class="submodifier_title_container">
    <img class="submodifier-image hidden-lg" [src]="curModifier.thumbnail" *ngIf="curModifier.thumbnail" alt="odaring image" loading="lazy">

    <div class="store_modifier_title">{{curModifier.title}}</div>
  </div>

  <div class="close_icon_style" (click)="closeSubDialog()">
    <i class="oda-times close_icon_color"></i>
  </div>

  <div class="modifierGrp_tab_container" *ngIf="curModifier != null" #menu_nav_bar>
    <div class="p-d-flex modifierGrp_tab_bar">
      <div class="p-d-flex inner-tab" *ngFor="let item of curModifier.subModifierGrps; let i = index" [id]="'ITM' +  item.code" (click)="scrollToSection(item.code)">
        <div class="modifierGrp_tab_tag" [ngClass]="{'section-done-padding': item.isComplete}">
          <div class="section_number_tag" *ngIf="item.isComplete == undefined || item.isComplete == false"
          [ngClass]="{'current-section-tag': selectedSection == 'ITM' + item.code}">{{i + 1}}</div>
          <i class="oda-check-alt" *ngIf="item.isComplete == true"></i>
        </div>

        <div class="modifierGrp_tab_title">
          <label [ngClass]="{'current_tab_item' : selectedSection == 'ITM' + item.code, 'tab_title_default_tab': selectedSection != 'ITM' + item.code,
            'section-done-title': item.isComplete}">{{item.title}}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- container that is use to display submodifier -->
  <div class="modifier_container">
    <app-store-item-content
      [storeId]="storeId"
      [currencyCode]="currencyCode"
      [isSubModifier]="isSubModifier"
      [isMobileView]="isMobileView"
      [submodifierGrps]="curModifier.subModifierGrps"
      *ngIf="curModifier"
      (notifyBaseDisplayMaximum)="showMaximumMessage()">
    </app-store-item-content>

    <div style="height: 240px;"></div>
  </div>
</div>

<div class="store_submodifier_footer">
  <div style="height: 100%;">
    <button class="subModifier_continue_button" (click)="addToSubModifier()" [ngClass]="{'submodifier_footer_button_complete': allComplete == true}">
      {{"merchant.detail.button.confirm" | translate}}
    </button>
  </div>
</div>
