import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { DetailActivity } from './order.model';

export interface OrderState extends EntityState<DetailActivity>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'order' })
export class OrderStore extends EntityStore<OrderState, DetailActivity> {

  constructor() {
    super();
  }

}

