<div class="address-list-container">
  <div class="p-grid" [hidden]="isHideHeader">
    <div class="p-col-9 header-container">
      <h3 class="heading-2 ">{{'manageAdress.header.1' | translate}}</h3>
    </div>
    <button class="p-col-3 manage-add-btn-add" type="button" (click)="addNewAddress()" *ngIf="!isCartPage">
      <i class="oda-plus-circle"></i>
      <label class="hidden-sm">{{ 'button.add' | translate }}</label>
    </button>
  </div>

  <div *ngIf="savedAddress.length === 0; else elseBlock">
    <span class="heading-3">{{'alert.manageAddress.des.10' | translate}}</span>
  </div>

  <!--show saved address detail-->
  <ng-template #elseBlock>
    <div class="alert-message-container hidden-sm" *ngIf="updateAddressSuccess">
      <span><i class="oda-check"></i> {{'alert.manageAddress.des.11'| translate }}</span>
    </div>

    <div class="p-d-flex p-ai-center" style="cursor: pointer; position:relative" *ngFor="let address of savedAddress; last as isLast" (click)="selectAddres(address)">
      <div class="p-mr-2" style="margin-top: 20px">
        <div class="address-name-section">
          <h2 class="heading-10 address-name" *ngIf="address.addressIcon === addressType.home">
            <i class="p-mr-1 oda-d-favchannel duotone"></i>
            {{address.addressDesc}}
          </h2>
          <h2 class="heading-10 address-name" *ngIf="address.addressIcon === addressType.work">
            <i class="p-mr-1 oda-d-briefcase duotone"></i>
            {{address.addressDesc}}
          </h2>
          <h2 class="heading-10 address-name" *ngIf="address.addressIcon === addressType.other">
            <i class="p-mr-1 oda-d-star duotone"></i>
            {{address.addressDesc}}
          </h2>
          <div class="default-pill" *ngIf="address.defaultFlag">
            <label>{{'location.saved.address.category.default' | translate}}</label>
          </div>
        </div>
        <h3 class="heading-13 list-desc">{{address.delAddress}}</h3>

      </div>
      <div class="p-ml-auto">
        <div class="p-d-flex" *ngIf="!address.showDeleteButton && !isCartPage">
          <button class="manage-add-btn-edit" (click)="$event.stopPropagation(); editAddress(address)">
            <i class="oda-d-edit duotone"></i>
          </button>
          <button class="manage-add-btn-edit" (click)="$event.stopPropagation(); address.showDeleteButton = true">
            <i class="oda-d-trash duotone"></i>
          </button>
        </div>
        <div class="p-d-flex" *ngIf="address.showDeleteButton">
          <button class="address-cancel-button" (click)="$event.stopPropagation(); address.showDeleteButton = false">
            {{'button.cancel' | translate}}
          </button>
          <button class="address-delete-button" (click)="$event.stopPropagation(); deleteAddress(address)">
            {{'button.delete' | translate}}
          </button>
        </div>
      </div>
      <hr class="hidden-lg" style="width: 100%; position: absolute; bottom: -20px;" *ngIf="!isLast"/>
    </div>

  </ng-template>
</div>
