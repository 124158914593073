
<div class="p-d-flex p-jc-center order-completed-rate-container" *ngIf="order">
  <form [formGroup]="rateForm" (ngSubmit)="onSubmit()">
    <div class="p-d-flex p-flex-column ">
      <div class="order-completed-rate-content p-text-center">
        <img [src]="'assets/mascot/' + mascotImage" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
        <h1>{{'order.completed.rate.des.1' | translate: { storeName: order.storeInfo.locDesc } }}?</h1>
        <p-rating formControlName="score" [cancel]="false" (onRate)="onRate($event)"></p-rating>
      </div>

      <ng-template [ngIf]="showRateReview">
        <hr>
        <div class="selected-review-content p-text-center">
          <h1>{{ questionText | translate}}</h1>
          <div class="review-category-container">
            <div class="button-group" formArrayName="categories" *ngFor="let category of reviewCategory; let i = index">
              <input id="category-{{ category.id }}" type="checkbox" [value]="category.reviewText" (change)="onCategoryChanged($event, category.id)" />
              <label class="radio-btn not-selectable" for="category-{{ category.id }}">{{ category.reviewText | translate }}<span>{{ emoji }}</span></label>
            </div>
          </div>
        </div>

        <div class="selected-food-content p-text-center">
          <h1>{{'order.completed.rate.des.3' | translate}}</h1>
          <div class="food-list-container" formArrayName="items" *ngFor="let orderData of orderDatas; let i = index;">
            <div class="food-name" [innerHtml]="orderData.lineDesc"></div>
            <div class="button-group">
              <input type="radio" id="{{ orderData.itemCode }}-like" name="{{ orderData.itemCode }}" checked value="true" (click)="onItemChanged($event, orderData.itemCode)">
              <label class="thumb-btn" for="{{ orderData.itemCode }}-like"><i class="oda-thumb-up"></i></label>
              <input type="radio" id="{{ orderData.itemCode }}-dislike" name="{{ orderData.itemCode }}" value="false" (click)="onItemChanged($event, orderData.itemCode)">
              <label class="thumb-btn" for="{{ orderData.itemCode }}-dislike"><i class="oda-thumb-down"></i></label>
            </div>
          </div>
        </div>

        <div class="check-box-content">
          <label class="checkbox-container" for="anonymous">
            <input id="anonymous" type="checkbox" [value]="isAnonymous" (change)="onAnonymousChanged($event)" [checked]="isAnonymous" />
            <span class="checkmark"></span>
            <span>{{'order.completed.rate.des.4' | translate}}</span>
          </label>
        </div>

        <div class="bottom-button">
          <button type="submit" class="btn-submit">{{'button.submit' | translate}}</button>
        </div>
      </ng-template>
    </div>
  </form>
</div>

