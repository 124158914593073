<div class="order-paid-msg-container">
  <div class="icon-container">
    <i class="oda-check-alt" *ngIf="isOrderPaid"></i>
    <i class="oda-alert-alt" *ngIf="!isOrderPaid"></i>
  </div>

  <h3 class="heading-11 order-paid-title" *ngIf="isOrderPaid">{{"qr.order.summary.paid.des.1" | translate}}</h3>
  <h3 class="heading-11 order-paid-title" *ngIf="!isOrderPaid">{{"cart.empty.title.1" | translate}}</h3>

  <p class="heading-9 order-paid-description" *ngIf="isOrderPaid">{{"qr.order.summary.paid.des.3" | translate}}</p>
  <p class="heading-9 order-paid-description" *ngIf="!isOrderPaid">{{"cart.empty.des.1" | translate}}</p>

  <div class="order-paid-action-button-container">
    <button class="order-paid-action-button" (click)="onClickOk()">{{"button.ok" | translate}}</button>
  </div>
</div>
