<!-- modifier/submodifier card container -->
<div class="p-d-flex content_container"
  [ngClass]="{'container_when_image_available': curMod.thumbnail != null,'border_when_qty_not_zero': curMod.qty > 0}"
  (click)="isSubModifier == false ? onClickItem(curModGrp, curMod) : onClickSubModItem(curModGrp, curMod)">

  <!-- modifier/submodifier thumbnail -->
  <div class="modifiers_thumbnail_container" *ngIf="curMod.thumbnail != null">
    <img class="modifiers-thumbnail" [src]="curMod.thumbnail" loading="lazy" alt="store image"/>
    <span class="unavailable-overlay" *ngIf="curMod.status == itemStatus.Unavailable">{{"merchant.card.status.unavailable" | translate}}</span>
  </div>

  <div class="p-d-flex p-flex-column mod-info-container">
    <div class="p-d-flex">
      <!-- modifier/submodifier info area -->
      <div class="p-d-flex p-flex-column modifier-title-container" [style.opacity]="curMod.status == itemStatus.Available ? '1' : '0.5'"
      (click)="subModifiersClickEventCheck(curMod); $event.stopPropagation()">
        <!-- modifier/submodifier name/title -->
        <div class="title-remark-container">
          <span class="modifier-title" [ngClass]="{'modifier-title-active': curMod.qty > 0}" [innerHtml]="curMod.title"></span>
          <div class="modifier-desc" *ngIf="curMod.description && curMod.qty > 0">{{curMod.description}}</div>
        </div>

        <!-- modifier customize button -->
        <div class="p-d-flex" class="customize_container" *ngIf="hasSubModifierGrp(curMod)">
          <span class="customize_button">{{"menu.selection.customize" | translate}}</span>
          <span style="color:#EB3600;" *ngIf="checkIfSubModifierIsRequired(curMod)">&#42;</span>
        </div>
      </div>

      <div class="p-ml-auto p-d-flex picker-container">
        <div class="p-d-flex p-flex-column heading-9">
          <!-- modifier/submodifier price -->
          <span style="text-align: right;" [style.margin-bottom]="curMod.qty > 0 ? '4px' : '0px'"
            [style.margin-bottom]="(curMod.qtyBalance || curMod.qtyBalance == 0) && curMod.status == itemStatus.Available ? '4px' : '0px'"
            [style.opacity]="curMod.status == itemStatus.Available ? '1' : '0.5'"
            *ngIf="curMod.price | modifierPrice : curMod" [ngClass]="{'modifier_price_when_got_qty': curMod.qty > 0}">
            +{{curMod.price | modifierPrice : curMod | currency : currencyCode : 'symbol-narrow'}}
          </span>

          <div class="itemList-itemAvailability-qty-container">
            <!-- <span> {{ 'item.quantity.availability' | translate:{ qtyBalance: qtyBalance > 99 ? '99+': qtyBalance } }}</span> -->
            <!--item quantity availability-->
            <div class="itemQty-container" *ngIf="(curMod.qtyBalance || curMod.qtyBalance == 0) && curMod.status == itemStatus.Available">
             <span *ngIf="!balance"> {{ 'item.quantity.availability' | translate:{ qtyBalance: curMod.qtyBalance > 99 ? '99+': curMod.qtyBalance } }}</span>
             <span *ngIf="balance"> {{ 'item.quantity.availability' | translate:{ qtyBalance:  curMod.qtyBalance - (curMod.qty * tempSelectedQty) > 99 ? '99+':  curMod.qtyBalance - (curMod.qty * tempSelectedQty) } }}</span>
            </div>

            <div class="p-d-flex modifier_quantity_container" style="height: 100%;"
              [style.margin-left]="curMod.qtyBalance >= 0 ? '8px' : '0px'"
              *ngIf="curMod.qty | modQuantityCondition : curMod : curModGrp : false">

            <!-- minus button -->
            <div class="modifier_minus_button">
              <p-tag [rounded]="true" (click)="isSubModifier == false? minusModifierQuantity(curModGrp, curMod) : minusSubModifierQuantity(curModGrp, curMod) ; $event.stopPropagation()"><i class="oda-minus"></i></p-tag>
            </div>

            <!-- modifier/submodifier current quantity -->
            <div class="modifier_quantity_indicator" style="min-width: 19px;">
              {{curMod.qty}}
            </div>

            <!-- plus button -->
            <div class="plus_button" (click)="isSubModifier == false? plusModifierQuantity(curModGrp, curMod) : plusSubModifierQuantity(curModGrp, curMod) ; $event.stopPropagation()"
            [ngClass]="{'plus_button_when_max': ((curModGrp.qtyGrp == curModGrp.maxSelect) || (curMod.qty == curMod.maxSelect))}" style="margin-top: 3px;">
              <i class="oda-plus" [ngClass]="{'plus_icon_when_max': ((curModGrp.qtyGrp == curModGrp.maxSelect) || (curMod.qty == curMod.maxSelect))}"></i>
            </div>
          </div>

          <!-- modifier/submodifier quantity if it only allow one -->
          <div class="one_item_only" *ngIf="curMod.qty | modQuantityCondition : curMod : curModGrp : true"
          [style.margin-left]="curMod.qtyBalance >= 0 ? '8px' : '0px'">
              x {{curMod.qty}}
            </div>
          </div>

          <span class="unavailable-desc" *ngIf="curMod.status == itemStatus.Unavailable">{{"merchant.card.status.unavailable" | translate}}</span>
        </div>
      </div>

    </div>

    <div class="selected-subMod-container" *ngIf="hasSubModifierGrp(curMod)">
      <!-- list of submodifiers chosen -->
      <div class="heading-12 selected-subMod-content-container" (click)="onChangeSubModifier(curMod); $event.stopPropagation()" *ngIf="selectedSubModifier(curMod)">
        <div class="selected-subMod-content" *ngFor="let submod of curMod | formatSubmodifiers">
          <div>
            -&nbsp;
          </div>
          <div class="info-container">
            <span *ngIf="submod.qty > 1">{{submod.qty}}x </span>
            {{submod.title}}
            <span class="selected-subMod-price" *ngIf="submod.price > 0">+{{(submod.qty * submod.price) | currency: currencyCode : 'symbol-narrow'}}</span>
            <span class="subMod-itemQty-container" *ngIf="(submod.qtyBalance || submod.qtyBalance == 0) && !balance && submod.status == itemStatus.Available">{{'item.quantity.availability' | translate:{ qtyBalance: submod.qtyBalance > 99 ? '99+': submod.qtyBalance } }}</span>
            <span class="subMod-itemQty-container" *ngIf="(submod.qtyBalance || submod.qtyBalance == 0) && balance && submod.status == itemStatus.Available">{{'item.quantity.availability' | translate:{ qtyBalance: submod.qtyBalance - (submod.qty * tempSelectedQty) > 99 ? '99+': submod.qtyBalance - (submod.qty * tempSelectedQty) } }}</span>
          </div>
        </div>
      </div>

      <!-- required message for modifier/submodifier -->
      <div *ngIf="!checkIfModComplete(curMod)" class="customize-error-msg">
        <i class="oda-alert p-mr-1" style="font-size: 15px;"></i>
        <span class="heading-12">{{"alert.menu.selection.required" | translate}}</span>
      </div>
    </div>
  </div>

  <!-- maximum quantity reached alert animation(desktop) -->
  <div class="p-d-flex error-message-container" *ngIf="curMod.isMax" @myInsertRemoveTrigger (@myInsertRemoveTrigger.done)="animationDone($event, curMod)">
    <div class="error-message">
      <i class="oda-alert p-mr-1" style="font-size: 15px;"></i>
      <span class="heading-11">{{"merchant.menu.choice.max" | translate}}</span>
    </div>
  </div>

</div>
