<!--total amount -detail -->
<div class="order-amount-container" *ngIf="currentOrderData" [ngClass]="{ 'coloredBg': isOrderSummary }">
  <div class="p-d-flex">
    <div class="charge-heading">{{"order.summary.card.subtotal" | translate}}</div>
    <div class="p-ml-auto total-heading">{{currentOrderData.grossAmt | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
  </div>

  <div class="p-d-flex" *ngFor="let discountItem of currentOrderData.discountList">
    <div class="charge-heading">{{discountItem.name}}</div>
    <div class="p-ml-auto total-heading">-{{discountItem.amount| currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
  </div>

  <div *ngIf="currentOrderData.orderTSes != null">
    <div class="p-d-flex p-flex-column" *ngFor="let orderTs of excludedTax">
      <div class="p-d-flex">
        <div class="charge-heading">{{orderTs.taxShortDesc}}</div>
        <div class="p-ml-auto total-heading" *ngIf="orderTs.totalTaxAmt > 0 || orderTs.totalTaxAmt == 0">{{orderTs.totalTaxAmt | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
      </div>

      <div class="p-d-flex" *ngIf="orderTs.waivedFlag">
        <div class="charge-heading">{{orderTs.taxShortDesc}}</div>
        <div class="p-ml-auto total-heading">{{-orderTs.totalTaxAmt | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
      </div>
    </div>
  </div>

  <div class="p-d-flex" *ngIf="currentOrderData.adjAmt < 0 || currentOrderData.adjAmt > 0">
    <div class="charge-heading">{{"order.summary.rounding.adj" | translate}}</div>
    <div class="p-ml-auto total-heading">{{ currentOrderData.adjAmt | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
  </div>

  <!--grand total-->
  <div class="p-d-flex">
    <div class="net-total-heading">{{"order.summary.card.total" | translate}}</div>
    <div class="p-ml-auto net-total-heading">{{currentOrderData.netTotal | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
  </div>

  <!-- inclusive tax -->
  <div class="p-d-flex" *ngFor="let tax of includedTax">
    <div class="charge-heading">{{tax.taxShortDesc}}</div>
    <div class="p-ml-auto total-heading">{{tax.totalTaxAmt | currency:currentOrderData.currCode : 'symbol-narrow'}}</div>
  </div>

  <!-- total saving -->
  <div class="p-d-flex" *ngIf="currentOrderData.discountList && currentOrderData.discountList.length > 1">
    <div class="charge-heading">{{"order.summary.card.total.saving" | translate}}</div>
    <div class="p-ml-auto total-heading">(-{{currentOrderData.savingAmt | currency:currentOrderData.currCode : 'symbol-narrow'}})</div>
  </div>

  <!-- payment method -->
  <div class="p-d-flex" *ngFor="let orderPayment of orderPaymentDetailModel; let rowIndex = index">
    <div class="charge-heading" *ngIf="rowIndex == 0">{{"order.payment.header.2" | translate}}</div>
    <div class="p-ml-auto total-heading">{{ orderPayment.paymentMethod }} ({{orderPayment.localAmt | currency:currentOrderData.currCode : 'symbol-narrow'}})</div>
  </div>

</div>

