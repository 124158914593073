import { Component, OnInit, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

import { ActivitySummary, DetailActivity } from 'src/app/home/order/order.model';
import { OrderService } from 'src/app/home/order/order.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { ReviewService } from 'src/app/core/services/review.service';
import { PopupMessageService } from 'src/app/core/services/popup-message.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { LoaderService } from 'src/app/core/loader-services/loader.service';
import { ChannelReviewTextResponse } from 'src/app/core/models/ChannelReviewTextResponse';
import { ReviewCategoryType } from 'src/app/core/enums/ReviewCategoryType';
import { OrderD } from 'src/app/core/models/OrderD';
import { ChannelData } from 'src/app/core/models';
import { decodeBase64 } from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.scss']
})
export class MyOrderComponent implements OnInit, OnDestroy{
  @ViewChild("menuContainer") menuElement: ElementRef;

  activities: ActivitySummary[] = [];
  reviewCategory: ChannelReviewTextResponse[];
  order: DetailActivity;
  orderDatas: OrderD[];
  channelData: ChannelData;
  channelId: number;
  totalActivity: number = 0;
  skip: number = 0;
  isActive: boolean = true;
  showRate: boolean;
  isOnLoad: boolean = false;
  isFirstLoad: boolean = true;
  isMobile: boolean = false;
  isSticky: boolean = false;
  isLoggedIn: boolean = false;

  user$: Subscription;
  order$: Subscription;
  popupMessage$: Subscription;
  cacheGuestOrder: any = null;

  constructor(private orderService: OrderService,
    private userQuery: UserQuery,
    private reviewService: ReviewService,
    private popupMessageService: PopupMessageService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private channelService : ChannelService,
    private loaderService: LoaderService) {}

  async ngOnInit() {
    this.getScreenSize();

    this.user$ = this.userQuery.isLoggedIn$.subscribe(userData => {
      this.isLoggedIn = userData? true: false;

      if(!this.isLoggedIn && !this.isMobile) {
        this.router.navigate(['/login']);
      }
    });

    this.popupMessage$ = this.popupMessageService.onHide.subscribe( (name: string) => {
      if(name === "successRate") {
        this.onSwitchActivity(this.isActive, true);
      }
    });

    await this.channelService.getChannelDataCheck();
    this.channelData = this.channelService.getChannelData();
    this.channelId = this.channelData.channelId;

    let guestOrder = localStorage.getItem('guest-order');
    let decodedGuestOrder = guestOrder ? decodeBase64(guestOrder) : null;
    this.cacheGuestOrder = decodedGuestOrder ? JSON.parse(decodedGuestOrder) : null;

    if (this.isLoggedIn) {
      this.isActive = this.route.snapshot.firstChild.url[0].path === 'history'? false: true;
      await this.getActivities(0, this.isActive, this.channelData?.channelTag);

      if (this.cacheGuestOrder) {
        let combinedActivities = [];
        combinedActivities.push(this.cacheGuestOrder);
        combinedActivities = [...combinedActivities, ...this.activities];
        this.activities = combinedActivities;
      }
    } else {
      if (this.cacheGuestOrder) {
        this.activities.push(this.cacheGuestOrder);
      }
    }

    // Event when route changed on my order
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd && event.url === "/my-order") {
        let isActive = this.route.snapshot.firstChild.url[0].path === 'history'? false: true;
        this.onSwitchActivity(isActive);
      }
    });
  }

  onSwitchActivity(isActive: boolean, reLoad?: boolean) {
    if(this.isActive != isActive ||reLoad){
      let type = isActive? 'active': 'history';
      this.location.replaceState('/my-order/' + type);

      this.isActive = isActive;
      this.skip = 0;
      this.activities = [];
      this.getActivities(this.skip, isActive, this.channelData?.channelTag);
    }
  }

  onLoadActivity(isActive: boolean) {
    if(this.activities){
      this.isActive = isActive;
      this.getActivities(this.skip, isActive, this.channelData?.channelTag);
    }
  }

  onReload() {
    if(this.activities){
      this.skip = 0;
      this.getActivities(this.skip, this.isActive, this.channelData?.channelTag);
    }
  }

  async onShowRate(event: any) {
    if (!this.reviewCategory) {
      this.reviewCategory = <ChannelReviewTextResponse[]>await this.reviewService.getChannelReviewText(this.channelId, ReviewCategoryType.Food);
    }

    <DetailActivity[]>await this.orderService.getActivityDetail(event.activityId, true);
    this.orderService.getActiveOrder().subscribe((data: any) => {
      this.order = data? data : null;
    });

    if(this.reviewCategory && this.order) {
      this.showRate = event.isShow;
      this.reviewService.isShow.next(true);
    }
  }

  onSuccessRate(event: any) {
    this.showRate = event;
    this.reviewService.isShow.next(false);
  }

  onHideRate() {
    this.reviewService.isShow.next(false);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    // If not loaded all activity and not loading
    if(this.skip < this.totalActivity && !this.isOnLoad) {
      const reduce = this.isMobile? 0: 380;
      // Scrolled to bottom
      if( Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - reduce)) {
        this.onLoadActivity(this.isActive);
      }
    }

    let menuPosition = this.menuElement.nativeElement.getBoundingClientRect().top;
    this.isSticky = menuPosition <= 0 ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true: false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = event.target.innerWidth <= 991 ? true: false;
  }

  async getActivities(skip: number, isActive: boolean, channelTag?: string) {
    if(this.isFirstLoad) {
      this.loaderService.startLoading();
    } else {
      this.isOnLoad = true;
    }

    let resp = null;
    let result = null;
    let isComplete = null;
    const take = 10;

    resp = <ActivitySummary>await this.orderService.getActivitySummary(skip, take, isActive, isComplete, channelTag);

    if(resp instanceof(HttpErrorResponse)){
      this.activities = null;
      this.loaderService.stopLoading();
    } else {
      result = resp && resp['body']? resp['body'] : null;

      for(let i=0; i < result.length; i++) {
        let index = skip + i;
        this.activities[index] = result[i];
      }

      this.skip = this.skip + result.length;
      this.totalActivity = resp.headers.get('x-total-count');

      if(this.isFirstLoad) {
        this.loaderService.stopLoading();
        this.isFirstLoad = false;
      } else {
        this.isOnLoad = false;
      }
    }
  }

  ngOnDestroy() {
    this.user$?.unsubscribe();
    this.popupMessage$?.unsubscribe();
  }
}
