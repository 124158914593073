import { CurrentStatusFlag } from './../../../core/enums/CurrentStatusFlag';
import { CommonAddressResponse } from './../../../core/models/CommonAddressResponse';
import { AddressType } from './../../../core/enums/AddressType';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CXMCustomerAddressResponse } from './../../../core/models/CXMCustomerAddressResponse';
import { UserService } from 'src/app/core/user/user.service';
import { UserQuery } from 'src/app/core/user/user.query';
import { CartModel } from 'src/app/core/models/CartModel';
import { CartQuery } from 'src/app/core/cart/cart.query';
import * as _ from 'lodash';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/store/store/store.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { CartService } from 'src/app/core/cart/cart.service';
import { OtpService } from 'src/app/account/services/otp.service';
import { PageName } from 'src/app/core/enums';
import { ChannelSecService } from 'src/app/home/channelsec/channelsec.service';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @Input() savedAddress: CXMCustomerAddressResponse[];
  @Input() updateAddressSuccess: boolean;
  @Input() isCartPage : boolean = false;
  @Input() cartGuid : string = null;
  @Input() currentAddress : string;
  @Input() orderType : string;
  @Input() tableNoReq : boolean;
  @Output() displayAddAddressForm = new EventEmitter();
  @Output() editSelectedAddress = new EventEmitter();
  @Output() deleteSelectedAddress = new EventEmitter();
  @Output() closeDialog = new EventEmitter();
  @Output() recalculateCart = new EventEmitter();
  @Output() onQuitAddress = new EventEmitter();

  addressType = AddressType;
  isDeleteAddress: boolean = false;
  deleteAddressId: number;
  isUserLogin: boolean = false;
  currentRoute : string;
  currentStoreId : number;
  reqTime : string;
  channelId : number;
  channelTag : string;
  outOfService : boolean = false;

  cartModel : CartModel; //newly added to store cart data
  currentOrder : OrderH;
  customerId : number | string;
  recentAddress: CommonAddressResponse = null;
  channelSub$: Subscription = new Subscription();
  addressSub$: Subscription = new Subscription();
  selectedAddress: CommonAddressResponse;
  orderTypeFlag = OrderTypeFlag;

  isMobileView : boolean;

  constructor(
    private userService: UserService,
    private userQuery: UserQuery,
    private cartQuery : CartQuery,
    private channelService: ChannelService,
    private router : Router,
    private storeService : StoreService,
    private cartService : CartService,
    private otpService: OtpService,
    private channelSecService: ChannelSecService,
    private breakpointObserver : BreakpointObserver,
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  ngOnInit(): void {
    this.userQuery.isLoggedIn$.subscribe(userData => {
      if (userData) {
        this.isUserLogin = true;
        this.customerId = userData.customerId;
      } else {
        this.isUserLogin = false;
        this.customerId = "null";
      }
    });

    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    this.currentRoute = splittedRoutes[1];

    if(this.currentRoute == "store"){
      this.currentStoreId = +splittedRoutes[2];
    }

    let channelData = this.channelService.getChannelData();
    this.channelId = channelData.channelId;
    this.channelTag = channelData.channelTag;

    this.cartQuery.getCurrentCart().subscribe(val => {
      this.cartModel = val? _.cloneDeep(val.cartModel) : null;
    })

    if (localStorage.getItem('recent-address') === 'undefined') {
      localStorage.removeItem('recent-address');
    } else if (localStorage.getItem('recent-address')) {
      this.recentAddress = JSON.parse(localStorage.getItem('recent-address'));
    }

    this.channelSub$ = this.userService.recentAddress$.subscribe(data => {
      if (data) {
        this.recentAddress = localStorage.getItem('recent-address') && localStorage.getItem('recent-address') !== 'undefined' ?
          JSON.parse(localStorage.getItem('recent-address')) : null;
      }
    });

    this.addressSub$ = this.userQuery.selectedAddress$.subscribe(address => {
      this.selectedAddress = address;
    });
  }

  addNewAddressEmit(isRecentAddress: boolean) {
    if (this.isUserLogin) {
      this.displayAddAddressForm.emit(isRecentAddress ? this.recentAddress : this.selectedAddress);
    } else {
      localStorage.setItem('showAddressForm', 'true');
      this.closeDialog.emit(true);
      this.otpService.navigateToPage(PageName.LoginPage, {});
    }
  }

  editAddressEmit(address: CXMCustomerAddressResponse) {
    this.editSelectedAddress.emit(address);
  }

  deleteAddressEmit(addressId: string) {
    this.deleteSelectedAddress.emit(addressId);
  }

  async selectAddress(address: any) {
    // remove the channel section state
    this.channelSecService.removeAll();

    if(this.currentRoute == "store"){
      this.userService.updateSelectedAddress(address);
      this.storeService.addressChangeChecking$.next(true);
      this.closeDialog.emit(true);

    }
    else if(this.currentRoute == "cart"){
      let cartIndex : number;
      cartIndex = this.cartModel.orderHs.findIndex(val => val.cartGUID == this.cartGuid);

      let orderType = this.storeService.getOrderType(this.cartModel.orderHs[cartIndex].orderData.sourceFlag);

      let storeResp = await this.storeService.getStoreMenuData(this.cartModel.orderHs[cartIndex].storeId, orderType, this.channelId, this.cartModel.orderHs[cartIndex].orderData.orderC.reqTime, this.channelTag, address.longitude, address.latitude, false);

      if(storeResp.StoreResponse.currentStatus != CurrentStatusFlag.OutofCoverage){
        this.outOfService = false;

        this.cartModel.orderHs[cartIndex].orderData.orderC.address = address.fullAddress;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addCity = address.city;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addState = address.state;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addPostal = address.postalCode;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addCountry = address.country;
        this.cartModel.orderHs[cartIndex].orderData.orderC.longitude = address.longitude;
        this.cartModel.orderHs[cartIndex].orderData.orderC.latitude = address.latitude;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addUnit = address.unit;
        this.cartModel.orderHs[cartIndex].orderData.orderC.addressDesc = address.addressDesc ? address.addressDesc : null;
        this.cartModel.orderHs[cartIndex].orderData.orderC.remarks = address.remarks ? address.remarks : null;
        this.cartModel.orderHs[cartIndex].orderData.orderC.distanceKM = storeResp.StoreResponse.distance;

        this.recalculateCart.emit(this.cartModel);
        this.closeDialog.emit(true);
      }
      else{
        this.outOfService = true;
      }
    }
    else {
      await this.userService.updateSelectedAddress(address);
      if(this.currentRoute == "home") {
        this.channelService.getChannel();
      }
      this.closeDialog.emit(true);
    }

    // check if getchanneldata api coverageKM, if over show error message

    // this.closeDialog.emit(true);
  }

  selectCloseAddress(address: CommonAddressResponse) {
    this.currentAddress = address.fullAddress;
    this.selectAddress(address);
    this.closeDialog.emit(true);
  }

  selectRecentAddress(recentAddress: CommonAddressResponse) {
    this.currentAddress = recentAddress.fullAddress;
    localStorage.setItem('recent-address', JSON.stringify(this.selectedAddress));
    this.selectAddress(recentAddress);
  }

  ngOnDestroy() {
    this.channelSub$?.unsubscribe();
    this.addressSub$?.unsubscribe();
  }

  selectCloseLocation(outputEvent: any) {
    this.closeDialog.emit(true);
  }

  onCloseAddress(){
    this.onQuitAddress.emit(true);
  }

}
