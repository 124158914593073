<div class="submit-landing-content">
  <div class="mascot-container" *ngIf="channelId | isShowMascot">
    <img src="assets/mascot/Mascot_qr-dine-in_submit-order@2x.webp" alt="odaring mascot">
  </div>

  <div class="message-container">
    <h3 class="submit-message">{{"qr.landing.submit.order.desc" | translate}}</h3>
    <span class="loader"></span>
  </div>
</div>
