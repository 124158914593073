import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import SwiperCore, { Navigation } from "swiper";
SwiperCore.use([Navigation]);

import { ChannelSec } from 'src/app/home/channelsec/channelsec.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {
  @Input() channelSec: ChannelSec | null = null;
  @Output() onClickItm = new EventEmitter();
  @Input() newsData: any;

  dataInd: number = 0;

  formatedDataSet: any = [];

  imageUrl: string;
  title: string;
  subtitle: string;

  displayBannerList: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    // Set data
    this.newsData?.infoPanelDs.forEach((infoData) => {
      switch (infoData["sectionCode"]) {
        case "101": {
          this.title = infoData.sectionData;
          break;
        }
        case "102": {
          this.subtitle = infoData.sectionData;
          break;
        }
        case "103": {
          break;
        }
        case "201": {
          this.imageUrl = infoData.sectionData;
          break;
        }
      }
    });
  }

  onClickCard(infoUrl: string) {
    let newsUrl: URL = infoUrl? new URL(infoUrl): null;
    let currentUrl: URL = new URL(window.location.href);
    let newsUrlArray: string[] = newsUrl?.href && newsUrl.href.includes('/news')? newsUrl.href.split('/news'): null;
    let newsUrlParams: string[] = newsUrlArray && newsUrlArray[1]? newsUrlArray[1].split('/').slice(1): null;

    if(newsUrlArray && (currentUrl.href).includes(newsUrlArray[0]) && newsUrlParams[0] && newsUrlParams[1]) {
      this.router.navigate(["news", newsUrlParams[0], newsUrlParams[1]]);
    } else {
      window.location.href = infoUrl;
    }
  }

  showDialogBanner() {
    this.displayBannerList = true;
    const body = document.body;
    body.style.overflow = "hidden";
  }

  closeDialogBanner() {
    this.displayBannerList = false;
    const body = document.body;
    body.style.overflow = "auto";
  }
}
