import { StoreItemStatsQuery } from './store-item-stats.query';
import { StoreItemStatsStore } from './store-item-stats.store';
import { Injectable } from "@angular/core";
import { StoreItemStatsModel } from './store-item-stats.model';

@Injectable({ providedIn: 'root' })
export class StoreItemStatsService {
  constructor(
    private storeItemStatsStore : StoreItemStatsStore,
    private storeItemStatsQuery : StoreItemStatsQuery
  ){}

  getStoreItemStatsValue(storeId : number){
    return this.storeItemStatsQuery.getEntity(storeId);
  }

  getStoreItemStatsObservable(storeId : number){
    return this.storeItemStatsQuery.selectEntity(storeId);
  }

  getAlllStoreItemStats(){
    return this.storeItemStatsQuery.selectAll();
  }

  upsertStoreItemStats(storeId : number, storeItemStats : StoreItemStatsModel){
    this.storeItemStatsStore.upsert(storeId, storeItemStats);
  }

  removeStoreItemStats(storeId : number){
    this.storeItemStatsStore.remove(storeId);
  }
  removeAllStoreItemStats(){
    this.storeItemStatsStore.remove();
  }
}
