import { Component, Input } from '@angular/core';
import { ItemMenuTags } from 'src/app/core/models/ItemMenuTags';
import { StoreService } from '../store/store.service';
import { MenuTags } from 'src/app/core/enums/MenuTags';

export interface ImageSet {
  width: string,
  height: string,
  x?: string,
  y?: string,
  size?: string,
  uomX?: string,
  uomY?: string,
  uomSize?: string
}

@Component({
  selector: 'app-store-menu-tag',
  templateUrl: './store-menu-tag.component.html',
  styleUrls: ['./store-menu-tag.component.scss']
})
export class StoreMenuTagComponent {
  @Input() itemMenuTag: ItemMenuTags;

  simplifiedNutriSet: ImageSet = {
    width: '48px',
    height: "28px",
    x: '34',
    y: '12',
    size: "8px",
    uomX: '42',
    uomY: '12',
    uomSize: '4px'
  };

  fullNutriSet: ImageSet = {
    width: '80px',
    height: "35px",
    x: '66',
    y: '18',
    size: "8px",
    uomX: '73',
    uomY: '19',
    uomSize: '4px'
  };

  defaultSet: ImageSet = {
    width: '20px',
    height: "20px",
  };

  imageSet: ImageSet;

  constructor(
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    switch (this.itemMenuTag.code) {
      case MenuTags.NG01A:
      case MenuTags.NG01B:
      case MenuTags.NG01C:
      case MenuTags.NG01D:
        this.imageSet = this.fullNutriSet;
        break;
      case MenuTags.NG01AS:
      case MenuTags.NG01BS:
      case MenuTags.NG01CS:
      case MenuTags.NG01DS:
        this.imageSet = this.simplifiedNutriSet;
        this.storeService.showNutriRemakImage(); // subscribe the value to show the nutri remark image
        break;
      default:
        this.imageSet = this.defaultSet;
        break;
    }
  }
}
