import { QrScannerService } from './../../../core/services/qr-scanner.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserQuery } from 'src/app/core/user/user.query';
import { BarcodeFormat } from '@zxing/library';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { Channel } from 'src/app/home/channel/channel.model';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { PlatformSet } from 'src/app/core/models';
import { SetCode } from 'src/app/core/enums/SetCode';

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {

  @Input() isGeneralScan: any;
  @Input() showHeaderDesc : boolean;
  @Input() channelId : number;
  @Output() permissionResponse = new EventEmitter<boolean>();
  @Output() closeDialog = new EventEmitter<boolean>();

  isLoggedIn: boolean = undefined;
  user$: Subscription;
  displayScannerQR: boolean = false;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  qrResultString: string;
  hasDevices: boolean;
  hasPermission: boolean;

  selectedChannel: Channel;
  channelSub$: Subscription = new Subscription();
  orderTypeFlag = OrderTypeFlag;
  tableNoReq: boolean = false;
  channelSetting: PlatformSet[] = [];
  enlargingDialog: boolean = false;

  constructor(
    private userQuery: UserQuery,
    private staticQrService: StaticQrService,
    private router : Router,
    private qrScannerService : QrScannerService,
    private channelQuery: ChannelQuery,
  ) { }

  ngOnInit(): void {
    this.user$ = this.userQuery.isLoggedIn$.subscribe(userData => {
      this.isLoggedIn = userData && userData.id != 999 ? true : false;
    });

    this.channelSub$ = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      this.selectedChannel = channel;
      this.channelSetting = channel?.data?.platformSets ? channel?.data?.platformSets : [];
      let reqTblNoSetting = this.channelSetting.find(setting => setting.setCode === SetCode.DIREQTBLNO)?.setValue;
      this.tableNoReq = reqTblNoSetting && reqTblNoSetting == '1' ? true : false;
    });

  }

  showQRDialog() {
    this.displayScannerQR = true;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);

    for (const device of devices) {
      if (/back|rear|environment/gi.test(device.label)) {
        this.currentDevice = device;
        break;
      }
    }
  }

  private setPermission(hasPermission: boolean | null) {
    this.hasPermission = hasPermission;
    this.permissionResponse.next(hasPermission);
    return this.permissionResponse;
  }


  async onScanResultClick(resultString: string) {
    this.qrResultString = resultString;
    if (this.router.url == "/profiles" || this.isGeneralScan) {
      window.open(resultString, '_self');
    }
    else{
      await this.staticQrService.scanStaticQR(resultString);
      this.qrScannerService.hide();
    }
  }

  closeScannerDialog() {
    this.closeDialog.emit();
  }
}
