import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { OrderStore, OrderState } from './order.store';
import { DetailActivity } from './order.model';

@Injectable({
  providedIn: 'root'
})
export class OrderQuery extends QueryEntity<OrderState, DetailActivity> {

  constructor(protected order: OrderStore) {
    super(order);
  }
}
