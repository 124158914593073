import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { QueueData } from '../models/local/QueueData';

/**
 * Create initial state
 */
 export function createInitialState(): QueueData {
  return {
    rsvTokenId: undefined,
    storeData: undefined,
    queueResponse: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'queue-data' })
export class QueueDataStore extends Store<QueueData> {

  constructor() {
    super(createInitialState());
  }
}
