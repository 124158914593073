import { TooltipArrowPosition } from './../../../core/enums/TooltipArrowPosition';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TooltipService } from 'src/app/core/services/tooltip.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit, OnDestroy {

  mascot : string;
  message : string;
  button : string;
  arrowPosition : string;

  tooltipArrowPos = TooltipArrowPosition;
  displayTooltip : boolean;
  tooltipDisplaySub$ : Subscription;
  tooltipDataSub$ : Subscription;

  constructor(
    private tooltipService : TooltipService
  ) { }

  ngOnInit(): void {

    this.tooltipDisplaySub$ = this.tooltipService.showTooltip$.subscribe(val => {
      this.displayTooltip = val;
    })

    this.tooltipDataSub$ = this.tooltipService.tooltipData$.subscribe(data => {
      if(data){
        this.mascot = data.mascot;
        this.message = data.message;
        this.button = data.button ? data.button : null;
        this.arrowPosition = data.arrowPosition;
      }

    })
  }

  ngOnDestroy(){
    this.tooltipDisplaySub$.unsubscribe();
    this.tooltipDataSub$.unsubscribe();
  }

  closeTooltip(){
    this.tooltipService.close();
  }

}
