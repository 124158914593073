import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { VerifyEmailStatus } from 'src/app/core/enums/VerifyEmailStatus';
import { UserService } from 'src/app/core/user/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async (params: Params) => {
      let code = params['code'] ? params['code'] : '';
      let userid = params['userid'] ? params['userid'] : '';
      let subscriptionId = params['SubscriptionID'] ? params['SubscriptionID'] : '';

      let confirmEmailStatus = await this.userService.getConfirmEmailStatus(code, userid, subscriptionId);

      if (confirmEmailStatus instanceof TimeoutError) {

      } else if (confirmEmailStatus instanceof HttpErrorResponse || !confirmEmailStatus) {
        if (confirmEmailStatus.error && confirmEmailStatus.error.errorCode === ErrorCode.EmailVerificationLinkExpired_400) {
          this.userService.confirmEmailStatus = VerifyEmailStatus.Expired;
          this.router.navigate(["verifyemail-expired"]);
        } else {
          this.userService.confirmEmailStatus = VerifyEmailStatus.Failed;
          this.router.navigate(["verifyemail-failed"]);
        }
      } else {
        this.userService.confirmEmailStatus = VerifyEmailStatus.Success;
        this.router.navigate(["verifyemail-success"]);
      }
    });
  }

}
