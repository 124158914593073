import { StaticQrState } from 'src/app/core/static-qr/static-qr.model';
import { QueueResponse } from 'src/app/core/models/QueueResponse';
import { ChannelPlatformSetResponse } from './../../core/models/ChannelPlatformSetResponse';
import { AddItemObject } from './../../core/models/local/AddItemObject';
import { MenuCatg } from 'src/app/core/models/MenuCatg';
import { Component, Input, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuItem } from 'src/app/core/models/MenuItem';
import { StoreQuery } from 'src/app/store/store/store.query';
import * as _ from 'lodash';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { OrderMenu } from 'src/app/core/models/OrderMenu';
import { CartModel } from 'src/app/core/models/CartModel';
import { CartService } from 'src/app/core/cart/cart.service';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { AvailableTime } from 'src/app/core/models/AvailableTime';
import { StoreService } from 'src/app/store/store/store.service';
import { UserService } from 'src/app/core/user/user.service';
import { User } from 'src/app/core/user/user.model';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { QrCartQuery } from 'src/app/core/qr-cart/qr-cart.query';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LeadTimeType } from 'src/app/core/enums/LeadTimeType';
import { EntryMode } from 'src/app/core/enums/EntryMode';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { MenuService } from 'src/app/core/menu/menu.service';
import * as deepClone from 'rfdc';
import { Modifier } from 'src/app/core/models/Modifier';
import { MenuItemStatus } from 'src/app/core/enums/MenuItemStatus';
import { QueueCartService } from 'src/app/core/queue-cart/queue-cart.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { MembershipWithPointStampResponse } from 'src/app/membership/membership/membership-with-point-stamp-response';
import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';
import { MiniProgramService } from 'src/app/core/mini-program/mini-program.service';
import { TimeService } from 'src/app/core/services/time.service';
import { SetCode } from 'src/app/core/enums/SetCode';

@Component({
  selector: 'app-store-item-modifier',
  templateUrl: './store-item-modifier.component.html',
  styleUrls: ['./store-item-modifier.component.scss']
})
export class StoreItemModifierComponent implements OnInit, OnDestroy {

  //for scrolling and getting ids for scrolling
  @ViewChild('menu_nav_bar') mileStoneNavbar;
  @ViewChild('scrollContainer') scrollContainer : ElementRef;

  @Input() storeId: number;
  @Input() priceSymbol: string; //must not change input value
  @Input() orderLineGuid : string = null;
  @Input() qrTokenResponse : WebLinkTokenResponse;
  @Input() variationList : MenuItem[] = [];
  @Input() parentMenuItem : MenuItem = null;
  @Input() changeImageSetting : ChannelPlatformSetResponse;
  @Input() queueResponse : QueueResponse;
  @Input() staticQrData : StaticQrState;
  @Input() membershipResponse : MembershipWithPointStampResponse[];
  @Input() appLinkTokenResponse : AppLinkTokenResponse;
  @Input() isEditSelectedOrder : boolean = false;
  @Input() disableOrder: boolean = false;
  @Output() onCloseDialog = new EventEmitter();
  @Output() removeSelectedItem = new EventEmitter();
  @Output() displayMobileRemoveDialog = new EventEmitter();
  @Output() displayMaximumMessage = new EventEmitter();
  @Output() displayPreorderChanged = new EventEmitter();
  @Output() onClickItemImage = new EventEmitter();
  @Output() refreshMenu = new EventEmitter();

  //for controlling condition
  isStickyTitle: boolean = false;
  selectedSection: string;
  isMobileView: boolean;

  //for controlling scroll logic
  scrollMode: number = 2;
  allIds: string[];
  selectedElement: any;

  //menuItem
  menuItemForMutation: MenuItem;
  storeName : string;

  //store response extra
  storeResponse: StoreResponse;
  menuData: OrderMenu[] = [];
  exponent: number;
  currencyCode : string;
  currencySymbol : string;
  menuCatg : MenuCatg[];

  //confirmed time on scheduling
  confirmedTime : AvailableTime;

  //for required checking purpose
  allComplete : boolean = false;

  //for log in status
  user : User
  isLoggedIn : boolean = false;
  customerId : number | string;

  //subscription for unsubscribe in ngOnDestroy
  sub: Subscription;
  sub4 : Subscription;
  sub5 : Subscription;
  sub6 : Subscription;
  menuSub$ : Subscription;
  selectedItemSub$ : Subscription;
  updateModGrpSub$ : Subscription;

  //for add to cart
  cartModel : CartModel;
  displayRequestRemove : boolean = false;

  leadTimeType = LeadTimeType;

  //upsell list
  upSellList : MenuItem[] = [];
  upSellLoading : boolean = false;
  entryMode = EntryMode;
  upsellTimer : any;
  isUpSell : boolean = false;

  // remarks
  remarks : string = "";

  //variation
  isVariationChosen : boolean = true;
  isVariation : boolean = false;

  // collection of image
  itemImage : string[] = [];
  imageError : boolean = false;

  isCollapse : boolean = false;
  showRemarkLabel: boolean = true;

  //order qty availability
  curStoreCart: OrderH = null;

  isShowMaxPerTranError : boolean = false;
  isShowMinPerTranError : boolean = false;
  isBlockAddItemQty: boolean = false;
  balanceGreaterThanMaxQty: boolean = false;
  remaningQtyBalance: { [key: string]: number } = {};
  remaningMaxQtyPerTran: { [key: string]: number } = {};
  totalOrderQtyInCartPerItem: any;

  constructor(
    private storeQuery: StoreQuery,
    private cartService : CartService,
    private cartQuery : CartQuery,
    private storeService : StoreService,
    private userService : UserService,
    private qrCartQuery : QrCartQuery,
    private breakpointObserver : BreakpointObserver,
    private analyticsService: AnalyticsService,
    private menuService: MenuService,
    private queueCartService : QueueCartService,
    private miniProgramService : MiniProgramService,
    private timeService : TimeService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit() {
    this.sub = this.storeQuery.selectEntity(this.storeId).subscribe(val => {
      this.storeResponse = _.cloneDeep(val.storeResponse);
    })

    this.menuSub$ = this.menuService.getMenuObservable(this.storeId).subscribe(menu => {
      if(menu && menu.menuResponse){
        this.menuData = menu.menuResponse;
        this.menuCatg = deepClone({proto: true})(menu.menuResponse[0].menuSets[0].menuCatgs);
      }
    })

    this.exponent = this.menuData[0].currency.exponent; // to get the exponent for the filtering of price
    this.currencyCode = this.menuData[0]?.currency?.code ? this.menuData[0].currency.code : "MYR";
    this.currencySymbol = this.menuData[0]?.currency?.symbol ? this.menuData[0].currency.symbol : "RM";

    this.selectedItemSub$ = this.menuService.selectedMenuItem$.subscribe(menuItem => {
      this.menuItemForMutation = menuItem ? deepClone({proto: true})(menuItem) : null;
    })

    // notification to update menu item
    this.updateModGrpSub$ = this.menuService.updateSelectedMod$.subscribe(modGrp => {
      if(modGrp){
        this.updateModifier(modGrp);
      }
    })

    this.user = this.userService.getCustomer();
    this.isLoggedIn = this.user ? true : false;
    this.customerId = this.user?.customerId ? this.user.customerId: "null";

    // get cart for respective mode(e.g dynamic qr dine in, normal flow, queue, mini program)
    this.getCart();

    this.sub5 = this.storeQuery.selectEntity(this.storeId).subscribe(val => {
      this.confirmedTime = val.confirmedOrderTime;
    })

    this.sub6 = this.menuService.updateCustomizeItem$.subscribe(async updateData =>{
      if(updateData){
        this.updateCustomizeItem(updateData.curModifier, updateData.modGrpCode);
      }
    })

    // if got variation then set the first one as selected
    if(this.variationList.length > 0 && !this.orderLineGuid){
      // auto select the first available variation menu item
      let firstAvailableVariation = this.variationList.find(variation => variation.status === MenuItemStatus.Available);
      this.menuItemForMutation = _.cloneDeep(firstAvailableVariation);
      await this.setChosenVariation(this.menuItemForMutation);
      this.isVariation = true;
    }

    this.menuItemForMutation = await this.menuService.itemMinMaxCheck(this.menuItemForMutation);
    await this.initMenuitem(this.menuItemForMutation, true);

    let remarkLabel = this.storeResponse && this.storeResponse.platformSets.find(setting => setting.setCode == SetCode.HIDEODRREMARK);
    if(remarkLabel && remarkLabel?.setValue === "1" ) {
      this.showRemarkLabel = false
    }

    await this.getItemQty();
    if(this.menuItemForMutation.qtyBalance && this.menuItemForMutation.qtyBalance != 0 && this.menuItemForMutation.maxQtyPerTran && this.menuItemForMutation.maxQtyPerTran != 0) {
      this.balanceGreaterThanMaxQty = this.menuItemForMutation.qtyBalance > this.menuItemForMutation.maxQtyPerTran ? true : false;
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.sub4?.unsubscribe();
    this.sub5?.unsubscribe();
    this.sub6?.unsubscribe();
    this.menuSub$?.unsubscribe();
    this.selectedItemSub$?.unsubscribe();
    this.updateModGrpSub$?.unsubscribe();

    clearTimeout(this.upsellTimer);
  }

  async initMenuitem(menuItem : MenuItem, reInitParentItem : boolean = false){
    this.storeName = this.storeResponse.locDesc;
    this.remarks = menuItem.remarks ? menuItem.remarks : "";

    this.isVariationChosen = this.checkIfVariationIsChosen(menuItem, this.variationList);

    if(menuItem.upSellItems){
      this.upSellList = await this.cartService.findCrossSellMenuItem(this.menuCatg, menuItem.upSellItems);
    }
    else{
      this.upSellList = [];
    }

    if (menuItem.modifierGrps != null) {
      menuItem = await this.menuService.initializeItemDetails(menuItem);

      if((!this.orderLineGuid) || (this.orderLineGuid && (this.isUpSell || this.isVariation))){
        menuItem.itemQty = 1;
      }
      else{
        this.allComplete = true;
      }

      this.selectedSection = "ITM" + menuItem.modifierGrps[0].code;
    }
    else{

      if((!this.orderLineGuid )|| (this.orderLineGuid && (this.isUpSell || this.isVariation))){
        menuItem.itemQty = 1;
      }

      this.allComplete = true;
    }

    // trigger change detection;
    this.menuItemForMutation = deepClone({proto: true})(menuItem);
    this.checkRequiredSection();
  }

  // check if the current selected menu item is one of the variation(if it exists)
  checkIfVariationIsChosen(menuItem : MenuItem, variationList : MenuItem[]){
    let isChosen : boolean;
    if(variationList.length > 0){
      isChosen = variationList.some(item => item.itemCode == menuItem.itemCode);
    }
    else{
      isChosen = true;
    }
    return isChosen;
  }

  //#region cart retrieval
  retrieveCartCheck(){
    if(this.qrTokenResponse){
      return this.qrCartQuery.selectEntity(this.qrTokenResponse.linkId);
    }
    else if(this.queueResponse){
      return this.queueCartService.getCartByEntity(this.storeId);
    }
    else if(this.appLinkTokenResponse){
      return this.miniProgramService.getAppCartByEntityId(this.appLinkTokenResponse.linkId);
    }
    else{
      return this.cartQuery.getCurrentCart();
    }
  }

  async getCart(){
    this.sub4 = this.retrieveCartCheck().subscribe(cart => {
      if(cart){
        this.cartModel = _.cloneDeep(cart.cartModel);

        if(this.cartModel && this.cartModel?.orderHs && this.cartModel?.orderHs?.length > 0){
          this.curStoreCart = this.cartModel.orderHs.find(orderH => orderH.storeId == this.storeId);
          this.totalOrderQtyInCartPerItem = this.cartService.getTotalOrderItemIncart(this.curStoreCart);
        }
        else{
          this.curStoreCart = null;
        }

      }
      else{
        this.cartModel = null;
      }
    })
  }
  //#endregion

  closeDialog() {
    this.onCloseDialog.emit(true);
  }

  // check required section to see if all complete
  async checkRequiredSection(){
    if(!this.menuItemForMutation.modifierGrps){
      return;
    }

    let requiredCounter = 0;
    requiredCounter = this.menuItemForMutation.modifierGrps.filter(val => (val.isComplete == true) || (!val.isComplete && val.qtyGrp >= val.minSelect)).length;

    if(requiredCounter == this.menuItemForMutation.modifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }
  }

  async minusItemQuantity(){
    this.isBlockAddItemQty = false;

    if(this.menuItemForMutation.itemQty == 1 && !this.orderLineGuid){
      return;
    }
    else if(this.menuItemForMutation.itemQty == 1 && this.orderLineGuid){
      if(!this.isMobileView){
        this.displayRequestRemove = true;
      }
      else{
        this.displayMobileRemoveDialog.emit(this.orderLineGuid);
      }
      return;
    }

    this.menuItemForMutation.itemQty -= 1;

    // trigger change detection
    this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);
  }

  async plusItemQuantity(){
    let menuItem = this.menuItemForMutation;

    if (menuItem.qtyBalance && menuItem.qtyBalance != 0 && !menuItem.maxQtyPerTran) {
      this.isBlockAddItemQty = await this.isBlockQtyBalanceItem(menuItem, true, false, false);
      if(this.isBlockAddItemQty) {
        return;
      }
    }else if(!menuItem.qtyBalance && menuItem.maxQtyPerTran && menuItem.maxQtyPerTran != 0) {
      this.isBlockAddItemQty = await this.isBlockQtyBalanceItem(menuItem, false, true, false);
      if(this.isBlockAddItemQty){
        this.isShowMaxPerTranError = true;
        this.isShowMinPerTranError = false;
        setTimeout(() => {
          this.isShowMaxPerTranError = false;
        }, 5000);
        return;
      }
    }else if(menuItem.qtyBalance && menuItem.qtyBalance != 0 && menuItem.maxQtyPerTran && menuItem.maxQtyPerTran != 0){
      this.isBlockAddItemQty = await this.isBlockQtyBalanceItem(menuItem, false, false, true);
      if(menuItem.qtyBalance < menuItem.maxQtyPerTran && this.isBlockAddItemQty ) {
        return;
      }else if((menuItem.qtyBalance > menuItem.maxQtyPerTran || menuItem.qtyBalance == menuItem.maxQtyPerTran) && this.isBlockAddItemQty) {
        this.isShowMaxPerTranError = true;
        this.isShowMinPerTranError = false;
        setTimeout(() => {
          this.isShowMaxPerTranError = false;
        }, 5000);
        return;
      }
    }

    menuItem.itemQty += 1;

    // trigger manual change detection
    this.menuItemForMutation = deepClone({proto: true})(menuItem);

  }

  async addToCart(){
    this.scrollMode = 1;
    let sectionPrefix = "SECT";
    let itemPrefix = "ITM";

    let isIdle = await this.timeService.isIdleCheck(new Date());
    if(isIdle){
      this.onCloseDialog.emit({ toClose: true, isIdle: true });
      return;
    }

    // if variation exists and not chosen then return
    if(this.variationList.length > 0 && !this.isVariationChosen){
      return;
    }

    if(!this.orderLineGuid){
      let isAvailable = await this.checkItemAvailability();
      if(!isAvailable){
        this.refreshMenu.emit();
        return;
      }
    }

    if (this.menuItemForMutation.minQtyPerTran && this.menuItemForMutation.itemQty < this.menuItemForMutation.minQtyPerTran) {
      this.isShowMinPerTranError = true;
      this.isShowMaxPerTranError = false;
      setTimeout(() => {
        this.isShowMinPerTranError = false;
      }, 5000);
      return;
    }

    // check if modifier and submodifier is complete or not
    await this.checkIfInnerItemComplete();

    if(this.menuItemForMutation.modifierGrps){
      await this.checkIfAllComplete();
    }

    if(this.allComplete == false){
      let incompleteSection = this.menuItemForMutation.modifierGrps.filter(val => (val.isComplete == false) || (val.isComplete == undefined && val.qtyGrp < val.minSelect));
      this.selectedSection = itemPrefix + incompleteSection[0].code;
      this.selectedElement = sectionPrefix + incompleteSection[0].code;

      const el = document.getElementById(this.selectedElement);
      el.scrollIntoView({behavior: 'smooth'});

      setTimeout(() => {
        this.scrollMode = 2;
      }, 1000);
    }
    else{
      let addItemInput = {} as AddItemObject;
      addItemInput.menuItem = this.menuItemForMutation;
      addItemInput.menuItem.remarks = this.remarks ? this.remarks : "";
      addItemInput.menuItem.parentMenuItem = this.parentMenuItem ? this.parentMenuItem : null;
      addItemInput.menuData = this.menuData;
      if(this.appLinkTokenResponse){
        addItemInput.orderType = this.appLinkTokenResponse.orderTypeFlag;
        addItemInput.chosenTime = this.appLinkTokenResponse.reqTime;
      }
      else{
        addItemInput.orderType = this.storeResponse.currentOrderType;
        if(this.curStoreCart) {
          addItemInput.chosenTime = this.curStoreCart.orderData.orderC.reqTime ? this.curStoreCart.orderData.orderC.reqTime : null;
        }else {
          addItemInput.chosenTime = this.confirmedTime && this.confirmedTime?.chosenTime ? this.confirmedTime.chosenTime.value : null;
        }
      }

      addItemInput.distance = this.storeResponse.distance;
      addItemInput.menuRowVersion = this.storeResponse.menuRowVersion;
      addItemInput.storeId = this.storeId;
      addItemInput.storeName = this.storeName;
      addItemInput.qrTokenResponse = this.qrTokenResponse ? this.qrTokenResponse : null;
      addItemInput.cartModel = _.cloneDeep(this.cartModel);
      addItemInput.orderLineGuid = this.orderLineGuid ? this.orderLineGuid : null;
      addItemInput.queueResponse = this.queueResponse ? this.queueResponse : undefined;
      addItemInput.staticData = this.staticQrData ? this.staticQrData : undefined;
      addItemInput.appLinkTokenResponse = this.appLinkTokenResponse ? this.appLinkTokenResponse : undefined;

      // process to remove object field that have undefined as value
      addItemInput = JSON.parse(JSON.stringify(addItemInput));

      if(this.membershipResponse && this.membershipResponse.length > 0 && (this.membershipResponse[0]?.membershipId || this.membershipResponse[0]?.membershipNo)){
        addItemInput.membershipResponse = this.membershipResponse[0];
      }

      let toClose = await this.cartService.addItemToCart(addItemInput);

      if(toClose){
        this.onCloseDialog.emit({ toClose: true, isIdle: false });
      }
    }
  }

  async scrollToSection(id) {
    this.scrollMode = 1;

    const prefix = "ITM";

    const indexs = Object.entries(this.mileStoneNavbar.nativeElement.children.item(0).children).map(val => (val[1] as any).getAttribute('id')).indexOf(prefix + id);
    this.selectedSection = this.mileStoneNavbar.nativeElement.children.item(0).children.item(indexs).getAttribute('id');
    this.selectedElement = "SECT" + id;
    let navItemEl = document.getElementById(prefix + id);

    this.horizontalScrolling(navItemEl, this.mileStoneNavbar.nativeElement);

    let counter = 0;

    for (let i = indexs; i > 0; i--) {
      let counter = 0;

      if(this.menuItemForMutation.modifierGrps[i - 1].qtyGrp >= this.menuItemForMutation.modifierGrps[i - 1].minSelect){
        this.menuItemForMutation.modifierGrps[i - 1].modifiers.forEach(val => {
          if(val.subModifierGrps){
            if(val.qty > 0){
              counter = val.subModifierGrps.filter(val => (val.isComplete == true) || (!val.isComplete && val.qtyGrp >= val.minSelect)).length;

              if(counter == val.subModifierGrps.length){
                val.isComplete = true;
              }
              else{
                val.isComplete = false;
              }
            }
            else{
              val.isComplete = true;
            }

          }
          else{
            val.isComplete = true;
          }
        })
      }
      else{
        this.menuItemForMutation.modifierGrps[i - 1].modifiers.forEach(val => {
          val.isComplete = false;
        })
      }

      let modComplete = this.menuItemForMutation.modifierGrps[i - 1].modifiers.every(val => val.isComplete == true);
      this.menuItemForMutation.modifierGrps[i - 1].isComplete = modComplete ? true : false;
    }

    let modComplete = this.menuItemForMutation.modifierGrps[indexs].modifiers.every(val => val.isComplete == true);
    this.menuItemForMutation.modifierGrps[indexs].isComplete = modComplete ? true : false;

    let requiredCounter = 0;
    requiredCounter = this.menuItemForMutation.modifierGrps.filter(val => (val.isComplete == true) || (!val.isComplete && val.qtyGrp >= val.minSelect)).length;

    if(requiredCounter == this.menuItemForMutation.modifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }

    // trigger change detection
    this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);

    const el = document.getElementById("SECT" + id);
    let amountToExclude = this.isMobileView ? 110 : 144
    this.scrollContainer.nativeElement.scrollTo({
      top: el.offsetTop - amountToExclude,
      behavior: 'smooth'
    });

    setTimeout(() => {
      this.scrollMode = 2;
    }, 600);

  }

  onScroll(event) {
    let descriptionContainer = this.isMobileView ? document.getElementById("description-container-mobile") : document.getElementById("description-container");
    let descriptionOffsetTop = descriptionContainer.offsetTop; //description container top value

    let stickyTitle = document.getElementById("sticky-title-container");
    let navBar = document.getElementById("menu_nav_bar");
    let stickyTitleHeight = stickyTitle.getBoundingClientRect().height;
    let titleBottom = (stickyTitle.offsetTop + stickyTitleHeight) - 0.5;

    if(event.target.scrollTop > descriptionOffsetTop){
      this.isStickyTitle = true;
      stickyTitle.style.visibility = "visible";
      if(navBar){
        navBar.style.top = titleBottom + "px";
      }
    }
    else{
      this.isStickyTitle = false;
      stickyTitle.style.removeProperty("top");
      stickyTitle.style.visibility = "hidden";
      if(navBar){
        navBar.style.removeProperty("top");
      }
    }

    let curEl = document.getElementById(this.selectedElement);

    if (curEl != null) {
      if (event.target.scrollTop + 144 != curEl.offsetTop && (event.target.scrollTop + 106) != curEl.offsetTop && (event.target.scrollTop + 120) != curEl.offsetTop && (event.target.offsetHeight + event.target.scrollTop < event.target.scrollHeight) && this.scrollMode == 1) {
        return;
      }
    }

    if(this.mileStoneNavbar){
      this.allIds = Object.entries(this.mileStoneNavbar.nativeElement.children.item(0).children).map(val => (val[1] as any).getAttribute('id'));
    }
    else{
      this.allIds = [];
    }

    let itemIds = this.allIds.map(val => val.split('ITM')[1]);
    let sectionPrefix = "SECT";
    let allItem = this.mileStoneNavbar ? this.mileStoneNavbar.nativeElement.children.item(0).children : [];
    let remark_offset = document.getElementById("remark_section");
    let navItemEl : HTMLElement;

    if (itemIds) {
      for (let i = 0; i < this.allIds.length; i++) {
        let el = document.getElementById(sectionPrefix + itemIds[i]);
        let elInFront = document.getElementById(sectionPrefix + itemIds[i + 1]);

        if (elInFront == null) {
          if (event.target.scrollTop >= (el.offsetTop - 144) && event.target.scrollTop < (remark_offset.offsetTop - 144)) {
            let counter = 0;

            if (this.selectedSection != this.allIds[allItem.length - 1]) {
              this.selectedSection = this.allIds[allItem.length - 1];
              navItemEl = document.getElementById(this.selectedSection);

              let selectedModifierGrp = this.menuItemForMutation.modifierGrps[allItem.length - 2];
              if(selectedModifierGrp){
                if(selectedModifierGrp.minSelect == 1 && selectedModifierGrp.modifiers.length == 1){
                  selectedModifierGrp.modifiers[0].isComplete = true;
                }
                else if(selectedModifierGrp.qtyGrp >= selectedModifierGrp.minSelect){
                  let modifiers = selectedModifierGrp.modifiers;
                  if (modifiers) {
                    modifiers.forEach(val => {
                      if(val.subModifierGrps){
                        if(val.qty > 0 && val.status == MenuItemStatus.Available){
                          counter = val.subModifierGrps.filter(val => (val.isComplete == true) || (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length;

                          if(counter == val.subModifierGrps.length){
                            val.isComplete = true;
                          }
                          else{
                            val.isComplete = false;
                          }
                        }
                        else{
                          val.isComplete = true;
                        }

                      }
                      else{
                        val.isComplete = true;
                      }
                    });
                  }
                }
                else{
                  selectedModifierGrp.modifiers.forEach(val => {
                    val.isComplete = false;
                  })
                }
              }

              let modComplete = selectedModifierGrp.modifiers.every(val => val.isComplete == true);
              selectedModifierGrp.isComplete = modComplete ? true : false;

              // trigger change detection
              this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);
              this.checkRequiredSection();
            }

          }
        }
        else {
          if (event.target.scrollTop >= (el.offsetTop - 144) && event.target.scrollTop < (elInFront.offsetTop - 144)) {
            let counter = 0;
            if (this.selectedSection != this.allIds[i]) {
              this.selectedSection = this.allIds[i];
              navItemEl = document.getElementById(this.selectedSection);

              if(this.menuItemForMutation.modifierGrps[i - 1]){
                if(this.menuItemForMutation.modifierGrps[i - 1].minSelect == 1 && this.menuItemForMutation.modifierGrps[i - 1].modifiers.length == 1){
                  this.menuItemForMutation.modifierGrps[i - 1].modifiers[0].isComplete = true;
                }
                else if(this.menuItemForMutation.modifierGrps[i - 1].qtyGrp >= this.menuItemForMutation.modifierGrps[i - 1].minSelect){
                  this.menuItemForMutation.modifierGrps[i - 1].modifiers.forEach(val => {
                    if(val.subModifierGrps){
                      if(val.qty > 0 && val.status == MenuItemStatus.Available){
                        counter = val.subModifierGrps.filter(val => (val.isComplete == true) || (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length;

                        if(counter == val.subModifierGrps.length){
                          val.isComplete = true;
                        }
                        else{
                          val.isComplete = false;
                        }
                      }
                      else{
                        val.isComplete = true;
                      }

                    }
                    else{
                      val.isComplete = true;
                    }
                  })
                }
                else{
                  this.menuItemForMutation.modifierGrps[i - 1].modifiers.forEach(val => {
                    val.isComplete = false;
                  })
                }

                let modComplete = this.menuItemForMutation.modifierGrps[i - 1].modifiers.every(val => val.isComplete == true);
                this.menuItemForMutation.modifierGrps[i - 1].isComplete = modComplete ? true : false;

                // trigger change detection
                this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);
                this.checkRequiredSection();
              }

            }
          }

        }
      }
    }

    if (this.scrollMode === 1) {
      return;
    }

    if(navItemEl){
      this.horizontalScrolling(navItemEl, this.mileStoneNavbar.nativeElement);
    }

  }

  horizontalScrolling(element : HTMLElement, parentElement : HTMLElement){
    let parentRect = parentElement.getBoundingClientRect();
    let marginToExclude = this.isMobileView ? 16 : 31; // left right padding to exclude since left and right didn't include this value

    let elRect = element.getBoundingClientRect();
    let elLeft = elRect.left - parentRect.left; //element left coordinates relative to parents
    let elRight = elRect.right - parentRect.right; //element right coordinates relative to parents

    //if element right relative to its parent container is more than 0
    if(elRight > 0){
      let diff = Math.ceil(elRight) + marginToExclude; //element right coordinates relative to it parent container plus right padding value
      this.mileStoneNavbar.nativeElement.children.item(0).scrollLeft += diff; //use the difference to plus into scroll left
    }
    //if element left coordinates relative to its parent container less than 0
    else if(elLeft < 0){
      let diff = Math.ceil(elLeft * -1) + marginToExclude; //element left coordinates in relative with its parent container plus left padding value
      this.mileStoneNavbar.nativeElement.children.item(0).scrollLeft -= diff; //use parent element scroll left to minus the difference
    }
  }

  //#region on click add to cart check all inner and outer modifier/submodifier if it is completed
  async checkIfInnerItemComplete(){
    if(this.menuItemForMutation.modifierGrps && this.menuItemForMutation.modifierGrps.length != 0){
      this.menuItemForMutation.modifierGrps = await this.checkModGrpComplete(this.menuItemForMutation.modifierGrps);
      this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);
    }
  }

  async checkModGrpComplete(modifierGrp : ModifierGrp[]){
    let modGrpLength = modifierGrp.length;
    for(let index = 0; index < modGrpLength; index++){
      if(modifierGrp[index].minSelect == 0){
        modifierGrp[index].modifiers = await this.setAllModComplete(modifierGrp[index].modifiers);
      }
      else{
        modifierGrp[index] = await this.normalModCheck(modifierGrp[index]);
      }

      let modComplete = modifierGrp[index].modifiers.every(val => val.isComplete == true);
      modifierGrp[index].isComplete = modComplete &&
      (modifierGrp[index].qtyGrp >= modifierGrp[index].minSelect || modifierGrp[index].minSelect == 0 || modifierGrp[index].modifiers.length == 1) ? true : false;
    }
    return modifierGrp;
  }

  async setAllModComplete(modifiers : Modifier[]){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      modifiers[index].isComplete = true;
    }

    return modifiers;
  }

  async normalModCheck(modifierGrp: ModifierGrp){
    let counter = 0;

    let modLength = modifierGrp.modifiers.length;
    for(let index = 0; index < modLength; index++){
      if(modifierGrp.modifiers[index].subModifierGrps){
        if(modifierGrp.modifiers[index].qty > 0 && modifierGrp.modifiers[index].status == MenuItemStatus.Available){
          counter = modifierGrp.modifiers[index].subModifierGrps.filter(val => (val.isComplete == true) || (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length;

          if(counter == modifierGrp.modifiers[index].subModifierGrps.length){
            modifierGrp.modifiers[index].isComplete = true;
          }
          else{
            modifierGrp.modifiers[index].isComplete = false;
          }
        }
        else{
          modifierGrp.modifiers[index].isComplete = true;
        }
      }
      else{
        if(modifierGrp.minSelect == 1 && modifierGrp.modifiers.length == 1){
          modifierGrp.isComplete = true;
        }
        else if(modifierGrp.qtyGrp >= modifierGrp.minSelect || modifierGrp.modifiers[index].status == MenuItemStatus.Unavailable){
          modifierGrp.modifiers[index].isComplete = true;
        }
        else{
          modifierGrp.modifiers[index].isComplete = false;
        }
      }
    }

    return modifierGrp;
  }
  //#endregion

  async checkIfAllComplete(){
    let requiredCounter = 0;
    requiredCounter = this.menuItemForMutation.modifierGrps.filter(val => (val.isComplete == true) || (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length;

    if(requiredCounter == this.menuItemForMutation.modifierGrps.length){
      this.allComplete = true;
    }
    else{
      this.allComplete = false;
    }
  }

  onClickCancel(){
    this.displayRequestRemove = false;
  }

  onClickRemove(){
    this.removeSelectedItem.emit(this.orderLineGuid);
  }

  showMaximumMessage(){
    this.displayMaximumMessage.emit();
  }

  async onClickUpsellItem(menuItem : MenuItem){
    this.isUpSell = true;
    this.isVariation = false;
    this.upSellLoading = true;

    this.analyticsService.viewUpsellItemsEvent(menuItem, this.currencyCode, this.menuData[0].menuSets[0].id, this.storeId);

    // deep clone/copy of the new menu item to the current global menu item field
    this.menuItemForMutation = deepClone({proto: true})(menuItem);
    // do necessary init logic for current menu item
    await this.initMenuitem(this.menuItemForMutation);

    // generate menu variation menu item lists if it exists
    if(menuItem.variations && menuItem.variations?.length > 0){
      this.parentMenuItem = _.cloneDeep(menuItem);
      this.variationList = await this.cartService.findCrossSellMenuItem(this.menuCatg, menuItem.variations);
      if(this.variationList.length > 0){
        this.variationList = await this.storeService.variationImageCheck(this.variationList);
      }
    }
    else{
      this.parentMenuItem = null;
      this.variationList = [];
    }

    this.isStickyTitle = false;

    this.upsellTimer = setTimeout(() => {
      this.upSellLoading = false;
    }, 1000);
  }

  show3DModelDialog(isShow: boolean) {
    this.closeDialog();
    this.storeService.item3DModel$.next({isShow: isShow, menuItem: this.menuItemForMutation});
  }

  async onClickVariationItem(menuItem : MenuItem){
    if (menuItem.status !== MenuItemStatus.Unavailable) {
      this.isVariation = true;
      this.isUpSell = false;

      this.analyticsService.viewVariationItemsEvent(menuItem, this.currencyCode, this.menuData[0].menuSets[0].id, this.storeId, this.parentMenuItem.itemCode);

      // set global menu item to the chosen menu variation item
      this.menuItemForMutation = deepClone({proto: true})(menuItem);
      // set current chosen variation to active(chosen)
      await this.setChosenVariation(this.menuItemForMutation);
      // init current menu item
      await this.initMenuitem(this.menuItemForMutation);
    }
  }

  async setChosenVariation(menuItem : MenuItem){
    this.variationList.forEach(item => {
      if(item.itemCode == menuItem.itemCode){
        item.isChosenVariation = true;
      }
      else{
        item.isChosenVariation = false;
      }
    })
  }

  // update modifier when add or minus operation is done
  async updateModifier(modifierGrp : ModifierGrp){
    const index = this.menuItemForMutation.modifierGrps.findIndex(val => val.code == modifierGrp.code);
    this.menuItemForMutation.modifierGrps[index] = modifierGrp;

    this.menuItemForMutation.modifierGrps = await this.loopThroughModGrpIsMax(this.menuItemForMutation.modifierGrps);

    // deep clone again to trigger change detection
    this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);

    this.checkRequiredSection();
  }

  async loopThroughModGrpIsMax(modifierGrps : ModifierGrp[]){
    let modGrpLength = modifierGrps.length;
    for(let index = 0; index < modGrpLength; index++){
      modifierGrps[index].modifiers = await this.removeAllIsMaxFlag(modifierGrps[index].modifiers);
    }
    return modifierGrps;
  }

  async removeAllIsMaxFlag(modifiers : Modifier[]){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      modifiers[index].isMax = false;
    }
    return modifiers;
  }

  // update specific modifier when click confirm during customization
  async updateCustomizeItem(modifier: Modifier, modGrpCode : string){
    let modGrpLength = this.menuItemForMutation.modifierGrps.length;
    let modGrpIndex : number = -1;
    for(let index = 0; index < modGrpLength; index++){
      if(this.menuItemForMutation.modifierGrps[index].code == modGrpCode){
        modGrpIndex = index;
        this.menuItemForMutation.modifierGrps[index].modifiers = await this.updateCustomizeModifier(this.menuItemForMutation.modifierGrps[index].modifiers, modifier);
      }
    }

    // check current modgrp to see if it meet its requirement
    if(modGrpIndex != -1){
      this.menuItemForMutation.modifierGrps[modGrpIndex] = await this.menuService.checkIndividualModGrp(this.menuItemForMutation.modifierGrps[modGrpIndex]);
    }

    // // trigger change detection
    this.menuItemForMutation = deepClone({proto: true})(this.menuItemForMutation);
    this.checkRequiredSection();
  }

  async updateCustomizeModifier(modifiers : Modifier[], newModifier : Modifier){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      if(modifiers[index].itemCode == newModifier.itemCode){
        modifiers[index].qty = newModifier.qty;
        modifiers[index].isComplete = true;
        modifiers[index].subModifierGrps = newModifier.subModifierGrps;
      }
    }
    return modifiers;
  }

  clickEnlargeItemImage(){
    if(this.imageError){
      return;
    }

    this.itemImage = [];
    let currentMenuItem : MenuItem;

    if(this.parentMenuItem && ((this.changeImageSetting && this.changeImageSetting?.setValue == '1') || !this.menuItemForMutation.thumbnail)){
      currentMenuItem = this.parentMenuItem;
    }
    else{
      currentMenuItem = this.menuItemForMutation;
    }

    if(!currentMenuItem.thumbnail || currentMenuItem.thumbnail == "assets/image-unavailable.svg"){
      return;
    }

    // currently only one image, later when added multi image then need to cater multi image logic
    this.itemImage.push(currentMenuItem.thumbnail);
    this.onClickItemImage.emit(this.itemImage);
  }

  emptyEvent(event: MouseEvent) {
    event.stopPropagation();
  }

  imageCantLoad(){
    this.imageError = true;
  }

  expandCollapseControl(){
    this.isCollapse = !this.isCollapse;
  }

  async checkItemAvailability(){
    let isAvailable : boolean;
    let reqTime : string;
    let curStoreCart : OrderH;

    if(this.cartModel && this.cartModel?.orderHs && this.cartModel?.orderHs?.length > 0){
      curStoreCart = this.cartModel.orderHs.find(orderH => orderH.storeId == this.storeId);
    }
    else{
      curStoreCart = null;
    }

    if(curStoreCart){
      reqTime = curStoreCart.orderData.orderC.reqTime;
    }
    else if(this.confirmedTime && this.confirmedTime?.chosenTime && this.confirmedTime?.chosenTime?.value){
      reqTime = this.confirmedTime.chosenTime.value;
    }
    else{
      reqTime = null;
    }

    isAvailable = await this.storeService.isItemAvailable(this.menuItemForMutation, reqTime);
    return isAvailable;
  }

  async getItemQty() {
    let totalOrderQtyInCartPerItem: any;
    if(this.curStoreCart && this.curStoreCart.orderData && this.curStoreCart.orderData.orderDs && this.curStoreCart.orderData.orderDs.length != 0) {
      totalOrderQtyInCartPerItem = await this.cartService.getTotalOrderItemIncart(this.curStoreCart);
    }

    if(totalOrderQtyInCartPerItem && totalOrderQtyInCartPerItem.length != 0){
      const matched = totalOrderQtyInCartPerItem.find(val => val.itemCode === this.menuItemForMutation.itemCode);
      if(matched){
        if(this.menuItemForMutation.qtyBalance) {
          this.remaningQtyBalance[matched.itemCode] = this.menuItemForMutation.qtyBalance - matched.orderQty;
          if(this.isEditSelectedOrder){
            this.remaningQtyBalance[matched.itemCode] = this.remaningQtyBalance[matched.itemCode] + this.menuItemForMutation.itemQty;
          }
        }
        if(this.menuItemForMutation.maxQtyPerTran) {
          this.remaningMaxQtyPerTran[matched.itemCode] = this.menuItemForMutation.maxQtyPerTran - matched.orderQty;
          if(this.isEditSelectedOrder){
            this.remaningMaxQtyPerTran[matched.itemCode] = this.remaningMaxQtyPerTran[matched.itemCode] + this.menuItemForMutation.itemQty;
          }
        }
      }else {
        this.remaningQtyBalance[this.menuItemForMutation.itemCode] = this.menuItemForMutation.qtyBalance ?? 0;
        this.remaningMaxQtyPerTran[this.menuItemForMutation.itemCode] = this.menuItemForMutation.maxQtyPerTran ?? 0;
      }
    }else {
      this.remaningQtyBalance[this.menuItemForMutation.itemCode] = this.menuItemForMutation.qtyBalance ?? 0;
      this.remaningMaxQtyPerTran[this.menuItemForMutation.itemCode] = this.menuItemForMutation.maxQtyPerTran ?? 0;
    }
  }

  async isBlockQtyBalanceItem(menuItem: MenuItem, isQtyBalance: boolean, isMaxQty: boolean, isMaxAndBalanceQty: boolean): Promise<boolean> {
    let isBlockPlus: boolean = false;

    if(isQtyBalance) {
      if(menuItem.itemQty >= this.remaningQtyBalance[menuItem.itemCode]){
        isBlockPlus = true;
      }
    }else if(isMaxQty) {
      if(menuItem.itemQty >= this.remaningMaxQtyPerTran[menuItem.itemCode]){
        isBlockPlus = true;
      }
    }else if(isMaxAndBalanceQty) {
      if(menuItem.qtyBalance > menuItem.maxQtyPerTran || menuItem.qtyBalance == menuItem.maxQtyPerTran) {
       if( menuItem.itemQty >= this.remaningMaxQtyPerTran[menuItem.itemCode]){
        isBlockPlus = true;
       }
      }else if(menuItem.qtyBalance < menuItem.maxQtyPerTran){
       if(menuItem.itemQty >= this.remaningQtyBalance[menuItem.itemCode]){
        isBlockPlus = true;
       }
      }
    }
    return isBlockPlus;
  }
}
