import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email-failed',
  templateUrl: './verify-email-failed.component.html',
  styleUrls: ['./verify-email-failed.component.scss']
})
export class VerifyEmailFailedComponent implements OnInit {
  displayResendFailed: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private routingService: RoutingService,
  ) { }

  ngOnInit(): void {
  }

  async resendVerificationLink() {
    let emailVerify = await this.userService.sendEmailVerification();
    if (emailVerify instanceof HttpErrorResponse === false && emailVerify instanceof TimeoutError === false) {
      this.userService.sentVerifyEmail = true;
      this.router.navigate(["/verification-link-sent"], { state: { emailVerifyData: emailVerify } });
    } else {
      this.displayResendFailed = true;
    }
  }

  navigateBackToHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }
}
