<div class="externalMembershipLogin-container">
  <div class="membership-logo">
    <!--with card image-->
    <img [src]="cardImageData" (error)="onImgError()" *ngIf="cardImageData && this.cardImageError == false"
      class="card-image" />
    <!--with logo image-->
    <div class="logoImage-card" *ngIf="logoImageData && !cardImageData || this.cardImageError == true">
      <img [src]="logoImageData" onerror="this.src='assets/image-unavailable.svg'" />
      <h1 class="desc-heading">{{membershipDesc}}</h1>
    </div>
  </div>

  <h1 class="heading-5">{{'external.membership.login.header' | translate}}</h1>
  <p class="heading-9">{{'external.membership.login.desc' | translate}}</p>

  <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
    <!--phone number-->
    <div class="externalMembership-phoneNo-container">
      <h2 class="heading-16">{{ 'input.label.1' | translate }}<span class="asterisk">*</span></h2>
      <ngx-intl-tel-input
        [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
        [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
        [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
        [selectFirstCountry]="telInpSetting.selectFirstCountry"
        [phoneValidation]="telInpSetting.phoneValidation" [inputId]="'phoneNo'"
        [separateDialCode]="telInpSetting.separateDialCode" [preferredCountries]="telInpSetting.preferredCountries"
        [selectedCountryISO]="countryCode | telInputCountryCode" name="phone" formControlName="phoneNo" #phone_number autofocus>
      </ngx-intl-tel-input>
      <i class="oda-times" *ngIf="phone_number.value" (click)="onResetPhone()"></i>

      <!-- error message for phone number input box -->
      <div class="invalid-feeback" *ngIf="isInvalid">
        <i class="oda-alert"></i>
        {{ 'login.form.mobile.number.invalid' | translate }}
      </div>
    </div>

    <!--password-->
    <div class="externalMembership-password-container">
      <h2 class="heading-16">{{ 'input.label.2' | translate }}<span class="asterisk">*</span></h2>
      <input pPassword type="password" [type]="hide ? 'password' : 'text'" formControlName="password"
        placeholder=" {{'input.placeholder.1' | translate }} " [feedback]="false" #password />
      <button type="button" (click)="hide = !hide" class="login-btn-hide" *ngIf="f.password.value">
        <i class="{{hide ? 'oda-eye-shut':'oda-eye-open'}}"></i>
      </button>
      <i class="oda-times" *ngIf="password.value" (click)="onResetPassword()"></i>
    </div>

    <h1 class="invalid-feeback" *ngIf="showTextError"><i class="oda-alert"></i> {{errorText | translate}}</h1>

    <div class="externalMembership-rememberMe-container" *ngIf="isLoggedIn">
      <div class="check-box-content">
        <label class="checkbox-container" for="anonymous">
          <input id="anonymous" type="checkbox" formControlName="rememberAccount"
            (change)="remeberExternalMember($event)" />
          <span class="checkmark"></span>
          <span>{{'remember.me' | translate}}</span>
        </label>
      </div>
    </div>

    <!--login btn-->
    <button class="login-btn"
      [ngClass]="{'disabled-button': !f.phoneNo.value || !f.password.value || !f.password.valid }"
      [disabled]="!f.phoneNo.value || !f.password.value || !f.password.valid">{{'button.login'
      |translate}}</button>
  </form>
</div>
