<div class="resetMobileForm-container">
  <!--desktop header-->
  <h1 class="heading-5 hidden-sm">{{ 'resetMobile.form.header' | translate}}</h1>

  <!--mobile header-->
  <div class="hidden-lg reset-mobile-header">
    <app-back-button></app-back-button>
    <h1 class="heading-14">{{'resetMobile.form.header' | translate}}</h1>
  </div>

  <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
    <div class="p-float-label">
      <h1 class="heading-11">{{'input.label.1' | translate}}<span style="color: #EB3600">*</span></h1>
      <div class="mobileNo-container">
        <ngx-intl-tel-input [cssClass]="''" [enableAutoCountrySelect]="telInpSetting.enableAutoCountrySelect"
        [customPlaceholder]="telInpSetting.customPlaceholder | translate" [enablePlaceholder]="telInpSetting.enablePlaceholder"
        [searchCountryFlag]="telInpSetting.searchCountryFlag" [searchCountryField]="telInpSetting.searchCountryField"
        [selectFirstCountry]="telInpSetting.selectFirstCountry"
        [separateDialCode]="telInpSetting.separateDialCode" [phoneValidation]="telInpSetting.phoneValidation"
        [preferredCountries]="telInpSetting.preferredCountries" [selectedCountryISO]="telInpSetting.selectedCountryISO"
        (keydown)="onMobileNoKeydown($event)"
        name="phone" formControlName="mobileNo" #phone_number>
        </ngx-intl-tel-input>
        <button type="reset" class="resetMobileForm-btn-clear" *ngIf="f.mobileNo.value"><i class="oda-times"></i></button>
      </div>
      <div class="error" *ngIf="mobileNoRequired"><i class="oda-alert"></i>{{ "reset.phone.no.empty" | translate }}</div>
      <div class="error" *ngIf="isInvalid"><i class="oda-alert"></i>{{ "login.register.phoneno.error" | translate }}</div>
      <div class="error" *ngIf="mobileNoExist"><i class="oda-alert"></i>{{ "reset.phone.no.registered" | translate }}</div>
    </div>

    <button type="submit" class="resetMobileForm-btn-cta">{{ 'button.submit' | translate}}</button>
  </form>
</div>
