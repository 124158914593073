import { TimeService } from 'src/app/core/services/time.service';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { Component, OnInit } from '@angular/core';
import { PageName } from 'src/app/core/enums';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-invalid-qr',
  templateUrl: './invalid-qr.component.html',
  styleUrls: ['./invalid-qr.component.scss']
})
export class InvalidQrComponent implements OnInit {

  otpInfo$: Subscription;
  locDesc: string = "";
  storeImageUrl : string =""

  constructor(
    private qrCartService : QrCartService,
    private staticQrService : StaticQrService,
    private routingService : RoutingService,
    private channelService : ChannelService,
    private timeService : TimeService,
    private commonService : CommonService
  ) { }

  ngOnInit(): void {
    this.otpInfo$ = this.commonService.getExpiredQrErrorData().subscribe((dt:any) => {
      this.locDesc = dt && dt.locDesc? dt.locDesc: this.locDesc;
      this.storeImageUrl = dt && dt.storeImageUrl? dt.storeImageUrl: this.storeImageUrl
    })
  }

  async onClickBackHome(){
    let channelData = this.channelService.getChannelData();
    let switchChannelData = this.channelService.getSwitchChannelData();

    this.qrCartService.quitQrDineInCheck();
    this.timeService.removeIdleTime();
    this.staticQrService.remove();

    if(channelData){
      this.routingService.navigate(PageName.HomePage, channelData);
    }
    else if(switchChannelData){
      this.routingService.navigateHomeCheck(switchChannelData);
    }
    else{
      switchChannelData = await this.channelService.switchChannelVerify(false);
      this.channelService.setSwitchChannelData(switchChannelData);
      this.routingService.navigateHomeCheck(switchChannelData);
    }
  }

}
