import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Store } from './store.model';

export interface StoreState extends EntityState<Store> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'store' })
export class StoreStore extends EntityStore<StoreState, Store> {

  constructor() {
    super();
  }

}

