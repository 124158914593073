import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { ChannelData } from 'src/app/core/models';
import { VoucherExtra } from 'src/app/core/models/VoucherExtra';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { UseControlFlag } from 'src/app/core/enums/UseControlFlag';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { Subscription } from 'rxjs';
import { VoucherCatgWithVoucherList } from 'src/app/core/models/VoucherCatgWithVoucherList';
import { RewardService } from 'src/app/account/services/reward.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { OrderVoucher } from 'src/app/core/models/OrderVoucher';
import { OrderData } from 'src/app/core/models/OrderData';

@Component({
  selector: 'app-select-voucher',
  templateUrl: './select-voucher.component.html',
  styleUrls: ['./select-voucher.component.scss'],
})
export class SelectVoucherComponent implements OnInit, OnDestroy {
  @Input() storeId: number;
  @Input() applicableVouchers: VoucherCatgWithVoucherList[];
  @Input() selectedVouchers: OrderVoucher[];
  @Input() orderData?: OrderData;
  @Input() showEnterVoucherCode: boolean = true;

  @ViewChild("searchBar") searchBarEl: ElementRef;
  @ViewChild("searchCodeBar") searchCodeBarEl: ElementRef;
  @ViewChildren("content") contentListEl: QueryList<ElementRef>;
  @ViewChild("content") contentEl: ElementRef;

  channelData: ChannelData;
  applicableVouchersFilter: VoucherCatgWithVoucherList[];
  useControlFlag: any = UseControlFlag;
  cardTypes: any = VoucherCardType;
  rewardCatgDetail: VoucherCatgWithVoucherList;
  rewardDetail: VoucherExtra;

  isLoggedIn: boolean = false;
  isMobile: boolean = false;
  isOnLoad: boolean = false;
  showDetail: boolean = false;
  isVoucherActive: boolean = false;
  showCode: boolean = false;
  showShare: boolean = false;
  isCopied: boolean = false;
  searchText: string = '';
  searchCodeText: string = '';
  searchedCodeText: string = '';
  shareUrl: string = "";
  channelId: number;
  customerId: number;
  noOfCardHidden: number = 5;
  latitude: number = 3.0858793;
  longitude: number = 101.586459;
  skip: number = 0;
  take: number = 10;
  scrollPosition: number = 0;

  userLoggedIn$: Subscription;
  selectVoucher$: Subscription;
  detailDialog$: Subscription;
  userQuery$: Subscription;
  shareDialog$: Subscription;
  cart$: Subscription;
  order$: Subscription;

  constructor(private rewardService: RewardService,
    private channelService: ChannelService,
    private toastService: ToastService) { }

  async ngOnInit() {
      await this.channelService.getChannelDataCheck();
      this.channelData = this.channelService.getChannelData();
      this.channelId = this.channelData.channelId;
      this.applicableVouchersFilter = _.cloneDeep(this.applicableVouchers);

      if(this.rewardService.seeMoreData) {
        this.onShowMore(this.rewardService.seeMoreData.seeMore, this.rewardService.seeMoreData.vchCatgId);
      }

      let scrollPosition = this.rewardService.dialogScrollPosition;
      this.contentEl.nativeElement.scrollTo(0, scrollPosition);
  }

  onClearSearch() {
    this.searchText = "";
    this.searchBarEl.nativeElement.focus();
  }

  onShowSearchCode() {
    this.rewardService.selectDialog.next({isShow: false});
    this.rewardService.searchVoucherDialog.next({isShow: true});
  }

  onShowMore(isShowMore: boolean, vchCatgId: number) {
    let cardGrpEl = document.getElementById("card-group-" + vchCatgId);

    if(cardGrpEl) {
      cardGrpEl.classList.add(isShowMore? "show-more": "show-less");
      cardGrpEl.classList.remove(isShowMore? "show-less": "show-more");
      this.rewardService.seeMoreData = {seeMore: isShowMore, vchCatgId: vchCatgId};
    }
  }

  onApply() {
    this.rewardService.applyVoucher.next({selectedVoucher: this.selectedVouchers, storeId: this.storeId});
  }

  onBack() {
    this.rewardService.selectDialog.next({isShow: false});
  }

  onScroll(event: any){
    this.rewardService.dialogScrollPosition = event.target.scrollTop;
  }

  ngOnDestroy(): void {
    this.userLoggedIn$?.unsubscribe()
    this.selectVoucher$?.unsubscribe();
    this.detailDialog$?.unsubscribe();
    this.userQuery$?.unsubscribe();
    this.cart$?.unsubscribe();
    this.toastService.closeToast();
  }
}
