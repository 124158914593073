import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { VoucherTypeExtraResponse } from 'src/app/core/models/VoucherTypeExtraResponse';
import { VoucherExtra } from 'src/app/core/models/VoucherExtra';
import { VoucherCardType } from 'src/app/core/enums/CardType';
import { HttpErrorResponse } from '@angular/common/http';
import { RewardService } from '../../services/reward.service';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/core/user/user.service';
import { StoreMode } from 'src/app/core/enums';
import { ToastData } from 'src/app/core/models/ToastData';
import { ToastService } from 'src/app/shared/services/toast.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { CartModel } from 'src/app/core/models/CartModel';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { StoreService } from 'src/app/store/store/store.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { AppLinkTokenResponse } from 'src/app/core/models/AppLinkTokenResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reward-detail',
  templateUrl: './reward-detail.component.html',
  styleUrls: ['./reward-detail.component.scss']
})
export class RewardDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() exploreReward?: VoucherTypeExtraResponse;
  @Input() myReward?: VoucherExtra;
  @Input() customCardType?: string;
  @Input() storeId: number;
  @Input() channelId: number;
  @Input() active: boolean;
  @Input() currentCart : CartModel;
  @Input() appLinkTokenResponse : AppLinkTokenResponse = null;
  @Input() fullpagePromotion: boolean = false;

  user$: Subscription;

  cardType: any;
  cardTypes: any = VoucherCardType;
  errorCode: any = ErrorCode;
  imageData: string = "";
  vchTypTitle: string = "";
  vchTypDesc: string = "";
  totalIssuedPercentage: number = 0;
  claimStartDate: string = "";
  claimEndDate: string = "";
  effectiveDate: string = "";
  expiryDate: string = "";
  termsData: string = "";
  isUserClaimed: boolean = false;
  isLoggedIn: boolean;
  issueLimitMode: number = 0;
  environment = environment;

  constructor(private router: Router,
    private rewardService: RewardService,
    private userService: UserService,
    private toastService: ToastService,
    private staticQrService : StaticQrService,
    private storeService : StoreService) { }

  async ngOnInit() {
    this.user$ = this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.isLoggedIn = userData? true: false;
    });

    this.setData();
  }

  ngOnChanges() {
    this.setData();
  }

  setData() {
    if (this.exploreReward) {
      this.cardType = this.customCardType? this.customCardType: this.rewardService.checkRewardType(this.exploreReward);
      this.imageData = this.exploreReward.imageData? this.exploreReward.imageData: "";
      this.vchTypTitle = this.exploreReward.vchTypTitle? this.exploreReward.vchTypTitle: "";
      this.vchTypDesc = this.exploreReward.vchTypDesc? this.exploreReward.vchTypDesc: "";
      this.totalIssuedPercentage = this.exploreReward.totalIssuedPercentage? this.exploreReward.totalIssuedPercentage: 0;
      this.claimStartDate = this.exploreReward.claimStartDate? this.exploreReward.claimStartDate: "";
      this.claimEndDate = this.exploreReward.claimEndDate? this.exploreReward.claimEndDate: "";
      this.effectiveDate = this.exploreReward.effectiveDate? this.exploreReward.effectiveDate: "";
      this.expiryDate = this.exploreReward.expiryDate? this.exploreReward.expiryDate: "";
      this.termsData = this.exploreReward.termsData? this.exploreReward.termsData: "";
      this.isUserClaimed = this.exploreReward.isUserClaimed? this.exploreReward.isUserClaimed: false;
      this.issueLimitMode = this.exploreReward.issueLimitMode? this.exploreReward.issueLimitMode: 0;
    }

    if(this.myReward){
      this.cardType = this.customCardType? this.customCardType: this.cardTypes.use;
      this.imageData = this.myReward.imageData? this.myReward.imageData: "";
      this.vchTypTitle = this.myReward.vchTypTitle? this.myReward.vchTypTitle: "";
      this.vchTypDesc = this.myReward.vchTypDesc? this.myReward.vchTypDesc: "";
      this.effectiveDate = this.myReward.effectiveDate? this.myReward.effectiveDate: "";
      this.expiryDate = this.myReward.expiryDate? this.myReward.expiryDate: "";
      this.termsData = this.myReward.termsData? this.myReward.termsData: "";
      this.isUserClaimed = true;
    }
  }

  onHide() {
    this.rewardService.detailDialog.next({isShow: false});
  }

  onBack() {
    if(this.exploreReward) {
      this.rewardService.detailDialog.next({isShow: false, isBack: true, vchTypId: this.exploreReward.vchTypId});
    }

    if(this.myReward) {
      this.rewardService.detailDialog.next({isShow: false, isBack: true, voucherNo: this.myReward.voucherNo});
    }

  }

  onBackHome(){
    this.router.navigate(['/home']);
  }

  onShowShare() {
    if(this.exploreReward || this.myReward) {
      let vchTypId = this.exploreReward? this.exploreReward.vchTypId: this.myReward.vchTypId;
      this.rewardService.shareDialog.next({isShow: true, vchTypId: vchTypId});
    }
  }

  onNavigate() {
    let storeList: any = this.exploreReward? this.exploreReward.storeResponseList: this.myReward.storeResponseList;

    if(storeList.length === 1) {
      let globalOrderType = this.staticQrService.getChannelOrderType();
      let storeCart : OrderH = null;
      let orderType : string;

      if((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.currentCart && this.currentCart?.orderHs && this.currentCart.orderHs?.length > 0){
        storeCart = this.currentCart.orderHs.find(orderH => orderH.storeId == storeList[0].storeId);
      }

      if(storeCart){
        orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
      }
      else{
        orderType = storeList[0].currentOrderType;
      }
      this.storeService.preStoreNavigation(orderType, storeList[0], true);
    } else {
      this.onHide();
      this.rewardService.storeDialog.next({isShow: true, storeList: storeList});
    }
  }

  async onClaim() {
    let response: any;

    if((this.exploreReward || this.myReward) && this.isLoggedIn) {
      let vchTypId = this.exploreReward? this.exploreReward.vchTypId: this.myReward.vchTypId;
      let manualAssignSerialList : string[] = [];
      manualAssignSerialList.push(this.exploreReward.vchSerialNo ? this.exploreReward.vchSerialNo : null);

      response = await this.rewardService.claimVoucher(vchTypId, this.storeId, this.channelId, true, manualAssignSerialList);

      if(response instanceof(HttpErrorResponse)){
        if(response.error.errorCode === this.errorCode.VoucherFullyClaimed_400) {
          this.cardType = this.cardTypes.full;
        }

        if(response.error.errorCode === this.errorCode.VoucherClaimLimitReached_400) {
          this.cardType = this.cardTypes.claimed;
        }
      } else {
        this.cardType = this.cardTypes.claimed;

        let toastData = {} as ToastData;
        toastData.icon = "oda-check-alt";
        toastData.iconColor = "#8CD600";
        toastData.message = "alert.voucher.claimed.successful";
        this.toastService.show(toastData);
      }

      this.rewardService.updateVoucherClaimStatus(this.storeId, vchTypId, this.cardType);
      this.rewardService.detailDialog.next({isShow: true, vchTypId: vchTypId});
    }

    if(!this.isLoggedIn) {
      this.rewardService.loginDialog.next(true);
    }
  }

  onUse() {
    if(this.myReward) {
      this.rewardService.qrDialog.next({isShow: true, voucherNo: this.myReward.voucherNo});
    }
  }

  async onSelect(selected: boolean) {
    this.rewardService.selectVoucher.next({selected: selected, voucher: this.myReward, type: this.cardType, storeId: this.storeId});
    this.rewardService.detailDialog.next({isShow: false});
  }

  ngOnDestroy(): void {
    this.user$.unsubscribe();
  }
}
