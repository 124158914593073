import { MenuItemStatus } from './../../../core/enums/MenuItemStatus';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { Modifier } from 'src/app/core/models/Modifier';
import { ModifierGrp } from 'src/app/core/models/ModifierGrp';
import { SubModifier } from 'src/app/core/models/SubModifier';
import { SubModifierGrp } from 'src/app/core/models/SubModifierGrp';
import * as _ from 'lodash';
import { StoreService } from '../../store/store.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MenuService } from 'src/app/core/menu/menu.service';
import { OrderH } from 'src/app/core/models/OrderH';

@Component({
  selector: 'app-store-item-list',
  templateUrl: './store-item-list.component.html',
  styleUrls: ['./store-item-list.component.scss'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 1 }),
        animate('2s', style({ opacity: 0 })),
      ])
    ])
  ]
})
export class StoreItemListComponent implements OnInit, OnDestroy {

  @Input() curMod: Modifier | SubModifier;
  @Input() curModGrp: ModifierGrp | SubModifierGrp;
  @Input() storeId: number;
  @Input() isSubModifier: boolean = false;
  @Input() currencyCode: string;
  @Input() isMobileView: boolean;
  @Input() tempSelectedQty: number = 0;
  @Input() curStoreCart: OrderH = null;

  @Output() displayMaximumMessage = new EventEmitter();

  autoOpenCustomize: boolean = false;
  itemStatus = MenuItemStatus;

  balance: boolean = true;

  constructor(
    private storeService: StoreService,
    private menuService: MenuService
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  // check if submodifier is required for UI purpose
  // need to change to PIPE
  checkIfSubModifierIsRequired(mod: any) {
    let counter: SubModifierGrp[] = [];

    if (mod.subModifierGrps) {
      counter = (mod as Modifier).subModifierGrps.filter(val => val.minSelect >= 1);
    }

    if (counter.length >= 1) {
      return true;
    }
    else {
      return false;
    }
  }

  // check if submodifier exist or not
  // need to change to PIPE
  selectedSubModifier(mod: any) {
    let selectedSubMod = (mod as Modifier).subModifierGrps.filter(subMod => subMod.qtyGrp > 0);

    if (selectedSubMod.length != 0) {
      return true;
    }
    else {
      return false;
    }
  }

  // check to show not complete message
  // need to change to PIPE
  checkIfModComplete(mod: any) {
    return (mod as Modifier).isComplete ? true : false;
  }

  async onClickItem(modifierGrp: ModifierGrp, modifier: any) {
    // debugger
    this.autoOpenCustomize = false;
    let isMax = false;

    if (modifier.status != this.itemStatus.Available) {
      return;
    }

    if ((modifierGrp.minSelect == 1 && modifierGrp.modifiers.length == 1)) {
      return;
    }

    let itemData = await this.menuService.modifierClickedCheck(modifierGrp, modifier);

    if (modifier.qtyBalance) {
      let tempModifier = _.cloneDeep(modifier);
      let isBlockItemToAdd = false;
      tempModifier.qty = itemData.totalModQty;
      let qtyToUse = tempModifier.qty * this.tempSelectedQty;
      isBlockItemToAdd = qtyToUse > tempModifier.qtyBalance ? true : false;
      if (isBlockItemToAdd) {
        this.displayMaximumMessage.emit();
        return;
      }
    } else if(modifier.qtyBalance == 0) {
      this.displayMaximumMessage.emit();
      return;
    }

    // reset all submodifier of current modifier if isResetSubModifier flag is true
    if (itemData.isResetSubModifier) {
      // if resetModQty flag is true then pass in true for method to clear all modifier quantity
      modifierGrp = await this.menuService.removeSubModSelectedQty(modifierGrp, modifier, itemData.resetModQty ? true : false);
      this.balance = false;
    }

    if (itemData.removeCurrentModOnly) {
      modifierGrp = await this.menuService.removeSelectedModOnly(modifierGrp, modifier, itemData.resetModQty ? true : false);
      this.balance = false;
    }

    // if modifier quantity is 0 OR undefined then its mean initially selected
    // check if need to auto open submodifier popup or not
    if (!modifier.qty) {
      this.autoOpenCustomize = this.checkIfSubModifierIsRequired(modifier);
    }

    isMax = (itemData.totalModGrpQty > modifierGrp.maxSelect && modifierGrp.maxSelect != 0)
      || (itemData.totalModQty > modifier.maxSelect && modifier.maxSelect != 0) ? true : false;

    if (isMax) {
      modifierGrp = await this.removeModIsMax(modifierGrp, modifier);
      if (!this.isMobileView) {
        if (!modifier.isMax) {
          modifier.isMax = true;
        }
      }
      else {
        this.displayMaximumMessage.emit();
        return;
      }
    }
    else {
      modifierGrp.qtyGrp = itemData.totalModGrpQty;
      modifier.qty = itemData.totalModQty;
      this.balance = true
    }

    if (!modifier.isMax) {
      modifierGrp = await this.checkIfRequirementDone(modifierGrp, modifier);

      this.updateMenuItem(modifierGrp, modifier, this.autoOpenCustomize);
    }

  }

  async onClickSubModItem(subModifierGrp: SubModifierGrp, subModifier: SubModifier) {
    // debugger
    let isMax = false;

    if (subModifier.status != this.itemStatus.Available) {
      return;
    }

    if ((subModifierGrp.minSelect == 1 && subModifierGrp.subModifiers.length == 1)) {
      return;
    }

    let itemData = await this.menuService.subModifierClickedCheck(subModifierGrp, subModifier);

    if (subModifier.qtyBalance) {
      let tempModifier = _.cloneDeep(subModifier);
      let isBlockItemToAdd = false;
      tempModifier.qty = itemData.totalModQty;
      let qtyToUse = tempModifier.qty * this.tempSelectedQty;
      isBlockItemToAdd = qtyToUse > tempModifier.qtyBalance ? true : false;
      if (isBlockItemToAdd) {
        this.displayMaximumMessage.emit();
        return;
      }
    } else if(subModifier.qtyBalance == 0){
      this.displayMaximumMessage.emit();
      return;
    }

    if (itemData.clearAllSubModQty) {
      // subModifierGrp.subModifiers.forEach(subMod => subMod.qty = 0);
      subModifierGrp = await this.menuService.removeAllSubModQtyExceptSelf(subModifierGrp, subModifier, itemData.resetSubModQty ? true : false);
      this.balance = false;
    }

    if (itemData.removeCurrentSubModQty) {
      subModifierGrp = await this.menuService.removeCurrentSubModQty(subModifierGrp, subModifier, itemData.resetSubModQty ? true : false);
      this.balance = false;
    }

    isMax = (itemData.totalModGrpQty > subModifierGrp.maxSelect && subModifierGrp.maxSelect != 0)
      || (itemData.totalModQty > subModifier.maxSelect && subModifier.maxSelect != 0) ? true : false;

    if (isMax) {
      // set all submodifier isMax to false before assigning new one
      subModifierGrp.subModifiers.forEach(subMod => {
        if (subMod.itemCode != subModifier.itemCode) {
          subMod.isMax = false;
        }
      })

      if (!this.isMobileView) {
        if (!subModifier.isMax) {
          subModifier.isMax = true;
        }
      }
      else {
        this.displayMaximumMessage.emit();
        return;
      }
    }
    else {
      subModifierGrp.qtyGrp = itemData.totalModGrpQty;
      subModifier.qty = itemData.totalModQty;
      subModifier.qtyBalance -= 1;
      this.balance = true
    }

    if (!subModifier.isMax) {
      subModifierGrp = this.checkIfSubModRequirementDone(subModifierGrp);

      this.updateSubMenuItem(subModifierGrp, subModifier);
    }
  }

  async minusModifierQuantity(modifierGrp: ModifierGrp, modifier: any) {

    if (modifier.qty == 0) {
      return;
    }

    if (modifier.qty == modifier.minSelect) {
      modifier.qty -= modifier.minSelect;
      modifierGrp.qtyGrp -= modifier.minSelect;
    }
    else {
      modifier.qty -= 1;
      modifierGrp.qtyGrp -= 1;
    }

    if (modifier.qty == 0) {
      if ((modifier as Modifier).subModifierGrps) {
        (modifier as Modifier).subModifierGrps.forEach(subModGrp => {
          subModGrp.qtyGrp = 0;
          subModGrp.isComplete = undefined;
          subModGrp.subModifiers.forEach(subMod => {
            subMod.qty = 0;
          })
        })
      }
    }

    modifierGrp.modifiers.forEach(mod => {
      mod.isMax = false;
    })

    modifierGrp = await this.checkIfRequirementDone(modifierGrp, modifier);

    this.updateMenuItem(modifierGrp, modifier);
  }

  minusSubModifierQuantity(subModifierGrp: SubModifierGrp, subModifier: SubModifier) {
    if (subModifier.qty == 0) {
      return;
    }

    if (subModifier.qty == subModifier.minSelect) {
      subModifier.qty -= subModifier.minSelect;
      subModifierGrp.qtyGrp -= subModifier.minSelect;
    }
    else {
      subModifier.qty -= 1;
      subModifierGrp.qtyGrp -= 1;
    }

    subModifierGrp.subModifiers.forEach(subMod => {
      subMod.isMax = false;
    })

    subModifierGrp = this.checkIfSubModRequirementDone(subModifierGrp);

    this.updateSubMenuItem(subModifierGrp, subModifier);
  }

  async plusModifierQuantity(modifierGrp: ModifierGrp, modifier: any) {
    let totalModGrpQty = 0;
    let totalModQty = 0;
    let isMax = false;

    totalModGrpQty = modifierGrp.qtyGrp + 1;
    totalModQty = modifier.qty + 1;

    isMax = (totalModGrpQty > modifierGrp.maxSelect && modifierGrp.maxSelect != 0)
      || (totalModQty > modifier.maxSelect && modifier.maxSelect != 0) ? true : false;

    if (isMax) {
      modifierGrp = await this.removeModIsMax(modifierGrp, modifier);
      if (!this.isMobileView) {
        if (!modifier.isMax) {
          modifier.isMax = true;
        }
      }
      else {
        this.displayMaximumMessage.emit();
        return;
      }
    }
    else {
      modifierGrp.qtyGrp = totalModGrpQty;
      modifier.qty = totalModQty;
    }

    if (!modifier.isMax) {
      modifierGrp = await this.checkIfRequirementDone(modifierGrp, modifier);

      this.updateMenuItem(modifierGrp, modifier);
    }
  }

  plusSubModifierQuantity(subModifierGrp: SubModifierGrp, subModifier: SubModifier) {
    let totalModGrpQty = 0;
    let totalModQty = 0;
    let isMax = false;

    totalModGrpQty = subModifierGrp.qtyGrp + 1;
    totalModQty = subModifier.qty + 1;

    isMax = (totalModGrpQty > subModifierGrp.maxSelect && subModifierGrp.maxSelect != 0)
      || (totalModQty > subModifier.maxSelect && subModifier.maxSelect != 0) ? true : false;

    if (isMax) {
      subModifierGrp.subModifiers.forEach(subMod => {
        if (subMod.itemCode != subModifier.itemCode) {
          subMod.isMax = false;
        }
      })

      if (!this.isMobileView) {
        if (!subModifier.isMax) {
          subModifier.isMax = true;
        }
      }
      else {
        this.displayMaximumMessage.emit();
        return;
      }
    }
    else {
      subModifierGrp.qtyGrp = totalModGrpQty;
      subModifier.qty = totalModQty;
    }

    if (!subModifier.isMax) {
      subModifierGrp = this.checkIfSubModRequirementDone(subModifierGrp);

      this.updateSubMenuItem(subModifierGrp, subModifier);
    }
  }

  // call to update modifier and selected menu item
  updateMenuItem(modifierGrp: ModifierGrp, modifier: Modifier, openCustomization?: boolean) {
    this.menuService.updateSelectedMod$.next(modifierGrp);

    if (openCustomization) {
      this.storeService.setModGrpCode(modifierGrp.code);
      // notify store component(parent class) to open customize popup
      this.storeService.openCustomize$.next(true);
      // pass current modifier to customize popup
      this.menuService.selectedModifier$.next(modifier);
    }
  }

  // call to update curModifier in akita store
  updateSubMenuItem(subModifierGrp: SubModifierGrp, subModifier: SubModifier) {
    this.menuService.updateSelectedSubMod$.next(subModifierGrp);
  }

  async checkIfRequirementDone(modifierGrp: ModifierGrp, modifier: Modifier) {
    // debugger
    modifierGrp = await this.menuService.checkIndividualModGrp(modifierGrp);
    return modifierGrp;
  }

  checkIfSubModRequirementDone(subModifierGrp: SubModifierGrp) {
    if (subModifierGrp.qtyGrp >= subModifierGrp.minSelect) {
      subModifierGrp.isComplete = true;
    }
    else {
      subModifierGrp.isComplete = false;
    }

    return subModifierGrp;
  }

  hasSubModifierGrp(curMod: any) {
    if (curMod.subModifierGrps && curMod.qty > 0) {
      return true;
    }
    else {
      return false;
    }

  }

  subModifiersClickEventCheck(curMod: any) {
    if (curMod.subModifierGrps && curMod.qty > 0) {
      this.onChangeSubModifier(curMod);
    }
    else if (!this.isSubModifier) {
      this.onClickItem(this.curModGrp, this.curMod);
    }
    else if (this.isSubModifier) {
      this.onClickSubModItem(this.curModGrp, this.curMod);
    }
  }

  // whole method took 36ms
  onChangeSubModifier(curMod: any) {
    this.storeService.setModGrpCode(this.curModGrp.code);

    this.storeService.openCustomize$.next(true);
    this.menuService.selectedModifier$.next(curMod);
  }

  async removeModIsMax(modGrp: ModifierGrp, modifier: Modifier) {
    modGrp.modifiers.forEach(mod => {
      if (mod.itemCode != modifier.itemCode) {
        mod.isMax = false;
      }
    })

    return modGrp;
  }

  animationDone(event: any, modifier: any) {
    modifier.isMax = false;
  }
}
