export enum EntryMode{
  KeyBoard = "keyboard",
  Menu = "Menu",
  BarcodeScanner = "BarcodeScanner",
  AutoInsertedCannotVoid = "AutoInsertedCannotVoid",
  AutoInsertedCanVoid = "AutoInsertedCanVoid",
  UpsellMenu = "UpsellMenu",
  CrossSellMenu = "CrossSellMenu",
  Voucher = "Voucher",
  Recommended = "Recommended"
}
