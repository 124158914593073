<div class="social-share-container" [ngClass]="{'hideForReferralPage': isReferralPage === true, 'hidden-name': hideName}">

  <div *ngFor="let icon of socialShareIcon" class="social-share-wrapper">
    <button mat-icon-button
          [ngClass]="[ icon ]"
          [shareButton]="icon"
          #fbBtn="shareButton"
          [title]="title"
          [url]="url"
          [description]="description"
          [autoSetMeta]="false"
          (opened)="shareReferral($event)">
      <div class="image-card">
        <img [src]="iconImage(icon)" alt="socialMedia icon"/>
      </div>
      <h1>{{ icon | titlecase }}</h1>
    </button>
  </div>
</div>


