import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MembershipDetailResponse } from '../membership/membership-detail-response';
import { MembershipRewardType } from '../membership/RewardType';
import { StampResponse } from '../membership/stamp-response';
declare var Gauge: any;

@Component({
  selector: 'app-membership-stamp',
  templateUrl: './membership-stamp.component.html',
  styleUrls: ['./membership-stamp.component.scss']
})
export class MembershipStampComponent implements OnInit {
  @Input() memberDetail: MembershipDetailResponse;

  stampList: StampResponse[] = [];
  staticLabels: number[] = [];
  iconImage: string[] = [];
  color: string[] = [];
  selectedStamp: StampResponse = null;
  balanceStamp: number = 0;

  circularSet = [
    {
      totalCount: 20,
      set: [
        { left: '10%', top: '83%' },
        { left: '8%', top: '74%' },
        { left: '6%', top: '63%' },
        { left: '5%', top: '53%' },
        { left: '6%', top: '43%' },
        { left: '9%', top: '33%' },
        { left: '14%', top: '23%' },
        { left: '21%', top: '15%' },
        { left: '38%', top: '7%' },
        { left: '29%', top: '10%' },

        { left: '47%', bottom: '84%' },

        { right: '29%', top: '10%' },
        { right: '38%', top: '7%' },
        { right: '21%', top: '15%' },
        { right: '14%', top: '23%' },
        { right: '9%', top: '33%' },
        { right: '6%', top: '43%' },
        { right: '5%', top: '53%' },
        { right: '6%', top: '63%' },
        { right: '8%', top: '74%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 19,
      set: [
        { left: '10%', top: '83%' },
        { left: '8%', top: '74%' },
        { left: '6%', top: '63%' },
        { left: '5%', top: '52%' },
        { left: '6%', top: '41%' },
        { left: '10%', top: '30%' },
        { left: '17%', top: '20%' },
        { left: '24%', top: '13%' },
        { left: '42%', top: '5%' },
        { left: '32%', top: '8%' },
        { right: '32%', top: '8%' },
        { right: '42%', top: '5%' },
        { right: '24%', top: '13%' },
        { right: '17%', top: '20%' },
        { right: '10%', top: '30%' },
        { right: '6%', top: '41%' },
        { right: '5%', top: '52%' },
        { right: '6%', top: '63%' },
        { right: '8%', top: '74%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 18,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '72%' },
        { left: '5%', top: '61%' },
        { left: '5%', top: '50%' },
        { left: '7%', top: '38%' },
        { left: '12%', top: '27%' },
        { left: '18%', top: '18%' },
        { left: '28%', top: '10%' },
        { left: '37%', top: '6%' },

        { left: '47%', bottom: '84%' },

        { right: '28%', top: '10%' },
        { right: '37%', top: '6%' },
        { right: '18%', top: '18%' },
        { right: '12%', top: '27%' },
        { right: '7%', top: '38%' },
        { right: '5%', top: '50%' },
        { right: '5%', top: '61%' },
        { right: '7%', top: '72%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 17,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '72%' },
        { left: '5%', top: '60%' },
        { left: '5%', top: '48%' },
        { left: '8%', top: '36%' },
        { left: '13%', top: '24%' },
        { left: '21%', top: '14%' },
        { left: '32%', top: '8%' },
        { left: '42%', top: '5%' },

        { right: '42%', top: '5%' },
        { right: '32%', top: '8%' },
        { right: '21%', top: '14%' },
        { right: '13%', top: '24%' },
        { right: '8%', top: '36%' },
        { right: '5%', top: '48%' },
        { right: '5%', top: '60%' },
        { right: '7%', top: '72%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 16,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '71%' },
        { left: '5%', top: '58%' },
        { left: '6%', top: '46%' },
        { left: '9%', top: '33%' },
        { left: '16%', top: '21%' },
        { left: '25%', top: '12%' },
        { left: '36%', top: '6%' },

        { left: '47%', bottom: '84%' },

        { right: '36%', top: '6%' },
        { right: '25%', top: '12%' },
        { right: '16%', top: '21%' },
        { right: '9%', top: '33%' },
        { right: '6%', top: '46%' },
        { right: '5%', top: '58%' },
        { right: '7%', top: '71%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 15,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '70%' },
        { left: '5%', top: '55%' },
        { left: '6%', top: '43%' },
        { left: '11%', top: '30%' },
        { left: '18%', top: '18%' },
        { left: '29%', top: '10%' },
        { left: '41%', top: '6%' },
        { right: '41%', top: '6%' },
        { right: '29%', top: '10%' },
        { right: '18%', top: '18%' },
        { right: '11%', top: '30%' },
        { right: '6%', top: '43%' },
        { right: '5%', top: '55%' },
        { right: '7%', top: '70%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 14,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '69%' },
        { left: '5%', top: '55%' },
        { left: '7%', top: '40%' },
        { left: '12%', top: '25%' },
        { left: '22%', top: '14%' },
        { left: '34%', top: '7%' },

        { left: '47%', bottom: '84%' },

        { right: '34%', top: '7%' },
        { right: '22%', top: '14%' },
        { right: '12%', top: '25%' },
        { right: '7%', top: '40%' },
        { right: '5%', top: '55%' },
        { right: '7%', top: '69%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 13,
      set: [
        { left: '10%', top: '83%' },
        { left: '7%', top: '68%' },
        { left: '5%', top: '53%' },
        { left: '8%', top: '36%' },
        { left: '15%', top: '21%' },
        { left: '26%', top: '11%' },
        { left: '40%', top: '5%' },

        { right: '40%', top: '5%' },
        { right: '26%', top: '11%' },
        { right: '15%', top: '21%' },
        { right: '8%', top: '36%' },
        { right: '5%', top: '53%' },
        { right: '7%', top: '68%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 12,
      set: [
        { left: '10%', top: '83%' },
        { left: '6%', top: '67%' },
        { left: '5%', top: '50%' },
        { left: '9%', top: '32%' },
        { left: '18%', top: '17%' },
        { left: '31%', top: '7%' },

        { left: '47%', bottom: '84%' },

        { right: '31%', top: '7%' },
        { right: '18%', top: '17%' },
        { right: '9%', top: '32%' },
        { right: '5%', top: '50%' },
        { right: '6%', top: '67%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 11,
      set: [
        { left: '10%', top: '83%' },
        { left: '6%', top: '65%' },
        { left: '5%', top: '47%' },
        { left: '11%', top: '28%' },
        { left: '24%', top: '13%' },
        { left: '39%', top: '6%' },

        { right: '39%', top: '6%' },
        { right: '24%', top: '13%' },
        { right: '11%', top: '28%' },
        { right: '5%', top: '47%' },
        { right: '6%', top: '65%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 10,
      set: [
        { left: '10%', top: '83%' },
        { left: '5%', top: '63%' },
        { left: '6%', top: '43%' },
        { left: '14%', top: '23%' },
        { left: '29%', top: '10%' },

        { left: '47%', bottom: '84%' },

        { right: '29%', top: '10%' },
        { right: '14%', top: '23%' },
        { right: '6%', top: '43%' },
        { right: '5%', top: '63%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 9,
      set: [
        { left: '10%', top: '83%' },
        { left: '5%', top: '61%' },
        { left: '7%', top: '39%' },
        { left: '18%', top: '18%' },
        { left: '37%', top: '6%' },

        { right: '37%', top: '6%' },
        { right: '18%', top: '18%' },
        { right: '7%', top: '39%' },
        { right: '5%', top: '61%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 8,
      set: [
        { left: '10%', top: '83%' },
        { left: '5%', top: '58%' },
        { left: '9%', top: '33%' },
        { left: '23%', top: '13%' },

        { left: '47%', bottom: '84%' },

        { right: '23%', top: '13%' },
        { right: '9%', top: '33%' },
        { right: '5%', top: '58%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 7,
      set: [
        { left: '10%', top: '83%' },
        { left: '5%', top: '55%' },
        { left: '12%', top: '26%' },
        { left: '32%', top: '8%' },

        { right: '32%', top: '8%' },
        { right: '12%', top: '26%' },
        { right: '5%', top: '55%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 6,
      set: [
        { left: '10%', top: '83%' },
        { left: '5%', top: '50%' },
        { left: '18%', top: '18%' },
        { left: '47%', bottom: '84%' },
        { right: '18%', top: '18%' },
        { right: '5%', top: '50%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 5,
      set: [
        { left: '10%', top: '83%' },
        { left: '6%', top: '44%' },
        { left: '29%', top: '9%' },

        { right: '29%', top: '9%' },
        { right: '6%', top: '44%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 4,
      set: [
        { left: '10%', top: '83%' },
        { left: '9%', top: '32%' },

        { left: '47%', bottom: '84%' },

        { right: '9%', top: '32%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 3,
      set: [
        { left: '10%', top: '83%' },
        { left: '18%', top: '17%' },

        { right: '10%', top: '83%' },
        { right: '18%', top: '17%' },
      ]
    },
    {
      totalCount: 2,
      set: [
        { left: '10%', top: '83%' },
        { left: '47%', bottom: '84%' },
        { right: '10%', top: '83%' },
      ]
    },
    {
      totalCount: 1,
      set: [
        { left: '10%', top: '83%' },
        { right: '10%', top: '83%' },
      ]
    }
  ];

  pointers: any = [];
  showOriColorIcon: boolean = false;
  isMobileView: boolean = false;
  defaultStamp = {
    "sChartId": 0,
    "stampId": 0,
    "stampDesc": "Stamp 0",
    "stampIcon": "",
    "unstampIcon": "",
    "rewardType": "N",
    "vchTypList": undefined,
    "rewardQty": 0
  };
  membershipRewardType = MembershipRewardType;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    })
  }

  async ngOnInit() {
    this.stampList = this.memberDetail.stampChart.stampList;

    for (let index = 0; index <= this.stampList.length; index++) {
      this.staticLabels.push(index);
    }

    this.balanceStamp = this.memberDetail.nextRewardStampId - this.memberDetail.stampEarned
    this.selectedStamp = this.stampList.find(stamp => stamp.stampId === this.memberDetail.stampEarned);

    if (!this.selectedStamp) {
      this.selectedStamp = this.defaultStamp;
    }

    // check pointer iconPath image
    let isCurrentStamp = this.stampList[this.memberDetail.stampEarned - 1];
    if (isCurrentStamp && isCurrentStamp.rewardType == MembershipRewardType.voucher) {
      this.iconImage.push('assets/mascot/membership_mascot_take_gift.webp');
    } else {
      this.iconImage.push('assets/mascot/membership_running_mascot.webp');
    }
    //check colorStop color
    if (this.memberDetail.stampEarned == 0) {
      this.color.push('#F4F4F4')
    }
    else {
      this.color.push('#FDEF23')
    }

    this.drawCircularGauge();
    this.pointers = await this.setPointerPosition(this.stampList.length)

    for (let index = 0; index < this.pointers.length; index++) {
      if ((index > this.memberDetail.stampEarned) && (this.pointers[index].selectedData.rewardType === MembershipRewardType.voucher)) {
        this.pointers[index].showIcon = true;

        if (this.selectedStamp.rewardType !== MembershipRewardType.voucher) {
          this.selectedStamp = this.pointers[index].selectedData;
        }

      } else {
        this.pointers[index].showIcon = false;
      }
    }
  }

  drawCircularGauge() {
    var mobileOpts = this.getOpts(true);
    var webOpts = this.getOpts(false);
    this.drawGaugeProcess('circular-progress-mobile', mobileOpts);
    this.drawGaugeProcess('circular-progress', webOpts);
  }

  setPointerPosition(totalCount: number) {
    let localStampList = this.stampList;
    localStampList.unshift(this.defaultStamp);

    let pointerList = [];
    let position: any = this.circularSet.find(data => data.totalCount == totalCount)?.set;
    for (let count = 0; count <= totalCount; count++) {
      pointerList.push({
        top: position[count].top,
        left: position[count]?.left,
        right: position[count]?.right,
        bottom: position[count]?.bottom,
        source: 'assets/mascot/membership_gift.webp',
        selectedData: localStampList[count]
      })
    }
    return pointerList;
  }

  selectStamp(index, pointer: any) {
    let selectedStamp: StampResponse = pointer.selectedData;

    if (((index == this.memberDetail.stampEarned) && (selectedStamp.rewardType === MembershipRewardType.voucher)) || pointer.showIcon) {
      this.selectedStamp = selectedStamp;
    }
  }

  getOpts(isMobile: boolean) {
    return {
      angle: -0.25, // The span of the gauge arc
      lineWidth: 0.03, // The line thickness
      radiusScale: 0.7, // Relative radius
      pointer: {
        iconPath: this.iconImage,  // Icon image source
        iconScale: 1,    // Size scaling factor
        iconAngle: 10.0,  // Rotation offset angle, degrees
        length: 0.65, // // Relative to gauge radius
        strokeWidth: 0.035, // The thickness
        color: 'transparent', // Fill color
        width: 120,
        height: 120
      },
      limitMax: false,     // If false, max value increases automatically if value > maxValue
      limitMin: false,     // If true, the min value of the gauge will be fixed
      colorStart: '#6F6EA0',   // Colors
      colorStop: this.color,    // just experiment with them
      strokeColor: '#EEEEEE',  // to see which ones work best for you
      generateGradient: true,
      highDpiSupport: true,     // High resolution support
      staticLabels: {
        font: "10px sans-serif",  // Specifies font
        labels: this.staticLabels,  // Print labels at these values
        color: "transparent",  // Optional: Label text color
        fractionDigits: 0  // Optional: Numerical precision. 0=round off.
      },
    };
  }

  drawGaugeProcess(elementId: string, opts: any) {
    var target: any = document.getElementById(elementId); // your canvas element
    var gauge = new Gauge(target).setOptions(opts); // create sexy gauge!
    gauge.maxValue = this.stampList.length; // set max gauge value
    gauge.setMinValue(0);  // Prefer setter over gauge.minValue = 0
    gauge.animationSpeed = 20; // set animation speed (32 is default value)
    gauge.set(this.memberDetail.stampEarned ? this.memberDetail.stampEarned : 0.1); // set actual value
    // gauge.set(0.1); // set actual value
  }
}
