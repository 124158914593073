import { AppLinkTokenResponse } from './../models/AppLinkTokenResponse';
import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { MiniProgramCart } from "./mini-program.model";

export interface MiniProgramState extends EntityState<MiniProgramCart> {
  appLinkTokenResponse : AppLinkTokenResponse
}

export function createInitialState() {
  return {
    appLinkTokenResponse: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'mp-store' })
export class MiniProgramStore extends EntityStore<MiniProgramState, MiniProgramCart> {

  constructor() {
    super(createInitialState());
  }
}
