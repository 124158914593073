<div class="orderType-container">
  <div *ngIf="!isMobileView">
    <p-dropdown [options]="orderTypeList" [(ngModel)]="selectedOrderType" optionLabel="name" optionValue="value"
    styleClass="orderTypeSelector" (onChange)="orderTypeChange(selectedOrderType)" >
    <ng-template let-item pTemplate="selectedItem">
      <div class="type-item heading-9">
        <i [ngClass]="item.icon" class="dropdown-order-type-icon">
        </i>
        <p class="order-type-name">
          {{item.name | translate}}
        </p>
        <i *ngIf="orderTypeList.length > 1" class="oda-chevron-down dropdown-down-arrow">
        </i>
      </div>
    </ng-template>

    <ng-template let-type pTemplate="item">
      <div class="type-item" [ngStyle]="{ 'font-weight': selectedOrderType == type.value ? 'bold' : 'normal' }">
        <i [ngClass]="type.icon" class="dropdown-order-type-icon"></i>
        {{type.name | translate}}
      </div>
    </ng-template>
  </p-dropdown>
  </div>

  <div class="mobile-orderType" *ngIf="isMobileView">
    <div *ngFor="let item of orderTypeList" (click)="orderTypeChange(item.value)">
      <div class="currentorderType" [ngClass]="{'unselectedOrderType': (selectedOrderType | lowercase ) != (item.value | lowercase )}">
        <div class="icon">
          <i [ngClass]="item.icon"></i>
        </div>
        {{item.name | translate}}
      </div>
    </div>
  </div>
</div>
