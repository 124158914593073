import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class QuitStoreGuard implements CanDeactivate<unknown> {
  constructor(
    private commonService : CommonService
  ) { }

  canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let keys = this.commonService.openDialogKeys;
    if (keys && keys.length > 0) {
      const modalState = {
        modal: true,
        desc: 'dialog'
      };
      history.pushState(modalState, null);
      this.commonService.closeDialogKey();
      return false;
    } else {
      return true;
    }
  }
}
