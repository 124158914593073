<div *ngIf="selectedMenuItem as itm">
  <div class="p-d-flex title_and_price_container">

    <div class="store_item_title" [innerHtml]="itm.title | formatInnerHtml: (itm.itemMenuTags | menuTags: 'icon')"></div>

    <div class="itemDescription-itemAvailability-price-container">
      <div class="itemQty-container" *ngIf="(itm.qtyBalance || itm.qtyBalance == 0) && itm.status == itemStatus.Available">
        {{"item.quantity.availability" | translate : { qtyBalance: itm.qtyBalance > 99 ? '99+' : itm.qtyBalance } }}
      </div>
      <!-- price container -->
      <div class="item-price-container" *ngIf="itm.dispPrice > 0 || itm.origPrice > 0"
        [ngStyle]="{ 'margin-left': itm.qtyBalance || itm.qtyBalance == 0? '8px': '0px'}">
        <div class="original_price p-mr-2 hidden-sm" *ngIf="itm.origPrice > 0"><del>{{itm.origPrice | currency:
            priceSymbol : 'symbol-narrow'}}</del></div>
        <div class="display_price">{{itm.dispPrice | currency : priceSymbol : 'symbol-narrow'}}</div>
        <div class="original_price hidden-lg" *ngIf="itm.origPrice > 0">
          <del>{{itm.origPrice | currency: priceSymbol : 'symbol-narrow'}}</del>
        </div>
      </div>

      <div class="price-range-container" *ngIf="itm.priceRange && itm.priceRange?.min != 0 && itm.variations && itm.variations?.length > 0
      && itm.origPrice == 0 && itm.dispPrice == 0" [ngStyle]="{ 'margin-left': itm.qtyBalance || itm.qtyBalance == 0 ? '8px': '0px'}">
        <span class="min-price-desc">
          {{"menu.item.from" | translate}}&nbsp;
        </span>
        <span class="min-price">{{ itm.priceRange.min | currency : priceSymbol : 'symbol-narrow' }}</span>
      </div>
    </div>
  </div>
</div>

<div class="store_item_description_container" [innerHtml]="selectedMenuItem.description"
  *ngIf="selectedMenuItem && selectedMenuItem?.description"></div>

  <!-- menu tag -->
  <ng-template [ngIf]="selectedMenuItem.itemMenuTags">
    <div class="menu-tag-container">
      <span *ngFor="let menuTag of selectedMenuItem.itemMenuTags | menuTags: 'nutri-grade'">
        <app-store-menu-tag [itemMenuTag]="menuTag"></app-store-menu-tag>
      </span>
    </div>
  </ng-template>
