import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashed-line',
  templateUrl: './dashed-line.component.html',
  styleUrls: ['./dashed-line.component.scss']
})
export class DashedLineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
