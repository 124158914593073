import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VerifyEmailStatus } from '../enums/VerifyEmailStatus';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyEmailGuard implements CanActivate {

  constructor(
    public router: Router,
    private userService: UserService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.url[0].path === 'verifyemail-success' && this.userService.confirmEmailStatus === VerifyEmailStatus.Success) {
      return true;
    } else if (route.url[0].path === 'verifyemail-failed' && this.userService.confirmEmailStatus === VerifyEmailStatus.Failed) {
      return true;
    } else if (route.url[0].path === 'verifyemail-expired' && this.userService.confirmEmailStatus === VerifyEmailStatus.Expired) {
      return true;
    } else if (route.url[0].path === 'verification-link-sent' && this.userService.sentVerifyEmail) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }

}
