
<div class="email-success-container">
  <div class="content">
    <img src="assets/mascot/Mascot_email-success@2x.webp" alt="odaring mascot"/>
    <h1 class="heading-11" style="text-align: center">
      {{'email.verification.success.title' | translate}}
    </h1>
    <h1 class="heading-13" style="text-align: center">
      {{'email.verification.success.content' | translate}}
    </h1>

    <div style="text-align: center">
      <button type="button" class="btn-back-home" (click)="navigateToHome()">
        {{'button.back.to.home' | translate}}
      </button>
    </div>
  </div>
</div>
