<div class="applied-promo-voucher-container">
    <div class="action-container promo" (click)="onClickApplyPromo();">
        <span class="action-description-container">
          <i class="oda-discount"></i>
          <span class="action-description">{{"order.summary.qr.dinein.promo" | translate}}</span>
        </span>

        <span class="action-button-container">
          <span class="action-button-description">{{"button.apply" | translate | titlecase}}</span>
          <i class="oda-chevron-right"></i>
        </span>

        <div class="applied-voucher-container" *ngIf="orderH.orderData.promotions != null && orderH.orderData.promotions.length > 0 && orderH.orderData.promotions && !isStorePage" (click)="$event.stopPropagation();">
          <div class="applied-voucher" *ngFor="let promo of orderH.orderData.promotions">
            <div class="content">
              <i class="oda-check-alt icon"></i>
              <span>{{ promo.promotionCode }}</span>
              <i class="oda-times btn-close" (click)="onClickRemovePromo(promo.promotionCode)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="action-container voucher" (click)="onClickSelectVoucher();" *ngIf="!isQrTokenResponse">
        <span class="action-description-container">
          <i class="oda-ticket"></i>
          <span class="action-description">{{"order.summary.qr.dinein.voucher" | translate}}</span>
        </span>

        <span class="action-button-container">
          <span class="action-button-description">{{"button.select" | translate | titlecase}}</span>
          <i class="oda-chevron-right"></i>
        </span>

        <div class="applied-voucher-container" *ngIf="orderH.orderData.vouchers != null && orderH.orderData.vouchers.length > 0 && orderH.orderData.vouchers && !isStorePage" (click)="$event.stopPropagation();">
          <div class="applied-voucher" *ngFor="let voucher of orderH.orderData.vouchers">
            <div class="content">
              <i class="oda-check-alt icon"></i>
              <span>{{ voucher.vchTypDesc }}</span>
              <i class="oda-times btn-close" (click)="onClickRemoveVoucher(voucher.voucherNo)"></i>
            </div>
          </div>
        </div>
    </div>
</div>

