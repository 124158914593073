<div class="user-menu-dropdown-container">
  <div class="btn-group dropdown-outline" [ngClass]="{'user-detail-border-style': isFromLandingPage === true}" dropdown dropdownToggle>
    <div id="button-autoclose1" class="btn-userDetail heading-11" aria-controls="dropdown-autoclose1">
      <i class="oda-user-open"></i>
      <span class="text-truncate user-display-name">{{ curUser && curUser.name? curUser.name : ' '}}</span>
      <i class="oda-chevron-down"></i>
    </div>

    <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu"
        aria-labelledby="button-autoclose1">
      <!--profile-->
      <li role="menuitem">
        <a class="dropdown-item" routerLinkActive="active" *ngFor="let itm of userMenuLists" (click)="onNavigate(itm.value)">
          <span class="dropdown_list">
            <i [ngClass]="itm.icon"></i>
            <p title="{{ itm.menuName | translate}}">{{ itm.menuName | translate}}</p>
          </span>
        </a>
      </li>

      <hr class="button-separator">

      <!--logout-->
      <li role="menuitem">
        <a class="dropdown-item" routerLinkActive="active" (click)="onLogout()">
          <span class="dropdown_list">
            <i class="oda-sign-out"></i>
            <p title="{{'navigation.Menu.des.10' | translate}}">{{'navigation.Menu.des.10' | translate}}</p>
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
