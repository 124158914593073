<div class="quit-member-term-container">
  <div class="content">
    <div class="loader-container" *ngIf="showLoading">
      <img src="assets/Loading-animation.gif" alt="loading animation">
    </div>
    <iframe [hidden]="showLoading" [src]="src" (load)="onLoad()" style="width:100%; height: inherit;" frameBorder="0" #iframe></iframe>
  </div>

  <div class="footer" *ngIf="!showLoading">
    <div class="check-box-content">
        <label class="checkbox-container" for="anonymous" (change)="onAgree($event)">
          <input id="anonymous" type="checkbox"  [value]="isAgree"  [checked]="isAgree" />
          <span class="checkmark"></span>
          <span>{{'delete.acc.agree.tnc.desc' | translate}}</span>
        </label>
    </div>
    <button type="button" [disabled]="!isAgree" (click)="onClickContinue()">{{ 'button.continue' | translate }}</button>
</div>
</div>
