import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from "src/app/core/enums/HttpHeaderType";
import { CustomHeader, CustomRequest } from "src/app/core/models/CustomRequest";
import { StoreMode } from 'src/app/core/enums';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { CustomService } from 'src/app/core/services/custom.service';
import { UserStore } from 'src/app/core/user/user.store';
import { MembershipWithPointStampResponse } from 'src/app/membership/membership/membership-with-point-stamp-response';
import { Router } from '@angular/router';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { GetMembershipByLoginRequest } from 'src/app/core/models/LoginRequest';
import { ExternalMemberDataaQuery } from 'src/app/membership/membership/externalMembership-data.query';
import { ExternalMemberDataStore } from 'src/app/membership/membership/externalMembership-data.store';
import * as _ from 'lodash';
import { ExternalMemberData } from 'src/app/core/models/local/ExternalMembershipData';
import { MembershipDetailResponse } from './membership-detail-response';
import { createMap, createMapper } from '@automapper/core';
import { classes } from '@automapper/classes';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';


export const mapper = createMapper({
  strategyInitializer: classes(),
});

@Injectable({
  providedIn: 'root'
})

export class MembershipService {

  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;
  errorCode: any = ErrorCode;

  loginDialog = new Subject();
  externalMembershipLogin = new Subject();
  joinTermDialog = new Subject();
  detailDialog = new Subject<{ isShow: boolean, vchTypId?: number }>();
  showLoginErrorData = new BehaviorSubject<{ errorText?: string;}>(null);
  onLoginExtMembership = new Subject<MembershipDetailResponse>();

  constructor(
    private userService: UserService,
    private customService: CustomService,
    private userStore: UserStore,
    private router: Router,
    private externalMemberDataaQuery: ExternalMemberDataaQuery,
    private externalMemberDataStore: ExternalMemberDataStore,
    private sessionStorageService: SessionStorageService,
  ) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.accessToken = dt && dt['accessToken'] ? dt['accessToken'] : '';
      this.refreshToken = dt && dt['refreshToken'] ? dt['accessToken'] : '';
      this.curUser = dt ? dt : null;
    });
  }

  async getMembershipTermsData(membershipCode: string) {
    let respDt = null;
    respDt = await this.reqGetMembershipTermsData(this.accessToken, membershipCode);
    return respDt;
  }

  async joinMembership(membershipCode: string, channelId: number, storeId: string) {
    let respDt = null;
    respDt = await this.reqJoinMembership(this.accessToken, membershipCode, channelId, storeId);
    if (!(respDt instanceof (HttpErrorResponse))) {
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async quitMembership(membershipCode: string) {
    let respDt = null;
    respDt = await this.reqQuitMembership(this.accessToken, membershipCode);
    if (!(respDt instanceof (HttpErrorResponse))) {
      respDt = respDt && respDt['body'] ? respDt['body'] : null;
    }
    return respDt;
  }

  async getMembershipDetails(membershipCode: string, channelId: number, latitude: number, longitude: number, membershipNo?: string, orderTypeFlag?: string) {
    let respDt = null;
    respDt = await this.reqGetMembershipDetails(this.accessToken, membershipCode, channelId, latitude, longitude, membershipNo, orderTypeFlag);
    if (respDt instanceof (HttpErrorResponse)) {
      if (respDt.error.errorCode === this.errorCode.MembershipNotFound_404) {
        this.router.navigate(["page-not-found"]);
      }
    }
    return respDt;
  }

  async getCustomerMemberships(channelTag?: string, searchText?: string,): Promise<MembershipWithPointStampResponse[]> {
    let respDt = null;
    respDt = await this.reqGetCustomerMemberships(this.accessToken, channelTag, searchText);
    return respDt;
  }

  async getMembershipRewardHistory(membershipCode: string, skip?: number, take?: number, earliestDateTime?: string) {
    let respDt = null;
    respDt = await this.reqGetMembershipRewardHistory(this.accessToken, membershipCode, skip, take, earliestDateTime);
    return respDt;
  }

  async getmemberclaimedvoucher(channelId: number, membershipCode: string, membershipNo: string) {
    let respDt = null;
    respDt = await this.reqGetmemberclaimedvoucher(this.accessToken, channelId, membershipCode, membershipNo);
    return respDt;
  }

  getExternalMemberData() {
    let externalMemberData = _.cloneDeep(this.externalMemberDataaQuery.getValue());
    if (externalMemberData && externalMemberData?.membershipDetailResponse) {
      return externalMemberData;
    }
    else {
      return null;
    }
  }

  getExternalMembershipResponseObservable() {
    return this.externalMemberDataaQuery.select(state => state.membershipDetailResponse);
  }

  saveExternalMembershipData(externalMembershipData: ExternalMemberData) {
    this.externalMemberDataStore.update(state => {
      return {
        membershipCode: externalMembershipData && externalMembershipData?.membershipCode ? externalMembershipData.membershipCode : undefined,
        channelId: externalMembershipData && externalMembershipData?.channelId ? externalMembershipData.channelId : undefined,
        membershipDetailResponse: externalMembershipData && externalMembershipData?.membershipDetailResponse ? externalMembershipData.membershipDetailResponse : null
      }
    })
  }

  removeExternalMembershipData() {
    this.externalMemberDataStore.reset();

    if(this.sessionStorageService.getItem('externalMembership-data')){
      this.sessionStorageService.removeItem('externalMembership-data');
    }
  }

  getChannelTagInLocalStorage() {
    return localStorage.getItem('channelTag');
  }

  getExternalLoginErrorData() {
    return this.showLoginErrorData.asObservable();
  }

  async getmembershipbylogin(loginRequest: GetMembershipByLoginRequest) {
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqExternalMembershipLogin(loginRequest, this.accessToken, channelTag);

    if (!(respDt instanceof HttpErrorResponse)) {
      let externalMemberData = this.getExternalMemberData();
      if (!externalMemberData) {
        externalMemberData = {} as ExternalMemberData;
      }
      externalMemberData.membershipDetailResponse = respDt['body'];
      this.saveExternalMembershipData(externalMemberData);
      return respDt['body'];
    }

    if (respDt instanceof (HttpErrorResponse)) {
      if (respDt.error.errorCode === this.errorCode.LoginIDPasswordInvalid_400) {
       this.showLoginErrorData.next({
        errorText : 'LoginIDPasswordInvalid_400'
       })
      }
      return respDt;
    }
  }

  async onExternalMember(loginRequest: GetMembershipByLoginRequest) {
    let resp = await this.getmembershipbylogin(loginRequest);
    this.sessionStorageService.removeItem("externalMembershipRequest");

    if (!(resp instanceof HttpErrorResponse)) {
      let externalMemberData = {} as ExternalMemberData;
      externalMemberData = this.getExternalMemberData();
      this.onLoginExtMembership.next(resp);
    }
  }

  private async reqGetMembershipTermsData(accessToken: string, membershipCode: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.membership.GetMembershipTermsData,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        membershipCode: membershipCode,
      },
      httpHeaderType: HttpHeaderType.Auth,
      responseType: 'text'
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqJoinMembership(accessToken: string, membershipCode: string, channelId: number, storeId: string) {
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.membership.JoinMembership,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        membershipCode: membershipCode,
        ChannelId: channelId,
        storeId: storeId,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqQuitMembership(accessToken: string, membershipCode: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.membership.QuitMembership,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        membershipCode: membershipCode,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);
    return respInfo;
  }

  private async reqGetMembershipDetails(accessToken: string, membershipCode: string, channelId: number, latitude: number, longitude: number,
    membershipNo?: string, orderTypeFlag?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.membership.GetMembershipDetails,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        membershipCode: membershipCode,
        channelId: channelId,
        Latitude: latitude,
        Longitude: longitude,
        OrderTypeFlag: orderTypeFlag ? orderTypeFlag : undefined,
        membershipNo: membershipNo ? membershipNo : undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetCustomerMemberships(accessToken: string, channelTag?: string, searchText?: string): Promise<MembershipWithPointStampResponse[]> {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.membership.GetCustomerMemberships,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      } as CustomHeader,
      queryParams: {
        channelTag: channelTag ? channelTag : undefined,
        SearchText: searchText,
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetMembershipRewardHistory(accessToken: string, membershipCode: string, skip?: number, take?: number, earliestDateTime?: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.membership.GetMembershipRewardHistory,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        membershipCode: membershipCode,
        skip: skip,
        take: take,
        earliestDateTime: earliestDateTime ? earliestDateTime : undefined
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private async reqGetmemberclaimedvoucher(accessToken: string, channelId: number, membershipCode: string, membershipNo: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.voucher.Getmemberclaimedvoucher,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelId: channelId,
        membershipCode: membershipCode,
        membershipNo: membershipNo
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;

    let respInfo = null;
    this.userStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.userStore.setLoading(false);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isOriBody?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => dd['body'] || dd['body'] === '' ? dd['body'] : dd);
  }

  private async reqExternalMembershipLogin(loginRequest: GetMembershipByLoginRequest, accessToken: string, channelTag: string,) {
    let newCr: CustomRequest = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.membership.Getmembershipbylogin,
      hostPath: environment.hostPath,
      body: {
        membershipCode: loginRequest.membershipCode,
        channelId: loginRequest.channelId,
        loginId: loginRequest.loginId,
        loginValue: loginRequest.loginValue,
        rememberAccount: loginRequest.rememberAccount
      },
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest;
    let respInfo = null;
    respInfo = await this.reqCustomHttpCallBody(newCr);
    return respInfo;
  }

  private reqCustomHttpCallBody(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }

  async populateMembershipResponse(extMembershipResp : MembershipDetailResponse){
    let membershipResponse : MembershipWithPointStampResponse = null;
    let membershipResponseList = [] as MembershipWithPointStampResponse[];

    createMap(
      mapper,
      MembershipDetailResponse,
      MembershipWithPointStampResponse
    );

    if(extMembershipResp){
      membershipResponse = mapper.map(extMembershipResp, MembershipDetailResponse, MembershipWithPointStampResponse);
    }

    if(membershipResponse)
      membershipResponseList.push(membershipResponse);

    return membershipResponseList;
  }
}
