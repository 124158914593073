import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceComponent implements OnInit {
  contentDesc: string = '';

  constructor(
    private location: Location,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    let maintenanceMsg = this.sessionStorageService.getItem('maintenanceMsg');
    this.contentDesc = maintenanceMsg ? maintenanceMsg : '';

    if (!this.contentDesc) {
      this.location.back();
    }
  }

}
