import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from "@angular/common";

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input() color: string;
  @Input() fontSize: string;
  @Input() manualBackEvent: boolean;
  @Output() onManualBackEvent = new EventEmitter();

  constructor(
    private location: Location
  ) { }

  ngOnInit(): void {
  }

  backWithLocation() {
    if (this.manualBackEvent) {
      this.onManualBackEvent.emit();
    } else {
      this.location.back();
    }
  }
}
