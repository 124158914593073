
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StoreMode } from 'src/app/core/enums';
import { RouteName } from 'src/app/core/enums/RouteName';
import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { QrCartQuery } from 'src/app/core/qr-cart/qr-cart.query';
import { QrCartService } from 'src/app/core/qr-cart/qr-cart.service';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { StoreService } from 'src/app/store/store/store.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { SessionStorageService } from '../../storage/session-storage.service';

interface userMenu {
  menuName: string;
  icon: string;
  value: string;
}

@Component({
  selector: 'app-user-menu-dropdown',
  templateUrl: './user-menu-dropdown.component.html',
  styleUrls: ['./user-menu-dropdown.component.scss']
})
export class UserMenuDropdownComponent implements OnInit, OnDestroy {

  @Input() isFromLandingPage: boolean = false;
  @Input() selectedCountryCode: string = '';
  curUser: any;
  sub: Subscription = new Subscription();
  localBaseHref: string = '';

  qrTokenResponse : WebLinkTokenResponse;
  qrCartSub : Subscription;

  userMenuLists: userMenu[] = [{
    //profile
    menuName: 'navigation.Menu.des.4',
    icon: 'oda-user-open',
    value: 'account'
  }, {
    //order
    menuName: 'navigation.Menu.des.5',
    icon: 'oda-utensils',
    value: 'my-order'
  }, {
    //reward
    menuName: 'navigation.Menu.des.7',
    icon: 'oda-gift',
    value: 'reward',
  }, {
    //my favourites
    menuName: 'navigation.Menu.des.6',
    icon: 'oda-heart',
    value: 'favourite-store',
  }
]

  constructor(
    private router: Router,
    private userService: UserService,
    private routingService: RoutingService,
    private channelService: ChannelService,
    private qrCartQuery : QrCartQuery,
    private storeService : StoreService,
    private qrCartService : QrCartService,
    private membershipService: MembershipService,
    private sessionStorageService: SessionStorageService
  ) { }

  ngOnInit(): void {
    this.sub = this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curUser = dt ? dt : null;
    });

    this.qrCartSub = this.qrCartQuery.select(val => val.qrTokenResponse).subscribe(tokenResponse => {
      this.qrTokenResponse = tokenResponse? tokenResponse : null;
    })
  }

  onNavigate(routePage: string) {
    if (this.isFromLandingPage && this.selectedCountryCode) {
      this.routingService.navigateChannelUrl(this.selectedCountryCode, routePage);
    } else {
      switch (routePage) {
        case 'account': {
          this.router.navigateByUrl("/account");
          break;
        }
        case 'my-order': {
          this.router.navigateByUrl("/my-order");
          break;
        }
        case 'reward': {
          this.router.navigateByUrl("reward");
          break;
        }
        case 'favourite-store': {
          this.router.navigateByUrl("/favourite-store");
          break;
        }
        default: {
          this.router.navigateByUrl("/");
          break;
        }
      }
    }
  }

  async onLogout() {
    let routes = this.router.url;
    let splittedRoutes = routes.split('/');
    let currentRoute = splittedRoutes[1];

    let sessionTokenResponse = JSON.parse(this.sessionStorageService.getItem("qrTokenResponse"));

    this.membershipService.removeExternalMembershipData();

    if(sessionTokenResponse && this.qrTokenResponse && (currentRoute == RouteName.Cart ||
      currentRoute == RouteName.Store || currentRoute == RouteName.OrderPayment)){
      this.storeService.openQuitQrDialog();
      this.userService.isLoggedOut$.next(true);
    }
    else if(currentRoute == RouteName.OrderSummary || currentRoute == RouteName.OrderDetail){
      this.qrCartService.orderSummaryQuitCheck();
      await this.userService.logoutInit(this.isFromLandingPage);
      if (!this.isFromLandingPage) {
        this.channelService.getChannel();
      }
    }
    else{
      await this.userService.logoutInit(this.isFromLandingPage);
      if (!this.isFromLandingPage) {
        this.channelService.getChannel();
      }
    }
  }

  ngOnDestroy(){
    this.qrCartSub?.unsubscribe();
  }
}
