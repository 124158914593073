import { AfterViewInit, Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import { ActivitySummary, LogData } from '../../order/order.model';
import { ActivityType } from 'src/app/core/enums/ActivityType';

@Component({
  selector: 'app-return-refund',
  templateUrl: './return-refund.component.html',
  styleUrls: ['./return-refund.component.scss'],
  providers: [ MessageService]
})
export class ReturnRefundComponent implements OnInit, AfterViewInit {
  @ViewChild("container") containerElement: ElementRef;
  refundData: Partial<ActivitySummary>;
  logData: LogData;
  detail: any;
  displayStatus: string;
  showImgDialog: boolean;
  imageUrl: string;
  isSuccess: boolean;

  constructor(private router: Router,
    private messageService: MessageService) { }

  async ngOnInit() {
    this.refundData = history.state.refundData ? JSON.parse(history.state.refundData): null;
    this.isSuccess = history.state.success ? true: false;
    this.logData = this.refundData.logData ? JSON.parse(this.refundData.logData): null;
    this.detail= this.logData['Data'] ? JSON.parse(this.logData['Data']): null;
    this.displayStatus = this.logData? this.checkTitle(this.logData.Title): '';
  }

  ngAfterViewInit() {
    if (this.isSuccess) {
      this.messageService.add({ severity:'success', closable: false });
    }
  }

  private checkTitle(title: ActivityType) {
    let displayTitle: string;

    switch(title) {
      case ActivityType.Refund_requested: {}
        displayTitle = 'refund.return.history.requested';
        break;
      default: 
        displayTitle = '';
        break;
    }

    return displayTitle;
  }

  onBack() {
    this.router.navigate(['/my-order']);
  }

  onShowImage(image: string) {
    this.showImgDialog = true;
    this.imageUrl = image;
  }

  onCloseToast() {
    this.messageService.clear();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if(this.containerElement){
      let containerElement = this.containerElement.nativeElement
      let containerHeight = containerElement.offsetTop + containerElement.offsetHeight + 35;
      let scrollBottom = window.innerHeight + window.scrollY;
 
      if ( scrollBottom > containerHeight ) {
        this.onCloseToast();
      }
    }
  }
}
