<div class="p-d-flex trash-icon-container">
  <i class="color oda-c-trash-circle"><span class="path1"></span></i>
</div>

<p class="remove-description heading-12">{{"menu.selection.remove" | translate}}</p>

<div class="action-button-footer">
  <button class="remove-button" (click)="onClickRemove()">{{"button.remove.item" | translate}}</button>
  <button class="cancel-button" (click)="onClickCancel()">{{"button.cancel" | translate}}</button>
</div>
