import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 , PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  defaultTheme = ['default-theme'];
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: any,
    public rendererFactory: RendererFactory2,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  setTheme(theme: any): void {
    if (isPlatformBrowser(this.platformId)){
      const themes = theme ? theme : null;
      this.renderer = this.rendererFactory.createRenderer(null, null);
      themes.forEach(t => this.renderer.removeClass(document.body, t));
      this.renderer.addClass(document.body, theme);
    }
  }

  loadTheme(channalTag: string): void {
    let tag = channalTag ? channalTag.toLowerCase() : '';

    switch (tag) {
      case '': {
        this.setTheme(this.defaultTheme);
        break;
      }

      case 'orientalcoffee': {
        this.setTheme(['oriental-theme']);
        break;
      }

      case 'bluedome': {
        this.setTheme(['bluedome-theme']);
        break;
      }

      default: {
        this.setTheme(this.defaultTheme);
        break;
      }
    }
  }
}
