import { WebLinkTokenResponse } from 'src/app/core/models/WebLinkTokenResponse';
import { Region } from '../enums/Region';
import { TimeZone } from './../enums/TimeZone';
import { Injectable } from '@angular/core';
import * as momentTz from 'moment-timezone';
import { Moment } from 'moment';
import * as moment from 'moment';
import { QueueResponse } from '../models/QueueResponse';
import { StaticQrState } from '../static-qr/static-qr.model';
import { Router } from '@angular/router';
import { ShortDay } from '../enums/Day';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TimeService{

  timeDifference: number = 0;
  serverTime : any; //any type because it is a moment object
  timezone : string;
  formatWithoutUtc : string = 'YYYY-MM-DD[T]HH:mm:ss';

  constructor(
    private router : Router,
    private sessionStorageService: SessionStorageService
  ){}

  //realign local time with the difference
  realignTime(momentTime : Moment, timeDifference: number){
    if(Math.sign(timeDifference) == 1){
      momentTime.subtract(timeDifference, 'second');
    }
    else if(Math.sign(timeDifference) == -1){
      momentTime.add(-timeDifference, 'second');
    }

    return momentTime;
  }

  //method to get the difference between local and server time
  calculateServerTimeDifference(){
    let curTime : Date = new Date();

    let currentTimeFormat = momentTz.tz(curTime, Region.UTC);

    let serverTime = momentTz.tz(this.serverTime, Region.UTC);

    let timeDifferenceCalculation = momentTz.duration(currentTimeFormat.millisecond(0).diff(serverTime.millisecond(0)));

    this.timeDifference = timeDifferenceCalculation.as('seconds');
  }

  convertToTimezoned(currentDate : Moment){
    let currentDateMoment : Moment
    if(this.timezone){
      currentDateMoment = momentTz.tz(currentDate, this.timezone);
    }
    else{
      currentDateMoment = momentTz(currentDate);
    }

    return currentDateMoment;
  }

  convertToUtc(currentDate : Moment | string){
    let currentDateMoment = moment(currentDate).tz(Region.UTC);

    return currentDateMoment;
  }

  //the current store time will be return in utc without timezone
  getAdjustedLocalTimeInUtc() : Moment{
    let date = new Date();

    let utcDate = momentTz.tz(date, Region.UTC);

    utcDate = this.realignTime(utcDate, this.timeDifference);

    return utcDate;
  }

  setTimeZoneRegion(timezone : string){
    switch(timezone){
      case TimeZone.Singapore_Standard_Time:
      case TimeZone.Taipei_Standard_Time:
      case TimeZone.North_Asia_Standard_Time:
        this.timezone = Region.Asia_Singapore;
        break;
      case TimeZone.Tokyo_Standard_Time:
      case TimeZone.Korea_Standard_Time:
        this.timezone = Region.Asia_Seoul;
        break;
      case TimeZone.AUS_Central_Standard_Time:
        this.timezone = Region.Australia_Darwin;
        break;
      default:
        this.timezone = Region.Asia_Singapore;
        break;
    }
  }

  //might not need this
  //a method to get timezone string
  getTimeZoneRegion(){
    return this.timezone ? this.timezone : Region.Asia_Singapore;
  }

  //method to get server time difference
  getServerTimeDifference(){
    return this.timeDifference;
  }

  //method to get server time
  //server time is UTC value but for is GMT +0
  getServerTime(){
    return this.serverTime;
  }

  setServerTime(serverTime : Date){
    this.serverTime = serverTime;
  }

  formatDateString(dateString : Moment){
    return dateString.format(this.formatWithoutUtc);
  }

  //#region date comparison
  isSameDate(date1 : Date | string, date2 : Date | string){
    return moment(date1).isSame(date2, 'days');
  }

  isSameOrAfterGivenDate(currentTime : any, startDate : string){
    let timeZone = this.getTimeZoneRegion();
    let currentTimeMoment = moment(currentTime).tz(timeZone);

    return currentTimeMoment.isSameOrAfter(startDate, 'day');
  }

  isSameOrBeforeGivenDate(currentTime : any, endDate : string){
    let timeZone = this.getTimeZoneRegion();
    let currentTimeMoment = moment(currentTime).tz(timeZone);

    return currentTimeMoment.isSameOrBefore(endDate, 'day');
  }

  isBetweenGivenDate(currentTime : any, startDate : string, endDate : string){
    let timeZone = this.getTimeZoneRegion();
    let currentTimeMoment = moment(currentTime).tz(timeZone);

    return currentTimeMoment.isSameOrAfter(startDate, 'days') && currentTimeMoment.isSameOrBefore(endDate, 'day');
  }
  //#endregion

  //#region time comparison
  checkIfItToday(timeString: string){
    let localDate = new Date();

    let localDateMoment = momentTz(localDate);
    let timeStringMoment = momentTz(timeString);

    if(localDateMoment.isSame(timeStringMoment, 'days')){
      return true;
    }
    else{
      return false;
    }
  }

  // use to compare if time is after between 2 dateTimes
  compareIsTimeAfter(currentTime : string, timeToCompared : string, timeToAdd : number, unit: moment.unitOfTime.DurationConstructor){
    //change time to utc because library will change to current timezone
    let currentTimeMoment = momentTz.tz(currentTime, Region.UTC);
    let compareTimeMoment = momentTz.tz(timeToCompared, Region.UTC);

    //add the value pass in to reach end dateTime
    compareTimeMoment.add(timeToAdd, unit);

    // if local time is after time stored then return true else false
    if(currentTimeMoment.isAfter(compareTimeMoment)){
      return true;
    }
    else{
      return false;
    }

  }

  isAfterTime(date1: Date, date2: Date) {
    return moment(date1).isSameOrAfter(date2, 'minute');
  }

  isSameOrAfterGivenTime(currentTime : any, startTime : string){
    let timeZone = this.getTimeZoneRegion();
    let currentTimeMoment = moment(currentTime).tz(timeZone);
    // get current date string from current time given
    let currentDate = currentTimeMoment.format("YYYY-MM-DD");

    let timeToCompareMoment = moment(currentDate + ' ' + startTime, "YYYY-MM-DD hh:mm").tz(timeZone);

    return currentTimeMoment.isSameOrAfter(timeToCompareMoment);
  }

  isBeforeGivenTime(currentTime : any, endTime : string){
    let timeZone = this.getTimeZoneRegion();

    let currentTimeMoment = moment(currentTime).tz(timeZone);
    // get current date string from current time given
    let currentDate = currentTimeMoment.format("YYYY-MM-DD");

    let timeToCompareMoment = moment(currentDate + ' ' + endTime, "YYYY-MM-DD hh:mm").tz(timeZone);

    return currentTimeMoment.isSameOrBefore(timeToCompareMoment);
  }

  isBetweenGivenTime(time1 : string, time2 : string, currentTime : any){
    let timeZone = this.getTimeZoneRegion();
    let currentTimeMoment = moment(currentTime).tz(timeZone);

    // get current date string from current time given
    let currentDate = currentTimeMoment.format("YYYY-MM-DD");
    let startTimeMoment = moment(currentDate + ' ' + time1, "YYYY-MM-DD hh:mm").tz(timeZone);
    let endTimeMoment = moment(currentDate + ' ' + time2, "YYYY-MM-DD hh:mm").tz(timeZone).tz(timeZone);

    return currentTimeMoment.isSameOrAfter(startTimeMoment) && currentTimeMoment.isSameOrBefore(endTimeMoment);
  }
  //#endregion

  //#region idle time handler
  storeIdleTime(currentDateTime : Date, idleTime : number){
    currentDateTime.setHours(currentDateTime.getHours() + idleTime);
    this.sessionStorageService.setItem("idleTime", JSON.stringify(currentDateTime.getTime()));
  }

  getIdleTime(){
    let idleTime = JSON.parse(this.sessionStorageService.getItem("idleTime"));
    return idleTime ? idleTime : null;
  }

  removeIdleTime(){
    this.sessionStorageService.removeItem("idleTime");
  }

  async isIdleCheck(currentDateTime : Date){
    let isIdle = false;
    let expiryTime = this.getIdleTime();

    if(currentDateTime.getTime() > expiryTime && expiryTime){
      isIdle = true;
    }

    return isIdle;
  }

  isIdleRouting(qrTokenResponse : WebLinkTokenResponse, queueResponse : QueueResponse, staticQrData : StaticQrState){
    if(qrTokenResponse || staticQrData){
      this.router.navigate(["invalid-qr"]);
    }
    else if(queueResponse){
      this.router.navigate(['queue/queue-invalid']);
    }
    else{
      this.router.navigate(["home"]);
    }
  }
  //#endregion

  getDayFromDate(dateInput : string | Date){
    let currentDate = new Date(dateInput);
    return currentDate.getDay();
  }

  convertDayToNumber(dayOfWeek : string){
    if(!dayOfWeek){
      return [];
    }

    let givenDays = dayOfWeek.split(',');

    let loopLength = givenDays.length;
    let shortDayEnum = ShortDay;
    let numberDaysArray = [];
    for(let i = 0; i < loopLength; i++){
      numberDaysArray.push(shortDayEnum[givenDays[i]]);
    }

    return numberDaysArray;
  }

  formatNextAvailableTime(currentTime : Moment, interval : number = 30){
    let calculatedMinutes = Math.ceil(+currentTime.format("mm") * (1 / interval)) * interval; //calculate first slot time
    return calculatedMinutes;
  }

  async getTimePeriodString(currentDateTime : string, timeToIncrement : number){
    let formattedString : string = "";
    let currentTimeMoment = moment(currentDateTime);
    formattedString += currentTimeMoment.format("HH:mm");
    currentTimeMoment.add(timeToIncrement, 'minutes');

    return formattedString + ' - ' + currentTimeMoment.format("HH:mm");
  }
}
