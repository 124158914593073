import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembershipRewardHistoryResponse } from 'src/app/membership/membership/membership-reward-history-response';
import { MembershipRewardType } from 'src/app/membership/membership/RewardType';
import { MembershipService } from 'src/app/membership/membership/membership.service';
import { TranCode } from 'src/app/membership/membership/TranCode'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-membership-reward-history',
  templateUrl: './membership-reward-history.component.html',
  styleUrls: ['./membership-reward-history.component.scss']
})
export class MembershipRewardHistoryComponent implements OnInit {
  @Input() membershipCode: string;
  @Input() channelId : number;
  @Output() clickClose = new EventEmitter();

  myHistory: MembershipRewardHistoryResponse[];
  myHistoryList: any[] = [];
  myHistoryDateList: any[] = [];
  tranCode = TranCode;
  rewardType = MembershipRewardType;

  skip: number = 0;
  take: number = 20;
  isOnFirstLoad: boolean = true;
  isOnLoad: boolean = false;
  isOnListLoad: boolean = false;
  isMaxLoad: boolean = false;
  isMobileView : boolean;

  constructor(
    private membershipService: MembershipService,
    private breakpointObserver : BreakpointObserver,
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    })
   }

  async ngOnInit() {
   this.isOnFirstLoad = false;
    this.isOnLoad = true;
    await this.loadHistory(this.skip);
    this.isOnLoad = false;
  }

  async loadHistory(skip: number) {
    let myHistoryList: MembershipRewardHistoryResponse[] = [];

    this.myHistory = <MembershipRewardHistoryResponse[]>await this.membershipService.getMembershipRewardHistory(this.membershipCode, this.skip, this.take);

    myHistoryList = this.myHistory ? this.myHistory : [];
    myHistoryList.forEach((history: MembershipRewardHistoryResponse) => {
      this.myHistoryList.push(history);

      let index: number = this.myHistoryDateList.findIndex((date: any) => (
        history.tranDate.split("T")[0] && date.tranDate.split("T")[0] === history.tranDate.split("T")[0]));

      if (index < 0) {
        this.myHistoryDateList.push({
          tranDate: history.tranDate ? history.tranDate : null,
          historyList: [history],
        });
      }
      else {
        this.myHistoryDateList[index].historyList.push(history)
      }
    });

    this.skip = skip + myHistoryList.length;
    this.isMaxLoad = myHistoryList && myHistoryList.length === this.take ? false : true;
  }

  async onHistoryScroll(e) {
    if (!this.isOnLoad && !this.isOnListLoad && !this.isMaxLoad) {
      if (e.target.scrollHeight < e.target.scrollTop + e.target.offsetHeight) {
        this.isOnListLoad = true;
        await this.loadHistory(this.skip);
        this.isOnListLoad = false;
      }
    }
  }

  onClickClose() {
    this.clickClose.emit();
  }

}
