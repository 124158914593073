<div class="popup-msg-container" [ngClass]="{'hidden-lg': showMobileOnly, 'hidden-sm': showWebOnly}">
    <p-dialog [(visible)]="display" [modal]="true" [draggable]=false (onHide)="onHide();" (onShow)="setBlockScroll()">
        <!-- Header -->
        <ng-template pTemplate="header"><span></span></ng-template>

        <!-- Content -->
        <div *ngIf="icon" [class]="{ 'icon-border': iconBgColor }" [style.background-color]="iconBgColor">
            <i [class]="icon" [style.color]="iconColor"></i>
        </div>
        <div *ngIf="iconImage">
            <img class="iconImage" [src]="iconImage" alt="odaring icon" loading="lazy"/>
        </div>
        <p class="title">{{ title | translate }}</p>
        <p class="desc" [innerHtml]="desc | translate"></p>

        <!-- Footer -->
        <ng-template pTemplate="footer"><button type="button" (click)="onHide()" [class.round]="btnRound">{{ btn | translate }}</button></ng-template>
    </p-dialog>
</div>
