import { SortList } from 'src/app/core/models/local/SortList';
import { Component, Input, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ChannelSec } from '../channelsec/channelsec.model';
import { PrimeNGConfig } from 'primeng/api';
import { ChannelService } from '../channel/channel.service';
import { StoreMode } from '../../core/enums';
import { Subscription } from 'rxjs';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { StoreService } from 'src/app/store/store/store.service';
import { StoreResponseListGenericDataResponse } from 'src/app/core/models/StoreResponseListGenericDataResponse';
import { SortedBy } from 'src/app/core/enums/SortedBy';
import { SortRating } from 'src/app/core/enums/SortRating';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { DefaultService } from '../services/default.service';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import * as _ from 'lodash';
import { CartQuery } from 'src/app/core/cart/cart.query';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { OrderH } from 'src/app/core/models/OrderH';
import { CartModel } from 'src/app/core/models/CartModel';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';

export interface searchedtext {
  text: string
}

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {
  @Input() channelSec: ChannelSec | null = null;
  sub: Subscription = new Subscription();
  channel: any = null;
  public searchInput: string = '';
  public resultSearchText: string = '';
  public storesData: StoreResponse[] = null;
  cuisines: any;
  searchResult: StoreResponseListGenericDataResponse = null;
  totalSearchResult: number = 0;
  isMobile: boolean;
  showMobileSelection: boolean = false;
  recentSearch: string[] = [];
  displayStoreDialog: boolean = false;
  storeData: StoreResponse;
  storeList: StoreResponse[];
  currency: CurrencyResponse;
  searchSortList: SortList[] = [
    { sortedBy: SortedBy.Default, sortRating: SortRating.Undefined, label: 'merchant.search.title.best.match', active: true },
    { sortedBy: SortedBy.DeliveryFee, sortRating: SortRating.HighestRating, label: 'merchant.search.filter.1', active: false },
    { sortedBy: SortedBy.DeliveryFee, sortRating: SortRating.LowestRating, label: 'merchant.search.filter.2', active: false },
    { sortedBy: SortedBy.Rating, sortRating: SortRating.HighestRating, label: 'merchant.information.reviews.filter.highest.rating', active: false },
    { sortedBy: SortedBy.Rating, sortRating: SortRating.LowestRating, label: 'merchant.information.reviews.filter.lowest.rating', active: false },
  ]
  currentFilter: string = "merchant.search.title.best.match"
  sortList: SortList = {
    sortedBy: SortedBy.Default,
    sortRating: SortRating.Undefined,
    label: "merchant.search.title.best.match"
  }
  isOnLoad: boolean = false;
  isContLoadData: boolean = true;
  routeSearchText: string = '';
  cartSub$ : Subscription;
  cartModel : CartModel;
  isLoading : boolean = false;
  channelId: number;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private channelService: ChannelService,
    private primengConfig: PrimeNGConfig,
    private storeService: StoreService,
    private breakpointObserver: BreakpointObserver,
    private defaultService: DefaultService,
    private cartQuery : CartQuery,
    private staticQrService : StaticQrService
  ) {
    if (localStorage.getItem('recent-search') === '' || localStorage.getItem('recent-search') === null) {
      localStorage.removeItem('recent-search');
    } else {
      this.recentSearch = JSON.parse(localStorage.getItem('recent-search'));
    }

    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this.primengConfig.ripple = true;
    await this.channelService.getChannelDataCheck();
    this.channelId = this.channelService.getChannelId();
    this.route.queryParams.subscribe((params: Params) => {
      this.searchInput = params.searchInputText;
      this.routeSearchText = params.searchInputText;
      if (this.searchInput) {
        let localRecentSearch = JSON.parse(localStorage.getItem('recent-search'))
        this.recentSearch = localRecentSearch ? localRecentSearch : [];
        this.searchStoresByText(this.searchInput);
      } else {
        this.showMobileSelection = true;
        this.storesData = null;
      }
    });

    this.sub = this.channelService.get(StoreMode.Internal).subscribe((dt) => {
      if (dt) {
        this.cuisines = dt.data.channelCatgAttrs[0] ? dt.data.channelCatgAttrs[0].catgAttrOptions : [];
        this.currency = dt.data.currency;
      }
    })

    this.defaultService.popupStoreMsg.subscribe(data => {
      this.storeData = data;
      this.storeList = data.otherStores;
    })

    this.defaultService.showStorePopupMsg.subscribe(data => {
      this.displayStoreDialog = data;
    })

    this.cartSub$ = this.cartQuery.getCurrentCart().subscribe(cart => {
      this.cartModel = cart && cart.cartModel ? cart.cartModel : null;
    });

    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.cartSub$?.unsubscribe();
  }

  onClickDisplaySearchDropdown() {
    if (!this.isMobile) {
      this.defaultService.showSearchFilterDialog(true, false);
    }
  }

  onClickSearchAgain() {
    this.searchInput = '';
    this.showMobileSelection = true;
    if (!this.isMobile) {
      this.defaultService.showSearchFilterDialog(true, false);
    }
  }

  clearSearchField() {
    this.searchInput = '';
    this.showMobileSelection = true;
    this.onClickDisplaySearchDropdown();
  }

  onRecentClick(searchText: string) {
    this.searchInput = searchText;
    this.defaultService.showSearchFilterDialog(false, false);
    this.router.navigate(['search-result'], { queryParams: { searchInputText: searchText } });
  }

  removeRecentRecord(index: number) {
    this.recentSearch.splice(index, 1);
    localStorage.setItem('recent-search', JSON.stringify(this.recentSearch));
  }

  //remove all recent search history
  ondelete() {
    this.recentSearch = [];
    localStorage.removeItem('recent-search');
  }

  async searchStoresByText(searchInputText: string, skip?: number, take?: number) {
    this.isOnLoad = true;

    this.resultSearchText = searchInputText;

    let resp = await this.storeService.getStores(
      this.storeService?.address?.latitude,
      this.storeService?.address?.longitude,
      skip ? skip : 0,
      take ? take : 10,
      this.channelId,
      undefined,
      this.channelService?.curChannel?.orderType,
      undefined,
      undefined,
      searchInputText,
      this.sortList.sortedBy,
      this.sortList.sortRating,
    )

    let respBody = resp["body"];

    if (!skip) {
      this.searchResult = respBody;
      this.isContLoadData = true;
      window.scroll({ top: 0, left: 0 });
    } else {
      let currentSearchResult = this.searchResult ? this.searchResult["data"] : [];
      this.searchResult["data"] = _.concat(currentSearchResult, respBody["data"]);
    }

    if (resp && resp.headers) {
      this.totalSearchResult = resp.headers.get('X-Total-Count');
    }

    this.storesData = this.searchResult && this.searchResult.data ? this.searchResult.data : [];
    this.defaultService.showSearchFilterDialog(false, false);
    this.showMobileSelection = false;
    await this.recentSearchReorder(searchInputText);
    this.isOnLoad = false;

    return respBody;
  }

  async recentSearchReorder(searchInputText: string) {
    let matchSearchIndex = this.recentSearch.findIndex(search => search === searchInputText);
    if (matchSearchIndex !== -1) {
      this.recentSearch.splice(matchSearchIndex, 1);
    }
    this.recentSearch.push(searchInputText);

    if (this.recentSearch && this.recentSearch.length > 5) {
      this.recentSearch.shift();
    }
    localStorage.setItem('recent-search', JSON.stringify(this.recentSearch));
  }

  outputSorting(sortList: SortList) {
    this.sortList = sortList
    this.searchStoresByText(this.searchInput, 0, 10);
  }

  @HostListener('window:scroll', ['$event'])
  async onWindowScroll() {
    if (!this.isOnLoad && this.isContLoadData && this.totalSearchResult != 0) {
      const reduce = this.isMobile ? 0 : 380;
      if (Math.ceil(window.innerHeight + window.scrollY) >= (document.body.scrollHeight - reduce)) {
        this.isOnLoad = true;
        let storeSkip = this.storesData ? this.storesData.length : 0;

        let stores = await this.searchStoresByText(this.resultSearchText, storeSkip, 10);

        if (stores && stores["data"].length === 10) {
          this.isContLoadData = true;
        } else {
          this.isContLoadData = false;
        }
        this.isOnLoad = false;
      }
    }
  }

  outputSearchStore(searchInputText: any) {
    this.searchStoresByText(searchInputText);
  }

  outputRecentSearch() {
    this.recentSearch = JSON.parse(localStorage.getItem('recent-search'));
  }

  async onClickItemCard(storeData : StoreResponse){
    this.storeService.removeIsDisplayedFlag();

    let globalOrderType = this.staticQrService.getChannelOrderType();
    let storeCart : OrderH = null;
    let orderType : string;

    if((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.cartModel && this.cartModel?.orderHs && this.cartModel.orderHs?.length > 0){
      storeCart = this.cartModel.orderHs.find(orderH => orderH.storeId == storeData.storeId);
    }

    if(storeCart){
      orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
    }
    else{
      orderType = storeData.currentOrderType;
    }
    this.storeService.preStoreNavigation(orderType, storeData);
  }
}
