import { QueueCartQuery } from './queue-cart.query';
import { UserService } from './../user/user.service';
import { Injectable } from "@angular/core";
import { CartModel } from "../models/CartModel";
import { CustomRequest } from "../models/CustomRequest";
import { StoreMode } from '../enums';
import { User } from '../user/user.model';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { TimeService } from '../services/time.service';
import { CustomService } from '../services/custom.service';
import { OrderH } from '../models/OrderH';
import { HttpErrorResponse } from '@angular/common/http';
import { QueueCart } from './queue-cart.model';
import { QueueCartStore } from './queue-cart.store';
import { StorageService } from 'src/app/shared/services/storage.service';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class QueueCartService {

  isLoggedIn : boolean;
  accessToken : string;
  user : User;

  constructor(
    private userService : UserService,
    private timeService : TimeService,
    private customService: CustomService,
    private queueCartStore : QueueCartStore,
    private queueCartQuery : QueueCartQuery,
    private storageService : StorageService
  ){
    this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.isLoggedIn = userData? true : false;
      this.user = userData? userData : "null";
    });
  }

  addCart(data: QueueCart, storeId : number) {
    let cartData = _.cloneDeep(data) as QueueCart;

    this.queueCartStore.upsert(storeId, cartData);
  }

  removeCart(){
    this.queueCartStore.remove();
  }

  getCartByValue(storeId : number){
    return this.queueCartQuery.getEntity(storeId);
  }

  getCartByEntity(storeId : number){
    return this.queueCartQuery.selectEntity(storeId);
  }

  async recalculateCart(channelId: number, storeId : number, cartModel: CartModel, queueNo : string, saveCart : boolean = true, updateState: boolean = false) {
    cartModel = this.processCartModel(cartModel);

    let respData = null;
    respData = await this.reqRecalculateCart(channelId, cartModel, saveCart);

    if(respData instanceof HttpErrorResponse === false){
      if (updateState) {
        this.addCart({
          id: storeId,
          cartModel: respData['body'],
          queueNo: queueNo ? queueNo : undefined
        }, storeId)
      }

      let paymentMethods = respData['body'].paymentMethods;
      this.storageService.setSessionStorage('pms', paymentMethods);

      return respData['body'];
    }
    else{
      return respData;
    }
  }

  private async reqRecalculateCart(channelId: number, cartModel: CartModel, saveCart : boolean = true) {
    let newCr = {} as CustomRequest;

    if (this.isLoggedIn) {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId,
          saveCart: saveCart
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest
    }
    else {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId
        }
      } as CustomRequest
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"));

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }

  processCartModel(cartModel: CartModel) {
    if(cartModel && cartModel.orderHs && cartModel.orderHs?.length > 0) {
      cartModel.orderHs.forEach((orderH: OrderH) => {
        orderH.orderData.orderVs = [];
        orderH.orderData.menuRowVersion = orderH.orderData.menuRowVersion? orderH.orderData.menuRowVersion: "0";
      });
    }

    return cartModel;
  }
}
