import { QueueService } from 'src/app/queue/queue/queue.service';
import { environment } from 'src/environments/environment';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { OtpService } from './../services/otp.service';
import { Location } from "@angular/common";
import { UserQuery } from 'src/app/core/user/user.query';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { PreviousRoute } from 'src/app/core/models/PreviousRoute';
import { PageName } from 'src/app/core/enums';

@Injectable({
  providedIn: 'root'
})
export class OtpCanActivate implements CanActivate {

  isLoggedIn: boolean;

  constructor(
    private router: Router,
    private otpService: OtpService,
    private userQuery: UserQuery,
    private location: Location,
    private previousRouteService: PreviousRouteService,
    private queueService : QueueService
  ) {
    this.userQuery.isLoggedIn$.subscribe(val => {
      this.isLoggedIn = val && val.id != 999 ? true : false;
    })
  }

  canActivate(route: ActivatedRouteSnapshot) {
    let previousRoute: PreviousRoute = this.previousRouteService.getPreviousRoute();
    const optObj = this.otpService.getOtpObj();
    let resolvedUrl = this.getResolvedUrl(route);
    let queueData = this.queueService.getQueueData();

    if (!this.isLoggedIn) {
      if((!(optObj && optObj.mobileObj && optObj.pageName) && !resolvedUrl.includes("queue/otp")) && queueData){
        this.queueService.navigateToPreQueue(true);
      }
      else if ((!(optObj && optObj.mobileObj && optObj.pageName) && !resolvedUrl.includes("queue/otp")) || ((route.url[0].path === 'captcha') && previousRoute.routeName === '/otp')) {
        return this.router.parseUrl("/login");
      }
      else if(!(optObj && optObj.mobileObj && optObj.pageName) && resolvedUrl.includes("queue/otp")){
        this.location.back();
        return false;
      }
      return true;
    } else if(optObj.pageName && optObj.pageName === PageName.VerifyMobilePage) {
      return true;
    }else {
      this.location.back();
      return false;
    }

    return true;
  }

  getResolvedUrl(route : ActivatedRouteSnapshot){
    return route.pathFromRoot
    .filter(routeData => routeData.url.length > 0)
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
  }
}



