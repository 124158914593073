import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortList } from 'src/app/core/models/local/SortList';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {

  @Input() sortList: SortList[];
  @Input() currentFilter: string;
  @Input() currentTab: string;
  @Input() dropdownRight: boolean = false;
  @Output() outputSorting = new EventEmitter<any>();

  openSortDialog: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onSorting(sortText: SortList, index: number) {
    this.openSortDialog = false;
    this.sortList.map(sort => {
      if (sort && sort == this.sortList[index]) {
        sort.active = true;
      } else {
        sort.active = false;
      }
    });

    this.outputSorting.emit(sortText);
  }

  onClickMobileSortButton(){
    this.openSortDialog = true;
  }
}
