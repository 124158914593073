import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { CxmCustomerCCard } from 'src/app/core/models/CxmCustomerCCard';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { ChannelService } from 'src/app/home/channel/channel.service';

@Component({
  selector: 'app-card-detail',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss']
})
export class CardDetailComponent implements OnInit {
  savedCard: CxmCustomerCCard[] = [];
  user: User;
  isLogIn: boolean;
  channelId: number;
  customerId: number | string;
  isMobileView: boolean = false;

  displayCard: boolean = false;
  displayRemoveDialog: boolean = false;
  closeDialog: boolean = false;
  deleteCard: boolean = false;
  selectedCard : any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private channelService: ChannelService,
    private userService: UserService,
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state: BreakpointState) => {
      this.isMobileView = state && state.matches ? true : false;
    })
  }

  async ngOnInit(): Promise<void> {
    await this.channelService.getChannelDataCheck();
    this.channelId = this.channelService.getChannelId();
    this.user = this.userService.getCustomer();
    this.isLogIn = this.user ? true : false;
    this.customerId = this.user?.customerId ? this.user.customerId : "null";
    this.savedCard = await this.getSavedCard();

    if (this.isLogIn && this.savedCard.length > 0) {
      this.displayCard = true;
    }
  }

  async deleteSavedCard(card: CxmCustomerCCard) {
    let deleteSavedCard = await this.userService.deleteCustomerSavedCard(card, this.channelService.getChannelId());
    if (deleteSavedCard == true) {
      this.savedCard = await this.getSavedCard();
    }
    if(this.isMobileView){
      this.displayRemoveDialog = false;
    }
  }

  async getSavedCard() {
    return this.user ? await this.userService.getCustomerSavedCard(this.channelId, +this.customerId) : null;
  }

  async openDialog(card: CxmCustomerCCard) {
    this.displayRemoveDialog = true;
    this.selectedCard = card;
  }

}
