<div class="item-card-container">
  <!--store image-->
  <div class="store-image">
    <div [ngClass]="{'disabled-img': storeData.currentStatus !== storeStatusFlag.Open}">
      <img [src]="storeData | storeImage : attrCode.OUTLETIMG"
           onerror="this.src='assets/errorMsg_image/imageError.webp'" alt="odaring image" loading="lazy"/>

      <p *ngIf="storeData.badgeText && storeData.badgeText != 'NULL'" class="badgeText">{{storeData.badgeText}}</p>
    </div>
    <!--store current status-->
    <div *ngIf="storeData.currentStatus !== storeStatusFlag.Open" class="disabled-img-text">
      <span *ngIf="storeData.currentStatus === storeStatusFlag.Closed">
        {{'store.current.status.closed' | translate}}
      </span>
      <span *ngIf="storeData.currentStatus === storeStatusFlag.OutofCoverage">
        {{'store.current.status.out.of.service' | translate}}
      </span>
      <span *ngIf="storeData.currentStatus === storeStatusFlag.TempClosed">
        {{'store.current.status.temporarily.closed' | translate}}
      </span>
    </div>
  </div>

  <!--store details-->
  <div class="store-details" [ngClass]="{'disabled-store':  storeData.currentStatus !== storeStatusFlag.Open}" style="padding-top: 19px">
    <h1 class="store-heading-1">{{storeData.locDesc}}</h1>
    <p class="store-heading-2">{{getStoreAttrDesc(storeData.storeCatgAttrs)}}</p>

    <div class="p-d-flex p-flex-wrap">
      <!--distance-->
      <p class="store-heading-3 p-mr-2" *ngIf="storeData.distance"><i class="oda-pin"></i>{{"merchant.delivery.distance" | translate: { distance: storeData.distance | number : '.1-1' } }}</p>
      <!--order type-->
      <p class="p-mr-2 store-heading-3">
        <i class="{{storeData.currentOrderType | orderTypeIcon}}"></i>
        <ng-template [ngIf]="storeData.currentOrderType == orderTypeFlag.Delivery && storeData.deliveryTime">
          {{ storeData.deliveryTime > 1 ? 'store.delivery.minutes' : 'store.delivery.minute' | translate: { minute: storeData.deliveryTime } }} -
        </ng-template>
        <span *ngIf="storeData.currentOrderType == orderTypeFlag.Delivery && storeData.deliveryFee">{{storeData.deliveryFee ? (storeData.deliveryFee | currency: currency.currCode : 'symbol-narrow') : 'free.delivery' | translate }}</span>
        <span *ngIf="storeData.currentOrderType == orderTypeFlag.Delivery && !storeData.deliveryTime && !storeData.deliveryFee">{{"free.delivery" | translate}}</span>
        <span *ngIf="storeData.currentOrderType == orderTypeFlag.Pickup">{{"order.type.select.pick.up" | translate}}</span>
        <span *ngIf="storeData.currentOrderType == orderTypeFlag.DineIn">{{"order.type.select.dine.in" | translate}}</span>
      </p>
      <!--rating-->
      <p class="store-heading-3" *ngIf="storeData.ratingAverage !== 0"><i class="oda-star"></i>{{storeData.ratingAverage | number : '.1-1'}}</p>
    </div>

    <hr class="item-card-split-line" *ngIf="storeData.promoText && storeData.promoText != 'NULL' || (storeData.otherStores.length > 1 && showStoreNearBy)">

    <!--prmotion text-->
    <p class="store-heading-4" *ngIf="storeData.promoText && storeData.promoText != 'NULL'"><i class="oda-discount"></i>{{storeData.promoText}}</p>
  </div>

  <!--store status-->
  <div class="store-status" *ngIf="storeData.currentStatus !== storeStatusFlag.Open">
    <i class="oda-clock"></i>
    <label *ngIf="storeData.currentStatus === storeStatusFlag.Closed && isStoreClosedPreOrder">
      {{"pre.order.from" | translate}}
      <span>{{storeData.currentStatus | nextAvailableTime : storeData}}</span>
    </label>
    <label *ngIf="storeData.currentStatus === storeStatusFlag.Closed && !isStoreClosedPreOrder">
      {{'store.qr.close' | translate}}
    </label>
    <label *ngIf="storeData.currentStatus === storeStatusFlag.OutofCoverage">{{'store.current.status.out.of.service' | translate}}</label>
    <label *ngIf="storeData.currentStatus === storeStatusFlag.TempClosed">{{'store.current.status.temporarily.closed' | translate}}</label>
  </div>

  <!--nearby store-->
  <div class="nearby-store-container" *ngIf="storeData.otherStores.length > 1 && showStoreNearBy">
    <div (click)="onClickOtherStore(storeData); $event.stopPropagation()">
      <ng-template [ngIf]="storeData.otherStores.length > 1">
        {{'item.card.number.stores.nearby' | translate: {count: storeData.otherStores.length} }}
      </ng-template>
    </div>
  </div>

</div>
