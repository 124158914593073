import { Component, OnInit, ViewEncapsulation, Input, HostListener, EventEmitter, Output } from '@angular/core';
import { EntryMode } from 'src/app/core/enums/EntryMode';
import { MenuItem } from 'src/app/core/models/MenuItem';
import * as _ from 'lodash';

@Component({
  selector: 'app-popoular-order-slider',
  templateUrl: './popoular-order-slider.component.html',
  styleUrls: ['./popoular-order-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopoularOrderSliderComponent implements OnInit {

  @Input() crossSellItem : MenuItem[];
  @Input() currencyCode : string;
  @Input() isUpsell : boolean;
  @Input() currentItem : MenuItem;

  @Output() clickItem = new EventEmitter();

  isMobile : boolean;
  entryMode = EntryMode;

  constructor( ) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }

  onClickItem(menuItem : MenuItem, entryMode : string){
    let cloneCrossSellItem = _.cloneDeep(menuItem);
    cloneCrossSellItem.entryMode = entryMode;
    this.clickItem.emit(cloneCrossSellItem);
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.isMobile = window.innerWidth <= 991 ? true : false;
  }
}
