import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { CustomHeader, CustomRequest, HMacObj } from '../models/CustomRequest';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { UtilsService } from './utils.service';
import * as _ from 'lodash';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomService {
  private curHttpHeader: CustomHeader = {
    apiVersion: '1',
    subId: environment.subscription,
    hmacAuth: '',
    accessToken: '',
    contentLanguage: 'en',
    contentType: 'application/json',
    dataVersion: ''
  };

  globalCustomRequest: CustomRequest = null;

  constructor(private http: HttpClient,
    private utilsService: UtilsService) {
  }

  createRequest(cus: CustomRequest, isCompression? : boolean, isOriBody? : boolean) {
    const utilsSv = this.utilsService;
    let reqDt = cus;
    reqDt = this.mappingCustomRequest(reqDt);
    let cusRequest: any = null;
    if (reqDt.httpMethod === HttpMethod.GET) {
      if(isCompression){
        cusRequest = new HttpRequest(reqDt.httpMethod, reqDt.hostPath + reqDt.requestpath + (reqDt.queryParams? '?' + utilsSv.createHttpQueryString(reqDt.queryParams): ''), {
          headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers), responseType: 'arrayBuffer' as 'json'
        });
      }else{
        cusRequest = new HttpRequest(reqDt.httpMethod, reqDt.hostPath + reqDt.requestpath + (reqDt.queryParams? '?' + utilsSv.createHttpQueryString(reqDt.queryParams): ''), {
          headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers), responseType: reqDt.responseType ? reqDt.responseType : 'json'
        });
      }
    } else if (reqDt.httpMethod === HttpMethod.POST || reqDt.httpMethod === HttpMethod.PUT || reqDt.httpMethod === HttpMethod.DELETE) {
      cusRequest = new HttpRequest(reqDt.httpMethod, reqDt.hostPath + reqDt.requestpath + (reqDt.queryParams? '?' + utilsSv.createHttpQueryString(reqDt.queryParams): ''),
        isOriBody? reqDt.body: JSON.stringify(reqDt.body), {
          headers: this.createHeader(reqDt.httpHeaderType, reqDt.headers),
        });
    }

    if (!cusRequest) {
      throw 'create Error Obj';
    }

    this.globalCustomRequest = cus;

    return this.http.request(cusRequest).toPromise().catch((error: HttpErrorResponse) => {
      return error;
    });
  }

  initHttpHeader(headers: CustomHeader) {
    this.curHttpHeader = headers;
  }

  private createHeader(headerType: HttpHeaderType, headers: CustomHeader) {
    let header = new HttpHeaders()
      .set("api-version", headers.apiVersion)
      .set("SubscriptionID", headers.subId)
      .set("X-Signature", headers.hmacAuth)
      .set("Content-Language", headers.contentLanguage);

    if (headerType === HttpHeaderType.Auth && headers.accessToken) {
      header = header.append("Authorization", "Bearer" + " " + headers.accessToken);
    }

    if (headers.contentType) {
      header = header.append("Content-Type", headers.contentType);
    }

    if(headers.channelTag){
      header = header.append("channelTag", headers.channelTag);
    }

    if (headers.dataVersion) {
      header = header.append("Data-Version", headers.dataVersion);
    }

    return header;
  }

  private mappingCustomRequest(cus: CustomRequest) {
    // sha256(POST/api/customer/registercustomer1612768937028b628d-8bc0-4ea3-b51b-1edab6980379{"api-version":"1","SubscriptionID":"00009","HMAC_Auth":"1","Authorization":"Bearer ","Content-Type":"application/json"}):1612768937:028b628d-8bc0-4ea3-b51b-1edab6980379)
    const cusReq = _.cloneDeep(cus);
    cusReq.headers = {
      ...this.curHttpHeader,
      ...cusReq.headers,
      hmacAuth: this.assignHMac(cusReq)
    };
    return cusReq;
  }

  public assignHMac(cus: CustomRequest) {
    const utilsSv = this.utilsService;
    let hMacObj: Partial<HMacObj> = {
      httpmethod: cus.httpMethod,
      requestpath: cus.requestpath,
      queryParams: cus.queryParams,
      date: new Date(),
      guid: utilsSv.createUUID(),
      body: cus.body
    }
    return this.constructHMacObj((hMacObj as HMacObj));
  }

  private constructHMacObj(hMacObj: HMacObj) {
    const utilsSv = this.utilsService;
    return utilsSv.createHttpHeaderHMac(hMacObj);
  }

  public getRecallCustomRequest() {
    return this.globalCustomRequest;
  }
}
