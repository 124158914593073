import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { SetCode } from 'src/app/core/enums/SetCode';
import { PlatformSet } from 'src/app/core/models';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { Channel } from 'src/app/home/channel/channel.model';
import { ChannelQuery } from 'src/app/home/channel/channel.query';
import { UserQuery } from '../../../core/user/user.query';
import { environment } from 'src/environments/environment';
import { ChannelService } from 'src/app/home/channel/channel.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  @Input() isUserLogin: boolean;

  selectedAddress$: Observable<any>;
  selectedChannel: Channel;
  isMobileView: boolean = false;
  orderTypeFlag = OrderTypeFlag;
  channelSetting: PlatformSet[] = [];
  shareTable: boolean = false;
  staticTableNo: string = '';
  tblNoLbl: string = '';
  channelSub$: Subscription = new Subscription();
  staticQrSub$: Subscription = new Subscription();
  defaultChannel = environment.odaringChannel;
  currentOrderType : string = OrderTypeFlag.All;

  constructor(
    private userQuery: UserQuery,
    private channelQuery: ChannelQuery,
    private breakpointObserver : BreakpointObserver,
    private staticQrService: StaticQrService,
    private channelService : ChannelService
  ) {
    this.breakpointObserver.observe(['(max-width: 991px)']).subscribe((state : BreakpointState) => {
      if(state.matches){
        this.isMobileView = true;
      }
      else{
        this.isMobileView = false;
      }
    });
  }

  ngOnInit(): void {
    this.selectedAddress$ = this.userQuery.selectedAddress$;
    this.channelSub$ = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      this.selectedChannel = channel;
      this.currentOrderType = channel && channel?.orderType ? channel.orderType : OrderTypeFlag.All;
      const orderTypeCount = await this.channelService.getOrderTypeCount(channel && channel?.data ? channel.data : null);

      if(orderTypeCount == 1){
        this.currentOrderType = await this.channelService.getOrderTypeWhenOnlyOneType(channel.data);
      }

      this.channelSetting = channel?.data?.platformSets ? channel?.data?.platformSets : [];
      let shareTableSetting = this.channelSetting.find(setting => setting.setCode === SetCode.SHARETABLE)?.setValue;
      this.shareTable = shareTableSetting && shareTableSetting == '1' ? true : false;

      let reqTblNoLblSetting = this.channelSetting.find(setting => setting.setCode === SetCode.DITBLNOLBL)?.setValue;
      this.tblNoLbl = reqTblNoLblSetting ? reqTblNoLblSetting : '';
    });

    this.staticQrSub$ = this.staticQrService.getStaticQrData().subscribe(staticQrData => {
      this.staticTableNo = staticQrData.tableNo ? staticQrData.tableNo : null;
    });
  }

  ngOnDestroy() {
    this.channelSub$?.unsubscribe();
    this.staticQrSub$?.unsubscribe();
  }
}
