import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { ChannelSecStore } from './channelsec.store';
import { ChannelSec } from './channelsec.model';
import { StoreMode } from 'src/app/core/enums';
import { ChannelSecQuery } from './channelsec.query';
import { CustomService } from 'src/app/core/services/custom.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { CustomRequest } from 'src/app/core/models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ChannelSecService {
  accessToken: string = "";
  refreshToken: string = "";
  curUser: User | null = null;

  curChannel: any = null;

  constructor(private channelSecStore: ChannelSecStore,
    private http: HttpClient,
    private customService: CustomService,
    private utilsService: UtilsService,
    private channelQuery: ChannelSecQuery,
    private userService: UserService) {

    this.get(StoreMode.Internal).subscribe((dt: any) => {
      this.curChannel = dt ? dt : null;
    });

    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.accessToken = dt && dt['accessToken']? dt['accessToken']: '';
      this.refreshToken = dt && dt['refreshToken']? dt['accessToken']: '';
      this.curUser = dt? dt : null;
    });
  }

  resetChannelHomeSection(channelSection: ChannelSec[]) {
    this.removeAll();
    this.channelSecStore.add(channelSection);
  }

  get(storeMode: StoreMode) {
    let entities = null;
    if (storeMode === StoreMode.External) {
      this.channelSecStore.setLoading(true);
      entities = this.http.get('https://akita.com');
      this.channelSecStore.setLoading(false);
    }
    return this.channelQuery.selectAll();
  }

  add(store: ChannelSec, formatSourceFlag: boolean = false) {
    let storeDt = _.cloneDeep(store) as ChannelSec;
    if (formatSourceFlag) {
      storeDt = this.format(storeDt);
    }
    this.channelSecStore.add(storeDt);
  }

  format(store: ChannelSec) {
    let storeDt = _.cloneDeep(store) as ChannelSec;
    let inData = _.cloneDeep(storeDt.data);
    storeDt.data.data = Object.entries(inData).filter(dd => dd[0].indexOf("data_") === 0).map((dd) => {
      const ind = Number(dd[0].replace("data_", ""));
      return {
        index: ind,
        dataSet: dd[1]
      };
    });
    return storeDt;
  }

  update(id: any, store: Partial<ChannelSec>) {
    this.channelSecStore.update(id, store);
  }

  remove(id: ID) {
    this.channelSecStore.remove(id);
  }

  removeAll() {
    this.channelSecStore.remove();
  }

  clear() {
    if (!this.curChannel) {
      return;
    }
    this.remove(this.curChannel.id);
  }

  error(err: any) {
    this.channelSecStore.setError(err);
  }

  destroy() {
    this.channelSecStore.destroy();
  }

  private setActive(tizId: number) {
    this.channelSecStore.setActive(tizId);
  }

  async getCustomerChannel() {
    let respDt = null;
    respDt = await this.reqCustomerChannel(this.accessToken, 'MadamLow');
    return respDt;
  }

  async getChannel() {
    let respDt = null;
    respDt = await this.reqChannelData(this.accessToken, 3.0819038, 101.5852720);
    return respDt;
  }

  async getHomeChannelSection() {
    let respDt = null;
    respDt = await this.reqHomeChannelSection(this.accessToken, 3.0819038, 101.5852720);
    return respDt;
  }

  async deleteCustomerChannel() {
    let respDt = null;
    respDt = await this.reqDeleteCustomerChannel(this.accessToken, 'MadamLow');
    return respDt;
  }

  private async reqCustomerChannel(accessToken: string, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      // queryParams: {
      //   channelTag: channelTag
      // },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelSecStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelSecStore.setLoading(false);
    return respInfo;
  }

  private async reqChannelData(accessToken: string, latitude: number, longitude: number, skip: number = 1, take: number = 3, channelId: number = 4, channelTag: string = environment.odaringChannel, orderType: string = 'All', storeAttrs: string = '') {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetChannelData,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      queryParams: {
        latitude: latitude,
        longitude: longitude,
        skip: skip,
        take: take,
        // channelId: channelId,
        channelTag: channelTag,
        orderType: orderType,
        storeAttrs: storeAttrs
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelSecStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelSecStore.setLoading(false);
    return respInfo;
  }

  private async reqHomeChannelSection(accessToken: string, latitude: number, longitude: number, skip: number = 3, take: number = 10, channelId: number = 4, homeSectionId: number = 6, channelTag: string = environment.odaringChannel, orderType: string = 'All', storeAttrs: string = '') {
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.channel.GetChannelHomeSection,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken
      },
      queryParams: {
        latitude: latitude,
        longitude: longitude,
        skip: skip,
        take: take,
        // channelId: channelId,
        homeSectionId: homeSectionId,
        channelTag: channelTag,
        orderType: orderType,
        storeAttrs: storeAttrs
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelSecStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelSecStore.setLoading(false);
    return respInfo;
  }

  private async reqDeleteCustomerChannel(accessToken: string, channelTag: string) {
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.channel.DeleteCustomerChannel,
      hostPath: environment.hostPath,
      headers: {
        accessToken: accessToken,
      },
      queryParams: {
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest;
    let respInfo = null;
    this.channelSecStore.setLoading(true);
    respInfo = await this.reqCustomHttpCall(newCr);
    this.channelSecStore.setLoading(false);
    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq).then((dd: any) => dd['body']);
  }

  private formatSrvDateStr(date: Date) {
    const utilSv = this.utilsService;
    return utilSv.formatSrvDateStr(date);
  }
}
