import { QueueStatusFlag } from './../../core/enums/QueueStatusFlag';
import { AcknowledgeQueueRequest } from './../../core/models/AcknowledgeQueueRequest';
import { QueueDataStore } from './../../core/queue-data/queue-data.store';
import { CancelQueueInfoRequest } from './../../core/models/CancelQueueInfoRequest';
import { UpdateQueueInfoRequest } from './../../core/models/UpdateQueueInfoRequest';
import { ToastData } from './../../core/models/ToastData';
import { ToastService } from './../../shared/services/toast.service';
import { StoreService } from 'src/app/store/store/store.service';
import { QueueData } from './../../core/models/local/QueueData';
import { HttpErrorResponse } from '@angular/common/http';
import { JoinQueueRequest } from './../../core/models/JoinQueueRequest';
import { Injectable } from '@angular/core';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { StoreMode } from 'src/app/core/enums';
import { HttpHeaderType } from 'src/app/core/enums/HttpHeaderType';
import { CustomRequest } from 'src/app/core/models/CustomRequest';
import { CustomService } from 'src/app/core/services/custom.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { QueueDataQuery } from 'src/app/core/queue-data/queue-data.query';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { OrderH } from 'src/app/core/models/OrderH';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { ChannelSecService } from 'src/app/home/channelsec/channelsec.service';
import { SessionStorageService } from 'src/app/shared/storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class QueueService {

  accessToken: string = "";
  refreshToken: string = "";

  toQuitQueue$ : Subject<boolean> = new Subject<boolean>();
  toRemoveQueueCart$ : Subject<boolean> = new Subject<boolean>();
  isQueueInfoPage : boolean;

  constructor(
    private userService: UserService,
    private customService : CustomService,
    private router : Router,
    private storeService : StoreService,
    private toastService : ToastService,
    private queueDataStore : QueueDataStore,
    private queueDataQuery : QueueDataQuery,
    private channelSecService : ChannelSecService,
    private sessionStorageService: SessionStorageService
  ) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      if(dt){
        this.accessToken = dt && dt['accessToken'] ? dt['accessToken'] : '';
        this.refreshToken = dt && dt['refreshToken'] ? dt['refreshToken'] : '';
      }
    });
  }

  getQueueData(){
    let queueData = _.cloneDeep(this.queueDataQuery.getValue());

    if(queueData && (queueData?.storeData || queueData?.queueResponse)){
      return queueData;
    }
    else{
      return null;
    }
  }

  getQueueResponseObservable(){
    return this.queueDataQuery.select(state => state.queueResponse);
  }

  removeQueueData(){
    this.queueDataStore.reset();
  }

  saveQueueData(queueData : QueueData){
    this.queueDataStore.update(state => {
      return{
        rsvTokenId: queueData && queueData?.rsvTokenId ? queueData.rsvTokenId : undefined,
        storeData: queueData && queueData?.storeData ? queueData.storeData : null,
        queueResponse: queueData && queueData?.queueResponse ? queueData.queueResponse : null
      }
    })
  }

  getChannelTagInLocalStorage(){
    return localStorage.getItem('channelTag');
  }

  async getOrJoinQueue(joinRequest : JoinQueueRequest){
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqGetOrJoinQueue(joinRequest, this.accessToken, channelTag);

    if(!(respDt instanceof HttpErrorResponse)){
      let queueData = this.getQueueData();
      if(!queueData){
        queueData = {} as QueueData;
      }
      queueData.rsvTokenId = respDt['body'].rsvTokenId;
      queueData.queueResponse = respDt['body'];
      this.saveQueueData(queueData);
      return respDt['body'];
    }
    else{
      this.errorHandling(respDt);
      return null;
    }
  }

  async getQueueByToken(rsvTokenId : string){
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqGetQueueByToken(rsvTokenId, this.accessToken, channelTag);

    if(!(respDt instanceof HttpErrorResponse)){
      let queueData = this.getQueueData();
      if(!queueData){
        queueData = {} as QueueData;
      }
      queueData.rsvTokenId = respDt['body'].rsvTokenId;
      queueData.queueResponse = respDt['body'];
      this.saveQueueData(queueData);

      if(respDt['body'].statusFlag == QueueStatusFlag.Expired){
        this.router.navigate(['queue/queue-expired'], { replaceUrl: true });
      }
      else if(respDt['body'].statusFlag == QueueStatusFlag.UserCancelled || respDt['body'].statusFlag == QueueStatusFlag.StoreCancelled){
        this.channelSecService.removeAll();
        this.router.navigate(['queue/queue-cancel']);
      }
      else{
        return respDt['body'];
      }
    }
    else{
      this.errorHandling(respDt);
      return null;
    }
  }

  async updateQueueInfo(updateRequest : UpdateQueueInfoRequest){
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqUpdateQueueInfo(updateRequest, this.accessToken, channelTag);

    if(!(respDt instanceof HttpErrorResponse)){
      let queueData = this.getQueueData();
      if(!queueData){
        queueData = {} as QueueData;
      }
      queueData.rsvTokenId = respDt['body'].rsvTokenId;
      queueData.queueResponse = respDt['body'];
      this.saveQueueData(queueData);
      return respDt['body'];
    }
    else{
      this.errorHandling(respDt);
      return null;
    }
  }

  async cancelQueue(cancelRequest : CancelQueueInfoRequest){
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqCancelQueue(cancelRequest, this.accessToken, channelTag);

    if(!(respDt instanceof HttpErrorResponse)){
      this.removeCanQueueRegisterFlag();
      return respDt['body'];
    }
    else{
      // place to write error handling
      return respDt;
    }
  }

  async acknowledgeQueue(acknowledgeQueueRequest : AcknowledgeQueueRequest, returnResp : boolean){
    let respDt = null;
    let channelTag = this.getChannelTagInLocalStorage();
    respDt = await this.reqAcknowledgeQueue(acknowledgeQueueRequest, this.accessToken, channelTag);

    if(!(respDt instanceof HttpErrorResponse)){
      let queueData = this.getQueueData();
      if(!queueData){
        queueData = {} as QueueData;
      }
      queueData.rsvTokenId = respDt['body'].rsvTokenId;
      queueData.queueResponse = respDt['body'];
      this.saveQueueData(queueData);
      return respDt['body'];
    }
    else{
      if(returnResp){
        return respDt;
      }
      else{
        this.errorHandling(respDt);
        return null;
      }
    }
  }

  private async reqGetOrJoinQueue(joinRequest : JoinQueueRequest, accessToken : string, channelTag : string){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.queue.GetOrJoinQueue,
      hostPath: environment.hostPath,
      body: {
        storeId: joinRequest.storeId,
        channelId : joinRequest.channelId,
        mobileNo: joinRequest.mobileNo,
        customerName: joinRequest.customerName,
        guestCount: joinRequest.guestCount,
        remarks: joinRequest.remarks,
      },
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Normal
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqGetQueueByToken(rsvTokenId : string, accessToken : string, channelTag : string){
    let newCr = {
      httpMethod: HttpMethod.GET,
      requestpath: environment.apis.queue.GetQueueByToken,
      hostPath: environment.hostPath,
      queryParams: {
        rsvTokenId : rsvTokenId
      },
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqUpdateQueueInfo(updateRequest : UpdateQueueInfoRequest, accessToken : string, channelTag : string){
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.queue.UpdateQueueInfo,
      hostPath: environment.hostPath,
      body: {
        storeId: updateRequest.storeId,
        channelId : updateRequest.channelId,
        mobileNo: updateRequest.mobileNo,
        customerName: updateRequest.customerName,
        guestCount: updateRequest.guestCount,
        remarks: updateRequest.remarks,
      },
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqCancelQueue(cancelRequest : CancelQueueInfoRequest, accessToken : string, channelTag : string){
    let newCr = {
      httpMethod: HttpMethod.PUT,
      requestpath: environment.apis.queue.CancelQueue,
      hostPath: environment.hostPath,
      body: {
        storeId: cancelRequest.storeId,
        channelId : cancelRequest.channelId,
        mobileNo: cancelRequest.mobileNo,
      },
      headers: {
        accessToken: accessToken,
        channelTag: channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private async reqAcknowledgeQueue(acknowledgeQueueRequest : AcknowledgeQueueRequest, accessToken : string, channelTag : string){
    let newCr = {
      httpMethod: HttpMethod.POST,
      requestpath: environment.apis.queue.AcknowledgeQueue,
      hostPath: environment.hostPath,
      body: {
        storeId: acknowledgeQueueRequest.storeId,
        channelId : acknowledgeQueueRequest.channelId,
        mobileNo: acknowledgeQueueRequest.mobileNo,
        orderHs: acknowledgeQueueRequest.orderHs
      },
      headers: {
        accessToken: accessToken,
        channelTag : channelTag
      },
      httpHeaderType: HttpHeaderType.Auth
    } as CustomRequest

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr);

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }

  async onJoinQueueInit(queueRequest : JoinQueueRequest, showLoginToast : boolean = false){
    // join queue
    let resp = await this.getOrJoinQueue(queueRequest);
    this.sessionStorageService.removeItem("queueRequest");

    if(!(resp instanceof HttpErrorResponse)){
      // get queue data from session storage
      let queueData = {} as QueueData;
      queueData = this.getQueueData();

      // remove empty spaces from location description
      let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(resp.locShortDesc);

      // navigate to queue info page
      this.router.navigate(["queue", queueData.storeData.storeId, locDescWithoutSpaces, resp.rsvTokenId],
      { replaceUrl: true, state: { queueResponse: resp, showLoginToast: showLoginToast } });
    }
  }

  async navigateToPreQueue(toReplaceUrl : boolean = true){
    let queueData = {} as QueueData;
    queueData = this.getQueueData();

    // remove empty spaces from location description
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(queueData.storeData.locShortDesc);

    // navigate to queue info page
    this.router.navigate(["queue", queueData.storeData.storeId, locDescWithoutSpaces], { replaceUrl: toReplaceUrl });
  }

  async navigateToPreQueueWithQueueResponse(toReplaceUrl : boolean = true){
    let queueData = {} as QueueData;
    queueData = this.getQueueData();

    // remove empty spaces from location description
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(queueData.queueResponse.locShortDesc);

    // navigate to queue info page
    this.router.navigate(["queue", queueData.queueResponse.storeId, locDescWithoutSpaces], { replaceUrl: toReplaceUrl });
  }

  loginToastInit(){
    let toastData : ToastData = {} as ToastData;

    toastData.message = "queue.login.success.desc.1";
    toastData.icon = "color oda-check-alt";
    toastData.iconColor = "#8CD600";
    this.toastService.show(toastData);
  }

  async getPreQueueRoute(){
    let queueData = {} as QueueData;
    queueData = this.getQueueData();

    // remove empty spaces from location description
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(queueData.storeData.locShortDesc);

    // build prequeue route
    let preQueueUrl = '/queue/' + queueData.storeData.storeId + '/' + locDescWithoutSpaces;
    return preQueueUrl;
  }

  async navigateToQueueInfo(storeId : number, locName : string, rsvTokenId : string, replaceUrl : boolean = true){
    // remove empty spaces from location description
    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(locName);

    this.router.navigate(["queue", storeId, locDescWithoutSpaces, rsvTokenId], { replaceUrl: replaceUrl });
  }

  async navigateToStore(){
    let queueData = {} as QueueData;
    queueData = this.getQueueData();

    let locDescWithoutSpaces = await this.storeService.replaceWhiteSpaceWithDash(queueData.queueResponse.locShortDesc);
    this.router.navigate(['store', queueData.queueResponse.storeId, locDescWithoutSpaces]);
  }

  showQuitQueuePopup(){
    this.toQuitQueue$.next(true);
  }

  closeQuitQueuePopup(){
    this.toQuitQueue$.next(false);
  }

  setIsQueueInfoPage(isQueueInfo : boolean){
    this.isQueueInfoPage = isQueueInfo;
  }

  getIsQueueInfoPage(){
    return this.isQueueInfoPage;
  }

  showRemoveQueueCart(){
    this.toRemoveQueueCart$.next(true);
  }

  closeRemoveQueueCart(){
    this.toRemoveQueueCart$.next(false);
  }

  async acknowledgeQueueInit(channelId : number, mobileNo : string, storeId : number, returnResp : boolean, orderH? : OrderH){
    let acknowledgeQueueRequest = {} as AcknowledgeQueueRequest;
    acknowledgeQueueRequest.channelId = channelId;
    acknowledgeQueueRequest.mobileNo = mobileNo;
    acknowledgeQueueRequest.storeId = storeId;

    if(orderH){
      acknowledgeQueueRequest.orderHs = [];
      acknowledgeQueueRequest.orderHs.push(orderH);
    }

    let resp = await this.acknowledgeQueue(acknowledgeQueueRequest, returnResp);
    return resp;
  }

  errorHandling(httpError : HttpErrorResponse){
    if(httpError?.error?.errorCode == ErrorCode.QueueNotFound_404){
      this.router.navigate(['queue/queue-invalid']);
    }
    else if(httpError?.error?.errorCode == ErrorCode.QueueExpired_400){
      this.router.navigate(['queue/queue-expired']);
    }
    else{
      this.router.navigate(['queue/queue-error']);
    }
  }

  //#region show back controller
  setShowBackIcon(){
    this.sessionStorageService.setItem('showBack', JSON.stringify(true));
  }

  getShowBackIcon(){
    let toShow = JSON.parse(this.sessionStorageService.getItem('showBack'));
    return toShow ? true : false;
  }

  removeShowBackIcon(){
    this.sessionStorageService.removeItem('showBack');
  }
  //#endregion

  setActivityRsvToken(rsvTokenId : string){
    let data = { 'rsvTokenId': rsvTokenId };

    try {
      this.sessionStorageService.setItem("queueOrderSummary", JSON.stringify(data));
    } catch(e) {
      console.log("Failed to set session storage", e)
    }
  }

  removeActivityRsvToken(){
    this.sessionStorageService.removeItem('queueOrderSummary');
  }

  //#region queue manual back get set of session data
  setManualBack(isBack){
    let data = { isManualBack: isBack};
    this.sessionStorageService.setItem("orderManualBack", JSON.stringify(data));
  }

  getManualBack(){
    let isManualBack : boolean = false;
    if(this.sessionStorageService.getItem("orderManualBack")){
      isManualBack = JSON.parse(this.sessionStorageService.getItem("orderManualBack")).isManualBack
    }
    return isManualBack ? true : false;
  }

  removeManualBack(){
    this.sessionStorageService.removeItem("orderManualBack");
  }
  //#endregion

  setCanQueueRegister(canRegister : boolean){
    this.sessionStorageService.setItem("queueCanRegister", JSON.stringify(canRegister));
  }

  getCanQueueRegister(){
    let canRegisterFlag = JSON.parse(this.sessionStorageService.getItem("queueCanRegister"));
    return canRegisterFlag ? true : false;
  }

  removeCanQueueRegisterFlag(){
    this.sessionStorageService.removeItem("queueCanRegister");
  }
}
