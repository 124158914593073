<div class="remove-cart-content">
  <div class="trash_confirmation_icon">
    <i class="duotone oda-d-trash" style="color: #b1b1b1;"><span class="path1"></span><span class="path2"></span></i>
  </div>

  <div class="remove-description heading-10">
    {{"queue.cart.desc.4" | translate}}
  </div>

  <div class="remove-item-container">
    <button class="remove-item-cancel" (click)="onClickCancel()">{{"button.cancel" | translate}}</button>
    <button class="remove-item-action-button" (click)="onClickRemoveItems()">{{"button.remove.item.with.s" | translate}}</button>
  </div>
</div>
