import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserChnlStore, UserChnlState } from './userchnl.store';
import { UserChannel } from './userchnl.model';

@Injectable({
  providedIn: 'root'
})
export class UserChnlQuery extends QueryEntity<UserChnlState, UserChannel> {

  isSubDomain$ = this.select(state => state.isSubDomain);

  constructor(protected store: UserChnlStore) {
    super(store);
  }

}
