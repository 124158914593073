import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StoreStore, StoreState } from './store.store';
import { Store } from './store.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreQuery extends QueryEntity<StoreState, Store> {

  constructor(protected store: StoreStore) {
    super(store);
  }
}
