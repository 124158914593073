<div class="apply-promo-container">
    <h1>{{ 'rewards.promo.code' | translate }}</h1>
    <div class="search-bar">
        <i class="oda-discount icon"></i>
        <input type="text" pInputText placeholder="{{ 'rewards.enter.promo.code' | translate }}" [(ngModel)]="searchPromoCode" (keyup.enter)="onClickApplyPromo()" />
        <i class="oda-times btn-close" *ngIf="searchPromoCode" (click)="onClickClose()"></i>
    </div>
    <div class="error" *ngIf="invalidPromo">
        <i class="oda-alert icon"></i>
        <p>{{ "rewards.invalid.promo.error" | translate }}</p>
    </div>
    <button type="button" class="btn-apply" [disabled]="!searchPromoCode" (click)="onClickApplyPromo()">{{ "button.apply" | translate }}</button>
</div>
