import { QrCacheData } from './../../core/models/local/QrCacheData';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ChangeData } from 'ngx-intl-tel-input';
import { decodeBase64, encodeBase64 } from 'src/app/core/services/utils.service';
import { SessionStorageService } from '../storage/session-storage.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  saveSubmittedNumber(phoneValue : ChangeData){
    if(phoneValue){
      localStorage.setItem("submittedPhoneNumber", JSON.stringify(phoneValue));
    }
  }

  getCachedNumber(){
    let storedNumber = JSON.parse(localStorage.getItem("submittedPhoneNumber"));

    if (typeof storedNumber === 'string') {
      localStorage.removeItem("submittedPhoneNumber");
      storedNumber = null;
    }

    return storedNumber? storedNumber : null;
  }

  //#region qr info
  // cache qr link data and expire time
  storeQrInfo(storeId : number, qrLink : string){
    if(storeId && qrLink){
      let cacheQrInfo = {} as QrCacheData;
      cacheQrInfo.storeId = storeId;
      cacheQrInfo.qrLink = qrLink;
      let currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 1); //plus 1 hours to current time to get expire time
      cacheQrInfo.expiryTime = currentTime.getTime(); //date will convert to milliseconds since epoch time(January 1, 1970, UTC)
      this.sessionStorageService.setItem("qrInfo", JSON.stringify(cacheQrInfo));
    }
  }

  getQrInfo(){
    let qrCacheData = JSON.parse(this.sessionStorageService.getItem("qrInfo"));
    return qrCacheData ? qrCacheData : null;
  }

  getQrInfoParamString() {
    let qrCacheData = JSON.parse(this.sessionStorageService.getItem("qrInfo"));
    let data = qrCacheData?.qrLink?.split('?');
    return data ? data[1] : null;
  }

  removeQrInfo(){
    this.sessionStorageService.removeItem("qrInfo");
  }

  resetQrExpiryTime(){
    let cacheQrInfo = this.getQrInfo();
    if(cacheQrInfo){
      let currentTime = new Date();
      currentTime.setHours(currentTime.getHours() + 1); //plus 1 hours to current time to get expire time
      cacheQrInfo.expiryTime = currentTime.getTime(); //date will convert to milliseconds since epoch time(January 1, 1970, UTC)
      this.sessionStorageService.setItem("qrInfo", JSON.stringify(cacheQrInfo));
    }
  }
  //#endregion

  storeIsQrScan(isScan: boolean = true, url?: string){
    let qrScanInfo = { isScan: isScan, url: url ? url : undefined };
    this.sessionStorageService.setItem("isQrScan", JSON.stringify(qrScanInfo));
  }

  getIsQrScan(){
    let qrScanData = JSON.parse(this.sessionStorageService.getItem("isQrScan"));
    return qrScanData ? qrScanData : null;
  }

  removeIsQrScan(){
    this.sessionStorageService.removeItem("isQrScan");
  }

  setSessionStorage(title: string, data: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.sessionStorageService.setItem(title, encodeBase64(JSON.stringify(data)));
    }
  }

  getSessionStorage(title: string): any {
    let data = null;
    if (isPlatformBrowser(this.platformId)) {
      let sessionData = this.sessionStorageService.getItem(title);
      let decodeSessionData = sessionData ? decodeBase64(sessionData) : null;
      data = decodeSessionData ? JSON.parse(decodeSessionData) : null;
    }
    return data;
  }

  //#region force check out flag
  storeForceCheckoutFlag(isForceCheckout : boolean){
    let forceCheckoutData = {
      forceCheckout: isForceCheckout
    };

    this.sessionStorageService.setItem("forceCheckout", JSON.stringify(forceCheckoutData));
  }

  getForceCheckoutFlag(){
    let forceCheckout : boolean;

    let data = JSON.parse(this.sessionStorageService.getItem("forceCheckout"));
    forceCheckout = data ? true : false;

    return forceCheckout;
  }

  removeForceCheckoutFlag(){
    this.sessionStorageService.removeItem("forceCheckout");
  }

  //#endregion
}
