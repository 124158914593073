import { OrderSourceFlag } from 'src/app/core/enums/OrderSourceFlag';
import { MiniProgramCart } from './mini-program.model';
import { OrderTypeFlag } from './../enums/OrderTypeFlag';
import { Injectable } from "@angular/core";
import { StoreMode } from "../enums";
import { AppLinkTokenResponse } from "../models/AppLinkTokenResponse";
import { UserService } from "../user/user.service";
import { MiniProgramQuery } from "./mini-program.query";
import { MiniProgramStore } from "./mini-program.store";
import { CustomRequest } from '../models/CustomRequest';
import { HttpMethod } from '@datorama/akita-ng-entity-service';
import { environment } from 'src/environments/environment';
import { HttpHeaderType } from '../enums/HttpHeaderType';
import { CartModel } from '../models/CartModel';
import { CustomService } from '../services/custom.service';
import { TimeService } from '../services/time.service';
import { OrderH } from '../models/OrderH';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class MiniProgramService {
  accessToken: string;
  isLoggedIn: boolean;

  constructor(
    private userService : UserService,
    private miniProgramStore : MiniProgramStore,
    private miniProgramQuery : MiniProgramQuery,
    private customService : CustomService,
    private timeService : TimeService,
    private router : Router,
    private storageService : StorageService
  ){
    this.userService.get(StoreMode.Internal).subscribe((userData: any) => {
      this.accessToken = userData && userData['accessToken'] ? userData['accessToken'] : '';
      this.isLoggedIn = userData? true : false;
    });
  }

  updateAppLinkTokenResponse(appLinkTokenResponse : AppLinkTokenResponse){
    this.miniProgramStore.update({
      appLinkTokenResponse : appLinkTokenResponse
    });
  }

  updateCart(linkId : number, cartModel : CartModel){
    let cartState = {} as MiniProgramCart;
    cartState.id = linkId;
    cartState.cartModel = cartModel;
    this.miniProgramStore.update(linkId, cartState);
  }

  getAppTokenRespObservable(){
    return this.miniProgramQuery.appLinkResponse$;
  }

  getAppTokenByValue(){
    return this.miniProgramQuery.getValue().appLinkTokenResponse;
  }

  getAppCartByEntityId(linkId : number){
    return this.miniProgramQuery.selectEntity(linkId);
  }

  getOrderType(orderTypeFlag : string){
    switch(orderTypeFlag){
      case OrderTypeFlag.Delivery:{
        return OrderTypeFlag.Delivery;
      }
      case OrderTypeFlag.Pickup:
      case OrderTypeFlag.QRTakeaway:{
        return OrderTypeFlag.Pickup;
      }
      case OrderTypeFlag.DineIn:
      case OrderTypeFlag.QRDineIn:{
        return OrderTypeFlag.DineIn;
      }
      default:{
        return OrderTypeFlag.Pickup;
      }
    }
  }

  getSourceFlag(orderTypeFlag : string){
    switch(orderTypeFlag){
      case OrderTypeFlag.Delivery:{
        return OrderSourceFlag.WebDelivery;
      }
      case OrderTypeFlag.Pickup:{
        return OrderSourceFlag.WebPickup;
      }
      case OrderTypeFlag.DineIn:{
        return OrderSourceFlag.WebDineIn;
      }
      case OrderTypeFlag.QRTakeaway:{
        return OrderSourceFlag.WebQrTakeaway;
      }
      case OrderTypeFlag.QRDineIn:{
        return OrderSourceFlag.WebQrDineIn;
      }
      default:{
        return OrderTypeFlag.Pickup;
      }
    }
  }

  processCartModel(cartModel: CartModel) {
    if(cartModel && cartModel.orderHs && cartModel.orderHs?.length > 0) {
      cartModel.orderHs.forEach((orderH: OrderH) => {
        orderH.orderData.orderVs = [];
        orderH.orderData.menuRowVersion = orderH.orderData.menuRowVersion? orderH.orderData.menuRowVersion: "0";
      });
    }

    return cartModel;
  }

  addCart(data: MiniProgramCart, linkId : number) {
    let cartData = _.cloneDeep(data) as MiniProgramCart;
    this.miniProgramStore.upsert(linkId, cartData);
  }

  clearCart(){
    this.miniProgramStore.remove();
  }

  async recalculateCart(channelId: number, linkId : number, cartModel: CartModel, updateState: boolean = false) {
    cartModel = this.processCartModel(cartModel);

    let respData = null;
    respData = await this.reqRecalculateCart(channelId, cartModel);

    if(respData instanceof HttpErrorResponse === false){
      if (updateState) {
        this.addCart({
          id: linkId,
          cartModel: respData['body']
        }, linkId)
      }

      let paymentMethods = respData['body'].paymentMethods;
      this.storageService.setSessionStorage('pms', paymentMethods);

      return respData['body'];
    }
    else{
      this.router.navigate(['technical-error']);
    }
  }

  private async reqRecalculateCart(channelId: number, cartModel: CartModel) {
    let newCr = {} as CustomRequest;

    if (this.isLoggedIn) {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId,
          saveCart: false
        },
        headers: {
          accessToken: this.accessToken
        },
        httpHeaderType: HttpHeaderType.Auth
      } as CustomRequest
    }
    else {
      newCr = {
        httpMethod: HttpMethod.POST,
        requestpath: environment.apis.cart.RecalculateCart,
        hostPath: environment.hostPath,
        body: cartModel,
        queryParams: {
          channelId: channelId,
          saveCart: false
        }
      } as CustomRequest
    }

    let respInfo = null;
    respInfo = await this.reqCustomHttpCall(newCr, false);
    this.timeService.setServerTime(respInfo.headers.get("X-ServerDateTime"));

    return respInfo;
  }

  private reqCustomHttpCall(cusreq: CustomRequest, isCompression?: boolean) {
    const cSv = this.customService;
    return cSv.createRequest(cusreq, isCompression).then((dd: any) => { return dd });
  }
}
