import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ChannelService } from 'src/app/home/channel/channel.service';
import { SectionDataType } from 'src/app/core/enums/DataType';
import { StoreMode } from 'src/app/core/enums';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss']
})
export class BottomNavComponent implements OnInit {
  channel$: Subscription;

  sectionDataType = SectionDataType;
  homeSectionData: any;
  orderHistory: any;
  activeOrders: any;
  orderCount: number = 0;

  constructor(private channelService: ChannelService) {}

  ngOnInit(): void {
    this.channel$ = this.channelService.get(StoreMode.Internal).subscribe((dt) => {
      if (dt) {
        this.homeSectionData = dt.data.channelHomeSections.slice();
        this.orderHistory = this.homeSectionData.filter(val => val.data.dataType === this.sectionDataType.OrderHistory)[0];

        if (this.orderHistory && this.orderHistory.data) {
          let dataInd = this.orderHistory.data.data.findIndex(dd => !!dd.dataSet);
          this.orderCount = dataInd > 0 ? this.orderHistory.data.data[dataInd].dataSet.length: 0;
        } else {
          this.orderCount = 0;
        }
      }
    });
  }
}
