import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageDesc'
})
export class LanguageDescPipe implements PipeTransform {

  transform(currentLang: string, fullDesc : boolean): string {
    let currentDesc : string;
    if(currentLang == 'en'){
      currentDesc = fullDesc ? 'English' : 'EN';
    }
    else if(currentLang == 'bm'){
      currentDesc = fullDesc ? 'Bahasa Melayu' : 'BM';
    }
    else if(currentLang == 'cn'){
      currentDesc = fullDesc ? '中文' : '中文';
    }

    return currentDesc;
  }

}
