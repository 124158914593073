import { Component, OnInit } from '@angular/core';
import { EmailUpdateConfirmationToken } from 'src/app/core/models/EmailUpdateConfirmationToken';
import { RoutingService } from 'src/app/home/services/routing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-link-sent',
  templateUrl: './verify-link-sent.component.html',
  styleUrls: ['./verify-link-sent.component.scss']
})
export class VerifyLinkSentComponent implements OnInit {

  emailVerifyData: EmailUpdateConfirmationToken = null;

  constructor(
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
    this.emailVerifyData = history.state.emailVerifyData? history.state.emailVerifyData: null;
  }

  navigateBackToHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }
}
