
<div class="refund-return" #container>
  <section class="refund-return-header hidden-sm">
    <div class="refund-return-header-wrapper p-d-flex p-jc-center">
      <div class="p-mr-6 sub-content">
        <h1 class="heading-3">{{ 'refund.return.history.title.3' | translate }}</h1>
      </div>
      <div class="mascot-container"><img src="assets/mascot/Mascot_sad@2x.webp" alt="odaring mascot"/></div>
    </div>
  </section>

  <section class="refund-return-header-mobile hidden-lg">
    <div class="p-d-flex p-jc-center p-ai-center">
      <i class="oda-chevron-left btn-back" (click)="onBack()"></i>
      <h1 class="heading-14">{{ 'refund.return.history.title.4' | translate }}</h1>
    </div>
  </section>

  <section class="refund-return-container">
    <div class="p-d-flex p-jc-center ">
      <section class="sub-content">
        <!--reference code-->
        <h1 class="heading heading-7" style="font-weight: normal">
          {{'order.summary.title.3' | translate}}
          <span class="heading-7">#{{ refundData.orderNo }}</span>
        </h1>
        <!--store name-->
        <h2 class="store-name heading-10">{{ refundData.storeInfo.locDesc }}</h2>

        <!--issue date and time-->
        <div class="tran-date p-d-flex">
          <h3 class="heading-13 p-mr-3">{{ refundData.tranDate | date: 'd LLL yyyy' }}</h3>
          <h3 class="heading-13">{{ refundData.tranDate | date: 'HH:mm' }}</h3>
        </div>

        <!--status-->
        <div class="p-d-flex">
          <div class="p-mr-2 heading-11" style="color: #8CD600"><span class="dot"></span>{{ displayStatus | translate }}</div>
          <div class="p-ml-auto heading-11" style="color: #EB3600">{{ refundData.netAmt | currency: refundData.currCode : 'symbol-narrow' }}</div>
        </div>

        <hr class="dash-line">

        <label class="heading-13" *ngIf="detail.Email">{{'input.label.8' | translate}}</label>
        <p class="heading-11 detail" *ngIf="detail.Email">{{ detail.Email }}</p>

        <label class="heading-13" *ngIf="detail.Remark">{{'refund.return.history.title.1' | translate}}</label>
        <p class="heading-11 detail" *ngIf="detail.Remark">{{ detail.Remark }}</p>

        <h4 class="heading-13 attachment-label" *ngIf="detail.Attachment">{{'refund.return.history.title.2' | translate}}</h4>
        <div class="p-d-flex" *ngIf="detail.Attachment">
          <div class="image-container" *ngFor="let image of detail.Attachment">
            <img [src]="image? image: 'assets/image-unavailable.svg'" (click)="onShowImage(image);" onerror="this.src='assets/errorMsg_image/imageError.webp'" alt="odaring mascot" loading="lazy"/>
          </div>
        </div>
      </section>
    </div>

    <div class="hidden-sm">
      <div class="p-d-flex p-jc-center">
        <button type="button" class="heading-11 btn-back" [routerLink]="['/my-order']">{{'button.back.to.order' | translate}}</button>
      </div>
    </div>
  </section>

  <section class="refund-return-dialog">
    <!-- Image Dialog -->
    <p-dialog [(visible)]="showImgDialog" *ngIf="showImgDialog" [modal]=true [dismissableMask]=true [showHeader]=true [draggable]=false >
      <div class="dialog-image">
        <img [src]="imageUrl? imageUrl: 'assets/image-unavailable.svg'" onerror="this.src='assets/errorMsg_image/imageError.webp'" alt="odaring mascot" loading="lazy"/>
      </div>
    </p-dialog>
  </section>

   <!-- Success request toast -->
  <p-toast position="bottom-center">
    <ng-template let-message pTemplate="message">
      <div class="toast-container" #toast>
        <i class="oda-check-circle"></i>
        <p>{{ 'refund.return.history.submitted' | translate }}</p>
        <button type="button" (click)="onCloseToast()"><i class="oda-times"></i></button>
      </div>
    </ng-template>
  </p-toast>
</div>




