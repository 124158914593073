<app-download-app></app-download-app>

<!--prompt address content-->
<div class="landing-page-container">
  <!--header-->
  <div class="landing-header">
    <img src="assets/odaring-logo.svg" class="hidden-sm" alt="odaring logo"/>
    <div class="sub-header">
      <!--guest mode: login button-->
      <button class="heading-11 hidden-sm" *ngIf="loginDisplayName === ''" (click)="navigateLogin()">
        <i class="oda-user-open"></i>{{'landingPage-header.5' | translate}}
      </button>
      <!--user mode: user dropdown-->
      <app-user-menu-dropdown class="hidden-sm" *ngIf="loginDisplayName !== ''" [isFromLandingPage]="true" [selectedCountryCode]="selectedCountryCode"></app-user-menu-dropdown>

      <!--country selector-->
      <div class="landing-country-selector">
        <p-dropdown [options]="countries" [(ngModel)]="selectedCountryCode"
                    (onChange)="countryChange(selectedCountryCode)" optionLabel="name" optionValue="countryCode"
                    styleClass="country">
          <!--display selected-->
          <ng-template let-item pTemplate="selectedItem">
            <img src="{{item.image}}" class="p-mr-1" alt="odaring image" loading="lazy" />
            <span>{{item.name}}</span>
          </ng-template>
          <!---dropdrown-->
          <ng-template let-country pTemplate="item">
            <img src="{{country.image}}" class="p-mr-1" alt="odaring image" loading="lazy"/>
            <span>{{country.name}}</span>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
  <!--content-->
  <div class="landing-content">
    <!--address search content-->
    <div class="address-content">
      <div class="sub-content">
        <h1 class="landing-heading-1 hidden-sm">{{ 'landingPage-header.1' | translate }}</h1>
        <h1 class="landing-heading-2 hidden-sm">{{ 'landingPage-header.2' | translate }}</h1>
        <h1 class="landing-heading-1 hidden-sm">{{ 'landingPage-header.3' | translate }}</h1>
        <div class="address-search-bar">
          <app-address-search [selectedChannelTag]="channelData?.channelTag"
                              [selectedChannelId]="channelData?.channelId"
                              [events]="eventsSubject.asObservable()"
                              (outputSelectedAddress)="selectAddress($event)"
                              (errorLocationCode)="errorLocationCode = $event">
          </app-address-search>
          <!--error msg: location not available-->
          <div class="invalid-feeback" *ngIf="errorLocationCode === errorLocation.NOT_AVAILABLE">
            <i class="oda-alert"></i>
            {{'alert.landing.pages.1' | translate}}
          </div>
          <!--error msg: location could not track-->
          <div class="p-d-flex invalid-feeback" *ngIf="errorLocationCode === errorLocation.BLOCK_GPS">
            <i class="oda-alert"></i>
            <div class="p-ml-1 hidden-sm">{{'alert.landing.pages.2' | translate}}</div>
            <div class="p-ml-1 hidden-lg">{{'alert.landing.pages.4' | translate}}</div>
          </div>
          <!--error msg: empty address-->
          <div class="invalid-feeback" *ngIf="errorLocationCode === errorLocation.EMPTY_ADDRESS">
            <i class="oda-alert"></i>
            {{'alert.landing.pages.3' | translate}}
          </div>
           <!--submit button: let's go'-->
           <div class="submit-btn">
            <button type="submit" (click)="navigateHome()">{{'button.lets.go' | translate}}</button>
           </div>
        </div>

        <!--mascot image-->
        <div class="mascot-content hidden-lg">

          <h1><img src="assets/mascot/Mascot_location@2x.webp" alt="odaring mascot"/>{{'landingPage-dialog-header.3' | translate}}</h1>
        </div>

      </div>
    </div>
    <div class="lottie-content hidden-sm">
      <div class="sub-content">
        <ng-lottie [options]="options"></ng-lottie>
      </div>
    </div>
  </div>
</div>

<!--about odaring section-->
<div class="seo-container hidden-sm">
  <!--title: why odaring-->
  <div class="header">
    <div class="outline-circle">
      <div class="solid-circle">
        <div class="why-odaring">
          {{"landingPage-header.6" | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="seo-content">
    <!--quick-->
    <div class="p-mb-6">
      <h1 class="seo-heading-1">
        <i class="color oda-c-timer"><span class="path1"></span></i>
        {{'landing-page.quick' | translate}}
      </h1>
      <p class="seo-heading-2">{{'landing-page.quick.des' | translate}}</p>
    </div>
    <!--simple-->
    <div class="p-mb-6">
      <h2 class="seo-heading-1">
        <i class="duotone oda-d-foodtimer"></i>
        {{'landing-page.simple' | translate}}
      </h2>
      <p class="seo-heading-2">{{'landing-page.simple.des' | translate}}</p>
    </div>
    <!--Whatever food you crave-->
    <div class="p-mb-6">
      <h3 class="seo-heading-1">
        <i class="color oda-c-hotmeal"><span class="path1"></span></i>
        {{'landing-page.whatever.food.you.crave'| translate}}
      </h3>
      <p class="seo-heading-2">{{'landing-page.whatever.food.you.crave.des' | translate}}</p>
    </div>
    <!--Easy pay, easy eat-->
    <div class="p-mb-6">
      <h4 class="seo-heading-1">
        <i class="oda-credit-card"></i>
        {{'landing-page.easy.pay.easy.eat' | translate}}
      </h4>
      <p class="seo-heading-2">{{'landing-page.easy.pay.easy.eat.des' | translate}}</p>
    </div>
  </div>
</div>

<!--FAQ section-->
<div class="faq-container hidden-sm">
  <!--title-->
  <div class="faq-header">
    <h1 class="faq-title">{{'landing-page.frequently.ask.question' | translate}}</h1>
    <div class="faq-solid-circle">
    </div>
  </div>

  <!--faq content-->
  <div class="faq-content">
    <div class="faq-listing">
      <p-accordion>

        <p-accordionTab [selected]="true">
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">01</h1>
              <span class="faq-heading-2">{{'landingPage-header.6' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.why.odaring.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">02</h1>
              <span class="faq-heading-2">{{'landing-page.faq.how.to.order.odaring.online' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.how.to.order.odaring.online.des.line.1' | translate}}</p>
            <p class="faq-heading-3">{{'landing-page.faq.how.to.order.odaring.online.des.line.2' | translate}}</p>
            <p class="faq-heading-3">{{'landing-page.faq.how.to.order.odaring.online.des.line.3' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">03</h1>
              <span class="faq-heading-2">{{'landing-page.faq.does.ordaring.accept.cash' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.does.ordaring.accept.cash.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">04</h1>
              <span class="faq-heading-2">{{'landing-page.faq.can.i.pay.online.on.odaring.for.my.food.orders' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.can.i.pay.online.on.odaring.for.my.food.orders.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">05</h1>
              <span class="faq-heading-2">{{'landing-page.faq.can.i.order.odaring.for.someone.else' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3"> {{'landing-page.faq.can.i.order.odaring.for.someone.else.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">06</h1>
              <span class="faq-heading-2">{{'landing-page.faq.how.much.does.odaring.charge.for.delivery' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.how.much.does.odaring.charge.for.delivery.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">07</h1>
              <span class="faq-heading-2">
                {{'landing-page.faq.does.odaring.have.a.minimum.order' | translate}}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.does.odaring.have.a.minimum.order.des' | translate}}</p>
          </ng-template>
        </p-accordionTab>

        <p-accordionTab>
          <ng-template pTemplate="header">
            <i class="oda-chevron-right"></i>
            <div class="content-header">
              <h1 class="faq-number-heading">08</h1>
              <span class="faq-heading-2">{{'landing-page.faq.can.i.cancel.my.order.and.ask.for.refund' | translate}}</span>
            </div>
          </ng-template>
          <ng-template pTemplate="content">
            <p class="faq-heading-3">{{'landing-page.faq.can.i.cancel.my.order.and.ask.for.refund.des' | translate}}</p>
            <ul class="faq-heading-3" style="list-style-position: outside; margin-left: 20px;">
              <li class="faq-heading-2">{{'landing-page.faq.can.i.cancel.my.order.and.ask.for.refund.des.reason.1' | translate}}</li>
              <li class="faq-heading-2">{{'landing-page.faq.can.i.cancel.my.order.and.ask.for.refund.des.reason.2' | translate}}</li>
            </ul>
          </ng-template>
        </p-accordionTab>

      </p-accordion>
    </div>
    <div class="faq-image">
      <img src="assets/mascot/Mascot_curious-left@2x.webp" alt="odaring mascot"/>
    </div>
  </div>
</div>
