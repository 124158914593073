import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Menu } from './menu.model';
import { MenuState, MenuStore } from './menu.store';

@Injectable({
  providedIn: 'root'
})
export class MenuQuery extends QueryEntity<MenuState, Menu> {
  constructor(
    protected menuStore : MenuStore
  ) {
    super(menuStore);
  }
}
