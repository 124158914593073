import { ChangeData } from 'ngx-intl-tel-input';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { WebLinkTokenResponse } from '../models/WebLinkTokenResponse';
import { QrCart } from './qr-cart.model';

export interface QrCartState extends EntityState<QrCart> {
  qrTokenResponse: WebLinkTokenResponse
}

export function createInitialState() {
  return {
    qrTokenResponse: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'qr-cart' })
export class QrCartStore extends EntityStore<QrCartState, QrCart> {

  constructor() {
    super(createInitialState());
  }
}
