<div class="otp-form-container">
  <form (ngSubmit)="onSubmit()" [formGroup]="fgroup">
    <div class="overall-content">
      <!--prefix and opt placeholder-->
      <section class="prefix-container" formArrayName="otpArr">
        <div class="heading-11 otpPrefix">{{ otpPrefix }}</div>
        <input type="text" id="otpInput-{{i}}" pattern="[0-9]*" digitOnly min="0" max="9" inputmode="numeric"
          [ngStyle]="{'border-color': verifyOTPError || invalidOTPError ? '#EB3600' : '#B1B1B1'}"
          [ngClass]="{'otpInput': (i+1) === otpArrs.controls.length}"
          #formotpArr
          *ngFor="let _ of otpArrs.controls; index as i" [formControlName]="i"
          (input)="onOtpInput($event, i)" (keydown)="onOtpKeydown($event, i)" (click)="onOtpFocus($event, i)">
      </section>

      <div class="p-d-flex p-mt-2 invalid-otp-message"
        *ngIf="(verifyOTPError && verifyOTPError.error.errorCode === ErrorCode.InvalidOTP) || invalidOTPError">
        <i class="oda-info"></i>
        <span>{{'otp.input.error' | translate}}</span>
      </div>

      <div class="otp-resend-via" *ngIf="isSignUpForm && hideTimer">
        {{'otp.did.not.receive.otp' | translate}}
      </div>

      <!--resend button && time && skip otp button-->
      <div class="otp-form-content" *ngIf="showOptions || (!showOptions && !hideTimer)">
        <!-- before 3 times -->
        <button *ngIf="!showOptions && !hideTimer" type="button" class="resend-btn heading-9">
          {{'otp.resend.otp.in' | translate}}
        </button>

        <app-timer *ngIf="!showOptions && !hideTimer" [timeInSec]="timeInSec"
          (onCallback)="onTimerCallback($event)" (onShowBackButton)="onShowBackButton($event)">
        </app-timer>

        <!-- after 3 times && otp login -->
        <div *ngIf="showOptions && !isSignUpForm">
          <div class="otp-resend-via">
            {{ 'otp.resend.message.1' | translate }}
          </div>
          <span class="resend-skip-btn heading-9" (click)="onSendOTP(true)">
            {{ 'otp.resend.message.2' | translate }}
          </span>
          <span *ngIf="userVerifiedEmail !== ''" class="or-text">{{ 'opt.form.des.1' | translate }}</span>
          <button *ngIf="userVerifiedEmail !== ''" type="button" class="resend-skip-btn heading-9" (click)="sendOtpEmail()">
            {{ 'otp.resend.message.3' | translate }}
          </button>
        </div>

        <!-- after 3 times && otp register -->
        <div *ngIf="showOptions && isSignUpForm && numberOfTimeResend <= 0">
          <span class="resend-skip-btn heading-9" (click)="onSendOTP(true)">
            {{'button.resend' | translate}}
          </span>
          <span class="or-text">{{ 'opt.form.des.1' | translate }}</span>
          <button type="button" class="resend-skip-btn heading-9" (click)="skipOtp()">
            {{ 'otp.countdown.skip' | translate }}
          </button>
        </div>
      </div>

      <div class="send-via-email" *ngIf="!showOptions && !isSignUpForm && userVerifiedEmail !== ''">
        <button type="button" class="send-via-email-btn heading-9" (click)="sendOtpEmail()">
          {{'otp.send.via.email' | translate}}
        </button>
      </div>

      <div class="send-via-email" *ngIf="showOptions && isSignUpForm &&
        (numberOfTimeRefresh > 0) && (numberOfTimeResend > 0) && hideTimer">
        <button type="button" class="send-via-email-btn heading-9" (click)="manualResendOTP()">
          {{'otp.resend.counter' | translate : { number: numberOfTimeResend } }}
        </button>
      </div>

    </div>

    <div class="p-d-flex p-jc-center verify-btn-panel">
      <button type="submit" class="verify-btn" id="btn-submit" [ngClass]="{'disabled-button': validLength !== 6}">{{'button.verify' | translate}}</button>
    </div>
  </form>
</div>
