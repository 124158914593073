export enum MembershipRewardType {
  none = "N",
  point = "P",
  stamp = "S",
  pointStamp = "B",
  voucher = "V",
  credit = "C",
  renewMembership = "R",
  activeMembership = "A",
  registerMembership = "M",
  linkPromotions = "L",
  changeMembershipCard = "H",
  upgradeMembership = "U",
  downgradeMembership = "D",
}
