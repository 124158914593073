import { TooltipData } from './../models/TooltipData';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  content : TooltipData;

  showTooltip$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tooltipData$ = new BehaviorSubject<TooltipData>(null);

  constructor() { }

  show(content : TooltipData){
    this.showTooltip$.next(true);
    this.tooltipData$.next(content);
  }

  close(){
    this.showTooltip$.next(false);
  }

  getTooltipContent(){
    return this.content;
  }

}
