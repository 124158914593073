import { CXMCustomerAddressResponse } from './../../../core/models/CXMCustomerAddressResponse';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ChannelService } from 'src/app/home/channel/channel.service';
import { AddressType } from './../../../core/enums/AddressType';
import { ChannelData } from 'src/app/core/models';
import { AddrSysFlag } from './../../../core/enums/AddrSysFlag';
import { CommonAddressResponse } from './../../../core/models/CommonAddressResponse';
import { UserQuery } from 'src/app/core/user/user.query';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit {
  @Input() editSelectedAddress: CXMCustomerAddressResponse;
  @Input() isAddNewAddress: boolean;
  @Input() isAddNewAddressDialog: boolean;
  @Input() savedNewAddress: CommonAddressResponse;
  @Output() onSubmitAddAddress = new EventEmitter();
  @Output() onUpdateAddress = new EventEmitter();
  @Output() onBackPreviousPage = new EventEmitter();

  displayAddAddressForm!: boolean;
  addressType = AddressType;
  addrSysFlag = AddrSysFlag;
  addressForm: UntypedFormGroup = this.fb.group({
    addressId: [],
    addressDesc: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    addressIcon: ["", [Validators.required]],
    unitNo: [""],
    delUnit: [""],
    addrSysFlag: [""],
    channelId: [null, [Validators.required]],
    latitude: [0, [Validators.required]],
    longitude: [0, [Validators.required]],
    delAddress: [""],
    delCountryCode: [""],
    delAddPostal: [""],
    delAddCity: [""],
    delStateCode: [""],
    remarks: [""],
    defaultFlag: [false, [Validators.required]],
  });

  channelData: ChannelData;
  searchValue: string = '';
  selectedAddress$: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private channelService: ChannelService,
    private userQuery: UserQuery,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.channelData = this.channelService.getChannelData();

    if (this.editSelectedAddress) {
      this.addressForm.patchValue({
        addressId: this.editSelectedAddress.addressId,
        addressDesc: this.editSelectedAddress.addressDesc,
        addressIcon: this.editSelectedAddress.addressIcon,
        delUnit: this.editSelectedAddress.delUnit,
        unitNo: this.editSelectedAddress.delUnit,
        addrSysFlag: this.editSelectedAddress.addrSysFlag,
        channelId: this.channelService.getChannelId(),
        latitude: this.editSelectedAddress.latitude,
        longitude: this.editSelectedAddress.longitude,
        delAddress: this.editSelectedAddress.delAddress,
        remarks: this.editSelectedAddress.remarks,
        delCountryCode: this.editSelectedAddress.delCountryCode,
        delAddPostal: this.editSelectedAddress.delAddPostal,
        delAddCity: this.editSelectedAddress.delAddCity,
        delStateCode: this.editSelectedAddress.delStateCode,
        defaultFlag: this.editSelectedAddress.defaultFlag,
      });
      this.searchValue = this.editSelectedAddress.delAddress;
    } else if (this.savedNewAddress) {
      this.addressForm.patchValue({
        channelId: this.channelService.getChannelId(),
        latitude: this.savedNewAddress.latitude,
        longitude: this.savedNewAddress.longitude,
        delAddress: this.savedNewAddress.fullAddress,
        delAddCity: this.savedNewAddress.city,
        delCountryCode: this.savedNewAddress.countryShortName,
        delStateCode: this.savedNewAddress.state,
        delAddPostal: this.savedNewAddress.postalCode
      });
      this.searchValue = this.savedNewAddress.fullAddress;
    }

    this.selectedAddress$ = this.userQuery.selectedAddress$.subscribe(address => {
      if (address && !this.editSelectedAddress && !this.savedNewAddress) {
        this.selectAddress(address);
      }
    });
  }

  onSubmit() {
    if (this.addressForm.valid) {
      if (this.editSelectedAddress) {
        this.onUpdateAddress.emit(this.addressForm.value)
      } else {
        this.addressForm.patchValue({
          delUnit: this.addressForm.get('unitNo').value,
          addrSysFlag: this.channelService.getChannelData().addrSysFlag,
        });
        this.onSubmitAddAddress.emit(this.addressForm.value);
      }
    }
  }

  selectAddress(address: CommonAddressResponse) {
    this.addressForm.patchValue({
      latitude: address.latitude,
      longitude: address.longitude,
      channelId: this.channelService.getChannelId(),
      delAddress: address.fullAddress,
      delAddCity: address.city,
      delAddPostal: address.postalCode,
      delStateCode: address.state,
      delCountryCode: address.countryShortName,
    });
  }

  backPreviousPage() {
    this.onBackPreviousPage.emit();
  }

  ngOnDestroy() {
    this.selectedAddress$.unsubscribe();
  }

  get fg() { return this.addressForm }
  get f() { return this.fg.controls; }
}
