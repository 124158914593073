import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { Subscription } from 'rxjs';
import { ChangeData } from 'ngx-intl-tel-input';
import { LoginForm } from 'src/app/account/models';
import { FormStage } from 'src/app/account/enums/FormStage';
import { OtpService } from 'src/app/account/services/otp.service';
import { UserService } from 'src/app/core/user/user.service';
import { Location } from "@angular/common";
import { SessionStorageService } from '../../storage/session-storage.service';

@Component({
  selector: 'app-qr-mobile-detected',
  templateUrl: './qr-mobile-detected.component.html',
  styleUrls: ['./qr-mobile-detected.component.scss']
})
export class QrMobileDetectedComponent implements OnInit, OnDestroy {

  isLogin : boolean = false;
  isAccountDetected : boolean = false;
  isAreYouInterested : boolean = false;
  phoneData : ChangeData;
  showContinueAsGuest : boolean = true;

  phoneSub$ : Subscription;

  constructor(
    private previousRouteService : PreviousRouteService,
    private router : Router,
    private otpService : OtpService,
    private userService : UserService,
    private route : ActivatedRoute,
    private location : Location,
    private sessionStorageService: SessionStorageService
    ) { }

  async ngOnInit() {
    // get respective routing data to determine which UI to show
    this.isAccountDetected = this.route.snapshot.data['isAccountDetected'] ? this.route.snapshot.data['isAccountDetected'] : false;
    this.isAreYouInterested = this.route.snapshot.data['isInterested'] ? this.route.snapshot.data['isInterested'] : false;

    let phoneCache = JSON.parse(localStorage.getItem("submittedPhoneNumber"));
    this.phoneData = phoneCache ? phoneCache : null;

    this.showContinueAsGuest = this.userService.getShowContinueAsGuestFlag();
  }

  onClickContinueAsGuest(){
    this.userService.removeShowContinueAsGuestFlag();
    // get previous route from store area and remove after that
    let previousRoute = this.previousRouteService.getPreviousRoute();
    this.sessionStorageService.removeItem('previousRoute');
    // navigate by url and replace url
    this.router.navigateByUrl(previousRoute.routeName, {replaceUrl: true});
  }

  async onClickLogin(){
    this.isLogin = true;
    const loginForm: LoginForm = {
      dialCode: this.phoneData.dialCode.replace('+', ''),
      phoneNo: this.formatPhoneNo(this.phoneData),
      stage: FormStage.Login,
      password: '',
      mobileObj: this.phoneData,
      otpCode: '',
    };

    let phoneNoExist : any;
    let pwIsSet : boolean;
    let customerProfileStatus = this.userService.getCustomerStatusCached();

    // set stored data to null after using it
    this.userService.setCustomerProfileStatus(null);

    phoneNoExist = customerProfileStatus.mobileNumber? true: false;
    pwIsSet = customerProfileStatus.passwordFlag? customerProfileStatus.passwordFlag : false;

    if(phoneNoExist && pwIsSet) {
      this.router.navigate(["password-login"], { state: { data: loginForm, phoneNoExist: phoneNoExist }, replaceUrl: true });
    } else {
      await this.otpService.onSendOTP(loginForm.mobileObj);
      this.router.navigate(["otp"], { state: { loginForm: loginForm, phoneNoExist: phoneNoExist, signupForm: !phoneNoExist }, replaceUrl: true });
    }
  }

  async onClickRegister(){
    this.isLogin = true;
    const loginForm: LoginForm = {
      dialCode: this.phoneData.dialCode.replace('+', ''),
      phoneNo: this.formatPhoneNo(this.phoneData),
      stage: FormStage.Signup,
      password: '',
      mobileObj: this.phoneData,
      otpCode: '',
    };

    let phoneNoExist : any;

    // set stored data to null after using it
    this.userService.setCustomerProfileStatus(null);

    phoneNoExist = false;

    try{
      await this.otpService.onSendOTP(loginForm.mobileObj);
    }catch(error){
      // error handling
    }

    this.router.navigate(["otp"], { state: { loginForm: loginForm, phoneNoExist: phoneNoExist, signupForm: !phoneNoExist }, replaceUrl: true });
  }

  backToPreviousPage(){
    this.userService.removeShowContinueAsGuestFlag();
    this.location.back();
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

  ngOnDestroy(): void {
    if(!this.isLogin){
      this.previousRouteService.removePreviousRoute();
    }
  }

}
