import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.scss']
})
export class PaymentFailedComponent implements OnInit {

  payTranId: string = '';
  isFailed: boolean = false;
  isApp: boolean = false;
  errorMsg: string = '';
  orderNumber: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.isFailed = params['IsSuccess'] == 'False' ? true : false;
      this.isApp = params['IsApp'] === 'True' ? true : false;
      this.orderNumber = params['OrderNumber'] ? params['OrderNumber'] : '';

      if (this.isFailed && this.isApp) {
        this.location.replaceState('/payment-failed/' + this.payTranId);
      }
      if (this.isFailed) {
        this.router.navigateByUrl("/order-payment", { state: { isPaymentFailed: true } });
      }
    });
  }
}
