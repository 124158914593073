import { ModSelectionData } from './../models/local/ModSelectionData';
import { SubModifierGrp } from './../models/SubModifierGrp';
import { MenuStore } from './menu.store';
import { Injectable } from "@angular/core";
import { MenuQuery } from "./menu.query";
import { Menu } from './menu.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuItem } from '../models/MenuItem';
import { ModifierGrp } from '../models/ModifierGrp';
import { Modifier } from '../models/Modifier';
import { MenuItemStatus } from '../enums/MenuItemStatus';
import { MenuCatg } from '../models/MenuCatg';
import { MenuCatgGrp } from '../models/MenuCatgGrp';
import { SubModifier } from '../models/SubModifier';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class MenuService {

  selectedMenuItem$ : BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>(null);
  selectedModifier$ : BehaviorSubject<Modifier> = new BehaviorSubject<Modifier>(null);
  updateSelectedMod$ : Subject<ModifierGrp> = new Subject();
  updateSelectedSubMod$ : Subject<SubModifierGrp> = new Subject();
  updateCustomizeItem$ : Subject<any> = new Subject<{modGrpCode : string, curModifier: Modifier}>()

  constructor(
    private menuStore: MenuStore,
    private menuQuery: MenuQuery
  ) { }

  getMenu(storeId : number){
    return this.menuQuery.getEntity(storeId);
  }

  getMenuObservable(storeId : number){
    return this.menuQuery.selectEntity(storeId);
  }

  getAllMenuObservable(){
    return this.menuQuery.selectAll();
  }

  upsertMenu(storeId: number, menu: Menu){
    this.menuStore.upsert(storeId, menu);
  }

  removeMenu(storeId : number){
    this.menuStore.remove(storeId);
  }

  removeAllMenu(){
    this.menuStore.remove();
  }

  async checkIndividualModGrp(modifierGrp : ModifierGrp){
    if(modifierGrp.minSelect == 0){
      modifierGrp.modifiers.forEach(mod => {
        mod.isComplete = true;
      })
    }
    else{
      modifierGrp = await this.normalIndividualCheck(modifierGrp);
    }

    let modComplete = modifierGrp.modifiers.every(val => val.isComplete == true);
    modifierGrp.isComplete = modComplete && (modifierGrp.qtyGrp >= modifierGrp.minSelect || modifierGrp.minSelect == 0 || modifierGrp.modifiers.length == 1) ? true : false;
    return modifierGrp;
  }

  async normalIndividualCheck(modifierGrp : ModifierGrp){
    let counter = 0;

    let modLength = modifierGrp.modifiers.length;
    for(let index = 0; index < modLength; index++){
      if(modifierGrp.modifiers[index].subModifierGrps){
        if(modifierGrp.modifiers[index].qty > 0 && modifierGrp.modifiers[index].minSelect > 0  && modifierGrp.modifiers[index].status == MenuItemStatus.Available){
          counter = modifierGrp.modifiers[index].subModifierGrps.filter(val => (val.isComplete == true) || (val.isComplete == undefined && val.qtyGrp >= val.minSelect)).length;

          if(counter == modifierGrp.modifiers[index].subModifierGrps.length){
            modifierGrp.modifiers[index].isComplete = true;
          }
          else{
            modifierGrp.modifiers[index].isComplete = false;
          }
        }
        else{
          modifierGrp.modifiers[index].isComplete = true;
        }
      }
      else{
        if(modifierGrp.minSelect == 1 && modifierGrp.modifiers.length == 1){
          modifierGrp.modifiers[index].isComplete = true;
        }
        else if(modifierGrp.qtyGrp >= modifierGrp.minSelect || modifierGrp.modifiers[index].status == MenuItemStatus.Unavailable){
          modifierGrp.modifiers[index].isComplete = true;
        }
        else{
          modifierGrp.modifiers[index].isComplete = false;
        }
      }
    }

    return modifierGrp;
  }

  async filteredChosenMenuGrp(menuCatgs : MenuCatg[], chosenMenuGrp : MenuCatgGrp){
    let filteredMenu = [];

    let recommendedSection = menuCatgs.find(menu => menu.code == "9999");

    if(chosenMenuGrp){
      let menuGrpLength = chosenMenuGrp.menuCatgs.length;
      for(let index = 0; index < menuGrpLength; index++){
        let menuCatgFound = menuCatgs.find(item => item.code == chosenMenuGrp.menuCatgs[index]);
        if(menuCatgFound){
          filteredMenu.push(menuCatgFound);
        }
      }
    }
    else{
      filteredMenu = filteredMenu.concat(menuCatgs);
    }

    filteredMenu = _.sortBy(filteredMenu, ['seqNo']);

    if(recommendedSection){
      filteredMenu.unshift(recommendedSection);
    }

    return filteredMenu;
  }

  //#region init menu details
  // menu item details(modifier)
  async initializeItemDetails(menuItem : MenuItem){
    let modifierGrpLength = menuItem.modifierGrps.length;
    for(let index = 0; index < modifierGrpLength; index ++){
      menuItem.modifierGrps[index] = await this.initModifiers(menuItem.modifierGrps[index]);
    }
    return menuItem;
  }

  async initModifiers(modifierGrp : ModifierGrp){
    let modLength = modifierGrp.modifiers.length;
    for(let index = 0; index < modLength; index ++){
      if(modifierGrp.minSelect == 1 && modifierGrp.modifiers.length == 1){
        modifierGrp.isComplete = true;
        if(modifierGrp.modifiers[0].status == MenuItemStatus.Available){
          let itemData = await this.modifierClickedCheck(modifierGrp, modifierGrp.modifiers[index]);

          modifierGrp.qtyGrp = itemData.totalModGrpQty;
          modifierGrp.modifiers[index].qty = itemData.totalModQty;
          modifierGrp.modifiers[index].isComplete = true;
        }
      }
      else {
        if(modifierGrp.qtyGrp == undefined){
          modifierGrp.qtyGrp = 0;
        }

        if (modifierGrp.modifiers[index].qty == undefined) {
          modifierGrp.modifiers[index].qty = 0;
        }
      }

      if(modifierGrp.modifiers[index].subModifierGrps){
        modifierGrp.modifiers[index].subModifierGrps = await this.initSubModifierGrp(modifierGrp.modifiers[index].subModifierGrps);
      }
    }

    return modifierGrp;
  }

  async initSubModifierGrp(subModifierGrp : SubModifierGrp[]){
    let subModGrpLength = subModifierGrp.length;

    for(let index = 0; index < subModGrpLength; index++){
      subModifierGrp[index] = await this.initSubMod(subModifierGrp[index]);
    }
    return subModifierGrp;
  }

  async initSubMod(subModifierGrp : SubModifierGrp){
    let subModLength = subModifierGrp.subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      if(subModifierGrp.minSelect == 1 && subModifierGrp.subModifiers.length == 1){
        if(subModifierGrp.subModifiers[0].status == MenuItemStatus.Available){
          let itemData = await this.subModifierClickedCheck(subModifierGrp, subModifierGrp.subModifiers[index]);
          subModifierGrp.qtyGrp = itemData.totalModGrpQty;
          subModifierGrp.subModifiers[index].qty = itemData.totalModQty;
          subModifierGrp.isComplete = true;
        }
      }
      else{
        if (subModifierGrp.qtyGrp == undefined) {
          subModifierGrp.qtyGrp = 0
        }

        if (subModifierGrp.subModifiers[index].qty == undefined) {
          subModifierGrp.subModifiers[index].qty = 0;
        }
      }
    }

    return subModifierGrp;
  }
  //#endregion

  //#region item min max check
  async itemMinMaxCheck(menuItem : MenuItem){
    if(!menuItem){
      return null;
    }

    if(menuItem.modifierGrps){
      menuItem.modifierGrps = await this.modGrpMinMaxCheck( menuItem.modifierGrps);
    }

    return menuItem;
  }

  async modGrpMinMaxCheck(modifierGrps : ModifierGrp[]){
    let grpLength = modifierGrps.length;
    for(let index = 0; index < grpLength; index++){
      if(modifierGrps[index].minSelect > modifierGrps[index].maxSelect && modifierGrps[index].maxSelect != 0)
        modifierGrps[index].minSelect = Math.min(modifierGrps[index].minSelect, modifierGrps[index].maxSelect);

      if(modifierGrps[index].modifiers && modifierGrps[index].modifiers?.length > 0)
        modifierGrps[index].modifiers = await this.modMinMaxCheck(modifierGrps[index].modifiers);
    }

    return modifierGrps;
  }

  async modMinMaxCheck(modifiers : Modifier[]){
    let modLength = modifiers.length;
    for(let index = 0; index < modLength; index++){
      if(modifiers[index].minSelect > modifiers[index].maxSelect && modifiers[index].maxSelect != 0)
        modifiers[index].minSelect = Math.min(modifiers[index].minSelect, modifiers[index].maxSelect);

      if(modifiers[index].subModifierGrps && modifiers[index].subModifierGrps?.length > 0)
        modifiers[index].subModifierGrps = await this.subModGrpMinMaxCheck(modifiers[index].subModifierGrps);
    }

    return modifiers;
  }

  async subModGrpMinMaxCheck(subModGrps : SubModifierGrp[]){
    let subGrpLength = subModGrps.length;
    for(let index = 0; index < subGrpLength; index++){
      if(subModGrps[index].minSelect > subModGrps[index].maxSelect && subModGrps[index].maxSelect != 0)
        subModGrps[index].minSelect = Math.min(subModGrps[index].minSelect, subModGrps[index].maxSelect);

      if(subModGrps[index].subModifiers && subModGrps[index].subModifiers.length)
        subModGrps[index].subModifiers = await this.subModMinMaxCheck(subModGrps[index].subModifiers);
    }

    return subModGrps;
  }

  async subModMinMaxCheck(subMods : SubModifier[]){
    let subModLength = subMods.length;
    for(let index = 0; index < subModLength; index++){
      if(subMods[index].minSelect > subMods[index].maxSelect && subMods[index].maxSelect != 0)
        subMods[index].minSelect = Math.min(subMods[index].minSelect, subMods[index].maxSelect);
    }

    return subMods;
  }
  //#endregion

  //#region modifier selection logic
  async modifierClickedCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let itemData = {} as ModSelectionData;

    if((modifierGrp.minSelect == 1 && modifierGrp.modifiers.length == 1)){
      itemData = await this.modGrpMandatoryOnlyModCheck(modifierGrp, modifier);
    }
    else if(modifierGrp.minSelect == 1 && modifierGrp.maxSelect == 1 && modifier.minSelect > 0 && modifier.maxSelect > 0
      && modifier.minSelect == modifier.maxSelect){
      itemData = await this.modifierComboBundleCheck(modifierGrp, modifier);
    }
    else if((modifierGrp.minSelect == 0 && modifierGrp.maxSelect == 1) || (modifierGrp.minSelect == 1 && modifierGrp.maxSelect == 1)){
      itemData = await this.modifierGrpMaximumSelectionOne(modifierGrp, modifier);
    }
    else if(modifierGrp.minSelect == modifierGrp.maxSelect && modifierGrp.modifiers.length == 2 && modifierGrp.minSelect > 0 && modifierGrp.maxSelect > 0){
      itemData = await this.modGrpMinMaxSameModLengthTwo(modifierGrp, modifier);
    }
    else if((modifier.minSelect == 0 && modifier.maxSelect == 1) || (modifier.minSelect == 1 && modifier.maxSelect == 1)){
      itemData = await this.modMaxSelectEqualsOne(modifierGrp, modifier);
    }
    else{
      itemData = await this.modifierDefaultCheck(modifierGrp, modifier);
    }

    return Object.keys(itemData).length > 0 ? itemData : null;
  }

  //#endregion

  //#region mandatory modifier group only one modifier selection check
  async modGrpMandatoryOnlyModCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let itemData = {} as ModSelectionData;

    if(modifierGrp.minSelect == 1 && modifierGrp.maxSelect == 1 && modifier.minSelect > 0 && modifier.maxSelect > 0
      && modifier.minSelect == modifier.maxSelect){
      itemData.totalModGrpQty = 1;
      itemData.totalModQty = modifier.minSelect;
      return itemData;
    }
    else{
      itemData.totalModGrpQty = 1;
      itemData.totalModQty = 1;
      return itemData;
    }
  }
  //#endregion

  //#region combo bundle check
  async modifierComboBundleCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;

    if(!modifierGrp.qtyGrp){
      modSelectionData.totalModGrpQty = 1;
      modSelectionData.totalModQty = modifier.minSelect;
    }
    else if(modifierGrp.qtyGrp > 0){
      if(modifier.qty > 0){
        modSelectionData.totalModGrpQty = 0;
        modSelectionData.totalModQty = 0;
        modSelectionData.isResetSubModifier = true;
      }
      else{
        modSelectionData.totalModGrpQty = modifierGrp.maxSelect;
        modSelectionData.totalModQty = modifier.minSelect;
        modSelectionData.isResetSubModifier = true;
        modSelectionData.resetModQty = true;
      }
    }

    return modSelectionData;
  }
  //#endregion

  //#region modifier group maximum selection is 1
  async modifierGrpMaximumSelectionOne(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;

    if(modifierGrp.qtyGrp == 1){
      if(modifier.qty == 1){
        modSelectionData.totalModGrpQty = 0;
        modSelectionData.totalModQty = 0;
        modSelectionData.removeCurrentModOnly = true;
        modSelectionData.resetModQty = true;
      }
      else{
        modSelectionData.totalModGrpQty = 1;
        modSelectionData.totalModQty = 1;
        modSelectionData.isResetSubModifier = true;
        modSelectionData.resetModQty = true;
      }
    }
    else{
      modSelectionData.totalModGrpQty = 1;
      modSelectionData.totalModQty = 1;
    }

    return modSelectionData;
  }
  //#endregion

  //#region modifier group minSelect == minSelect AND modifier length is 2
  async modGrpMinMaxSameModLengthTwo(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;

    if (modifierGrp.qtyGrp == 0) {
      if(modifier.minSelect == 0 && modifier.maxSelect == 0){
        modSelectionData.totalModGrpQty = modifierGrp.maxSelect;
        modSelectionData.totalModQty = modifierGrp.maxSelect;
      }
      else{
        modSelectionData.totalModGrpQty = Math.min(modifierGrp.maxSelect, modifier.maxSelect);
        modSelectionData.totalModQty = Math.min(modifierGrp.maxSelect, modifier.maxSelect);
      }
    }
    else if (modifierGrp.qtyGrp == modifierGrp.maxSelect) {
      if(modifier.qty == modifierGrp.maxSelect){
        modSelectionData.totalModGrpQty = 0;
        modSelectionData.totalModQty = 0;
        modSelectionData.removeCurrentModOnly = true;
        modSelectionData.resetModQty = true;
      }
      else if(modifier.qty >= 0 && ((modifier.minSelect == 0 && modifier.maxSelect == 0) || (modifier.qty != modifier.maxSelect))){
        modSelectionData.totalModGrpQty = modifierGrp.qtyGrp;
        modSelectionData.totalModQty = modifier.qty + 1;
        modSelectionData.isResetSubModifier = true;
      }
      else if(modifier.qty == modifier.maxSelect){
        // will prompt maximum error message when reached here
        modSelectionData.totalModGrpQty = modifierGrp.qtyGrp + 1;
        modSelectionData.totalModQty = modifier.qty + 1;
      }
    }
    else if (modifierGrp.qtyGrp > 0 && modifierGrp.qtyGrp < modifierGrp.maxSelect) {
      modSelectionData.totalModGrpQty = modifierGrp.qtyGrp + 1;
      modSelectionData.totalModQty = modifier.qty + 1;
    }
    else{
      return modSelectionData;
    }

    return modSelectionData;
  }
  //#endregion

  //#region modifier max selection is 1
  async modMaxSelectEqualsOne(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;

    if(modifier.qty == 1){
      modSelectionData.totalModGrpQty = modifierGrp.qtyGrp - 1;
      modSelectionData.totalModQty = 0;
      modSelectionData.removeCurrentModOnly = true;
      modSelectionData.resetModQty = true;
    }
    else{
      modSelectionData.totalModGrpQty = modifierGrp.qtyGrp + 1;
      modSelectionData.totalModQty = 1;
    }

    return modSelectionData;
  }
  //#endregion

  //#region default modifier check
  async modifierDefaultCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let itemData = {} as ModSelectionData;

    if(modifier.qty == 0){
      itemData = await this.modifierDefaultZeroQtyCheck(modifierGrp, modifier);
    }
    else{
      itemData = await this.modifierQtyOccupiedCheck(modifierGrp, modifier);
    }

    return itemData;
  }

  async modifierDefaultZeroQtyCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;

    let amountToIncrement = 0;

    if(modifier.minSelect > 1){
      amountToIncrement = modifier.minSelect;
    }
    else{
      amountToIncrement = 1;
    }

    modSelectionData.totalModGrpQty = modifierGrp.qtyGrp + amountToIncrement;
    modSelectionData.totalModQty = modifier.qty + amountToIncrement;
    return modSelectionData;
  }

  async modifierQtyOccupiedCheck(modifierGrp : ModifierGrp, modifier : Modifier){
    let modSelectionData = {} as ModSelectionData;
    let amountToIncrement = 0;
    let isMinus : boolean = false;

    if(modifier.minSelect == modifier.maxSelect && modifier.minSelect != 0 && modifier.maxSelect != 0){
      amountToIncrement = modifier.minSelect
      isMinus = true;
    }
    else{
      amountToIncrement = 1;
    }

    modSelectionData.totalModGrpQty = isMinus ? modifierGrp.qtyGrp - amountToIncrement : modifierGrp.qtyGrp + amountToIncrement;
    modSelectionData.totalModQty = isMinus ? modifier.qty - amountToIncrement : modifier.qty + amountToIncrement;

    return modSelectionData;
  }
  //#endregion

  //#region submodifier selection logic
  async subModifierClickedCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let itemData = {} as ModSelectionData;

    if((subModifierGrp.minSelect == 1 && subModifierGrp.subModifiers.length == 1)){
      itemData = await this.subModGrpMandatoryOnlySubModCheck(subModifierGrp, subModifier);
    }
    else if(subModifierGrp.minSelect == 1 && subModifierGrp.maxSelect == 1 && subModifier.minSelect > 0 && subModifier.maxSelect > 0
      && subModifier.minSelect == subModifier.maxSelect){
      itemData = await this.subModifierComboBundleCheck(subModifierGrp, subModifier);
    }
    else if((subModifierGrp.minSelect == 0 && subModifierGrp.maxSelect == 1) || (subModifierGrp.minSelect == 1 && subModifierGrp.maxSelect == 1)){
      itemData = await this.subModifierGrpMaximumSelectionOne(subModifierGrp, subModifier);
    }
    else if(subModifierGrp.minSelect == subModifierGrp.maxSelect && subModifierGrp.subModifiers.length == 2 && subModifierGrp.minSelect > 0 && subModifierGrp.maxSelect > 0){
      itemData = await this.subModGrpMinMaxSameSubModLengthTwo(subModifierGrp, subModifier);
    }
    else if((subModifier.minSelect == 0 && subModifier.maxSelect == 1) || (subModifier.minSelect == 1 && subModifier.maxSelect == 1)){
      itemData = await this.subModMaxSelectEqualsOne(subModifierGrp, subModifier);
    }
    else{
      itemData = await this.subModifierDefaultCheck(subModifierGrp, subModifier);
    }

    return Object.keys(itemData).length > 0 ? itemData : null;
  }
  //#endregion

  //#region mandatory submodifier modifier group only one submodifier selection check
  async subModGrpMandatoryOnlySubModCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let itemData = {} as ModSelectionData;

    if(subModifierGrp.minSelect == 1 && subModifierGrp.maxSelect == 1 && subModifier.minSelect > 0 && subModifier.maxSelect > 0
      && subModifier.minSelect == subModifier.maxSelect){
      itemData.totalModGrpQty = 1;
      itemData.totalModQty = subModifier.minSelect;
      return itemData;
    }
    else{
      itemData.totalModGrpQty = 1;
      itemData.totalModQty = 1;
      return itemData;
    }
  }
  //#endregion

  //#region submodifier combo bundle check
  async subModifierComboBundleCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let subModSelectionData = {} as ModSelectionData;

    if(!subModifierGrp.qtyGrp){
      subModSelectionData.totalModGrpQty = 1;
      subModSelectionData.totalModQty = subModifier.minSelect;
    }
    else if(subModifierGrp.qtyGrp > 0){
      if(subModifier.qty > 0){
        subModSelectionData.totalModGrpQty = 0;
        subModSelectionData.totalModQty = 0;
      }
      else{
        subModSelectionData.totalModGrpQty = 1;
        subModSelectionData.totalModQty = subModifier.minSelect;
        subModSelectionData.clearAllSubModQty = true;
        subModSelectionData.resetSubModQty = true;
      }
    }

    return subModSelectionData;
  }
  //#endregion

  //#region submodifier group maximum selection is 1
  async subModifierGrpMaximumSelectionOne(subModifierGrp : ModifierGrp, subModifier : SubModifier){
    let subModSelectionData = {} as ModSelectionData;

    if(subModifierGrp.qtyGrp == 1){
      if(subModifier.qty == 1){
        subModSelectionData.totalModGrpQty = 0;
        subModSelectionData.totalModQty = 0;
        subModSelectionData.removeCurrentSubModQty = true;
        subModSelectionData.resetSubModQty = true;
      }
      else{
        subModSelectionData.totalModGrpQty = 1;
        subModSelectionData.totalModQty = 1;
        subModSelectionData.clearAllSubModQty = true;
        subModSelectionData.resetSubModQty = true;
      }
    }
    else{
      subModSelectionData.totalModGrpQty = 1;
      subModSelectionData.totalModQty = 1;
    }

    return subModSelectionData;
  }
  //#endregion

  //#region submodifier group minSelect == minSelect AND submodifier length is 2
  async subModGrpMinMaxSameSubModLengthTwo(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let subModSelectionData = {} as ModSelectionData;

    if (subModifierGrp.qtyGrp == 0) {
      if(subModifier.minSelect == 0 && subModifier.maxSelect == 0){
        subModSelectionData.totalModGrpQty = subModifierGrp.maxSelect;
        subModSelectionData.totalModQty = subModifierGrp.maxSelect;
      }
      else{
        subModSelectionData.totalModGrpQty = Math.min(subModifierGrp.maxSelect, subModifier.maxSelect);
        subModSelectionData.totalModQty = Math.min(subModifierGrp.maxSelect, subModifier.maxSelect);
      }
    }
    else if (subModifierGrp.qtyGrp == subModifierGrp.maxSelect) {
      if(subModifier.qty == subModifierGrp.maxSelect){
        subModSelectionData.totalModGrpQty = 0;
        subModSelectionData.totalModQty = 0;
      }
      else if(subModifier.qty >= 0 && ((subModifier.minSelect == 0 && subModifier.maxSelect == 0) || (subModifier.qty != subModifier.maxSelect))){
        subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp;
        subModSelectionData.totalModQty = subModifier.qty + 1;
        subModSelectionData.clearAllSubModQty = true;
      }
      else if(subModifier.qty == subModifier.maxSelect){
        // will prompt maximum error message when reached here
        subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp + 1;
        subModSelectionData.totalModQty = subModifier.qty + 1;
      }
    }
    else if (subModifierGrp.qtyGrp > 0 && subModifierGrp.qtyGrp < subModifierGrp.maxSelect) {
      subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp + 1;
      subModSelectionData.totalModQty = subModifier.qty + 1;
    }
    else{
      return subModSelectionData;
    }

    return subModSelectionData;
  }
  //#endregion

  //#region submodifier max selection is 1
  async subModMaxSelectEqualsOne(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let subModSelectionData = {} as ModSelectionData;

    if(subModifier.qty == 1){
      subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp - 1;
      subModSelectionData.totalModQty = 0;
      subModSelectionData.removeCurrentSubModQty = true;
      subModSelectionData.resetSubModQty = true;
    }
    else{
      subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp + 1;
      subModSelectionData.totalModQty = 1;
    }

    return subModSelectionData;
  }
  //#endregion

  //#region default submodifier check
  async subModifierDefaultCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let itemData = {} as ModSelectionData;

    if(subModifier.qty == 0){
      itemData = await this.subModifierDefaultZeroQtyCheck(subModifierGrp, subModifier);
    }
    else{
      itemData = await this.subModifierQtyOccupiedCheck(subModifierGrp, subModifier);
    }

    return itemData;
  }

  async subModifierDefaultZeroQtyCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let amountToIncrement = 0;
    let subModSelectionData = {} as ModSelectionData;

    if(subModifier.minSelect > 1){
      amountToIncrement = subModifier.minSelect;
    }
    else{
      amountToIncrement = 1;
    }

    subModSelectionData.totalModGrpQty = subModifierGrp.qtyGrp + amountToIncrement;
    subModSelectionData.totalModQty = subModifier.qty + amountToIncrement;
    return subModSelectionData;
  }

  async subModifierQtyOccupiedCheck(subModifierGrp : SubModifierGrp, subModifier : SubModifier){
    let subModSelectionData = {} as ModSelectionData;
    let amountToIncrement = 0;
    let isMinus : boolean = false;

    if(subModifier.minSelect == subModifier.maxSelect && subModifier.minSelect != 0 && subModifier.maxSelect != 0){
      amountToIncrement = subModifier.minSelect
      isMinus = true;
    }
    else{
      amountToIncrement = 1;
    }

    subModSelectionData.totalModGrpQty = isMinus ? subModifierGrp.qtyGrp - amountToIncrement : subModifierGrp.qtyGrp + amountToIncrement;
    subModSelectionData.totalModQty = isMinus ? subModifier.qty - amountToIncrement : subModifier.qty + amountToIncrement;

    return subModSelectionData;
  }
  //#endregion

  //#region logic to remove modifier and submodifier
  async removeSubModSelectedQty(modGrp: ModifierGrp, modifier: Modifier, clearAll : boolean = false){
    for await(let mod of modGrp.modifiers){
      if (mod.itemCode != modifier.itemCode) {
        if (mod.qty > 0) {
          mod.qty = clearAll ? 0 : mod.qty - 1;
          if(mod.qty == 0 && mod.subModifierGrps){
            for await(let subModGrp of mod.subModifierGrps){
              subModGrp.isComplete = undefined;
              if(subModGrp.qtyGrp > 0){
                subModGrp.qtyGrp = 0;
                subModGrp.subModifiers.forEach(val => {
                  if(val.qty > 0){
                    val.qty = 0;
                  }
                })
              }
            }
          }
        }
      }
    }

    return modGrp;
  }

  async removeSelectedModOnly(modGrp: ModifierGrp, modifier: Modifier, clearAll : boolean = false){
    for await(let mod of modGrp.modifiers){
      if (mod.itemCode == modifier.itemCode) {
        if (mod.qty > 0) {
          mod.qty = clearAll ? 0 : mod.qty - 1;
          if(mod.qty == 0 && mod.subModifierGrps){
            for await(let subModGrp of mod.subModifierGrps){
              subModGrp.isComplete = undefined;
              if(subModGrp.qtyGrp > 0){
                subModGrp.qtyGrp = 0;
                subModGrp.subModifiers.forEach(val => {
                  if(val.qty > 0){
                    val.qty = 0;
                  }
                })
              }
            }
          }
        }
      }
    }

    return modGrp;
  }

  async removeCurrentSubModQty(subModGrp : SubModifierGrp, subModifier : SubModifier, clearAll : boolean = false){
    let subModLength = subModGrp.subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      if(subModGrp.subModifiers[index].itemCode == subModifier.itemCode){
        subModGrp.subModifiers[index].qty = clearAll ? 0 : subModGrp.subModifiers[index].qty - 1;
      }
    }

    return subModGrp;
  }

  async removeAllSubModQtyExceptSelf(subModGrp : SubModifierGrp, subModifier : SubModifier, clearAll : boolean = false){
    let subModLength = subModGrp.subModifiers.length;
    for(let index = 0; index < subModLength; index++){
      if(subModGrp.subModifiers[index].itemCode != subModifier.itemCode && subModGrp.subModifiers[index].qty > 0){
        subModGrp.subModifiers[index].qty = clearAll ? 0 : subModGrp.subModifiers[index].qty - 1;
      }
    }

    return subModGrp;
  }
  //#endregion
}
