import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StoreCatgAttrs } from 'src/app/core/models';
import { CurrentStatusFlag } from 'src/app/core/enums/StatusFlag';
import { CurrencyResponse } from 'src/app/core/models/CurrencyResponse';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import * as _ from 'lodash';
import { OrderTypeFlag } from 'src/app/core/enums/OrderTypeFlag';
import { StoreService } from 'src/app/store/store/store.service';
import { AttrCode } from 'src/app/core/enums/AttrCode';
import { CartModel } from 'src/app/core/models/CartModel';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { OrderH } from 'src/app/core/models/OrderH';

@Component({
  selector: 'app-nearby-store',
  templateUrl: './nearby-store.component.html',
  styleUrls: ['./nearby-store.component.scss']
})
export class NearbyStoreComponent implements OnInit {
  @Input() storeList: StoreResponse[];
  @Input() currency: CurrencyResponse;
  @Input() showRewardDialog: boolean = false;
  @Input() currentCart : CartModel;

  channel$: Subscription = new Subscription();
  storeStatusFlag: any = CurrentStatusFlag;
  orderTypeFlag = OrderTypeFlag;
  attrCode = AttrCode;

  constructor(private router: Router,
    private storeService : StoreService,
    private staticQrService : StaticQrService
  ) { }

  ngOnInit(): void {}

  getStoreAttrDesc(storeCatgAttrs?: StoreCatgAttrs[]): string {
    return _.chain(storeCatgAttrs).map((attr) => {
      return attr.attrDesc;
    }).join(', ')
      .value();
  }

  async onNavigate(storeData: StoreResponse) {
    this.storeService.removeIsDisplayedFlag();

    let globalOrderType = this.staticQrService.getChannelOrderType();
    let storeCart : OrderH = null;
    let orderType : string;

    if((!globalOrderType || globalOrderType == OrderTypeFlag.All) && this.currentCart && this.currentCart?.orderHs && this.currentCart.orderHs?.length > 0){
      storeCart = this.currentCart.orderHs.find(orderH => orderH.storeId == storeData.storeId);
    }

    if(storeCart){
      orderType = this.storeService.getOrderType(storeCart.orderData.sourceFlag);
    }
    else{
      orderType = storeData.currentOrderType;
    }

    this.storeService.preStoreNavigation(orderType, storeData, this.showRewardDialog);
  }
}
