import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { persistState, enableAkitaProdMode } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const storage = persistState({
  key: 'odaring',
  include: ['user'],
});

const cartStorage = persistState({
  key: 'cart',
  include: ['cart'],
});

const qrCartStorage = persistState({
  key: 'qr-cart',
  include: ['qr-cart'],
});

const channelUserStorage = persistState({
  key: 'channelUser',
  include: ['channelUser'],
});

const staticQrStorage = persistState({
  key: 'staticQr',
  include: ['staticQr'],
  storage: sessionStorage
});

const queueCartStorage = persistState({
  key: 'queue-cart',
  include: ['queue-cart']
})

const queueDataStorage = persistState({
  key: 'queue-data',
  include: ['queue-data'],
  storage: sessionStorage
})

const externalMemberDataStorage = persistState({
  key: 'externalMembership-data',
  include: ['externalMembership-data'],
  storage: sessionStorage
})


const miniProgramStorage = persistState({
  key: 'mp-data',
  include: ['mp-store'],
  storage: sessionStorage
})

const providers = [
  { provide: 'persistStorage', useValue: storage },
  { provide: 'persistStorage', useValue: cartStorage },
  { provide: 'persistStorage', useValue: qrCartStorage },
  { provide: 'persistStorage', useValue: channelUserStorage},
  { provide: 'persistStorage', useValue: staticQrStorage},
  { provide: 'persistStorage', useValue: queueCartStorage},
  { provide: 'persistStorage', useValue: queueDataStorage},
  { provide: 'persistStorage', useValue: miniProgramStorage},
  { provide: 'persistStorage', useValue: externalMemberDataStorage}
]

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

function bootstrap() {
     platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
   };


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}

