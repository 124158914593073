<!-- desktop view -->
<div class="search-position">
  <form (ngSubmit)="onSearch()" [formGroup]="searchFgroup">
    <div class="search-contianer">
      <!--overlay-->
      <div *ngIf="(displaySearchDropdown || displayFilterDropdown) && !isMobileView"
        (click)="onClickCloseSearchDropdown()" class="search-overlay"></div>

      <!-- mobile show input textbox only -->
      <div *ngIf="isFromHome && isMobileView" class="hidden-lg" style="padding: 0px 20px;" [style.background-color]="'var(--primary-color)'">
        <div class="searchbar-container">
          <div class="searchbar heading-12" (click)="showSearchDialog()">
            <i class="oda-search"></i>{{ 'input.placeholder.11' | translate }}
          </div>
          <button type="button" class="filter-btn filter-btn-color-{{filterColorSet ? filterColorSet.setValue : 1 }}" (click)="onClickDisplayFilterDropdown()">
            <i class="oda-filter">
              <p-badge *ngIf="totalCountItem != 0" [value]="totalCountItem" styleClass="badge-white-mobile"></p-badge>
            </i>
          </button>
        </div>
      </div>

      <!--search input box-->
      <div class="p-float-label" style="z-index: 2; display: flex;" *ngIf="!(isFromHome && isMobileView)">
        <input type="text" pInputText placeholder="{{ 'home.appbar.searchbar.input.text' | translate }}"
          formControlName="searchInputText" name="searchInputText" #searchValue
          autocomplete="off" (click)="onClickDisplaySearchDropdown()" />
        <i class="oda-search"></i>

        <!--clear input field button-->
        <button type="button" class="clear-btn" *ngIf="searchInputValue" (click)="clearSearchField()">
          <i class="oda-times"></i>
        </button>

        <button *ngIf="!displaySearchDropdown && isFromHome" type="button"
          class="filter-btn filter-btn-color-{{filterColorSet ? filterColorSet.setValue : 1 }}" (click)="onClickDisplayFilterDropdown()">
          <i class="oda-filter">
            <p-badge *ngIf="totalCountItem != 0" [value]="totalCountItem" styleClass="badge-white"></p-badge>
          </i>
        </button>
      </div>

      <div *ngIf="displaySearchDropdown && !isMobileView" class="search-dropdown">
        <!--recent search list and hot search-->
        <div *ngIf="recentSearch && recentSearch.length > 0" class="heading-11 recent-delete-heading">
          {{ 'search.result.recent.search' | translate }}
          <button type="button" class="clearAll-btn" (click)="ondelete()">
            <i class="duotone oda-d-trash"></i>
          </button>
        </div>

        <!--recennt search list-->
        <div class="recent-list">
          <div *ngFor="let searchText of recentSearch; let i = index">
            <h1 class="heading-9 recent-heading" (click)="onRecentClick(searchText)">
              {{ searchText }}
              <button type="button"
                style="outline: none; border: none; background-color: transparent; padding-right: 5px;"
                (click)="removeRecentRecord(i); $event.stopPropagation()">
                <i class="oda-times" style="font-size: 10px; color: #B1B1B1;  "></i>
              </button>
            </h1>
            <hr />
          </div>
        </div>

        <!--hot search-->
        <div style="padding: 0px 25px; padding-top: 8px;">
          <h1 class="heading-11">
            <i class="duotone oda-d-firetag" style="font-size: 20px; color: var(--iconTextButton-primary); vertical-align: middle; "></i>
            {{'merchant.search.category.hot.search' | translate}}
          </h1>

          <div class="p-d-flex p-flex-wrap">
            <div *ngFor="let cuisine of cuisines" (click)="onRecentClick(cuisine.attrOptionDesc)"
              class="p-mr-2 p-mb-2 p-mt-1 heading-9"
              style="width: auto; height: 35px; border-radius: 18px; background-color: rgb(253 239 35 / 15%); padding: 7px 16px; cursor: pointer;">
              {{cuisine.attrOptionDesc}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- filter dropdown -->
  <div *ngIf="displayFilterDropdown" class="filter-containers hidden-sm">
    <div class="filter-dropdown">
      <div class="filter-header">
        <h1 class="heading-11">{{'filter.page.title' | translate}}</h1>
        <button type="button" class="delete-btn" (click)="clearAllFilter()">
          <i class="duotone oda-d-trash"></i>
          <span>{{'filter.page.clear.all' | translate}}</span>
        </button>
      </div>

      <div class="content" style="margin-bottom: -10px;">
        <h1 class="heading-11">{{catgAttrsDes_2}}</h1>
        <p-selectButton [options]="catgAttrsList_2" [(ngModel)]="catgAttrsValue_2" optionLabel="attrOptionDesc"
          (click)="onClickItem()" [multiple]="true">
        </p-selectButton>
      </div>

      <div class="content">
        <h1 class="heading-11">{{'input.label.15' | translate}}</h1>
        <div class="heading-12 price-tag" [ngClass]="{'heading-13': !priceRangeActive}">
          <span>{{selectedChannel.currency.currPrefix}} {{priceRangeValues[0]}}</span>
          <span>{{selectedChannel.currency.currPrefix}} {{priceRangeValues[1]}}<span
              *ngIf="priceRangeValues && priceRangeValues[1] == selectedChannel.filterMaxVal">+</span>
          </span>
        </div>
        <div class="price-slider">
          <p-slider [(ngModel)]="priceRangeValues" [range]="true" (onChange)="priceSlide(priceRangeValues)"
            class="price-range" [ngClass]="{'price-range-active': priceRangeActive}">
          </p-slider>
        </div>
      </div>

      <div class="content">
        <h1 class="heading-11">{{'input.label.16' | translate}}</h1>
        <div class="rating-content">
          <div *ngFor="let itm of rateList">
            <button class="rate-btn"  (click)="onClickRate(itm)" [ngClass]="{'selectedRate': selectedRateValue == itm} ">{{itm}}<i class="oda-star rating-icon"></i></button>
          </div>
        </div>
      </div>

      <div class="content">
        <h1 class="heading-11">{{catgAttrsDes_1}}</h1>
        <p-selectButton [options]="catgAttrsList_1" [(ngModel)]="catgAttrsValue_1" optionLabel="attrOptionDesc"
          (click)="onClickItem()" [multiple]="true">
        </p-selectButton>
      </div>

      <button type="button" class="apply-btn" (click)="onClickFilterBtn()">
        {{'button.apply' | translate}} <span *ngIf="totalCountItem > 0">({{totalCountItem}})</span>
      </button>
    </div>
  </div>
</div>


<!-- filter dialog -->
<div class="filter-dialog hidden-lg" *ngIf="displayFilterDropdown">
  <p-dialog [(visible)]="displayFilterDropdown" [blockScroll]="blockScroll">
    <div class="filter-dialog-container">
      <div class="header">
        <i class="oda-chevron-left" (click)="onClickCloseFilterDropdown()"></i>
        <h1 class="heading-14">{{'filter.page.title' | translate}}</h1>
        <button type="button" (click)="clearAllFilter()"><i class="duotone oda-d-trash"></i></button>
      </div>

      <div class="filter-dialog-content">
        <div class="content" style="margin-bottom: -5px;">
          <h1 class="heading-11">{{catgAttrsDes_2}}</h1>
          <p-selectButton [options]="catgAttrsList_2" [(ngModel)]="catgAttrsValue_2" optionLabel="attrOptionDesc"
            (click)="onClickItem()" [multiple]="true">
          </p-selectButton>
        </div>

        <div class="content">
          <h1 class="heading-11">{{'input.label.15' | translate}}</h1>
          <div class="heading-12 price-tag" [ngClass]="{'heading-13': !priceRangeActive}">
            <span>{{selectedChannel.currency.currPrefix}} {{priceRangeValues[0]}}</span>
            <span>{{selectedChannel.currency.currPrefix}} {{priceRangeValues[1]}}<span
                *ngIf="priceRangeValues && priceRangeValues[1] == selectedChannel.filterMaxVal">+</span>
            </span>
          </div>
          <div class="price-slider">
            <p-slider [(ngModel)]="priceRangeValues" [range]="true" (onChange)="priceSlide(priceRangeValues)"
              class="price-range" [ngClass]="{'price-range-active': priceRangeActive}">
            </p-slider>
          </div>
        </div>

        <div class="content">
          <h1 class="heading-11">{{'input.label.16' | translate}}</h1>
          <div class="rating-content">
            <div *ngFor="let itm of rateList">
              <button class="rate-btn"  (click)="onClickRate(itm)" [ngClass]="{'selectedRate': selectedRateValue == itm} ">{{itm}}<i class="oda-star rating-icon"></i></button>
            </div>
          </div>
        </div>

        <div class="content">
          <h1 class="heading-11">{{catgAttrsDes_1}}</h1>
          <p-selectButton [options]="catgAttrsList_1" [(ngModel)]="catgAttrsValue_1" optionLabel="attrOptionDesc"
            (click)="onClickItem()" [multiple]="true">
          </p-selectButton>
        </div>
      </div>

      <div class="filter-dialog-bottom">
        <button type="button" class="apply-btn" (click)="onClickFilterBtn()">
          {{'button.apply' | translate}} <span *ngIf="totalCountItem > 0">({{totalCountItem}})</span>
        </button>
      </div>
    </div>
  </p-dialog>
</div>
