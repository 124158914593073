<div class="cart-bg cart-form" [ngClass]="{'cart-form-when-store-page' : isStorePage == true}">
  <!--store name and order-more button-->
  <div class="store-info-container" [ngClass]="{'heading-when-nothing' : ((cartModel == null || !orderH) && isStorePage),
    'store-info-container-when-store-page': isStorePage == true, 'store-info-container-no-select': orderH?.isSelect == false && !isStorePage}">
    <div class="p-d-flex p-ai-center">
      <!-- checkbox for selecting order  -->
      <div class="check-box-container" *ngIf="!isStorePage && orderH.orderData.itemQty != 0 && !qrTokenResponse">
        <span class="unchecked-box" (click)="onSelectStore()" [style.border]="orderH?.isSelect ? 'none' : '1px solid #B1B1B1'"
        [style.background-color]="orderH?.isSelect ? 'var(--iconTextButton-primary)' : '#FFFFFF'"
        [ngClass]="{'set-cursor-pointer': cartModel.orderHs.length > 1 && !qrTokenResponse, 'set-cursor-not-allowed':  cartModel.orderHs.length == 1 && !qrTokenResponse,
        'set-cursor-auto': qrTokenResponse}" *ngIf="!isStorePage && orderH.orderData.itemQty != 0 && !qrTokenResponse">
          <i class="oda-check" *ngIf="!isStorePage && orderH?.isSelect"></i>
        </span>
      </div>

      <!--display store name-->
      <h1 class="pymtCard-heading-4" [ngClass]="{'set-cursor-pointer': cartModel.orderHs.length > 1 && !qrTokenResponse,
        'set-cursor-not-allowed':  cartModel.orderHs.length == 1 && !qrTokenResponse, 'set-cursor-auto': qrTokenResponse}" (click)="onSelectStore()"
         [style.color]="orderH?.isSelect ? 'var(--text-secondary)' : 'var(--text-primary)'" *ngIf="!isStorePage && orderH?.storeName != null">{{orderH.storeName}}</h1>

      <!-- title when it is store page -->
      <span class="yrCart-heading" *ngIf="isStorePage">{{"cart.title" | translate}}</span>

      <!--delete button-->
      <div class="p-ml-auto" *ngIf="orderH != null && orderH?.orderData?.orderDs.length != 0">
        <div class="p-d-flex remove-all-item-container" (click)="onClickTrashCan()">
          <button type="button" class="btn-delete" >
            <i class="duotone oda-d-trash" [style.color]="orderH?.isSelect ? 'var(--iconTextButton-secondary)' : 'var(--iconTextButton-primary)'"></i>
          </button>

          <span class="item-quantity-indicator hidden-sm">{{"cart.item.quantity" | translate : {itemQty : orderH.orderData.orderDs.length } }}</span>
        </div>
      </div>
    </div>

    <div class="order-more-container pymtCard-heading-2" [style.margin-left]="!qrTokenResponse ? '1px' : '0px'"
    *ngIf="(!isStorePage && !queueResponse) || (!isStorePage && queueResponse && queueResponse.statusFlag != queueStatusFlag.Called)" (click)="onClickOrderMore()">
      <span class="order-more-button">
        <i class="oda-plus" [style.color]="orderH?.isSelect ? 'var(--primary-color)' : 'transparent'"></i></span>
      <h3 class="order-more-description" [style.color]="orderH?.isSelect ? 'var(--text-secondary)' : 'var(--text-primary)'">{{'order.payment.sub.header.8' | translate}}</h3>
    </div>
  </div>

  <div class="sub-content" [ngClass]="{'sub-content-when-store-page' : isStorePage == true, 'sub-content-when-not-selected': !orderH?.isSelect && !isStorePage, 'sub-content-when-empty': !orderH, 'sub-content-when-store-page-with-saveAmount': isStorePage && orderH && orderH.orderData.savingAmt}">
   <!--temporarily hide membership details-->
    <!--membership detail-->
    <div *ngIf="!isStorePage && storeResponse && (orderH.orderData.orderC.membershipId || orderH.orderData.orderC.membershipNo)" [hidden]="true">
      <div class="membershipid-container">
        <!--with card image-->
        <div class="card-image" *ngIf="orderH.orderData.orderC.cardImageData">
          <img [src]="orderH.orderData.orderC.cardImageData" (error)="onImgError()" alt="card image" loading="lazy"/>
        </div>
        <!--without card image-->
        <div *ngIf="orderH.orderData.orderC.logoImageData && !orderH.orderData.orderC.cardImageData || this.cardImageError == true" class="logo-image">
          <img [src]="orderH.orderData.orderC.logoImageData" onerror="this.src='assets/image-unavailable.svg'" alt="logo image" loading="lazy"/>
          <h1 class="desc-heading">{{orderH.orderData.orderC.membershipDesc}}</h1>
        </div>
        <h1>{{'membership.id.label' | translate}}: {{orderH.orderData.orderC.membershipNo}} </h1>
        <div class="level-desc" *ngIf="orderH.orderData.orderC.levelDesc">{{orderH.orderData.orderC.levelDesc}}</div>
      </div>
    </div>
    <!--address form-->
    <div class="address-container" *ngIf="!isStorePage">
      <div class="p-d-flex address-description" *ngIf="(orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery || orderH?.orderData?.sourceFlag == orderSourceFlag.AppDelivery ||
        ((orderH?.orderData?.sourceFlag == orderSourceFlag.WebPickup || orderH?.orderData?.sourceFlag == orderSourceFlag.AppPickup)) && !orderH?.orderData?.tableNo) && orderH.isSelect && !qrTokenResponse && !queueResponse">
        <div class="p-d-flex" *ngIf="orderH.orderData.sourceFlag != orderSourceFlag.WebDineIn">
          <i class="oda-pin"></i>

          <div class="address-title-container p-flex-column" [ngClass]="{'no-title': !orderH.orderData.orderC.addressDesc, 'got-title': orderH.orderData.orderC.addressDesc}"
          *ngIf="orderH.orderData.sourceFlag == orderSourceFlag.WebDelivery">
            <h1 class="pymtCard-heading-1" *ngIf="orderH?.orderData?.orderC.addressDesc != null">{{orderH.orderData.orderC.addressDesc}}</h1>
            <h2 class="pymtCard-heading-3 address p-mb-0" *ngIf="orderH?.orderData?.orderC.address" [ngClass]="{'address-when-no-desc' : orderH?.orderData?.orderC.addressDesc == null}">
              {{orderH.orderData.orderC.address}}
            </h2>
          </div>

          <div class="store-title-container p-flex-column" [ngClass]="{'no-title': !storeResponse && storeResponse?.locDesc, 'got-title': storeResponse && storeResponse?.locDesc}"
          *ngIf="orderH.orderData.sourceFlag == orderSourceFlag.WebPickup">
            <h1 class="pymtCard-heading-1" *ngIf="storeResponse">{{storeResponse.locDesc}}</h1>

            <div class="p-d-flex">
              <h2 class="pymtCard-heading-3" style="margin-bottom: 0;" *ngIf="storeResponse">
                {{storeResponse.fullAddress}}
              </h2>
            </div>

            <div class="view-map-btn-container">
              <span class="view-map-btn" (click)="onClickViewMap()">{{"button.view.map" | translate}}</span>
            </div>

            <!-- phone no info -->
            <div class="phone-no-container" *ngIf="storeResponse && orderH?.orderData?.sourceFlag == orderSourceFlag.WebPickup && orderH.isSelect && !qrTokenResponse && !queueResponse">
              <span>{{storeResponse.phoneNo}}</span>
            </div>
          </div>

          <div class="p-ml-auto" *ngIf="orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery || orderH?.orderData?.sourceFlag == orderSourceFlag.AppDelivery">
            <i class="duotone oda-d-edit" (click)="onClickEditAddress()"></i>
          </div>
        </div>
      </div>

       <!--floor/unit-->
      <span class="p-float-label" *ngIf="orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery &&
        orderH.isSelect &&
        !qrTokenResponse &&
        !queueResponse">
        <input class="floor-input" type="text" pInputText placeholder="{{ 'order.summary.title.1' | translate }}" style="padding-left: 20px;"
        [(ngModel)]="orderH.orderData.orderC.addUnit" (click)="onClickInputBox(orderH.orderData.orderC.addUnit, true)" (focusout)="updateUnitNo($event)"/>
      </span>

      <!--remark for drive-->
      <span class="p-float-label" *ngIf="
        orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery &&
        orderH.isSelect &&
        !qrTokenResponse &&
        !queueResponse">
        <!-- <h4 class="floor-remark-title">{{'order.summary.title.2' | translate}}</h4> -->
        <input class="remark-input" type="text" pInputText placeholder="{{ 'input.placeholder.13' | translate }}" style="padding-left: 20px;"
         [(ngModel)]="orderH.orderData.orderC.remarks" (click)="onClickInputBox(orderH.orderData.orderC.remarks)" (focusout)="updateRemarkValue($event)"/>
      </span>

      <hr *ngIf="(orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery || orderH?.orderData?.sourceFlag == orderSourceFlag.AppDelivery ||
        (orderH?.orderData?.sourceFlag == orderSourceFlag.WebPickup || orderH?.orderData?.sourceFlag == orderSourceFlag.AppPickup)  && !orderH?.orderData?.tableNo) && orderH.isSelect && !qrTokenResponse && !queueResponse">

      <!--delivery status/QR dine in info/queue info -->
      <div class="p-d-flex time-schedule-container"
        *ngIf="orderH"
        (click)="onClickScheduleInfo()"
        [ngClass]="{'cursor-default': queueResponse, 'unclickable': !orderH.isSelect}">

        <app-order-info
          [orderH]="orderH"
          [storeResponse]="storeResponse"
          [qrTokenResponse]="qrTokenResponse && !queueResponse? qrTokenResponse: null"
          [queueResponse]="queueResponse">
        </app-order-info>

        <i class="oda-chevron-right" *ngIf="(((orderH?.orderData?.sourceFlag == orderSourceFlag.WebDelivery || orderH?.orderData?.sourceFlag == orderSourceFlag.AppDelivery ||
        orderH?.orderData?.sourceFlag == orderSourceFlag.WebPickup || orderH?.orderData?.sourceFlag == orderSourceFlag.AppPickup ||
        orderH?.orderData?.sourceFlag == orderSourceFlag.WebDineIn || orderH?.orderData?.sourceFlag == orderSourceFlag.AppDineIn) && orderH.isSelect)
        || qrTokenResponse) && !queueResponse && !appLinkTokenResponse"></i>
      </div>

      <app-dashed-line *ngIf="queueResponse"></app-dashed-line>

      <div class="queue-preconfirm-message" *ngIf="queueResponse &&
        (queueResponse?.statusFlag == queueStatusFlag.Active || queueResponse?.statusFlag == queueStatusFlag.Called)">
        <i class="oda-alert"></i>
        <span class="pre-confirm-message" *ngIf="queueResponse.statusFlag == queueStatusFlag.Active">{{"queue.cart.desc.3" | translate}}</span>
        <span class="pre-confirm-message" *ngIf="queueResponse.statusFlag == queueStatusFlag.Called">{{"queue.cart.desc.2" | translate}}</span>
      </div>
    </div>

    <!-- for store page when the right hand side cart is empty -->
    <div class="empty-cart-message" *ngIf="orderH == null || orderH?.orderData?.orderDs.length == 0 && isStorePage">
      <div class="mascot-container" *ngIf="channelId | isShowMascot">
        <img class="empty-cart-mascot" src="assets/mascot/Mascot_cart-empty-Grey@2x.webp" alt="odaring mascot">
      </div>
      <div class="empty-cart-description">
        {{"cart.store.empty.message" | translate}}
      </div>
    </div>

    <div class="item-cart-outer-container" *ngIf="orderH != null && orderH?.orderData?.orderDs.length != 0">
      <div class="order-list-title" *ngIf="!isStorePage">
        <h2>{{'order.summary.title.4' | translate}}</h2>
      </div>

      <section class="item-cart-container" *ngFor="let item of orderH.orderData.orderDs; let i = index" [ngClass]="{'item-cart-container-store-page': isStorePage == true}">

        <!--order item list-->
        <div class="order-item-outer-container"
        [style.cursor]="
        (item.voidFlag == voidFlag.Unavailable) ||
        (queueResponse && queueResponse?.statusFlag == queueStatusFlag.Called) ? 'default' : 'cursor'"
        (click)="onClickEditItem(item)">
          <app-order-item
            [item]="item"
            [currCode]="orderH.orderData.currCode"
            [isOrderSummaryPage]="(queueResponse && queueResponse?.statusFlag == queueStatusFlag.Called) ? true : false">
          </app-order-item>

          <div class="cartForm-itemBalance-orderQty-container">
             <!--item quantity availability-->
           <div class="itemQty-container" *ngIf="(item.itemBalanceQty || item.itemBalanceQty == 0) && item.voidFlag == voidFlag.Available" [ngStyle]="{ 'background-color':  item.balanceQty < 0 ? '#EB3600': '#000000' }">
            {{ 'item.quantity.availability' | translate:{ qtyBalance: item.itemBalanceQty > 99 ? '99+' : (item.itemBalanceQty) } }}
           </div>

          <!--quantity: minus/ plus button-->
          <div class="quantity-button-container" *ngIf="item.orderQty >= 1 &&
            (!queueResponse || (queueResponse && queueResponse.statusFlag == queueStatusFlag.Active))"
            [style.opacity]="item.voidFlag == voidFlag.Unavailable ? '0.3' : '1'">
            <button typeof="button" class="qauntityMinus_btn" [style.cursor]="item.voidFlag == voidFlag.Unavailable ? 'default' : 'cursor'"
            (click)="minus_quantity(item, i); $event.stopPropagation()"><i class="oda-minus"></i></button>
            <span class="qauntity_input">{{item.orderQty}}</span>
            <button typeof="button" class="qauntityPlus_btn" [style.cursor]="item.voidFlag == voidFlag.Unavailable ? 'default' : 'cursor'"
             (click)="plus_quantity(item, i); $event.stopPropagation()"
             [ngClass]="{'qauntityPlus_btn_disable': (maxQtyPerTran[item.itemCode] == totalOrderInCartPerItem[item.itemCode]) || ((maxQtyPerTran[item.itemCode] && !item.balanceQty) || (maxQtyPerTran[item.itemCode] && item.balanceQty && maxQtyPerTran[item.itemCode] < item.balanceQty)) && maxQtyPerTran[item.itemCode] && maxQtyPerTran[item.itemCode] - totalOrderInCartPerItem[item.itemCode] == 0  }"
             [disabled]="item.balanceQty != null && item.balanceQty != undefined && item.balanceQty <= 0">
             <i class="oda-plus"></i></button>
          </div>

          <!-- cancel/remove button -->
          <div class="cancel-remove-button-container" *ngIf="item.orderQty == 0">
            <button class="cancel-button" (click)="cancelRemove(item); $event.stopPropagation()">{{"button.cancel" | translate}}</button>
            <button class="remove-button" (click)="removeSingleItem(item); $event.stopPropagation()">{{"button.remove" | translate}}</button>
          </div>
          </div>
        </div>

        <app-void-item-message *ngIf="item.voidFlag == voidFlag.Unavailable"></app-void-item-message>

        <hr class="border-between-item" *ngIf="i != orderH.orderData.orderDs.length - 1">

      </section>
    </div>

    <div *ngIf="orderH && orderH?.isSelect && this.currentCrossSellList.length > 0 && !isStorePage">
      <app-popoular-order-slider
        [crossSellItem]="currentCrossSellList"
        [currencyCode]="orderH.orderData.currCode"
        (clickItem)="onClickCrossSellItem($event)">
      </app-popoular-order-slider>
    </div>

    <div class="p-d-flex cutleryRequest-container" (click)="onClickCutlery()"
    *ngIf="orderH != null && orderH?.orderData?.orderDs.length != 0 && !isStorePage && orderH?.orderData.sourceFlag != orderSourceFlag.WebQrDineIn
    && orderH?.orderData?.sourceFlag != orderSourceFlag.WebDineIn" [ngClass]="{'unclickable': !orderH.isSelect}">
      <div class="p-d-flex p-ai-center" style="height:auto">
        <i class="color oda-c-utensils"><span class="path1"></span></i>
        <h1 class="pymtCard-heading-1">{{cutleryLabel | translate}} <span class="pymtCard-heading-5">{{ 'cart.cutlery.requested' | translate }}</span></h1>
      </div>
      <div class="p-ml-auto" style="height:auto">
        <p-inputSwitch [(ngModel)]="cutlery_request" (onChange)="onChangeCutlerySwitch($event)" [ngClass]="{'unclickable': !orderH.isSelect}"></p-inputSwitch>
      </div>
    </div>

    <div class="reward-container" *ngIf="orderH?.isSelect && !isStorePage && !queueResponse">
      <app-applied-promo-voucher [orderH]="orderH" [isStorePage]="isStorePage"
        (onApplyPromo)="onApplyPromo()" (OnRemovePromo)="onRemovePromo($event)"
        (onSelectVoucher)="onSelectVoucher()" (onRemoveVoucher)="onRemoveVoucher($event)"
        [isQrTokenResponse]="qrTokenResponse ? true : false"></app-applied-promo-voucher>
    </div>

    <div class="price-content" *ngIf="orderH != null && orderH?.orderData?.orderDs.length != 0">
      <app-order-amount [orderData]="orderH.orderData"></app-order-amount>
    </div>

    <div class="wave-pattern hidden-lg"></div>
  </div>

  <div *ngIf="displayMaxPerTranError" class="maxPerTranErrorContainer" [ngClass]="{'maxPerTranErrorContainercart' : !isStorePage}">
    <i class="oda-times"></i>
    <h1>{{'max.per.tran.error.text' | translate: { maxQty: displaymaxQtyPerTranText} }}</h1>
  </div>

  <div class="footer-action-container" *ngIf="isStorePage">
    <div class="discount-container" *ngIf="orderH && orderH.orderData.savingAmt">
      <img src="assets/mascot/Mascot_no-voucher@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
      <p [innerHtml]="'rewards.saved.desc.1' | translate: { savedPrice: orderH.orderData.savingAmt | currency: orderH?.orderData?.currCode : 'symbol-narrow' }"></p>
    </div>

    <button class="action-button"
    [disabled]="storeResponse && (storeResponse.currentStatus != statusFlag.Open) || ( storeResponse.currentStatus == statusFlag.Open && orderH && orderH?.orderData.itemQty == 0)"
    [ngClass]="{'action-button-enable': orderH != null && orderH?.orderData?.orderDs.length != 0 && orderH?.orderData.itemQty > 0 && storeStatus == statusFlag.Open}"
    (click)="onClickOrderNow()">
      {{"merchant.store.view.cart.desc" | translate}}
    </button>
  </div>
</div>

<!-- dialog for confirmation of whole item removal -->
<div class="remove-dialog-container">
  <p-dialog [(visible)]="showRemoveDialog" [position]="dialogPosition" [dismissableMask]="dismissable" [style]="{width:'400px'}" [baseZIndex]="10000" [modal]="true" [closable]='true'>
    <app-remove-all-message
      [isStorePage]="isStorePage"
      [isOrderType]="isOrderType"
      [qrTokenResponse]="qrTokenResponse"
      [queueResponse]="queueResponse"
      (removeAll)="removeAllItem()"
      (closeDialog)="closeRemoveAllDialog()"
      (cancelRemove)="onClickNo()"
      (orderTypeRemove)="orderTypeRemoveCart()"
      (orderTypeRemain)="closeRemoveAllDialog()"
      *ngIf="showRemoveDialog">
    </app-remove-all-message>
  </p-dialog>
</div>

<div class="time-schedule-dialog">
  <p-dialog header=" " [(visible)]="showOrderTimeDialog" [style]="{width: '685px', height: '700px'}" [baseZIndex]="10000" [modal]="true" [closable]='false'>
    <app-store-odr-time
      [availableTimeSlot]="filteredTime"
      [todayTime]="currentOrderTime"
      [currentOrderDateSlot]="curOrderDateSlot"
      [storeResponse]="storeResponse"
      [orderH]="orderH"
      [isCartPage]="true"
      [isMobileView]="isMobileView"
      (onCloseDialog)="closeOrderTimeDialog($event)"
      (checkoutCurrentOrderTime)="checkoutOfTime($event)"
      *ngIf="showOrderTimeDialog">
    </app-store-odr-time>
  </p-dialog>
</div>

<div class="cart-address-dialog">
  <p-dialog *ngIf="displayAddress" header=" " [(visible)]="displayAddress" [position]="dialogPosition"
  [style]="{width: '650px', height: 'auto'}" [modal]="true" [dismissableMask]="true" [closable]="true"
  [blockScroll]="true">
    <div class="col-12 hidden-sm" style="text-align: right; padding: 0px;" (click)="closeDialog()">
      <button class="dialog-close-btn">
        <span><i class="oda-times" style="font-size: 15px; color: lightgray;"></i></span>
      </button>
    </div>
    <app-address *ngIf="displayAddress" [savedAddress]="savedAddress" [updateAddressSuccess]="updateAddressSuccess" [isCartPage]="true"
    [currentAddress]="orderH.orderData.orderC.address" [cartGuid]="orderH.cartGUID" (recalculateCart)="onChangeAddress($event)"
    (onQuitAddress)="this.displayAddress= false">
    </app-address>
  </p-dialog>
</div>

<div class="cart-dialog">
  <p-dialog header=" " [(visible)]="displayStoreItm" position="center" [style]="{'width': '700px'}" [baseZIndex]="10000" [modal]="true" [closable]='false' *ngIf="displayStoreItm">
    <app-store-item-modifier
      class="cart-modifier-tag"
      [storeId]="curStoreId"
      [qrTokenResponse]="qrTokenResponse"
      [priceSymbol]="priceSymbol"
      [orderLineGuid]="orderLineGuid"
      [variationList]="variationList"
      [changeImageSetting]="changeImageSetting"
      [parentMenuItem]="parentMenuItem"
      [queueResponse]="queueResponse"
      [staticQrData]="staticData"
      [membershipResponse]="merchantMemberships"
      [appLinkTokenResponse]="appLinkTokenResponse"
      (onCloseDialog)="onCloseEditDialog($event)"
      (displayMaximumMessage)="onReachedMaximumQuantity()"
      (removeSelectedItem)="removeSelectedItem($event)"
      (displayMobileRemoveDialog)="displayMobileRemoveItemDialog($event)"
      [isEditSelectedOrder]="true">
    </app-store-item-modifier>
  </p-dialog>

  <div class="submod-dialog">
    <p-dialog header=" " [(visible)]="isSubModifier" [position]="dialogPosition" [style]="{width: '700px'}" [baseZIndex]="11000" [modal]="true" [closable]='false' *ngIf="isSubModifier">
      <app-store-item-submodifier
        class="cart-submod-tag"
        [storeId]="curStoreId"
        [currencyCode]="orderH?.orderData?.currCode"
        [isSubModifier]="isSubModifier"
        (displayMaximumMessage)="onReachedMaximumQuantity()"
        *ngIf="isSubModifier">
      </app-store-item-submodifier>
    </p-dialog>
  </div>
</div>

<div class="out-of-coverage-dialog">
  <p-dialog header=" " [(visible)]="displayOutOfCoverage" [position]="dialogPosition" [dismissableMask]="dismissable" [style]="{width: '430px', height: '300px'}"
  [baseZIndex]="10000" [modal]="true" [closable]='true'>
  <app-out-of-service-message [storeResponse]="storeResponse" [isCartPage]="true" [channelId]="channelId" (closeDialog)="closeOutOfCoverageDialog()" *ngIf="displayOutOfCoverage"></app-out-of-service-message>
  </p-dialog>
</div>

<app-qr-dinein-selection
  [orderH]="orderH"
  [qrTokenResponse]="qrTokenResponse"
  [storeResponse]="storeResponse"
  [dismissable]="dismissable"
  [dialogPosition]="dialogPosition"
  [isStorePage]="false"
  [staticQrData]="staticData"
  [channelId]="channelId"
  (closeDialog)="closeDineInInfoDialog()"
  (continueDineInSelection)="confirmDineInOrderType($event)"
  (staticContinue)="checkoutOfTime($event)"
  *ngIf="displayDineInInfo">
</app-qr-dinein-selection>

<div class="mobile-remove-dialog">
  <p-dialog [(visible)]="displayMobileRemoveDialog" position="bottom" [dismissableMask]="true"
   [baseZIndex]="10000" [modal]="true" [closable]='true'>
    <app-remove-selected-item-message [orderLineGuid]="currentOrderLineGuid" (closeDialog)="closeMobileRemoveDialog()"
    (removeItem)="removeSelectedItem($event)" *ngIf="displayMobileRemoveDialog"></app-remove-selected-item-message>
  </p-dialog>
</div>




