export enum ActivityTypeButton {
    cancel = 'cancel',
    cancelled = 'cancelled',
    refund = 'refund',
    refunded = 'refunded',
    pay = 'pay',
    reorder = 'reorder',
    makePayment = 'makePayment',
    accept = 'accept',
    rate = 'rate',
    viewIssue = 'viewIssue',
    none = 'none',
    close = "close"
}
