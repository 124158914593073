import { environment } from './../../../../environments/environment.prod';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { RoutingService } from './../../../home/services/routing.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-channel-not-found',
  templateUrl: './channel-not-found.component.html',
  styleUrls: ['./channel-not-found.component.scss']
})
export class ChannelNotFoundComponent implements OnInit {

  showChannelErrorPage: boolean = false;

  constructor(
    private routingService: RoutingService,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.showChannelErrorPage = true;
    }
  }

  navigateHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }
}
