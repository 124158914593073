import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-queue-remove-cart',
  templateUrl: './queue-remove-cart.component.html',
  styleUrls: ['./queue-remove-cart.component.scss']
})
export class QueueRemoveCartComponent implements OnInit {

  @Output() cancelRemove = new EventEmitter();
  @Output() removeItems = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClickCancel(){
    this.cancelRemove.emit();
  }

  onClickRemoveItems(){
    this.removeItems.emit();
  }

}
