import { QueueDataStore } from './queue-data.store';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { QueueData } from '../models/local/QueueData';

@Injectable({ providedIn: 'root' })
export class QueueDataQuery extends Query<QueueData> {

  constructor(protected store: QueueDataStore) {
    super(store);
  }
}
