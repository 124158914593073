import { Component, ViewEncapsulation } from "@angular/core";
import { Router } from '@angular/router';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
SwiperCore.use([Autoplay, Pagination, Navigation]);


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent {

  imageSlider: any[] = [
    {
      image: 'assets/mascot/Mascot_food@2x.webp',
      imageDesc: 'splash.screen.description1',
      imageAlt: 'mascot food'
    },
    {
      image: 'assets/mascot/Mascot_reward@2x.webp',
      imageDesc: 'splash.screen.description2',
      imageAlt: 'mascot reward'
    },
    {
      image: 'assets/mascot/Mascot_doorstep@2x.webp',
      imageDesc: 'splash.screen.description3',
      imageAlt: 'mascot doorstep'
    }
  ];


  constructor(
    private router: Router,
  ) { }

  ngOnInit() { }

  navigateToLanding(){
    localStorage.removeItem('previousRoute');
    this.router.navigate(['/']);
  }
}
