import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StoreMode } from '../enums';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  isLoggedIn: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {
    this.userService.get(StoreMode.Internal).subscribe((dt: any) => {
      this.isLoggedIn = dt ? true : false;
    })
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.isLoggedIn) {
      this.router.navigateByUrl('/login', { state: { isFromLanding: true, showContGuest: true } });
      return false;
    }

    return true;
  }

}
