import { DefaultService } from './../../home/services/default.service';
import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { StaticQrService } from '../static-qr/static-qr.service';
import { UtilsService } from '../services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class HomeResolver implements Resolve<boolean> {
  constructor(
    private defaultService : DefaultService,
    private staticQrService : StaticQrService,
    private utilsService : UtilsService
  ){}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //check if route query have order type or not
    //if have then assign as channel order type
    const query = this.utilsService.toLowerQueryChar(route.queryParams);
    if(query && query?.ordertype){
      this.staticQrService.saveChannelOrderType(query.ordertype);
    }

    // if channelsec state data empty then recall api
    if (this.defaultService.isChannelSecDataEmpty()) {
      await this.defaultService.recallChannelSecData();
    }

    // whenever reached home will remove cache static order type only data
    // as user manually go to home and we assume they will continue using web
    this.staticQrService.removeOrderTypeOnlyData();

    await this.staticQrService.staticQrProcess(route);

    return null;
  }
}
