<div class="my-fav-container" *ngIf="!isLoading">
  <div class="content">
    <div class="sub-content">
      <!--desktop header-->
      <div class="fav-header hidden-sm" *ngIf="favouriteStoreList.length > 0">
        <i class="oda-heart hidden-sm"></i>
        {{'navigation.Menu.des.6' | translate}}
      </div>
      <!--mobile header-->
      <div class="fav-header hidden-lg">
        <app-back-button class="back-btn hidden-lg"></app-back-button>
        {{'navigation.Menu.des.6' | translate}}
      </div>

      <div class="fav-content" *ngIf="favouriteStoreList.length > 0; else elseBlock">
        <app-item-card-v
          [stores]="favouriteStoreList"
          [currentCart]="cartModel">
        </app-item-card-v>
      </div>

      <ng-template #elseBlock>
        <div class="empty-fav">
          <div class="sub-content">
            <img src="assets/mascot/Mascot_cart-empty-Grey@2x.webp" alt="odaring mascot" *ngIf="channelTag | isShowMascot"/>
            <h1>{{'favourite.nofavourite.des' | translate}}</h1>
            <button routerLink="/home">{{'merchant.browse.food.button' | translate}}</button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
