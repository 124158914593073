<div class="content-container">
  <div class="mascot-container" *ngIf="channelId | isShowMascot">
    <img src="assets/mascot/Mascot_quene-number@2x.webp" alt="odaring mascot">
  </div>

  <h3 class="notification-title heading-11">{{"back.to.odaring.desc.1" | translate}}</h3>
  <p class="notification-desc heading-9">{{"back.to.odaring.desc.2" | translate}}</p>

  <div class="button-container">
    <button class="button-cancel heading-11" (click)="onClickCancel()">{{"button.cancel"| translate}}</button>
    <button class="button-back-home heading-11" (click)="onClickBackToOdaring()">{{"button.back.to.odaring.desc.1" | translate}}</button>
  </div>
</div>
