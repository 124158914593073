import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { QueueService } from 'src/app/queue/queue/queue.service';
import { RouteName } from '../enums/RouteName';

@Injectable({
  providedIn: 'root'
})
export class QueueDataCheckGuard implements CanActivate {

  routes : string;

  constructor(
    private queueService : QueueService,
    private router : Router,
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.routes = this.router.url;
      let splittedRoutes = this.routes.split('/');
      let currentRoute = splittedRoutes[1];

      if(this.queueService.getQueueData() && (currentRoute == RouteName.Cart || currentRoute == RouteName.Store || this.queueService.getIsQueueInfoPage())){
        this.queueService.showQuitQueuePopup();
        this.queueService.setIsQueueInfoPage(false);
        return false;
      }
      else{
        this.queueService.removeShowBackIcon();
        this.queueService.removeQueueData();
        this.queueService.removeActivityRsvToken();
        this.queueService.removeManualBack();
        return true;
      }
  }

}
