import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageName } from 'src/app/core/enums';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { RoutingService } from '../../services/routing.service';
import { ProfileService } from '../../services/profile.service';
import { UserService } from 'src/app/core/user/user.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';
import { UtilsService} from 'src/app/core/services/utils.service';

@Component({
  selector: 'app-reset-mobile-form',
  templateUrl: './reset-mobile-form.component.html',
  styleUrls: ['./reset-mobile-form.component.scss']
})
export class ResetMobileFormComponent implements OnInit {
  @Output() onResetMobile  = new EventEmitter();

  telInpSetting = DefaultSettingService.telInpSetting;

  fgroup: UntypedFormGroup = this.fb.group({
    mobileNo: ['', Validators.required],
  });

  submitted: boolean = false;
  mobileNoRequired: boolean = false;
  mobileNoExist: boolean = false;
  errorCode: any = ErrorCode;
  isInvalid: boolean = false;

  constructor(private fb: UntypedFormBuilder,
    private profileService: ProfileService,
    private userService: UserService,
    private routingService: RoutingService,
    private utilService : UtilsService,
  ) { }

    async ngOnInit() {
    this.getFormControl('mobileNo').valueChanges.subscribe((dt) => {
      this.isInvalid = false;
      this.mobileNoExist = false;
      this.mobileNoRequired = false;
    })
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }

  onMobileNoKeydown(event: any) {
    if (event.key === "Enter") {
      this.onSubmit();
    }
  }

  async onSubmit() {
    let phoneNo = this.getFormControl('mobileNo').value;
    if(phoneNo) {
      const mobile = phoneNo && phoneNo.e164Number;
      let isInvalid  = await this.utilService.phoneNoValidation(mobile);
      if(!isInvalid) {
        this.isInvalid = false;
        this.continueReset();
      }else {
        this.isInvalid = true;
      }
    } else {
      this.mobileNoRequired = true;
    }
  }

  async continueReset(){
    // Check if mobile no. exist
    let formattedMobileNo: string = "";
    let formattedDialCode: string = "";

    if(this.f.mobileNo.value.e164Number && this.f.mobileNo.value.dialCode) {
      formattedMobileNo = this.f.mobileNo.value.e164Number? this.f.mobileNo.value.e164Number.replace(this.f.mobileNo.value.dialCode, ''): '';
      formattedDialCode = this.f.mobileNo.value.dialCode? this.f.mobileNo.value.dialCode.replace('+', ''): '';
    }

    let resp: any = await this.userService.getCustomerProfileStatus(formattedDialCode + formattedMobileNo, "");

    if (!(resp instanceof HttpErrorResponse)) {
      this.mobileNoExist = resp.mobileNumber? true: false;
    } else {
      let sendOtpResult: any = await this.profileService.onSendOTP(this.f.mobileNo.value);

      if (!(sendOtpResult instanceof HttpErrorResponse)) {
        this.routingService.navigate(PageName.OTPPage, {pageName: PageName.ResetMobilePage});
      }
    }
  }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }
}
