<div class="news-container">
  <div class="title-container" *ngIf="channelSecData?.data?.sectionDesc">
    <i class="color oda-c-news hidden-sm icon"></i>
    <i class="back-btn oda-chevron-left hidden-lg" routerLink="/home"></i>
    <h1>{{ channelSecData?.data?.sectionDesc }}</h1>
  </div>

  <div class="list-container" *ngIf="news">
    <div class="newsList-content" *ngFor="let newData of news">
        <app-news-list [newsData]="newData"></app-news-list>
    </div>
  </div>

  <h1 *ngIf="news" class="news-end-scroll">{{'home.end.scroll.desc' | translate}}</h1>
</div>
