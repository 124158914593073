import { StoreItemStatsModel } from './store-item-stats.model';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StoreItemStatsState, StoreItemStatsStore } from './store-item-stats.store';

@Injectable({
  providedIn: 'root'
})
export class StoreItemStatsQuery extends QueryEntity<StoreItemStatsState, StoreItemStatsModel> {
  constructor(
    protected storeItemStatsStore : StoreItemStatsStore
  ) {
    super(storeItemStatsStore);
  }
}
