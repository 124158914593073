import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/core/services/utils.service';
import { ErrorObj } from 'src/app/core/models';
import { PageName } from 'src/app/core/enums';
import { User } from 'src/app/core/user/user.model';
import { UserService } from 'src/app/core/user/user.service';
import { ErrorCode } from 'src/app/core/enums/ErrorCode';

@Component({
  selector: 'app-reset-pass-form',
  templateUrl: './reset-pass-form.component.html',
  styleUrls: ['./reset-pass-form.component.scss']
})
export class ResetPassFormComponent implements OnInit{
  @Output() onSubmitted  = new EventEmitter();
  @Input() pageName: PageName;
  @Input() errorCode: ErrorCode;

  user: User;
  pageNames: any = PageName;
  errorObj: ErrorObj = null as any;
  errorCodes: any = ErrorCode;

  activeIndex: number = 2;
  isLoggedIn: boolean;
  showCurrPw: boolean = true;
  showNewPw: boolean = true;
  showConfirmPw: boolean = true;
  submitted: boolean = false;

  fgroup: UntypedFormGroup = this.fb.group({
    currPassword: [''],
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required]],
  }, { validators: MustMatch('password', 'confirmPassword')});

  constructor(private fb: UntypedFormBuilder,
    private userService: UserService) { }

  ngOnInit(): void {
    this.user = this.userService.getCustomer();
    this.isLoggedIn = this.user ? true : false;

    this.valueChanges("currPassword").subscribe((dt) => {
      this.submitted = false;
    });

    this.valueChanges("password").subscribe((dt) => {
      this.submitted = false;
    });

    this.valueChanges("confirmPassword").subscribe((dt) => {
      this.submitted = false;
    });

    this.createFormValidation();
  }

  onSubmit() {
    this.submitted = true;

    if (this.fg.invalid) {
      return;
    }

    let dt = null;

    if (this.pageName === PageName.ForgotPasswordPage) {
      dt = this.getFormControl("password").value;
    } else if (this.pageName === PageName.ResetPasswordPage) {
      dt = {
        currPassword: this.getFormControl("currPassword").value,
        newPassword: this.getFormControl("password").value
      }
    }

    this.onSubmitted.emit(dt);
  }

  get fg() { return this.fgroup; }

  get f() { return this.fg.controls; }

  private getFormControl(formControlName: string) {
    return this.fgroup.controls[formControlName];
  }

  private createFormValidation() {
    if (this.pageName === PageName.ForgotPasswordPage) {
      this.getFormControl('currPassword').setValidators([]);
      this.getFormControl('currPassword').disable();
    } else if (this.pageName === PageName.ResetPasswordPage) {
      this.getFormControl('currPassword').setValidators([Validators.required]);
      this.getFormControl('currPassword').enable();
    }
  }

  private valueChanges(formControlName: string) {
    return this.getFormControl(formControlName).valueChanges;
  }

}
