import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { QrCart } from './qr-cart.model';
import { QrCartState, QrCartStore } from './qr-cart.store';

@Injectable({
  providedIn: 'root'
})
export class QrCartQuery extends QueryEntity<QrCartState, QrCart> {

  qrWebLinkResponse$ = this.select(state => state.qrTokenResponse);

  constructor(protected qrCart: QrCartStore) {
    super(qrCart);
  }

}
