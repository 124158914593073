import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AccountsRoutingModule } from './account-routing.module';
import { LoginComponent } from './login/login.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AccountComponent } from './account.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { RoutingService } from './services/routing.service';
import { ResetMobileComponent } from './reset-mobile/reset-mobile.component';
import { ResetMobileFormComponent } from './reset-mobile/reset-mobile-form/reset-mobile-form.component';
import { ManageAddressComponent } from './manage-address/manage-address.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { RewardsComponent } from './rewards/rewards.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardDetailComponent } from 'src/app/account/card-Detail/card-detail.component';
import { OrderFormComponent } from './my-order/order-form/order-form.component';
import { EmptyOrderAlertComponent } from './my-order/alert/empty-order-alert/empty-order-alert.component';
import { RateFormComponent } from './my-order/rate-form/rate-form.component';
import { AddressComponent } from './address/address.component';
import { NewAddressComponent } from './address/new-address/new-address.component';
import { EditAddressComponent } from './address/edit-address/edit-address.component';
import { LoadingComponent } from './loading/loading.component';
import { ResetPasswordResultComponent } from './reset-password-result/reset-password-result.component';
import { RewardCardComponent } from './rewards/reward-card/reward-card.component';
import { RewardDetailComponent } from './rewards/reward-detail/reward-detail.component';
import { RewardItemComponent } from './rewards/reward-item/reward-item.component';
import { RewardSliderComponent } from './rewards/reward-slider/reward-slider.component';
import { RewardPromotionComponent } from './rewards/reward-promotion/reward-promotion.component';
import { RewardPromotionDetailComponent } from './rewards/reward-promotion-detail/reward-promotion-detail.component';
import { RewardHistoryComponent } from './rewards/reward-history/reward-history/reward-history.component';
import { FilterSelectRewardPipe } from './pipes/filter-select-reward.pipe';
import { SelectVoucherComponent } from './rewards/select-voucher/select-voucher.component';
import { SearchVoucherCodeComponent } from './rewards/search-voucher-code/search-voucher-code.component';
import { VerifyEmailExpiredComponent } from './verify-email-expired/verify-email-expired.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { VerifyEmailFailedComponent } from './verify-email-failed/verify-email-failed.component';
import { VerifyEmailSuccessComponent } from './verify-email-success/verify-email-success.component';
import { VerifyLinkSentComponent } from './verify-link-sent/verify-link-sent.component';
import { CompleteProfileComponent } from './complete-profile/complete-profile.component';
import { ResetMobileOtpComponent } from './reset-mobile/reset-mobile-otp/reset-mobile-otp/reset-mobile-otp.component';
import { SetupPasswordComponent } from './setup-password/setup-password.component';
import { PasswordLoginComponent } from './password-login/password-login/password-login.component';
import { SetupPasswordFormComponent } from './setup-password/setup-password-form/setup-password-form.component';
import { MyFavStoreComponent } from './my-fav-store/my-fav-store.component';
import { ProfileSetupPasswordComponent } from './setup-password/profile-setup-password/profile-setup-password.component';
import { ApplyPromoComponent } from './rewards/apply-promo/apply-promo.component';
import { MembershipModule } from 'src/app/membership/membership.module';
import { FullPagePromotionDetailComponent } from './rewards/full-page-promotion-detail/full-page-promotion-detail.component';

@NgModule({
  declarations: [
    LoginComponent,
    AccountComponent,
    EditProfileComponent,
    ResetPasswordComponent,
    LoginFormComponent,
    ResetMobileComponent,
    ResetMobileFormComponent,
    ManageAddressComponent,
    MyOrderComponent,
    RewardsComponent,
    CardDetailComponent,
    OrderFormComponent,
    RateFormComponent,
    EmptyOrderAlertComponent,
    AddressComponent,
    NewAddressComponent,
    EditAddressComponent,
    LoadingComponent,
    ResetPasswordResultComponent,
    RewardCardComponent,
    RewardDetailComponent,
    RewardItemComponent,
    RewardSliderComponent,
    RewardPromotionComponent,
    RewardPromotionDetailComponent,
    RewardHistoryComponent,
    FilterSelectRewardPipe,
    SelectVoucherComponent,
    SearchVoucherCodeComponent,
    VerifyEmailExpiredComponent,
    VerifyEmailComponent,
    VerifyEmailFailedComponent,
    VerifyEmailSuccessComponent,
    VerifyLinkSentComponent,
    CompleteProfileComponent,
    ResetMobileOtpComponent,
    MyFavStoreComponent,
    SetupPasswordComponent,
    SetupPasswordFormComponent,
    PasswordLoginComponent,
    ProfileSetupPasswordComponent,
    ApplyPromoComponent,
    FullPagePromotionDetailComponent
  ],
  imports: [
    CommonModule,
    AccountsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
    MembershipModule
  ],
  exports: [
    LoginComponent,
    AccountComponent,
    EditProfileComponent,
    ResetPasswordComponent,
    ManageAddressComponent,
    MyOrderComponent,
    RewardsComponent,
    CardDetailComponent,
    RateFormComponent,
    RewardSliderComponent,
    RewardDetailComponent,
    RewardPromotionComponent,
    RewardPromotionDetailComponent,
    SelectVoucherComponent,
    SearchVoucherCodeComponent,
    ApplyPromoComponent
  ],
  providers: [
    RoutingService
  ]
})
export class AccountModule { }
