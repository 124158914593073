<div class="stamp-gauge">
  <canvas id="circular-progress" #canvasEl class="membership-canvas" [hidden]="this.isMobileView"></canvas>
  <canvas id="circular-progress-mobile" #canvasEl2 class="membership-canvas-mobile" [hidden]="!this.isMobileView"></canvas>

  <div class="stamp-content" *ngIf="selectedStamp">
    <div class="stamp-info">
      <div class="stamp-icon"><i class="oda-membership-stamp"></i>{{'membership.stamps.unit.label' | translate}}</div>
      <div class="stamp">{{memberDetail.stampEarned}}/{{memberDetail.totalStamp}}</div>
      <!--reward image available-->
      <img class="selectedStampIcon-grey"
        [ngClass]="{'selectedStampIcon-color' : this.memberDetail.stampEarned == this.selectedStamp.stampId}"
        *ngIf="selectedStamp.stampIcon" src="{{selectedStamp.stampIcon}}"
        onerror="this.src='assets/mascot/Mascot_membership-reward-grey@2x.webp'" alt="membership stamp" loading="lazy"/>

      <!--reward image unavailable-->
      <img class="selectedStampIcon-grey"
        [ngClass]="{'selectedStampIcon-color' : this.memberDetail.stampEarned == this.memberDetail.nextRewardStampId}"
        *ngIf="!selectedStamp.stampIcon" src="assets/mascot/Mascot_membership-reward-grey@2x.webp" alt="membership stamp">

    </div>
  </div>

  <div class="gift-hint" *ngIf="selectedStamp">
    <div class="hint-text" *ngIf="this.selectedStamp.sChartId !== this.memberDetail.stampChart.colCount">
      {{'membership.collect.get.stamp.desc' | translate: {stamp_quantity: selectedStamp.stampId, stamp_desc:
      selectedStamp.stampDesc} }}
    </div>

    <div class="hint-text" *ngIf="this.selectedStamp.sChartId === this.memberDetail.stampChart.colCount">
      {{'membership.mission.accomplish.desc' | translate}}
    </div>
  </div>

</div>

<div class="pointer-container">
  <img *ngFor="let pointer of pointers; let i = index" class="gift-icon" (click)="selectStamp(i, pointer)"
    [ngStyle]="{'left': pointer?.left, 'right': pointer?.right, 'top': pointer?.top, 'bottom': pointer?.bottom}"
    [ngClass]="{'gift-selected' : pointer.selectedData.stampId === selectedStamp.stampId,
    'gift-hide': !pointer.showIcon,
    'center-gift': pointers | evenCenterGift : i,
    'cursor-pointer': pointer.showIcon || ((pointer.selectedData.stampId === this.memberDetail.stampEarned) && (pointer.selectedData.rewardType === membershipRewardType.voucher))}"
    [src]="pointer?.source" alt="membership stamp" loading="lazy">
</div>
