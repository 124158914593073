export enum PageName {
  LandingPage = 'LandingPage',
  LoginPage = 'LoginPage',
  ForgotPasswordPage = 'ForgotPasswordPage',
  ForgotSuccessPage = 'ForgotSuccessPage',
  ForgotFailedPage = 'ForgotFailedPage',
  ResetMobilePage = 'ResetMobilePage',
  VerifyMobilePage = 'VerifyMobilePage',
  ResetPasswordPage = 'ResetPasswordPage',
  SignupPage = 'SignupPage',
  LoadingPage = 'LoadingPage',
  OTPPage = 'OTPPage',
  HomePage = 'HomePage',
  ProfilePage = 'ProfilePage',
  CartPage = 'CartPage',
  CompleteOtpProfile = 'CompleteOtpProfile'
}
