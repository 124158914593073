import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimeoutError } from 'rxjs';
import { PreviousRoute } from 'src/app/core/models/PreviousRoute';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';
import { UserService } from 'src/app/core/user/user.service';
import { RoutingService } from 'src/app/home/services/routing.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email-expired',
  templateUrl: './verify-email-expired.component.html',
  styleUrls: ['./verify-email-expired.component.scss']
})
export class VerifyEmailExpiredComponent implements OnInit {
  displayResendFailed: boolean = false;

  constructor(
    private userService: UserService,
    private previousRouteService : PreviousRouteService,
    private router: Router,
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
  }

  async resendVerificationLink() {
    if (this.userService.curUser) {
      let emailVerify = await this.userService.sendEmailVerification();
      if (emailVerify instanceof HttpErrorResponse === false && emailVerify instanceof TimeoutError === false) {
        this.userService.sentVerifyEmail = true;
        this.router.navigate(["/verification-link-sent"], { state: { emailVerifyData: emailVerify } });
      } else {
        this.displayResendFailed = true;
      }
    } else {
      let previousRoute = {} as PreviousRoute;
      previousRoute.routeName = this.router.url;
      this.previousRouteService.savedPreviousRoute(previousRoute);
      this.router.navigate(['/login']);
    }
  }

  navigateBackToHome() {
    let specificChannel = localStorage.getItem('channelTag') ? localStorage.getItem('channelTag') : environment.odaringChannel;
    this.routingService.navigateHomeUrl(specificChannel);
  }
}
