<div class="select-voucher-container">
    <div class="menu-container">
        <div class="menu-wrapper">
            <div class="menu">
                <div class="btn-back hidden-lg">
                    <i class="oda-chevron-left" (click)="onBack();"></i>
                </div>
                <h1>{{ "order.summary.voucher.select" | translate }}</h1>
                <button type="button" class="btn-enter-code" (click)="onShowSearchCode()" *ngIf="showEnterVoucherCode">{{ "rewards.enter.code" | translate }}</button>
            </div>
        </div>
    </div>
    <div class="content-container" *ngIf="applicableVouchers && applicableVouchers.length > 0" (scroll)="onScroll($event)" #content>
            <div class="card-group-container show-less" id="card-group-{{ applicableVoucher.vchCatgId }}" *ngFor="let applicableVoucher of applicableVouchers">
                <div class="card-group" *ngIf="applicableVoucher.voucherList && applicableVoucher.voucherList.length > 0" >
                    <div class="title-container">
                        <h2>{{ applicableVoucher.vchCatgCode | translate | titlecase }} </h2>
                        <p *ngIf="applicableVoucher.multiFlag === 0"><span class="hidden-sm">(</span>{{ "rewards.select.to.apply" | translate: {noOfVoucher: 1} }}<span class="hidden-sm">)</span></p>
                        <p *ngIf="applicableVoucher.multiFlag > 0"><span class="hidden-sm">(</span>{{ "rewards.select.any.to.apply" | translate }}<span class="hidden-sm">)</span></p>
                    </div>
                    <div class="card-container">
                        <div class="card-wrapper" *ngFor="let reward of applicableVoucher.voucherList; let i = index;" [ngClass]="{'hidden-card': i >= noOfCardHidden}">
                            <app-reward-card [myReward]="reward" [showStoreName]="false" [searchText]="searchText" [selectedReward]="selectedVouchers" [channelId]="channelId" [customCardType]="applicableVoucher.multiFlag > 0? cardTypes.multiSelect: cardTypes.singleSelect" [orderData]="orderData"></app-reward-card>
                        </div>
                    </div>
                    <div class="button-container" *ngIf="applicableVoucher.voucherList.length > noOfCardHidden" >
                        <button type="type" class="btn-show" id="button-show-more-{{ applicableVoucher.vchCatgId }}" (click)="onShowMore(true, applicableVoucher.vchCatgId);"><span>{{ "button.show.more" | translate }}</span><i class="oda-chevron-down"></i></button>
                        <button type="type" class="btn-show" id="button-show-less-{{ applicableVoucher.vchCatgId }}" (click)="onShowMore(false, applicableVoucher.vchCatgId);"><span>{{ "button.show.less" | translate }}</span><i class="oda-chevron-up"></i></button>
                    </div>
                </div>
            </div>
            <ng-template [ngIf]="applicableVouchers.length <= 0">
                <div class="no-voucher">
                    <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot" />
                    <h1 class="title">{{ 'rewards.result.empty.des.1' | translate }}</h1>
                    <p class="desc">{{ 'rewards.result.empty.des.2' | translate }}</p>
                    <button type="button" (click)="onClearSearch();" *ngIf="searchText">{{ "merchant.search.again.food.button" | translate }}</button>
                </div>
            </ng-template>
    </div>

    <div class="footer-container" *ngIf="applicableVouchers && applicableVouchers.length > 0">
        <button type="button" class="btn-apply" [disabled]="selectedVouchers.length <= 0" (click)="onApply();">{{ "button.apply" | translate }} <span *ngIf="selectedVouchers.length> 0">({{ selectedVouchers.length }})</span></button>
    </div>

    <ng-template [ngIf]="applicableVouchers && applicableVouchers.length <= 0">
        <div class="no-voucher">
            <img src="assets/mascot/Mascot_no-voucher-Grey@2x.webp" alt="odaring mascot" *ngIf="channelId | isShowMascot"/>
            <h1 class="title">{{ 'rewards.result.empty.des.1' | translate }}</h1>
            <p class="desc">{{ 'rewards.result.empty.des.2' | translate }}</p>
            <button type="button" (click)="onBack();">{{ "button.go.back" | translate }}</button>
        </div>
    </ng-template>
</div>
