import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PageName } from 'src/app/core/enums/PageName';


@Injectable({ providedIn: 'root' })
export class RoutingService {

  private curPath = "account";
  private homePath = 'home';
  private fromPath = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  setFromPath(pathName: string) {
    this.fromPath = pathName;
  }

  getFromPath(): string {
    return this.fromPath;
  }

  navigate(pageName: PageName, data?: any) {
    switch (pageName) {
      case PageName.LandingPage: {
        this.router.navigate([""], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.LoginPage: {
        this.router.navigate(["login"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.ForgotPasswordPage: {
        this.router.navigate(["forgot-password"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.ResetPasswordPage: {
        this.router.navigateByUrl("/account/profile/reset-password", { state: data });
        break;
      }
      case PageName.ForgotSuccessPage: {
        this.router.navigate(["reset-success"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.ForgotFailedPage: {
        this.router.navigate(["reset-failed"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.SignupPage: {
        this.router.navigate(["register"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.LoadingPage: {
        this.router.navigate(["loading"], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.ProfilePage: {
        this.router.navigateByUrl("/account/profile", { state: data });
        break;
      }
      case PageName.ResetMobilePage: {
        this.router.navigateByUrl("/account/profile/reset-phone", { state: data });
        break;
      }
      case PageName.OTPPage: {
        if (data && data?.pageName === PageName.ResetMobilePage) {
          this.router.navigateByUrl("/account/profile/reset-phone-no", { skipLocationChange: true, state: data });
        } else {
          this.router.navigate(["otp"], { relativeTo: this.activatedRoute, state: data });
        }
        break;
      }
      case PageName.HomePage: {
        this.router.navigate([this.homePath], { relativeTo: this.activatedRoute, state: data });
        break;
      }
      case PageName.CartPage: {
        this.router.navigate(['cart'], { relativeTo: this.activatedRoute, state: data })
        break;
      }
      case PageName.CompleteOtpProfile: {
        this.router.navigateByUrl("complete-profile");
        break;
      }
      default: {
        break;
      }
    }
  }


}
