<div class="image-container">
  <div class="bg ">
    <img src="assets/odaring-logo.svg" class="slider-logoHeader" (click)="navigateToLanding()" alt="odaring logo" />
  </div>
</div>

<div class="slider-content">
  <swiper [spaceBetween]="30" [centeredSlides]="true" [autoplay]="{
          delay: 12000,
          disableOnInteraction: false
          }" [pagination]="{ clickable: true }" [navigation]="false" class="mySwiper">

    <ng-template swiperSlide *ngFor="let itm of imageSlider">
      <img [src]="itm.image" [alt]="itm.imageAlt" />
      <h1 class="slider-heading-1">{{ itm.imageDesc | translate }}</h1>
    </ng-template>

  </swiper>
</div>
