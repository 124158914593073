export enum AnalyticsEvent {
  view_item = 'view_item',
  c_view_store_event = 'c_view_store_event',
  search = 'search',
  login = 'login',
  sign_up = 'sign_up',
  share = 'share',
  c_switch_channel_event = 'c_switch_channel_event',
  c_delete_channel_event = 'c_delete_channel_event',
  c_request_channel_event = 'c_request_channel_event',
  c_delivery_selection_event = 'c_delivery_selection_event',
  c_location_request = 'c_location_request',
  purchase = 'purchase',
  items = 'items',
  c_qr_dine_in_submit_order_event = 'c_qr_dine_in_submit_order_event',
  c_3D_model_viewer_event = 'c_3D_model_viewer_event',
  c_refund_event = 'c_refund_event',
  c_request_receipt_event = 'c_request_receipt_event',
  c_cancel_order_event = 'c_cancel_order_event',
  c_accept_order_event = 'c_accept_order_event',
  c_confirm_pick_up_order_event = 'c_confirm_pick_up_order_event',
  c_delete_account_event = 'c_delete_account_event',
  c_filter_action_event = 'c_filter_action_event',
  c_short_cut_event = 'c_short_cut_event',
  c_select_delivery_mode_event = 'c_select_delivery_mode_event',
  add_to_cart = 'add_to_cart',
  c_cart_modifier_event = 'c_cart_modifier_event',
  c_cart_sub_modifier_event = 'c_cart_sub_modifier_event',
  c_edit_cart = 'c_edit_cart',
  c_edit_cart_modifier_event = 'c_edit_cart_modifier_event',
  c_edit_cart_sub_modifier_event = 'c_edit_cart_sub_modifier_event',
  remove_from_cart = 'remove_from_cart',
  c_view_upsell_items_event = 'c_view_upsell_items_event',
  c_view_cross_sell_items_event = 'c_view_cross_sell_items_event',
  c_view_variation_items_event = 'c_view_variation_items_event',
}
