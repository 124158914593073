<div class="address-search-container">
  <form (submit)="addressInputSubmit()">
    <span class="p-input-icon-left">
      <i class="oda-pin pin-icon"></i>
      <input class="text-truncate" type="text" pInputText [(ngModel)]="addressSearchValue" name="addressSearchValue" (ngModelChange)='changed($event)'
             placeholder="{{'delivery.page.hint.text.field' | translate}}" #addressSearch style="padding-right: 102px;" autocomplete="off">
      <i class="oda-times remove-address" *ngIf="addressSearchValue" (click)="removeAddress()"></i>
      <button type="button" class="search_btn" (click)="searchCurrentLocation()">
        <i class="oda-pointer"></i>
      </button>
    </span>
  </form>

  <div class="pac-container pac-logo hdpi" [ngStyle]="{'display': displayPlaceLayout ? 'unset' : 'none'}">
    <div class="pac-item" *ngFor="let place of placeList" (click)="selectAddress(place.addressId, place.type)">
      <span>{{place.fullAddress}}</span>
    </div>
  </div>
  <div class="invalid-feeback" *ngIf="locationNotFound">
    <i class="oda-alert"></i> {{'alert.address.selected.cannot.detect.location'| translate }}
  </div>
</div>
