import { Component, Input, OnInit, Output, OnDestroy, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { countDownTimerConfigModel } from 'ngx-timer';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  timerConfig: countDownTimerConfigModel = new countDownTimerConfigModel();
  @Input() timeInSec = new Observable<number>();
  @Output() onCallback = new EventEmitter();
  @Output() onShowBackButton = new EventEmitter<boolean>();

  sub: Subscription = new Subscription();
  sub2: Subscription = new Subscription();
  isShow: boolean = true;
  countdownTimeSec: number = 0;
  numberOfTime: number = environment.optMaxFailBypass;
  @ViewChild('countDown', { static: false }) private countDown: CountdownComponent;

  constructor(
    // private countdownTimerService: CountdownTimerService
  ) { }

  ngOnInit(): void {
    this.sub = this.timeInSec.subscribe((obs) => {
      this.countdownTimeSec = obs;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // private setTimerConfig(timeInSec: number) {
  //   this.timerConfig = new countDownTimerConfigModel();
  //   //custom class
  //   this.timerConfig.timerClass  = 'countDownTimer';
  //   //timer text values
  //   this.timerConfig.timerTexts = new countDownTimerTexts();
  //   this.timerConfig.timerTexts.hourText = ":";
  //   this.timerConfig.timerTexts.minuteText = ":";
  //   this.timerConfig.timerTexts.secondsText = " ";

  //   this.countdownTimerService.isTimerStart
  //   this.stopTimer();
  //   let cdate = new Date();
  //   cdate.setSeconds(cdate.getSeconds() + timeInSec);
  //   if (!this.countdownTimerService.isTimerStart) {
  //     this.countdownTimerService.startTimer(cdate);
  //   }


  //   this.isShow = true;
  //   this.sub2 = this.countdownTimerService.interval.subscribe(dd => {
  //     if ((dd + 1) === timeInSec) {
  //       this.isShow = false;
  //       this.onCallback.emit(dd);
  //       this.sub2.unsubscribe();
  //     }
  //   });
  // }

  // stopTimer() {
  //   this.countdownTimerService.stopTimer();
  // }

  handleEvent(event: CountdownEvent) {
    if (event.action === 'start') {
      this.onShowBackButton.emit(false);
    }

    if (event.action === 'notify') {
      this.onShowBackButton.emit(true);
    }

    if (event.action === 'done' && this.numberOfTime > 0) {
      this.numberOfTime--;

      this.onCallback.emit();
    }
  }
}
