<!--desktop footer-->
<div class="footer-container hidden-sm" *ngIf="showFooter && isFinishedLoading">
  <div class="content">
    <!--odaring logo & contact detail-->
    <div class="contact-detail">
      <img src="assets/odaring-logo-blackWhite.svg" alt="odaring logo"/>
      <!--contact detail: evolf Tech-->
      <div class="detail-info">
        <h1 class="footer-heading-1" style="cursor: pointer;" (click)="onNavigate('evolf')">Evolf Technology Sdn Bhd <span class="footer-heading-3">(1392349-U)</span></h1>
        <h2 class="footer-heading-4">Unit 10-1, Level 10, Tower 5, UOA Business Park, No. 1, Jalan Pengaturcara U1/51A, Seksyen U1, 40150 Shah Alam, Selangor Darul Ehsan</h2>
      </div>
    </div>

    <!--links-->
    <div class="p-ml-auto" style="display: flex">
      <div class="info-content">
        <!--about us-->
        <a (click)="onNavigate('aboutUs')" style="display: none">
          <h1 class="footer-heading-2">{{'navigation.Menu.des.16' | translate}}</h1>
        </a>
        <!--be come a partner-->
        <a (click)="onNavigate('beComeAPartner')">
          <h1 class="footer-heading-2">{{'navigation.Menu.des.17' | translate}}</h1>
        </a>
        <!--email-->
        <a (click)="onNavigate('email')">
          <h1 class="footer-heading-2">{{'navigation.Menu.des.12' | translate}}</h1>
        </a>
      </div>

      <div class="info-content">
        <!--faq-->
        <a (click)="onNavigate('faq')" >
          <h1 class="footer-heading-2">{{'navigation.Menu.des.13' | translate}}</h1>
        </a>
        <!--privacy and policy-->
        <a (click)="onNavigate('privacyPolicy')">
          <h1 class="footer-heading-2">{{'navigation.Menu.des.14' | translate}}</h1>
        </a>
        <!--term and conditions-->
        <a (click)="onNavigate('tNc')">
          <h1 class="footer-heading-2">{{'navigation.Menu.des.15' | translate}}</h1>
        </a>
      </div>

      <div class="app-link">
        <img src="assets/app_icon/apple_store.webp" (click)="onNavigate('apple-store')" alt="appleStore logo"/>
        <img src="assets/app_icon/google_play.webp"  (click)="onNavigate('google-play')" alt="googlePlay logo"/>
        <img src="assets/app_icon/huawei_gallery.webp" (click)="onNavigate('huawei-gallery')" alt="huaweiGallery logo"/>
      </div>
    </div>
  </div>

  <!--social media link-->
  <div class="socialMedia-link">

    <a (click)="onNavigate('instagram')">
      <i class="oda-instagram"></i>
    </a>

    <a (click)="onNavigate('facebook')">
      <i class="oda-facebook"></i>
    </a>

    <a (click)="onNavigate('twitter')">
      <i class="oda-twitter"></i>
    </a>

    <h2 class="p-ml-auto">Odaring ({{buildVersion}}). Powered by Evolf Technology.</h2>
  </div>

</div>

<!--mobile footer nav bar-->
<section class="hidden-lg" *ngIf="showMobileBottomNav">
  <app-bottom-nav></app-bottom-nav>
</section>
