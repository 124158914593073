import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartService } from 'src/app/core/services/cart.service';
import * as _ from 'lodash';
import { DialogMode, StoreMode } from 'src/app/core/enums';
import { StoreResponse } from 'src/app/core/models/StoreResponse';
import { ChannelSecService } from '../channelsec/channelsec.service';
import { ChannelService } from '../channel/channel.service';
import { ChannelSec } from '../channelsec/channelsec.model';
import { ChannelSectionListResponse } from 'src/app/core/models/ChannelSectionListResponse';

@Injectable()
export class DefaultService {

  defCurSelectedItm = {
    ctgDt: {
      Title: null
    },
    itmDt: {
      Thumbnail: null,
      Title: null,
    },
    dialogMode: DialogMode.AddToCart,
    itmInd: -1,
    showFlag: false,
  };
  curSelectedItm$: Subject<any> = new Subject<any>();
  curSelectedItm = this.defCurSelectedItm;

  popupStoreMsg = new Subject<StoreResponse>();
  showStorePopupMsg = new Subject<boolean>();

  channelSecData: ChannelSec[] = null;
  openSearchFilter$ = new Subject<{displaySearch, displayFilter}>();
  openOrderTypeHome$ = new Subject<boolean>();
  displaySplashNews$ = new Subject<ChannelSectionListResponse[]>();

  constructor(
    private cartService: CartService,
    private channelSecService: ChannelSecService,
    private channelService: ChannelService,
  ) {
    this.channelSecService.get(StoreMode.Internal).subscribe((data: ChannelSec[]) => {
      this.channelSecData = data;
    });
  }

  onClickItem(ctgdt: any, dt: any, mode: DialogMode = DialogMode.AddToCart, itmInd: number = -1) {
    this.curSelectedItm.ctgDt = ctgdt;
    this.curSelectedItm.itmDt = dt;
    this.curSelectedItm.showFlag = true;
    this.curSelectedItm.dialogMode = mode;
    this.curSelectedItm.itmInd = itmInd;
    this.curSelectedItm$.next(_.cloneDeep(this.curSelectedItm));
  }

  get curSelectItm$() {
    return this.curSelectedItm$.asObservable();
  }

  onAddToCart(dt: any, mode: DialogMode) {
    if (mode === DialogMode.AddToCart) {
      this.cartService.addItem(dt);
    } else if (mode === DialogMode.Edit) {
      this.cartService.updateItem(dt, dt.itmInd);
    }

    this.closeItem();
    // this.displayMerchantItm = false;
  }

  onDeleteCartItem(itmInd: any) {
    this.cartService.deleteItem([itmInd]);
  }

  onPlaceOrder(dt: any) {
    this.cartService.placeOrder(dt);
  }

  closeItem() {
    this.curSelectedItm = this.defCurSelectedItm;
    this.curSelectedItm$.next(_.cloneDeep(this.curSelectedItm));
  }

  showOtherStoreDialog(content: StoreResponse) {
    this.showStorePopupMsg.next(true);
    this.popupStoreMsg.next(content);
  }

  HideOtherStoreDialog() {
    this.showStorePopupMsg.next(false);
  }

  isChannelSecDataEmpty(): boolean {
    return this.channelSecData.length === 0 ? true : false;
  }

  async recallChannelSecData() {
    await this.channelService.recallGetChannelData();
  }

  searchFilterDialogObserve() {
    return this.openSearchFilter$.asObservable();
  }

  showSearchFilterDialog(displaySearch: boolean, displayFilter: boolean) {
    this.openSearchFilter$.next({
      displaySearch: displaySearch,
      displayFilter: displayFilter
    });
  }

  showOrderTypeHome(open: boolean) {
    this.openOrderTypeHome$.next(open);
  }
}
