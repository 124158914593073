import { Component, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputTrim]'
})
export class InputTrimDirective {

  @Input() maxLength: number;

  constructor(private el: ElementRef) { }

  @HostListener('input') onInput(event) {
    const length = this.el.nativeElement.value ? this.el.nativeElement.value.length : 0;

    if (length > this.maxLength) {
      this.el.nativeElement.value = this.el.nativeElement.value.substr(0, length - 1);
    }
  }

   @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    // on blur, add currency formatting
    if(value)
    this.el.nativeElement.value = value.trim();
  }

}
