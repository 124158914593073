<div class="membership-voucherDetail-container">
  <div class="MvoucherDetail-header" *ngIf="!isMobileView">
    <i class="oda-times" (click)="onClickClose()"></i>
  </div>
  <div class="MvoucherDetail-header" *ngIf="isMobileView">
    <i class="oda-chevron-left" (click)="onClickClose()"></i>
    <h1>{{'rewards.details.title' | translate}}</h1>
  </div>
  <div class="voucherDetail-card cardDetail">
    <div class="VDetail-imageData">
      <img [src]="membershipVoucherDetail.imageData" onerror="this.src='assets/image-unavailable.svg'" alt="membership voucher" loading="lazy">
    </div>
    <div class="detail-content">
      <div class="voucher-header">
        <h1 class="vchTypTitle">{{membershipVoucherDetail.vchTypTitle}}</h1>
        <h1 class="pointAmount" *ngIf="membershipVoucherDetail.pointAmount && onshowInfo">
          {{membershipVoucherDetail.pointAmount | number}}<span>{{'membership.points.unit.label' | translate}}</span></h1>
      </div>
      <h1 class="vchTypDesc">{{membershipVoucherDetail.vchTypDesc}}</h1>
      <div class="progressbar-content" *ngIf="membershipVoucherDetail.totalIssuedPercentage != 0 && onshowInfo">
        <div class="progressbar">
          <p-progressBar [value]="membershipVoucherDetail.totalIssuedPercentage" [showValue]="false"></p-progressBar>
        </div>
        <p class="text-claimed">{{ membershipVoucherDetail.totalIssuedPercentage }}% {{ "button.voucher.has.claim" |
          translate }}</p>
      </div>
      <hr>
      <!--valid date-->
      <h1 class="validDate-header">{{'valid.date.label' | translate}}</h1>
      <div class="validDate-content">
        <h1 class="validDate" *ngIf="membershipVoucherDetail.effectiveDate">{{'menu.item.from' |
          translate}} {{membershipVoucherDetail.effectiveDate | date: "dd/MM/yyyy"}} </h1>
        <h1 class="validDate" [ngClass]="{'validDate-2' : membershipVoucherDetail.effectiveDate}" *ngIf="membershipVoucherDetail.expiryDate">{{'merchant.delivery.time.until' |
          translate}} {{membershipVoucherDetail.expiryDate | date: "dd/MM/yyyy"}}</h1>
      </div>
      <!--Terms & condition-->
      <div *ngIf="membershipVoucherDetail.termsData">
        <h1 class="termData" [innerHtml]="membershipVoucherDetail.termsData"></h1>
      </div>
    </div>
  </div>
  <div class="voucherDetail-card cardButton" [ngStyle]="{ 'padding-bottom': onshowInfo? '15px': '50px'}">
    <div class="partition">
      <hr>
    </div>
    <button (click)="onClaim();" *ngIf="onshowInfo">{{'membership.redeem.btn.label' | translate}}</button>
  </div>
</div>
