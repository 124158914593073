import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modifierPrice'
})
export class ModifierPricePipe implements PipeTransform {

  transform(value: number, modifier): number {
    let totalPrice = 0;

    if(modifier.subModifierGrps){
      for(let i = 0; i < modifier.subModifierGrps.length; i++){
        if(modifier.subModifierGrps[i].qtyGrp > 0){
          for(let j = 0; j < modifier.subModifierGrps[i].subModifiers.length; j++){
            if(modifier.subModifierGrps[i].subModifiers[j].qty && modifier.subModifierGrps[i].subModifiers[j].qty > 0){
              totalPrice += modifier.subModifierGrps[i].subModifiers[j].qty * modifier.subModifierGrps[i].subModifiers[j].price;
            }
          }
        }
      }
    }

    return value + totalPrice;
  }

}
