import { ChangeData } from 'ngx-intl-tel-input';
import { UtilsService } from './../../../core/services/utils.service';
import { Subscription} from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DefaultSettingService } from 'src/app/core/services/default-setting.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/config.service';
import { DetailActivity } from 'src/app/home/order/order.model';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-submit-phone-no',
  templateUrl: './submit-phone-no.component.html',
  styleUrls: ['./submit-phone-no.component.scss']
})
export class SubmitPhoneNoComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() onClickClose = new EventEmitter();
  @Output() onClickSubmit = new EventEmitter();
  @Input() order : DetailActivity;
  @Input() channelId : number;

  telInpSetting = DefaultSettingService.telInpSetting;
  phoneNoVal : string = '';
  phoneData : ChangeData;
  countryCode : string = "MY";

  fgroup: UntypedFormGroup = this.fb.group({
    phoneNo: ['', Validators.compose([Validators.required])],
  });

  phoneSub$ : Subscription;
  isInvalid: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router : Router,
    private configService: ConfigService,
    private utilService : UtilsService,
    private storageService : StorageService

  ) { }

  async ngOnInit() {
    // get submitted phone data value from local storage
    this.phoneData = this.storageService.getCachedNumber();

    if(this.phoneData){
      // get mobile number
      let phoneNo = this.formatPhoneNo(this.phoneData);
      // get dial code e.g 60
      let dialCode = this.phoneData.dialCode.replace('+', '');
      // throw in mobile number with '+' to a library to know its origin
      let parsedPhoneData = await this.utilService.parseGivenPhoneNumber("+" + dialCode + phoneNo);
      this.countryCode = parsedPhoneData.country;
      this.getFormControl('phoneNo').setValue(parsedPhoneData.nationalNumber);
    }
    else{
      this.countryCode = localStorage.getItem('countryCode') ? localStorage.getItem('countryCode') : 'MY';
    }

    this.phoneSub$ = this.fgroup.get('phoneNo').valueChanges.subscribe(val => {
      if(val){
        this.phoneNoVal = val.number ? val.number : '';
      }
      else{
        this.phoneNoVal = '';
      }
    });

    this.getFormControl('phoneNo').valueChanges.subscribe((dt) => {
      this.isInvalid = false;
    })
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let el = document.getElementById("phoneNo");
      el.focus();
    }, 100);
  }

  ngOnDestroy(): void {
    this.phoneSub$?.unsubscribe();
  }

  get fg() { return this.fgroup }

  get f() { return this.fg.controls; }

  private getFormControl(formControlName: string) {
    return this.fg.controls[formControlName];
  }

  onNavigate(dt: string) {
    const originCountryUrl = window.location.origin + this.configService.get('appBaseHref');
    switch (dt) {
      case 'privacyPolicy': {
        window.open(originCountryUrl + '/privacy-policy', '_blank');
        break;
      }
      case 'tNc': {
        window.open(originCountryUrl + '/term-conditions', '_blank');
        break;
      }
      default: {
        this.router.navigateByUrl("/");
        break;
      }
    }
  }

  clickClose(){
    this.onClickClose.emit();
  }

  async onSubmit(){
    let phoneData = this.getFormControl('phoneNo').value;
    if(phoneData) {
      const mobile = phoneData && phoneData.e164Number;
      let isInvalid  = await this.utilService.phoneNoValidation(mobile);
      if(!isInvalid) {
        this.isInvalid = false;
        this.onClickSubmit.emit(phoneData);
      }else {
        this.isInvalid = true;
      }
    }
  }

  private formatPhoneNo(phoneObj: ChangeData) {
    return phoneObj.e164Number? phoneObj.e164Number.replace(phoneObj.dialCode || '', ''): '';
  }

}
