import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserStore, UserState } from './user.store';
import { User } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserQuery extends QueryEntity<UserState, User> {

  selectedAddress$ = this.select(state => state.selectedAddress);
  isLoggedIn$ = this.selectFirst();

  constructor(protected store: UserStore) {
    super(store);
  }

  queryError() {
    return this.selectError();
  }

  queryLoading() {
    return this.selectLoading();
  }

  query(strArr: string[]) {
    return this.select(strArr);
  }


}
