import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlatformSet } from 'src/app/core/models';
import { QrScannerService } from 'src/app/core/services/qr-scanner.service';
import { StaticQrService } from 'src/app/core/static-qr/static-qr.service';
import { ChannelQuery } from 'src/app/home/channel/channel.query';

@Component({
  selector: 'app-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent implements OnInit {

  @Output() outputSelectedLocation = new EventEmitter();

  staticTableNo: string = '';
  tblNoLbl: string = '';
  channelSub$: Subscription = new Subscription();
  qrScannerSub$: Subscription = new Subscription();
  staticQrSub$: Subscription = new Subscription();
  channelSetting: PlatformSet[] = [];
  displayScanner: boolean = false;

  constructor(
    private staticQrService: StaticQrService,
    private channelQuery: ChannelQuery,
    private qrScannerService: QrScannerService
  ) { }

  ngOnInit(): void {
    this.channelSub$ = this.channelQuery.currentChannel$.subscribe(async (channel) => {
      this.channelSetting = channel?.data?.platformSets ? channel?.data?.platformSets : [];
      let reqTblNoLblSetting = this.channelSetting.find(setting => setting.setCode === 'DI_TBLNOLBL')?.setValue;
      this.tblNoLbl = reqTblNoLblSetting ? reqTblNoLblSetting : '';
      this.staticTableNo = this.staticQrService.get().tableNo ? (this.tblNoLbl + ' ' + this.staticQrService.get().tableNo) : '';
    });

    this.qrScannerSub$ = this.qrScannerService.showPopupScanner$.subscribe((showScanner: any) => {
      this.displayScanner = showScanner.isShow;
    });

    this.staticQrSub$ = this.staticQrService.getStaticQrData().subscribe(staticQrData => {
      this.staticTableNo = staticQrData.tableNo ? this.tblNoLbl + ' ' + staticQrData.tableNo : '';
    });
  }

  closeLocationDialog() {
    this.outputSelectedLocation.emit(true);
  }

  showScannerDialog() {
    this.qrScannerService.show(false);
  }

  ngOnDestroy() {
    this.channelSub$?.unsubscribe();
    this.qrScannerSub$?.unsubscribe();
    this.staticQrSub$?.unsubscribe();
  }
}
