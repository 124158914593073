<div class="order-info-container">
  <i *ngIf="sourceFlag && lastActivityType !== activityTypes.Queuing && !queueResponse" class="source-flag-icon {{ sourceFlag | orderTypeIcon }}"></i>
  <i *ngIf="(!sourceFlag && lastActivityType !== activityTypes.Queuing) && qrTokenResponse" class="source-flag-icon oda-qr-dinein"></i>
  <i *ngIf="lastActivityType === activityTypes.Queuing || queueResponse" class="source-flag-icon color oda-clock-alt"></i>
  <div class="order-info-wrapper">
    <!--delivery status-->
    <!-- Delivery -->
    <span class="font-1 padding-for-icon" *ngIf="detailActivity && (sourceFlag == orderSourceFlag.WebDelivery || sourceFlag == orderSourceFlag.AppDelivery) && lastActivityType !== activityTypes.Order_completed">
      {{ 'order.info.est.delivery' | translate }}<span class="font-1">,&nbsp;</span>
    </span>
    <span class="font-1 padding-for-icon" *ngIf="detailActivity && (sourceFlag == orderSourceFlag.WebDelivery || sourceFlag == orderSourceFlag.AppDelivery) && lastActivityType === activityTypes.Order_completed">
      {{ 'order.info.delivery.status.des.5' | translate }}<span class="font-1">,&nbsp;</span>
    </span>
    <span class="font-1 padding-for-icon" *ngIf="orderH && (sourceFlag == orderSourceFlag.WebDelivery || sourceFlag == orderSourceFlag.AppDelivery)">
      {{ 'order.type.select.delivery' | translate }}<span class="font-1">,&nbsp;</span>
    </span>

    <!-- Pick-up -->
    <span class="font-1 padding-for-icon" *ngIf="detailActivity && (sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup) && lastActivityType !== activityTypes.Order_completed">
      {{ 'order.info.est.pickup' | translate }}<span class="font-1">,&nbsp;</span>
    </span>
    <span class="font-1 padding-for-icon" *ngIf="detailActivity && (sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup) && lastActivityType === activityTypes.Order_completed">
      {{ 'order.info.pickup.status.des.2' | translate }}<span class="font-1">,&nbsp;</span>
    </span>
    <span class="font-1 padding-for-icon" *ngIf="orderH && (sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup)">
      {{ 'order.type.select.pick.up' | translate }}<span class="font-1">,&nbsp;</span>
    </span>

    <!-- Dine-in -->
    <span class="font-1 padding-for-icon" *ngIf="sourceFlag && (sourceFlag == orderSourceFlag.WebDineIn || sourceFlag == orderSourceFlag.AppDineIn) && !queueResponse">
      {{ "order.info.dine.in" | translate }}<span class="font-1">,&nbsp;</span>
    </span>

    <!-- QR Dine-in -->
    <span class="font-1 padding-for-icon" *ngIf="(sourceFlag && (sourceFlag == orderSourceFlag.WebQrDineIn || sourceFlag == orderSourceFlag.AppQrDineIn) && lastActivityType !== activityTypes.Queuing && !queueResponse && !qrTokenResponse) || (qrTokenResponse && storeResponse?.currentOrderType == orderTypeFlag.DineIn && storeResponse?.qrDineFlag)">
      {{ 'order.summary.qr.dinein.title' | translate }}<span class="font-1">,&nbsp;</span>
    </span>

    <!-- Takeaway -->
    <span class="font-1 padding-for-icon" *ngIf="(sourceFlag && (sourceFlag == orderSourceFlag.WebQrTakeaway || sourceFlag == orderSourceFlag.AppQrTakeaway) && !queueResponse && !qrTokenResponse) || (qrTokenResponse && storeResponse?.currentOrderType == orderTypeFlag.Pickup && storeResponse?.qrTakeawayFlag)">
      {{ 'order.summary.qr.takeaway.title' | translate }}<span class="font-1">,&nbsp;</span>
    </span>

    <!-- Queue -->
    <span class="font-1 padding-for-icon" *ngIf="lastActivityType === activityTypes.Queuing || queueResponse">
      {{ 'order.info.delivery.status.des.4' | translate }}<span class="font-1">&nbsp;-&nbsp;</span>
    </span>

    <!--table without given table label-->
    <span class="font-1-bold padding-for-icon table-no" *ngIf="tableNo && !tableLabel">
      {{ "merchant.qr.dinein.table.no" | translate: {tableNo : tableNo} }}
      <span class="dot" *ngIf="(sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup) && tableNo"></span>
    </span>

    <!--table with table lable given-->
    <span class="font-1-bold padding-for-icon table-no" *ngIf="tableNo && tableLabel">
      {{ tableLabel + ' ' + tableNo }}
      <span class="dot" *ngIf="(sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup) && tableNo"></span>
    </span>

    <!--queue no-->
    <div *ngIf="queueNo && ((detailActivity && lastActivityType === activityTypes.Queuing) || (orderH && queueResponse))">
      <span class="font-1-bold padding-for-icon">
        {{ "queue.title.1" | translate }} {{ queueNo }}
      </span>
    </div>

    <!--date and time-->
    <span class="datetime padding-for-icon" *ngIf="sourceFlag == orderSourceFlag.WebDelivery || sourceFlag == orderSourceFlag.AppDelivery
      || sourceFlag == orderSourceFlag.WebPickup || sourceFlag == orderSourceFlag.AppPickup
      || ((sourceFlag == orderSourceFlag.WebDineIn || sourceFlag == orderSourceFlag.AppDineIn) && !tableNo);">

      <!--date-->
      <span class="font-1-bold" *ngIf="isToday">{{ 'order.info.delivery.status.des.2' | translate }},&nbsp;</span>
      <span class="font-1-bold" *ngIf="!isToday && detailActivity">{{ (estimatedTime? estimatedTime: todayDate) | date: 'EEE' }},&nbsp;</span>
      <span class="font-1-bold" *ngIf="!isToday && !detailActivity">{{ (reqTime? reqTime: todayDate) | date: 'EEE' }},&nbsp;</span>
      <span class="font-1-bold" *ngIf="detailActivity">{{ (estimatedTime? estimatedTime: todayDate) | date: 'd/M' }}</span>
      <span class="font-1-bold" *ngIf="!detailActivity">{{ (reqTime? reqTime: todayDate) | date: 'd/M' }}</span>

      <!--time-->
      <div class="time" *ngIf="(!detailActivity && !reqTime) || (detailActivity && !estimatedTime); else displayTime">
        <span class="dot">&#x2022;</span><span class="font-1-bold">{{ 'merchant.delivery.time.asap' | translate }}</span>
      </div>

      <ng-template #displayTime>
        <span class="dot">&#x2022;</span>
        <span class="font-1-bold" *ngIf="detailActivity">{{ estimatedTime | date: 'H:mm' }}</span>
        <span class="font-1-bold" *ngIf="!detailActivity">{{ timeFormatCorrection(reqTime) }}</span>
      </ng-template>
    </span>

    <!--pax-->
    <span class="pax"
      *ngIf="(sourceFlag == orderSourceFlag.WebDineIn || sourceFlag == orderSourceFlag.AppDineIn ||
        sourceFlag == orderSourceFlag.WebQrDineIn|| sourceFlag == orderSourceFlag.AppQrDineIn ||
        sourceFlag == orderSourceFlag.WebQrTakeaway || sourceFlag == orderSourceFlag.AppQrTakeaway) && guestCount">
      <i class="oda-user-open"></i>
      <span class="pax-number" class="font-1 padding-for-icon">{{ guestCount == 0 ? 1 : guestCount }} {{"order.summary.pax.title" | translate}}</span>
    </span>
  </div>
</div>
