<div class="reward-card-container" [ngClass]="[ cardType ]">
    <div class="tag" *ngIf="countDownTime.length > 0">
        <div class="timer">
            <i class="oda-clock"></i>
            <p><span>{{ displayHr }}:</span><span >{{ displayMin }}:</span><span>{{ displaySc }}</span></p>
        </div>
    </div>
    <div class="reward-card-wrapper" [ngClass]="{ 'active': isChecked }">
        <span class="node top"></span>
        <div class="reward-card" [ngClass]="{'disable': isBelowMinAmt}">
            <div class="img-container" (click)="onClickCard($event)">
                <img [src]="imageData" *ngIf="imageData" alt="reward image" loading="lazy"/>
                <div class="img-icon" *ngIf="!imageData">
                    <i class="oda-store-discount" *ngIf="vchCatgCode === vchCatgCodes.MERCHANT"></i>
                    <i class="oda-d-logo" *ngIf="vchCatgCode === vchCatgCodes.ODARING"></i>
                    <i class="oda-present" *ngIf="vchCatgCode === vchCatgCodes.MEMBERSHIP"></i>
                </div>
            </div>
            <div class="content-container" (click)="onClickCard($event)">
                <p class="title" [hidden]="!showStoreName && merchantDesc">{{ merchantDesc }}</p>
                <p class="desc" [innerHtml]="vchTypDesc | highlightSearchText : searchText">{{ vchTypDesc }}</p>
                <div class="info-container">
                    <p class="date" *ngIf="exploreReward && claimStartDate">{{ "voucher.des.start.des.1" | translate }} {{ claimStartDate | date: "dd/MM/yyyy" }}</p>
                    <p class="date" *ngIf="myReward && expiryDate">{{ "rewards.myvoucher.desc.valid" | translate }} {{ expiryDate | date: "dd/MM/yyyy" }}</p>
                    <i class="btn-info oda-info" (click)="onShowDetail()"></i>
                </div>
                <div class="progressbar-container" *ngIf="issueLimitMode > 0">
                    <p-progressBar [value]="totalIssuedPercentage" [showValue]="false"></p-progressBar>
                    <p>{{ totalIssuedPercentage }}% <span class="text-claimed">{{ "button.voucher.has.claim" | translate }}</span><span class="text-used">{{ "rewards.used" | translate }}</span></p>
                </div>
                <div class="min-spend-container" *ngIf="isBelowMinAmt">
                    <i class="oda-alert"></i>
                    <p>{{ 'rewards.spend.more.to.use' | translate: { 'minPrice': minNetAmt - orderData.netAmt | currency: orderData?.currCode : 'symbol-narrow' } }}</p>
                </div>
            </div>
            <div class="button-container store" (click)="onShowOtherStore();">
                <div class="icon">
                    <i class="oda-shop"></i>
                </div>
                <p>{{ "button.voucher.gotostore" | translate }}</p>
            </div>
            <div class="button-container claim" (click)="onClaim();">
                <div class="icon">
                    <i class="oda-download"></i>
                </div>
                <p>{{ "button.voucher.claimable" | translate }}</p>
            </div>
            <div class="button-container full">
                <div class="icon">
                    <span class="icon-border"></span>
                    <i class="oda-check"></i>
                </div>
                <p>{{ "button.voucher.fully.claimed" | translate }}</p>
            </div>
            <div class="button-container claimed">
                <div class="logo" >
                    <img src="assets/odaring-logo-grey.svg" alt="odaring logo"/>
                    <p>{{ "button.voucher.has.claim" | translate }}</p>
                </div>
            </div>
            <div class="button-container use" (click)="onShowQr();">
                <div class="inline-icon">
                    <p>{{ "rewards.use" | translate }}</p>
                    <i class="oda-chevron-right"></i>
                </div>
            </div>
            <div class="button-container singleSelect" *ngIf="myReward && cardType === cardTypes.singleSelect">
                <label class="radio-btn">
                    <input type="checkbox" id="select-voucher-{{ myReward.voucherNo }}" name="select-voucher-{{ myReward.vchCatgId }}" (click)="onClickRadio($event);" [checked]="isChecked" [disabled]="isBelowMinAmt">
                    <span class="checkmark"><i class="oda-check"></i></span>
                </label>
            </div>
            <div class="button-container multiSelect" *ngIf="myReward && cardType === cardTypes.multiSelect">
                <label class="checkbox-btn">
                    <input type="checkbox" id="select-voucher-{{ myReward.voucherNo }}" name="select-voucher-{{ myReward.vchCatgId }}" (click)="onClickCheckbox($event);" [checked]="isChecked" [disabled]="isBelowMinAmt">
                    <span class="checkmark"><i class="oda-check"></i></span>
                </label>
            </div>
            <div class="button-container used">
                <div class="logo" >
                    <img src="assets/odaring-logo-grey.svg" alt="odaring logo"/>
                    <p>{{ "rewards.used" | translate }}</p>
                </div>
                <div class="date" *ngIf="occuredDate">{{ "rewards.on.date" | translate: {date: occuredDate | date: "dd/MM/yy" } }}</div>
            </div>
            <div class="button-container expired">
                <div class="logo" >
                    <img src="assets/odaring-logo-grey.svg" alt="odaring logo"/>
                    <p>{{ "rewards.expired" | translate }}</p>
                </div>
                <div class="date" *ngIf="occuredDate">{{ "rewards.on.date" | translate: {date: occuredDate | date: "dd/MM/yy" } }}</div>
            </div>
        </div>
        <span class="node bottom"></span>
    </div>
</div>
