import { Injectable } from '@angular/core';
import { PageName } from 'src/app/core/enums';
import { OtpService } from './otp.service';
import { RegisterService } from './register.service';
import { ProfileService } from './profile.service';
import { ChangeData } from 'ngx-intl-tel-input';

@Injectable()
export class LoadingService {

  constructor(
    private otpService: OtpService,
    private registerService: RegisterService,
    private profileService: ProfileService,
  ) { }
  async processLoading(dt: any) {
      if (dt === PageName.SignupPage) {
        const otpDt = this.otpService.data;
        await this.registerService.onSignup(otpDt);
      } else if (dt === PageName.ProfilePage || PageName.ResetMobilePage) {
        const mobileObjDt = this.otpService.mobileObj as ChangeData;
        const otpDt = this.otpService.otpCode;
        if (dt === PageName.ProfilePage) {
          await this.profileService.onUpdateMobileNoStatus(mobileObjDt , otpDt);
        } else if (dt === PageName.ResetMobilePage) {
          await this.profileService.onVerifyUpdateMobileNo(mobileObjDt , otpDt);
        }
      }
  }
}
